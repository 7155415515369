import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { kelQuartierModuleContext } from '../contexts/kelQuartierModuleContext';

import Bar from '../../common/assets/sprite/vi3p/marker-bar.png';
import Bibliotheque from '../../common/assets/sprite/vi3p/marker-bibliotheque.png';
import Poste from '../../common/assets/sprite/vi3p/marker-bureau-de-poste.png';
import Bus from '../../common/assets/sprite/vi3p/marker-bus.png';
import Cinema from '../../common/assets/sprite/vi3p/marker-cinema.png';
import Creche from '../../common/assets/sprite/vi3p/marker-creche.png';
import Ecole from '../../common/assets/sprite/vi3p/marker-ecole.png';
import EcoleMaternelle from '../../common/assets/sprite/vi3p/marker-ecole-maternelle.png';
import EcolePrimaire from '../../common/assets/sprite/vi3p/marker-ecole-primaire.png';
import EnseignementSup from '../../common/assets/sprite/vi3p/marker-enseignement-sup.png';
import EquipementSportif from '../../common/assets/sprite/vi3p/marker-equipement-sportif.png';
import EspaceVert from '../../common/assets/sprite/vi3p/marker-espace-vert.png';
import Hopitaux from '../../common/assets/sprite/vi3p/marker-hopitaux.png';
// import Mediatheque from '../../assets/sprite/vi3p/marker-mediatheque.png';
import Metro from '../../common/assets/sprite/vi3p/marker-metro.png';
import MonumentHistorique from '../../common/assets/sprite/vi3p/marker-monument-historique.png';
import Pharmacie from '../../common/assets/sprite/vi3p/marker-pharmacie.png';
import Restaurant from '../../common/assets/sprite/vi3p/marker-restaurant.png';
import Supermarche from '../../common/assets/sprite/vi3p/marker-supermarche.png';
import Theatre from '../../common/assets/sprite/vi3p/marker-theatre.png';
import Tramway from '../../common/assets/sprite/vi3p/marker-tramway.png';
import Velo from '../../common/assets/sprite/vi3p/marker-velo.png';

export default function KelQuartierModuleProvider(props) {
  const { children, getGoogleReverseGeocoding, kelquartierGetPois, theme } = props;
  const [kelQuartierPOIsData, setKelQuartierPOIsData] = useState([]);
  const [activePois, setActivePois] = useState([]);
  const [addresses, setAddresses] = useState(new Map());
  const getPicto = useCallback(theme2 => {
    switch (theme2) {
      case 'bus':
        return Bus;
      case 'metro':
        return Metro;
      case 'tramway':
        return Tramway;
      case 'location de velo':
        return Velo;
      case 'bureau de poste':
        return Poste;
      case 'mini marker':
        return Supermarche;
      case 'supermarche':
        return Supermarche;
      case 'cinema':
        return Cinema;
      case 'espace vert':
        return EspaceVert;
      case 'equipement sportif':
        return EquipementSportif;
      case 'bar':
        return Bar;
      case 'restaurant':
        return Restaurant;
      case 'ecole maternelle':
        return EcoleMaternelle;
      case 'ecole primaire / college / lycee':
        return EcolePrimaire;
      case 'ecole':
        return Ecole;
      case 'enseignement superieur':
        return EnseignementSup;
      case 'bibliotheque':
        return Bibliotheque;
      case 'monument historique':
        return MonumentHistorique;
      case 'theatre':
        return Theatre;
      case 'hopital':
        return Hopitaux;
      case 'pharmacie':
        return Pharmacie;
      case 'creche':
        return Creche;
      default:
        return theme2;
    }
  }, []);

  return (
    <kelQuartierModuleContext.Provider
      value={{
        activePois,
        addresses,
        getGoogleReverseGeocoding,
        getPicto,
        kelQuartierPOIsData,
        kelquartierGetPois,
        setActivePois,
        setAddresses,
        setKelQuartierPOIsData,
        theme,
      }}
    >
      {children}
    </kelQuartierModuleContext.Provider>
  );
}

KelQuartierModuleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  getGoogleReverseGeocoding: PropTypes.func.isRequired,
  kelquartierGetPois: PropTypes.func.isRequired,
  theme: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
};
