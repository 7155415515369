import React, { useContext } from 'react';
import classnames from 'classnames';

import { getPromotionStylesFromSettings } from 'services/promotions';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { Promotion } from 'types/promotions';

import FieldCta from 'commonUi/FieldCta/FieldCta';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './ProgramPromotion.module.scss';

export default function ProgramPromotion({ promotion }: ProgramPromotionProps) {
  const { settings } = useContext(SettingsContext);

  if (
    !promotion.promo_programme_title &&
    !promotion.description &&
    !promotion.cta &&
    !promotion.image
  ) {
    return null;
  }

  return (
    <div className={styles.root} style={getPromotionStylesFromSettings(settings)}>
      <div className={classnames(styles.textContent, { [styles.fullWidth]: !promotion.image })}>
        {promotion.promo_programme_title && (
          <div className={styles.title}>
            <FieldRte className={styles.rte} html={promotion.promo_programme_title} />
          </div>
        )}
        {promotion.description && (
          <div className={styles.text}>
            <FieldRte className={styles.rte} html={promotion.description} />
          </div>
        )}
        {promotion.cta && (
          <div className={styles.cta}>
            <FieldCta
              color="secondary"
              className={styles.ctaBtn}
              cta={{
                document_reference: promotion.cta.referenceDocument,
                target: '_blank',
                title: promotion.cta.titre,
                uri: promotion.cta.uri,
              }}
            />
          </div>
        )}
      </div>
      {promotion.image && (
        <div className={styles.imageContent}>
          <FieldImage media={promotion.image} />
        </div>
      )}
    </div>
  );
}

export type ProgramPromotionProps = {
  promotion: Promotion;
};
