import React, { useCallback, useState } from 'react';
import type { ContextType, PropsWithChildren } from 'react';
import { uniqBy } from 'lodash';

import { foldersContext } from 'modules/App/Contexts/foldersContext';

export default function FoldersProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const [folderSaveType, setFolderSaveType] = useState<
    ContextType<typeof foldersContext>['folderSaveType']
  >(null);
  const [folderComparisons, setFolderComparisons] = useState<
    ContextType<typeof foldersContext>['folderComparisons']
  >([]);
  const [folderLots, setFolderLots] = useState<ContextType<typeof foldersContext>['folderLots']>(
    []
  );
  const [folderPrograms, setFolderPrograms] = useState<
    ContextType<typeof foldersContext>['folderPrograms']
  >([]);
  const [folderTms, setFolderTms] = useState<ContextType<typeof foldersContext>['folderTms']>(null);

  function emptyDatas() {
    setFolderComparisons([]);
    setFolderLots([]);
    setFolderPrograms([]);
    setFolderTms(null);
  }

  return (
    <foldersContext.Provider
      value={{
        emptyDatas,
        folderComparisons,
        folderLots,
        folderPrograms,
        folderSaveType,
        folderTms,
        setFolderComparisons,
        setFolderLots: useCallback(
          selectedLots =>
            setFolderLots(() => uniqBy([...selectedLots], d => `${d.programRef}-${d.lotNumber}`)),
          [setFolderLots]
        ),
        setFolderPrograms: useCallback(
          items =>
            setFolderPrograms(prevState => uniqBy([...prevState, ...items], d => d.programRef)),
          [setFolderPrograms]
        ),
        setFolderSaveType,
        setFolderTms,
      }}
    >
      {children}
    </foldersContext.Provider>
  );
}
