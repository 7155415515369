import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { detectNavigator } from '../../services/device';
import AppLayout from '../../layout/AppLayout';

import 'services/redirect'; // Keep this import to clear the redirect URL after each page load.

import Refresh from '../Authentication/Refresh';
import Login from '../Authentication/Login';
import SignUp from '../Authentication/SignUp';
import ProfileEditRedirect from '../MyProfile/ProfileEditRedirect';

export default function App() {
  useEffect(() => {
    const version = detectNavigator();

    if (version > 0) {
      const html = document.getElementsByTagName('html')[0];
      html.setAttribute('class', 'ie');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/refresh-aadb2c" component={Refresh} />
          <Route exact path="/signin-aadb2c" component={Login} />
          <Route exact path="/signup-aadb2c" component={SignUp} />
          {/* The profil-edit route looks like it's no longer used */}
          <Route exact path="/profil-edit-aadb2c" component={ProfileEditRedirect} />

          <Route path="*">
            <AppLayout />
          </Route>
        </Switch>
      </Router>
    </SWRConfig>
  );
}
