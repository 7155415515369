import React from 'react';

export default function SliderArrow({ className, label, onClick, style }: SliderArrowProps) {
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
      aria-label={label}
    />
  );
}

export type SliderArrowProps = {
  className?: string;
  label: string;
  onClick?: () => void;
  style?: { [x: string]: string };
};
