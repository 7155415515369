import React from 'react';
import classnames from 'classnames';

import { replaceTokens } from 'services/formatter';
import {
  ACTIVITY_STEPS_CONFIRMATION,
  ACTIVITY_STEP_COLOR_CURRENT,
  ACTIVITY_STEP_COLOR_FUTURE,
  ACTIVITY_STEP_COLOR_PAST,
} from 'settings/activity';
import { LABEL_ACTIVITY_STEP } from 'settings/labels';
import { TOKEN_NB } from 'settings/token';

import { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';

import { Pastille } from 'commonUi/Pastille/Pastille';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ActivityConfirmationSteps.module.scss';

export default function ActivityConfirmationSteps({
  activeStep,
  className = undefined,
  withLoadIconOnActiveStep = false,
}: ActivityConfirmationStepsProps) {
  return (
    <ol className={classnames(className, styles.list)}>
      {ACTIVITY_STEPS_CONFIRMATION.map(({ icon, iconCurrent, title }, index) => {
        let bgColor = ACTIVITY_STEP_COLOR_PAST;
        if (index === activeStep) {
          bgColor = ACTIVITY_STEP_COLOR_CURRENT;
        } else if (index > activeStep) {
          bgColor = ACTIVITY_STEP_COLOR_FUTURE;
        }
        let iconName: Icons | undefined = index < activeStep ? 'check-circle-bg' : undefined;
        if (withLoadIconOnActiveStep && index === activeStep) {
          iconName = 'load';
        }

        return (
          <li
            key={title}
            className={classnames(styles.item, {
              [styles.currentItem]: index === activeStep,
            })}
          >
            <Pastille
              className={styles.itemTag}
              label={replaceTokens(LABEL_ACTIVITY_STEP, { [TOKEN_NB]: index + 1 })}
              labelClassName={styles.itemTagLabel}
              bgColor={bgColor}
              textColor="#fff"
              icon={iconName}
              chipClassName={styles.chip}
            />

            <div className={styles.itemIconWrapper}>
              <SvgIcon
                className={styles.itemIcon}
                iconId={index === activeStep ? iconCurrent || icon : icon}
              />
            </div>

            <span className={styles.itemTitle}>{title}</span>
          </li>
        );
      })}
    </ol>
  );
}

export type ActivityConfirmationStepsProps = {
  activeStep: number;
  className?: string;
  withLoadIconOnActiveStep?: boolean;
};
