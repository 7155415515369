import React from 'react';

import styles from './SpinLoader.module.scss';

export default function SpinLoader({
  height = 50,
  loading = true,
  pColor = '#555',
  sColor = '#f3f3f3',
  thickness = 10,
  width = 50,
}: SpinLoaderProps) {
  return (
    <div
      className={styles.root}
      style={{
        display: loading ? 'flex' : 'none',
        borderRadius: '50%',
        border: `${thickness || 10}px solid ${sColor || '#f3f3f3'}`,
        borderTop: `${thickness || 10}px solid ${pColor || '#555'}`,
        height: `${height || 50}px`,
        width: `${width || 50}px`,
      }}
    />
  );
}

export type SpinLoaderProps = {
  height?: number;
  loading?: boolean;
  pColor?: string;
  sColor?: string;
  thickness?: number;
  width?: number;
};
