import React from 'react';
import { Redirect } from 'react-router-dom';

import { decodeToken, getCodeErrorFromUrl, getTokenFromUrl, setTokenInSession } from 'services';

export default function ProfilEditRedirect() {
  if (getCodeErrorFromUrl()) {
    return (
      <Redirect
        to={{
          pathname: '/mon-profil',
          state: { isEdited: false },
        }}
      />
    );
  }

  const encodedToken = getTokenFromUrl();
  const token = decodeToken(encodedToken, true);

  if (token) {
    setTokenInSession(encodedToken);

    return (
      <Redirect
        to={{
          pathname: '/mon-profil',
          state: { isEdited: true },
        }}
      />
    );
  }
  return <Redirect to="/mon-profil" />;
}
