import React, { useContext, useEffect, useState } from 'react';
import type { RouteChildrenProps } from 'react-router-dom';

import userContext from 'modules/App/Contexts/userContext';
import { getNormalizedRoleFrom, isInvite } from 'services';
import {
  LABEL_PAGE_TITLE_PROFILE,
  LABEL_TAB_ACCOUNT,
  LABEL_TAB_COMMERCIAL_RIGHTS,
  LABEL_TAB_FEES,
  LABEL_TAB_INTERLOCUTORS,
  USER_ROLE_OP,
} from 'settings';

import PageTitle from 'commonUi/PageTitle/PageTitle';
import Tabs from 'commonUi/Tabs/Tabs';
import TabPanel from 'commonUi/Tabs/TabPanel';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import AccountContacts from './AccountContacts/AccountContacts';
import AccountDetail from './AccountDetail/AccountDetail';
import AccountFees from './AccountFees/AccountFees';
import AccountRights from './AccountRights/AccountRights';

export default function MyProfile({
  history,
  location,
}: RouteChildrenProps<any, { isEdited: boolean }>) {
  const [redirect, setRedirect] = useState(false);
  const { userCrm } = useContext(userContext);
  const role = userCrm && getNormalizedRoleFrom(userCrm.extension_VI3P_Role);

  useEffect(() => {
    if (isInvite()) {
      setRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (redirect) {
      history.push('/pending-validation');
    }
  }, [history, redirect]);

  const headers = [
    { id: 'account', label: LABEL_TAB_ACCOUNT },
    { id: 'interlocutors', label: LABEL_TAB_INTERLOCUTORS },
    { id: 'commercial-rights', label: LABEL_TAB_COMMERCIAL_RIGHTS },
  ];

  if (userCrm && role !== USER_ROLE_OP) {
    headers.push({ id: 'fees', label: LABEL_TAB_FEES });
  }

  return (
    <>
      <PageTitle title={LABEL_PAGE_TITLE_PROFILE} />
      <Tabs defaultTab="account" headers={headers} isMyProfile>
        {activeTab => (
          <>
            <TabPanel index="account" value={activeTab}>
              <AccountDetail isEdited={location?.state?.isEdited} />
            </TabPanel>
            <TabPanel index="interlocutors" value={activeTab}>
              <AccountContacts />
            </TabPanel>
            <TabPanel index="commercial-rights" value={activeTab}>
              <AccountRights />
            </TabPanel>
            {userCrm && role !== USER_ROLE_OP && (
              <TabPanel index="fees" value={activeTab}>
                <AccountFees />
              </TabPanel>
            )}
          </>
        )}
      </Tabs>
      {/* TODO : check if TagCommander props are correct */}
      <TagCommander navigation_pagename="espace_perso" navigation_template="espace_perso.profil" />
    </>
  );
}
