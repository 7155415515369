import React, { useContext, useMemo, useState } from 'react';
import classnames from 'classnames';
import { uniqBy } from 'lodash';
import qs from 'query-string';

import {
  LABEL_DELETE,
  LABEL_DOWNLOAD,
  LABEL_SELECT_ALL,
  LABEL_SEND,
  LABEL_SEND_FOLDER_DOCUMENTS,
  LABEL_UNSELECT_ALL,
  LABEL_X_PROGRAM,
  LABEL_X_PROGRAMS,
} from 'settings/labels';
import { LOT_JSON_PROGRAM_REF } from 'settings/lots';
import { MODAL_ID_SEND_BOOKLET, MODAL_ID_SEND_DOCUMENTS } from 'settings/modal';
import { PROGRAM_DEFAULT_SORT_BY, PROGRAM_DEFAULT_SORT_ORDER } from 'settings/search';
import { TOKEN_FOLDER_NAME, TOKEN_NB } from 'settings/token';

import type { FolderProgram, FolderType } from 'api/vi3pAPI/apiTypes/FolderType';
import type { DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';
import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import programLotContext from 'modules/App/Contexts/programLotContext';

import { downloadDocs } from 'services/download';
import { replaceTokens } from 'services/formatter';

import { useSortedPrograms } from 'hooks/useSortedPrograms';
import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';
import { useDocuments } from 'modules/MyFolder/hooks/useDocuments';

import { getProgramByRef } from 'api/viOffresAPI/apiClient';

import Button from 'commonUi/Button/Button';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import ProgramResult from 'modules/HomePage/Components/ProgramCard/ProgramResult';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';
import { ModalSendDocuments } from 'modules/ActionsProgramsAndLots/ModalSendDocuments/ModalSendDocuments';

import styles from './Tabs.module.scss';

interface FolderProgramsProps {
  folder: FolderType;
  handleDelete: (programs: { programRef: string }[]) => void;
  programs: FolderProgram[];
}

export function TabPrograms({ folder, handleDelete, programs: programsRefs }: FolderProgramsProps) {
  const { lots: lotsJson, programs: programsJson, isProgramsLoading } = useContext(
    programLotContext
  );
  const availableLots = useMemo(
    () =>
      (lotsJson ?? []).reduce<Record<LotJson['ref'], number>>(
        (acc, lot) => ({
          ...acc,
          [lot[LOT_JSON_PROGRAM_REF]]: (acc[lot[LOT_JSON_PROGRAM_REF]] ?? -1) + 1,
        }),
        {}
      ),
    [lotsJson]
  );
  const folderPrograms = useMemo(
    () =>
      (programsJson ?? []).filter(program =>
        programsRefs.some(({ programRef }) => programRef === program.ref)
      ),
    [programsJson, programsRefs]
  );

  const [selectedPrograms, setSelectedPrograms] = useState<{ programRef: string }[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [bookletProgram, setBookletProgram] = useState<ProgramListType>();

  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(bookletProgram?.ref, open === MODAL_ID_SEND_BOOKLET);

  const { programs: sortedPrograms, isLoading: isSortLoading } = useSortedPrograms(
    folderPrograms,
    PROGRAM_DEFAULT_SORT_BY,
    PROGRAM_DEFAULT_SORT_ORDER
  );

  const queryParams = qs.stringify(
    { programs: selectedPrograms.map(s => s.programRef) },
    {
      encode: false,
      arrayFormat: 'bracket',
    }
  );
  const { documents } = useDocuments(queryParams);

  if (!sortedPrograms) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.nbItems}>
          {replaceTokens(sortedPrograms.length <= 1 ? LABEL_X_PROGRAM : LABEL_X_PROGRAMS, {
            [TOKEN_NB]: sortedPrograms.length,
          })}
        </div>
        <div>
          {sortedPrograms.length > 0 && (
            <button
              className={styles.buttonLink}
              type="button"
              onClick={() => {
                if (selectedPrograms.length === sortedPrograms.length) {
                  setSelectedPrograms([]);
                } else {
                  const allselectedPrograms = sortedPrograms.map(p => ({ programRef: p.ref }));
                  setSelectedPrograms(prevState =>
                    uniqBy([...prevState, ...allselectedPrograms], l => `${l.programRef}`)
                  );
                }
              }}
            >
              {selectedPrograms.length === sortedPrograms.length
                ? LABEL_UNSELECT_ALL
                : LABEL_SELECT_ALL}
            </button>
          )}
        </div>
        <div className={styles.buttonsHeader}>
          {selectedPrograms.length > 0 && (
            <div className={styles.buttons}>
              <div className={styles.buttonsItem}>
                <Button
                  className={styles.button}
                  color="primary"
                  iconId={!downloadInProgress ? 'icon-download-bicolor' : ''}
                  iconClass={styles.buttonSvgIcon}
                  labelWhileLoading
                  loading={downloadInProgress}
                  loadingClassName={styles.buttonLoader}
                  onClick={async () => {
                    setDownloadInProgress(true);
                    const programsPromise = selectedPrograms?.map(p =>
                      getProgramByRef(p.programRef)
                    );
                    const programs = await Promise.all(programsPromise);
                    if (programs && programs.length > 0) {
                      const documents = programs
                        .filter(p => p.documents && p.documents.length > 0)
                        .reduce<DocumentTypeV2[]>(
                          (acc, program) => acc.concat(program.documents),
                          []
                        );

                      await downloadDocs(documents, folder.title);
                    }
                    setDownloadInProgress(false);
                  }}
                  tooltip={LABEL_DOWNLOAD}
                  variant="outlined"
                />
              </div>
              <div className={styles.buttonsItem}>
                <Button
                  className={styles.button}
                  color="primary"
                  iconId="icon-send"
                  iconClass={styles.buttonSvgIcon}
                  onClick={() => openModal(MODAL_ID_SEND_DOCUMENTS)}
                  tooltip={LABEL_SEND}
                  variant="outlined"
                />
              </div>
              <div className={styles.buttonsItem}>
                <Button
                  className={classnames(styles.button, styles.deleteBtn)}
                  color="primary"
                  icon="trash"
                  iconClass={styles.buttonIcon}
                  onClick={() => {
                    handleDelete(selectedPrograms);
                    setSelectedPrograms([]);
                  }}
                  tooltip={LABEL_DELETE}
                  variant="outlined"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isProgramsLoading || isSortLoading ? (
        <div className={styles.loading}>
          <SpinLoader width={100} height={100} />
        </div>
      ) : (
        <div className={styles.programs}>
          {sortedPrograms.map(program => (
            <div
              key={program.ref}
              className={classnames(styles.program, {
                [styles.selected]: selectedPrograms.some(p => p.programRef === program.ref),
              })}
            >
              <Checkbox
                checked={selectedPrograms.some(p => p.programRef === program.ref)}
                classes={{ root: styles.checkbox }}
                color="primary"
                handleChange={() => {
                  if (
                    selectedPrograms.find(selectedLot => selectedLot.programRef === program.ref)
                  ) {
                    setSelectedPrograms(selectedPrograms =>
                      selectedPrograms.filter(
                        selectedLot => !(selectedLot.programRef === program.ref)
                      )
                    );
                    return;
                  }
                  setSelectedPrograms(selectedPrograms => [
                    ...selectedPrograms,
                    { programRef: program.ref },
                  ]);
                }}
              />
              <ProgramResult
                className={styles.programCard}
                availableLots={program?.ref ? availableLots[program.ref] : 0}
                hideMapButton
                isSelected={selectedPrograms.some(p => p.programRef === program.ref)}
                openModalSendBooklet={programRef => {
                  setBookletProgram(programsJson.find(program => program.ref === programRef));
                  openModal(MODAL_ID_SEND_BOOKLET);
                }}
                program={program}
                showLotsListButton={false}
                showSendProgramPdfButton={false}
                tmsInfos={{
                  launchAlert: {
                    open: {
                      navigation_pagename: 'espace_perso.dossiers.alerteav',
                      navigation_template: `espace_perso.${program.ref}`,
                    },
                    confirmed: {
                      navigation_pagename: 'espace_perso.dossiers.alerteav.confirmation',
                      navigation_template: `espace_perso.${program.ref}`,
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
      )}

      {bookletProgram && open === MODAL_ID_SEND_BOOKLET && (
        <ModalSendBooklet
          booklet={booklet}
          closeCallBack={closeModal}
          open
          program={bookletProgram}
        />
      )}

      <ModalSendDocuments
        closeCallBack={closeModal}
        documents={documents}
        open={open === MODAL_ID_SEND_DOCUMENTS}
        title={replaceTokens(LABEL_SEND_FOLDER_DOCUMENTS, {
          [TOKEN_FOLDER_NAME]: folder.title,
        })}
      />
    </div>
  );
}
