import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  bold: {
    fontWeight: 'medium',
  },
  tax: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '25px',
    width: '25px',
  },
  pastilleLabel: {
    fontSize: '7px',
  },
});
