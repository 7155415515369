import React from 'react';

import { LABEL_FOLDERS_CONFIRM_QUESTION, LABEL_FOLDER_DELETE } from 'settings/labels';

import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';

export function ModalDeleteFolder(props: ModalDeleteFolderType) {
  const { closeCallBack, deleteCallBack, open } = props;

  return (
    <ModalConfirm
      onCancel={closeCallBack}
      onConfirm={deleteCallBack}
      open={open}
      title={LABEL_FOLDER_DELETE}
    >
      <div>{LABEL_FOLDERS_CONFIRM_QUESTION}</div>
    </ModalConfirm>
  );
}

type ModalDeleteFolderType = {
  closeCallBack: () => void;
  deleteCallBack: () => void;
  open: boolean;
};
