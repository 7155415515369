import React from 'react';
import type { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';

import SilentRefresh from 'modules/App/SilentRefresh/SilentRefresh';

interface ModalType {
  classes?: { modalSkin?: string | string[]; modalOuter?: string | string[] };
  className?: string;
  showModal: boolean;
  closeCallback?: MouseEventHandler<HTMLButtonElement>;
  type: string;
}

export default function Modal({
  children,
  classes = {},
  className,
  showModal = false,
  closeCallback,
  type = 'slider',
}: PropsWithChildren<ModalType>) {
  const stopPropagation = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleClose: typeof closeCallback = event => {
    stopPropagation(event);
    if (typeof closeCallback === 'function') {
      closeCallback(event);
    }
  };

  let content: ReactNode = null;
  let overlay: ReactNode = null;
  if (type === 'fullscreen') {
    content = <div className="modal-inner">{children}</div>;
    overlay = (
      <button
        type="button"
        className="modal-overlay"
        onClick={handleClose}
        aria-label="Fermer la modale"
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <span className="modal-close" />
      </button>
    );
  } else if (type === 'form' || type === 'cancel') {
    content = children;
    overlay = (
      <button
        type="button"
        className="modal-overlay"
        onClick={handleClose}
        aria-label="Fermer la modale"
        style={{ display: showModal ? 'block' : 'none' }}
      />
    );
  } else if (type === 'confirm') {
    content = children;
  }

  const modalSkinClass = classes && classes.modalSkin ? classes.modalSkin : undefined;
  const modalOuterClass = classes && classes.modalOuter ? classes.modalOuter : undefined;
  return (
    <>
      {showModal && <SilentRefresh />}

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classnames('modal-wrap', className)}
        style={{ display: showModal ? 'block' : 'none' }}
        onClick={stopPropagation}
      >
        <div className={classnames('modal-skin', modalSkinClass)}>
          <div className={classnames('modal-outer', modalOuterClass)}>{content}</div>
        </div>
      </div>

      {overlay}
    </>
  );
}
