import axios from 'axios';

export function axiosInstance({ baseUrl, apiKey }: { baseUrl: string; apiKey: string }) {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': apiKey,
    },
  });
}

export const axiosVI3PInstance = axiosInstance({
  baseUrl: window.vinci.REACT_APP_VINCI_VI3P_API_URL as string,
  apiKey: window.vinci.REACT_APP_VINCI_VI3P_API_KEY as string,
});

// Add a request interceptor
axiosVI3PInstance.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    window.vinci.REACT_APP_SESSION_AUTH_KEY as string
  )}`;
  return config;
});
