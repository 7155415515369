import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, FormHelperText, RadioGroup as RadioGroupMaterial } from '@material-ui/core';

import styles from './RadioButton.module.scss';

function RadioGroup(props) {
  const { error, helperText, name, value, onChange, radioLabel } = props;

  return (
    <FormControl component="fieldset">
      <RadioGroupMaterial row name={name} value={value} onChange={onChange}>
        {radioLabel}
      </RadioGroupMaterial>
      {helperText && (
        <FormHelperText
          classes={{
            error: styles.helperTextError,
            root: styles.helperTextRoot,
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

RadioGroup.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  radioLabel: PropTypes.element,
};

RadioGroup.defaultProps = {
  error: false,
  helperText: undefined,
  name: undefined,
  value: undefined,
  onChange: undefined,
  radioLabel: undefined,
};

export default RadioGroup;
