import React from 'react';
import type { ContextType, PropsWithChildren } from 'react';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

export default function SettingsProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { data: settings, isLoading, mutate } = useSWRVi3pImmutable<
    ContextType<typeof SettingsContext>['settings']
  >({ url: '/settings' }, { keepPreviousData: true });

  return (
    <SettingsContext.Provider
      value={{
        // Since we're not rendering the child tree if `settings` is undefined, we can safely assume
        // that `settings` is non-null when used by children components
        settings: settings as NonNullable<typeof settings>,
        isLoading,
        mutate,
      }}
    >
      {settings ? (
        children
      ) : (
        <div className="page-loader">
          <SpinLoader width={100} height={100} />
        </div>
      )}
    </SettingsContext.Provider>
  );
}
