import { createContext } from 'react';

export const kelQuartierModuleContext = createContext();

export const kelQuartierModuleContextDefault = {
  kelQuartierPOIsData: [],
  kelquartierGetPois: () => {},
  setActivePois: () => {},
  theme: 'vi3p',
};
