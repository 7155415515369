import React from 'react';

import { PDF_TAX_ICON } from 'settings/pdf';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

interface PdfPastilleFiscalityProps {
  tax: string;
}

export default function PdfPastilleFiscality({ tax }: PdfPastilleFiscalityProps) {
  return <PdfSvgPicto picto={PDF_TAX_ICON[tax]} />;
}
