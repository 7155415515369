import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { formatPriceRange, replaceTokens } from 'services/formatter';
import { showQuarter } from 'services/date';
import {
  LABEL_ACTABILITY_DATE,
  LABEL_DELIVERY_DATE,
  LABEL_HT,
  LABEL_NB_AVAILABLE_LOT,
  LABEL_NB_AVAILABLE_LOTS,
  LABEL_TTC,
} from 'settings/labels';
import { PROGRAM_TAX_TYPE_FURNISHED } from 'settings/programs';
import { TAX_TYPE_MALRAUX } from 'settings/taxes';
import { TOKEN_NB } from 'settings/token';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramPdfTotemStyles';

const DATE_LABELS = {
  actabilite: LABEL_ACTABILITY_DATE,
  livraison: LABEL_DELIVERY_DATE,
};

function getProgramPriceRange(program: ProgramTypeV2) {
  const rangeIsUsable = (range: string) => range.split('-').every(Boolean);
  if (program.informationsFiscalesEtFinancieres.fiscalites.includes(TAX_TYPE_MALRAUX)) {
    return [
      rangeIsUsable(program.informationsFiscalesEtFinancieres.prixHorsMobilier)
        ? program.informationsFiscalesEtFinancieres.prixHorsMobilier
        : program.informationsFiscalesEtFinancieres.prixTVAReduite,
      LABEL_TTC,
    ];
  }
  if (program.informationsFiscalesEtFinancieres.typeFiscalite === PROGRAM_TAX_TYPE_FURNISHED) {
    return [program.informationsFiscalesEtFinancieres.prixHorsMobilier, LABEL_HT];
  }
  return [program.informationsFiscalesEtFinancieres.prixTVANormal, LABEL_TTC];
}

export interface ProgramPdfTotemProps {
  program: ProgramTypeV2;
}

export default function ProgramPdfTotem({ program }: ProgramPdfTotemProps) {
  const [priceRange, tax] = getProgramPriceRange(program);
  const formattedPrice = formatPriceRange(priceRange, '€', false, true);

  return (
    <View style={styles.totem}>
      <View style={styles.totemGradient} />
      {formattedPrice && (
        <View style={styles.priceWrapper}>
          <Text style={styles.price}>{`${formattedPrice} ${tax}`}</Text>
        </View>
      )}
      {!!program.nbLotsDisponibles && (
        <Text style={styles.availableLots}>
          {replaceTokens(
            Number(program.nbLotsDisponibles) === 1
              ? LABEL_NB_AVAILABLE_LOT
              : LABEL_NB_AVAILABLE_LOTS,
            {
              [TOKEN_NB]: program.nbLotsDisponibles,
            }
          )}
        </Text>
      )}

      {(formattedPrice || !!program.nbLotsDisponibles) && <View style={styles.separator} />}

      {['actabilite', 'livraison'].map(
        key =>
          !!program.dates[key] && (
            <View key={key} style={styles.datesListItem}>
              <Text>{DATE_LABELS[key]}</Text>
              <Text style={styles.dateValue}>
                {showQuarter(new Date(Date.parse(program.dates[key])))}
              </Text>
            </View>
          )
      )}
    </View>
  );
}
