import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';

import styles from './DropdownList.module.scss';

interface DropdownListType {
  buttonClassName?: string;
  children?: React.ReactNode;
  className?: string;
  id: string;
  label: React.ReactNode;
  popinClassName?: string;
}

export default function DropdownList({
  buttonClassName = undefined,
  children = null,
  className = undefined,
  id,
  label,
  popinClassName,
}: DropdownListType) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const childrenArray = children instanceof Array ? children : [children];

  return (
    <div className={classnames(className, styles.root)}>
      <button
        aria-controls={id}
        aria-haspopup="true"
        className={classnames(styles.button, buttonClassName, { [styles.open]: open })}
        onClick={handleOpen}
        type="button"
      >
        {label}
      </button>
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        classes={{ paper: classnames(popinClassName, styles.paper) }}
        disableScrollLock
        getContentAnchorEl={null}
        id={id}
        keepMounted
        open={open}
        onClose={handleClose}
        PopoverClasses={{ root: styles.popover }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {childrenArray
          .filter(x => x)
          .map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MuiMenuItem classes={{ root: styles.listItem }} key={index}>
              {child}
            </MuiMenuItem>
          ))}
      </MuiMenu>
    </div>
  );
}
