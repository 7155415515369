import React, { forwardRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as MuiButton, ButtonGroup as MuiButtonGroup } from '@material-ui/core';
import classnames from 'classnames';

import { LABEL_DISPLAY_NB, LABEL_LOTS, LABEL_PROGRAMS } from 'settings/labels';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

import { modifyQuery } from 'services/url';

import styles from './ListSwitcher.module.scss';

interface ListSwitcherProps {
  className?: string;
  lotsNumber: number | undefined;
  lotsOnClick?: () => void;
  lotsUrlParams?: Record<string, string>;
  programsNumber: number | undefined;
  programsOnClick?: () => void;
  programsUrlParams?: Record<string, string>;
  type: 'lot' | 'program';
}

export default forwardRef<HTMLDivElement, ListSwitcherProps>(function ListSwitcher(
  {
    className,
    lotsNumber,
    lotsOnClick,
    lotsUrlParams,
    programsNumber,
    programsOnClick,
    programsUrlParams,
    type,
  },
  ref
) {
  const history = useHistory();
  const { setComponentPageVars, setPageVars } = useContext(TmsContext);

  return (
    <MuiButtonGroup
      ref={ref}
      classes={{ root: classnames(className, styles.root, styles.group) }}
      disableElevation
      disableRipple
      disableFocusRipple
    >
      <MuiButton
        data-test-id="results-list--programmes-switch"
        onClick={() => {
          programsOnClick?.();
          history.replace(modifyQuery({ type: 'program', ...programsUrlParams }));
          setPageVars({});
          setComponentPageVars({});
        }}
        disabled={type === 'program'}
        classes={{ root: styles.button, disabled: styles.buttonDisabled }}
      >
        <span className={styles.label}>
          {type !== 'program' && <span className={styles.labelPrefix}>{LABEL_DISPLAY_NB}</span>}{' '}
          <span className={styles.number}>{programsNumber ?? 0}</span>{' '}
          <span className={styles.labelText}>{LABEL_PROGRAMS}</span>
        </span>
      </MuiButton>
      <MuiButton
        data-test-id="results-list--lots-switch"
        onClick={() => {
          lotsOnClick?.();
          history.replace(modifyQuery({ type: 'lot', ...lotsUrlParams }));
          setPageVars({});
          setComponentPageVars({});
        }}
        disabled={type === 'lot'}
        classes={{ root: styles.button, disabled: styles.buttonDisabled }}
      >
        <span className={styles.label}>
          {type !== 'lot' && <span className={styles.labelPrefix}>{LABEL_DISPLAY_NB}</span>}{' '}
          <span className={styles.number}>{lotsNumber ?? 0}</span>{' '}
          <span className={styles.labelText}>{LABEL_LOTS}</span>
        </span>
      </MuiButton>
    </MuiButtonGroup>
  );
});
