import { createContext } from 'react';

type FolderSaveType = 'comparison' | 'lot' | 'program';

interface FolderLot {
  lotNumber: string;
  programRef: string;
}

interface FolderProgram {
  programName: string;
  programRef: string;
}
interface FolderTms {
  confirmation: Record<string, string>;
  default: Record<string, string>;
}

export const foldersContext = createContext<{
  emptyDatas: () => void;
  folderComparisons: FolderLot[];
  folderLots: FolderLot[];
  folderPrograms: FolderProgram[];
  folderSaveType: FolderSaveType | null;
  folderTms: FolderTms | null;
  setFolderComparisons: (lots: FolderLot[]) => void;
  setFolderLots: (lots: FolderLot[]) => void;
  setFolderPrograms: (programs: FolderProgram[]) => void;
  setFolderSaveType: (saveType: FolderSaveType | null) => void;
  setFolderTms: (tms: FolderTms | null) => void;
}>({
  emptyDatas() {},
  folderComparisons: [],
  folderLots: [],
  folderPrograms: [],
  folderSaveType: null,
  folderTms: null,
  setFolderComparisons() {},
  setFolderLots() {},
  setFolderPrograms() {},
  setFolderSaveType() {},
  setFolderTms() {},
});
