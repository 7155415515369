import { useContext } from 'react';
import useSWRImmutable from 'swr/immutable';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';

import useAttributionProgram from './useAttributionProgram';

export default function useLoadProgramAndLot(
  programRef: string | undefined,
  lotNumber: string | undefined
) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { isExcluded } = useContext(userContext);
  const { program, isLoading: isProgramLoading, error: errorProgram } = useAttributionProgram(
    programRef
  );

  const { data: lot, isLoading: isLotLoading, error: errorLot } = useSWRImmutable(
    lotNumber && programRef && (!isAttributionSwitchedOn || !isExcluded(programRef, lotNumber))
      ? `/programmes/${programRef}/lots/${lotNumber}?site=vi3p`
      : null,
    url => axiosViOffreInstance.get<LotTypeV2>(url).then(response => response.data),
    { keepPreviousData: true }
  );

  return {
    isError: !!errorLot || !!errorProgram,
    isLoading: isLotLoading || isProgramLoading,
    isLotLoading,
    isProgramLoading,
    lot,
    program,
  };
}
