import React, { useContext } from 'react';

import { LABEL_FROM, LABEL_HT, LABEL_TTC } from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { formatPrice } from 'services/formatter';

import taxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

interface ProgramCardInfoPriceProps {
  classes?: Partial<Record<'root' | 'label' | 'tax' | 'number', string>>;
  program: ProgramListType;
}

export default function ProgramCardInfoPrice({ classes = {}, program }: ProgramCardInfoPriceProps) {
  const { taxesById } = useContext(taxonomiesContext);
  const tax = taxesById?.[program.taxes[0]] === TAX_TYPE_LMNP ? LABEL_HT : LABEL_TTC;

  function printPrice(price: number) {
    return <span className={classes.number}>{formatPrice(price)}</span>;
  }

  if ((program.budget.min && !program.budget.max) || program.budget.min === program.budget.max) {
    return (
      <div className={classes.root}>
        <span className={classes.label}>
          {LABEL_FROM} {printPrice(program.budget.min)} <span className={classes.tax}>({tax})</span>
        </span>
      </div>
    );
  }

  if (program.budget.min && program.budget.max) {
    return (
      <div className={classes.root}>
        <span className={classes.label}>
          De {printPrice(program.budget.min)} à {printPrice(program.budget.max)}{' '}
          <span className={classes.tax}>({tax})</span>
        </span>
      </div>
    );
  }

  return null;
}
