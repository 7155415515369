import React from 'react';

import { LABEL_SEARCH_DELETE, LABEL_SEARCH_DELETE_CONFIRM_TEXT } from 'settings/labels';

import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';

export default function ModalDeleteSaveSearch({
  closeCallBack,
  deleteCallBack,
  loading,
  open,
}: ModalDeleteSaveSearchProps) {
  return (
    <>
      <ModalConfirm
        loading={loading}
        onCancel={closeCallBack}
        onConfirm={deleteCallBack}
        open={open}
        title={LABEL_SEARCH_DELETE}
      >
        <div>{LABEL_SEARCH_DELETE_CONFIRM_TEXT}</div>
      </ModalConfirm>
    </>
  );
}

type ModalDeleteSaveSearchProps = {
  closeCallBack: () => void;
  deleteCallBack: () => void;
  loading: boolean;
  open: boolean;
};
