import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { ContextType, PropsWithChildren } from 'react';
import type { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

import { getTokenFromSession, isTokenExpired } from 'services/authentication';
import { ACTIVITY_STATUS_ACTIVE } from 'settings/activity';
import { NEWSLETTER_STATUS_READ } from 'settings/newsletter';

import useAlerts from 'hooks/useAlerts';
import { useOptions, usePreBookings } from 'hooks/useActivities';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';
import { useSWROffresImmutable } from 'api/offresAPI/useSWROffres';
import { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';
import { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';
import { Newsletter } from 'api/vi3pAPI/apiTypes/Newsletter';
import { Prescriptor } from 'api/vi3pAPI/apiTypes/Prescriptor';
import { SavedSearchType } from 'api/vi3pAPI/apiTypes/SavedSearch';

const NEWSLETTER_REFRESH_INTERVAL = 60 * 1000; // 1 minute

export default function UserProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const [userToken, setUserToken] = useState(getTokenFromSession());
  const userCrm = useMemo<UserTokenType>(() => (userToken ? jwtDecode(userToken) : undefined), [
    userToken,
  ]);
  const userId = userCrm?.extension_VI3P_CompteId;
  const contactId = userCrm?.extension_VI3P_ContactId;
  const savedSearchUrl = `profils/${contactId}/recherches`;

  const { data: exclusionList, isLoading: isExclusionListLoading } = useSWROffresImmutable<
    string[] | undefined
  >(
    {
      url: userId && isAttributionSwitchedOn ? `/listesAttribution/${userId}/exclusion` : undefined,
    },
    // No retry on error because a 404 is a valid response
    { shouldRetryOnError: false }
  );
  const processedExclusionList = useMemo(() => {
    if (isAttributionSwitchedOn && exclusionList) {
      return new Set(exclusionList.map(item => item.toUpperCase()));
    }
    return undefined;
  }, [exclusionList, isAttributionSwitchedOn]);
  const isExcluded = useMemo<ContextType<typeof userContext>['isExcluded']>(() => {
    if (processedExclusionList) {
      return (programRef, lotNumber) =>
        processedExclusionList.has(`${programRef.toUpperCase()};${lotNumber.toUpperCase()}`);
    }
    return () => true;
  }, [processedExclusionList]);

  const { data: rawPrescriptorData } = useSWRVi3pImmutable<{ data: Prescriptor }>({
    url: !isTokenExpired(userCrm) && userId ? `prescripteurs/${userId}` : undefined,
  });
  const prescriptorData = useMemo(() => {
    if (rawPrescriptorData?.data) {
      return {
        ...rawPrescriptorData.data,
        interlocuteurs: rawPrescriptorData.data.interlocuteurs.map(item => ({
          ...item,
          field_description_contact: item.field_description_contact || 'Animateur Commercial',
        })),
      };
    }
    return undefined;
  }, [rawPrescriptorData]);

  const { data: allOptions, url: optionsUrl } = useOptions('all');
  const { data: allPreBookings, url: preBookingsUrl } = usePreBookings('all');
  const options = allOptions?.filter(
    option => option.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
  );
  const preBookings = allPreBookings?.filter(
    prebooking => prebooking.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
  );

  const activityUrls = useMemo(
    () => ({
      options: optionsUrl,
      preBookings: preBookingsUrl,
      savedSearch: savedSearchUrl,
    }),
    [optionsUrl, preBookingsUrl]
  );

  const {
    alerts,
    createAlert,
    deleteAlert,
    toggleAlert,
    alertsMarketing = {},
    createMarketingAlert,
    deleteMarketingAlerts,
  } = useAlerts(userCrm);
  const { data: savedSearch } = useSWRVi3pImmutable<SavedSearchType[]>({
    url: userCrm ? savedSearchUrl : undefined,
  });
  const {
    data: contactPrescriptor,
    isValidating: isContactPrescriptorValidating,
    mutate: mutateContactPrescriptor,
  } = useSWRVi3pImmutable<ContactPrescriptorType>({
    url: !isTokenExpired(userCrm) && contactId ? `contactsPrescripteurs/${contactId}` : undefined,
  });

  const newsletterUrl = contactId ? `profils/${contactId}/newsletters` : undefined;
  const { data: newsletters = [], mutate: mutateNewsletters } = useSWRVi3pImmutable<Newsletter[]>(
    { url: newsletterUrl },
    { refreshInterval: NEWSLETTER_REFRESH_INTERVAL }
  );
  const unreadNewsletters = useMemo(
    () => newsletters?.filter(({ statut }) => statut !== NEWSLETTER_STATUS_READ).length ?? 0,
    [newsletters]
  );
  const updateNewsletterStatus = useCallback<
    ContextType<typeof userContext>['updateNewsletterStatus']
  >(
    async (id, status) => {
      if (newsletterUrl) {
        try {
          const response = await axiosVI3PInstance.patch<any, AxiosResponse<Newsletter[]>>(
            newsletterUrl,
            { id, statut: status }
          );
          await mutateNewsletters(response.data, { revalidate: false });
        } catch (e) {
          // do nothing
        }
      }
      throw new Error('No Contact Prescriptor CRM ID provided');
    },
    [newsletterUrl, mutateNewsletters]
  );
  const refreshNewsletters = useCallback<ContextType<typeof userContext>['refreshNewsletters']>(
    () => mutateNewsletters(),
    []
  );

  return (
    <userContext.Provider
      value={{
        contactPrescriptor,
        exclusionList: processedExclusionList,
        isExcluded,
        isExclusionListLoading: isAttributionSwitchedOn && isExclusionListLoading,
        userCrm,
        userPrescripteurs: prescriptorData,
        userToken,
        setUserToken,
        options,
        preBookings,
        activityUrls,
        alerts,
        createAlert,
        deleteAlert,
        toggleAlert,
        alertsMarketing,
        createMarketingAlert,
        deleteMarketingAlerts,
        savedSearch,
        mutateContactPrescriptor,
        isContactPrescriptorLoading: !contactPrescriptor && isContactPrescriptorValidating,
        newsletters,
        unreadNewsletters,
        updateNewsletterStatus,
        refreshNewsletters,
      }}
    >
      {children}
    </userContext.Provider>
  );
}
