import React, { useEffect, useRef, useState } from 'react';
import {
  ClickAwayListener as MuiClickAwayListener,
  Collapse as MuiCollapse,
} from '@material-ui/core';
import classnames from 'classnames';

import { LABEL_INTERLOCUTORS } from 'settings/labels';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import AssistanceManaged from './AssistanceManaged';
import Interlocutor from './Interlocutor';

import styles from './Assistance.module.scss';

interface Interlocutor {
  description: string;
  email: string;
  nom: string;
  prenom: string;
  telephone: string;
  vignette: string;
}
const makeId = (interlocutor: Interlocutor | undefined) =>
  [interlocutor?.email, interlocutor?.prenom, interlocutor?.nom].filter(Boolean).join('-');

interface AssistanceDesktopProps {
  defaultMail?: string;
  interlocutors: Interlocutor[];
}

export default function AssistanceDesktop({ defaultMail, interlocutors }: AssistanceDesktopProps) {
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState<string | undefined>(() =>
    interlocutors.length === 1 ? makeId(interlocutors[0]) : undefined
  );
  const refPrevActiveId = useRef<string | undefined>(makeId(interlocutors[0]));
  const refList = useRef<HTMLDivElement>(null);
  const refDetail = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (!defaultMail && open) {
      if (activeId) {
        refPrevActiveId.current = activeId;

        if (refDetail.current) {
          const detailStyle = getComputedStyle(refDetail.current);
          const detailPadding =
            parseInt(detailStyle.getPropertyValue('padding-top'), 10) +
            parseInt(detailStyle.getPropertyValue('padding-bottom'), 10);
          const button = refDetail.current.querySelector(`.${styles.return}`);
          const item = refDetail.current.querySelector(`[data-id="${activeId}"]`);
          if (item && button) {
            const buttonTopMargin = parseInt(
              getComputedStyle(button).getPropertyValue('margin-top'),
              10
            );
            setHeight(detailPadding + button.clientHeight + buttonTopMargin + item.clientHeight);
          }
        }
      } else {
        setHeight(refList.current?.clientHeight);
      }
    }
  }, [defaultMail, open, activeId]);

  if (defaultMail) {
    return (
      <MuiClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={classnames(styles.root, open && styles.open)}>
          <MuiCollapse collapsedHeight={46} in={open}>
            <button type="button" className={styles.toggle} onClick={() => setOpen(prev => !prev)}>
              <SvgIcon className={styles.icon} iconId="icon-chat" />
              <span className={styles.text}>{LABEL_INTERLOCUTORS}</span>
            </button>
            <AssistanceManaged email={defaultMail} />
          </MuiCollapse>
        </div>
      </MuiClickAwayListener>
    );
  }

  const toggleButton = (
    <button
      type="button"
      className={styles.toggle}
      onClick={() =>
        setOpen(prev => {
          if (prev && interlocutors.length > 1) {
            setActiveId(undefined);
            return false;
          }
          return !prev;
        })
      }
    >
      <SvgIcon className={styles.icon} iconId="icon-chat" />
      <span className={styles.text}>{LABEL_INTERLOCUTORS}</span>
    </button>
  );

  return (
    <MuiClickAwayListener
      onClickAway={() => {
        setOpen(false);
        if (interlocutors.length > 1) {
          setActiveId(undefined);
        }
      }}
    >
      <div className={classnames(styles.root, open && styles.open)}>
        <MuiCollapse collapsedHeight={46} in={open}>
          <div
            className={classnames(styles.container, { [styles.active]: activeId })}
            style={{ height }}
          >
            <div ref={refList} className={classnames(styles.section, styles.list)}>
              {toggleButton}
              {interlocutors.map(interlocutor => (
                <button
                  key={makeId(interlocutor)}
                  type="button"
                  className={styles.item}
                  onClick={() => setActiveId(makeId(interlocutor))}
                >
                  {interlocutor.vignette && (
                    <div className={styles.img}>
                      <img src={interlocutor.vignette} alt="" />
                    </div>
                  )}
                  <span className={styles.content}>
                    <span className={styles.name}>
                      {interlocutor.prenom} {interlocutor.nom.toUpperCase()}
                    </span>
                    <span className={styles.role}>{interlocutor.description}</span>
                  </span>
                </button>
              ))}
            </div>
            <div ref={refDetail} className={classnames(styles.section, styles.detail)}>
              {interlocutors.length > 1 ? (
                <button
                  type="button"
                  className={styles.return}
                  onClick={() => setActiveId(undefined)}
                >
                  Retour
                </button>
              ) : (
                toggleButton
              )}

              {interlocutors.map(interlocutor => (
                <Interlocutor
                  className={classnames(styles.item, {
                    [styles.active]:
                      interlocutors.length === 1 ||
                      makeId(interlocutor) === refPrevActiveId.current,
                  })}
                  data-id={makeId(interlocutor)}
                  interlocutor={interlocutor}
                />
              ))}
            </div>
          </div>
        </MuiCollapse>
      </div>
    </MuiClickAwayListener>
  );
}
