import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BlocAlerteModalProvider from 'sharedModulesV4/BlocAlerteModal/providers/BlocAlerteModalProvider';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

export default function BlocAlerteModalProviderContainer(props) {
  const { children, theme } = props;
  const { userCrm } = useContext(userContext);
  const { settings, mutate: mutateSettings } = useContext(SettingsContext);

  return (
    <BlocAlerteModalProvider
      settings={settings.bloc_alerte}
      updateSettings={() => mutateSettings()}
      theme={theme}
      userIdCrm={userCrm?.extension_VI3P_ContactId}
    >
      {children}
    </BlocAlerteModalProvider>
  );
}

BlocAlerteModalProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
};
