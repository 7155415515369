export function chunkify<T, K>(array: T[], chunkSize: number, makeChunkKey: (x: number) => K) {
  const result: { chunk: T[]; key: K }[] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push({
      chunk: array.slice(i, i + chunkSize),
      key: makeChunkKey(i),
    });
  }
  return result;
}

export function mapAndFilter<T, M>(array: T[], mapCb: (item: T) => M | undefined) {
  return array.reduce((array, item) => {
    const mapped = mapCb(item);
    if (mapped !== undefined && mapped !== null) {
      return [...array, mapped];
    }
    return array;
  }, []);
}

export function filterNullable<T>(array: T[]) {
  return array.filter(Boolean) as NonNullable<T>[];
}
