import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  pastille: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '9px',
  },
  pastilleLabel: {
    fontSize: '5px',
    marginTop: '-1px',
  },
  expiration: {
    color: COLORS.GREYS.PURE_BLACK,
    fontSize: '6px',
    margin: '2px 0 0 0',
    textTransform: 'uppercase',
  },
});
