import React, { useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import { SEARCH_NB_ROWS_API_LOAD } from 'settings/search';
import { LOTS_PER_PAGE_TABLET } from 'settings/lots';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import programLotContext from 'modules/App/Contexts/programLotContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';
import LotRowTablette from 'commonUi/ListLotV2/LotRow/LotRowTablette';
import { ProgrammeTabletteDetailsCpn } from 'modules/DetailsLotsAndActivity/ProgrammeTabletteDetailsCpn';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';

interface LotTableTabletteProps {
  activeProgramRef?: string;
  activeLots?: LotJson[];
  currentPage: number;
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  lots?: LotJson[];
  noResultMessage?: ReactNode;
  loadMore: any;
  hasMore: boolean;
  taxById: TaxesById;
  reduceMobilePanel?: () => void;
  selectedLots?: { lotNumber: string; programRef: string }[];
  pageTemplate?: string;
  pageTemplateRef?: string;
}

export default function LotTableTablette({
  activeProgramRef,
  activeLots = [],
  currentPage,
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lots = [],
  noResultMessage = null,
  taxById,
  loadMore = undefined,
  hasMore = false,
  reduceMobilePanel = undefined,
  selectedLots = [],
  pageTemplate = undefined,
  pageTemplateRef = undefined,
}: LotTableTabletteProps) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert, userCrm } = useContext(
    userContext
  );
  const { lotNumber, programRef } = useSearch();

  const { statuses, updateStatus } = useGetLotsStatuses(
    [...activeLots, ...lots].slice(
      Math.trunc((currentPage * LOTS_PER_PAGE_TABLET) / SEARCH_NB_ROWS_API_LOAD) *
        SEARCH_NB_ROWS_API_LOAD,
      (Math.trunc((currentPage * LOTS_PER_PAGE_TABLET) / SEARCH_NB_ROWS_API_LOAD) + 1) *
        SEARCH_NB_ROWS_API_LOAD
    ),
    // If attribution switch is ON, the lots have already been filtered for it in `useLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );
  const { taxes: fiscalities } = useContext(TaxonomiesContext);
  const { programs } = useContext(programLotContext);

  const [currentRowOpen, setCurrentRowOpen] = useState<string>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      lots.length > 0 &&
      !lots.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const { number: currentLotNumber, ref: currentProgramRef } =
    lots?.find(lot => currentRowOpen === lot.nid) ?? {};
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentProgramRef, currentLotNumber);

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = lots?.findIndex(l => l.number === lotNumber);
      const currentOpen = activeIndex !== -1 ? lots[activeIndex].nid : undefined;
      setCurrentRowOpen(currentOpen);
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  if (!lots?.length && !activeLots?.length) return <div>{noResultMessage}</div>;
  const heightRefElem = document.getElementById('panelContent');

  return (
    <>
      <LotTableErrorModal
        open={loadLotError || openError}
        onClose={() => {
          setOpenError(false);
          setLoadLotError(false);
        }}
        title={LABEL_LOTS_ENABLE_ERROR_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />

      <InfiniteScroll
        loader={<div />}
        dataLength={activeLots.length + lots.length}
        next={loadMore}
        hasMore={hasMore}
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
        hasChildren
        height={heightRefElem ? heightRefElem.clientHeight - 50 : undefined}
        scrollableTarget={window}
      >
        {activeProgramRef &&
          activeLots
            ?.filter(lot => lot.ref === activeProgramRef)
            .map(lot => (
              <LotRowTablette
                renderShowMore={() => {
                  if (!lotDetail || !programDetail) {
                    return null;
                  }
                  return (
                    <MobileDrawer isDrawerOpen>
                      <ProgrammeTabletteDetailsCpn
                        myBookings={myBookings}
                        myOptions={myOptions}
                        closeDrawer={() => setCurrentRowOpen(undefined)}
                        lot={lot}
                        fiscalities={fiscalities}
                        statuses={statuses}
                        updateStatus={updateStatus}
                        pageTemplate={pageTemplate}
                        pageTemplateRef={pageTemplateRef}
                        lotApiOffre={lotDetail}
                        programDatas={programDetail}
                      />
                    </MobileDrawer>
                  );
                }}
                onStatusAlertClick={toggleAlert}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                handleOnChangeCheckbox={handleOnChangeCheckbox}
                headings={headings}
                hideMapButton={hideMapButton}
                key={lot.nid}
                lot={lot}
                statuses={statuses}
                updateStatus={updateStatus}
                taxById={taxById}
                reduceMobilePanel={reduceMobilePanel}
                isChecked={selectedLots.some(
                  l => l.lotNumber === lot.number && l.programRef === lot.ref
                )}
                pageTemplate={pageTemplate}
                pageTemplateRef={pageTemplateRef}
                isDrawerOpen={currentRowOpen === lot.nid}
                setCurrentRowOpen={setCurrentRowOpen}
              />
            ))}
        {lots?.map(lot => (
          <LotRowTablette
            renderShowMore={() => {
              if (!lotDetail || !programDetail) {
                return null;
              }
              return (
                <MobileDrawer isDrawerOpen>
                  <ProgrammeTabletteDetailsCpn
                    myBookings={myBookings}
                    myOptions={myOptions}
                    closeDrawer={() => setCurrentRowOpen(undefined)}
                    lot={lot}
                    fiscalities={fiscalities}
                    statuses={statuses}
                    updateStatus={updateStatus}
                    pageTemplate={pageTemplate}
                    pageTemplateRef={pageTemplateRef}
                    lotApiOffre={lotDetail}
                    programDatas={programDetail}
                  />
                </MobileDrawer>
              );
            }}
            programs={programs}
            onStatusAlertClick={toggleAlert}
            myBookings={myBookings}
            myOptions={myOptions}
            alerts={alerts}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            headings={headings}
            hideMapButton={hideMapButton}
            key={lot.nid}
            lot={lot}
            statuses={statuses}
            updateStatus={updateStatus}
            taxById={taxById}
            reduceMobilePanel={reduceMobilePanel}
            isChecked={selectedLots.some(
              l => l.lotNumber === lot.number && l.programRef === lot.ref
            )}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            isDrawerOpen={currentRowOpen === lot.nid}
            setCurrentRowOpen={setCurrentRowOpen}
          />
        ))}
      </InfiniteScroll>
    </>
  );
}
