export const LOG_TYPE_ERROR = 'error';
export const LOG_TYPE_INFO = 'info';
export const LOG_TYPE_SUCCESS = 'success';
export const LOG_TYPE_WARN = 'warning';

export const LOG_LEVEL_ERROR = 0;
export const LOG_LEVEL_WARN = 1;
export const LOG_LEVEL_SUCCESS = 2;
export const LOG_LEVEL_INFO = 3;

export const LOG_MAX_DISPLAY = 5;

export const DEBUG_LEVELS =
  typeof window.vinci.DEBUG === 'string'
    ? window.vinci.DEBUG.split(',').map(level => Number(level))
    : [];
export const NOTIFY_LEVELS =
  typeof window.vinci.NOTIFY === 'string'
    ? window.vinci.NOTIFY.split(',').map(level => Number(level))
    : [];

export const LOG_STYLES = {
  FgBlack: {
    color: '#000',
  },
  FgBlue: {
    color: '#00a',
  },
  FgCyan: {
    color: '#0aa',
  },
  FgGreen: {
    color: '#0a0',
  },
  FgMagenta: {
    color: '#a0a',
  },
  FgRed: {
    color: '#a00',
  },
  FgWhite: {
    color: '#aaa',
  },
  FgYellow: {
    color: '#aa0',
  },
};
