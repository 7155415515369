import React from 'react';
import classnames from 'classnames';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './AssistanceManaged.module.scss';

interface AssistanceManagedProps {
  className?: string;
  email: string;
}

export default function AssistanceManaged({ className, email }: AssistanceManagedProps) {
  return (
    <div className={classnames(className, styles.root)}>
      <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="m 31.538,32.102 c -5.155,0 -10.31,0 -15.465,0 C 5.4984761,32.512388 -2.9012815,20.766313 0.90570219,10.892315 3.935985,0.75296768 17.766851,-3.3983402 25.879858,3.3963695 c 4.282443,3.200023 6.482961,8.5898585 6.221142,13.8563155 -0.01681,4.816641 0.03357,9.639838 -0.0251,14.452397 C 32.007985,31.936059 31.779724,32.104364 31.539,32.101 M 16.073,1.171 C 6.2406984,0.78941676 -1.569483,11.711038 1.9702898,20.891984 3.9705799,27.112556 10.405786,31.361362 16.889013,30.977 c 4.694662,0 9.389325,0 14.083987,0 0,-4.966667 0,-9.933333 0,-14.9 0.1477,-8.0150915 -6.880011,-15.0467653 -14.9,-14.9" />
        <path d="m 15.586,20.067 c -0.99377,-0.150896 -0.52643,-1.598919 -0.43408,-2.234963 0.30079,-1.427369 1.52353,-2.249875 2.5773,-3.114347 0.81553,-0.665933 1.57333,-1.56866 1.59952,-2.673277 0.14113,-1.714184 -1.44565,-3.273001 -3.15362,-3.127786 -1.03431,-0.062475 -1.7007,0.74871 -2.42712,1.294612 -0.46519,0.16541 -0.91818,-0.421243 -0.64048,-0.82947 1.41068,-1.993867 4.6345,-2.121624 6.24781,-0.312062 1.38656,1.414922 1.46641,3.834501 0.22492,5.359668 -0.74589,0.98618 -1.82596,1.638006 -2.70925,2.482625 -0.77898,0.772426 -0.67427,1.877709 -0.78303,2.848232 -0.09228,0.182458 -0.2947,0.31481 -0.50197,0.301768" />
        <path d="m 16.741,23.481 c 0.02752,0.70923 -0.76029,1.27261 -1.42254,1.01727 -0.68004,-0.20324 -0.95847,-1.13088 -0.50275,-1.67502 0.39251,-0.54669 1.29311,-0.55227 1.69237,-0.01048 0.15004,0.18808 0.23352,0.42767 0.23292,0.66823 z" />
      </svg>

      <p className={styles.smallP}>Une question sur ce programme&nbsp;?</p>
      <p className={styles.bigP}>Contactez-nous&nbsp;!</p>
      <a className={styles.button} href={`mailto:${email}`}>
        <SvgIcon className={styles.icon} iconId="icon-enveloppe" />
        Contacter par mail
      </a>
    </div>
  );
}
