import React from 'react';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './AccountDetailField.module.scss';

export default function AccountDetailField({ label, value, iconId }: AccountDetailFieldProps) {
  return (
    <div className={styles.root}>
      {iconId && <SvgIcon iconId={iconId} className={styles.iconStart} />}
      <div>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
      </div>
      <Icon className={styles.icon} icon="lock" />
    </div>
  );
}

type AccountDetailFieldProps = {
  label: string;
  value: string;
  iconId?: string;
};
