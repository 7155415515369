import React from 'react';
import classnames from 'classnames';

import { LABEL_KNOW_MORE } from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import FieldCta from 'commonUi/FieldCta/FieldCta';

import styles from './LotPromotion.module.scss';

export default function LotPromotion({ className = undefined, lot }: LotPromotionProps) {
  if (!lot?.bloc_libre) {
    return null;
  }

  const {
    field_titre_bloc_libre: title,
    field_text_wysiwyg: text,
    field_cta_form_paragraph: cta,
    field_couleur_de_texte: color,
    field_couleur_de_fond: backgroundColor,
  } = lot.bloc_libre;

  if (!title && !text && !cta) {
    return null;
  }

  return (
    <div className={classnames(styles.root, className)} style={{ backgroundColor, color }}>
      {title && <FieldRte className={styles.title} html={title} />}
      {text && <FieldRte className={styles.text} html={text} />}
      {cta?.field_url_cta && (
        <div className={styles.cta}>
          <FieldCta
            color="secondary"
            className={styles.ctaBtn}
            cta={{
              target: cta.field_link_target_blank ? '_blank' : '_self',
              title: cta.field_url_cta_titre || LABEL_KNOW_MORE,
              uri: cta.field_url_cta,
            }}
          />
        </div>
      )}
    </div>
  );
}

export type LotPromotionProps = {
  className?: string;
  lot: LotTypeV2;
};
