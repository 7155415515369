import React, { useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { PAGE_TYPE_KITCHEN, PAGE_TYPE_SEO } from 'settings/app';
import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';
import { ContentType } from 'api/vi3pAPI/apiTypes/ContentType';
import { PageTypes } from 'api/vi3pAPI/apiTypes/PageTypes';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import KitchenPage from 'modules/KitchenPage/KitchenPage';
import SEOPage from 'modules/SEOPage/SEOPage';

import { ErrorPage404 } from '../ErrorPage/ErrorPage';

import styles from './OtherPage.module.scss';

function getPageType(pathname: string, pages: Record<PageTypes, string | null>) {
  if (!pages) {
    return undefined;
  }
  return Object.entries(pages).find(
    ([, pagePath]) => pagePath && pathname.match(new RegExp(`^${pagePath}\\/?$`))
  )?.[0];
}

export default function OtherPage({ location }: RouteComponentProps) {
  const { settings, isLoading: isSettingsLoading } = useContext(SettingsContext);
  const { data: page, isValidating: isContentValidating } = useSWRVi3pImmutable<ContentType>({
    url: `/content/node/all?field_path=${location.pathname}`,
  });
  const tms = {
    navigation_pagename: page?.navigation_pagename,
    navigation_template: page?.navigation_template,
  };

  if (!page && isContentValidating) {
    return (
      <div className={styles.loader}>
        <CircularProgress variant="indeterminate" size={42} />
      </div>
    );
  }

  if (!page && !isContentValidating) {
    return <ErrorPage404 />;
  }

  if ((!page && isContentValidating) || isSettingsLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress variant="indeterminate" size={42} />
      </div>
    );
  }

  switch (page?.content_type) {
    case PAGE_TYPE_SEO:
      if (getPageType(location.pathname, settings.pages) === PAGE_TYPE_KITCHEN) {
        return <KitchenPage paragraphs={page.paragraphs} tms={tms} />;
      }
      return <SEOPage paragraphs={page.paragraphs} tms={tms} />;

    default:
      return <ErrorPage404 />;
  }
}
