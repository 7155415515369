import React, { useCallback } from 'react';

import { PARAGRAPH_TYPE_ICON_TEXT, PARAGRAPH_TYPE_RICH_TEXT } from 'settings/paragraph';
import { ParagraphType } from 'types/paragraph';

import ParagraphIconText from './Components/ParagraphIconText/ParagraphIconText';
import ParagraphRte from './Components/ParagraphRte/ParagraphRte';

export default function Paragraph({
  className = undefined,
  containerClassName = undefined,
  paragraph,
  widthContainerClassName = undefined,
  withoutContainer = false,
  ...rest
}: ParagraphProps) {
  const renderParagraph = useCallback(
    (paragraph, containerClassName, rest) => {
      const { content_type: type } = paragraph;

      switch (type) {
        case PARAGRAPH_TYPE_ICON_TEXT:
          return (
            <ParagraphIconText
              containerClassName={containerClassName}
              paragraph={paragraph}
              {...rest}
            />
          );

        case PARAGRAPH_TYPE_RICH_TEXT:
          return (
            <ParagraphRte containerClassName={containerClassName} paragraph={paragraph} {...rest} />
          );

        default:
          return null;
      }
    },
    [className]
  );

  if (!paragraph) {
    return null;
  }

  return renderParagraph(paragraph, containerClassName, rest);
}

export type ParagraphProps = {
  className?: string;
  containerClassName?: string;
  paragraph: ParagraphType;
  withoutContainer?: boolean;
  widthContainerClassName?: string;
};
