import React from 'react';
import type { ComponentProps } from 'react';

import { Cta } from 'types/cta';

import Button from 'commonUi/Button/Button';

interface FieldCtaProps extends ComponentProps<typeof Button> {
  cta?: Cta;
}

export default function FieldCta({ cta, ...buttonProps }: FieldCtaProps) {
  if (!cta?.uri && !cta?.title) {
    return null;
  }

  return (
    <Button variant="contained" color="primary" href={cta.uri} target={cta.target} {...buttonProps}>
      {cta.title}
    </Button>
  );
}
