import React, { useContext, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { IconButton as MuiIconButton } from '@material-ui/core';

import { LABEL_NEWSLETTER_LINK, LABEL_NEWSLETTER_PANEL_TITLE, LABEL_TODAY } from 'settings/labels';
import { NEWSLETTER_STATUS_READ } from 'settings/newsletter';
import { PANEL_ID_NEWSLETTER } from 'settings/panels';

import type { Newsletter } from 'api/vi3pAPI/apiTypes/Newsletter';

import userContext from 'modules/App/Contexts/userContext';

import Drawer, { DRAWER_DIRECTION_RIGHT } from 'commonUi/Drawer/Drawer';
import NotificationDot from 'commonUi/NotificationDot/NotificationDot';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './NewsletterPanel.module.scss';

interface NewsletterPanelProps {
  closePanel?: () => void;
  open?: string;
}

export default function NewsletterPanel({ closePanel, open }: NewsletterPanelProps) {
  const {
    newsletters: rawNewsletters,
    unreadNewsletters,
    updateNewsletterStatus,
    refreshNewsletters,
  } = useContext(userContext);

  const groupedNewsletters = useMemo(() => {
    if (!rawNewsletters?.length) {
      return [];
    }
    const map = rawNewsletters.reduce<Map<string, Newsletter[]>>((acc, newsletter) => {
      const dayStr = dayjs(newsletter.date_creation).format('YYYY-MM-DD');
      if (acc.has(dayStr)) {
        acc.set(dayStr, [...(acc.get(dayStr) ?? []), newsletter]);
      } else {
        acc.set(dayStr, [newsletter]);
      }
      return acc;
    }, new Map());
    map.forEach(newsletters =>
      // Sort individual newsletters from most recent to oldest in each group
      newsletters.sort(({ date_creation: dateA }, { date_creation: dateB }) => {
        if (dayjs(dateA).isBefore(dateB)) {
          return 1;
        }
        if (dayjs(dateA).isSame(dateB, 'minutes')) {
          return 0;
        }
        return -1;
      })
    );
    // Sort newsletter groups from most recent to oldest
    return Array.from(map.entries()).sort(([dateA], [dateB]) =>
      dayjs(dateA).isBefore(dateB) ? 1 : -1
    );
  }, [rawNewsletters]);

  useEffect(() => {
    if (open === PANEL_ID_NEWSLETTER) {
      refreshNewsletters();
    }
  }, [open]);

  return (
    <Drawer
      classes={{
        drawerContainerClassName: styles.drawerContainer,
        drawerRootClassName: styles.drawerRoot,
        paperRootClassName: styles.paperRoot,
      }}
      onClose={closePanel}
      open={open === PANEL_ID_NEWSLETTER}
      openDirection={DRAWER_DIRECTION_RIGHT}
    >
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <SvgIcon className={styles.icon} iconId="icon-receipt-bicolor" />
          <NotificationDot className={styles.nb} amount={unreadNewsletters} />
        </div>
        <div className={styles.title}>{LABEL_NEWSLETTER_PANEL_TITLE}</div>
        {closePanel && (
          <MuiIconButton classes={{ root: styles.close }} onClick={closePanel}>
            <Icon icon="cross" />
          </MuiIconButton>
        )}
      </div>

      <div className={styles.content}>
        {groupedNewsletters.map(([date, newletters]) => (
          <div key={date} className={styles.group}>
            {dayjs().isSame(date, 'day') ? (
              <div className={classnames(styles.title, styles.today)}>{LABEL_TODAY}</div>
            ) : (
              <div className={styles.title}>{dayjs(date).format('DD/MM/YYYY')}</div>
            )}

            {newletters.map(newsletter => (
              <a
                key={newsletter.id}
                className={classnames(styles.newsletter, {
                  [styles.unread]: newsletter.statut !== NEWSLETTER_STATUS_READ,
                })}
                href={newsletter.pdf}
                target="_blank"
                rel="noopener noreferrer"
                onClick={
                  newsletter.statut !== NEWSLETTER_STATUS_READ
                    ? () => updateNewsletterStatus(newsletter.id, NEWSLETTER_STATUS_READ)
                    : undefined
                }
              >
                <div className={styles.iconContainer}>
                  <SvgIcon className={styles.icon} iconId="icon-newsletter-mono" />
                </div>
                <div className={styles.content}>
                  <div className={styles.title}>{newsletter.titre}</div>
                  <span className={styles.link}>{LABEL_NEWSLETTER_LINK}</span>
                </div>
                <div className={styles.time}>{dayjs(newsletter.date_creation).format('HH:mm')}</div>
              </a>
            ))}
          </div>
        ))}
      </div>
    </Drawer>
  );
}
