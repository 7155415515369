import React from 'react';
import classnames from 'classnames';

import { LABEL_NEW_RESIDENCE, LABEL_PREVIEW } from 'settings/labels';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { programIsNew, programIsPreview } from 'services/programs';

import styles from './ProgramCardThumbnailLabel.module.scss';

interface ProgramCardThumbnailLabelProps {
  className?: string;
  program: ProgramListType;
}

export default function ProgramCardThumbnailLabel({
  className,
  program,
}: ProgramCardThumbnailLabelProps) {
  if (programIsPreview(program)) {
    return (
      <div className={classnames(className, styles.root, styles.preview)}>{LABEL_PREVIEW}</div>
    );
  }

  if (programIsNew(program)) {
    return (
      <div className={classnames(className, styles.root, styles.new)}>{LABEL_NEW_RESIDENCE}</div>
    );
  }

  return null;
}
