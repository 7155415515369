import { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';

function capitalize(valueToCapitalize) {
  if (typeof valueToCapitalize !== 'string') return '';
  return valueToCapitalize.charAt(0).toUpperCase() + valueToCapitalize.slice(1);
}

export const MAIL_MESSAGE_DOCUMENTS = function mailMessageDocuments(prescriptor?: UserTokenType) {
  return `Bonjour,

Vous trouverez ci-après, un lien pour télécharger le(s) document(s) concernant votre recherche.

Votre conseiller.

${capitalize(prescriptor?.given_name)} ${capitalize(prescriptor?.family_name)}
${prescriptor?.email}`;
};

export const MAIL_MESSAGE_COMPARE = function mailMessageDocuments(
  prescriptor: UserTokenType | undefined
) {
  return `Bonjour,

Veuillez trouver ci-après, le tableau de la comparaison des lots susceptibles de vous intéresser.

Votre conseiller.

${capitalize(prescriptor?.given_name)} ${capitalize(prescriptor?.family_name)}
${prescriptor?.email}`;
};

export const MAILER_SENT = 'Votre e-mail a bien été expédié';
export const MAILER_BAD_MAIL = 'Veuillez saisir une adresse e-mail valide.';
export const MAILER_MISSING_DOC = 'Veuillez sélectionner au moins un document.';
