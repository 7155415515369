import React, { useContext } from 'react';
import classNames from 'classnames';

import { getNormalizedRoleFrom } from 'services';
import {
  LABEL_COMPANY_NAME,
  LABEL_DISCONNECTION,
  LABEL_EMAIL_ADDRESS,
  LABEL_FULLNAME,
  LABEL_INFORMATIONS_EDITED,
  LABEL_MOBILE_PHONE,
  LABEL_NOT_SPECIFIED,
  LABEL_PHONE,
  LABEL_POSTAL_ADDRESS,
  LABEL_PROFILE_MODIFY,
  LABEL_PROFILE_TAB_ACCOUNT_TITLE,
} from 'settings/labels';
import { USER_ROLE_DO, USER_ROLE_OP } from 'settings/user';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import userContext from 'modules/App/Contexts/userContext';

import Button from 'commonUi/Button/Button';
import PanelContainer from 'layout/components/PanelContainer/PanelContainer';

import AccountDetailField from './AccountDetailField';
// @TODO: Check if this file is needed
// import AccountDetailPreferredField from './AccountDetailPreferredField';
import AccountDetailPhonesField from './AccountDetailPhonesField';

import styles from './AccountDetail.module.scss';

export default function AccountDetail(props: AccountDetailType) {
  const { isEdited = false } = props;
  const { userCrm, userPrescripteurs } = useContext(userContext);
  const { isFooterVisibleMobile, isResponsive } = useContext(ResponsiveContext);

  if (!userCrm || !userPrescripteurs) {
    return <PanelContainer title={LABEL_PROFILE_TAB_ACCOUNT_TITLE} />;
  }

  const { field_raisonsociale: companyName } = userPrescripteurs;
  const {
    city,
    email,
    extension_telephone: phone,
    extension_telephoneMobile: mobilePhone,
    extension_VI3P_Role: dirtyRole,
    family_name: lastName,
    given_name: firstName,
    postalCode,
    streetAddress,
  } = userCrm;
  const role = getNormalizedRoleFrom(dirtyRole);
  const address = [streetAddress, postalCode, city]
    .filter(v => v !== LABEL_NOT_SPECIFIED)
    .join(' ');

  const phones = [
    { label: LABEL_PHONE, value: phone || ' ' },
    { label: LABEL_MOBILE_PHONE, value: mobilePhone || ' ' },
  ];

  return (
    <PanelContainer
      headerRight={
        role &&
        ![USER_ROLE_OP, USER_ROLE_DO].includes(role) && (
          <Button
            className={styles.modifyLink}
            color="secondary"
            href={window.vinci.REACT_APP_PROFILE_EDIT_URL}
            variant="contained"
          >
            {LABEL_PROFILE_MODIFY}
          </Button>
        )
      }
      title={LABEL_PROFILE_TAB_ACCOUNT_TITLE}
      headerClassName={styles.panelHeader}
    >
      <div className={styles.root}>
        {isEdited && <p className={styles.text}>{LABEL_INFORMATIONS_EDITED}</p>}
        <div className={styles.grid}>
          <div className={styles.gridItemFull}>
            <AccountDetailField
              label={LABEL_FULLNAME}
              value={`${firstName || ' '} ${lastName || ' '}`}
              iconId="icon-user-short"
            />
          </div>
          <div className={styles.gridItemFull}>
            <AccountDetailPhonesField phones={phones} />
          </div>
          <div className={styles.gridItemFull}>
            <AccountDetailField
              label={LABEL_POSTAL_ADDRESS}
              value={address || ' '}
              iconId="icon-marker-address"
            />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField
              label={LABEL_EMAIL_ADDRESS}
              value={email || ' '}
              iconId="icon-letter"
            />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_COMPANY_NAME} value={companyName || ' '} />
          </div>
        </div>
        {isResponsive && (
          <>
            <div className={styles.disconnectWrapper}>
              <Button
                className={styles.disconnectIconBtn}
                color="primary"
                href="/deconnexion"
                icon="power-off"
                variant="contained"
              >
                {LABEL_DISCONNECTION}
              </Button>
            </div>
            {role && ![USER_ROLE_OP, USER_ROLE_DO].includes(role) && (
              <div
                className={classNames(styles.bottom, {
                  [styles.isSticky]: !isFooterVisibleMobile,
                })}
              >
                <Button
                  className={styles.modifyLink}
                  color="secondary"
                  href={window.vinci.REACT_APP_PROFILE_EDIT_URL}
                  variant="contained"
                >
                  {LABEL_PROFILE_MODIFY}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </PanelContainer>
  );
}

type AccountDetailType = {
  isEdited?: boolean;
};
