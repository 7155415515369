import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import { MarkerClusterer } from '@react-google-maps/api';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { PROGRAM_HOUSING_TYPE_SENIOR, PROGRAM_HOUSING_TYPE_STUDENT } from 'settings/programs';
import { MAP_DEFAULT_LAT, MAP_DEFAULT_LNG, MAP_DEFAULT_ZOOM } from 'settings/search';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { modifyQuery } from 'services/url';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import { kelQuartierModuleContext } from 'sharedModulesV4/KelQuartier/contexts/kelQuartierModuleContext';
import {
  KELQUARTIER_PICTOS,
  MAP_MARKER_ZOOM,
} from 'sharedModulesV4/KelQuartier/settings/kelquartier';
import KelQuartier from 'sharedModulesV4/KelQuartier/components/KelQuartier/KelQuartier';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import Marker from './Map/Marker/Marker';
import GMap from './Map/Map/GMap';

const clusterStyles: ComponentProps<typeof MarkerClusterer>['styles'] = [
  {
    anchorIcon: [54, 18],
    fontFamily: 'Vinci Sans, Arial, sans-serif',
    fontWeight: '500',
    height: 54,
    textColor: '#003c71',
    textSize: 11,
    url: '/images/map-cluster.svg',
    width: 36,
  },
];

const useCustomStylesButton = makeStyles({
  root: {
    position: 'absolute',
    zIndex: 500,
    right: '24px',
    top: '16px',
    background: '#F8FAFF',
    borderRadius: '28px',
    '&:hover': {
      background: '#F8FAFF',
    },
  },
});

const MAP_KELQUARTIER_OFFSET_LAT = 0.002;
const MAP_KELQUARTIER_OFFSET_LNG = 0.006;

interface SearchMapProps {
  taxById?: TaxesById;
  programs?: ProgramListType[];
}

export default function SearchMap({ taxById, programs }: SearchMapProps) {
  const history = useHistory();
  // be careful KELQUARTIER can't be refact
  const { setActivePois, kelQuartierPOIsData, setKelQuartierPOIsData, getPicto } = useContext(
    kelQuartierModuleContext
  );

  const { isResponsive } = useContext(ResponsiveContext);
  const [kelQuartierLatLng, setKelQuartierLatLng] = useState<{ lat: number; lng: number }>();
  const [displayKelQuartier, setDisplayKelQuartier] = useState(false);
  const [openPanelKelQuartier, setOpenPanelKelQuartier] = useState(true);

  const mapRef = useRef<google.maps.Map>();
  const { programRef, marker: markerUrl, lat, lng, zoom, viewport } = useSearch();
  const customStylesButton = useCustomStylesButton();

  useEffect(() => {
    if (!lat && !lng && !programRef) {
      setActivePois([]);
      setKelQuartierPOIsData({});
      setKelQuartierLatLng(undefined);
      setOpenPanelKelQuartier(false);
    }
  }, [lat, lng, programRef]);

  useEffect(() => {
    if (programRef && displayKelQuartier) {
      const currentProgram = programs?.find(p => p.ref === programRef);
      if (currentProgram) {
        history.replace(
          modifyQuery({
            zoom: MAP_MARKER_ZOOM,
            lat: Number(currentProgram.lat),
            lng: Number(currentProgram.lng),
          })
        );
        setKelQuartierLatLng({ lat: currentProgram.lat, lng: currentProgram.lng });
        setOpenPanelKelQuartier(!isResponsive);
      }
    }
    if (!displayKelQuartier) {
      setActivePois([]);
      setKelQuartierPOIsData({});
      setOpenPanelKelQuartier(false);
    }
  }, [displayKelQuartier]);

  useEffect(() => {
    if (programRef) {
      const selectedProgram = programs?.find(program => program.ref === programRef);
      const isSameProgram =
        selectedProgram && kelQuartierLatLng
          ? selectedProgram.lat === kelQuartierLatLng.lat &&
            selectedProgram.lng === kelQuartierLatLng.lng
          : false;
      if (!isSameProgram) {
        setKelQuartierPOIsData({});
        setActivePois([]);
        setDisplayKelQuartier(false);
      }
    }
  }, [programRef]);

  useEffect(() => {
    if (Object.keys(kelQuartierPOIsData).length > 0 && !isResponsive) {
      handleToggleInfoBox(programRef);
    }
  }, [kelQuartierPOIsData]);

  useEffect(() => {
    if (viewport && mapRef.current) {
      mapRef.current.fitBounds(
        Object.fromEntries(
          viewport.split(',').map(bound => {
            const [key, value] = bound.split('=');
            return [key, Number(value)];
          })
        ) as Record<'north' | 'east' | 'south' | 'west', number>
      );
    }
  }, [viewport]);

  function handleToggleInfoBox(ref?: string) {
    const selectedProgram = programs?.find(program => program.ref === ref);
    const isSameProgram =
      selectedProgram && kelQuartierLatLng
        ? selectedProgram.lat === kelQuartierLatLng.lat &&
          selectedProgram.lng === kelQuartierLatLng.lng
        : false;

    if (!ref) {
      history.replace(modifyQuery({}, ['programRef']));
      setOpenPanelKelQuartier(false);
    } else if (ref === programRef) {
      history.replace(modifyQuery({}, ['programRef']));
    } else {
      history.replace(modifyQuery({ programRef: ref, origin: 'map' }));
      if (isSameProgram) {
        setOpenPanelKelQuartier(true);
      } else {
        setKelQuartierPOIsData({});
        setActivePois([]);
        setDisplayKelQuartier(false);
      }
    }
  }

  const centerLat = Number(lat) + (openPanelKelQuartier ? MAP_KELQUARTIER_OFFSET_LAT : 0);
  const centerLng = Number(lng) + (openPanelKelQuartier ? MAP_KELQUARTIER_OFFSET_LNG : 0);

  const [programsOther, programsSenior, programsStudent] = useMemo(
    () =>
      (programs ?? []).reduce<[ProgramListType[], ProgramListType[], ProgramListType[]]>(
        ([programsOther, programsSenior, programsStudent], program) => {
          if (program.housingType === PROGRAM_HOUSING_TYPE_SENIOR) {
            return [programsOther, [...programsSenior, program], programsStudent];
          }
          if (program.housingType === PROGRAM_HOUSING_TYPE_STUDENT) {
            return [programsOther, programsSenior, [...programsStudent, program]];
          }
          return [[...programsOther, program], programsSenior, programsStudent];
        },
        [[], [], []]
      ),
    [programs]
  );

  function renderMarker(
    program: ProgramListType,
    icon?: string,
    clusterer?: ComponentProps<typeof Marker>['clusterer']
  ) {
    return (
      <Marker
        key={program.nid}
        clusterer={clusterer}
        customIcon={icon}
        displayKelQuartierToggle
        isOpen={program.ref === programRef}
        lat={program.lat}
        lng={program.lng}
        nid={program.nid}
        onToggleInfoBox={handleToggleInfoBox}
        program={program}
        taxById={taxById}
        displayKelQuartier={displayKelQuartier}
        toggleKelQuartier={() => setDisplayKelQuartier(displayKelQuartier => !displayKelQuartier)}
      />
    );
  }

  return (
    <>
      {displayKelQuartier && kelQuartierLatLng && (
        <>
          <KelQuartier
            defaultOpenPanel={isResponsive ? !openPanelKelQuartier : openPanelKelQuartier}
            closeDrawer={() => setOpenPanelKelQuartier(true)}
            residenceLat={kelQuartierLatLng.lat}
            residenceLng={kelQuartierLatLng.lng}
          />
          <Button
            className={customStylesButton.root}
            onClick={() => {
              setOpenPanelKelQuartier(openPanelKelQuartier => !openPanelKelQuartier);
            }}
          >
            <SvgIcon
              iconId="icon-point-interest"
              style={{ width: '22px', height: '28px', marginLeft: '10px', marginRight: '8px' }}
            />
            <SvgIcon
              iconId="icon-double-arrow"
              style={{ width: '31px', height: '31px', marginRight: '10px' }}
            />
          </Button>
        </>
      )}
      <GMap
        center={{ lat: centerLat || MAP_DEFAULT_LAT, lng: centerLng || MAP_DEFAULT_LNG }}
        onMapChange={() => {
          if (mapRef.current) {
            const bounds = (mapRef.current.getBounds() as google.maps.LatLngBounds).toJSON();
            const center = mapRef.current.getCenter().toJSON();
            const zoom = mapRef.current.getZoom();
            if (bounds.north !== bounds.south && bounds.east !== bounds.west) {
              history.replace(
                modifyQuery({
                  north: bounds.north,
                  south: bounds.south,
                  east: bounds.east,
                  west: bounds.west,
                  lat: center.lat - (openPanelKelQuartier ? MAP_KELQUARTIER_OFFSET_LAT : 0),
                  lng: center.lng - (openPanelKelQuartier ? MAP_KELQUARTIER_OFFSET_LNG : 0),
                  zoom,
                })
              );
            }
          }
        }}
        onLoad={ref => {
          mapRef.current = ref;
        }}
        onClick={() => handleToggleInfoBox()}
        zoom={Number(zoom) || MAP_DEFAULT_ZOOM}
      >
        {kelQuartierPOIsData?.data &&
          kelQuartierPOIsData?.data?.map(marker => (
            <Marker
              lat={marker.lat}
              lng={marker.lon}
              nid={marker.id}
              customIcon={getPicto(KELQUARTIER_PICTOS[marker.id_theme_2])}
              hideLabel
              customInfobox={marker.nom}
              onToggleInfoBox={markerNid => {
                if (markerUrl === markerNid) {
                  history.replace(modifyQuery({}, ['marker']));
                } else {
                  history.replace(modifyQuery({ marker: markerNid, origin: 'map' }));
                }
              }}
              isOpen={markerUrl === marker.id}
            />
          ))}
        {programsStudent.map(program => renderMarker(program, '/images/map-student.svg'))}
        {programsSenior.map(program => renderMarker(program, '/images/map-ovelia.svg'))}
        <MarkerClusterer averageCenter enableRetinaIcons styles={clusterStyles}>
          {clusterer =>
            programsOther.map(program => renderMarker(program, '/images/map-pin.svg', clusterer))
          }
        </MarkerClusterer>
      </GMap>
    </>
  );
}
