import type { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { AxiosResponse } from 'axios';

export function getPreferredLocationString(
  postalCode: string | undefined,
  city: string | undefined,
  lat: number | string,
  lng: number | string
) {
  return [postalCode, city ? encodeURIComponent(city) : undefined, lat, lng]
    .filter(v => typeof v !== 'undefined')
    .join(':');
}

export async function patchContactPrescriptor(
  idCrm: string | undefined,
  payload: Pick<ContactPrescriptorType, 'localisationPreferee'>
) {
  if (idCrm) {
    return axiosVI3PInstance
      .patch<any, AxiosResponse<ContactPrescriptorType>>(`contactsPrescripteurs/${idCrm}`, payload)
      .then(response => response.data);
  }
  return Promise.reject(new Error('No Contact Prescriptor CRM ID provided'));
}
