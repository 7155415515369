import React from 'react';
import classnames from 'classnames';

import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { COLORS } from 'settings/ui';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import textFieldStyles from '../TextField/TextField.module.scss';
import styles from './DateField.module.scss';

const materialTheme = createMuiTheme({
  overrides: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: COLORS.GREYS.PURE_GREY,
        fontFamily: 'inherit',
        fontSize: '12px',
        fontWeight: '500',
        textTransform: 'uppercase',
      },
      daysHeader: {
        borderBottom: '1px solid #F3F3F3',
        maxHeight: 'none',
        padding: '10px 0',
      },
      transitionContainer: {
        color: COLORS.PRIMARY.BLUE_DARK,
        padding: '10px 0 0',
        textTransform: 'capitalize',
      },
    },
    MuiPickersDay: {
      current: {
        color: '#50E3C2',
      },
      day: {
        color: COLORS.PRIMARY.BLUE_DARK,
        height: '25px',
        margin: '5px 7px',
        width: '25px',
      },
      daySelected: {
        color: COLORS.GREYS.PURE_WHITE,
        backgroundColor: COLORS.PRIMARY.BLUE_DARK,
      },
      hidden: {
        color: COLORS.GREYS.GREY,
        opacity: 1,
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'inherit',
        fontSize: '16px',
      },
      body2: {
        fontFamily: 'inherit',
        fontSize: '14px',
      },
    },
    MuiPopover: {
      paper: {
        border: `1px solid ${COLORS.GREYS.GREY}`,
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '6px',
      },
    },
  },
});

class LocalizedUtils extends DateFnsUtils {
  // eslint-disable-next-line class-methods-use-this
  getDatePickerHeaderText(date) {
    return format(date, 'd MMMM', { locale: this.locale });
  }
}

export default function DateField({
  disabled,
  disableFuture,
  enableToolbar,
  error,
  helperText,
  id,
  label,
  maxDate,
  minDate,
  name,
  notchedOutlineInvisible,
  onChange,
  required,
  value,
  readOnly,
  ...inputProps
}: DateFieldType) {
  const handleChange = (value: Date) => {
    if (typeof onChange === 'function') {
      onChange({ target: { id, value } });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          autoOk
          animateYearScrolling
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          classes={{
            root: textFieldStyles.root,
          }}
          disabled={disabled}
          disableFuture={disableFuture}
          disableToolbar={!enableToolbar}
          error={error}
          format="dd/MM/yyyy"
          fullWidth
          helperText={helperText}
          id={id}
          inputVariant="outlined"
          keyboardIcon={
            <Icon
              className={classnames(textFieldStyles.endAdornmentIcon, styles.openIcon)}
              icon={disabled ? 'cancel' : 'calendar'}
            />
          }
          leftArrowIcon={<Icon className={styles.prevMonth} icon="arrow-left" />}
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          name={name}
          onChange={handleChange}
          rightArrowIcon={<Icon className={styles.nextMonth} icon="arrow-left" />}
          value={value}
          required={required}
          variant="inline"
          FormHelperTextProps={{
            classes: {
              error: textFieldStyles.helperTextError,
              root: textFieldStyles.helperTextRoot,
            },
          }}
          InputProps={{
            readOnly,
            classes: {
              adornedEnd: textFieldStyles.inputAdornedEnd,
              disabled: textFieldStyles.inputDisabled,
              error: textFieldStyles.inputError,
              input: textFieldStyles.input,
              focused: textFieldStyles.inputFocused,
              notchedOutline: notchedOutlineInvisible
                ? textFieldStyles.notchedOutlineInvisible
                : textFieldStyles.notchedOutline,
              root: classnames('toto', textFieldStyles.inputRoot, textFieldStyles.inputRootDate),
            },
          }}
          InputLabelProps={{
            classes: {
              focused: textFieldStyles.labelFocused,
              root: textFieldStyles.labelRoot,
            },
          }}
          PopoverProps={{
            anchorOrigin: { horizontal: 'left', vertical: 58 },
            transformOrigin: { horizontal: 'left', vertical: 'top' },
            classes: { root: styles.popoverRoot },
          }}
          {...inputProps}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export type DateFieldType = {
  disabled?: boolean;
  disableFuture?: boolean;
  enableToolbar?: boolean;
  error?: boolean;
  helperText?: string;
  id: string;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  notchedOutlineInvisible?: boolean;
  onChange: ({ target: { id: string, value: Date } }) => void;
  required?: boolean;
  value: string | Date;
  readOnly?: boolean;
};

DateField.defaultProps = {
  disabled: false,
  disableFuture: false,
  readOnly: false,
  enableToolbar: false,
  error: false,
  helperText: undefined,
  label: '',
  maxDate: undefined,
  minDate: undefined,
  notchedOutlineInvisible: false,
  required: false,
  value: undefined,
};
