import React, { useContext, useEffect, useState } from 'react';

import { isTokenExpired } from 'services';
import { getLoginUrlWithState } from 'services/url';

import userContext from 'modules/App/Contexts/userContext';

const REFRESH_INTERVAL_MS = 10000; // 10 seconds
const REFRESH_THRESHOLD_MS = 300000; // 5 minutes

export default function SilentRefresh({ enableTimer = false }: SilentRefreshType) {
  const { userCrm } = useContext(userContext);
  const [expiring, setExpiring] = useState(false);

  useEffect(() => {
    function checkToken() {
      if (isTokenExpired(userCrm, 0)) {
        window.location.href = getLoginUrlWithState();
        setExpiring(false);
      } else if (isTokenExpired(userCrm, REFRESH_THRESHOLD_MS)) {
        setExpiring(true);
      } else {
        setExpiring(false);
      }
    }

    checkToken();
    if (enableTimer) {
      const timer = setInterval(checkToken, REFRESH_INTERVAL_MS); // clearing interval
      return () => clearInterval(timer);
    }
    return undefined;
  }, [enableTimer, userCrm]);

  if (expiring) {
    return (
      <iframe
        title="Silent Refresh"
        src={window.vinci.REACT_APP_SILENT_REFRESH_URL}
        style={{
          border: '0px',
          top: '0px',
          left: '0px',
          height: '0px',
          width: '0px',
          visibility: 'hidden',
        }}
      />
    );
  }

  return null;
}

export type SilentRefreshType = {
  enableTimer?: boolean;
};
