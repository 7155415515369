import React, { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import styles from './CustomScrollbar.module.scss';

interface CustomScrollbarProps extends ComponentProps<typeof OverlayScrollbarsComponent> {
  className?: string;
}

export const CustomScrollbar = forwardRef<OverlayScrollbarsComponent, CustomScrollbarProps>(
  ({ children, className, options = undefined, ...otherProps }, ref) => {
    return (
      <div {...otherProps} className={classnames(className, styles.root)}>
        <OverlayScrollbarsComponent ref={ref} options={options}>
          {children}
        </OverlayScrollbarsComponent>
      </div>
    );
  }
);
