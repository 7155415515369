import React from 'react';
import classnames from 'classnames';

import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
} from '@material-ui/core';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './SelectField.module.scss';

export default function SelectField({
  disabled = false,
  error = false,
  helperText,
  id,
  label,
  noEmptyOption,
  onBlur,
  onChange,
  onFocus,
  options,
  required = false,
  value,
  ...selectProps
}: SelectFieldType) {
  const labelId = `${id}-label`;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (typeof onChange === 'function') {
      onChange(event.target.value);
    }
  };

  return (
    <MuiFormControl
      classes={{
        root: classnames(styles.root, {
          [styles.disabled]: disabled,
          [styles.error]: error,
        }),
      }}
      fullWidth
      variant="outlined"
    >
      <MuiInputLabel
        classes={{
          error: styles.labelError,
          focused: styles.labelFocused,
          root: styles.labelRoot,
        }}
        error={error}
        disabled={disabled}
        id={labelId}
        required={required}
      >
        {label}
      </MuiInputLabel>
      <MuiSelect
        classes={{
          disabled: styles.selectDisabled,
          root: classnames(styles.selectRoot, {
            [styles.selectError]: error,
          }),
        }}
        disabled={disabled}
        id={id}
        IconComponent={() => <Icon className={styles.icon} icon="chevron-down" />}
        labelId={labelId}
        MenuProps={{
          classes: {
            list: styles.listRoot,
          },
        }}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        value={value}
        {...selectProps}
      >
        {!noEmptyOption && (
          <MuiMenuItem
            classes={{
              root: classnames(styles.menuItemRoot, styles.menuItemEmpty),
            }}
            value=""
          >
            {label}
          </MuiMenuItem>
        )}
        {options.map(({ value, key }) => (
          <MuiMenuItem
            data-test-idz={key}
            classes={{
              root: styles.menuItemRoot,
            }}
            key={key}
            value={key}
          >
            {value}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <MuiFormHelperText
          classes={{
            error: styles.helperTextError,
            root: styles.helperTextRoot,
          }}
          error={error}
        >
          {helperText}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
}

export type SelectFieldType = {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id: string;
  label?: string;
  noEmptyOption?: boolean;
  onBlur?: React.FocusEventHandler;
  onChange?: (value: string) => void;
  onFocus?: React.FocusEventHandler;
  options: {
    key: string | number;
    value: string;
  }[];
  required?: boolean;
  value: string;
};
