import React, { useEffect, useState } from 'react';
import type { RefObject } from 'react';
import classnames from 'classnames';

import styles from './HeaderGuest.module.scss';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface HeaderGuestProps {
  /** The header's Buttons panel is shown or hidden depending on the scroll location of this ref's value, which is a DOM element of the page */
  refElement?: RefObject<HTMLElement>;
}

export default function HeaderGuest({ refElement }: HeaderGuestProps) {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    const disconnectElement = refElement?.current;
    if (disconnectElement) {
      const handleScroll = () => {
        const loginBottom = disconnectElement.getBoundingClientRect().bottom;
        if (shown && loginBottom > 0) {
          setShown(false);
        } else if (!shown && loginBottom <= 0) {
          setShown(true);
        }
      };
      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [refElement, shown]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.root} id="guest-header">
        <a className={styles.logo} href="/">
          <picture>
            <source srcSet="/images/logo_vip_color.png" media="(max-width: 991px)" />
            <img
              src="/images/logo_vi3p_color.png"
              alt="Logo Vinci Patrimoine"
              width={976}
              height={201}
            />
          </picture>
        </a>

        <div className={classnames(styles.panel, { [styles.open]: shown })}>
          <div className={styles.links}>
            <a className={classnames(styles.link, 'btn-primary')} href={LOGIN_URL}>
              Se connecter
            </a>
            <a
              className={classnames(styles.link, styles.reverse, 'btn-primary', 'btn-reverse')}
              href={SIGNUP_URL}
            >
              Devenir partenaire
            </a>
          </div>
        </div>

        <img
          src="/images/new_images/logo_vinci.svg"
          alt="Logo vinci"
          className={styles.vinci}
          width="120"
          height="44"
        />
      </header>
    </div>
  );
}
