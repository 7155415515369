import React from 'react';
import classnames from 'classnames';
import { Dialog as MuiDialog } from '@material-ui/core';

import { LABEL_LOAD_PROBLEM_ERROR_RELOAD_LABEL, LABEL_RELOAD_PAGE } from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './LotTableErrorModal.module.scss';

export default function LotTableErrorModal({
  additionalTitle = undefined,
  disablePortal = true,
  paperClassName = undefined,
  scrollPaperClassName = undefined,
  onClose,
  open,
  subtitle = undefined,
  title = undefined,
  withCloseBtn = false,
  ...DialogProps
}: LotTableErrorModalType) {
  return (
    <MuiDialog
      disablePortal={disablePortal}
      disableScrollLock
      hideBackdrop
      open={open}
      onClose={onClose}
      classes={{
        root: classnames(styles.dialogRoot, styles.selectorWeight),
        scrollPaper: classnames(
          styles.dialogScrollPaper,
          styles.selectorWeight,
          scrollPaperClassName
        ),
        paper: classnames(styles.dialogPaper, styles.selectorWeight, paperClassName),
      }}
      {...DialogProps}
    >
      <button type="button" className={styles.closeButton} onClick={onClose}>
        <Icon icon="cross" />
      </button>
      <div className={classnames(styles.container, { [styles.reload]: withCloseBtn })}>
        {title && <div className={styles.title}>{title}</div>}
        {withCloseBtn && (
          <div className={styles.reloadLabel}>
            {LABEL_LOAD_PROBLEM_ERROR_RELOAD_LABEL}{' '}
            <button type="button" onClick={onClose} className={styles.reloadButton}>
              {LABEL_RELOAD_PAGE}
            </button>
          </div>
        )}
        {additionalTitle && <div className={styles.additionalTitle}>{additionalTitle}</div>}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
    </MuiDialog>
  );
}

export type LotTableErrorModalType = {
  additionalTitle?: string;
  disablePortal?: boolean;
  onClose?: React.MouseEventHandler;
  open: boolean;
  paperClassName?: string;
  scrollPaperClassName?: string;
  subtitle?: string;
  title?: string;
  withCloseBtn?: boolean;
  [x: string]: any;
};
