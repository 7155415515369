export const KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER = 'primoAccedant';
export const KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR = 'investisseur';
export const KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE = 'residencePrincipale';
export const KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE = 'residenceSecondaire';

export const KEY_VAT_REDUCED_PRICE = 'reducedPrice';
export const KEY_VAT_NORMAL_PRICE = 'normalPrice';

export const FIELDSET_PROSPECT = 'acquereur';
export const FIELDSET_CO_PROSPECT = 'co-acquereur';

export const FIELD_PROSPECT_ADDRESS = 'field-address';
export const FIELD_PROSPECT_BIRTH_DATE = 'field-date-of-birth';
export const FIELD_PROSPECT_BIRTH_PLACE = 'field-place-of-birth';
export const FIELD_PROSPECT_CITY = 'field-city';
export const FIELD_PROSPECT_CIVILITY = 'field-civility';
export const FIELD_PROSPECT_COUNTRY = 'field-country';
export const FIELD_PROSPECT_DESTINATION = 'field-purchase-destination';
export const FIELD_PROSPECT_EMAIL = 'field-email';
export const FIELD_PROSPECT_FAMILY_STATUS = 'field-family-status';
export const FIELD_PROSPECT_FIRSTNAME = 'field-firstname';
export const FIELD_PROSPECT_LASTNAME = 'field-lastname';
export const FIELD_PROSPECT_NATIONALITY = 'field-nationality';
export const FIELD_PROSPECT_PHONE = 'field-phone-number';
export const FIELD_PROSPECT_SITUATION = 'field-current-situation';
export const FIELD_PROSPECT_ZIPCODE = 'field-zipcode';

export const FIELD_FISCALITY = 'field-fiscality';
export const FIELD_LOT_PRICE = 'field-lot-price';
export const FIELD_PTZ = 'field-ptz';

export const FIELD_CO_PROSPECT = 'field-coPrescriber';

export const FIELD_CREDIT_AMOUNT = 'field-total-loan';
export const FIELD_CREDIT_CONTRIBUTION = 'field-personal-contribution';
export const FIELD_CREDIT_FUNDING = 'field-funding';
export const FIELD_DEPOSIT_AMOUNT = 'field-deposit-amount';
export const FIELD_DEPOSIT_CHECK_DATE = 'field-remittance-bank-check-date';
export const FIELD_DEPOSIT_MODE = 'field-payment-method';
export const FIELD_FINANCIAL_CONDITIONS = 'field-special-conditions-documents';
export const FIELD_FINANCIAL_DOCUMENTS = 'field-additional-documents';
export const FIELD_FINANCIAL_DUTY_FREE = 'field-duty-free';
export const FIELD_FINANCIAL_FEES = 'field-percentage-fees';
export const FIELD_FINANCIAL_NOTE = 'field-descriptive-note';
export const FIELD_FINANCIAL_SIGNATURE = 'field-signature-date';
export const FIELD_FINANCIAL_KITCHEN = 'field-financial-kitchen';

export const FIELD_LOT_FISCALITY_HIDDEN = 'field-lot-fiscality-hidden';
