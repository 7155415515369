import React from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';

import Footer from './components/Footer';
import HeadElement from './components/HeadElement';
import HeaderGuest from './components/HeaderGuest/HeaderGuest';

export default function GuestLayout({
  children,
  refElement,
}: PropsWithChildren<ComponentProps<typeof HeaderGuest>>) {
  return (
    <>
      <HeadElement />
      <HeaderGuest refElement={refElement} />
      <div className="page-layout">
        <main role="main">{children}</main>
      </div>
      <Footer />
    </>
  );
}
