/**
 * Takes an iterable of promises as input and returns a single Promise. This returned promise fulfills when all of the
 * input's promises settle (including when an empty iterable is passed), with an array of objects that describe the
 * outcome of each fulfilled promise (rejected promises are excluded).
 */
export async function promiseAllFullfilled<T>(promises: Promise<T>[]) {
  const results = await Promise.allSettled(promises);
  const fullfilledResults = results.filter(
    ({ status }) => status === 'fulfilled'
  ) as PromiseFulfilledResult<T>[];
  return fullfilledResults.map(({ value }) => value);
}
