import React, { useContext, useEffect, useState } from 'react';

import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';

import { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';

import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import programLotContext from 'modules/App/Contexts/programLotContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import LotRowTablette from 'commonUi/ListLotV2/LotRow/LotRowTablette';
import LotTableContainer from 'commonUi/ListLotV2/LotTable/LotTableContainer';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';
import { ProgrammeTabletteDetailsCpn } from 'modules/DetailsLotsAndActivity/ProgrammeTabletteDetailsCpn';
import PdfProvider from 'modules/App/Providers/PdfProvider';

interface LotTableTabletteProps {
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  lots?: LotJson[];
  selectedLots?: { lotNumber: string; programRef: string }[];
  reduceMobilePanel?: () => void;
}

export default function LotTableTablette({
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lots = [],
  selectedLots = [],
  reduceMobilePanel = undefined,
}: LotTableTabletteProps) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert, userCrm } = useContext(
    userContext
  );
  const { lotNumber, programRef } = useSearch();
  const { taxes, taxesById } = useContext(TaxonomiesContext);
  const { programs } = useContext(programLotContext);

  const [currentRowOpen, setCurrentRowOpen] = useState<string>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      lots.length > 0 &&
      !lots.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const { number: currentLotNumber, ref: currentProgramRef } =
    lots?.find(lot => currentRowOpen === lot.nid) ?? {};
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentProgramRef, currentLotNumber);

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = lots?.findIndex(l => l.number === lotNumber);
      const currentOpen = activeIndex !== -1 ? lots[activeIndex].nid : undefined;
      setCurrentRowOpen(currentOpen);
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  // put current lot on top of the list
  let sortedData = lots;
  if (lots && lotNumber && programRef) {
    const lotIndex = lots.findIndex(lot => lot.number === lotNumber && lot.ref === programRef);
    if (lotIndex !== -1) {
      sortedData = [lots[lotIndex], ...lots.slice(0, lotIndex), ...lots.slice(lotIndex + 1)];
    }
  }
  const { statuses, updateStatus } = useGetLotsStatuses(
    sortedData,
    // If attribution switch is ON, the lots have already been filtered for it in `useLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );

  return (
    <LotTableContainer>
      <LotTableErrorModal
        open={loadLotError || openError}
        onClose={() => {
          setOpenError(false);
          setLoadLotError(false);
        }}
        title={LABEL_LOTS_ENABLE_ERROR_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />

      {sortedData?.map(lot => (
        <LotRowTablette
          renderShowMore={() => {
            if (!lotDetail || !programDetail) {
              return null;
            }
            return (
              <PdfProvider program={programDetail} lot={lotDetail} statuses={statuses}>
                <MobileDrawer isDrawerOpen>
                  <ProgrammeTabletteDetailsCpn
                    myBookings={myBookings}
                    myOptions={myOptions}
                    closeDrawer={() => setCurrentRowOpen(undefined)}
                    lot={lot}
                    fiscalities={taxes}
                    statuses={statuses}
                    updateStatus={updateStatus}
                    lotApiOffre={lotDetail}
                    programDatas={programDetail}
                  />
                </MobileDrawer>
              </PdfProvider>
            );
          }}
          programs={programs}
          onStatusAlertClick={toggleAlert}
          myBookings={myBookings}
          myOptions={myOptions}
          alerts={alerts}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          headings={headings}
          hideMapButton={hideMapButton}
          key={lot.nid}
          lot={lot}
          statuses={statuses}
          updateStatus={updateStatus}
          taxById={taxesById}
          reduceMobilePanel={reduceMobilePanel}
          isChecked={selectedLots.some(l => l.lotNumber === lot.number && l.programRef === lot.ref)}
          isDrawerOpen={currentRowOpen === lot.nid}
          setCurrentRowOpen={setCurrentRowOpen}
        />
      ))}
    </LotTableContainer>
  );
}
