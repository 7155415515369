import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  totem: {
    alignItems: 'flex-start',
    color: COLORS.PRIMARY.BLUE_DARK,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',
    fontWeight: 'medium',
    textTransform: 'uppercase',
  },
  totemLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '66.66%',
  },
  totemLeftLine: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0',
  },
  totemLeftLineCol: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
  },
  totemLeftLineColGroup: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  totemRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  pastilleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0',
  },
  pastillePicto: {
    marginRight: '4px',
  },
  pastille: {
    height: '25px',
    margin: '0 4px 0 0',
    width: '25px',
  },
  picto: {
    margin: '0 10px 0 0',
    width: '16px',
  },
  subInfos: {
    fontSize: '7px',
    fontWeight: 'medium',
  },
});
