export function makeYouTubeThumbnailFromId(
  videoId: string,
  format: 'mqdefault' | 'maxresdefault' | '01' | '02' | '03' = 'mqdefault'
) {
  return `https://img.youtube.com/vi/${videoId}/${format}.jpg`;
}

export const YOUTUBE_ID_REGEX = /^.*(?:(?:youtu.be\/)|(?:v\/)|(?:\/u\/\w\/)|(?:embed\/)|(?:watch\?))\??v?=?([^#&?]*).*/;
export function extractVideoIdFromUrl(videoUrl: string) {
  return YOUTUBE_ID_REGEX.exec(videoUrl)?.[1];
}
