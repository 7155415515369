import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { LABEL_NOTARY_FEES, LABEL_OFFFERED } from 'settings/labels';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

import styles from './PdfPastilleStyles';

export default function PdfPastilleNotaryFees() {
  return (
    <View style={styles.pastilleNotaryFees}>
      <View style={styles.pastilleNotaryFeesPicto}>
        <PdfSvgPicto picto="checkedCircle" />
      </View>
      <View style={styles.pastilleNotaryFeesLabel}>
        <Text>{LABEL_NOTARY_FEES}</Text>
        <Text style={styles.pastilleNotaryFeesLabelBold}>{LABEL_OFFFERED}</Text>
      </View>
    </View>
  );
}
