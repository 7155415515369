import React, { useContext, useEffect, useMemo, useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';

import { ACTIVITY_HEADING_STATUS } from 'settings/activity';
import {
  LABEL_LOTS_ENABLE_ERROR_SUBTITLE,
  LABEL_LOTS_ENABLE_ERROR_TITLE,
  LABEL_SORT_BY,
} from 'settings/labels';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';

import {
  Activity,
  ActivitySortByType,
  ActivitySortOrderType,
  ActivityType,
} from 'api/vi3pAPI/apiTypes/ActivityType';

import { getActivitiesHeadings, sortActivities } from 'services/activities';
import { getNormalizedRole } from 'services/authentication';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import DropdownList from 'commonUi/DropdownList/DropdownList';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import SortItem from 'commonUi/SortItem/SortItem';

import { ActivityDetailsMobileCpn } from 'modules/DetailsLotsAndActivity/ActivityDetailsMobileCpn';
import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';

import ActivityMobileDetail from './ActivityMobileDetail';

import styles from './ActivityTableMobile.module.scss';

interface ActivityTableMobileProps {
  activityType: ActivityType;
  apiUrls: ComponentProps<typeof ActivityMobileDetail>['apiUrls'];
  data: Activity[] | undefined;
  empty: ReactNode;
}

export default function ActivityTableMobile({
  activityType,
  apiUrls,
  data,
  empty,
}: ActivityTableMobileProps) {
  const { activityId } = useSearch();
  const { taxes: fiscalities } = useContext(TaxonomiesContext);

  const [openActivityId, setOpenActivityId] = useState(activityId);
  const [sortBy, setSortBy] = useState<ActivitySortByType>(ACTIVITY_HEADING_STATUS);
  const [sortOrder, setSortOrder] = useState<ActivitySortOrderType>(SORT_ORDER_ASC);
  const [loadActivityError, setLoadActivityError] = useState<boolean>(
    typeof activityId !== 'undefined' &&
      !!data &&
      data.length > 0 &&
      !data.some(activity => activity.field_idcrm_ac.toLowerCase() === activityId.toLowerCase())
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const loadedActivity = data?.find(
    activity => openActivityId?.toLowerCase() === activity.field_idcrm_ac.toLowerCase()
  );
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(
    loadedActivity?.field_referenceprogramme_ac,
    loadedActivity?.field_numerolot_ac
  );

  const userType = getNormalizedRole();
  const headings = getActivitiesHeadings(activityType, userType);

  const sortedData = useMemo(() => sortActivities(data, sortBy, sortOrder), [
    data,
    sortBy,
    sortOrder,
  ]);
  const isDrawerOpen =
    !isDetailsLoading &&
    !isError &&
    !!openActivityId &&
    !!loadedActivity &&
    openActivityId?.toLowerCase() === loadedActivity.field_idcrm_ac.toLowerCase();

  function reorder(heading: ActivitySortByType) {
    if (heading === sortBy) {
      if (sortOrder === SORT_ORDER_ASC) {
        setSortOrder(SORT_ORDER_DESC);
      } else {
        setSortOrder(SORT_ORDER_ASC);
      }
    } else {
      setSortBy(heading);
      setSortOrder(SORT_ORDER_ASC);
    }
  }

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  if (sortedData.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.noResult}>{empty}</div>
      </div>
    );
  }
  if (isDrawerOpen) {
    return (
      <MobileDrawer isDrawerOpen openFrom="right">
        <ActivityDetailsMobileCpn
          activity={loadedActivity as NonNullable<typeof loadedActivity>} // `isDrawerOpen=true guarantees that this is defined
          closeDrawer={() => setOpenActivityId(undefined)}
          fiscalities={fiscalities}
          lotFromApi={lotDetail}
          programFromApi={programDetail}
        />
      </MobileDrawer>
    );
  }

  return (
    <div>
      <DropdownList id="sort-activities" className={styles.sorter} label={LABEL_SORT_BY}>
        {headings
          .filter(({ field }) => field)
          .map(({ field, title }) => (
            <SortItem
              onClick={reorder}
              sortBy={sortBy}
              sortOrder={sortOrder}
              title={title}
              type={field as string} // entries with `undefined` `field` value have been filtered out
            />
          ))}
      </DropdownList>

      {sortedData.map(activity => (
        <ActivityMobileDetail
          key={activity.field_idcrm_ac}
          activity={activity}
          apiUrls={apiUrls}
          setOpenActivityId={setOpenActivityId}
        />
      ))}

      <LotTableErrorModal
        open={loadActivityError || openError}
        onClose={() => {
          setOpenError(false);
          setLoadActivityError(false);
        }}
        title={LABEL_LOTS_ENABLE_ERROR_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />
    </div>
  );
}
