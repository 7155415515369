import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import { replaceTokens } from 'services/formatter';
import {
  LABEL_DISPLAY_CARD_PROGRAM,
  LABEL_DOWNLOAD_BOOKLET,
  LABEL_PROGRAM_DESCRIPTION_TITLE,
  LABEL_PROGRAM_INFO_TITLE,
  LABEL_READ_LESS,
  LABEL_READ_MORE,
  LABEL_STRONG_POINTS_TITLE,
} from 'settings/labels';
import {
  PROGRAM_ABOUT_LINE_LIMIT_DESKTOP,
  PROGRAM_ABOUT_LINE_LIMIT_MOBILE,
} from 'settings/programs';
import { TOKEN_NAME } from 'settings/token';

import programPdfContext from 'modules/App/Contexts/programPdfContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { useBooklet } from 'hooks/useBooklet';

import { ProgramTypeV2, isProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import StrongPoints from 'commonUi/StrongPoints/StrongPoints';
import Button from 'commonUi/Button/Button';

import ProgramMedias from '../ProgramMedias/ProgramMedias';

import ProgramCertifications from './ProgramCertifications';
import ProgramInfo from './ProgramInfo';

import styles from './ProgramDescription.module.scss';

export interface ProgramDescriptionType {
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramDescription({ className, program }: ProgramDescriptionType) {
  const { isResponsive } = useContext(ResponsiveContext);
  const textRef = useRef<HTMLDivElement>(null);

  const { generate, isGenerating } = useContext(programPdfContext);

  const [isShortened, setIsShortened] = useState<boolean | null>(null);

  const { descriptions, nomCommercial: name } = program;
  const { descriptionLongue } = descriptions;
  const text = ReactHtmlParser(descriptionLongue);

  const { booklet } = useBooklet(
    isProgramListType(program) ? program?.ref : program?.referenceProgramme,
    true
  );

  useEffect(() => {
    const lineLimit = isResponsive
      ? PROGRAM_ABOUT_LINE_LIMIT_MOBILE
      : PROGRAM_ABOUT_LINE_LIMIT_DESKTOP;
    // Text is shortened in CSS only if it's too long
    if (
      textRef.current &&
      textRef.current.clientHeight >
        Math.round(parseFloat(getComputedStyle(textRef.current).lineHeight) * lineLimit)
    ) {
      setIsShortened(true);
    }
  }, [isResponsive]);

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.about}>
        <div className={styles.aboutCol}>
          <h3 className={styles.title}>
            {replaceTokens(LABEL_PROGRAM_DESCRIPTION_TITLE, { [TOKEN_NAME]: name })}
          </h3>

          <div
            ref={textRef}
            id="program-about-text"
            className={classnames(styles.text, { [styles.shortened]: isShortened })}
          >
            {text}
          </div>
          {isShortened !== null && (
            <button
              type="button"
              className={styles.readMore}
              onClick={() => setIsShortened(prev => !prev)}
              aria-controls="program-about-text"
              aria-expanded={!isShortened}
            >
              {isShortened ? LABEL_READ_MORE : LABEL_READ_LESS}
            </button>
          )}
          <div className={styles.actionButtons}>
            <div className={styles.actionButton}>
              <Button
                className={styles.link}
                variant="contained"
                color="primary"
                disabled={!booklet?.url || booklet.url === '#'}
                href={booklet.url}
                icon="download"
                iconClass={styles.icon}
                target="_blank"
              >
                {LABEL_DOWNLOAD_BOOKLET}
              </Button>
            </div>
            <div className={styles.actionButton}>
              <Button
                color="primary"
                icon="display"
                variant="contained"
                loading={isGenerating[program.referenceProgramme]}
                onClick={ev => {
                  ev.preventDefault();
                  generate(program.referenceProgramme).then(url => window.open(url, '_blank'));
                }}
              >
                {LABEL_DISPLAY_CARD_PROGRAM}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.aboutCol}>
          <ProgramMedias program={program} />
        </div>
      </div>

      <div className={styles.infos}>
        <ProgramInfo className={styles.info} program={program} title={LABEL_PROGRAM_INFO_TITLE} />
      </div>

      <StrongPoints
        className={styles.strongPoints}
        strongPoints={program.pointsForts}
        title={LABEL_STRONG_POINTS_TITLE}
      />

      <ProgramCertifications
        className={styles.certifications}
        certifications={program.certifications}
      />
    </div>
  );
}
