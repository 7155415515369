import React, { useContext } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { getPromotionStylesFromSettings } from 'services/promotions';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import SliderInline from 'commonUi/SliderInline/SliderInline';

import ProgramCardThumbnailLabel from './ProgramCardThumbnailLabel';
import ProgramCardThumbnailPictos from './ProgramCardThumbnailPictos';

import styles from './ProgramCardThumbnail.module.scss';

interface ProgramCardThumbnailProps {
  className?: string;
  isHover?: boolean;
  program: ProgramListType;
}

export default function ProgramCardThumbnail({
  className,
  isHover = false,
  program,
}: ProgramCardThumbnailProps) {
  const { settings } = useContext(SettingsContext);

  const programPath = `/programme/page/${program.ref}/prix`;
  const promotionLabel = program.promotions?.length
    ? Object.values(program.promotions[0])[0]
    : undefined;

  return (
    <div className={classnames(className, styles.root, { [styles.hover]: isHover })}>
      {promotionLabel && (
        <div className={styles.promo} style={getPromotionStylesFromSettings(settings)}>
          <FieldRte className={styles.text} html={promotionLabel} />
        </div>
      )}
      <SliderInline
        disableSwipe
        lazyLoadImages
        renderArrows
        renderDots={false}
        classes={{
          root: styles.images,
          slider: styles.slider,
          image: styles.img,
          dots: styles.dots,
          dot: styles.dot,
          dotCurrent: styles.current,
        }}
        images={program.image.length ? program.image : ['/images/placeholders/360x200.png']}
        renderImage={imageElement => (
          <ReactRouterLink className={styles.link} to={programPath} target="_blank">
            {imageElement}
          </ReactRouterLink>
        )}
      />
      <ProgramCardThumbnailLabel className={styles.label} program={program} />
      <ProgramCardThumbnailPictos className={styles.pictos} program={program} />
    </div>
  );
}
