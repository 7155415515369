import React from 'react';
import classnames from 'classnames';

import { MediaType } from 'types/medias';
import { Color } from 'types/ui';

import { colorToString } from 'services/ui';

import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './IconText.module.scss';

export const ICON_TEXT_VARIANT_DEFAULT = 'standard';
export const ICON_TEXT_VARIANT_COLUMNS = 'columns';
export const ICON_TEXT_VARIANT_VERTICAL = 'vertical';

interface IconTextProps {
  className?: string;
  icon: MediaType[];
  iconSize?: number;
  reverse?: boolean;
  text?: string;
  title?: string;
  titleColor: Color;
  variant:
    | typeof ICON_TEXT_VARIANT_DEFAULT
    | typeof ICON_TEXT_VARIANT_COLUMNS
    | typeof ICON_TEXT_VARIANT_VERTICAL;
}

export default function IconText({
  className,
  icon,
  iconSize = 40,
  reverse = false,
  text,
  title,
  titleColor,
  variant = ICON_TEXT_VARIANT_DEFAULT,
}: IconTextProps) {
  return (
    <div
      className={classnames(className, styles.root, {
        [styles.reverse]: reverse,
        [styles.standard]: variant === ICON_TEXT_VARIANT_DEFAULT,
        [styles.columns]: variant === ICON_TEXT_VARIANT_COLUMNS,
        [styles.vertical]: variant === ICON_TEXT_VARIANT_VERTICAL,
      })}
    >
      {title && (
        <h3 className={styles.title} style={{ color: colorToString(titleColor) }}>
          {title}
        </h3>
      )}
      <div className={styles.content}>
        <FieldImage
          className={styles.icon}
          media={icon}
          style={{
            width: typeof iconSize === 'number' && iconSize ? `${iconSize / 10}rem` : undefined,
          }}
        />
        {text && <FieldRte className={styles.text} html={text} />}
      </div>
    </div>
  );
}
