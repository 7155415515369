import { LOTS_IDS_PARAM_LENGTH } from 'settings/lots';

import type { ProgramsDocumentsType } from './apiTypes/Documents';
import type { GeoSearch } from './apiTypes/GeoSearch';
import type { LotTypeV2 } from './apiTypes/LotType';
import type { ProgramTypeV2 } from './apiTypes/Program';
import type { Status } from './apiTypes/Statuses';

import { axiosViOffreInstance } from './axiosInstance';

export async function getLocality(url: string | undefined | null, search: string) {
  if (!url) {
    return undefined;
  }
  const response = await axiosViOffreInstance
    .get<GeoSearch>(url, {
      params: {
        q: search,
        startWith: true,
      },
    })
    .then(response => response);
  const { data } = response || {};
  return data;
}

export async function getLotsStatuses(
  url: string,
  ids: string | string[],
  attributionToken?: string
) {
  if (ids?.length > LOTS_IDS_PARAM_LENGTH) {
    return {};
  }
  const response = await axiosViOffreInstance.get<Record<number, Status>>(url, {
    params: {
      lots: ids,
      ...(attributionToken ? { listeAttribution: attributionToken } : {}),
    },
  });
  return response?.data ?? {};
}

export async function getProgramByRef(programRef: string) {
  const response = await axiosViOffreInstance
    .get<ProgramTypeV2>(`programmes/${programRef}`)
    .then(response => response);
  const { data } = response || {};
  return data;
}

export async function getLot(programRef: string, lotNumber: string) {
  const response = await axiosViOffreInstance
    .get<LotTypeV2>(`programmes/${programRef}/lots/${lotNumber}?site=vi3p`)
    .then(response => response);
  const { data } = response || {};
  return data;
}

export async function getDocumentsPrograms(url: string) {
  const response = await axiosViOffreInstance
    .get<ProgramsDocumentsType>(url)
    .then(response => response);
  const { data } = response || {};
  return data;
}
