import React from 'react';
import { Font, Image, Page, View } from '@react-pdf/renderer';

import logoVIPatrimoine from 'images/logo_vi3p_color.png';
import logoVinci from 'images/new_images/logo_vinci.png';

import styles from './PdfPageLayoutStyles';

// Register font
Font.register({
  family: 'vinci_sans',
  fonts: [
    {
      src: '/static/media/vinci_sans-regular-webfont.941ead2d.woff',
    },
    {
      src: '/static/media/vinci_sans-medium-webfont.9993fac5.woff',
      fontWeight: 'medium',
    },
    {
      src: '/static/media/vinci_sans-light-italic.6a88cb1d.woff',
      fontWeight: 'light',
      fontStyle: 'italic',
    },
  ],
});

// Disabling hyphenation
Font.registerHyphenationCallback(word => [word]);

export default function PdfPageLayout({ children }: PDFLayoutType) {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={logoVIPatrimoine} style={styles.logoVIP} />
        <Image src={logoVinci} style={styles.logoVinci} />
      </View>
      <View style={styles.content}>{children}</View>
    </Page>
  );
}

export type PDFLayoutType = {
  children?: React.ReactNode;
};
