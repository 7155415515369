import { createContext } from 'react';

import type { LotExport, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

interface ProgramLotsExportsContext {
  lots: LotExport[];
  programs: ProgramListType[];
  getLot(lotNumber: string, programRef: string): LotJson | undefined;
  getLots(callback: (lot: LotExport) => boolean): LotJson[];
  getProgram(programRef: string): ProgramListType | undefined;
  getPrograms(callback: (program: ProgramListType) => boolean): ProgramListType[];
  isLoading: boolean;
  isLotsLoading: boolean;
  isProgramsLoading: boolean;
}

export default createContext<ProgramLotsExportsContext>({
  lots: [],
  programs: [],
  getLot: () => undefined,
  getLots: () => [],
  getProgram: () => undefined,
  getPrograms: () => [],
  isLoading: false,
  isLotsLoading: false,
  isProgramsLoading: false,
});
