import React from 'react';
import classnames from 'classnames';

import { MediaType } from 'types/medias';
import { Color } from 'types/ui';

import { colorToString } from 'services/ui';

import IconText, {
  ICON_TEXT_VARIANT_COLUMNS,
  ICON_TEXT_VARIANT_DEFAULT,
  ICON_TEXT_VARIANT_VERTICAL,
} from 'commonUi/IconText/IconText';

import styles from './ParagraphIconText.module.scss';

export default function ParagraphIconText({ containerClassName = undefined, paragraph }) {
  const {
    background_color: bgColor,
    display_mode: variant,
    icon_text_items: iconTexts,
    picto_size: iconSize,
    text_color: textColor,
  } = paragraph;

  if (!iconTexts?.filter(({ image: img, riche_texte: txt }) => txt || img)?.length) {
    return null;
  }

  return (
    <div className={containerClassName} style={{ background: colorToString(bgColor) }}>
      <div
        className={classnames(styles.root, {
          [styles.standard]: !variant || variant === ICON_TEXT_VARIANT_DEFAULT,
          [styles.columns]: variant === ICON_TEXT_VARIANT_COLUMNS,
          [styles.vertical]: variant === ICON_TEXT_VARIANT_VERTICAL,
        })}
        style={{ background: bgColor ? colorToString(bgColor) : undefined }}
      >
        {iconTexts.map(({ id, image: icon, riche_texte: text, title }) => (
          <IconText
            key={id}
            className={styles.child}
            icon={icon}
            iconSize={Number(iconSize)}
            text={text}
            title={title}
            titleColor={textColor}
            variant={variant}
          />
        ))}
      </div>
    </div>
  );
}

export type ParagraphIconTextProps = {
  containerClassName?: string;
  paragraph: {
    field_background_color: Color;
    field_display_mode: typeof ICON_TEXT_VARIANT_COLUMNS | typeof ICON_TEXT_VARIANT_DEFAULT;
    field_icon_text_items: {
      id: string;
      field_image: MediaType[];
      field_rich_text: string;
      field_title: string;
    }[];
    field_picto_size: string;
    field_text_color: Color;
  };
};
