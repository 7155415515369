import React, { useContext } from 'react';
import classnames from 'classnames';

import { getPromotionStylesFromSettings } from 'services/promotions';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { LABEL_PROMO } from 'settings/labels';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './PromoPastille.module.scss';

interface PromoPastilleProps {
  classes?: Partial<Record<'root' | 'text', string>>;
  promotions: null | Record<string, string>[];
  vertical?: boolean;
}

export default function PromoPastille({
  classes = {},
  promotions,
  vertical = false,
}: PromoPastilleProps) {
  const { settings } = useContext(SettingsContext);

  const label = promotions?.[0] ? Object.values(promotions[0])[0] : `<p>${LABEL_PROMO}</p>`;
  return (
    <div
      className={classnames(classes.root, styles.root, { [styles.vertical]: vertical })}
      style={getPromotionStylesFromSettings(settings)}
    >
      <FieldRte className={classnames(classes.text, styles.text)} html={label} />
    </div>
  );
}
