import {
  PROGRAM_HOUSING_TYPE_COLLECTIVE,
  PROGRAM_HOUSING_TYPE_INDIVIDUAL,
  PROGRAM_HOUSING_TYPE_SENIOR,
  PROGRAM_HOUSING_TYPE_STUDENT,
  PROGRAM_PHASE_ARCHIVE,
  PROGRAM_PHASE_LAUNCH,
  PROGRAM_PHASE_MARKETING,
  PROGRAM_PHASE_PARKING,
  PROGRAM_PHASE_PREVIEW,
} from 'settings/programs';
import {
  SORT_ORDER_ASC,
  SORT_ORDER_DESC,
  SORT_PROGRAM_BY_DELIVERY,
  SORT_PROGRAM_BY_LOCALITY,
  SORT_PROGRAM_BY_POSITION,
  SORT_PROGRAM_BY_SEARCH,
  SORT_PROGRAM_BY_TAX,
} from 'settings/search';

import type { Promotion } from 'types/promotions';

import type { StrongPointType } from 'commonUi/StrongPoints/StrongPoints';
import type { DocumentTypeV2 } from './Documents';
import type { ReportLight } from './Reports';
import type { StatusLabel } from './Statuses';
import type { TaxType } from './Taxonomies';

export interface ProgramFreeBlock {
  background_color: string;
  cta?: {
    title: string;
    texte_lien?: string;
    uri: string;
    document?: string;
  };
  display: 'img_right' | 'img_left';
  image?: string;
  media?: string;
  mentions_legales: string;
  text_wysiwyg: string;
  title_wysiwyg: string;
}

type ProgramType =
  | typeof PROGRAM_HOUSING_TYPE_COLLECTIVE
  | typeof PROGRAM_HOUSING_TYPE_INDIVIDUAL
  | typeof PROGRAM_HOUSING_TYPE_STUDENT
  | typeof PROGRAM_HOUSING_TYPE_SENIOR;

/* eslint-disable prettier/prettier */
export type ProgramExport = [
  nid: string,
  latitude: number,
  longitude: number,
  name: string,
  city: string,
  postalCode: string,
  department: string,
  region: string,
  min: Record<string, string>,
  max: Record<string, string>,
  lotNumber: string,
  kindTIDs: string[],
  kindLabels: Record<string, string[]>,
  deliveryDate: string | null,
  taxTIDs: string[],
  image: string | string[],
  price: string | null,
  profitability: string | null,
  photos: string[],
  surface: [min: string, max: string] | null,
  annexTIDs: string[],
  actabilityDate: string | null,
  others: string[],
  eligibility: '1' | 0,
  reference: string,
  attribution: string[],
  offers: string[],
  housingType: ProgramType,
  cuisineEquipee: boolean,
  venteInterne: boolean,
  ventePartenaireVI2P: boolean,
  cleanCity: string,
  url3d: string | null,
  unknownData: '',
  promotions: null | Record<string, string>[],
  marketingDate: string | null,
  reports?: '1' | '0',
  eligibilityADB?: '1' | '0' | null,
];
/* eslint-enable prettier/prettier */

export interface ProgramListType {
  actability: Date | null;
  annexes: string[];
  attribution: string[];
  budget: { min: number; max: number };
  city: string;
  cleanCity: string;
  cuisineEquipee: boolean;
  delivery: Date | null;
  department: string;
  eligibility: boolean;
  eligibilityAdb: boolean;
  housingType: ProgramType;
  image: string[];
  kinds: string[];
  kindsDisplay: string[];
  lat: number;
  lng: number;
  lotNumber: number;
  marketing: Date | null;
  name: string;
  nid: string;
  offers: string[];
  others: string[];
  photos: string[];
  postalCode: string;
  price: number;
  profitability: number;
  ref: string;
  region: string;
  reports: boolean;
  surface: { min: number; max: number } | null;
  taxes: string[];
  venteInterne: boolean;
  ventePartenaireVi2p: boolean;
  url3d: string | null;
  promotions: null | Record<string, string>[];
}

export interface ProgramLotTypeV2 {
  reference: string;
  type: 'Autres' | 'Bureaux' | 'Caves' | 'Commerces' | 'Logements' | 'Parkings'; // Taxonmy Type de lot superieur
  hasParking: number;
  hasCave: number;
  etage: string; // Taxonomy Etage
  statut: StatusLabel; // Taxonomy Statut du lot
  surfaceHabitable: number;
  surfaceAnnexes: number;
  prixTTC: number;
  rendementLocatif: number;
  loyerMarcheHT: number;
  fiscalites: {
    dispositif:
      | 'DeficitFoncier'
      | 'Demembrement'
      | 'LMNP'
      | 'Malraux'
      | 'Pinel'
      | 'ResidenceHorsPinel'; // Taxonomy Fiscalité
  };
  plan: {
    url: string;
    properties: { alt: string };
  }[];
  referenceProgramme: string;
  nomCommercial: string;
  codePostal: string;
  ville: string;
  dateLivraisonPrevisionnelle: Date;
}

export interface ProgramTypeV2Financial {
  typeFiscalite: string;
  fiscalites: TaxType[];
  TVAalternative: boolean;
  prixMoyenM2: string;
  rentabilite: string;
  zoneFiscale: string;
  prixHorsMobilier: string;
  prixTVAReduite: string;
  prixTVANormal: string;
  prixMaitrises: boolean;
}

export interface ProgramTypeV2 {
  id: number;
  referenceProgramme: string;
  nomCommercial: string;
  type: ProgramType;
  phaseCommerciale:
    | typeof PROGRAM_PHASE_ARCHIVE
    | typeof PROGRAM_PHASE_LAUNCH
    | typeof PROGRAM_PHASE_MARKETING
    | typeof PROGRAM_PHASE_PARKING
    | typeof PROGRAM_PHASE_PREVIEW;
  nbLots: number;
  nbLotsDisponibles?: number;
  aPartirDe: string;
  thematiques: string[];
  eligibiliteCR: boolean;
  ordreDepotGarantie: string;
  informationsVinciImmobilier: {
    statutVip: string;
    gammeVip: string;
    prioriteCommerciale: number;
    agence: string;
  };
  informationsFiscalesEtFinancieres: ProgramTypeV2Financial;
  publication: {
    validePublication: boolean;
    publieSur: string[];
  };
  contacts: {
    adresse: string;
    cp: string;
    email: string;
    nom: string;
    prenom: string;
    tel: string;
    type: string;
    ville: string;
  }[];
  bureauDeVente: {
    horairesOuverture: string;
    adresse: string;
    longitude: number;
    latitude: number;
  };
  dates: {
    livraison: string;
    livraisonPrevisionnelle: string;
    actabilite: string;
    actabilitePrevisionnelle: string;
    lancementCommercial: string;
    ouvertureTMATech: string;
    fermetureTMATech: string;
    ouvertureTMAEsth: string;
    fermetureTMAEsth: string;
    ouvertureVisiteCloison: string;
    fermetureVisiteCloison: string;
  };
  descriptions: {
    descriptionLongue: string;
    descriptionCourte: string;
  };
  localisation: {
    localisation: string;
    adresse: string;
    codePostal: number;
    ville: string;
    pays: string;
    longitude: number;
    latitude: number;
  };
  documents: DocumentTypeV2[];
  lots: Record<string, { lots: ProgramLotTypeV2[] }>;
  perspectives: {
    panorama: string;
    slider: string[];
  };
  promotions: Promotion[];
  residencesGerees: {
    residenceGeree: boolean;
    nomGestionnaire?: string;
    prestations: string[];
  };
  residencesPartagees: {
    occupationPossible: boolean;
    nbSemaineOccupation?: number;
  };
  videos: string[];
  paragraphesVi3p: {
    type: { target_id: string }[];
    field_texte_simple: {
      value: string;
      format: string;
    }[];
  }[];
  pointsForts: StrongPointType[];
  repartitionsLots: string;
  certifications: {
    name: string;
    pictogramme: string | never[];
    mentionsLegales: string | never[];
  }[];
  offresServices: string[];
  nouveaute: boolean;
  packs: {
    cuisineEquipee: boolean;

    // TODO : check type here
    gestionnaireAdb: {
      descriptif: string;
      id_crm: string;
      images: any;
      mandate_name: string;
      nom: string;
      price_list_name: string;
      url: string;
      url_mandate: string;
      url_price_list: string;
    };
    eligibleAdb: boolean;
    eligibleMeuble: boolean;
  };
  aidesVente: {
    fraisNotaireOfferts: {
      venteInterne: boolean;
      ventePartenaireVI2P: boolean;
    };
  };
  maquetteOrbitale: string;
  // TODO : type correctly
  medias: any;
  video_v4: any;
  depotGarantie: {
    bicDepotGarantie: string;
    ibanDepotGarantie: string;
    modePaiement: string;
    montantDepotGarantie: string;
    nomBanqueDepotGarantie: string;
    ordreDepotGarantie: string;
  };
  content_v4: any[];
  paragraphesLibresVi3P: ProgramFreeBlock[];
  bulletin_chantier?: ReportLight;
  tva_reduite_vi3p: boolean;
}

export function isProgramTypeV2(
  program: ProgramTypeV2 | ProgramListType | undefined
): program is ProgramTypeV2 {
  return (program as ProgramTypeV2)?.referenceProgramme !== undefined;
}

export function isProgramListType(
  program: ProgramTypeV2 | ProgramListType | undefined
): program is ProgramListType {
  return (program as ProgramListType)?.ref !== undefined;
}

export type ProgramSortBy =
  | typeof SORT_PROGRAM_BY_DELIVERY
  | typeof SORT_PROGRAM_BY_LOCALITY
  | typeof SORT_PROGRAM_BY_POSITION
  | typeof SORT_PROGRAM_BY_SEARCH
  | typeof SORT_PROGRAM_BY_TAX;
export type ProgramSortOrder = typeof SORT_ORDER_ASC | typeof SORT_ORDER_DESC;
