import React from 'react';
import type { ComponentProps } from 'react';
import { Chip } from '@material-ui/core';
import type { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete/Autocomplete';

import { LOCATION_TYPE_CITY } from 'settings/search';

import type { GeoSearchResult } from 'api/viOffresAPI/apiTypes/Autocomplete';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import SuggestedSearch from '../../SuggestedSearch/SuggestedSearch';

import styles from './AutocompleteChip.module.scss';

interface AutocompleteChipProps {
  autocompleteOpen: boolean;
  defaultValues: ComponentProps<typeof SuggestedSearch>['defaultValues'];
  getTagProps: AutocompleteGetTagProps;
  index: number;
  onAutocompleteOptionClick: ComponentProps<typeof SuggestedSearch>['onAutocompleteOptionClick'];
  option: GeoSearchResult;
  positionSuggestedSearchRef?: React.RefObject<HTMLDivElement>;
}

export default function AutocompleteChip({
  autocompleteOpen,
  defaultValues,
  getTagProps,
  index,
  onAutocompleteOptionClick,
  option,
  positionSuggestedSearchRef = undefined,
}: AutocompleteChipProps) {
  return (
    <>
      {option.type === LOCATION_TYPE_CITY && (
        <SuggestedSearch
          autocompleteOpen={autocompleteOpen}
          defaultValues={defaultValues}
          onAutocompleteOptionClick={onAutocompleteOptionClick}
          option={option}
          positionRef={positionSuggestedSearchRef}
        />
      )}
      <Chip
        classes={{
          deleteIcon: styles.deleteIcon,
          label: styles.label,
          root: styles.chip,
        }}
        deleteIcon={<Icon icon="cross" />}
        label={option.label}
        size="small"
        variant="outlined"
        {...getTagProps({ index })}
      />
    </>
  );
}
