import React from 'react';

import { LABEL_INTERLOCUTORS } from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';

import AssistanceManaged from './AssistanceManaged';
import Interlocutor from './Interlocutor';

import styles from './Assistance.module.scss';

interface Interlocutor {
  description: string;
  email: string;
  nom: string;
  prenom: string;
  telephone: string;
  vignette: string;
}
const makeId = (interlocutor: Interlocutor | undefined) =>
  [interlocutor?.email, interlocutor?.prenom, interlocutor?.nom].filter(Boolean).join('-');

interface AssistanceMobileProps {
  defaultMail?: string;
  interlocutors: Interlocutor[];
}

export default function AssistanceMobile({ defaultMail, interlocutors }: AssistanceMobileProps) {
  const [open, setOpen] = React.useState(false);

  if (defaultMail) {
    return (
      <>
        <button type="button" className={styles.toggle} onClick={() => setOpen(true)}>
          <SvgIcon className={styles.icon} iconId="icon-chat" />
          <span className="sr-only">{LABEL_INTERLOCUTORS}</span>
        </button>
        <MobileDrawer className={styles.container} isDrawerOpen={open} openFrom="bottom">
          <header className={styles.drawerHeader}>
            <div className={styles.title}>Mes interlocuteurs</div>
            <button type="button" className={styles.close} onClick={() => setOpen(false)}>
              <Icon icon="cross" />
              <span className="sr-only">Fermer le volet</span>
            </button>
          </header>
          <AssistanceManaged className={styles.prout} email={defaultMail} />
        </MobileDrawer>
      </>
    );
  }

  return (
    <>
      <button type="button" className={styles.toggle} onClick={() => setOpen(true)}>
        <SvgIcon className={styles.icon} iconId="icon-chat" />
        <span className="sr-only">{LABEL_INTERLOCUTORS}</span>
      </button>
      <MobileDrawer className={styles.container} isDrawerOpen={open} openFrom="bottom">
        <header className={styles.drawerHeader}>
          <div className={styles.title}>Mes interlocuteurs</div>
          <button type="button" className={styles.close} onClick={() => setOpen(false)}>
            <Icon icon="cross" />
            <span className="sr-only">Fermer le volet</span>
          </button>
        </header>
        <div className={styles.detail}>
          {interlocutors.map(interlocutor => (
            <Interlocutor
              key={makeId(interlocutor)}
              phoneIsLink
              className={styles.item}
              interlocutor={interlocutor}
            />
          ))}
        </div>
      </MobileDrawer>
    </>
  );
}
