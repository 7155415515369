import { useContext, useEffect } from 'react';
import { isEqual } from 'lodash';

import { TMS_DEFAULT_VALUES } from 'settings/tms';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

export type TagCommanderEventProps = {
  isActive?: boolean;
  useEffectDeps?: string[];
};

export default function TagCommanderEvent({
  isActive = false,
  useEffectDeps = [],
  ...tmsValues
}: TagCommanderEventProps & Partial<typeof TMS_DEFAULT_VALUES>) {
  const { setEventPageVars } = useContext(TmsContext);

  useEffect(() => {
    setEventPageVars(prevVars => {
      if (isActive && !isEqual(prevVars, tmsValues)) {
        return tmsValues;
      }
      if (!isActive && isEqual(prevVars, tmsValues)) {
        return {};
      }
      return prevVars;
    });
  }, [isActive, ...useEffectDeps.map(key => tmsValues[key])]);

  return null;
}
