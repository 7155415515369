import React from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';

import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';

import { exhaustivenessCheck } from 'services/functional';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  checked?: boolean;
  classes?: Partial<Record<'label' | 'labelTitle' | 'labelSubtitle' | 'root', string>>;
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  handleChange?: (ev: any) => void;
  label?: string | { title: string | ReactNode; subtitle?: string };
  name?: string;
}

export function Checkbox({
  checked = false,
  classes = {},
  color,
  disabled = false,
  handleChange,
  label: labelProps,
  name,
}: CheckboxProps) {
  let label: ReactNode;
  if (typeof labelProps === 'string') {
    label = <div className={classnames(classes.label, styles.label)}>{labelProps}</div>;
  } else if (typeof labelProps === 'object') {
    label = (
      <div className={classnames(classes.label, styles.label)}>
        <div className={classnames(classes.labelTitle, styles.labelTitle)}>{labelProps.title}</div>
        <div className={classnames(classes.labelSubtitle, styles.labelSubtitle)}>
          {labelProps.subtitle}
        </div>
      </div>
    );
  } else if (typeof labelProps !== 'undefined') {
    return exhaustivenessCheck(labelProps);
  }
  return (
    <MuiFormControlLabel
      classes={{
        root: classnames(classes.root, styles.root, {
          [styles.multiline]: typeof labelProps === 'object',
        }),
      }}
      control={
        <MuiCheckbox
          classes={{
            checked: styles.checked,
            root: styles.checkbox,
          }}
          checked={checked}
          disableRipple
          color={color}
          onChange={handleChange}
          name={name}
        />
      }
      disabled={disabled}
      label={label}
    />
  );
}
