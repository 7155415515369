import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { axiosOffresInstance } from './axiosInstance';

export function useSWROffres<T>(
  request: Omit<AxiosRequestConfig, 'baseURL' | 'method' | 'data'>,
  config?: Partial<SWRConfiguration<T>>
) {
  const requestModified: AxiosRequestConfig = {
    ...request,
    method: `GET`,
    params: {
      site: 'vi3p',
    },
  };

  const { data: axiosData, ...responseUseSWR } = useSWR<
    AxiosResponse<T> | T | { data: T },
    AxiosError
  >(
    request?.url ? JSON.stringify(requestModified) : null,
    async () => axiosOffresInstance(requestModified).then(response => response.data),
    config
  );

  return { ...responseUseSWR, data: axiosData as T };
}

export function useSWROffresImmutable<T>(
  request: Omit<AxiosRequestConfig, 'baseURL' | 'method' | 'data'>,
  config?: Partial<SWRConfiguration<T>>
) {
  const requestModified: AxiosRequestConfig = {
    ...request,
    method: `GET`,
    params: {
      site: 'vi3p',
    },
  };

  const { data: axiosData, ...responseUseSWR } = useSWRImmutable<
    AxiosResponse<T> | T | { data: T },
    AxiosError
  >(
    request?.url ? JSON.stringify(requestModified) : null,
    async () => axiosOffresInstance(requestModified).then(response => response.data),
    config
  );

  return { ...responseUseSWR, data: axiosData as T };
}
