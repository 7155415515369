import React, { useContext } from 'react';

import userContext from 'modules/App/Contexts/userContext';
import { replaceTokens } from 'services';
import {
  LABEL_HOUSING,
  LABEL_HT_IMMO,
  LABEL_HT_TTC,
  LABEL_NO_FEES,
  LABEL_PERCENT_HOUSING,
  LABEL_PERCENT_SERVICE_RESIDENCE,
  LABEL_PROFILE_TAB_FEES_TITLE,
  LABEL_SERVICE_RESIDENCE,
  TOKEN_NB,
} from 'settings';

import CardIcon from 'commonUi/CardIcon/CardIcon';
import PanelContainer from 'layout/components/PanelContainer/PanelContainer';

import CardsList from '../CardsList/CardsList';

import styles from './AccountFees.module.scss';

export default function AccountFees() {
  const { userPrescripteurs } = useContext(userContext);

  if (!userPrescripteurs) {
    return <PanelContainer title={LABEL_PROFILE_TAB_FEES_TITLE} />;
  }

  const taux = userPrescripteurs.mandats[0]?.field_tauxhonoraires;
  return (
    <PanelContainer title={LABEL_PROFILE_TAB_FEES_TITLE}>
      <div className={styles.root}>
        {taux ? (
          <>
            <CardsList title={LABEL_HOUSING}>
              <CardIcon
                icon="percent"
                text={`* ${LABEL_HT_TTC}`}
                title={replaceTokens(`${LABEL_PERCENT_HOUSING}*`, { [TOKEN_NB]: taux })}
              />
            </CardsList>
            <CardsList title={LABEL_SERVICE_RESIDENCE}>
              <CardIcon
                icon="percent"
                text={`** ${LABEL_HT_IMMO}`}
                title={replaceTokens(`${LABEL_PERCENT_SERVICE_RESIDENCE}**`, { [TOKEN_NB]: taux })}
              />
            </CardsList>
          </>
        ) : (
          <p className={styles.text}>{LABEL_NO_FEES}</p>
        )}
      </div>
    </PanelContainer>
  );
}
