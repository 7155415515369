import React from 'react';
import classnames from 'classnames';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { LABEL_HT, LABEL_TTC } from 'settings';
import { formatPrice } from 'services/formatter';

import styles from './LotPrice.module.scss';

interface LotPriceProps {
  displayPanel?: boolean;
  lot: LotTypeV2 | undefined;
}

export function LotPrice({ displayPanel = false, lot }: LotPriceProps) {
  if (!lot) {
    return null;
  }

  switch (lot.fiscalites[0]) {
    case 'Demembrement':
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.TVANormale.prixTTC)} {LABEL_TTC}
              </span>
            </span>
          </div>
        </div>
      );

    case 'LMNP':
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.prixHTHorsMobilier)} {LABEL_HT}
              </span>
            </span>
          </div>
        </div>
      );

    case 'Malraux':
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.prixHTHorsMobilier)} {LABEL_TTC}
              </span>
            </span>
          </div>
        </div>
      );

    case 'ResidenceHorsPinel':
    case 'Pinel':
      return (
        <div
          className={classnames(styles.root, {
            [styles.displayPanel]: displayPanel,
            [styles.double]: lot.prix.TVAalternative?.prixTTC,
          })}
        >
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.TVANormale.prixTTC)} {LABEL_TTC}
              </span>
            </span>
          </div>
          {!!lot?.prix.TVAalternative?.prixTTC && (
            <div className={styles.priceContainer}>
              <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
              <span className={styles.highlight}>
                <span>
                  {formatPrice(lot.prix.TVAalternative.prixTTC)} {LABEL_TTC}{' '}
                </span>
                <span className={styles.tax}>TVA {lot.prix.TVAalternative.tauxTva}%</span>
              </span>
            </div>
          )}
        </div>
      );

    default:
      return null;
  }
}
