import { FLOORS } from 'settings/app';
import { LABEL_RANGE, LABEL_RANGE_HIGHER, LABEL_RANGE_LOWER } from 'settings/labels';
import { TOKEN_MAX, TOKEN_MIN } from 'settings/token';
import { NON_BREAKING_SPACE } from 'settings/ui';

export function budgetFormatter(price: number) {
  return `${Math.trunc(price / 1000)}k`;
}

export const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
});

export function floorFormatter(floor: keyof typeof FLOORS) {
  return FLOORS[floor] ? FLOORS[floor] : floor;
}

export function surfaceFormatter(surface: number) {
  return new Intl.NumberFormat('fr-FR').format(surface);
}

export function formatPrice(
  price: number | string | undefined | null,
  currency = '€',
  isNonBreakingSpace = false,
  priceSpacesInPoint = false
) {
  if (typeof price === 'string' && (price.includes(currency) || price.includes('-'))) {
    return price;
  }

  const priceNumber = typeof price === 'string' ? parseFloat(price.replace(/[^\d.]/g, '')) : price;

  if (Number.isNaN(priceNumber)) {
    return '';
  }
  if (priceNumber === 0 || !priceNumber) {
    return '-';
  }

  const formattedPrice = `${new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(
    priceNumber
  )}`;

  const finalFormattedPrice = priceSpacesInPoint
    ? formattedPrice.replace(/\s/g, '.')
    : formattedPrice;

  const formattedPriceWithCurrency = `${finalFormattedPrice} ${currency}`;

  if (isNonBreakingSpace) {
    return formattedPriceWithCurrency.replace(/\s/g, NON_BREAKING_SPACE);
  }

  return formattedPriceWithCurrency;
}

export function getMinPrice(field: string | undefined) {
  if (field !== undefined) {
    const prixHT = field.split('-');
    return formatPrice(prixHT[0]);
  }
  return undefined;
}

/**
 * Display a value with its SI unit system (For example 5000 bytes becomes 5KB)
 * @param {Number} size - The value to format
 * @param {Array<String>} units - An array containing the incremental sizes of the unit (For example [o, Ko, Mo, Go, ...] for file sizes)
 * @param {Number=} [decimals=0] - The number of wanted shown decimals
 * @return {string} - The formatted value
 */
export function unitsFormatter(size: number, units: readonly string[], decimals = 0) {
  const log = Math.trunc(Math.log10(size));
  const magnitude = (log - (log % 3)) / 3;
  const value = Math.round(size / 10 ** (magnitude * 3 - decimals)) / 10 ** decimals;

  return `${value}${units[magnitude]}`;
}

export function formatPhone(value: string | number | null | undefined, format: string) {
  if (!value) {
    return value;
  }
  return Array.from(value.toString().replace(/\s/g, ''))
    .reduce((str, c) => str.replace('.', c), format)
    .replaceAll('.', '')
    .trim();
}

export function budgetValueFormatter(price: number) {
  return Math.trunc(price / 1000);
}

export function budgetValueFormatterReverse(price: number) {
  return Math.trunc(price * 1000);
}

export function replaceTokens(text = '', tokens: Record<string, string | number | undefined>) {
  return text.replace(
    new RegExp(Object.keys(tokens).join('|'), 'gi'),
    matched => tokens[matched.toLowerCase()]?.toString() ?? ''
  );
}

export function replaceBold(text = '', boldChar = '*') {
  return text.replace(
    new RegExp(`\\${boldChar}(.+?)\\${boldChar}`, 'g'),
    (m, p1) => `<strong>${p1}</strong>`
  );
}

export function formatUrlLocations(locations) {
  return locations?.map(location => ({
    label: location.split(',')[0].split('=')[1],
    value: location.split(',')[1].split('=')[1],
    id: location.split(',')[2].split('=')[1],
    type: location.split(',')[3].split('=')[1],
  }));
}

export function numberFormatter(number: number | string | null, unit = '') {
  const parsedNumber = parseFloat(number?.toString() ?? '');
  if (Number.isNaN(parsedNumber)) {
    return undefined;
  }
  const surface = new Intl.NumberFormat('fr-FR').format(parsedNumber);
  return surface && surface !== '0' ? `${surface}${unit}` : undefined;
}

export function formatPriceRange(
  str,
  currency = '€',
  isNonBreakingSpace = false,
  priceSpacesInPoint = false
) {
  const [min, max] = str
    .split('-')
    .map(v => formatPrice(v, currency, isNonBreakingSpace, priceSpacesInPoint));
  let label = LABEL_RANGE;
  if (!min && !max) {
    return null;
  }
  if ((min && !max) || min === max) {
    label = LABEL_RANGE_LOWER;
  } else if (!min && max) {
    label = LABEL_RANGE_HIGHER;
  }

  return replaceTokens(label, {
    [TOKEN_MIN]: min,
    [TOKEN_MAX]: max,
  });
}
