import React from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import RowDetailsListItem from './RowDetailsListItem';

import styles from './RowDetailsList.module.scss';

interface RowDetailsListType {
  className?: string;
  items: Record<string, ComponentProps<typeof RowDetailsListItem>>;
}

export default function RowDetailsList({ className, items = {} }: RowDetailsListType) {
  if (!Object.entries(items).length) {
    return null;
  }

  return (
    <ul className={classnames(className, styles.root)}>
      {Object.entries(items).map(([key, item]) => (
        <li key={key} className={styles.item}>
          <RowDetailsListItem {...item} />
        </li>
      ))}
    </ul>
  );
}
