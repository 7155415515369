import { COOKIE_TEST_MODAL_AUTO_OPEN } from 'settings/cookies';

export function processCookie(cookie: string = document.cookie): Record<string, any> {
  return Object.fromEntries(cookie.split(';').map(crumb => crumb.trim().split('=')));
}

type TestCookieKeys = typeof COOKIE_TEST_MODAL_AUTO_OPEN; // This can evolve as more test scenarios that require us to disable a specific behaviour emerge
export function isTestCookieEnabled(key: TestCookieKeys): boolean;
export function isTestCookieEnabled(...keys: TestCookieKeys[]): boolean[];
export function isTestCookieEnabled(...keys: TestCookieKeys[]): boolean | boolean[] {
  if (keys.length === 0) {
    return false;
  }
  const cookie = processCookie();
  const check = (value: string) => value === '1';
  if (keys.length === 1) {
    return check(cookie[keys[0]]);
  }
  return keys.map(key => check(cookie[key]));
}
