import { useMemo } from 'react';

export function useInfinitePager<T>(page: number, itemsPerPage: number, items: T[]) {
  const paginatedItems = useMemo(() => {
    if (!items) {
      return null;
    }
    return items.slice(0, itemsPerPage * (page + 1));
  }, [itemsPerPage, page, items]);
  const hasMoreItems = items && paginatedItems ? paginatedItems.length < items.length : false;
  return [paginatedItems, hasMoreItems];
}

export function useInfinitePagerWithTyped<T>(page: number, itemsPerPage: number, items: T[]) {
  const paginatedItems = useMemo(() => {
    if (!items) {
      return [];
    }
    return items.slice(0, itemsPerPage * (page + 1));
  }, [itemsPerPage, page, items]);
  const hasMoreItems = items && paginatedItems ? paginatedItems.length < items.length : false;
  return { paginatedItems, hasMoreItems };
}
