import React from 'react';
import { Image, View } from '@react-pdf/renderer';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import styles from './LotPdfPlanStyles';

export interface LotPdfPlanProps {
  lot: LotTypeV2;
  plan: string;
}

export default function LotPdfPlan({ plan }: LotPdfPlanProps) {
  return <View>{plan && <Image key={plan} style={styles.image} src={plan} />}</View>;
}
