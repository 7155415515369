import { useContext, useMemo } from 'react';
import { ModalUiContext } from 'modules/App/Contexts/ModalUiContext';

export function useModalUi() {
  const [modalUiContext, setModalUiContext] = useContext(ModalUiContext);

  const focus = modalName => {
    if (modalUiContext.id !== modalName && modalUiContext.open === true) {
      // Close Old Modal
      setModalUiContext({
        id: modalUiContext.id,
        data: {},
        open: false,
      });
    }
  };

  const closeModal = modalName => {
    document.body.classList.remove('scroll-lock');
    if (typeof modalUiContext.data.closeCallback === 'function') {
      modalUiContext.data.closeCallback();
    }
    if (modalUiContext.id === modalName) {
      setModalUiContext({
        id: modalName,
        data: {},
        open: false,
      });
    }
  };

  const openModal = (modalName, data) => {
    document.body.classList.add('scroll-lock');
    focus(modalName);
    if (modalUiContext.open === true) closeModal();
    setModalUiContext({
      id: modalName,
      open: true,
      data,
    });
  };

  const updateData = updatedData => {
    setModalUiContext({
      id: modalUiContext.id,
      open: modalUiContext.open,
      data: updatedData,
    });
  };

  const isModalOpen = modalName => {
    return modalUiContext.id === modalName && modalUiContext.open;
  };

  return {
    closeModal,
    openModal,
    updateData,
    closeCurrent: () => closeModal(modalUiContext.id),
    isModalOpen,
  };
}

export function useModalUiObserver(modalName, fn) {
  const [modalUiContext] = useContext(ModalUiContext);
  useMemo(() => {
    if (modalUiContext.id === modalName) fn(modalUiContext);
  }, [fn, modalName, modalUiContext]);
}
