import React, { useContext } from 'react';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import ProgramBannerLabel from './ProgramBannerLabel';
import ProgramBannerPictos from './ProgramBannerPictos';
import ProgramBooster from './ProgramBooster';

import styles from './ProgramBanner.module.scss';

interface ProgramBannerProps {
  program: ProgramTypeV2 | undefined;
}

export default function ProgramBanner({ program }: ProgramBannerProps) {
  const { isMobile } = useContext(ResponsiveContext);

  if (!program) {
    return null;
  }

  const Booster = <ProgramBooster className={styles.booster} program={program} />;

  return (
    <div className={styles.root}>
      <div className={styles.backgroundContainer}>
        <div className={styles.bottom}>
          <div className={styles.content}>
            <ProgramBannerLabel className={styles.label} program={program} />

            <h1 className={styles.programTitle}>{program.nomCommercial}</h1>

            {program.localisation.ville && (
              <div className={styles.location}>
                {program.localisation.ville}{' '}
                {program.localisation.codePostal && <>({program.localisation.codePostal})</>}
              </div>
            )}
            <ProgramBannerPictos program={program} />
          </div>

          {!isMobile && Booster}
        </div>

        <img
          className={styles.backgroundImg}
          src={program.perspectives?.panorama}
          alt={program.nomCommercial}
        />
      </div>

      {isMobile && Booster}
    </div>
  );
}
