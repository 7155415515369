import React from 'react';

import { taxMapping } from 'services/taxes';
import { formatPrice } from 'services/formatter';
import {
  LABEL_FINANCIAL_INFORMATIONS,
  LABEL_FISCAL_AREA,
  LABEL_KNOW_MORE,
  LABEL_NUDE_PROPERTY_TTC,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_INCLUDING_TAX_TTC,
  LABEL_PRICE_MOBILIER_HT,
  LABEL_PROFITABILITY,
  LABEL_PROFITABILITY_HT,
  LABEL_RENT_LMNP_YEAR,
  LABEL_RENT_MARKET_MONTH,
  LABEL_RENT_PINEL,
  LABEL_SECURITY_DEPOSIT,
} from 'settings/labels';
import { LOT_DEFAULT_SECURITY_DEPOSIT } from 'settings/lots';
import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_NONE,
  TAX_TYPE_PINEL,
  TAX_TYPE_PINEL_DEROGE,
  TAX_TYPE_PINEL_PLUS,
  TAX_TYPE_PLS,
} from 'settings/taxes';

import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import RowDetailsList from 'commonUi/RowDetailsList/RowDetailsList';
import DepositInfos from 'commonUi/DepositInfos/DepositInfos';

interface LotDetailsFinancialType {
  classes?: {
    root?: string;
    title?: string;
  };
  fiscality?: TaxTaxonomyTerm;
  lot: LotTypeV2;
  program: ProgramTypeV2;
}

export default function LotDetailsFinancial({
  classes = {},
  fiscality,
  lot,
  program,
}: LotDetailsFinancialType) {
  const taxType = fiscality ? taxMapping.get(fiscality.nom) : undefined;
  const isPinel =
    !!taxType &&
    [
      TAX_TYPE_BRS,
      TAX_TYPE_PINEL,
      TAX_TYPE_PINEL_DEROGE,
      TAX_TYPE_PINEL_PLUS,
      TAX_TYPE_PLS,
    ].includes(taxType);
  const isLMNP = taxType === TAX_TYPE_LMNP;
  const isDemembrement = taxType === TAX_TYPE_DEMEMBREMENT;
  const isResidenceHorsPinel = taxType === TAX_TYPE_NONE;
  const {
    bicDepotGarantie,
    ibanDepotGarantie,
    modePaiement,
    nomBanqueDepotGarantie,
    ordreDepotGarantie,
  } = program.depotGarantie;

  let profitability = parseFloat(lot.investissementLocatif.rendementLocatif ?? '');
  if (isLMNP) {
    profitability = parseFloat(lot.investissementLocatif.rentabiliteLMNP ?? '');
  }
  if (isPinel) {
    profitability = parseFloat(lot.investissementLocatif.rentabilitePinel ?? '');
  }

  return (
    <div className={classes?.root}>
      <div className={classes?.title}>{LABEL_FINANCIAL_INFORMATIONS}</div>
      <RowDetailsList
        items={{
          ...(isPinel
            ? {
                [LABEL_FISCAL_AREA]: {
                  label: LABEL_FISCAL_AREA,
                  value: program?.informationsFiscalesEtFinancieres?.zoneFiscale,
                },
                [LABEL_RENT_PINEL]: {
                  label: LABEL_RENT_PINEL,
                  value: formatPrice(lot?.investissementLocatif?.plafondLoyerPinel),
                },
              }
            : {}),
          ...(isPinel || isResidenceHorsPinel
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: LABEL_RENT_MARKET_MONTH,
                  value: formatPrice(lot?.investissementLocatif?.loyerMarcheHT),
                },
              }
            : {}),
          ...(isLMNP
            ? {
                [LABEL_PRICE_IMMO_HT]: {
                  label: LABEL_PRICE_IMMO_HT,
                  value: formatPrice(lot?.prix?.prixHTHorsMobilier),
                },
                [LABEL_PRICE_INCLUDING_TAX_TTC]: {
                  label: LABEL_PRICE_INCLUDING_TAX_TTC,
                  value: formatPrice(lot?.prix?.TVANormale?.prixTTC),
                },
                [LABEL_PRICE_MOBILIER_HT]: {
                  label: LABEL_PRICE_MOBILIER_HT,
                  value: formatPrice(lot?.prix?.prixMobilierHT),
                },
                [LABEL_RENT_LMNP_YEAR]: {
                  label: LABEL_RENT_LMNP_YEAR,
                  value: formatPrice(lot?.investissementLocatif?.loyerMarcheAnnuelHT),
                },
              }
            : {}),
          ...(isDemembrement
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: `${LABEL_NUDE_PROPERTY_TTC} = ${LABEL_PRICE_INCLUDING_TAX_TTC}`,
                  value: LABEL_KNOW_MORE,
                  href: `/programme/page/${program.referenceProgramme}/prix`,
                },
              }
            : {}),
          [LABEL_PROFITABILITY]: {
            label: isLMNP ? LABEL_PROFITABILITY_HT : LABEL_PROFITABILITY,
            value: profitability > 0 ? `${profitability}%` : '-',
          },
          [LABEL_SECURITY_DEPOSIT]: {
            label: LABEL_SECURITY_DEPOSIT,
            value: program?.depotGarantie?.montantDepotGarantie
              ? formatPrice(program.depotGarantie.montantDepotGarantie)
              : LOT_DEFAULT_SECURITY_DEPOSIT,
            infos: (
              <DepositInfos
                bicDepotGarantie={bicDepotGarantie}
                ibanDepotGarantie={ibanDepotGarantie}
                ordreDepotGarantie={ordreDepotGarantie}
                nomBanqueDepotGarantie={nomBanqueDepotGarantie}
                modePaiement={modePaiement}
                montantDepot={
                  program?.depotGarantie?.montantDepotGarantie
                    ? formatPrice(program.depotGarantie.montantDepotGarantie)
                    : LOT_DEFAULT_SECURITY_DEPOSIT
                }
              />
            ),
          },
        }}
      />
    </div>
  );
}
