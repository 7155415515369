import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Drawer from '../../../common/components/Atoms/Drawer/Drawer';
import Icon from '../../../common/components/Atoms/Icon';
import PoiList from '../PoiList/PoiList';

import { kelQuartierModuleContext } from '../../contexts/kelQuartierModuleContext';
import { KELQUARTIER_THEMES } from '../../settings/kelquartier';

import styles from './KelQuartier.module.scss';

const poiKeyIdsAssoc = KELQUARTIER_THEMES.reduce((acc, theme, index1) => {
  const { pois } = theme;
  const poiIds = pois.reduce((acc, poi, index2) => {
    const key = `poi-${index1}-${index2}`;
    acc[key] = poi;
    return acc;
  }, {});
  return {
    ...acc,
    ...poiIds,
  };
}, {});

function isFloat(value) {
  return !!(value % 1) || Number.isInteger(value);
}

export default function KelQuartier({
  defaultOpenPanel,
  closeDrawer,
  labels,
  residenceLat,
  residenceLng,
}) {
  const { displayPoi, hidePoi } = labels;

  const displayPoiLabel = displayPoi || 'Afficher les points d’intérêt';
  const hidePoiLabel = hidePoi || 'Masquer les points d’intérêt';

  const [openPanel, setOpenPanel] = useState(defaultOpenPanel);
  const [openDrawer, setOpenDrawer] = useState(false);
  const {
    activePois,
    addresses,
    getGoogleReverseGeocoding,
    kelquartierGetPois,
    kelQuartierPOIsData,
    setActivePois,
    setAddresses,
    setKelQuartierPOIsData,
    theme,
  } = useContext(kelQuartierModuleContext);

  useEffect(() => {
    setOpenPanel(defaultOpenPanel);
  }, [defaultOpenPanel]);

  useEffect(() => {
    if (isFloat(residenceLat) && isFloat(residenceLng)) {
      const ids = activePois.reduce(
        (acc, activePoi) => acc.concat(poiKeyIdsAssoc[activePoi].ids),
        []
      );

      if (ids.length > 0) {
        kelquartierGetPois(ids, residenceLat, residenceLng).then(datas => {
          setKelQuartierPOIsData(datas);
        });
      } else {
        setKelQuartierPOIsData([]);
      }
    }
  }, [activePois, kelquartierGetPois, residenceLat, residenceLng, setKelQuartierPOIsData]);

  useEffect(() => {
    if (!kelQuartierPOIsData.data) {
      return;
    }
    const promises = kelQuartierPOIsData.data
      .filter(({ id }) => !addresses.has(id))
      .map(({ id, lat, lon }) =>
        getGoogleReverseGeocoding(lat, lon).then(data => [
          id,
          data.results && data.results[0] && data.results[0].formatted_address,
        ])
      );
    Promise.all(promises).then(data => setAddresses(new Map([...addresses, ...data])));
  }, [kelQuartierPOIsData]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePoiToggle({ target }) {
    const { name, checked } = target;
    const exist = activePois.indexOf(name) !== -1;
    if (checked && !exist) {
      setActivePois(state => state.concat(name));
    } else if (checked && exist) {
      setActivePois(state => state.filter(id => id !== name));
    }
  }

  function handlePanelToggle() {
    setOpenPanel(open => !open);
  }

  function handleOpenDrawer() {
    setOpenDrawer(true);
  }

  function handleCloseDrawer() {
    setOpenDrawer(false);
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }

  if (!residenceLat && !residenceLng) {
    return null;
  }

  const themeClassNames = theme instanceof Array ? theme.map(t => styles[t]) : styles[theme];

  return (
    <div className={classNames(styles.root, themeClassNames)}>
      <div className={classNames(styles.actionSM, themeClassNames)}>
        <button
          className={classNames(styles.button, themeClassNames)}
          type="button"
          onClick={handleOpenDrawer}
        >
          <Icon className={styles.buttonIcon} icon="show" />
          {displayPoiLabel}
        </button>
      </div>
      <div className={classNames(styles.container, themeClassNames)}>
        <div className={classNames(styles.poisContainer, themeClassNames)}>
          <div
            className={classNames(styles.poi, themeClassNames, { [styles.poiClosed]: !openPanel })}
          >
            <PoiList activePois={activePois} onPoiToggle={handlePoiToggle} />
          </div>
          <div className={classNames(styles.actionXL, themeClassNames)}>
            <button
              className={classNames(styles.button, themeClassNames, {
                [styles.buttonOpen]: openPanel,
              })}
              type="button"
              onClick={handlePanelToggle}
            >
              {openPanel ? (
                <>
                  <Icon className={styles.buttonIcon} icon="hide" />
                  {hidePoiLabel}
                </>
              ) : (
                <>
                  <Icon className={styles.buttonIcon} icon="show" />
                  {displayPoiLabel}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <Drawer
        className={classNames(styles.drawer, themeClassNames)}
        onClose={handleCloseDrawer}
        open={openDrawer}
        title="Mes points d’intérêt"
      >
        <PoiList activePois={activePois} onPoiToggle={handlePoiToggle} />
      </Drawer>
    </div>
  );
}

KelQuartier.propTypes = {
  closeDrawer: PropTypes.func,
  defaultOpenPanel: PropTypes.bool,
  labels: PropTypes.shape({
    displayPoi: PropTypes.string,
    hidePoi: PropTypes.string,
  }),
  residenceLat: PropTypes.number.isRequired,
  residenceLng: PropTypes.number.isRequired,
};

KelQuartier.defaultProps = {
  closeDrawer: undefined,
  defaultOpenPanel: false,
  labels: {},
};
