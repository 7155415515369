import React from 'react';
import classnames from 'classnames';

import { LABEL_NEW_RESIDENCE, LABEL_PREVIEW } from 'settings/labels';
import {
  PROGRAM_PHASE_ARCHIVE,
  PROGRAM_PHASE_LAUNCH,
  PROGRAM_PHASE_MARKETING,
  PROGRAM_PHASE_PARKING,
  PROGRAM_PHASE_PREVIEW,
} from 'settings/programs';

import styles from './ProgramBannerLabel.module.scss';

interface ProgramBannerLabelProps {
  className?: string;
  program: {
    nouveaute: boolean;
    phaseCommerciale:
      | typeof PROGRAM_PHASE_ARCHIVE
      | typeof PROGRAM_PHASE_LAUNCH
      | typeof PROGRAM_PHASE_MARKETING
      | typeof PROGRAM_PHASE_PARKING
      | typeof PROGRAM_PHASE_PREVIEW;
  };
}

export default function ProgramBannerLabel({ className, program }: ProgramBannerLabelProps) {
  if (program.phaseCommerciale === PROGRAM_PHASE_PREVIEW) {
    return <div className={classnames(className, styles.root)}>{LABEL_PREVIEW}</div>;
  }

  if (program.nouveaute) {
    return <div className={classnames(className, styles.root)}>{LABEL_NEW_RESIDENCE}</div>;
  }

  return null;
}
