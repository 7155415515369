import React from 'react';
import type { HTMLAttributes, ReactNode } from 'react';
import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import { LABEL_LOT_DETAIL, LABEL_RETURN } from 'settings/index';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './LotDetailLayout.module.scss';

interface LotDetailLayoutTabletteProps {
  closeDrawer?: HTMLAttributes<HTMLButtonElement>['onClick'];
  loaded: boolean;
  lotNumber: string | undefined;
  renderDetails?: () => ReactNode;
  renderImage?: () => ReactNode;
  renderButton?: () => ReactNode;
  renderPromotion?: (className?: string) => ReactNode;
}

export default function LotDetailLayoutTablette({
  closeDrawer,
  loaded,
  lotNumber,
  renderButton,
  renderDetails,
  renderImage,
  renderPromotion,
}: LotDetailLayoutTabletteProps) {
  if (!loaded) {
    return (
      <div className={styles.loading}>
        <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={24} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <button className={styles.returnBtn} onClick={closeDrawer} type="button">
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_RETURN}</span>
        </button>
        <div className={styles.name}>
          {LABEL_LOT_DETAIL} {lotNumber}
        </div>
      </div>
      {renderImage && <div className={styles.image}>{renderImage()}</div>}
      {renderPromotion && renderPromotion(styles.promo)}
      <div className={styles.cols}>
        {renderDetails && (
          <div className={classnames(styles.details, styles.colsItem)}>{renderDetails()}</div>
        )}
        {renderButton && (
          <div className={classnames(styles.buttons, styles.colsItem)}>{renderButton()}</div>
        )}
      </div>
    </div>
  );
}
