export const TAX_TYPE_PINEL = 'Pinel';
export const TAX_TYPE_NONE = 'Hors dispositif';
export const TAX_TYPE_DEMEMBREMENT = 'Démembrement';
export const TAX_TYPE_LMNP = 'LMNP';
export const TAX_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_TYPE_MALRAUX = 'Malraux';
export const TAX_TYPE_PINEL_PLUS = 'Pinel+';
export const TAX_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_TYPE_PLS = 'Prêt Locatif Social';
export const TAX_TYPE_PINEL_DEROGE = 'Pinel Deroge';

export const TAX_LABEL_TYPE_PINEL = 'Pinel';
export const TAX_LABEL_TYPE_NONE = 'Hors dispositif';
export const TAX_LABEL_TYPE_DEMEMBREMENT = 'Nue Propriété';
export const TAX_LABEL_TYPE_LMNP = 'LMNP';
export const TAX_LABEL_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_LABEL_TYPE_MALRAUX = 'Malraux';
export const TAX_LABEL_TYPE_PINEL_PLUS = 'Pinel+';
export const TAX_LABEL_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_LABEL_TYPE_PLS = 'Prêt Locatif Social';
export const TAX_LABEL_TYPE_PINEL_DEROGE = 'Pinel Deroge';

export const TAX_ICON = {
  [TAX_TYPE_PINEL]: 'icon-fiscality-pinel',
  [TAX_TYPE_LMNP]: 'icon-fiscality-lmnp',
  [TAX_TYPE_NONE]: 'icon-fiscality-hors-dispositif',
  [TAX_TYPE_DEMEMBREMENT]: 'icon-fiscality-nue-propriete',
  [TAX_LABEL_TYPE_PINEL_PLUS]: 'icon-fiscality-pinel-plus',
  [TAX_LABEL_TYPE_BRS]: 'icon-fiscality-brs',
  [TAX_LABEL_TYPE_PLS]: 'icon-fiscality-pls',
  [TAX_LABEL_TYPE_PINEL_DEROGE]: 'icon-fiscality-pinel-deroge',
  [TAX_LABEL_TYPE_MALRAUX]: 'icon-fiscality-malraux',
};
