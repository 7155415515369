import React from 'react';

import { ParagraphType } from 'api/vi3pAPI/apiTypes/ContentType';
import ParagraphFreestyle from './ParagraphFreestyle/ParagraphFreestyle';

export default function Paragraphs({ paragraphs }: ParagraphsType) {
  return (
    <>
      {paragraphs.map(paragraph => {
        switch (paragraph.content_type) {
          case 'freestyle':
            return <ParagraphFreestyle key={paragraph.uuid} paragraph={paragraph} />;

          default:
        }
        const exhaustiveCheck: never = paragraph.content_type;
        return exhaustiveCheck;
      })}
    </>
  );
}

export type ParagraphsType = {
  paragraphs: ParagraphType[];
};
