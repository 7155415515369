import React, { useContext, useMemo } from 'react';

import { filterNullable } from 'services/array';

import ContactsContext from 'modules/App/Contexts/ContactsContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import userContext from 'modules/App/Contexts/userContext';

import AssistanceMobile from './AssistanceMobile';
import AssistanceDesktop from './AssistanceDesktop';

interface AssistanceProps {
  defaultMail?: string;
  showLinkedContacts?: boolean;
}

export default function Assistance({ defaultMail, showLinkedContacts = false }: AssistanceProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { userPrescripteurs } = useContext(userContext);
  const { getContacts, isLoading } = useContext(ContactsContext);

  const interlocutors = useMemo(() => {
    if (defaultMail || isLoading || !userPrescripteurs?.interlocuteurs.length) {
      return [];
    }
    const emails = Array.from(
      new Set(
        userPrescripteurs.interlocuteurs.map(interlocutor => interlocutor.field_email.toLowerCase())
      )
    );
    return filterNullable(getContacts(emails, showLinkedContacts));
  }, [defaultMail, showLinkedContacts, userPrescripteurs?.interlocuteurs, getContacts, isLoading]);

  if (!interlocutors.length && !defaultMail) {
    return null;
  }

  if (isResponsive) {
    return <AssistanceMobile defaultMail={defaultMail} interlocutors={interlocutors} />;
  }

  return <AssistanceDesktop defaultMail={defaultMail} interlocutors={interlocutors} />;
}
