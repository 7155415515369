import React from 'react';
import classnames from 'classnames';

import { formatPrice, numberFormatter } from 'services/formatter';
import {
  LABEL_ADDITIONAL_INFORMATIONS,
  LABEL_BALCONY,
  LABEL_CAVE,
  LABEL_GARDEN,
  LABEL_LOGGIA,
  LABEL_NORMAL_VAT,
  LABEL_PARKING,
  LABEL_REDUCED_VAT,
  LABEL_SELL_INCLUSION,
  LABEL_TERRACE,
} from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { Availability } from 'commonUi/Availability/Availability';

import styles from './ActivityDetailsAdditional.module.scss';

export default function ActivityDetailsAdditional({ classes = {}, lot }: LotDetailsAdditionalType) {
  return (
    <div className={classes?.root}>
      <div className={classes?.title}>{LABEL_ADDITIONAL_INFORMATIONS}</div>
      <div className={styles.grid}>
        {lot?.parkingVi3p?.length > 0 &&
          lot.parkingVi3p.map(parking => (
            <div className={styles.gridCol}>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <div key={parking.nom}>
                    {LABEL_PARKING}* <span className={styles.value}>{parking.nom}</span>
                    <div className={styles.prices}>
                      <div className={styles.pricesItem}>
                        <span className={styles.label}>{LABEL_NORMAL_VAT}</span>
                        <span className={styles.value}>
                          {formatPrice(parking.prixTauxTvaNormale)}
                        </span>
                      </div>
                      <div className={styles.pricesItem}>
                        <span className={styles.label}>{LABEL_REDUCED_VAT}</span>
                        <span className={styles.value}>
                          {formatPrice(parking.prixTauxTvaReduite)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={classnames(styles.label, styles.right)}>
                    *{LABEL_SELL_INCLUSION}
                  </div>
                </li>
              </ul>
            </div>
          ))}
        <div className={styles.gridCol}>
          <div className={styles.availabilities}>
            {lot?.surfaces?.surfaceJardin > 0 && (
              <Availability
                label={LABEL_GARDEN}
                value={
                  numberFormatter(lot?.surfaces?.surfaceJardin)
                    ? `${numberFormatter(lot.surfaces.surfaceJardin)} m²`
                    : undefined
                }
              />
            )}
            {lot?.caveVi3p?.length > 0 && <Availability label={LABEL_CAVE} />}
            {lot?.surfaces?.surfaceBalcon > 0 && (
              <Availability
                label={LABEL_BALCONY}
                value={
                  numberFormatter(lot?.surfaces?.surfaceBalcon)
                    ? `${numberFormatter(lot.surfaces.surfaceBalcon)} m²`
                    : undefined
                }
              />
            )}
            {lot?.surfaces.surfaceTerrasse > 0 && (
              <Availability
                label={LABEL_TERRACE}
                value={
                  numberFormatter(lot?.surfaces?.surfaceTerrasse)
                    ? `${numberFormatter(lot.surfaces.surfaceTerrasse)} m²`
                    : undefined
                }
              />
            )}
            {lot?.surfaces.surfaceLoggia > 0 && (
              <Availability
                label={LABEL_LOGGIA}
                value={
                  numberFormatter(lot?.surfaces?.surfaceLoggia)
                    ? `${numberFormatter(lot.surfaces.surfaceLoggia)} m²`
                    : undefined
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export type LotDetailsAdditionalType = {
  classes?: {
    root?: string;
    title?: string;
  };
  lot: LotTypeV2;
};
