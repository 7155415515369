import React from 'react';
import type { HTMLAttributes } from 'react';
import classnames from 'classnames';

import { getColumnStyles } from 'services/ui';

import styles from './LotRow.module.scss';

interface LotScrollingRowType {
  cellsWidth?: Parameters<typeof getColumnStyles>[2];
  className?: string;
  headings?: { id: string; align?: string }[];
  index: number;
  style?: HTMLAttributes<HTMLDivElement>['style'];
}

export default function LotScrollingRow({
  cellsWidth,
  className,
  headings = [],
  index,
  style,
}: LotScrollingRowType) {
  return (
    <div style={style}>
      <div
        className={classnames(
          className,
          styles.resultItem,
          index % 2 === 0 ? styles.even : styles.odd
        )}
      >
        {headings.map(col => (
          <div
            key={col.id}
            className={classnames(styles.cell, {
              [styles.alignCenter]: col.align === 'center',
              [styles.alignLeft]: col.align === 'left',
              [styles.alignRight]: col.align === 'right',
            })}
            style={getColumnStyles(col, headings, cellsWidth)}
          >
            {col.id === 'promo' ? '' : '-'}
          </div>
        ))}
      </div>
    </div>
  );
}
