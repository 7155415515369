import React from 'react';
import type { ComponentProps } from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import classnames from 'classnames';

import styles from './Drawer.module.scss';

export const DRAWER_DIRECTION_BOTTOM = 'bottom';
export const DRAWER_DIRECTION_LEFT = 'left';
export const DRAWER_DIRECTION_RIGHT = 'right';
export const DRAWER_DIRECTION_TOP = 'top';

export default function Drawer({
  children,
  classes,
  onClose,
  open,
  openDirection = DRAWER_DIRECTION_LEFT,
}: DrawerProps) {
  return (
    <MuiDrawer
      anchor={openDirection}
      BackdropProps={{
        classes: { root: classnames(styles.backdropRoot, classes?.backdropRootClassName) },
      }}
      classes={{
        root: classnames(styles.drawerRoot, classes?.drawerRootClassName),
      }}
      elevation={0}
      open={open}
      PaperProps={{
        classes: {
          root: classnames(styles.paperRoot, classes?.paperRootClassName),
        },
      }}
      variant="temporary"
      onClose={onClose}
    >
      <div className={classnames(styles.drawerContainer, classes?.drawerContainerClassName)}>
        {open && children}
      </div>
    </MuiDrawer>
  );
}

export type DrawerProps = {
  children: React.ReactNode;
  classes?: {
    backdropRootClassName?: string;
    drawerContainerClassName?: string;
    drawerRootClassName?: string;
    paperRootClassName?: string;
  };
  onClose?: ComponentProps<typeof MuiDrawer>['onClose'];
  open: boolean;
  openDirection?:
    | typeof DRAWER_DIRECTION_BOTTOM
    | typeof DRAWER_DIRECTION_LEFT
    | typeof DRAWER_DIRECTION_RIGHT
    | typeof DRAWER_DIRECTION_TOP;
};
