import { FLOORS } from 'settings/app';
import { LOT_CELL_TYPE_CHECKBOX } from 'settings/lots';
import {
  SORT_LOT_BY_DELIVERY,
  SORT_LOT_BY_EXPOSURE,
  SORT_LOT_BY_FLOOR,
  SORT_LOT_BY_KIND,
  SORT_LOT_BY_NUMBER,
  SORT_LOT_BY_PARKING,
  SORT_LOT_BY_PLAN,
  SORT_LOT_BY_PRICE_IMMO_HT,
  SORT_LOT_BY_PRICE_INCLUDING_TAX,
  SORT_LOT_BY_PRICE_MOB_HT,
  SORT_LOT_BY_PRICE_REDUCED_VAT,
  SORT_LOT_BY_PROFITABILITY,
  SORT_LOT_BY_PROFITABILITY_CUSTOM,
  SORT_LOT_BY_PROGRAM,
  SORT_LOT_BY_RENT,
  SORT_LOT_BY_RENT_CUSTOM,
  SORT_LOT_BY_RENT_FURNISHED,
  SORT_LOT_BY_STATUS,
  SORT_LOT_BY_SURFACE,
  SORT_LOT_BY_SURFACE_ANNEX,
  SORT_LOT_BY_TAX, SORT_LOT_BY_TYPE,
  SORT_ORDER_ASC,
  SORT_ORDER_DESC,
  SORT_PROGRAM_BY_POSITION,
} from 'settings/search';

import type { Promotion } from 'types/promotions';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { FiscalityValuesType } from './Fiscality';
import type { KitchenType } from './Kitchen';
import type { StatusLabel } from './Statuses';
import { DocumentTypeV2 } from './Documents';

/* eslint-disable prettier/prettier */
export type LotExport = [
  nid: string,
  programId: string,
  taxTid: string,
  type: string | null,
  lotNumber: string,
  kindTid: string,
  kindLabel: string,
  floor: keyof typeof FLOORS,
  exposure: string,
  surface: string,
  surfaceAnnex: string,
  hasParking: 0 | 1,
  priceReducedVAT: string | null,
  priceIncludingTax: string | null,
  rent: string | null,
  profitability: string | null,
  deliveryDate: string | null,
  status: StatusLabel,
  expirationDate: string | null,
  customPrice1: string | null,
  customPrice2: string | null,
  customRent: string | null,
  customProfitability: string | null,
  attributions: string[],
  annexes: string[],
  others: string[],
  plan: string | null,
  programRef: string,
  kitchenType: KitchenType | null,
  kitchenPrice: string | null,
  venteInterne: boolean,
  ventePartenaireVi2p: boolean,
  promotions: null | Record<string, string>[],
  rentFurnished: string | null,
];
/* eslint-enable prettier/prettier */

export interface LotJson {
  nid: string;
  programId: string;
  tax: string;
  type: string | null;
  number: string;
  kindTid: string;
  kind: string;
  floor: string;
  exposure: string;
  surface: number;
  surfaceAnnex: number;
  hasParking: 'Oui' | 'Non';
  priceReducedVat: number;
  priceIncludingTax: number;
  rent: number;
  rentFurnished: number;
  profitability: number;
  delivery: Date | null;
  status: StatusLabel;
  expirationDate: Date | null;
  customPrice1: number;
  customPrice2: number;
  customRent: number;
  customProfitability: number;
  program: ProgramListType;
  attributions: string[];
  annexes: string[];
  others: string[];
  plan: string;
  ref: string;
  kitchen: {
    price: string | null;
    type: KitchenType | null;
  };
  venteInterne: boolean;
  ventePartenaireVi2p: boolean;
  promotions: null | Record<string, string>[];
}

type HeadingField = typeof LOT_CELL_TYPE_CHECKBOX | keyof LotJson;
export interface HeadingType {
  field?: HeadingField | HeadingField[];
  title: string | string[];
  sub?: string;
  type: string | string[];
  id: string;
  align?: 'left' | 'center' | 'right';
  separator?: 'before' | 'after' | 'both' | 'none';
  unit?: string;
  sort?: LotSortBy;
}

export type HeadingsWidthType = Record<string, number>;

type ParkingType = {
  nom: string;
  prixHT: number;
  prixTauxTvaNormale: number;
  prixTauxTvaReduite: number;
};

type ImageType = {
  url: string;
};

export type LotTypeV2 = {
  id: number;
  idCrm: string;
  referenceProgramme: string;
  reference: string;
  type: 'Autres' | 'Bureaux' | 'Caves' | 'Commerces' | 'Logements' | 'Parkings'; // Taxonmy Type de lot superieur
  nature: string; // Taxonomy Nature
  natureSimplifiee:
    | 'Appartement'
    | 'Autre'
    | 'Bureaux'
    | 'Cave'
    | 'Commerce'
    | 'Duplex'
    | 'Maison'
    | 'Parking'
    | 'Vente en bloc caves'
    | 'Vente en bloc logements'
    | 'Vente en bloc Parking'
    | 'Vente en bloc terrain'; // Taxonomy Nature simplifiée
  nbPieces: number;
  orientation: string; // Taxonomy Orientation
  etage: keyof typeof FLOORS; // Taxonomy Etage
  typeChauffage: 'Electrique' | 'Gaz' | 'Urbain'; // Taxonomy Type de chauffage
  statut: StatusLabel; // Taxonomy Statut du lot
  publication: boolean;
  dateExpiration?: Date;
  surfaces: {
    surfaceHabitable: number;
    surfaceAnnexes: number;
    surfaceBalcon: number;
    surfaceTerrasse: number;
    surfaceJardin: number;
    surfaceLoggia: number;
  };
  prix: {
    TVAalternative?: {
      tauxTva: string;
      prixTTC: number;
    };
    TVANormale: {
      tauxTva: string;
      prixTTC: number;
    };
    prixHTHorsMobilier: number;
    prixMobilierHT: number;
  };
  fiscalites: FiscalityValuesType[];
  investissementLocatif: {
    loyerBailMensuel: string | null;
    loyerMarcheHT: string | null;
    loyerMarcheAnnuelHT: string | null;
    rendementLocatif: string | null;
    rentabiliteLMNP: string | null;
    rentabilitePinel: string | null;
    plafondLoyerPinel: string | null;
    TVARecup: boolean;
    economieImpotPinel9ans: string | null;
  };
  dispositifsSpecifiques: {
    prixSociaux: boolean;
    prixMaitrises: boolean;
  };
  commerce: {
    contraintes?: string;
    eligibiliteAlimentaire: false;
    depotGarantie?: string;
  };
  promotions: Promotion[];
  medias: {
    media: string;
  }[];
  listeAttributions: string[];
  lotsSecondaires: {
    target_id: number;
    target_revision_id: number;
    target_type: string;
    target_uuid: string;
  }[];
  documents: DocumentTypeV2[];
  parkingVi3p: ParkingType[];
  caveVi3p: string[];
  parkings: string;
  typologie: string; // Taxonomy  Typologie de lot
  dispositif: {
    reference: string;
    tvaNormal?: number;
    tvaAlternative?: number;
    rentabilitePinel?: number;
    etage: string; // Taxonomy Etage
    orientation: string; // Taxonomy Orientation
    surfaceHabitable: number;
    prixMaitrises: boolean;
    surfaceBalcon: number;
    surfaceTerrasse: number;
    surfaceJardin: number;
    surfaceLoggia: number;
    typeChauffage: 'Electrique' | 'Gaz' | 'Urbain'; // Taxonomy Type de chauffage
    depotGarantie?: string;
    loyerMarcheHT?: number;
    prixTtcTvaNormal: number;
    prixTtcTvaAlternative?: number;
    statut: 'Acté' | 'Libre' | 'Livré' | 'Non offert' | 'Optionné' | 'Pré-réservé' | 'Réservé'; // Taxonomy Statut du lot
    economieImpotPinel9ans: number;
    plafondLoyerPinel: number;
    parking: string;
    cave: string;
    rendementLocatif?: number;
    nbPieces: number;
    pictogramme: string;
    loyerMarcheAnnuelHT?: number;
    prixMobilierHT: number;
  };
  parking: string;
  packs: {
    cuisineEquipee:
      | {
          type: KitchenType;
          prix: string;
        }
      | {
          type: null;
          prix: null;
        };
    eligibleAdb: boolean;
  };
  images: ImageType[];
  aidesVente: {
    fraisNotaireOfferts: {
      venteInterne: boolean;
      ventePartenaireVI2P: boolean;
    };
  };
  bloc_libre: {
    field_couleur_de_fond: string;
    field_couleur_de_texte: string;
    field_cta_form_paragraph: {
      field_document_reference: string;
      field_link_target_blank: boolean;
      field_url_cta: string;
      field_url_cta_titre: string;
      field_onglet_id: string;
      field_webform_id: string;
    };
    field_date_debut_affichage: string;
    field_date_fin_affichage: string;
    field_text_wysiwyg: string;
    field_titre_bloc_libre: string;
    field_visuel: {
      alt: string;
      url: string;
    };
  };
};

export type LotSortBy =
  | typeof SORT_PROGRAM_BY_POSITION
  | typeof SORT_LOT_BY_TAX
  | typeof SORT_LOT_BY_PROGRAM
  | typeof SORT_LOT_BY_EXPOSURE
  | typeof SORT_LOT_BY_NUMBER
  | typeof SORT_LOT_BY_KIND
  | typeof SORT_LOT_BY_FLOOR
  | typeof SORT_LOT_BY_SURFACE
  | typeof SORT_LOT_BY_SURFACE_ANNEX
  | typeof SORT_LOT_BY_PARKING
  | typeof SORT_LOT_BY_PLAN
  | typeof SORT_LOT_BY_PRICE_REDUCED_VAT
  | typeof SORT_LOT_BY_PRICE_INCLUDING_TAX
  | typeof SORT_LOT_BY_PRICE_IMMO_HT
  | typeof SORT_LOT_BY_PRICE_MOB_HT
  | typeof SORT_LOT_BY_RENT
  | typeof SORT_LOT_BY_RENT_CUSTOM
  | typeof SORT_LOT_BY_RENT_FURNISHED
  | typeof SORT_LOT_BY_PROFITABILITY
  | typeof SORT_LOT_BY_PROFITABILITY_CUSTOM
  | typeof SORT_LOT_BY_DELIVERY
  | typeof SORT_LOT_BY_STATUS
  | typeof SORT_LOT_BY_TYPE;

export type LotSortOrder = typeof SORT_ORDER_ASC | typeof SORT_ORDER_DESC;

export function isLotTypeV2(lot: LotTypeV2 | LotJson | undefined): lot is LotTypeV2 {
  return !!(lot as LotTypeV2 | undefined)?.reference && !!(lot as LotTypeV2 | undefined)?.referenceProgramme;
}

export function isLotList(lot: LotTypeV2 | LotJson | undefined): lot is LotJson {
  return !!(lot as LotJson | undefined)?.number && !!(lot as LotJson | undefined)?.ref;
}
