import React, { useContext, useState } from 'react';
import type { RefObject } from 'react';
import {
  ClickAwayListener as MuiClickAwayListener,
  Grow as MuiGrow,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@material-ui/core';

import { LABEL_PROGRAMS, LABEL_SEARCH_SUGGESTION } from 'settings/labels';
import { LOCATION_TYPE_DEPARTMENT } from 'settings/search';
import { TMS_TIMEOUT } from 'settings/tms';

import type {
  GeoSearchResult,
  GeoSearchResultCity,
  GeoSearchResultDepartment,
} from 'api/viOffresAPI/apiTypes/Autocomplete';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommander from 'modules/App/TagCommander/TagCommander';

import styles from './SuggestedSearch.module.scss';

interface SuggestedSearchProps {
  autocompleteOpen: boolean;
  defaultValues: GeoSearchResult[];
  onAutocompleteOptionClick: (value: GeoSearchResult[]) => void;
  option: GeoSearchResultCity;
  positionRef?: RefObject<HTMLDivElement>;
}

export default function SuggestedSearch({
  autocompleteOpen,
  defaultValues,
  onAutocompleteOptionClick,
  option,
  positionRef = undefined,
}: SuggestedSearchProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const [open, setOpen] = useState<boolean>(option.nbResultsDepartment > option.nbResults);
  const [suggestionsClicked, setSuggestionsClicked] = useState(false);

  function handleClick() {
    onAutocompleteOptionClick([
      ...defaultValues.filter(v => v.label !== option.label),
      {
        id: option.idDepartment,
        label: option.department,
        type: LOCATION_TYPE_DEPARTMENT,
        value: option.department,
      } as GeoSearchResultDepartment,
    ]);
  }

  if (autocompleteOpen) {
    return null;
  }

  return (
    <>
      <MuiPopper
        transition
        open={open}
        anchorEl={!isResponsive ? positionRef?.current : undefined}
        placement="bottom-start"
        style={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <MuiGrow {...TransitionProps}>
            <MuiPaper classes={{ root: styles.paper }}>
              <MuiClickAwayListener onClickAway={() => setOpen(false)}>
                <div className={styles.popin}>
                  <button
                    type="button"
                    className={styles.popinClose}
                    onClick={() => setOpen(false)}
                  >
                    <Icon className={styles.popinCloseIcon} icon="cross" />
                  </button>
                  <div className={styles.popinTitle}>
                    <Icon className={styles.popinTitleIcon} icon="idea" />
                    {LABEL_SEARCH_SUGGESTION}
                  </div>
                  <div className={styles.popinButtonWrapper}>
                    <button
                      type="button"
                      className={styles.popinButton}
                      onClick={() => {
                        setSuggestionsClicked(true);
                        setTimeout(() => {
                          handleClick();
                        }, TMS_TIMEOUT);
                      }}
                    >
                      <span>
                        {option.department} {option.nbResultsDepartment}
                      </span>{' '}
                      {LABEL_PROGRAMS}
                    </button>
                  </div>
                </div>
              </MuiClickAwayListener>
            </MuiPaper>
          </MuiGrow>
        )}
      </MuiPopper>

      {suggestionsClicked && (
        <TagCommander
          navigation_pagename="resultats_recherche_suggestion"
          navigation_template="recherche"
          search_location={[option.label]}
          useEffectDeps={['navigation_pagename', 'navigation_template', 'search_location']}
        />
      )}
    </>
  );
}
