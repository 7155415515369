import React from 'react';

import { formatActivityDate } from 'services/date';
import { PASTILLE_STATUS_COLOR, PASTILLE_STATUS_ICON } from 'services/pastille';
import { LABEL_DATE_END_ON } from 'settings/index';
import { LOT_STATUS_OPTIONED, LOT_STATUS_PRE_BOOKED } from 'settings/lots';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { Pastille } from 'commonUi/Pastille/Pastille';

import styles from './LotStatus.module.scss';

export function LotStatus({ lot }: LotStatusProps) {
  return (
    <div className={styles.root}>
      <span>
        <Pastille
          label={lot.statut}
          labelClassName={styles.pastille}
          icon={PASTILLE_STATUS_ICON[lot.statut]}
          bgColor={PASTILLE_STATUS_COLOR[lot.statut].bgColor}
          textColor={PASTILLE_STATUS_COLOR[lot.statut].textColor}
        />
      </span>
      {(lot.statut === LOT_STATUS_OPTIONED || lot.statut === LOT_STATUS_PRE_BOOKED) &&
        lot.dateExpiration && (
          <div className={styles.statutEndDateContainer}>
            <span className={styles.statutEndDate}>
              {LABEL_DATE_END_ON} {formatActivityDate(lot.dateExpiration, 'DD/MM/YY')}{' '}
            </span>
          </div>
        )}
    </div>
  );
}

type LotStatusProps = {
  lot: LotTypeV2;
};
