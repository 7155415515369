import React, { useMemo, useState } from 'react';

import { extractVideoIdFromUrl, makeYouTubeThumbnailFromId } from 'services/videos';
import { MEDIAS_TYPE_IMAGE, MEDIAS_TYPE_VIDEO } from 'settings/medias';

import { MediaType, mediaIsVideo } from 'types/medias';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import ModalLayout from 'commonUi/ModalLayout/ModalLayout';
import GallerySlider from 'commonUi/GallerySlider/GallerySlider';
import MediasVerticalSlider from 'commonUi/MediasVerticalSlider/MediasVerticalSlider';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldVideo from 'commonUi/FieldVideo/FieldVideo';

import styles from './ProgramMedias.module.scss';

export interface ProgramMediasProps {
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramMedias({ className, program }: ProgramMediasProps) {
  const [openModal, setOpenModal] = useState(false);

  const items = useMemo(() => {
    const images = program.perspectives?.slider ?? [];
    const result = images.map(media => ({
      url: media,
      properties: { type: MEDIAS_TYPE_IMAGE } as MediaType['properties'],
    }));
    if (program.videos && program.videos.length > 0) {
      result.unshift({
        url: program.videos[0],
        properties: { type: MEDIAS_TYPE_VIDEO },
      });
    }

    return result;
  }, [program]);

  if (!items.length) {
    return null;
  }

  return (
    <>
      <MediasVerticalSlider
        classes={{
          root: className,
          activeContainer: styles.activeContainer,
          slider: styles.mediasSlider,
          activeSlide: styles.activeItem,
          slide: styles.sliderItem,
          slideVideo: styles.videoMini,
        }}
        medias={items}
        onActiveClick={() => setOpenModal(true)}
      />
      <ModalLayout open={openModal} onClose={() => setOpenModal(false)}>
        <GallerySlider
          infinite
          slider={items}
          makeSlide={media => {
            switch (media.properties.type) {
              case MEDIAS_TYPE_VIDEO: {
                const videoId = extractVideoIdFromUrl(media.url);
                if (videoId) {
                  return {
                    key: videoId,
                    content: (
                      <div className={styles.videoContainer}>
                        <FieldVideo className={styles.video} id={videoId} isLazy={false} />
                      </div>
                    ),
                  };
                }
                return null;
              }
              case MEDIAS_TYPE_IMAGE:
                return {
                  key: media.properties.target_uuid,
                  content: <FieldImage media={media} />,
                };
              default:
                return null;
            }
          }}
          makeMiniature={media => {
            const videoId = mediaIsVideo(media) ? extractVideoIdFromUrl(media.url) : undefined;
            if (videoId) {
              return {
                key: media.url,
                content: <img src={makeYouTubeThumbnailFromId(videoId)} alt="" />,
              };
            }
            return {
              key: media.properties.target_uuid,
              content: <FieldImage media={media} />,
            };
          }}
        />
      </ModalLayout>
    </>
  );
}
