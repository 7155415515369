import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  booklet: {
    margin: '10px 0 0 5px',
    fontSize: '7px',
  },
  bookletLink: {
    fontStyle: 'italic',
    fontSize: '7px',
    textDecoration: 'none',
    color: COLORS.PRIMARY.BLUE_DARK,
  },
});
