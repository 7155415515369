import React, { useContext } from 'react';
import type { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer as MuiDrawer } from '@material-ui/core';

import {
  LABEL_ACTABILITY_DATE,
  LABEL_AND_MORE,
  LABEL_ANNEX,
  LABEL_APPARTMENT_TYPE,
  LABEL_CLEAR_ALL_CRITERIAS,
  LABEL_CLOSE,
  LABEL_DELIVERY_DATE,
  LABEL_FISCALITY,
  LABEL_OTHER_CRITERIAS,
  LABEL_PROFITABILITY,
  LABEL_RANGE_PRICE,
  LABEL_SURFACE_SIMPLE,
} from 'settings/labels';
import { OTHER_OPTIONS, SLIDER_RANGE } from 'settings/search';

import { budgetValueFormatter, budgetValueFormatterReverse } from 'services/formatter';
import { modifyQuery } from 'services/url';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import FilterOptions from 'commonUi/Filter/FilterOptions';
import RangeField from 'commonUi/RangeField/RangeField';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';
import ListSwitcher from 'modules/HomePage/Components/ListSwitcher/ListSwitcher';
import Autocomplete from 'modules/HomePage/Components/Search/Autocomplete/Autocomplete';
import SaveSearchButtonWithModal from 'modules/SaveSearch/Components/SaveSearchButton/SaveSearchButtonWithModal';

import styles from './FiltersPanel.module.scss';

interface FiltersPanelProps {
  isSavedSearchEdition?: boolean;
  onClose?: () => void;
  modal: ComponentProps<typeof SaveSearchButtonWithModal>['modal'];
  open: boolean;
  resultsLots: ComponentProps<typeof SaveSearchButtonWithModal>['resultsLots'];
  resultsPrograms: ComponentProps<typeof SaveSearchButtonWithModal>['resultsPrograms'];
}

export default function FiltersPanel({
  isSavedSearchEdition = false,
  onClose,
  modal,
  open,
  resultsLots,
  resultsPrograms,
}: FiltersPanelProps) {
  const history = useHistory();
  const {
    annexes: annexOptions,
    kinds: kindOptions,
    taxes: taxOptions,
    years: yearOptions,
  } = useContext(TaxonomiesContext);
  const searchFilters = useSearch();
  const {
    actability,
    annexes,
    budgetMax,
    budgetMin,
    delivery,
    kinds,
    others,
    profitabilityMax,
    profitabilityMin,
    surfaceMax,
    surfaceMin,
    taxes,
    type: searchType,
  } = searchFilters;

  const clearSearch = () => {
    const { searchId, type, ...currentFilters } = searchFilters; // Omit 'type' from search
    history.replace(modifyQuery({}, Object.keys(currentFilters)));
  };

  const budgetValue = {
    min: Number(budgetMin) || 0,
    max: Number(budgetMax) || Number(SLIDER_RANGE.values.budget.max),
  };
  const surfaceValue =
    surfaceMax || surfaceMin
      ? {
          min: Number(surfaceMin) || 0,
          max: Number(surfaceMax) || Number(SLIDER_RANGE.values.surface.max),
        }
      : {};
  const profitabilityValue =
    profitabilityMax || profitabilityMin
      ? {
          min: Number(profitabilityMin) || 0,
          max: Number(profitabilityMax) || Number(SLIDER_RANGE.values.profitability.max),
        }
      : {};

  function handleClose() {
    onClose?.();
    if (isSavedSearchEdition) {
      const { type, searchId, ...keysToRemove } = searchFilters;
      history.replace(
        modifyQuery({ panelMySearch: 'open' }, ['searchId', ...Object.keys(keysToRemove)])
      );
    }
  }

  return (
    <MuiDrawer
      anchor="right"
      classes={{ paper: styles.paper }}
      elevation={0}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <header className={styles.header}>
        <Tooltip title={LABEL_CLOSE}>
          <button type="button" className={styles.close} onClick={handleClose}>
            <i className="icon icon--arrow-left" />
          </button>
        </Tooltip>
        <div className={styles.title}>
          <SvgIcon className={styles.icon} iconId="icon-filters" />
          Filtres
        </div>
        <Tooltip title={LABEL_CLEAR_ALL_CRITERIAS}>
          <button type="button" className={styles.reset} onClick={clearSearch}>
            <SvgIcon className={styles.icon} iconId="icon-reset" />
          </button>
        </Tooltip>
      </header>

      <div className={styles.body}>
        <div className={styles.container}>
          <div className={styles.section}>
            <Autocomplete simple />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_RANGE_PRICE}</div>
            <RangeField
              classes={{ root: styles.range }}
              endAdornmentText={
                <>
                  K<sup>€</sup>
                </>
              }
              id="field-price"
              maxRange={Number(SLIDER_RANGE.values.budget.max)}
              minRange={0}
              moreLabelMax={`500K € ${LABEL_AND_MORE}`}
              name="field-price"
              onChange={value =>
                history.replace(modifyQuery({ budgetMax: value.max, budgetMin: value.min }))
              }
              values={budgetValue}
              valuesFormatter={budgetValueFormatter}
              valuesFormatterReverse={budgetValueFormatterReverse}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_DELIVERY_DATE}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ delivery: value }))}
              options={yearOptions}
              value={delivery}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_FISCALITY}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
                pastille: styles.pastille,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ taxes: value }))}
              options={taxOptions.map(term => ({ label: term.nom, value: term.id }))}
              value={taxes}
              withPastille
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_APPARTMENT_TYPE}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ kinds: value }))}
              options={kindOptions.map(term => ({ label: term.nom, value: term.id }))}
              value={kinds}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_ANNEX}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ annexes: value }))}
              options={annexOptions.map(term => ({ label: term.nom, value: term.id }))}
              value={annexes}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_SURFACE_SIMPLE}</div>
            <RangeField
              endAdornmentText={
                <>
                  m<sup>2</sup>
                </>
              }
              id="field-surface-range"
              maxRange={Number(SLIDER_RANGE.values.surface.max)}
              minRange={0}
              name="field-surface-range"
              onChange={value =>
                history.replace(modifyQuery({ surfaceMax: value.max, surfaceMin: value.min }))
              }
              values={surfaceValue}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_ACTABILITY_DATE}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ actability: value }))}
              options={yearOptions}
              value={actability}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_PROFITABILITY}</div>
            <RangeField
              endAdornmentText="%"
              id="field-profitability-range"
              maxRange={Number(SLIDER_RANGE.values.profitability.max)}
              minRange={0}
              name="field-profitability-range"
              onChange={value =>
                history.replace(
                  modifyQuery({ profitabilityMax: value.max, profitabilityMin: value.min })
                )
              }
              values={profitabilityValue}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.title}>{LABEL_OTHER_CRITERIAS}</div>
            <FilterOptions
              classes={{
                item: styles.value,
                checkbox: styles.checkbox,
                label: styles.label,
              }}
              maxOptions={4}
              multiple
              onChange={value => history.replace(modifyQuery({ others: value }))}
              options={OTHER_OPTIONS}
              value={others}
            />
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <ListSwitcher
          className={styles.switcher}
          type={searchType}
          lotsNumber={resultsLots?.length}
          lotsOnClick={onClose}
          programsNumber={resultsPrograms?.length}
          programsOnClick={onClose}
        />

        {isSavedSearchEdition && (
          <SaveSearchButtonWithModal
            modal={modal}
            resultsLots={resultsLots}
            resultsPrograms={resultsPrograms}
          />
        )}
      </footer>
    </MuiDrawer>
  );
}
