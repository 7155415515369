import React from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Drawer as MuiDrawer } from '@material-ui/core';

import styles from './MobileDrawer.module.scss';

interface MobileDrawerProps {
  className?: string;
  displayNone?: boolean;
  isDrawerOpen: boolean;
  openFrom?: ComponentProps<typeof MuiDrawer>['anchor'];
}

export default function MobileDrawer({
  children,
  className,
  displayNone,
  isDrawerOpen = false,
  openFrom = 'left',
}: PropsWithChildren<MobileDrawerProps>) {
  return (
    <MuiDrawer
      anchor={openFrom}
      BackdropProps={{
        invisible: true,
      }}
      classes={{
        root: classnames(styles.drawerRoot, { [styles.displayNone]: displayNone }),
        paper: styles.drawerPaper,
      }}
      elevation={0}
      open={isDrawerOpen}
      PaperProps={{
        classes: {
          root: styles.paperRoot,
        },
      }}
      variant="temporary"
    >
      <div className={classnames(className, styles.drawerContainer)}>{children}</div>
    </MuiDrawer>
  );
}
