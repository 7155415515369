import isMobileJS from 'ismobilejs';

export function detectNavigator() {
  const sAgent = window.navigator.userAgent;
  const iDx = sAgent.indexOf('MSIE');

  if (iDx > 0) {
    return parseInt(sAgent.substring(iDx + 5, sAgent.indexOf('.', iDx)), 10);
  }
  if (navigator.userAgent.match(/Trident\/7\./)) {
    return 11;
  }
  return 0;
}

export const isAppleDevice = () => isMobileJS(window.navigator.userAgent).apple.device;

export const isMobileDevice = () => {
  const detect = isMobileJS(window.navigator.userAgent);
  return detect.phone || detect.tablet;
};
