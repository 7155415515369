import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import { LABEL_DRAG } from 'settings/labels';
import {
  SEARCH_PANEL_DELTA,
  SEARCH_PANEL_HEIGHT_MIN,
  SEARCH_PANEL_MEDIUM_RATIO,
  SEARCH_PANEL_SIZE_FULL,
  SEARCH_PANEL_SIZE_MEDIUM,
  SEARCH_PANEL_SIZE_MIN,
  SEARCH_PANEL_SIZE_NULL,
} from 'settings/search';
import { HEADER_DOM_ID } from 'settings/ui';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getFilterableValues } from 'services/search';

import { useModal } from 'hooks/useModal';
import useSearchSortState from 'hooks/useSearchSortState';
import { useSortedLots } from 'hooks/useSortedLots';
import { useSortedPrograms } from 'hooks/useSortedPrograms';

import LotDropdownSort from 'commonUi/ListLotV2/LotDropdownSort/LotDropdownSort';
import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import SaveSearchButtonWithModal from 'modules/SaveSearch/Components/SaveSearchButton/SaveSearchButtonWithModal';
import useFilterData from '../../../hooks/useFilterData';
import { useSearch } from '../../../hooks/useSearch';

import FiltersPanel from '../../Search/FiltersPanel/FiltersPanel';
import SearchMap from '../../Search/SearchMap';
import Autocomplete from '../../Search/Autocomplete/Autocomplete';
import ListSwitcher from '../../ListSwitcher/ListSwitcher';
import ProgramsList from '../../ProgramsList/ProgramsList';
import { ProgramsDropdownSort } from '../../ProgramsDropdownSort/ProgramsDropdownSort';
import LotsListMobile from '../../LotsList/LotsListMobile';

import styles from './SearchLayout.module.scss';

export default function SearchLayoutMobile({
  tmsLots = {},
  tmsPrograms = {},
}: SearchLayoutMobileProps) {
  const search = useSearch();
  const { origin, programRef, searchId, taxes, type: searchType = 'program' } = search;
  const filterableValues = getFilterableValues(search);
  const modal = useModal();
  const { taxesById } = useContext(TaxonomiesContext);

  const dragRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  const [panelHeight, setPanelHeight] = useState(SEARCH_PANEL_HEIGHT_MIN);
  const [panelSize, setPanelSize] = useState(
    searchId ? SEARCH_PANEL_SIZE_FULL : SEARCH_PANEL_SIZE_MEDIUM
  );
  const [isMoreCriteriasOpen, setIsMoreCriteriasOpen] = useState(false);

  const {
    programs: { sortBy: programsSortBy, sortOrder: programsSortOrder },
    lots: { sortBy: lotsSortBy, sortOrder: lotsSortOrder },
    reorderPrograms,
    reorderLots,
  } = useSearchSortState(filterableValues.locations);

  const { filterData, isLoading: isDataLoading } = useFilterData();
  const { lots: filteredLots, programs: filteredPrograms, lotNbByPrograms } = useMemo(
    () => filterData(filterableValues),
    [filterData, JSON.stringify(filterableValues)]
  );

  const { programs: sortedPrograms, isLoading: isProgramSortLoading } = useSortedPrograms(
    filteredPrograms,
    programsSortBy,
    programsSortOrder
  );

  let programsRef =
    filteredLots?.length > 0 ? filteredLots?.map(lot => ({ programRef: lot.ref })) : [];
  programsRef =
    sortedPrograms?.length > 0
      ? sortedPrograms.map(program => ({ programRef: program.ref }))
      : programsRef;
  const { lots: sortedLots, isLoading: isLotSortLoading } = useSortedLots(
    filteredLots,
    lotsSortBy,
    lotsSortOrder,
    programsRef
  );

  let panelClassSuffix = panelSize || SEARCH_PANEL_SIZE_MIN;

  const selectedTaxes = taxes.length && taxesById ? taxes.map(taxeId => taxesById[taxeId]) : [];

  if (programRef) {
    panelClassSuffix = SEARCH_PANEL_SIZE_NULL;
  }

  useEffect(() => {
    function onResize() {
      const header = document.getElementById(HEADER_DOM_ID);
      setScreenHeight(window.innerHeight - (header?.offsetHeight || 0));
    }
    if (screenHeight === 0) {
      onResize();
    }
    window.addEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    if (programRef) {
      setPanelSize(SEARCH_PANEL_SIZE_NULL);
    } else {
      setPanelSize(prevState =>
        (prevState === SEARCH_PANEL_SIZE_NULL && origin === 'list') || searchId
          ? SEARCH_PANEL_SIZE_FULL
          : SEARCH_PANEL_SIZE_MIN
      );
    }
  }, [programRef, origin, searchId]);

  useEffect(() => {
    if (searchId) {
      setIsMoreCriteriasOpen(true);
    }
  }, [searchId]);

  return (
    <ActivityModalsProvider>
      <div
        className={classnames(styles.root, {
          [styles.isDragged]: isDragging,
        })}
        style={{ height: `${screenHeight}px` }}
      >
        <div
          className={classnames(styles.panel, styles[`panel${panelClassSuffix}`])}
          ref={panelRef}
          style={isDragging ? { height: `${panelHeight}px` } : {}}
        >
          <button
            className={styles.dragBtn}
            type="button"
            onTouchStart={() => {
              dragRef.current?.addEventListener('touchmove', e => {
                const height = window.innerHeight - e.changedTouches[0].clientY;

                setIsDragging(true);
                setPanelHeight(height < SEARCH_PANEL_HEIGHT_MIN ? SEARCH_PANEL_HEIGHT_MIN : height);
              });
            }}
            onTouchEnd={() => {
              setIsDragging(false);

              if (panelHeight > SEARCH_PANEL_HEIGHT_MIN + SEARCH_PANEL_DELTA) {
                setPanelSize(SEARCH_PANEL_SIZE_MEDIUM);
              } else {
                setPanelSize(SEARCH_PANEL_SIZE_MIN);
              }

              if (panelHeight > window.innerHeight * SEARCH_PANEL_MEDIUM_RATIO) {
                setPanelSize(SEARCH_PANEL_SIZE_FULL);
              }
            }}
            ref={dragRef}
          >
            {LABEL_DRAG}
          </button>
          <div className={styles.panelHeader}>
            <ListSwitcher
              type={searchType}
              lotsNumber={filteredLots?.length}
              lotsOnClick={() => setPanelSize(SEARCH_PANEL_SIZE_FULL)}
              programsNumber={sortedPrograms?.length}
            />
            <div className={styles.panelHeaderInner}>
              <div>
                <SaveSearchButtonWithModal
                  modal={modal}
                  resultsLots={filteredLots}
                  resultsPrograms={sortedPrograms}
                />
              </div>
              {searchType === 'lot' && (
                <LotDropdownSort
                  taxes={selectedTaxes}
                  reorderLots={reorderLots}
                  lotsSortBy={lotsSortBy}
                  lotsSortOrder={lotsSortOrder}
                />
              )}
              {searchType === 'program' && (
                <ProgramsDropdownSort
                  reorderPrograms={reorderPrograms}
                  sortBy={programsSortBy}
                  sortOrder={programsSortOrder}
                />
              )}
            </div>
          </div>
          <div
            className={classnames(styles.panelContent, { [styles.panelContentLocked]: searchId })}
            id="panelContent"
          >
            {searchId && <div className={styles.panelContentHideLayer} />}
            {searchType === 'lot' ? (
              <LotsListMobile
                isLoading={isDataLoading || isLotSortLoading}
                displayAllHeader
                reduceMobilePanel={() => setPanelSize(SEARCH_PANEL_SIZE_MIN)}
                sortedLots={sortedLots}
                {...tmsLots}
              />
            ) : (
              <ProgramsList
                availableLots={lotNbByPrograms}
                programs={sortedPrograms}
                isLoading={isDataLoading || isProgramSortLoading}
                {...tmsPrograms}
              />
            )}
          </div>
        </div>
        <div
          className={classnames(styles.map, {
            [styles.mapFull]: programRef,
          })}
        >
          <SearchMap programs={sortedPrograms} taxById={taxesById} />
          <div className={classnames(styles.location, styles.onMap)}>
            <Autocomplete />
            <button
              className={styles.moreCriteriasBtn}
              type="button"
              onClick={() => setIsMoreCriteriasOpen(true)}
            >
              <Icon className={styles.moreCriteriasBtnIcon} icon="criterias" />
            </button>
            <FiltersPanel
              isSavedSearchEdition={!!search.searchId}
              modal={modal}
              open={isMoreCriteriasOpen}
              onClose={() => setIsMoreCriteriasOpen(false)}
              resultsLots={filteredLots}
              resultsPrograms={sortedPrograms}
            />
          </div>
        </div>
      </div>

      <TagCommanderEvent
        isActive={isMoreCriteriasOpen}
        navigation_pagename="resultats_pluscriteres"
        navigation_template="recherche"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </ActivityModalsProvider>
  );
}

export type SearchLayoutMobileProps = {
  tmsLots?: Record<string, string>;
  tmsPrograms?: Record<string, string>;
};
