import axios from 'axios';

import { APP_URL_PENDING } from 'settings/app';
import { error } from './log';

/**
 * Get contract data.
 * @param documentUrl - url to backoffice drupal
 * @param [setDownloadProgression] - function to set percent of progression
 */
export function downloadContract(
  documentUrl: string,
  setDownloadProgression?: (percent: number) => void
) {
  return axios.get<ArrayBuffer>(documentUrl, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(window.vinci.REACT_APP_SESSION_AUTH_KEY)}`,
      'Ocp-Apim-Subscription-Key': window.vinci.REACT_APP_VINCI_VI3P_API_KEY,
      withCredentials: true,
    },
    responseType: 'arraybuffer',
    onDownloadProgress: progressEvent => {
      if (
        typeof setDownloadProgression === 'function' &&
        progressEvent &&
        progressEvent.loaded &&
        progressEvent.loaded
      ) {
        const total = parseFloat(progressEvent.total);
        const current = progressEvent.loaded;
        const percentCompleted = Math.floor((current / total) * 100);
        setDownloadProgression(percentCompleted);
      }
    },
  });
}

/**
 * Opens a blob in a new tab. On most device, the tab is opened after the blob has been loaded, but if
 * specified, the tab is opened first and then redirected once the blob is loaded. The goal is to avoid
 * browsers preventing a new tab from being asynchronously opened.
 */
export async function openBlob(
  blobPromise: (() => Promise<Blob | undefined>) | undefined,
  fileName?: string,
  openFirst = false,
  pendingPageUrl = APP_URL_PENDING
) {
  if (!blobPromise) {
    error('logic', 'No Blob provided');
    return;
  }
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blobData = await blobPromise();
    window.navigator.msSaveOrOpenBlob(blobData, fileName);
  }

  if (openFirst) {
    const windowReference = window.open(pendingPageUrl);
    if (windowReference) {
      try {
        const blobData = await blobPromise();
        windowReference.location.href = window.URL.createObjectURL(blobData);
      } catch (error) {
        windowReference.close();
        throw new Error(error);
      }
    }
  }

  const blobData = await blobPromise();
  window.URL = window.URL || window.webkitURL;
  const data = window.URL.createObjectURL(blobData);
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';
  // link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
}
