import { createContext } from 'react';

export const BLOC_ALERTE_MODAL_CONTEXT_DEFAULT = {
  showBlock: false,
  setShowBlock: () => {},
  setBlockAsSeenByUser: () => {},
  settings: {
    titre: undefined,
    image: undefined,
    contenu: undefined,
    bloc_alerte_cta: {
      libelle: undefined,
      url: undefined,
      target: undefined,
    },
    est_affiche: undefined,
    date_modification: undefined,
  },
  theme: undefined,
  userIdCrm: undefined,
};

export const blocAlerteModalContext = createContext(BLOC_ALERTE_MODAL_CONTEXT_DEFAULT);
