import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import qs from 'query-string';

import { LABEL_ADDRESS } from 'settings/labels';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramPdfLocalisationStyles';

export default function ProgramPdfLocalisation({ program }: ProgramPdfLocalisationProps) {
  const {
    localisation: { adresse, codePostal, latitude: lat, longitude: lng, ville },
  } = program;

  const queryParams = qs.stringify(
    {
      center: `${lat},${lng}`,
      key: window.vinci.REACT_APP_GOOGLE_MAP_API_KEY,
      mapType: 'roadmap',
      markers: `icon:${window.location.origin}/images/map-pin-reduced.png|${lat},${lng}`,
      scale: 2,
      size: `578x343`,
      style: [
        'feature:administrative|element:labels.text.fill|color:0x444444',
        'feature:landscape|element:all|color:0xf2f2f2',
        'feature:poi|element:all|visibility:on',
        'feature:road|element:all|saturation:-100|lightness:45',
        'feature:road.highway|element:all|visibility:simplified',
        'feature:road.arterial|element:labels.icon|visibility:off',
        'feature:transit|element:all|visibility:on',
        'feature:water|element:all|color:0x70c1e2|visibility:on',
      ],
      zoom: 15,
    },
    {
      encode: false,
      arrayFormat: 'none',
    }
  );

  return (
    <View style={styles.block}>
      <View style={styles.addressWrapper}>
        <Text>{LABEL_ADDRESS}</Text>
        <Text style={styles.address}>{`${adresse} ${codePostal} ${ville}`}</Text>
        <Image
          style={styles.image}
          src={`https://maps.googleapis.com/maps/api/staticmap?${queryParams}`}
        />
      </View>
    </View>
  );
}

export type ProgramPdfLocalisationProps = {
  program: ProgramTypeV2;
};
