import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  infos: {
    backgroundColor: COLORS.GREYS.PURE_WHITE,
    display: 'flex',
    flexDirection: 'row',
    margin: '0 -5px',
    padding: '8px 0',
    position: 'relative',
  },
  col: {
    backgroundColor: COLORS.GREYS.WHITE,
    margin: '0 5px',
  },
  colGeneralInfos: {
    width: '24%',
  },
  colHousingInfos: {
    width: '22%',
  },
  colAdditionalInfos: {
    width: '29%',
  },
  colFinancialInfos: {
    width: '25%',
  },
  colContent: {
    padding: '8px 12px',
  },
  title: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '8px',
    fontWeight: 'medium',
    margin: '0 0 3px',
  },
  titleBorder: {
    backgroundColor: COLORS.PRIMARY.BLUE_DARK,
    height: '1px',
    margin: '0 0 4px',
    width: '25px',
  },
  image: {
    margin: '7px 0',
    width: '100%',
  },
  kitchen: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '15px 0 5px 0',
    textAlign: 'center',
  },
  kitchenPicto: {
    width: '20px',
  },
  kitchenLabel: {
    color: COLORS.GREYS.PURE_GREY,
    fontSize: '6px',
  },
  kitchenPrice: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '6px',
    fontWeight: 'medium',
  },
  imgControlledPrices: {
    height: '10px',
    margin: '0 0 0 5px',
    width: '23px',
  },
  subCols: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 -4px',
  },
  subColsCol: {
    padding: '0 4px',
    width: '50%',
  },
  parkingList: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '8px',
    padding: '0 0 0 5px',
    position: 'relative',
  },
  listDot: {
    backgroundColor: COLORS.GREYS.GREY,
    borderRadius: '50%',
    height: '2px',
    left: 0,
    position: 'absolute',
    top: '5px',
    width: '2px',
  },
  listLabel: {
    margin: '0 2px 0 0',
    fontSize: '8px',
  },
  listValue: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '8px',
    fontWeight: 'medium',
  },
  parkingDetails: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  parkingDetailsLabel: {
    color: COLORS.GREYS.PURE_GREY,
    fontSize: '6px',
  },
  parkingDetailsValue: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '6px',
    fontWeight: 'medium',
  },
  note: {
    color: COLORS.GREYS.PURE_GREY,
    fontSize: '6px',
    textAlign: 'right',
  },
});
