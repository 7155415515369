import React, { useContext, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import * as JsPDF from 'jspdf';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import {
  LABEL_COMPARISON,
  LABEL_DOWNLOAD,
  LABEL_RETURN,
  LABEL_SAVE_IN_FOLDER,
  LABEL_SEND_COMPARISON,
} from 'settings/labels';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import { useModal } from 'hooks/useModal';
import { usePanels } from 'hooks/usePanels';

import { getLot, getProgramByRef } from 'api/viOffresAPI/apiClient';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Button from 'commonUi/Button/Button';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import { ModalSendComparison } from 'modules/ActionsProgramsAndLots/ModalSendComparison/ModalSendComparison';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import FolderLinks from 'modules/Folders/FolderLinks';
import { ComparisonTable } from '../ComparisonTable/ComparisonTable';

import styles from './ComparisonPage.module.scss';

async function getComparisonDatas(url: string) {
  const JOIN_CHARACTER = ',';
  const { lots: lotsParams } = qs.parse(url, {
    arrayFormat: 'bracket',
  }) as { lots: string[] | undefined };
  const programsPromise = Array.from(
    new Set(lotsParams?.map(lot => lot.split(JOIN_CHARACTER)[0]))
  ).map(programRef => getProgramByRef(programRef));
  const lotsPromise = Array.from(
    new Set(lotsParams?.map(lot => lot.split(JOIN_CHARACTER) as [string, string]))
  ).map(([programRef, lotNumber]) => getLot(programRef, lotNumber));
  const programs = await Promise.all(programsPromise).then(programs =>
    Object.fromEntries(programs.map(program => [program.referenceProgramme, program]))
  );
  const lots = await Promise.all(lotsPromise).then(lots =>
    Object.fromEntries(
      lots.map(lot => [`${lot.referenceProgramme}${JOIN_CHARACTER}${lot.reference}`, lot])
    )
  );
  return (
    lotsParams?.map(lot => ({
      program: programs[lot.split(',')[0]],
      lot: lots[lot],
    })) ?? []
  );
}

export function ComparisonPageDesktopAndTablet() {
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search, { arrayFormat: 'bracket' });
  const tableRef = useRef<HTMLDivElement>(null);
  const { emptyDatas, setFolderComparisons, setFolderSaveType } = useContext(foldersContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const { closeModal, open, openModal } = useModal();
  const { data: comparisonDatas } = useSWR(
    location.search ? location.search : null,
    getComparisonDatas
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  if (!params.lots) {
    return null;
  }

  async function createTablePDF() {
    setIsDownloading(true);
    await html2canvas(tableRef.current, {
      quality: 4,
      allowTaint: true,
      ignoreElements: true,
      scale: 1,
    }).then(canvas => {
      const pdf = new JsPDF({
        format: [tableRef.current?.offsetWidth, tableRef.current?.offsetHeight],
        hotfixes: ['px_scaling'],
        orientation: 'landscape',
        unit: 'px',
      });
      pdf.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0);
      pdf.save(`${document.title}.pdf`);
    });
    setIsDownloading(false);
  }

  function handleAddFolderClick() {
    emptyDatas();
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('comparison');
    setFolderComparisons(
      (params.lots as string[]).map(str => ({
        lotNumber: str.split(',')[1],
        programRef: str.split(',')[0],
      }))
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <button className={styles.returnBtn} onClick={() => history.goBack()} type="button">
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_RETURN}</span>
        </button>
        <div className={styles.title}>
          <Icon className={styles.titleIcon} icon="compare" />
          {LABEL_COMPARISON}
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonsItem}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-download-bicolor"
              iconClass={styles.buttonSvgIcon}
              onClick={createTablePDF}
              loading={isDownloading}
              variant="outlined"
              tooltip={LABEL_DOWNLOAD}
            />
          </div>
          <div className={styles.buttonsItem}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-send"
              iconClass={styles.buttonSvgIcon}
              onClick={openModal}
              variant="outlined"
              tooltip={LABEL_SEND_COMPARISON}
            />
          </div>
          <div className={styles.buttonsItem}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-add-to-folder"
              iconClass={styles.buttonSvgIcon}
              onClick={handleAddFolderClick}
              variant="outlined"
              tooltip={LABEL_SAVE_IN_FOLDER}
            />
          </div>
        </div>
      </div>
      <div className={styles.content} ref={tableRef}>
        <ComparisonTable comparisonDatas={comparisonDatas} />
      </div>

      <ModalSendComparison
        closeCallBack={closeModal}
        comparisonDatas={comparisonDatas}
        handleDownload={createTablePDF}
        isDownloading={isDownloading}
        open={open}
      />
      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      <TagCommanderEvent
        isActive={isDownloading}
        navigation_pagename="comparaison.telecharger"
        navigation_template="comparaison"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}
