import React from 'react';
import type { ComponentProps } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

import styles from './PdfPastilleStyles';

interface PdfPastilleProps {
  backgroundColor?: string;
  label: string;
  labelStyle?: any;
  picto?: ComponentProps<typeof PdfSvgPicto>['picto'];
  pictoStyle?: any;
  textColor?: string;
}

export default function PdfPastille({
  backgroundColor = COLORS.SECONDARY.PINK,
  label,
  labelStyle,
  picto,
  pictoStyle,
  textColor,
}: PdfPastilleProps) {
  return (
    <View style={[styles.pastille, { backgroundColor }]}>
      {picto && (
        <View style={[styles.picto, pictoStyle]}>
          <PdfSvgPicto picto={picto} />
        </View>
      )}
      <View style={[styles.label, labelStyle]}>
        <Text style={{ color: textColor }}>{label}</Text>
      </View>
    </View>
  );
}
