import React, { useContext } from 'react';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';

import LotCardLoadProgramAndLot from '../../../commonUi/LotCard/LotCardLoadProgramAndLot';

import styles from './ListAddItems.module.scss';

export function LotsList() {
  const { folderLots } = useContext(foldersContext);
  const { isResponsive } = useContext(ResponsiveContext);

  if (folderLots.length === 0) {
    return null;
  }

  const lotsCardsHtml = folderLots.map(item => (
    <div className={styles.card} key={`${item.programRef}-${item.lotNumber}`}>
      <LotCardLoadProgramAndLot lotNumber={item.lotNumber} programRef={item.programRef} />
    </div>
  ));

  return (
    <div>{isResponsive ? lotsCardsHtml : <CustomScrollbar>{lotsCardsHtml}</CustomScrollbar>}</div>
  );
}
