import React from 'react';
import type { ComponentProps } from 'react';
import dayjs from 'dayjs';
import { LABEL_TODAY } from 'settings/labels';
import { formatActivityDate } from 'services/date';
import { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';
import Notification from './Notification';
import styles from './NotificationsList.module.scss';

export function NotificationWithHeader({
  notif,
  displayHeader,
  onClick,
}: {
  notif: NotificationType;
  displayHeader: boolean;
  onClick: ComponentProps<typeof Notification>['handleClick'];
}) {
  const { date } = notif;
  const dateFormatted = formatActivityDate(date, 'DD/MM/YYYY');
  const isToday = dayjs().isSame(date, 'day');

  return (
    <li key={notif.notification_id}>
      {displayHeader && (
        <span className={styles.headerDate} key={`notif-${dateFormatted}`}>
          {isToday ? LABEL_TODAY : dateFormatted}
        </span>
      )}
      <Notification handleClick={onClick} key={notif.notification_id} notification={notif} />
    </li>
  );
}
