import React, { ContextType, useCallback, useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import useSWRImmutable from 'swr/immutable';
import memoize from 'memoizee';

import { LOT_JSON_ATTRIBUTION, LOT_JSON_NUMBER, LOT_JSON_PROGRAM_REF } from 'settings/lots';

import type { LotExport } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramExport } from 'api/viOffresAPI/apiTypes/Program';

import programLotContext from 'modules/App/Contexts/programLotContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import { filterNullable } from 'services/array';
import { formatLot } from 'services/lots';
import { formatProgramsSwitchedOff, formatProgramsSwitchedOn } from 'services/programs';

export default function ProgramLotProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { settings } = useContext(settingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { exclusionList, isExcluded, isExclusionListLoading, userCrm } = useContext(userContext);
  const { taxesById } = useContext(TaxonomiesContext);

  const { data: lots, isLoading: isLotsLoading } = useSWRImmutable<LotExport[]>(
    window.vinci.REACT_APP_STORAGE_LOTS_PATH,
    () =>
      fetch(`${window.vinci.REACT_APP_STORAGE_LOTS_PATH}?${new Date().getTime()}`).then(stream =>
        stream.json()
      ),
    { keepPreviousData: true }
  );
  const processedLots = useMemo(() => {
    if (!lots?.length) {
      return [];
    }
    if (isAttributionSwitchedOn) {
      if (!exclusionList) {
        // If there is no exclusion list, something went wrong somewhere OR the list hasn't been
        // loaded yet, so we cut the calculations short  and show nothing for now.
        return [];
      }
      return lots.filter(lot => !isExcluded(lot[LOT_JSON_PROGRAM_REF], lot[LOT_JSON_NUMBER]));
    }
    return lots.filter(lot =>
      userCrm?.extension_VI3P_ListeAttribution !== undefined
        ? lot[LOT_JSON_ATTRIBUTION].includes(userCrm?.extension_VI3P_ListeAttribution)
        : false
    );
  }, [isAttributionSwitchedOn, userCrm?.extension_VI3P_ListeAttribution, lots, exclusionList]);

  const { data: programs, isLoading: isProgramsLoading } = useSWRImmutable<ProgramExport[]>(
    window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH,
    () =>
      fetch(
        `${window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH}?${new Date().getTime()}`
      ).then(stream => stream.json()),
    { keepPreviousData: true }
  );
  const processedPrograms = useMemo(() => {
    if (!programs?.length) {
      return [];
    }
    if (isAttributionSwitchedOn) {
      return formatProgramsSwitchedOn(programs, processedLots, taxesById);
    }
    return formatProgramsSwitchedOff(programs, userCrm?.extension_VI3P_ListeAttribution);
  }, [programs, isAttributionSwitchedOn, taxesById, processedLots]);

  const getProgram = useMemo<ContextType<typeof programLotContext>['getProgram']>(
    () => memoize(programRef => processedPrograms.find(program => program.ref === programRef)),
    [processedPrograms]
  );
  const getPrograms = useCallback<ContextType<typeof programLotContext>['getPrograms']>(
    callback => processedPrograms.filter(program => callback(program)),
    [processedPrograms]
  );
  const getLot = useCallback<ContextType<typeof programLotContext>['getLot']>(
    (programRef, lotNumber) => {
      const lot = processedLots.find(
        lot => lot[LOT_JSON_PROGRAM_REF] === programRef && lot[LOT_JSON_NUMBER] === lotNumber
      );
      const program = getProgram(programRef);
      return lot && program ? formatLot(lot, program) : undefined;
    },
    [processedLots, getProgram]
  );
  const getLots = useCallback<ContextType<typeof programLotContext>['getLots']>(
    callback =>
      filterNullable(
        processedLots
          .filter(lot => callback(lot))
          .map(lot => {
            const program = getProgram(lot[LOT_JSON_PROGRAM_REF]);
            return program ? formatLot(lot, program) : undefined;
          })
      ),
    [processedLots, getProgram]
  );

  return (
    <programLotContext.Provider
      value={{
        lots: processedLots,
        programs: processedPrograms,
        getProgram,
        getPrograms,
        getLot,
        getLots,
        isLoading: isLotsLoading || isProgramsLoading || isExclusionListLoading,
        isLotsLoading: isLotsLoading || (isAttributionSwitchedOn && isExclusionListLoading),
        isProgramsLoading: isProgramsLoading || (isAttributionSwitchedOn && isExclusionListLoading),
      }}
    >
      {children}
    </programLotContext.Provider>
  );
}
