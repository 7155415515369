import React, { useContext } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { InfoBox as GoogleInfoBox, Marker as GoogleMarker } from '@react-google-maps/api';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { kelQuartierModuleContext } from 'sharedModulesV4/KelQuartier/contexts/kelQuartierModuleContext';

import InfoBox from '../InfoBox/InfoBox';

function getLatLngFromMarker(marker: [number, number]) {
  if (window.google?.maps) {
    return new window.google.maps.LatLng(marker[1], marker[0]) as google.maps.LatLng;
  }
  return undefined;
}

interface MarkerProps
  extends Pick<
    ComponentProps<typeof InfoBox>,
    'displayKelQuartier' | 'displayKelQuartierToggle' | 'taxById' | 'toggleKelQuartier'
  > {
  clusterer?: ComponentProps<typeof GoogleMarker>['clusterer'];
  customIcon?: string;
  customInfobox?: ReactNode;
  hideLabel?: boolean;
  isOpen?: boolean;
  lat: number;
  lng: number;
  nid: string | number;
  onToggleInfoBox?: (refOrNid?: string | number) => void;
  program?: ProgramListType;
}

export default function Marker({
  clusterer,
  customIcon = '/images/map-pin.svg',
  customInfobox,
  displayKelQuartier,
  displayKelQuartierToggle,
  hideLabel = false,
  isOpen = false,
  lat,
  lng,
  nid,
  onToggleInfoBox,
  program,
  taxById,
  toggleKelQuartier,
}: MarkerProps) {
  const { addresses } = useContext(kelQuartierModuleContext);
  const position = getLatLngFromMarker([lng, lat]);
  if (!position) {
    return null;
  }

  const label: google.maps.MarkerLabel = hideLabel
    ? { text: ' ' }
    : {
        color: '#00264C',
        fontFamily: 'Vinci Sans, Arial, sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        text: ' ',
      };

  function handleToggleInfoBox() {
    if (typeof onToggleInfoBox === 'function') {
      if (program) {
        onToggleInfoBox(program.ref);
        return;
      }
      onToggleInfoBox(nid);
    }
  }

  const poiAddress = addresses.get(nid);
  return (
    <GoogleMarker
      clusterer={clusterer}
      position={position}
      icon={{
        url: customIcon,
        labelOrigin: { x: 20, y: 20 } as google.maps.Point,
      }}
      label={label}
      onClick={handleToggleInfoBox}
      opacity={isOpen ? 0.5 : 1}
      visible
    >
      {program && !customInfobox && (
        <InfoBox
          isOpen={isOpen}
          onClose={handleToggleInfoBox}
          position={position}
          program={program}
          taxById={taxById}
          displayKelQuartier={displayKelQuartier}
          displayKelQuartierToggle={displayKelQuartierToggle}
          toggleKelQuartier={toggleKelQuartier}
        />
      )}

      {customInfobox && isOpen && (
        <GoogleInfoBox
          onCloseClick={handleToggleInfoBox}
          options={{
            pixelOffset: new (window as any).google.maps.Size(-140, -60),
            alignBottom: true,
            boxStyle: {
              boxShadow: `3px 3px 10px rgba(0,0,0,0.6)`,
              width: '202px',
            },
            closeBoxURL: '',
            enableEventPropagation: false,
          }}
          position={position}
        >
          <div className="google_map_infobox">
            <div className="list-item-programs">
              <div className="marker-item-programs-title">{customInfobox}</div>
              {poiAddress && <div className="marker-item-programs-price-label">{poiAddress}</div>}
            </div>
          </div>
        </GoogleInfoBox>
      )}
    </GoogleMarker>
  );
}
