import React from 'react';
import classnames from 'classnames';

import type { ActivityHeading } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { HeadingType } from 'api/viOffresAPI/apiTypes/LotType';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './TableHeader.module.scss';

interface TableHeaderType<Heading extends HeadingType | ActivityHeading> {
  col: Heading;
  reorder: (heading: Heading, statuses?: any) => void;
  statuses?: any;
  sortBy: string;
  style?: any;
  sortOrder: string;
  TagName?: keyof JSX.IntrinsicElements;
}

export default function TableHeader<Heading extends HeadingType | ActivityHeading>({
  col,
  reorder,
  sortBy,
  sortOrder,
  statuses,
  style = undefined,
  TagName = 'div',
}: TableHeaderType<Heading>) {
  const title = (
    <>
      <span className={styles.cellText}>
        {(Array.isArray(col.title) ? col.title : [col.title]).map((title, index, array) => (
          <div key={title} className={styles.cellTitle}>
            {title}&nbsp;{index !== array.length - 1 && '&'}
          </div>
        ))}
        {col.sub && <span className={styles.additionalInfo}>{col.sub}</span>}
      </span>
      {!!col.sort && <SvgIcon className={styles.sortIcon} iconId="icon-sort-list" />}
    </>
  );

  const cellBaseClassName = classnames(styles.cell, {
    [styles.alignLeft]: col.align === 'left',
    [styles.alignCenter]: col.align === 'center',
    [styles.alignRight]: col.align === 'right',
    [styles.separatorBefore]: typeof col.separator === 'undefined' || col.separator === 'before',
    [styles.separatorAfter]: col.separator === 'after',
    [styles.separatorBoth]: col.separator === 'both',
    [styles.separatorNone]: col.separator === 'none',
  });

  if (!col.sort) {
    return (
      <TagName className={cellBaseClassName} style={style}>
        <span className={styles.cellContent}>{title}</span>
      </TagName>
    );
  }
  return (
    <TagName
      style={style}
      className={classnames(cellBaseClassName, {
        [styles.highlight]: col.sort === sortBy,
        [styles.asc]: col.sort === sortBy && sortOrder === 'ASC',
      })}
    >
      <button type="button" className={styles.cellContent} onClick={() => reorder(col, statuses)}>
        {title}
      </button>{' '}
    </TagName>
  );
}
