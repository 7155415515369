import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_DOCUMENTS_SEND,
  LABEL_EMAIL_CONTENT,
  LABEL_EMAIL_FORM_ERROR_TITLE,
  LABEL_EMAIL_RECEIVER,
  LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR,
  LABEL_EMAIL_SUBJECT,
  LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_SEND_DOCUMENTS,
  MAIL_MESSAGE_DOCUMENTS,
} from 'settings';

import { regexEmail } from 'services/constraints';

import userContext from 'modules/App/Contexts/userContext';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import TextField from 'commonUi/TextField/TextField';

import styles from '../ModalAction.module.scss';

interface FormSendDocumentsProps {
  documents: any[];
  handleClose: () => void;
}

export function FormSendDocuments({ documents, handleClose }: FormSendDocumentsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { userCrm } = useContext(userContext);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    setValue('field-content', MAIL_MESSAGE_DOCUMENTS(userCrm));
  }, []);

  const { control, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(sendBookletSchema),
    mode: 'onChange',
  });
  const handleSendMail = handleSubmit(async values => {
    setLoading(true);
    try {
      await axiosVI3PInstance.post(`mailer`, {
        to: values?.['field-receiver'],
        subject: values?.['field-object'],
        message: values?.['field-content'],
        attachments: documents,
        type: 'document',
        prescripteurFullName: userCrm ? `${userCrm.given_name} ${userCrm.family_name}` : undefined,
        prescripteurEmail: userCrm?.email,
      });
      enqueueSnackbar(LABEL_CONFIRM_DOCUMENTS_SEND);
      handleClose();
    } catch (newError) {
      if (newError?.response?.status) {
        setApiError(true);
      }
    }
    setLoading(false);
  });
  const { isDirty, isValid, errors } = formState;

  return (
    <form onSubmit={handleSendMail}>
      <div className={styles.form}>
        <div className={styles.input}>
          <Controller
            name="field-receiver"
            control={control}
            render={props => (
              <TextField
                error={errors?.['field-receiver']}
                helperText={errors?.['field-receiver'] && LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR}
                label={LABEL_EMAIL_RECEIVER}
                name="field-receiver"
                onChange={ev => props?.field.onChange(ev.target.value)}
                required
                value={props?.field.value}
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-object"
            control={control}
            render={props => (
              <TextField
                error={errors?.['field-object']}
                helperText={errors?.['field-object'] && LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR}
                id="field-object"
                label={LABEL_EMAIL_SUBJECT}
                name="field-object"
                onChange={ev => props?.field.onChange(ev.target.value)}
                required
                value={props?.field.value}
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-content"
            control={control}
            render={props => (
              <TextField
                id="field-content"
                label={LABEL_EMAIL_CONTENT}
                multiline
                name="field-content"
                onChange={ev => props?.field.onChange(ev.target.value)}
                required
                value={props?.field.value ? props?.field.value : MAIL_MESSAGE_DOCUMENTS(userCrm)}
              />
            )}
          />
        </div>
        {!apiError && (
          <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
        )}
      </div>
      <div className={classNames(styles.modalFooter, { [styles.errorFooter]: apiError })}>
        {apiError && (
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => setApiError(false)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{LABEL_EMAIL_FORM_ERROR_TITLE}</h4>
              <p>{LABEL_FORM_ERROR_CONTENT}</p>
            </div>
          </div>
        )}
        {!apiError && (
          <>
            <div className={styles.cancelButton}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            </div>
            <div className={styles.createButton}>
              <Button
                disabled={!isDirty || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                {LABEL_SEND_DOCUMENTS}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const sendBookletSchema = yup.object().shape({
  'field-receiver': yup.string().required().matches(RegExp(regexEmail)),
  'field-object': yup.string().trim().required(),
});
