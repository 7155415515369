import React from 'react';
import type { ComponentProps, HTMLAttributes } from 'react';
import classnames from 'classnames';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ToggleCircle.module.scss';

interface ToggleCircleProps {
  active?: boolean;
  className?: string;
  iconId: ComponentProps<typeof SvgIcon>['iconId'];
  onClick?: HTMLAttributes<HTMLButtonElement>['onClick'];
}

export default function ToggleCircle({
  active = false,
  className,
  iconId,
  onClick,
}: ToggleCircleProps) {
  return (
    <button
      type="button"
      className={classnames(className, styles.root, active && styles.active)}
      onClick={onClick}
    >
      <SvgIcon iconId={`icon-search-${iconId.toLowerCase()}`} />
    </button>
  );
}
