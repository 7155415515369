import React from 'react';
import classNames from 'classnames';
import { Link } from '@material-ui/core';

import { formatActivityDate } from 'services/date';
import { ACTIVITY_TYPE_BOOKING, ACTIVITY_TYPE_PRE_BOOKING } from 'settings/activity';
import { LABEL_LOT } from 'settings/labels';
import {
  NOTIF_LINK_LABEL_ACT_CERTIFICATION,
  NOTIF_LINK_LABEL_DEADLINE_OPTION,
  NOTIF_LINK_LABEL_DEADLINE_PRERES,
  NOTIF_LINK_LABEL_LOT_AVAILABLE,
  NOTIF_LINK_LABEL_NEW_CONTRACT,
  NOTIF_LINK_LABEL_SEE_RESERVATION,
  NOTIF_LINK_LABEL_SIGNED_CONTRACT,
  NOTIF_LINK_LABEL_SIGNED_FOLDER,
  NOTIF_STATUS_UNREAD,
  NOTIF_TITLE_ACT_CERTIFICATION,
  NOTIF_TITLE_CONTRACT_GENERATION_FAILED,
  NOTIF_TITLE_DEADLINE_OPTION,
  NOTIF_TITLE_DEADLINE_PRERES,
  NOTIF_TITLE_NEW_CONTRACT,
  NOTIF_TITLE_SEND_SIGN_REQUEST_FAILED,
  NOTIF_TITLE_SEND_SIGN_REQUEST_SUCCESS,
  NOTIF_TITLE_SIGNED_CONTRACT,
  NOTIF_TITLE_SIGNED_FOLDER,
  NOTIF_TYPE_ACT_CERTIFICATION,
  NOTIF_TYPE_DEADLINE_1H_OPTION,
  NOTIF_TYPE_DEADLINE_1H_OPTION_COACQ,
  NOTIF_TYPE_DEADLINE_1H_PRERES,
  NOTIF_TYPE_DEADLINE_1H_PRERES_COACQ,
  NOTIF_TYPE_DEADLINE_OPTION,
  NOTIF_TYPE_DEADLINE_OPTION_COACQ,
  NOTIF_TYPE_DEADLINE_PRERES,
  NOTIF_TYPE_DEADLINE_PRERES_COACQ,
  NOTIF_TYPE_LOT_AVAILABLE,
  NOTIF_TYPE_NEW_CONTRACT,
  NOTIF_TYPE_PREBOOK_GENERATE_CONTRACT_FAILED,
  NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_FAILED,
  NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_SUCCESS,
  NOTIF_TYPE_SIGNED_CONTRACT,
  NOTIF_TYPE_SIGNED_CONTRACT_COACQ,
  NOTIF_TYPE_SIGNED_FOLDER,
  NOTIF_TYPE_SIGNED_FOLDER_COACQ,
} from 'settings/notifications';

import { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './Notification.module.scss';

export interface NotificationProps {
  handleClick: (ev, url, notifId, documentCrmId, type) => void;
  notification: NotificationType;
}

export default function Notification({ handleClick, notification }: NotificationProps) {
  const {
    notification_id: notifId,
    notification_type: type,
    date,
    notification_message: message,
    field_numero_lot: lotNumber,
    field_referenceprogramme: programRef,
    status,
    notification_document_data: {
      field_activite_commerciale_id: activiteCrmId,
      field_id_crm_document: documentCrmId,
      type_activite_commerciale: activityType,
    },
  } = notification;

  let url;
  let icon;
  let title;
  let linkLabel;
  if (!activiteCrmId || !documentCrmId) {
    url = `/programme/page/${programRef}/prix?lotNumber=${lotNumber}&programRef=${programRef}`;
  }

  switch (type) {
    case NOTIF_TYPE_SIGNED_CONTRACT:
    case NOTIF_TYPE_SIGNED_CONTRACT_COACQ: {
      const tab = activityType === ACTIVITY_TYPE_PRE_BOOKING ? 'pre-reservations' : 'reservations';
      url = `/mon-activite-commerciale/${tab}?activityId=${activiteCrmId}`;
      icon = 'contract-signed-vinci';
      title = NOTIF_TITLE_SIGNED_CONTRACT;
      linkLabel = NOTIF_LINK_LABEL_SIGNED_CONTRACT;
      break;
    }
    case NOTIF_TYPE_SIGNED_FOLDER:
    case NOTIF_TYPE_SIGNED_FOLDER_COACQ: {
      const tab = activityType === ACTIVITY_TYPE_BOOKING ? 'reservations' : 'pre-reservations';
      url = `/mon-activite-commerciale/${tab}?activityId=${activiteCrmId}`;
      icon = 'contract-signed';
      title = NOTIF_TITLE_SIGNED_FOLDER;
      linkLabel = NOTIF_LINK_LABEL_SIGNED_FOLDER;
      break;
    }
    case NOTIF_TYPE_NEW_CONTRACT:
      url = `/mon-activite-commerciale/pre-reservations?activityId=${activiteCrmId}`;
      icon = 'reservation-contract';
      title = NOTIF_TITLE_NEW_CONTRACT;
      linkLabel = NOTIF_LINK_LABEL_NEW_CONTRACT;
      break;
    case NOTIF_TYPE_DEADLINE_OPTION:
    case NOTIF_TYPE_DEADLINE_OPTION_COACQ:
    case NOTIF_TYPE_DEADLINE_1H_OPTION:
    case NOTIF_TYPE_DEADLINE_1H_OPTION_COACQ:
      url = `/pre-reservation/${programRef}/${lotNumber}`;
      icon = 'option';
      title = NOTIF_TITLE_DEADLINE_OPTION;
      linkLabel = NOTIF_LINK_LABEL_DEADLINE_OPTION;
      break;
    case NOTIF_TYPE_DEADLINE_PRERES:
    case NOTIF_TYPE_DEADLINE_PRERES_COACQ:
    case NOTIF_TYPE_DEADLINE_1H_PRERES:
    case NOTIF_TYPE_DEADLINE_1H_PRERES_COACQ:
      url = `/mon-activite-commerciale/pre-reservations?activityId=${activiteCrmId}`;
      icon = 'pre-book';
      title = NOTIF_TITLE_DEADLINE_PRERES;
      linkLabel = NOTIF_LINK_LABEL_DEADLINE_PRERES;
      break;
    case NOTIF_TYPE_LOT_AVAILABLE:
      url = `/programme/page/${programRef}/prix?lotNumber=${lotNumber}&programRef=${programRef}`;
      icon = 'home';
      title = NOTIF_TYPE_LOT_AVAILABLE;
      linkLabel = NOTIF_LINK_LABEL_LOT_AVAILABLE;
      break;
    case NOTIF_TYPE_ACT_CERTIFICATION:
      url = `/mon-activite-commerciale/ventes?activityId=${activiteCrmId}`;
      icon = 'reservation-contract';
      title = NOTIF_TITLE_ACT_CERTIFICATION;
      linkLabel = NOTIF_LINK_LABEL_ACT_CERTIFICATION;
      break;
    case NOTIF_TYPE_PREBOOK_GENERATE_CONTRACT_FAILED: {
      const tab = activityType === ACTIVITY_TYPE_PRE_BOOKING ? 'pre-reservations' : 'reservations';
      url = `/mon-activite-commerciale/${tab}?activityId=${activiteCrmId}`;
      icon = 'send-demand';
      title = NOTIF_TITLE_CONTRACT_GENERATION_FAILED;
      linkLabel = NOTIF_LINK_LABEL_SEE_RESERVATION;
      break;
    }
    case NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_SUCCESS: {
      const tab = activityType === ACTIVITY_TYPE_PRE_BOOKING ? 'pre-reservations' : 'reservations';
      url = `/mon-activite-commerciale/${tab}?activityId=${activiteCrmId}`;
      icon = 'send-demand';
      title = NOTIF_TITLE_SEND_SIGN_REQUEST_SUCCESS;
      linkLabel = NOTIF_LINK_LABEL_SEE_RESERVATION;
      break;
    }
    case NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_FAILED: {
      const tab = activityType === ACTIVITY_TYPE_PRE_BOOKING ? 'pre-reservations' : 'reservations';
      url = `/mon-activite-commerciale/${tab}?activityId=${activiteCrmId}`;
      icon = 'send-demand';
      title = NOTIF_TITLE_SEND_SIGN_REQUEST_FAILED;
      linkLabel = NOTIF_LINK_LABEL_SEE_RESERVATION;
      break;
    }
    default:
      icon = 'notifications';
      break;
  }

  return (
    <div
      className={classNames(styles.notification, {
        [styles.unreadNotification]: status === NOTIF_STATUS_UNREAD,
      })}
    >
      <button
        type="button"
        className={styles.notificationContent}
        onClick={ev => handleClick(ev, url, notifId, documentCrmId, type)}
      >
        <div className={styles.leftContent}>
          <div className={styles.iconCircle}>
            <Icon icon={icon} />
          </div>
          <div>
            <div className={styles.title}>
              {LABEL_LOT} {lotNumber} - {title}{' '}
            </div>
            <div className={styles.detail}>{message}</div>
            <div className={styles.actionUrl}>
              <Link className={classNames([styles.detail], [styles.link])} href={url}>
                {linkLabel}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.date}>
          <span>{formatActivityDate(date, 'HH:mm')}</span>
        </div>
      </button>
    </div>
  );
}
