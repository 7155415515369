import React from 'react';
import type { CSSProperties } from 'react';
import classnames from 'classnames';

import { isAuthenticated } from 'services/authentication';
import { setRedirectUrl } from 'services/redirect';
import { htmlEntityDecode } from 'services/stringHtml';
import { taxMapping } from 'services/taxes';

import LazyImage from 'commonUi/LazyImage/LazyImage';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';

import styles from './UpcomingSliderItem.module.scss';

interface UpcomingSliderItemProps {
  className?: string;
  data: {
    field_adresse_locality?: string;
    field_adresse_postal_code?: number;
    field_diaporama_descriptif?: string;
    field_fiscalite?: string;
    field_ref_operation?: string;
    field_type?: string;
    title: string;
  };
  style?: CSSProperties;
}

export default function UpcomingSliderItem({ className, data, style }: UpcomingSliderItemProps) {
  const {
    field_adresse_locality: address,
    field_adresse_postal_code: postalCode,
    field_diaporama_descriptif: diapo = '/images/placeholders/923x519.png',
    field_fiscalite: tax,
    field_ref_operation: ref,
    field_type: type,
    title,
  } = data;
  const standardTax = tax ? taxMapping?.get(tax.split(/,\s?/)[0]) : undefined;
  const programUrl = ref ? `/programme/page/${ref}/description` : null;

  return (
    <a
      href={isAuthenticated() ? programUrl : window.vinci.REACT_APP_LOGIN_URL}
      className={classnames(className, styles.root)}
      onClick={setRedirectUrl(programUrl)}
      target="_blank"
      rel="noreferrer"
      style={style}
    >
      <div className={styles.content}>
        <div className={styles.img}>
          <LazyImage alt={title} src={diapo.split('#')[0].replace('&amp;', '&')} />
          {standardTax && (
            <span className={styles.etiquette}>
              <PastilleFiscality tax={standardTax} />
            </span>
          )}
        </div>

        <div className={styles.infos}>
          {address && postalCode && (
            <div className={styles.location}>
              {htmlEntityDecode(address)} ({postalCode})
            </div>
          )}

          <div className={styles.title}>{htmlEntityDecode(title)}</div>

          <div className={styles.type}>{type}</div>
        </div>
      </div>
    </a>
  );
}
