import React from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';

import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { ModalSendPdf } from 'modules/ActionsProgramsAndLots/ModalSendPdf/ModalSendPdf';

interface ButtonModalPdfProps {
  onClose: ComponentProps<typeof ModalSendPdf>['closeCallBack'];
  open: boolean;
  pdf: { blob: Blob; url: string } | undefined;
  program?: ComponentProps<typeof ModalSendPdf>['program'];
  lot?: ComponentProps<typeof ModalSendPdf>['lot'];
}

export default function ButtonModalPdf({
  children,
  onClose,
  open,
  pdf,
  program,
  lot,
}: PropsWithChildren<ButtonModalPdfProps>) {
  return (
    <>
      {children}
      <ModalSendPdf
        closeCallBack={onClose}
        blobPdf={pdf?.blob}
        open={open && !!pdf && !!program}
        pdfUrl={pdf?.url}
        program={program}
        lot={lot}
      />

      <TagCommanderEvent
        isActive={open}
        navigation_template="recherche"
        navigation_pagename="resultats_programmes_ficheprogramme"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </>
  );
}
