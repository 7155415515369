import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  block: {
    margin: '17px 0 0 0',
  },
  addressWrapper: {
    alignItems: 'center',
    color: COLORS.GREYS.PURE_BLACK,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '8px',
  },
  address: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontWeight: 'medium',
    margin: '0 0 0 2px',
  },
  image: {
    height: 'auto',
    margin: '12px 0 0 0',
    width: '100%',
  },
});
