import { useEffect, useState } from 'react';
import { createHook } from 'hookleton';

import { error } from 'services';

export const useLoadLots = createHook(() => {
  const [lots, setLots] = useState([]);

  useEffect(() => {
    fetch(`${window.vinci.REACT_APP_STORAGE_LOTS_PATH}?${new Date().getTime()}`)
      .then(stream => stream.json())
      .then(json => setLots(json))
      .catch(e => error('fetch', e));
  }, []);

  return lots;
});

export function useLoadLotsWithDefaultNull() {
  const [lots, setLots] = useState(null);

  useEffect(() => {
    fetch(`${window.vinci.REACT_APP_STORAGE_LOTS_PATH}?${new Date().getTime()}`)
      .then(stream => stream.json())
      .then(json => {
        setLots(json);
      })
      .catch(e => error('fetch', e));
  }, []);

  return lots;
}
