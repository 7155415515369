import { useMemo } from 'react';

import { getDistanceInKm } from 'services/map';

type Coordinates = Parameters<typeof getDistanceInKm>[0];

export default function useDistancesFromPoint(
  items: ({ id: string } & Coordinates)[],
  coordsRef: Coordinates | undefined
) {
  return useMemo(() => {
    if (!items || !coordsRef) {
      return undefined;
    }
    return items.reduce((acc, item) => {
      acc.set(item.id, getDistanceInKm(item, coordsRef));
      return acc;
    }, new Map<string, number>());
  }, [items, coordsRef?.lng, coordsRef?.lat]);
}
