import React from 'react';
import classnames from 'classnames';

import { getMapDirectionProps } from 'services/map';

import Icon from '../common/components/Atoms/Icon';

import styles from './ProgramItinerary.module.scss';

export default function ProgramItinerary({
  classes = {},
  isDesktop,
  isMobileApple,
  postalCode,
  address,
  lat,
  lng,
  title,
  city,
}: ProgramItineraryType) {
  return (
    <div className={classnames(classes?.root, styles.root)}>
      <div className={classnames(classes?.destination, styles.destination)}>
        <div className={classnames(classes?.name, styles.name)}>{title}</div>
        <address className={classnames(classes?.address, styles.address)}>
          <div>{address}</div>{' '}
          <div>
            {postalCode} {city}
          </div>
        </address>
      </div>
      <a
        className={classnames(classes?.start, styles.start)}
        {...getMapDirectionProps(
          lat,
          lng,
          `${address} ${postalCode} ${city}`,
          isDesktop,
          isMobileApple
        )}
      >
        <Icon icon="itineraire" />
      </a>
    </div>
  );
}

export type ProgramItineraryType = {
  classes?: {
    address?: string;
    destination?: string;
    name?: string;
    root?: string;
    start?: string;
  };
  isDesktop: boolean;
  isMobileApple: boolean;
  postalCode: string | number;
  address: string;
  lat: number;
  lng: number;
  title: string;
  city: string;
};
