import { useContext, useMemo } from 'react';

import {
  KINDS_ORDER,
  SORT_LOT_BY_KIND,
  SORT_LOT_BY_PROGRAM,
  SORT_LOT_BY_STATUS,
  SORT_LOT_BY_TAX,
  SORT_ORDER_ASC,
  SORT_PROGRAM_BY_POSITION,
} from 'settings/search';

import type { LotJson, LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

function sortLots(
  lotsT: LotJson[] | undefined,
  programRefs: string[],
  sortBy: LotSortBy,
  sortOrder: LotSortOrder,
  taxesById?: TaxesById,
  statuses?: Record<string, { label: string }>
) {
  if (!lotsT || (lotsT && lotsT.length > 0 && !programRefs)) {
    return [];
  }
  const lots = lotsT.filter(({ program }) => programRefs.includes(program.ref));
  if (lots.length === 0) {
    return lots;
  }

  const sign = sortOrder === SORT_ORDER_ASC ? 1 : -1;
  if (sortBy === SORT_PROGRAM_BY_POSITION) {
    return lots.sort((lotA, lotB) => {
      const programIndexA = programRefs.indexOf(lotA.program.ref);
      const programIndexB = programRefs.indexOf(lotB.program.ref);
      if (programIndexA === programIndexB) {
        return KINDS_ORDER.indexOf(lotA.kind) - KINDS_ORDER.indexOf(lotB.kind);
      }
      return programIndexA - programIndexB;
    });
  }

  if (sortBy === SORT_LOT_BY_PROGRAM) {
    return lots.sort((a, b) => {
      const aCity = a.program.city;
      const bCity = b.program.city;
      if (programRefs.includes(a.program.ref) && !programRefs.includes(b.program.ref)) {
        return -1;
      }
      if (!programRefs.includes(a.program.ref) && programRefs.includes(b.program.ref)) {
        return 1;
      }
      return sign * aCity.localeCompare(bCity);
    });
  }

  if (sortBy === SORT_LOT_BY_TAX && taxesById) {
    return lots.sort((a, b) => {
      if (a.tax === null) {
        return sign * -1;
      }

      if (b.tax === null) {
        return sign * 1;
      }

      return sign * taxesById[a.tax].localeCompare(taxesById[b.tax]);
    });
  }

  if (sortBy === SORT_LOT_BY_STATUS && statuses) {
    return lots.sort((a, b) => {
      const statusLotA = statuses[a.nid] ? statuses[a.nid].label : a[sortBy];
      const statusLotB = statuses[b.nid] ? statuses[b.nid].label : b[sortBy];
      return sign * statusLotA.localeCompare(statusLotB);
    });
  }

  if (sortBy === SORT_LOT_BY_KIND) {
    const sortedDatas = lots.sort((a, b) => {
      return KINDS_ORDER.indexOf(a.kind) - KINDS_ORDER.indexOf(b.kind);
    });

    return sortOrder === SORT_ORDER_ASC ? sortedDatas : sortedDatas.reverse();
  }

  const lotToCompare = lots.find(lot => lot[sortBy]);
  let isNumber = true;
  let isDate = false;
  if (lotToCompare) {
    isNumber = typeof lotToCompare[sortBy] === 'number';
    isDate = lotToCompare[sortBy] instanceof Date;
  }
  return lots.sort((a, b) => {
    const valueA = a[sortBy];
    const valueB = b[sortBy];
    if (isNumber) {
      if (!valueA) {
        return 1;
      }
      if (!valueB) {
        return -1;
      }
      return sign * ((valueA as number) - (valueB as number));
    }
    if (isDate) {
      const aInt = (valueA as Date).getTime();
      const bInt = (valueB as Date).getTime();
      return sign * (aInt - bInt);
    }
    if (!a[sortBy]) {
      return 1;
    }
    if (!b[sortBy]) {
      return -1;
    }
    return sign * (a[sortBy] as string).localeCompare(b[sortBy] as string);
  });
}
function isSortLoading(sortBy: LotSortBy, isTaxesLoading: boolean) {
  switch (sortBy) {
    case SORT_LOT_BY_TAX:
      return isTaxesLoading;

    default:
      return false;
  }
}

export function useSortedLots(
  lots: LotJson[] | undefined,
  sortBy: LotSortBy,
  sortOrder: LotSortOrder,
  programs: { programRef: string }[],
  statuses: Parameters<typeof sortLots>[5] = {}
) {
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  const programRefs = useMemo(
    () => (programs?.length ? programs.map(({ programRef }) => programRef) : []),
    [programs]
  );

  return {
    lots: useMemo(() => sortLots(lots, programRefs, sortBy, sortOrder, taxesById, statuses), [
      lots,
      programRefs,
      sortOrder,
      sortBy,
      statuses,
      taxesById,
    ]),
    isLoading: isSortLoading(sortBy, isTaxesLoading),
  };
}
