import React, { useContext } from 'react';
import classnames from 'classnames';
import qs from 'query-string';

import {
  LABEL_3_LOTS_MAX,
  LABEL_ADD_TO_A_FOLDER,
  LABEL_ADD_TO_A_FOLDER_TOOLTIP,
  LABEL_COMPARE,
  LABEL_COMPARE_TOOLTIP,
} from 'settings/labels';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import { usePanels } from 'hooks/usePanels';

import { foldersContext } from 'modules/App/Contexts/foldersContext';

import Button from 'commonUi/Button/Button';
import Tooltip from 'commonUi/Tooltip/Tooltip';
import FolderLinks from 'modules/Folders/FolderLinks';

import styles from './LotsListSelectedActions.module.scss';

interface LotsListSelectedActionsType {
  hideButtonsLabel?: boolean;
  onAddToFolder?: () => void;
  origin?: string;
  pageTemplate?: string;
  pageTemplateRef?: string;
  selectedLots?: { lotNumber: string; programRef: string }[];
}

export default function LotsListSelectedActions({
  hideButtonsLabel = false,
  onAddToFolder = () => {},
  origin = undefined,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  selectedLots = [],
}: LotsListSelectedActionsType) {
  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType, setFolderTms } = useContext(
    foldersContext
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  const comparisonQueryParams = qs.stringify(
    { lots: selectedLots.map(s => Object.values(s)) },
    {
      encode: false,
      arrayFormat: 'bracket',
    }
  );

  const handleAddToFolder = () => {
    if (folderSaveType !== 'lot') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('lot');
    setFolderLots([...selectedLots]);
    onAddToFolder();
    if (pageTemplate && pageTemplateRef) {
      setFolderTms({
        confirmation: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_ajoutdossier.confirmation`,
        },
        default: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_ajoutdossier`,
        },
      });
    }
  };

  const isCompareBtnDisabled = selectedLots.length <= 1 || selectedLots.length > 3;

  const compareBtn = (
    <Button
      className={classnames(styles.button, { [styles.hiddenLabel]: hideButtonsLabel })}
      disabled={isCompareBtnDisabled}
      color="primary"
      href={
        !isCompareBtnDisabled ? `/comparaison?${comparisonQueryParams}&origin=${origin}` : undefined
      }
      iconId={isCompareBtnDisabled ? 'icon-compare-disable' : 'icon-compare'}
      iconClass={styles.icon}
      labelClassName={styles.label}
      labelTextClassName={styles.labelText}
      variant="outlined"
    >
      <span
        className={classnames(styles.content, {
          [styles.disabled]: isCompareBtnDisabled,
        })}
      >
        <span className={styles.top}>{LABEL_COMPARE}</span>
        <span className={styles.sub}>({LABEL_3_LOTS_MAX})</span>
      </span>
    </Button>
  );

  const addToFolderBtn = (
    <Button
      className={classnames(styles.button, { [styles.hiddenLabel]: hideButtonsLabel })}
      color="primary"
      iconId={selectedLots.length === 0 ? 'icon-add-to-folder-disable' : 'icon-add-to-folder'}
      iconClass={styles.icon}
      labelClassName={styles.label}
      labelTextClassName={styles.labelText}
      onClick={handleAddToFolder}
      variant="outlined"
      disabled={selectedLots.length === 0}
    >
      <span
        className={classnames(styles.content, {
          [styles.disabled]: selectedLots.length === 0,
        })}
      >
        {LABEL_ADD_TO_A_FOLDER}
      </span>
    </Button>
  );

  let compareTooltip = '';
  let addFolderTooltip = '';
  if (hideButtonsLabel) {
    compareTooltip = `${LABEL_COMPARE} (${LABEL_3_LOTS_MAX})`;
    addFolderTooltip = LABEL_ADD_TO_A_FOLDER;
  }
  if (isCompareBtnDisabled) {
    compareTooltip = LABEL_COMPARE_TOOLTIP;
  }
  if (selectedLots.length === 0) {
    addFolderTooltip = LABEL_ADD_TO_A_FOLDER_TOOLTIP;
  }

  return (
    <>
      <Tooltip arrow placement="top" title={compareTooltip}>
        <div className={styles.btnWrapper}>{compareBtn}</div>
      </Tooltip>
      <Tooltip arrow placement="top" title={addFolderTooltip}>
        <div className={styles.btnWrapper}>{addToFolderBtn}</div>
      </Tooltip>

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
    </>
  );
}
