import React, { useContext, useEffect, useRef, useState } from 'react';
import type { CSSProperties, ComponentProps, Key, ReactNode } from 'react';
import classnames from 'classnames';

import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import { LOT_GRID_NB_LINES } from 'settings/lots';
import { HEADER_DOM_ID, PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import type { HeadingType, HeadingsWidthType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';

import { getFirstPromotion } from 'services/promotions';
import { getColumnWidth } from 'services/ui';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import userContext from 'modules/App/Contexts/userContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';
import { usePanels } from 'hooks/usePanels';

import LotCell from 'commonUi/ListLotV2/LotCell/LotCell';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import LotRow from 'commonUi/ListLotV2/LotRow/LotRow';
import LotScrollingRow from 'commonUi/ListLotV2/LotRow/LotScrollingRow';
import TableHeader from 'commonUi/Table/TableHeader/TableHeader';
import TableV2 from 'commonUi/Table/TableV2';
import FolderLinks from 'modules/Folders/FolderLinks';
import { LotDetailsCpn } from 'modules/DetailsLotsAndActivity/LotDetailsCpn';

import styles from './LotTableDesktop.module.scss';

interface LotTableDesktopProps {
  data?: LotJson[];
  headerContent?: ReactNode;
  headings?: HeadingType[];
  headingsWidth?: HeadingsWidthType;
  handleOnChangeCheckbox: ComponentProps<typeof LotCell>['handleOnChangeCheckbox'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  program: ProgramTypeV2;
  reorder?(heading: HeadingType): void;
  selectedLots?: { lotNumber: string; programRef: string }[];
  sortBy: string;
  sortOrder: string;
  statuses: Record<string, Status>;
  updateStatus: (lotNid: string, status: any) => void;
}

export default function LotTableDesktop({
  data = [],
  handleOnChangeCheckbox,
  headerContent = undefined,
  headings = [],
  headingsWidth = undefined,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  program,
  reorder = () => {},
  selectedLots = [],
  sortBy,
  sortOrder,
  statuses,
  updateStatus,
}: LotTableDesktopProps) {
  const { lotNumber, programRef } = useSearch();
  const { componentPageVars, setComponentPageVars } = useContext(TmsContext);

  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert } = useContext(
    userContext
  );
  const { taxes, taxesById } = useContext(TaxonomiesContext);
  const [currentRowOpen, setCurrentRowOpen] = useState<{ number: string; ref: string }>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      data.length > 0 &&
      !data.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentRowOpen?.ref, currentRowOpen?.number);
  // get lot information cell

  const rowsListRef = useRef(null);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
  const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);

  // scroll to table if lotnumber exist
  useEffect(() => {
    const element = document.getElementById('programs-lots-list');
    if (element && lotNumber) {
      const activeIndex = data?.findIndex(l => l.number === lotNumber);
      const header = document.getElementById(HEADER_DOM_ID);
      const programAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
      const rect = element.getBoundingClientRect();
      window.scrollTo({
        top:
          rect.bottom -
          (header?.clientHeight ?? 0) -
          (programAnchors?.clientHeight ?? 0) -
          element.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
      setCurrentRowOpen(
        activeIndex !== -1
          ? { number: data[activeIndex].number, ref: data[activeIndex].ref }
          : undefined
      );
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  // put current lot on top of the list
  let sortedData = data;
  if (data && lotNumber && programRef) {
    const lotIndex = data.findIndex(lot => lot.number === lotNumber && lot.ref === programRef);
    if (lotIndex !== -1) {
      sortedData = [data[lotIndex], ...data.slice(0, lotIndex), ...data.slice(lotIndex + 1)];
    }
  }

  const renderTableHeader = (col: HeadingType) => (
    <TableHeader
      key={col.id}
      col={col}
      reorder={reorder}
      sortBy={sortBy}
      sortOrder={sortOrder}
      style={{
        width: headingsWidth ? getColumnWidth(col, headings, headingsWidth) : undefined,
      }}
    />
  );

  const renderLotRow = (lot: LotJson, key: Key, index: number, style?: CSSProperties) => {
    const isRowOpen = currentRowOpen?.number === lot.number && currentRowOpen?.ref === lot.ref;
    return (
      <LotRow
        key={key}
        className={classnames({ [styles.minHeight]: sortedData.length <= LOT_GRID_NB_LINES })}
        cellsWidth={headingsWidth}
        isCurrentRowOpen={isRowOpen && !isDetailsLoading}
        onClickRow={() => {
          // Reset componentPageVars if we close the current lot accordion
          if (
            currentRowOpen &&
            componentPageVars?.navigation_pagename === `${pageTemplateRef}.${lot.number}`
          ) {
            setComponentPageVars({});
          }
          setCurrentRowOpen(prev =>
            prev?.number === lot.number && prev?.ref === lot.ref
              ? undefined
              : { number: lot.number, ref: lot.ref }
          );
        }}
        data={lot}
        headings={headings}
        index={index}
        style={style}
        renderCell={(col, handleDisplayLot, isLotOpen) => (
          <LotCell
            handleDisplayLot={handleDisplayLot}
            isDetailsLoading={isDetailsLoading && isRowOpen}
            isLotOpen={isLotOpen}
            onStatusAlertClick={toggleAlert}
            myBookings={myBookings}
            myOptions={myOptions}
            alerts={alerts}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            col={col}
            lot={lot}
            statuses={statuses}
            taxById={taxesById}
            isChecked={selectedLots.some(
              l => l.lotNumber === lot.number && l.programRef === lot.ref
            )}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
          />
        )}
        renderShowMore={index => {
          if (!lotDetail || !programDetail) {
            return null;
          }
          return (
            <LotDetailsCpn
              lotApiOffre={lotDetail}
              programDatas={programDetail}
              myOptions={myOptions}
              myBookings={myBookings}
              fiscalities={taxes}
              index={index}
              statuses={statuses}
              updateStatus={updateStatus}
              pageTemplate={pageTemplate}
              pageTemplateRef={pageTemplateRef}
              openDropdownTop={index === sortedData.length - 1}
              openPanel={openPanel}
            />
          );
        }}
      />
    );
  };

  const promotionsHB = getFirstPromotion(program.promotions, true);

  // Do not use virtualized list to avoid min height issue for lille number of lots
  if (sortedData.length <= LOT_GRID_NB_LINES) {
    return (
      <>
        <div className={styles.lotsListWrapper} id="programs-lots-list">
          <LotTableErrorModal
            open={loadLotError || openError}
            onClose={() => {
              setOpenError(false);
              setLoadLotError(false);
            }}
            title={LABEL_LOTS_ENABLE_ERROR_TITLE}
            subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
          />

          <div
            className={styles.lotsListHeader}
            id="program-lots-list-header"
            style={
              programPromoBanner
                ? {
                    top:
                      (programMenuAnchors?.offsetHeight || 0) +
                      (programPromoBanner?.offsetHeight || 0),
                  }
                : undefined
            }
          >
            {headerContent && <div>{headerContent}</div>}
            <div
              className={classnames(styles.tableHeader, { [styles.noBorderRadius]: promotionsHB })}
            >
              {headings.map(renderTableHeader)}
            </div>
          </div>
          {sortedData.map((lot, index) => renderLotRow(lot, lot.nid, index))}
        </div>

        <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
      </>
    );
  }

  return (
    <>
      <TableV2
        className="header-sticky"
        disableHeight
        error={
          <LotTableErrorModal
            open={loadLotError || openError}
            onClose={() => {
              setOpenError(false);
              setLoadLotError(false);
            }}
            title={LABEL_LOTS_ENABLE_ERROR_TITLE}
            subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
          />
        }
        headerBorderRadius={!promotionsHB}
        headerContent={headerContent}
        headings={headings}
        id="programs-lots-list"
        rowCount={sortedData.length}
        rowsListRef={rowsListRef}
        renderHeader={renderTableHeader}
        renderRow={({ index, isScrolling, isVisible, key, style }) => {
          if (!isVisible && isScrolling) {
            return (
              <LotScrollingRow
                key={key}
                cellsWidth={headingsWidth}
                headings={headings}
                index={index}
                style={style}
              />
            );
          }

          return renderLotRow(sortedData[index], key, index, style);
        }}
      />

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
    </>
  );
}
