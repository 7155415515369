import React, { useContext, useState } from 'react';

import { DOC_NATURE_PRODUCT } from 'settings/documents';
import {
  LABEL_BOOKLET,
  LABEL_CARD_PROGRAM,
  LABEL_DOWNLOAD_BOOKLET,
  LABEL_DOWNLOAD_PRODUCT_BOOKLET,
  LABEL_PRODUCT_BOOKLET,
  LABEL_SEND_BOOKLET,
  LABEL_SEND_PRODUCT_BOOKLET,
} from 'settings/labels';
import {
  MODAL_ID_SEND_BOOKLET,
  MODAL_ID_SEND_CARD_PROGRAM,
  MODAL_ID_SEND_PRODUCT_BOOKLET,
} from 'settings/modal';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import programPdfContext from 'modules/App/Contexts/programPdfContext';

import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';

import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import PdfButtonUI from 'commonUi/Dropdowns/PdfButtonUI';
import BookletDropdown from 'commonUi/Dropdowns/BookletDropdown';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';

import styles from './ProgramActions.module.scss';

const programDropdown = {
  label: LABEL_CARD_PROGRAM,
  icon: 'program-card' as const,
  id: 'program-card',
  modalId: MODAL_ID_SEND_CARD_PROGRAM,
};

interface ProgramActionsProps {
  program: ProgramTypeV2;
}

export default function ProgramActions({ program }: ProgramActionsProps) {
  const { open, openModal, closeModal } = useModalMultiples();
  const [activeButton, setActiveButton] = useState<string>();

  const { getPdf, generate: generateProgram, isGenerating: isGeneratingProgram } = useContext(
    programPdfContext
  );
  const pdf = getPdf(program.referenceProgramme);

  const { booklet } = useBooklet(program?.referenceProgramme, true);
  const productBooklet = program.documents.find(
    doc => doc.nature.toLowerCase() === DOC_NATURE_PRODUCT.toLowerCase()
  );

  return (
    <div className={styles.root}>
      <div className={styles.buttonWrapper}>
        <ButtonModalPdf
          pdf={pdf}
          program={program}
          open={open === MODAL_ID_SEND_CARD_PROGRAM}
          onClose={() => closeModal()}
        >
          <PdfButtonUI
            onClick={() => {
              if (!pdf) {
                generateProgram(program.referenceProgramme);
              }
            }}
            loading={isGeneratingProgram[program.referenceProgramme]}
            button={programDropdown}
            isButtonActive={activeButton === programDropdown.id}
            url={pdf?.url}
            setActiveButton={setActiveButton}
            openModal={openModal}
            tms={{
              navigation_template: `programme.${program.referenceProgramme}`,
              navigation_pagename: 'programme.ficheprogramme',
            }}
          />
        </ButtonModalPdf>
      </div>
      {productBooklet && (
        <div className={styles.buttonWrapper}>
          <BookletDropdown
            bookletUrl={productBooklet.url}
            buttonIcon="booklet"
            buttonId="product"
            modalId={MODAL_ID_SEND_PRODUCT_BOOKLET}
            openModal={openModal}
          >
            {LABEL_PRODUCT_BOOKLET}
          </BookletDropdown>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <BookletDropdown
          bookletUrl={booklet?.url}
          buttonIcon="booklet"
          buttonId="booklet"
          modalId={MODAL_ID_SEND_BOOKLET}
          openModal={openModal}
        >
          {LABEL_BOOKLET}
        </BookletDropdown>
      </div>

      <ModalSendBooklet
        booklet={booklet}
        closeCallBack={closeModal}
        open={!!open && [MODAL_ID_SEND_BOOKLET, MODAL_ID_SEND_PRODUCT_BOOKLET].includes(open)}
        program={program}
        title={open === MODAL_ID_SEND_BOOKLET ? LABEL_SEND_BOOKLET : LABEL_SEND_PRODUCT_BOOKLET}
        downloadLabel={
          open === MODAL_ID_SEND_BOOKLET ? LABEL_DOWNLOAD_BOOKLET : LABEL_DOWNLOAD_PRODUCT_BOOKLET
        }
      />
    </div>
  );
}
