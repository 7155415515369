import React, { ReactNode, useRef } from 'react';
import classnames from 'classnames';
import { Collapse as MuiCollapse } from '@material-ui/core';

import { COLORS } from 'settings/ui';

import { Cta } from 'types/cta';
import { MediaType } from 'types/medias';
import { Color } from 'types/ui';

import { colorToString } from 'services/ui';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import AccordionMediaVisual from './AccordionMediaVisual';

import styles from './AccordionMediaItem.module.scss';

interface AccordionMediaItemProps {
  buttonIcon?: Icons;
  classes?: {
    root?: string;
    button?: string;
  };
  colors?: {
    text: Color;
    separator: Color;
  };
  item: {
    content: ReactNode;
    cta: Cta[];
    medias: MediaType[];
    tabLabel: string;
    title: ReactNode;
    uuid: string;
  };
  onOpen?: (uuid: string) => void;
  open?: boolean;
  showMedias?: boolean;
}

export default function AccordionMediaItem({
  buttonIcon = 'chevron-down',
  classes,
  colors = {
    text: {
      color: COLORS.GREYS.PURE_BLACK,
      opacity: '1',
    },
    separator: {
      color: COLORS.GREYS.GREY2,
      opacity: '1',
    },
  },
  item,
  onOpen = () => {},
  open = false,
  showMedias = false,
}: AccordionMediaItemProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      className={classnames(classes?.root, styles.root)}
      style={{ color: colorToString(colors.text) }}
    >
      <button
        ref={buttonRef}
        type="button"
        disabled={open}
        className={classnames(classes?.button, styles.button, { [styles.open]: open })}
        onClick={() => onOpen(item.uuid)}
      >
        {item.tabLabel ?? item.title}

        <Icon className={styles.buttonIcon} icon={buttonIcon} />
      </button>

      <MuiCollapse in={open} timeout="auto">
        <div className={styles.content}>
          <div className={styles.title}>{item.title}</div>

          {showMedias && <AccordionMediaVisual className={styles.visual} medias={item.medias} />}

          {item.content}
        </div>
      </MuiCollapse>
      <span className={styles.separator} style={{ borderColor: colorToString(colors.separator) }} />
    </div>
  );
}
