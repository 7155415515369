import React from 'react';
import { Button, Divider, MenuItem } from '@material-ui/core';

import { LABEL_DISCONNECTION, LABEL_MY_ACCOUNT } from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from '../HeaderAuthenticated.module.scss';

export default function MyAccountMenu() {
  const tooltipLinks = [
    {
      href: '/mon-profil',
      icon: <Icon icon="user" />,
      label: LABEL_MY_ACCOUNT,
      target: '_blank',
    },
    {
      href: '/deconnexion',
      icon: <Icon icon="power-off" />,
      label: LABEL_DISCONNECTION,
      target: '_self',
    },
  ];

  return (
    <>
      {tooltipLinks.map((tooltipLink, idxTooltipLink) => (
        <>
          <MenuItem key={tooltipLink.label} classes={{ root: styles.menuItemRoot }}>
            <Button
              classes={{
                root: styles.menuItemButton,
                startIcon: styles.menuItemButtonStartIcon,
              }}
              variant="text"
              startIcon={tooltipLink.icon}
              disableRipple
              href={tooltipLink.href}
              target={tooltipLink.target}
            >
              {tooltipLink.label}
            </Button>
          </MenuItem>
          {idxTooltipLink !== tooltipLinks.length - 1 && (
            <Divider key={`divider-${tooltipLink.label}`} />
          )}
        </>
      ))}
    </>
  );
}
