import React from 'react';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { ActivityDetails } from 'commonUi/ListLotV2/ActivityRow/ActivityDetails/ActivityDetails';
import { LotDetailLayoutMobile } from 'commonUi/ListLotV2/LotDetailLayout/LotDetailLayoutMobile';
import LotDetailsImage from 'commonUi/ListLotV2/LotDetails/LotDetailsImage';

interface ActivityDetailsMobileCpnType {
  activity: Activity;
  closeDrawer: () => void;
  fiscalities: TaxTaxonomyTerm[];
  lotFromApi: LotTypeV2 | undefined;
  programFromApi: ProgramTypeV2 | undefined;
}

export function ActivityDetailsMobileCpn({
  activity,
  closeDrawer,
  fiscalities,
  lotFromApi,
  programFromApi,
}: ActivityDetailsMobileCpnType) {
  return (
    <LotDetailLayoutMobile
      lotNumber={lotFromApi?.reference}
      closeDrawer={closeDrawer}
      loaded={lotFromApi !== undefined && programFromApi !== undefined}
      renderDetails={() => (
        <ActivityDetails
          activity={activity}
          lotFromApi={lotFromApi}
          programFromApi={programFromApi}
          fiscalities={fiscalities}
        />
      )}
      renderImage={() => {
        return <LotDetailsImage lotFromApi={lotFromApi} programFromApi={programFromApi} />;
      }}
    />
  );
}
