import {
  MAIN_SEARCH_DEFAULT_PARAMS,
  SESSION_KEY_MAIN_VALUES,
  SESSION_KEY_MAP_STATE,
} from 'settings/search';

const storage = sessionStorage;

// --- MAIN SEARCH ---------------------------------------------------------------------------------
export function getMainSearchValues() {
  const params = storage.getItem(SESSION_KEY_MAIN_VALUES);

  if (params === null) {
    return MAIN_SEARCH_DEFAULT_PARAMS;
  }

  try {
    return JSON.parse(params);
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    return MAIN_SEARCH_DEFAULT_PARAMS;
  }
}

export function setMainSearchValues(params) {
  storage.setItem(SESSION_KEY_MAIN_VALUES, JSON.stringify(params));
}

export function clearMainSearchValues() {
  storage.setItem(SESSION_KEY_MAIN_VALUES, JSON.stringify(MAIN_SEARCH_DEFAULT_PARAMS));
}

// --- MAP DISPLAY STATE ---------------------------------------------------------------------------
export function getMapState() {
  const mapState = storage.getItem(SESSION_KEY_MAP_STATE);
  return mapState === null ? true : Boolean(mapState);
}

export function setMapState(mapState) {
  storage.setItem(SESSION_KEY_MAP_STATE, JSON.stringify(Boolean(mapState)));
}
