import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/es/';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'modules/App/App';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/styles/main.scss';
import './sharedModulesV4/common/assets/styles/components/_scrollarea.scss';
import 'react-phone-input-2/lib/style.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';

ReactDOM.render(<App />, document.getElementById('app'));
