import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Fade as MuiFade, Popper as MuiPopper } from '@material-ui/core';

import { useDebouncedValue } from 'hooks';

import { getLocality } from 'api/viOffresAPI/apiClient';
import useSWR from 'swr';
import TextField from 'commonUi/TextField/TextField';
import styles from './CityAutocomplete.module.scss';

interface CityAutocompleteProps {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
  notchedOutlineInvisible?: boolean;
  onChange: (city: string) => void;
  required?: boolean;
  value?: string;
}

export function CityAutocomplete({
  disabled = false,
  error = false,
  helperText,
  label,
  notchedOutlineInvisible = false,
  onChange,
  required = false,
  value,
}: CityAutocompleteProps) {
  const textfieldRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [cities, setCities] = useState<{ value: string }[]>([]);
  const [citiesQuery, setCitiesQuery] = useState<string>();
  const [clickedCity, setClickedCity] = useState<string>();
  const [searchCity, setSearchCity] = useState<string>();

  const isOpen = isFocus && cities.length > 0 && clickedCity !== value;

  const debouncedSearchCity = useDebouncedValue(searchCity, 200);
  const { data } = useSWR(
    debouncedSearchCity && searchCity !== undefined
      ? [
          searchCity.length >= 3 && searchCity !== citiesQuery ? 'search/geosearch' : null,
          searchCity,
        ]
      : null,
    ([url, city]) => getLocality(url, city)
  );

  function onFocus() {
    setIsFocus(true);
  }
  function onBlur() {
    setIsFocus(false);
  }

  useEffect(() => {
    if (data) {
      const { villes } = data;
      if (villes instanceof Array) {
        // setIsOpen(true);
        setCities(villes.map(({ libelle_ville: value }) => ({ value })));
        setCitiesQuery(searchCity);
      }
    }
  }, [data]);

  useEffect(() => {
    if (value !== clickedCity) {
      setSearchCity(value);
    }
  }, [clickedCity, value]);

  const handleCityClick = useCallback(
    (ev, value) => {
      ev.preventDefault();
      textfieldRef.current?.blur();
      setClickedCity(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <>
      <TextField
        ref={textfieldRef}
        disabled={disabled}
        error={error}
        helperText={helperText}
        InputLabelProps={{ variant: value ? 'filled' : 'standard' }}
        label={label}
        notchedOutlineInvisible={notchedOutlineInvisible}
        onChange={ev => onChange(ev.target.value)}
        required={required}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <MuiPopper
        disablePortal
        transition
        anchorEl={textfieldRef.current}
        open={isOpen}
        placement="bottom-start"
        style={{ zIndex: 125 }}
      >
        {({ TransitionProps, placement }) => (
          <MuiFade {...TransitionProps} timeout={150}>
            <div className={classnames(styles.selectVinci, styles[placement])}>
              {cities.map(({ value }) => (
                <button
                  key={value}
                  type="button"
                  className={styles.selectVinciItem}
                  onClick={ev => handleCityClick(ev, value)}
                >
                  <span className="itemCity">{value}</span>
                </button>
              ))}
            </div>
          </MuiFade>
        )}
      </MuiPopper>
    </>
  );
}
