import React from 'react';
import { Route } from 'react-router-dom';
import type { RouteProps } from 'react-router';

import GuestLayout from 'layout/GuestLayout';
import ErrorBoundary from './Tools/ErrorBoundary';

interface GuestRouteProps extends Omit<RouteProps, 'component' | 'path' | 'render'> {
  component: NonNullable<RouteProps['component']>;
  path?: Exclude<RouteProps['path'], readonly string[]>;
}

export default function GuestRoute({ component: Component, ...routeProps }: GuestRouteProps) {
  return (
    <Route
      {...routeProps}
      render={componentProps => (
        <GuestLayout>
          <ErrorBoundary location={routeProps.location}>
            <Component {...componentProps} />
          </ErrorBoundary>
        </GuestLayout>
      )}
    />
  );
}
