import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { MEDIAS_TYPE_IMAGE, MEDIAS_TYPE_PLAN, MEDIAS_TYPE_VIDEO } from 'settings/medias';
import { BREAKPOINT_SM } from 'settings/ui';

import type { MediaType, VideoType } from 'types/medias';

import { extractVideoIdFromUrl, makeYouTubeThumbnailFromId } from 'services/videos';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldVideo from 'commonUi/FieldVideo/FieldVideo';
import SlickSlider from 'commonUi/SlickSlider/SlickSlider';

import styles from './MediasSelector.module.scss';

interface MediasSelectorProps {
  classes?: {
    root?: string;
    activeMedia?: string;
  };
  medias?: (MediaType | VideoType)[];
}

export default function MediasSelector({ classes = {}, medias = [] }: MediasSelectorProps) {
  const [activeMedia, setActiveMedia] = useState(medias?.[0]);
  const isSlider = medias.length >= 4;

  useEffect(() => {
    setActiveMedia(prevActiveMedia => {
      if (medias?.[0] && prevActiveMedia !== medias?.[0]) {
        return medias?.[0];
      }
      return prevActiveMedia;
    });
  }, [medias]);

  if (!medias.length) {
    return null;
  }

  function renderMediaButton(media: MediaType | VideoType) {
    let content: JSX.Element | undefined;
    let key: string | undefined;
    switch (media.properties[0].type) {
      case MEDIAS_TYPE_VIDEO: {
        const videoId = extractVideoIdFromUrl((media as VideoType).url);
        if (videoId) {
          key = videoId;
          content = (
            <img className={styles.mediaButtonImage} src={makeYouTubeThumbnailFromId(key)} alt="" />
          );
        }
        break;
      }

      case MEDIAS_TYPE_IMAGE:
      case MEDIAS_TYPE_PLAN: {
        key = media.properties[0].target_uuid;
        content = <FieldImage className={styles.mediaButtonImage} media={media as MediaType} />;
        break;
      }

      default:
        break;
    }

    if (!key || !content) {
      return null;
    }

    return (
      <button
        key={key}
        type="button"
        className={classnames(styles.mediaButton, {
          [styles.active]: media === activeMedia,
          [styles.video]: media.properties[0].type === MEDIAS_TYPE_VIDEO,
        })}
        onClick={() => setActiveMedia(media)}
      >
        {content}
      </button>
    );
  }

  return (
    <div className={classnames(classes.root, styles.root)}>
      <div
        className={classnames(classes.activeMedia, styles.activeMedia, {
          [styles.video]: activeMedia?.properties[0]?.type === MEDIAS_TYPE_VIDEO,
        })}
      >
        {medias.map(media => {
          switch (media.properties[0].type) {
            case MEDIAS_TYPE_VIDEO: {
              const key = extractVideoIdFromUrl((media as VideoType).url);
              return (
                <FieldVideo
                  key={key}
                  className={classnames(styles.media, { [styles.shown]: media === activeMedia })}
                  id={key}
                />
              );
            }

            case MEDIAS_TYPE_IMAGE:
            case MEDIAS_TYPE_PLAN: {
              return (
                <FieldImage
                  key={media.properties[0].target_uuid}
                  className={classnames(styles.media, { [styles.shown]: media === activeMedia })}
                  media={activeMedia}
                />
              );
            }

            default:
              return null;
          }
        })}
      </div>
      {medias.length > 1 &&
        (isSlider ? (
          <SlickSlider
            className={classnames(styles.medias, styles.slider)}
            prevArrow={
              <button type="button">
                <Icon icon="chevron-down" />
              </button>
            }
            nextArrow={
              <button type="button">
                <Icon icon="chevron-down" />
              </button>
            }
            slidesToShow={4}
            slidesToScroll={1}
            infinite={false}
            responsive={[
              {
                breakpoint: BREAKPOINT_SM - 1,
                settings: {
                  arrows: false,
                  dots: true,
                  slidesToScroll: 4,
                },
              },
            ]}
          >
            {medias.map(renderMediaButton)}
          </SlickSlider>
        ) : (
          <div className={styles.medias}>{medias.map(renderMediaButton)}</div>
        ))}
    </div>
  );
}
