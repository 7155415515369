import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'vinci_sans',
    padding: '0 12px',
  },

  // Header styles
  header: {
    alignItems: 'center',
    backgroundColor: COLORS.GREYS.PURE_WHITE,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
  },
  logoVIP: {
    height: '22px',
    width: '110px',
  },
  logoVinci: {
    height: '29px',
    width: '111px',
  },

  // Page content styles
  content: {
    backgroundColor: COLORS.GREYS.WHITE,
    flex: '1 0 auto',
  },
});
