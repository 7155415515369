import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  root: {
    position: 'absolute',
    bottom: '15px',
  },
  col: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontWeight: 'medium',
    fontStyle: 'italic',
    fontSize: '6px',
    padding: '0 5px',
  },
});
