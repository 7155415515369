import React from 'react';
import classnames from 'classnames';
import {
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepLabel,
  Typography,
} from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './Stepper.module.scss';

export default function Stepper({ activeStep, setStep, steps }: StepperProps) {
  function StepIcon({ active, completed, icon }: StepIconProps) {
    const icons = {
      1: <Icon icon="user-short" className={styles.icon} />,
      2: <Icon icon="user-short" className={styles.icon} />,
      3: <Icon icon="price-euro" className={styles.icon} />,
    };

    return (
      <button
        className={classnames(styles.rootIcon, {
          [styles.activeIcon]: active,
          [styles.completedIcon]: completed,
        })}
        onClick={() => setStep(Number(icon) - 1)}
        type="button"
      >
        {icons[Number(icon)]}
      </button>
    );
  }

  type StepIconProps = {
    active: boolean;
    completed: boolean;
    icon: string;
  };

  const stepConnectorStyle = {
    root: classnames(styles.rootLine),
    line: classnames(styles.line),
    alternativeLabel: classnames(styles.alternativeLabel),
    completed: classnames(styles.completed),
    active: classnames(styles.active),
  };

  const labelStyle = {
    root: styles.rootColorLabel,
    completed: styles.completedColorLabel,
    active: styles.activeColorLabel,
  };

  return (
    <StylesProvider injectFirst>
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnector classes={stepConnectorStyle} />}
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon} classes={labelStyle}>
              <Typography className={classnames(styles.label)}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </StylesProvider>
  );
}

export type StepperProps = {
  activeStep: number;
  setStep: (step: number) => void;
  steps: string[];
};
