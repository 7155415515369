import React, { useEffect, useState } from 'react';
import type { ComponentProps, MutableRefObject, PropsWithChildren, RefObject } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import type { SliderRef } from 'types/slider';

export interface SlickSliderProps extends ComponentProps<typeof Slider> {
  className?: string;
  sliderRef?: RefObject<SliderRef> | MutableRefObject<SliderRef | undefined>;
}

export default function SlickSlider({
  children,
  className,
  sliderRef,
  ...sliderProps
}: PropsWithChildren<SlickSliderProps>) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, [setReady]);

  if (!ready) {
    const childrenArray = Array.isArray(children) ? children : [children];
    return (
      <div className={classnames('slick-slider', className)}>
        <div className="slick-track">
          {childrenArray.map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="slick-slide" key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Slider className={className} ref={sliderRef} {...sliderProps}>
      {children}
    </Slider>
  );
}
