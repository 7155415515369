import React, { useContext } from 'react';

import { formatPrice, replaceTokens } from 'services/formatter';
import {
  LABEL_KITCHEN_LOT_DETAIL_INCLUDED,
  LABEL_KITCHEN_LOT_DETAIL_OPTION,
  LABEL_KITCHEN_LOT_DETAIL_TITLE,
} from 'settings/labels';
import { TOKEN_PRICE } from 'settings/token';
import { KITCHEN_TYPE_INLUDED, KITCHEN_TYPE_OPTION } from 'settings/kitchen';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import KitchenIcon from 'commonUi/KitchenIcon/KitchenIcon';

import styles from './KitchenEquippedInfosBlock.module.scss';

interface KitchenEquippedInfosBlockProps {
  lot?: LotTypeV2;
}

export default function KitchenEquippedInfosBlock({ lot }: KitchenEquippedInfosBlockProps) {
  const { settings } = useContext(SettingsContext);

  return (
    <div className={styles.root}>
      <KitchenIcon className={styles.link} iconClassName={styles.icon} Wrapper={null} />
      <span className={styles.title}>{LABEL_KITCHEN_LOT_DETAIL_TITLE}</span>
      {lot?.packs?.cuisineEquipee?.type === KITCHEN_TYPE_OPTION && (
        <span className={styles.detail}>
          {replaceTokens(LABEL_KITCHEN_LOT_DETAIL_OPTION, {
            [TOKEN_PRICE]: formatPrice(lot?.packs?.cuisineEquipee?.prix, '€', true),
          })}
        </span>
      )}
      {lot?.packs?.cuisineEquipee?.type === KITCHEN_TYPE_INLUDED && (
        <span className={styles.detail}>{LABEL_KITCHEN_LOT_DETAIL_INCLUDED}</span>
      )}
      {settings.equipped_kitchen_url && settings.equipped_kitchen_label && (
        <a
          href={settings.equipped_kitchen_url}
          target="_blank"
          className={styles.bookletLink}
          rel="noopener noreferrer"
        >
          {settings.equipped_kitchen_label}
        </a>
      )}
    </div>
  );
}
