import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Button as ButtonMui,
  Link,
  ClickAwayListener as MuiClickAwayListener,
} from '@material-ui/core';

import { LABEL_DISPLAY, LABEL_SEND } from 'settings/labels';
import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import Button from 'commonUi/Button/Button';
import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import styles from './Dropdowns.module.scss';

interface PdfButtonUIProps {
  button: {
    label: string;
    icon: Icons;
    id: string;
    modalId: string;
  };
  isButtonActive: boolean;
  loading?: boolean;
  onClick?: () => void;
  openDropdownTop?: boolean;
  openModal?: (modalId: string) => void;
  setActiveButton: (activeButton: any) => void;
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
  url?: string | null;
}

export default function PdfButtonUI({
  button,
  isButtonActive,
  loading,
  onClick = () => {},
  openDropdownTop = false,
  openModal = () => {},
  setActiveButton,
  tms = undefined,
  url,
}: PdfButtonUIProps) {
  const [isDisplayBtnClicked, setIsDisplayBtnClicked] = useState<boolean>(false);

  function handleClickOutside() {
    if (isButtonActive) {
      setIsDisplayBtnClicked(false);
      setActiveButton(undefined);
    }
  }

  return (
    <>
      <MuiClickAwayListener onClickAway={handleClickOutside}>
        <div className={styles.buttonDropdownContainer}>
          <Button
            action
            activeClassName={openDropdownTop ? styles.active : undefined}
            className={classnames(styles.button, styles.buttonDropdown, {
              [styles.isActive]: isButtonActive,
            })}
            color="primary"
            iconId={!isButtonActive ? `icon-${button.icon}` : undefined}
            icon={isButtonActive ? button.icon : undefined}
            iconClass={!isButtonActive ? styles.buttonSvgIcon : styles.buttonIcon}
            iconDropdownClass={styles.buttonDropdownIcon}
            isActive={isButtonActive}
            labelClassName={styles.buttonLabel}
            loading={loading}
            onClick={() => {
              onClick();
              setActiveButton(activeButton => (activeButton === button.id ? undefined : button.id));
            }}
            variant="outlined"
            withDropdown
          >
            {button.label}
          </Button>
          {url && (
            <div
              className={classnames(styles.dropdownContent, {
                [styles.show]: isButtonActive,
                [styles.dropdownOnTop]: openDropdownTop,
              })}
            >
              <Link
                className={styles.button}
                href={url}
                target="_blank"
                onClick={() => setIsDisplayBtnClicked(true)}
              >
                <Icon icon="display" className={styles.icon} /> {LABEL_DISPLAY}
              </Link>

              <ButtonMui className={styles.button} onClick={() => openModal(button.modalId)}>
                <Icon icon="send" className={styles.icon} />
                {LABEL_SEND}
              </ButtonMui>
            </div>
          )}
        </div>
      </MuiClickAwayListener>

      {tms && (
        <TagCommanderEvent
          isActive={isDisplayBtnClicked}
          {...tms}
          useEffectDeps={Object.keys(tms)}
        />
      )}
    </>
  );
}
