import React, { useContext, useMemo } from 'react';

import { NOTIF_STATUS_UNREAD } from 'settings/notifications';

import type { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';
import type { apiData } from 'api/vi3pAPI/useSWRVi3p';

import userContext from 'modules/App/Contexts/userContext';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import { NOTIFICATIONS_DELAY } from 'modules/Notifications/NotificationsList';

import NotificationDot from 'commonUi/NotificationDot/NotificationDot';

export default function UnreadNotificationsPastille({
  className = '',
  excludeTypes = [],
  types = [],
}: UnreadNotificationsPastilleProps) {
  const { userCrm } = useContext(userContext);

  const { data: unsortedNotifications } = useSWRVi3p<apiData<NotificationType[]>>(
    { url: userCrm ? `/profils/${userCrm.extension_VI3P_ContactId}/notifications` : undefined },
    { refreshInterval: NOTIFICATIONS_DELAY }
  );

  const unreadNotifs = useMemo(
    () =>
      unsortedNotifications?.data.filter(
        ({ notification_type, status }) =>
          (status === NOTIF_STATUS_UNREAD && types.length === 0 && excludeTypes.length === 0) ||
          (status === NOTIF_STATUS_UNREAD &&
            types.length > 0 &&
            types.includes(notification_type)) ||
          (status === NOTIF_STATUS_UNREAD &&
            excludeTypes.length > 0 &&
            !excludeTypes.includes(notification_type))
      ).length ?? 0,
    [unsortedNotifications]
  );

  if (!unsortedNotifications || unreadNotifs <= 0) {
    return null;
  }
  return <NotificationDot className={className} amount={unreadNotifs} />;
}

export type UnreadNotificationsPastilleProps = {
  className?: string;
  excludeTypes?: string[];
  types?: string[];
};
