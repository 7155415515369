import React from 'react';
import { Redirect } from 'react-router-dom';

import { LOCALSTORAGE_KEY_BLOC_ALERTE } from 'sharedModulesV4/BlocAlerteModal/settings/localStorage';

import {
  getCodeErrorFromUrl,
  getStateFromUrl,
  getTokenFromSession,
  getTokenFromUrl,
  isInvite,
  isTokenExpired,
  redirectUrl,
  setTokenInSession,
} from 'services';

export default function Login() {
  const errorCode = getCodeErrorFromUrl();

  if (errorCode !== undefined && errorCode !== false) {
    if (errorCode === window.vinci.REACT_APP_FORGOTTEN_PASSWORD_CODE) {
      window.location.href = window.vinci.REACT_APP_FORGOTTEN_PASSWORD_URL;
      return null;
    }
  }

  const token = getTokenFromUrl() || getTokenFromSession();
  if (token) {
    if (!isTokenExpired(token)) {
      localStorage.removeItem(LOCALSTORAGE_KEY_BLOC_ALERTE);
      setTokenInSession(token);
    }
  }
  if (isInvite()) {
    return <Redirect to="/pending-validation" />;
  }

  const state = getStateFromUrl();
  if (state && state.origin) {
    return <Redirect to={state.origin} />;
  }
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }
  return <Redirect to="/" />;
}
