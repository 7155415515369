import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import useDownloadActivityFile from 'hooks/useDownloadActivityFile';

import { openBlob } from 'services/files';
import { ACTIVITIES_DOWNLOAD_ZIP_TMS } from 'settings/activity';
import {
  LABEL_DOWNLOAD_FOLDER,
  LABEL_FAIL_DOWNLOAD_FILE,
  LABEL_NO_DOCUMENT,
  LABEL_ZIP,
} from 'settings/labels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import Button from 'commonUi/Button/Button';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import CircularProgressWithLabel from 'commonUi/CircularProgressWithLabel/CircularProgressWithLabel';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import ActivityDownloadDocumentItem from './ActivityDownloadDocumentItem';

import styles from './ActivityDownloadDocuments.module.scss';

interface ActivityDownloadDocumentsProps {
  activity: Activity;
  closePopin?: (ev: React.MouseEvent) => void;
}

export default function ActivityDownloadDocuments({
  activity,
  closePopin,
}: ActivityDownloadDocumentsProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { enqueueSnackbar } = useSnackbar();

  const { isDownloading, downloadingProgress, handleFileDownload } = useDownloadActivityFile();

  function handleDownloadFiles(ev: React.MouseEvent) {
    ev.stopPropagation();
    if (!isDownloading) {
      openBlob(
        () =>
          handleFileDownload(`activiteCommerciale/${activity.field_idcrm_ac}/documents?format=zip`)
            .then(response => new Blob([response.data], { type: response.headers['content-type'] }))
            .catch(() => {
              enqueueSnackbar(LABEL_FAIL_DOWNLOAD_FILE, {
                autoHideDuration: null,
                content: (key, message) => (
                  <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
                ),
              });
              throw new Error();
            }),
        activity.field_idcrm_ac,
        isResponsive
      );
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {activity.field_documents_ac && activity.field_documents_ac.length > 0 && (
          <Button
            className={styles.largeButton}
            disabled={isDownloading}
            color="primary"
            onClick={handleDownloadFiles}
            variant="outlined"
          >
            <div className={styles.btnLabelWrapper}>
              {isDownloading ? (
                <CircularProgressWithLabel value={downloadingProgress} />
              ) : (
                <SvgIcon className={styles.largeButtonSvgIcon} iconId="icon-download-bicolor" />
              )}
              <div className={styles.btnLabel}>
                {LABEL_DOWNLOAD_FOLDER} <span className={styles.btnLabelInfos}>({LABEL_ZIP})</span>
              </div>
            </div>
          </Button>
        )}
        <button className={styles.closeBtn} type="button" onClick={closePopin}>
          <Icon className={styles.closeIcon} icon="cross" />
        </button>
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {activity.field_documents_ac && activity.field_documents_ac.length > 0 && (
            <CustomScrollbar>
              {activity.field_documents_ac.map(doc => (
                <ActivityDownloadDocumentItem
                  key={doc.idCrm}
                  activityId={activity.field_idcrm_ac}
                  document={doc}
                />
              ))}
            </CustomScrollbar>
          )}
          {!activity.field_documents_ac ||
            (activity.field_documents_ac.length === 0 && (
              <div className={styles.noDocs}>{LABEL_NO_DOCUMENT}</div>
            ))}
        </div>
      </div>

      {ACTIVITIES_DOWNLOAD_ZIP_TMS[activity.field_type_ac] && (
        <TagCommanderEvent
          isActive={isDownloading}
          navigation_pagename={ACTIVITIES_DOWNLOAD_ZIP_TMS[activity.field_type_ac]}
          navigation_template="espace_perso"
          useEffectDeps={['navigation_pagename']}
        />
      )}
    </div>
  );
}
