import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormControlLabel, Radio, Typography } from '@material-ui/core';

import styles from './RadioButton.module.scss';

import DefaultIcon from './default.svg';
import ErrorIcon from './error.svg';
import CheckedIcon from './hover.svg';

function RadioLabel(props) {
  const { disabled, error, label, value } = props;

  const radioLabelClassName = classnames(styles.root, {
    error,
  });

  const icon = error ? (
    <img src={ErrorIcon} alt="errorIcon" />
  ) : (
    <img src={DefaultIcon} alt="defaultIcon" />
  );
  return (
    <FormControlLabel
      classes={{ root: radioLabelClassName }}
      value={value}
      control={
        <Radio
          classes={{ root: styles.iconRoot }}
          disableRipple
          disabled={disabled}
          color="inherit"
          checkedIcon={<img src={CheckedIcon} alt="checkedIcon" />}
          icon={icon}
        />
      }
      label={
        <Typography className={error ? classnames(styles.error) : classnames(styles.label)}>
          {label}
        </Typography>
      }
    />
  );
}

RadioLabel.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
};

RadioLabel.defaultProps = {
  disabled: false,
  error: false,
  label: undefined,
  value: undefined,
};

export default RadioLabel;
