import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { isInvite } from 'services/authentication';

import ModalPendingValidation from 'commonUi/ModalPendingValidation/ModalPendingValidation';

export default function PendingValidation() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  if (isInvite()) {
    return isModalOpen ? (
      <ModalPendingValidation open={isModalOpen} handleClose={() => setIsModalOpen(false)} />
    ) : (
      <Redirect to="/deconnexion" />
    );
  }
  return <Redirect to="/" />;
}
