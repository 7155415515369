import React, { useContext, useMemo } from 'react';
import type { ComponentProps } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_ACTABLE,
  LABEL_DELIVERY,
  LABEL_LOT,
  LABEL_LOTS,
  LABEL_NO_LOT,
} from 'settings/labels';
import {
  PROGRAM_HOUSING_TYPE_COLLECTIVE,
  PROGRAM_HOUSING_TYPE_SENIOR,
  PROGRAM_HOUSING_TYPE_STUDENT,
} from 'settings/programs';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { formatActivityDate, showQuarter } from 'services/date';
import { programIsPreview } from 'services/programs';
import { switchSinglePlural } from 'services/string';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import LaunchAlertCta from 'commonUi/LaunchAlertCta/LaunchAlertCta';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import ProgramCardInfoPrice from './ProgramCardInfoPrice';

import styles from './ProgramCardInfo.module.scss';

interface ProgramCardInfoProps {
  availableLots?: number;
  className?: string;
  isHover?: boolean;
  program: ProgramListType;
  tmsInfos?: ComponentProps<typeof LaunchAlertCta>['modalTmsValues'];
}

export default function ProgramCardInfo({
  availableLots,
  className,
  isHover = false,
  program,
  tmsInfos,
}: ProgramCardInfoProps) {
  const { taxesById } = useContext(TaxonomiesContext);

  const housingType = useMemo(() => {
    switch (program.housingType) {
      case PROGRAM_HOUSING_TYPE_COLLECTIVE:
        return 'Collective';
      case PROGRAM_HOUSING_TYPE_STUDENT:
        return 'Étudiante';
      case PROGRAM_HOUSING_TYPE_SENIOR:
        return 'Senior';
      default:
        return undefined;
    }
  }, [program.housingType]);

  const rootClassName = classnames(className, styles.root, {
    [styles.preview]: programIsPreview(program),
  });
  const programPath = `/programme/page/${program.ref}/prix`;

  const ProgramName = (
    <div className={styles.name} title={program.name}>
      {program.name}
    </div>
  );
  const ProgramLocation = (
    <address className={styles.location}>
      <SvgIcon className={styles.icon} iconId="icon-location-xs" />
      <span className={styles.text}>
        {[program.postalCode, program.city].filter(Boolean).join(' ')}
      </span>
    </address>
  );
  const ProgramHousing = housingType && (
    <div className={classnames(styles.type, styles.item)}>
      <div className={styles.label} />
      <div>{housingType}</div>
    </div>
  );

  if (programIsPreview(program)) {
    return (
      <>
        <ReactRouterLink className={rootClassName} to={programPath} target="_blank">
          {ProgramName}
          {ProgramLocation}
          {ProgramHousing}
          {!!program.marketing && (
            <div className={styles.marketing}>
              Commercialisation le{' '}
              <span className={styles.date}>
                {formatActivityDate(program.marketing, 'DD/MM/YYYY')}
              </span>
            </div>
          )}
        </ReactRouterLink>
        <LaunchAlertCta
          classes={{
            root: classnames(styles.marketingAlert, { [styles.hover]: isHover }),
            icon: styles.icon,
            countdown: styles.countdown,
            active: styles.active,
          }}
          launch={program.marketing}
          programData={{
            city: program.city,
            fiscalite: taxesById?.[program.taxes[0]],
            name: program.name,
            postalCode: program.postalCode,
            programRef: program.ref,
          }}
          modalTmsValues={tmsInfos}
        />
      </>
    );
  }

  return (
    <ReactRouterLink className={rootClassName} to={programPath} target="_blank">
      {ProgramName}

      <div className={styles.data}>
        <div className={classnames(styles.column, styles.large)}>{ProgramLocation}</div>
        <div className={classnames(styles.column, styles.small)}>{ProgramHousing}</div>
      </div>

      <div className={styles.data}>
        <div className={classnames(styles.column, styles.large)}>
          <ProgramCardInfoPrice
            classes={{
              root: styles.item,
              label: styles.price,
              number: styles.highlight,
              tax: styles.tax,
            }}
            program={program}
          />
          <div className={classnames(styles.item, styles.highlight)}>
            {availableLots ? (
              <>
                {switchSinglePlural(availableLots, LABEL_LOT, LABEL_LOTS)}{' '}
                {program.kindsDisplay.join(', ')}
              </>
            ) : (
              LABEL_NO_LOT
            )}
          </div>
        </div>
        <div className={classnames(styles.column, styles.small)}>
          {program.delivery ? (
            <div className={styles.item}>
              <span className={styles.label}>{LABEL_DELIVERY}</span>
              <span className={styles.highlight}>{showQuarter(program.delivery)}</span>
            </div>
          ) : (
            <div className={styles.item}>&nbsp;</div>
          )}
          {program.actability ? (
            <div className={styles.item}>
              <span className={styles.label}>{LABEL_ACTABLE}</span>
              <span className={styles.highlight}>{showQuarter(program.actability)}</span>
            </div>
          ) : (
            <div className={styles.item}>&nbsp;</div>
          )}
        </div>
      </div>
    </ReactRouterLink>
  );
}
