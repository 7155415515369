import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { IconButton } from '@material-ui/core';

import Button from 'commonUi/Button/Button';
import { replaceTokens } from 'services';
import {
  EDIT_FOLDER_MODAL_ID,
  LABEL_CANCEL_BUTTON,
  LABEL_CREATE_FOLDER_CREATE_BUTTON,
  LABEL_CREATE_FOLDER_ERROR_HELPER_TEXT,
  LABEL_CREATE_FOLDER_INPUT_LABEL,
  LABEL_CREATE_FOLDER_TITLE,
  LABEL_RENAME_FOLDER,
  LABEL_RENAME_FOLDER_MODAL_TITLE,
  TOKEN_FOLDER_NAME,
} from 'settings/index';

import TextField from 'commonUi/TextField/TextField';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Modal from 'commonUi/Modal/Modal';

import styles from './Modals.module.scss';

export function ModalEditFolder(props: ModalEditFolderType) {
  const {
    closeCallBack,
    folderName,
    folderNames,
    isFolderNameAlreadyUsed,
    open,
    updateFolder,
    setIsFolderNameAlreadyUsed,
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const handleInputOnChange = useCallback(
    e => {
      const inputValue = e.target.value;
      props.handleInputOnChange();
      setInputValue(inputValue);
      if (folderNames.includes(inputValue)) {
        setIsFolderNameAlreadyUsed(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  useEffect(() => {
    setInputValue(folderName);
  }, [open]);

  return (
    <div id={EDIT_FOLDER_MODAL_ID} className={classNames('show', [styles.show])}>
      <Modal
        classes={{ modalSkin: [styles.modalSkin], modalOuter: [styles.modalOuter] }}
        className={styles.modalRoot}
        closeCallback={closeCallBack}
        showModal={open}
        type="form"
      >
        <div className="modal-inner">
          <div className={styles.modalHeader}>
            <IconButton
              classes={{ root: styles.iconButton }}
              disableRipple
              onClick={() => {
                closeCallBack();
                setInputValue('');
              }}
            >
              <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
            </IconButton>
          </div>

          <div className={styles.modalContent}>
            <h2 className={styles.title}>
              {folderName ? LABEL_RENAME_FOLDER_MODAL_TITLE : LABEL_CREATE_FOLDER_TITLE}
            </h2>
            <div className={styles.form}>
              <form>
                <TextField
                  error={isFolderNameAlreadyUsed}
                  helperText={
                    isFolderNameAlreadyUsed
                      ? replaceTokens(LABEL_CREATE_FOLDER_ERROR_HELPER_TEXT, {
                          [TOKEN_FOLDER_NAME]: inputValue,
                        })
                      : undefined
                  }
                  id="folderName"
                  label={LABEL_CREATE_FOLDER_INPUT_LABEL}
                  name="folder-name"
                  required
                  onChange={handleInputOnChange}
                  value={inputValue}
                />
              </form>
            </div>
            <div
              className={classNames(styles.modalFooter, {
                [styles.helperTextDisplay]: isFolderNameAlreadyUsed,
              })}
            >
              <div className={styles.buttons}>
                <div className={styles.buttonsItem}>
                  <Button
                    className={styles.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      closeCallBack();
                      setInputValue('');
                    }}
                  >
                    {LABEL_CANCEL_BUTTON}
                  </Button>
                </div>
                <div className={styles.buttonsItem}>
                  <Button
                    className={styles.button}
                    disabled={!inputValue || inputValue === folderName || isFolderNameAlreadyUsed}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      updateFolder(inputValue);
                    }}
                  >
                    {folderName ? LABEL_RENAME_FOLDER : LABEL_CREATE_FOLDER_CREATE_BUTTON}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

type ModalEditFolderType = {
  closeCallBack: () => void;
  folderName: string;
  folderNames: string[];
  handleInputOnChange: () => void;
  isFolderNameAlreadyUsed: boolean;
  open: boolean;
  setIsFolderNameAlreadyUsed: (isFolderNameAlreadyUsed: boolean) => void;
  updateFolder: (folderName: string) => void;
};
