import React from 'react';

import { useHistory } from 'react-router-dom';

import { LABEL_ACCESS_COMPARISON_REFUSED, LABEL_COMPARISON, LABEL_RETURN } from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ComparisonPage.module.scss';

export function ComparisonPageMobile() {
  const history = useHistory();

  return (
    <div>
      <div className={styles.header}>
        <button className={styles.returnBtn} onClick={() => history.goBack()} type="button">
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_RETURN}</span>
        </button>
        <div className={styles.title}>
          <Icon className={styles.titleIcon} icon="compare" />
          {LABEL_COMPARISON}
        </div>
      </div>
      <div className={styles.content}>
        <p>{LABEL_ACCESS_COMPARISON_REFUSED}</p>
      </div>
    </div>
  );
}
