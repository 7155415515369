import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  image: {
    position: 'relative',
  },
  imageWrapper: {
    borderRadius: '4px',
    height: '135px',
    overflow: 'hidden',
    position: 'relative',
    width: '140px',
  },
  bannerImage: {
    height: '100%',
    width: 'auto',
  },
  new: {
    backgroundColor: COLORS.GREYS.PURE_WHITE,
    border: `1px solid ${COLORS.PRIMARY.BLUE}`,
    color: COLORS.PRIMARY.BLUE,
    fontSize: '5px',
    fontWeight: 'bold',
    lineHeight: '1',
    padding: '3px 5px',
    position: 'absolute',
    left: '-2px',
    textTransform: 'uppercase',
    top: '5px',
  },
  pictosList: {
    alignItems: 'center',
    bottom: '1px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    margin: '0 -1px',
    position: 'absolute',
    left: '1px',
  },
  pictosListItem: {
    padding: '2px 1px',
  },
  imgVi4you: {
    height: '25px',
    width: '25px',
  },
  imgControlledPrices: {
    height: 'auto',
    width: '28px',
  },
});
