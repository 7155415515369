import React, { useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import { HEADER_DOM_ID, PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';

import programLotContext from 'modules/App/Contexts/programLotContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';

import LotRowTablette from 'commonUi/ListLotV2/LotRow/LotRowTablette';
import LotTableContainer from 'commonUi/ListLotV2/LotTable/LotTableContainer';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import { ProgrammeTabletteDetailsCpn } from 'modules/DetailsLotsAndActivity/ProgrammeTabletteDetailsCpn';

import styles from './LotTableTablette.module.scss';

interface LotTableProps {
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headerContent?: ReactNode;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  lots?: LotJson[];
  noResultMessage?: ReactNode;
  pageTemplate?: string;
  pageTemplateRef?: string;
  selectedLots?: { lotNumber: string; programRef: string }[];
  taxById: TaxesById;
  reduceMobilePanel?: () => void;
  statuses: Record<string, Status>;
  updateStatus: (lotNid: string, status: any) => void;
}

export default function LotTableTablette({
  handleOnChangeCheckbox = undefined,
  headerContent = undefined,
  headings = [],
  hideMapButton = false,
  lots = [],
  noResultMessage = null,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  selectedLots = [],
  taxById,
  reduceMobilePanel = undefined,
  statuses,
  updateStatus,
}: LotTableProps) {
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert } = useContext(
    userContext
  );
  const { taxes: fiscalities } = useContext(TaxonomiesContext);
  const { programs } = useContext(programLotContext);

  const { lotNumber, programRef } = useSearch();

  const [currentRowOpen, setCurrentRowOpen] = useState<string>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      lots.length > 0 &&
      !lots.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);
  const [headerTop, setHeaderTop] = useState<number>(0);

  // load current lot
  const { number: currentLotNumber, ref: currentProgramRef } =
    lots?.find(lot => currentRowOpen === lot.nid) ?? {};
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentProgramRef, currentLotNumber);

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = lots?.findIndex(l => l.number === lotNumber);
      const currentOpen = activeIndex !== -1 ? lots[activeIndex].nid : undefined;
      setCurrentRowOpen(currentOpen);
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  useEffect(() => {
    const siteHeader = document.getElementById(HEADER_DOM_ID);
    if (siteHeader) {
      if (currentRowOpen && siteHeader.getBoundingClientRect().top < 0 && headerTop === 0) {
        setHeaderTop(siteHeader.getBoundingClientRect().top);
        siteHeader.style.top = '';
      }
      if (!currentRowOpen && headerTop < 0) {
        siteHeader.style.top = `${headerTop}px`;
        setHeaderTop(0);
      }
    }
  }, [currentRowOpen]);

  if (!lots?.length) return <div>{noResultMessage}</div>;

  // put current lot on top of the list
  let sortedData = lots;
  if (lots && lotNumber && programRef) {
    const lotIndex = lots.findIndex(lot => lot.number === lotNumber && lot.ref === programRef);
    if (lotIndex !== -1) {
      sortedData = [lots[lotIndex], ...lots.slice(0, lotIndex), ...lots.slice(lotIndex + 1)];
    }
  }

  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
  const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);

  return (
    <LotTableContainer scrollable={false}>
      <LotTableErrorModal
        open={loadLotError || openError}
        onClose={() => {
          setOpenError(false);
          setLoadLotError(false);
        }}
        title={LABEL_LOTS_ENABLE_ERROR_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />

      {headerContent && (
        <div
          className={styles.lotsListHeader}
          style={
            programPromoBanner
              ? {
                  top:
                    (programMenuAnchors?.offsetHeight || 0) +
                    (programPromoBanner?.offsetHeight || 0),
                }
              : undefined
          }
        >
          {headerContent}
        </div>
      )}

      {sortedData?.map(lot => (
        <LotRowTablette
          renderShowMore={() => {
            if (!lotDetail || !programDetail) {
              return null;
            }
            return (
              <MobileDrawer isDrawerOpen>
                <ProgrammeTabletteDetailsCpn
                  myBookings={myBookings}
                  myOptions={myOptions}
                  closeDrawer={() => setCurrentRowOpen(undefined)}
                  lot={lot}
                  fiscalities={fiscalities}
                  statuses={statuses}
                  updateStatus={updateStatus}
                  pageTemplate={pageTemplate}
                  pageTemplateRef={pageTemplateRef}
                  lotApiOffre={lotDetail}
                  programDatas={programDetail}
                />
              </MobileDrawer>
            );
          }}
          programs={programs}
          onStatusAlertClick={toggleAlert}
          myBookings={myBookings}
          myOptions={myOptions}
          alerts={alerts}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          headings={headings}
          hideMapButton={hideMapButton}
          key={lot.nid}
          lot={lot}
          statuses={statuses}
          updateStatus={updateStatus}
          taxById={taxById}
          reduceMobilePanel={reduceMobilePanel}
          isChecked={selectedLots.some(l => l.lotNumber === lot.number && l.programRef === lot.ref)}
          pageTemplate={pageTemplate}
          pageTemplateRef={pageTemplateRef}
          isDrawerOpen={currentRowOpen === lot.nid}
          setCurrentRowOpen={setCurrentRowOpen}
        />
      ))}
    </LotTableContainer>
  );
}
