import React from 'react';

import { replaceTokens } from 'services';
import { LABEL_X_COMPARISON, LABEL_X_COMPARISONS, TOKEN_NB } from 'settings';

import { FolderComparison } from 'api/vi3pAPI/apiTypes/FolderType';

import { ComparisonItem } from '../ComparisonItem/ComparisonItem';

import styles from './Tabs.module.scss';

interface TabComparisonsProps {
  comparisons: FolderComparison[];
  handleDelete: (comparison: FolderComparison) => void;
}

export function TabComparisons({ comparisons, handleDelete }: TabComparisonsProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.nbItems}>
          {replaceTokens(comparisons.length <= 1 ? LABEL_X_COMPARISON : LABEL_X_COMPARISONS, {
            [TOKEN_NB]: comparisons.length,
          })}
        </div>
      </div>
      {comparisons.map((lots, idx) => (
        <ComparisonItem
          key={lots.map(l => Object.values(l)).join()}
          comparisonDatas={lots}
          handleDelete={handleDelete}
          number={idx + 1}
        />
      ))}
    </div>
  );
}
