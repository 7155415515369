import React, { ImgHTMLAttributes } from 'react';

export default function ImageOverlay({ alt = '', opacity, src, ...imgProps }: ImageOverlayType) {
  return (
    <div className="image-overlay-wrapper">
      <img src={src} alt={alt} {...imgProps} />
      <div className="image-overlay-component" style={{ opacity }} />
    </div>
  );
}

export type ImageOverlayType = ImgHTMLAttributes<Element> & {
  opacity?: string;
};
