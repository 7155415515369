import { useContext } from 'react';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

export function useMediaImg(media, forceDevice) {
  const { isResponsive } = useContext(ResponsiveContext);
  const singleMedia = media?.[0] || media;
  if (!singleMedia) {
    return { alt: undefined, url: undefined };
  }

  let alt;
  let { url } = singleMedia;
  let width;
  let height;

  if (!url) {
    return { alt: null, url: null };
  }

  if (typeof url === 'object') {
    if (!forceDevice) {
      url = isResponsive ? url.mobile : url.desktop;
    } else {
      url = url[forceDevice];
    }
  }

  if (!url) {
    url = singleMedia.panorama;
  }

  if (singleMedia.properties) {
    alt = singleMedia.properties.alt;
    url = window.vinci.DISABLE_CDN_USE
      ? singleMedia.properties.url
      : url || singleMedia.properties.url;
    width = singleMedia.properties.width;
    height = singleMedia.properties.height;
  }

  return { alt, url, height, width };
}
