import React from 'react';

import { LABEL_SEE_MANAGEMENT_MANDATE } from 'settings/labels';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './AdbInfosBlock.module.scss';

export default function AdbInfosBlock({ program }: AdbInfosBlockProps) {
  return (
    <div className={styles.root}>
      <SvgIcon className={styles.icon} iconId="icon-adb" />
      {program?.packs?.gestionnaireAdb?.url_mandate && (
        <a
          href={program.packs.gestionnaireAdb.url_mandate}
          target="_blank"
          className={styles.infosLink}
          rel="noreferrer"
        >
          {program.packs.gestionnaireAdb.mandate_name || LABEL_SEE_MANAGEMENT_MANDATE}
        </a>
      )}
    </div>
  );
}

export type AdbInfosBlockProps = {
  program: ProgramTypeV2;
};
