import React from 'react';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import { LABEL_PENDING_VALIDATION_TEXT } from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ModalPendingValidation.module.scss';

export default function ModalPendingValidation({
  handleClose,
  open,
  ...DialogProps
}: ModalPendingValidationProps) {
  if (!open) {
    return null;
  }

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: styles.modalPaper,
        root: styles.modalRoot,
      }}
      {...DialogProps}
    >
      <div className={styles.root}>
        <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
          <Icon className={styles.svgIcon} icon="cross" />
        </IconButton>
        <div className={styles.text}>{LABEL_PENDING_VALIDATION_TEXT}</div>
      </div>
    </MuiDialog>
  );
}

export type ModalPendingValidationProps = {
  handleClose: () => void;
  open: boolean;
};
