import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import {
  LABEL_PROGRAM_INFO_ADDRESS,
  LABEL_PROGRAM_INFO_HOURS,
  LABEL_PROGRAM_INFO_MANAGER,
  LABEL_PROGRAM_INFO_NB,
  LABEL_PROGRAM_INFO_OCCUPATION,
  LABEL_PROGRAM_INFO_PRODUCT,
  LABEL_PROGRAM_INFO_SALESPOINT,
  LABEL_PROGRAM_INFO_TYPE,
  LABEL_PROGRAM_INFO_TYPOLOGY,
  LABEL_PROGRAM_INFO_ZONE,
} from 'settings/labels';
import { PROGRAM_HOUSING_TYPE_MAPPING } from 'settings/programs';
import { NonNullableArray } from '../types/tools';

export function getEntries(program: ProgramTypeV2, fields: Record<string, boolean>) {
  let typology = {};
  try {
    typology = JSON.parse(program.repartitionsLots);
  } catch (e) {
    // do nothing
  }
  const housingType = program?.type && PROGRAM_HOUSING_TYPE_MAPPING.get(program.type);
  const mapping: Record<string, { title: string; value: string | number | undefined }> = {
    type: {
      title: LABEL_PROGRAM_INFO_TYPE,
      value: housingType || program?.type,
    },
    manager: {
      title: LABEL_PROGRAM_INFO_MANAGER,
      value: program?.residencesGerees?.nomGestionnaire,
    },
    number: {
      title: LABEL_PROGRAM_INFO_NB,
      value: program?.nbLots,
    },
    typology: {
      title: LABEL_PROGRAM_INFO_TYPOLOGY,
      value: Object.keys(typology).join(', ') || undefined,
    },
    occupation: {
      title: LABEL_PROGRAM_INFO_OCCUPATION,
      value: program?.residencesPartagees?.occupationPossible ? 'Oui' : 'Non',
    },
    address: {
      title: LABEL_PROGRAM_INFO_ADDRESS,
      value: `${program?.localisation?.adresse} ${program?.localisation?.codePostal} ${program?.localisation?.ville}`,
    },
    salespoint: {
      title: LABEL_PROGRAM_INFO_SALESPOINT,
      value: program?.bureauDeVente?.adresse,
    },
    schedule: {
      title: LABEL_PROGRAM_INFO_HOURS,
      value: program?.bureauDeVente?.horairesOuverture,
    },
    product: {
      title: LABEL_PROGRAM_INFO_PRODUCT,
      value: program?.informationsVinciImmobilier?.gammeVip,
    },
    zone: {
      title: LABEL_PROGRAM_INFO_ZONE,
      value: program?.informationsFiscalesEtFinancieres?.zoneFiscale,
    },
  };

  const entries = Object.entries(fields).map(([key, show]) => (show ? mapping[key] : undefined));
  return entries.filter(x => x?.value) as NonNullableArray<typeof entries>;
}
