import React from 'react';
import classnames from 'classnames';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ToggleButton.module.scss';

export default function ToggleButton({
  nb,
  selectedTypologies,
  setSelectedTypologies,
  typology,
}: ToggleButtonProps) {
  function handleClick() {
    if (selectedTypologies.includes(typology)) {
      setSelectedTypologies(selectedTypologies.filter(t => t !== typology));
    } else {
      setSelectedTypologies([...selectedTypologies, typology]);
    }
  }

  return (
    <button
      className={classnames(styles.root, {
        [styles.disabled]: !selectedTypologies.includes(typology),
      })}
      type="button"
      onClick={handleClick}
    >
      <span className={styles.label}>{`${typology} (${nb})`}</span>
      <Icon className={styles.icon} icon="cross" />
    </button>
  );
}

export type ToggleButtonProps = {
  nb: number;
  selectedTypologies: string[];
  setSelectedTypologies: (typologies: string[]) => void;
  typology: string;
};
