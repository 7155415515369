import React, { forwardRef, useEffect, useState } from 'react';
import type { InputHTMLAttributes } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { debounce } from 'lodash';
import classnames from 'classnames';
import {
  CircularProgress,
  ClickAwayListener as MuiClickAwayListener,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@material-ui/core';
import MuiAutoComplete from '@material-ui/lab/Autocomplete';
import type { AutocompleteProps as MuiAutoCompleteProps } from '@material-ui/lab/Autocomplete';

import { LABEL_SEARCH_NO_LOCATION } from 'settings/labels';

import type { Ville } from 'api/viOffresAPI/apiTypes/GeoSearch';

import useAutocomplete from 'modules/HomePage/hooks/useAutocomplete';

import AutocompletePaper from 'modules/HomePage/Components/Search/Autocomplete/AutocompletePaper';

import styles from './AutocompleteCity.module.scss';

interface AutocompleteCityProps {
  classes?: Partial<Record<'root' | 'input' | 'inputOpen' | 'paper' | 'popper', string>>;
  defaultValue?: Ville;
  disabled?: boolean;
  id: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange?: MuiAutoCompleteProps<Ville, false, true, false>['onChange'];
  value?: Ville;
}

export default forwardRef<HTMLInputElement, AutocompleteCityProps>(function AutocompleteCity(
  { classes = {}, defaultValue, disabled = false, id, inputProps, onChange = () => {}, value },
  inputRef
) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const { results: autocompleteOptions, isValidating } = useAutocomplete(
    search,
    data => data.villes
  );

  const debounced = debounce(setSearch, 500);

  useEffect(() => {
    setIsOpen(!!search && search.length >= 2);
  }, [search]);

  return (
    <MuiClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={classnames(classes.root, styles.root)}>
        <MuiAutoComplete<typeof autocompleteOptions[number], false, true, false>
          disableClearable
          disabled={disabled}
          forcePopupIcon={false}
          classes={{
            popper: classnames(classes.popper, styles.popper),
            paper: classnames(classes.paper, styles.paper),
          }}
          defaultValue={defaultValue}
          onChange={(...args) => {
            setSearch('');
            onChange(...args);
          }}
          onInputChange={(ev, value) => {
            if (ev?.type === 'change') {
              debounced(value);
            }
          }}
          open={isOpen}
          options={!isValidating ? autocompleteOptions : []}
          getOptionLabel={option => `${option.libelle_ville} (${option.libelle_postal_code})`}
          noOptionsText={
            isValidating ? (
              <CircularProgress variant="indeterminate" size={24} />
            ) : (
              LABEL_SEARCH_NO_LOCATION
            )
          }
          renderInput={({ id: _, InputProps, inputProps: inputPropsAutocomplete, ...props }) => (
            <MuiTextField
              inputRef={inputRef}
              id={id}
              InputProps={{
                ...InputProps,
                endAdornment: false,
                disableUnderline: true,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                ...inputPropsAutocomplete,
                ...inputProps,
                className: classnames(
                  inputProps?.className,
                  classes.input,
                  isOpen ? classes.inputOpen : undefined,
                  styles.input,
                  styles.inputOpen
                ),
              }}
              {...props}
            />
          )}
          renderOption={(option, state) => {
            if (state.inputValue) {
              const regex = new RegExp(state.inputValue, 'gi');
              const highlight = (str: string) =>
                ReactHtmlParser(
                  str.replace(regex, match => `<span class="search-highlight">${match}</span>`)
                );
              return (
                <MuiTypography classes={{ root: styles.typographyRoot }} noWrap>
                  {highlight(option.libelle_ville)} ({highlight(option.libelle_postal_code)})
                </MuiTypography>
              );
            }
            return (
              <MuiTypography noWrap>
                {option.libelle_ville} ({option.libelle_postal_code})
              </MuiTypography>
            );
          }}
          PaperComponent={AutocompletePaper}
          value={value}
        />
      </div>
    </MuiClickAwayListener>
  );
});
