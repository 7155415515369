import React from 'react';

import { ParagraphApiRte } from 'types/paragraph';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

export default function ParagraphRte({
  containerClassName = undefined,
  paragraph,
}: ParagraphRteProps) {
  const { riche_texte: body } = paragraph;

  if (!paragraph) {
    return null;
  }

  return (
    <div className={containerClassName}>
      <FieldRte html={body} />
    </div>
  );
}

export type ParagraphRteProps = {
  containerClassName?: string;
  paragraph: ParagraphApiRte;
};
