import React, { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import type { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { Button as MuiButton, CircularProgress as MuiCircularProgress } from '@material-ui/core';
import type { ButtonProps as MuiButtonProps } from '@material-ui/core';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';
import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import styles from './Button.module.scss';

interface ButtonProps extends Omit<MuiButtonProps, 'action' | 'color'> {
  action?: boolean;
  activeClassName?: string;
  color?: ComponentProps<typeof MuiCircularProgress>['color'];
  component?: typeof RouterLink;
  icon?: Icons;
  iconClass?: string;
  iconDropdownClass?: string;
  iconId?: string;
  isActive?: boolean;
  labelClassName?: string;
  labelTextClassName?: string;
  labelWhileLoading?: boolean;
  loading?: boolean;
  loadingClassName?: string;
  loadingReplaceIcon?: boolean;
  rel?: string;
  target?: string;
  to?: string;
  tooltip?: string;
  tooltipClassName?: ComponentProps<typeof Tooltip>['className'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  withDropdown?: boolean;
  withSaveAction?: boolean;
}

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    action = false,
    activeClassName,
    className,
    color = 'inherit',
    disabled = false,
    icon,
    iconClass,
    iconDropdownClass,
    iconId,
    isActive = false,
    labelClassName,
    labelTextClassName,
    labelWhileLoading = false,
    loading = false,
    loadingClassName,
    loadingReplaceIcon = false,
    onClick,
    tooltip,
    tooltipClassName = undefined,
    tooltipPlacement = 'top',
    variant,
    withDropdown = false,
    withSaveAction = false,
    children,
    ...props
  },
  ref
) {
  const btnClassName = classnames(className, styles.root, {
    'content-regular-16': variant === 'contained',
    'content-regular-15': variant === 'outlined' && !action,
    'content-regular-14': action || withDropdown,
    [styles.loading]: loading,
    [classnames(styles.isActive, activeClassName)]: withDropdown && isActive,
  });
  const button = (
    <MuiButton
      ref={ref}
      classes={{
        containedPrimary: styles.containedPrimary,
        containedSecondary: styles.containedSecondary,
        outlined: icon && tooltip ? styles.outlined : styles.outlinedWithText,
        outlinedPrimary: !withDropdown
          ? styles.outlinedColorPrimary
          : styles.outlinedColorPrimaryDropdown,
        outlinedSecondary: styles.outlinedColorSecondary,
        label: classnames(labelClassName, action ? styles.labelAction : styles.label),
        root: classnames(styles.root, styles.selectorWeight),
        colorInherit: styles.colorInherit,
        text: styles.text,
      }}
      className={btnClassName}
      color={color}
      disableRipple
      onClick={onClick}
      variant={variant}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <MuiCircularProgress
          classes={{
            colorPrimary: styles.colorPrimary,
            colorSecondary: styles.colorSecondary,
            root: classnames(styles.loader, loadingClassName, {
              [styles.withLabel]: labelWhileLoading,
            }),
          }}
          variant="indeterminate"
          color={color}
          size={24}
        />
      )}
      {icon && !loadingReplaceIcon && !loading && (
        <Icon
          className={classnames(iconClass, styles.icon, {
            [styles.isActiveIconDropdown]: withDropdown && isActive,
            [styles.disabledIconSaveAction]: withSaveAction && disabled,
          })}
          icon={icon}
        />
      )}

      {iconId && !loadingReplaceIcon && !loading && (
        <SvgIcon className={classnames(styles.iconSprite, iconClass)} iconId={iconId} />
      )}
      {(!loading || labelWhileLoading) && (
        <span className={classnames(styles.labelText, labelTextClassName)}>{children}</span>
      )}
      {withDropdown && (
        <Icon
          className={classnames(iconDropdownClass, styles.iconDropdown, {
            [styles.dropdownReturn]: isActive,
          })}
          icon="chevron-down"
        />
      )}
    </MuiButton>
  );
  return (
    <>
      {(icon || iconId) && tooltip ? (
        <Tooltip className={tooltipClassName} arrow placement={tooltipPlacement} title={tooltip}>
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
});
