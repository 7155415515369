import React from 'react';
import classNames from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';

import styles from './PanelContainer.module.scss';

interface PanelContainerProps {
  headerRight?: ReactNode;
  title?: string;
  headerClassName?: string;
}

export default function PanelContainer({
  children,
  headerRight,
  title,
  headerClassName = '',
}: PropsWithChildren<PanelContainerProps>) {
  return (
    <div className={styles.root}>
      <div className={styles.panel}>
        {title && (
          <div className={classNames(styles.header, headerClassName)}>
            <h2 className={styles.title}>{title}</h2>
            {headerRight}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
