import React, { useContext, useMemo, useState } from 'react';
import classnames from 'classnames';
import { uniqBy } from 'lodash';
import qs from 'query-string';

import { downloadDocs } from 'services/download';
import { replaceTokens } from 'services/formatter';
import { getHeadingsWidth, getLotHeadings } from 'services/lots';
import { taxMapping } from 'services/taxes';
import {
  LABEL_3_LOTS_MAX,
  LABEL_ADD_TO_A_FOLDER,
  LABEL_COMPARE,
  LABEL_DELETE,
  LABEL_DOWNLOAD,
  LABEL_SELECT_ALL,
  LABEL_SEND,
  LABEL_SEND_FOLDER_DOCUMENTS,
  LABEL_UNSELECT_ALL,
  LABEL_X_LOT,
  LABEL_X_LOTS,
} from 'settings/labels';
import { PANEL_ID_FOLDERS } from 'settings/panels';
import { SORT_LOT_BY_PROGRAM, SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';
import { TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS } from 'settings/taxes';
import { TMS_ORIGIN_FOLDER } from 'settings/tms';
import { TOKEN_FOLDER_NAME, TOKEN_NB } from 'settings/token';

import type { FolderLot, FolderType } from 'api/vi3pAPI/apiTypes/FolderType';
import type { DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';
import type { HeadingType, LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';

import { filterNullable } from 'services/array';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import programLotContext from 'modules/App/Contexts/programLotContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getLot } from 'api/viOffresAPI/apiClient';

import { useModal } from 'hooks/useModal';
import { useSortedLots } from 'hooks/useSortedLots';
import { usePanels } from 'hooks/usePanels';

import Button from 'commonUi/Button/Button';
import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';
import { ModalSendDocuments } from 'modules/ActionsProgramsAndLots/ModalSendDocuments/ModalSendDocuments';
import FolderLinks from 'modules/Folders/FolderLinks';

import LotTableTablette from './LotTableTablette';
import LotTableDesktop from './LotTableDesktop';
import LotTableMobile from './LotTableMobile';

import { useDocuments } from '../../hooks/useDocuments';

import styles from './Tabs.module.scss';

interface TabLotsProps {
  folder: FolderType;
  handleDelete: (lots: FolderLot[]) => void;
  lots: FolderLot[];
}

export function TabLots({ folder, handleDelete, lots: lotsId }: TabLotsProps) {
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType } = useContext(
    foldersContext
  );
  const { taxesById } = useContext(TaxonomiesContext);

  const { getLot: getLotFromJson, isLoading } = useContext(programLotContext);

  const lots = useMemo(
    () =>
      isLoading
        ? []
        : filterNullable(
            lotsId.map(({ lotNumber, programRef }) => getLotFromJson(lotNumber, programRef))
          ),
    [lotsId, isLoading, getLot]
  );

  const [lotsSortBy, setLotsSortBy] = useState<LotSortBy>(SORT_LOT_BY_PROGRAM);
  const [lotsSortOrder, setLotsSortOrder] = useState<LotSortOrder>(SORT_ORDER_ASC);
  const [selectedLots, setSelectedLots] = useState<{ lotNumber: string; programRef: string }[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const programsRef = lots.map(lot => ({ programRef: lot.ref }));
  const { lots: sortedLots, isLoading: isSortLoading } = useSortedLots(
    lots,
    lotsSortBy,
    lotsSortOrder,
    programsRef
  );
  const uniqueTaxes = sortedLots.map(lot => lot.tax).filter((x, i, a) => a.indexOf(x) === i);

  let viewportMode: Parameters<typeof getLotHeadings>[4] = 'desktop';
  if (isTablette) {
    viewportMode = 'tablet';
  } else if (isResponsive) {
    viewportMode = 'mobile';
  }

  const headings = useMemo(() => {
    const isPinel =
      !!taxesById &&
      uniqueTaxes.every(taxId =>
        [TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS].includes(taxesById[taxId])
      );
    const taxeFormatted =
      taxesById && uniqueTaxes.length === 1 ? taxMapping.get(taxesById[uniqueTaxes[0]]) : undefined;
    return getLotHeadings(
      false,
      isPinel && uniqueTaxes.length > 1 ? TAX_TYPE_PINEL : taxeFormatted,
      true,
      false,
      viewportMode
    ) as HeadingType[];
  }, [uniqueTaxes, taxesById, viewportMode]);
  const headingsWidth = getHeadingsWidth(
    false,
    taxesById && uniqueTaxes.length === 1 ? taxMapping.get(taxesById[uniqueTaxes[0]]) : undefined
  );
  const { open, openModal, closeModal } = useModal();

  const comparisonQueryParams = qs.stringify(
    { lots: selectedLots.map(s => [s.programRef, s.lotNumber]) },
    {
      encode: false,
      arrayFormat: 'bracket',
    }
  );

  const { documents } = useDocuments(comparisonQueryParams);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  if (!sortedLots || !lots || !taxesById) {
    return null;
  }

  function handleOnChangeCheckbox(programRef: string, lotNumber: string) {
    if (
      selectedLots.find(
        selectedLot => selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber
      )
    ) {
      setSelectedLots(selectedLots =>
        selectedLots.filter(
          selectedLot =>
            !(selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber)
        )
      );
      return;
    }
    setSelectedLots(selectedLots => [...selectedLots, { programRef, lotNumber }]);
  }

  function handleSelectAll() {
    if (selectedLots.length === lots.length) {
      setSelectedLots([]);
    } else {
      const allSelectedLots = lots.map(l => ({ lotNumber: l.number, programRef: l.program.ref }));
      setSelectedLots(prevState =>
        uniqBy([...prevState, ...allSelectedLots], l => `${l.programRef}-${l.lotNumber}`)
      );
    }
  }

  function deleteLots() {
    handleDelete(selectedLots);
    setSelectedLots([]);
  }

  async function downloadDocuments() {
    setDownloadInProgress(true);
    const lotsPromise = selectedLots?.map(l => getLot(l.programRef, l.lotNumber));
    const lots = await Promise.all(lotsPromise);
    if (lots && lots.length > 0) {
      const documents = lots
        .filter(l => l.documents && l.documents.length > 0)
        .reduce<DocumentTypeV2[]>((acc, lot) => acc.concat(lot.documents), []);

      await downloadDocs(documents, folder.title);
    }
    setDownloadInProgress(false);
  }

  function handleAddToFolder() {
    if (folderSaveType !== 'lot') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('lot');
    setFolderLots(selectedLots);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.nbItems}>
            {replaceTokens(lots.length <= 1 ? LABEL_X_LOT : LABEL_X_LOTS, {
              [TOKEN_NB]: lots.length,
            })}
          </div>
          <div>
            {lots.length > 0 && (
              <button className={styles.buttonLink} type="button" onClick={handleSelectAll}>
                {selectedLots.length === lots.length ? LABEL_UNSELECT_ALL : LABEL_SELECT_ALL}
              </button>
            )}
          </div>
          <div className={styles.buttonsHeader}>
            {selectedLots.length > 0 && (
              <div className={styles.buttons}>
                {!isResponsive && selectedLots.length > 1 && (
                  <div className={styles.buttonsItem}>
                    <Button
                      className={styles.button}
                      disabled={selectedLots.length > 3}
                      color="primary"
                      href={
                        selectedLots.length <= 3
                          ? `/comparaison?${comparisonQueryParams}&origin=${TMS_ORIGIN_FOLDER}`
                          : undefined
                      }
                      iconId={selectedLots.length > 3 ? 'icon-compare-disable' : 'icon-compare'}
                      iconClass={styles.buttonSvgIcon}
                      labelClassName={styles.label}
                      tooltip={`${LABEL_COMPARE} (${LABEL_3_LOTS_MAX})`}
                      variant="outlined"
                    />
                  </div>
                )}
                <div className={styles.buttonsItem}>
                  <Button
                    className={styles.button}
                    color="primary"
                    iconId="icon-add-to-folder"
                    iconClass={styles.buttonSvgIcon}
                    labelClassName={styles.label}
                    onClick={handleAddToFolder}
                    tooltip={LABEL_ADD_TO_A_FOLDER}
                    variant="outlined"
                  />
                </div>
                <div className={styles.buttonsItem}>
                  <Button
                    className={styles.button}
                    color="primary"
                    iconId={!downloadInProgress ? 'icon-download-bicolor' : ''}
                    iconClass={styles.buttonSvgIcon}
                    labelWhileLoading
                    loading={downloadInProgress}
                    loadingClassName={styles.buttonLoader}
                    onClick={downloadDocuments}
                    tooltip={LABEL_DOWNLOAD}
                    variant="outlined"
                  />
                </div>
                <div className={styles.buttonsItem}>
                  <Button
                    className={styles.button}
                    color="primary"
                    iconId="icon-send"
                    iconClass={styles.buttonSvgIcon}
                    onClick={openModal}
                    tooltip={LABEL_SEND}
                    variant="outlined"
                  />
                </div>
                <div className={styles.buttonsItem}>
                  <Button
                    className={classnames(styles.button, styles.deleteBtn)}
                    color="primary"
                    icon="trash"
                    iconClass={styles.buttonIcon}
                    onClick={deleteLots}
                    tooltip={LABEL_DELETE}
                    variant="outlined"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div id="panelContent">
          {isLoading || isSortLoading ? (
            <div className={styles.loading}>
              <SpinLoader width={100} height={100} />
            </div>
          ) : (
            <ActivityModalsProvider>
              {!isResponsive && !isTablette && (
                <LotTableDesktop
                  data={sortedLots}
                  handleOnChangeCheckbox={handleOnChangeCheckbox}
                  headings={headings}
                  headingsWidth={headingsWidth}
                  reorder={heading => {
                    if (!heading.sort) {
                      return;
                    }
                    if (heading.sort === lotsSortBy) {
                      if (lotsSortOrder === SORT_ORDER_ASC) {
                        setLotsSortOrder(SORT_ORDER_DESC);
                      } else {
                        setLotsSortOrder(SORT_ORDER_ASC);
                      }
                    } else {
                      setLotsSortBy(heading.sort);
                      setLotsSortOrder(SORT_ORDER_ASC);
                    }
                  }}
                  selectedLots={selectedLots}
                  sortBy={lotsSortBy}
                  sortOrder={lotsSortOrder}
                />
              )}
              {!isResponsive && isTablette && (
                <LotTableTablette
                  handleOnChangeCheckbox={handleOnChangeCheckbox}
                  headings={headings}
                  hideMapButton
                  lots={lots}
                  selectedLots={selectedLots}
                />
              )}
              {isResponsive && (
                <LotTableMobile
                  handleOnChangeCheckbox={handleOnChangeCheckbox}
                  headings={headings}
                  hideMapButton
                  lots={lots}
                  selectedLots={selectedLots}
                  withCheckbox
                />
              )}
            </ActivityModalsProvider>
          )}
        </div>
      </div>

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      <ModalSendDocuments
        closeCallBack={closeModal}
        documents={documents}
        open={open}
        title={replaceTokens(LABEL_SEND_FOLDER_DOCUMENTS, {
          [TOKEN_FOLDER_NAME]: folder.title,
        })}
      />
    </>
  );
}
