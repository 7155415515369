import { createContext } from 'react';

import {
  MODAL_ID_CANCEL_OPTION,
  MODAL_ID_CANCEL_PREBOOK,
  MODAL_ID_CREATE_OPTION,
} from 'settings/modal';

export const ActivityModalsDefault = {
  currentModal: undefined,
  openCreateOption: () => {},
  openCancelOption: () => {},
  openCancelPreBooking: () => {},
  closeModal: () => {},
};

export default createContext<{
  currentModal:
    | typeof MODAL_ID_CREATE_OPTION
    | typeof MODAL_ID_CANCEL_OPTION
    | typeof MODAL_ID_CANCEL_PREBOOK
    | undefined;
  openCreateOption: (
    data: { lotNumber: string; programRef: string; programName?: string },
    onSuccess?: () => void,
    optimisticSuccess?: () => void,
    undoOptimisticSuccess?: () => void
  ) => void;
  openCancelOption: (
    data: { activityId: string; expirationDate: Date | undefined; lotNumber: string },
    onSuccess?: () => void,
    optimisticSuccess?: () => void,
    undoOptimisticSuccess?: () => void
  ) => void;
  openCancelPreBooking: (
    data: { activityId: string; expirationDate: Date | undefined; lotNumber: string },
    onSuccess?: () => void,
    optimisticSuccess?: () => void,
    undoOptimisticSuccess?: () => void
  ) => void;
  closeModal: () => void;
}>(ActivityModalsDefault);
