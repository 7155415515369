import React from 'react';
import classnames from 'classnames';

import {
  DOC_TYPE_COMMERCE,
  DOC_TYPE_COMPLEMENTAIRE,
  DOC_TYPE_RESERVATION,
} from 'settings/documents';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import DocumentsList from 'commonUi/DocumentsList/DocumentsList';

import styles from './ProgramDocuments.module.scss';

export default function ProgramDocuments({ className = undefined, program }: ProgramDocumentsType) {
  return (
    <DocumentsList
      className={classnames(className, styles.root)}
      columns={[DOC_TYPE_RESERVATION, DOC_TYPE_COMPLEMENTAIRE, DOC_TYPE_COMMERCE]}
      program={program}
    />
  );
}

export type ProgramDocumentsType = {
  className?: string;
  program: ProgramTypeV2;
};
