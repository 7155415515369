import React from 'react';
import { reverse, sortBy } from 'lodash';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import styles from './LotPlan.module.scss';

export function LotPlan({ lot }: LotPlanProps) {
  const plans = reverse(
    sortBy(
      lot.documents.filter(d => d.nature === 'Plan'),
      'dateDerniereModification'
    )
  );

  if (plans?.length === 0) {
    return <div>-</div>;
  }

  return (
    <a className={styles.root} href={plans[0].url} target="_blank" rel="noreferrer">
      <Icon className={styles.icon} icon="plans" />
    </a>
  );
}

type LotPlanProps = {
  lot: LotTypeV2;
};
