import React, { useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import {
  ACTIVITIES_TABS_TMS,
  ACTIVITY_STATUS_WON,
  ACTIVITY_TAB_ID_BOOKINGS,
  ACTIVITY_TAB_ID_OPTIONS,
  ACTIVITY_TAB_ID_PREBOOKINGS,
  ACTIVITY_TAB_ID_SALES,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
  STEPS_LIST_BOOKINGS_ACTS,
  STEPS_LIST_OPTIONS_PREBOOKINGS,
  STEPS_SHARES_BOOKINGS_ACTS,
  STEPS_SHARES_OPTIONS_PREBOOKINGS,
} from 'settings/activity';
import {
  LABEL_STEPS_BOOKINGS_ACTS_HEADER,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_HEADER,
  LABEL_TAB_OPTIONS,
  LABEL_TAB_PRERESERVATIONS,
  LABEL_TAB_RESERVATIONS,
  LABEL_TAB_SALES,
} from 'settings/labels';

import type { Activity as ActivityType } from 'api/vi3pAPI/apiTypes/ActivityType';

import { useBookings, useOptions, usePreBookings } from 'hooks/useActivities';

import ActivityStepsSummary from 'commonUi/ActivityStepsSummary/ActivityStepsSummary';
import Tabs from 'commonUi/Tabs/Tabs';
import TabPanel from 'commonUi/Tabs/TabPanel';

import ActivityTab from './ActivityTab';

import styles from './Activity.module.scss';

function getActivityTabLabel(
  label: string,
  activities: ActivityType[] | undefined,
  isLoading = false
) {
  if (!label) {
    return null;
  }
  if (isLoading) {
    return (
      <span className={styles.tab}>
        {label}
        <CircularProgress className={styles.tabLoader} variant="indeterminate" size={16} />
      </span>
    );
  }
  if (!activities) {
    return label;
  }
  return activities && `${label} (${activities.length})`;
}

export default function Activity({ history, match }: RouteComponentProps<{ activeTab: string }>) {
  const activeTab = match.params.activeTab || ACTIVITY_TAB_ID_OPTIONS;

  const { data: bookings, isLoading: isBookingLoading, url: bookingsUrl } = useBookings('all');
  const { data: options, isLoading: isOptionsLoading, url: optionsUrl } = useOptions('all');
  const { data: preBookings, isLoading: isPreBookingLoading, url: preBookingsUrl } = usePreBookings(
    'all'
  );

  const sales = bookings?.filter(booking => booking.field_statutnom_ac === ACTIVITY_STATUS_WON);

  const apiUrls = useMemo(
    () => ({
      bookings: bookingsUrl,
      options: optionsUrl,
      preBookings: preBookingsUrl,
      sales: bookingsUrl,
    }),
    [bookingsUrl, optionsUrl, preBookingsUrl]
  );

  const tabHeaders = [
    {
      id: ACTIVITY_TAB_ID_OPTIONS,
      label: getActivityTabLabel(LABEL_TAB_OPTIONS, options, isOptionsLoading),
    },
    {
      id: ACTIVITY_TAB_ID_PREBOOKINGS,
      label: getActivityTabLabel(LABEL_TAB_PRERESERVATIONS, preBookings, isPreBookingLoading),
    },
    {
      id: ACTIVITY_TAB_ID_BOOKINGS,
      label: getActivityTabLabel(LABEL_TAB_RESERVATIONS, bookings, isBookingLoading),
    },
    {
      id: ACTIVITY_TAB_ID_SALES,
      label: getActivityTabLabel(LABEL_TAB_SALES, sales, isBookingLoading),
    },
  ];

  return (
    <Tabs
      sticky
      defaultTab={activeTab}
      headers={tabHeaders}
      id="activity-tabs"
      onTabChange={newTab => history.push(`/mon-activite-commerciale/${newTab}`)}
    >
      {(activeTab, changeTab) => (
        <>
          <TabPanel
            index={ACTIVITY_TAB_ID_OPTIONS}
            value={activeTab}
            tms={ACTIVITIES_TABS_TMS[ACTIVITY_TAB_ID_OPTIONS]}
          >
            <ActivityTab
              activityType={ACTIVITY_TYPE_OPTION}
              data={options}
              apiUrls={apiUrls}
              changeTab={changeTab}
              isLoading={isOptionsLoading}
              stepsSummaryContent={
                <ActivityStepsSummary
                  className={styles.stepsSummary}
                  steps={STEPS_LIST_OPTIONS_PREBOOKINGS}
                  stepsShares={STEPS_SHARES_OPTIONS_PREBOOKINGS}
                  title={LABEL_STEPS_OPTIONS_PREBOOKINGS_HEADER}
                />
              }
            />
          </TabPanel>
          <TabPanel
            index={ACTIVITY_TAB_ID_PREBOOKINGS}
            value={activeTab}
            tms={ACTIVITIES_TABS_TMS[ACTIVITY_TAB_ID_PREBOOKINGS]}
          >
            <ActivityTab
              activityType={ACTIVITY_TYPE_PRE_BOOKING}
              data={preBookings}
              apiUrls={apiUrls}
              changeTab={changeTab}
              isLoading={isPreBookingLoading}
              stepsSummaryContent={
                <ActivityStepsSummary
                  className={styles.stepsSummary}
                  steps={STEPS_LIST_OPTIONS_PREBOOKINGS}
                  stepsShares={STEPS_SHARES_OPTIONS_PREBOOKINGS}
                  title={LABEL_STEPS_OPTIONS_PREBOOKINGS_HEADER}
                />
              }
            />
          </TabPanel>
          <TabPanel
            index={ACTIVITY_TAB_ID_BOOKINGS}
            value={activeTab}
            tms={ACTIVITIES_TABS_TMS[ACTIVITY_TAB_ID_BOOKINGS]}
          >
            <ActivityTab
              activityType={ACTIVITY_TYPE_BOOKING}
              data={bookings}
              apiUrls={apiUrls}
              changeTab={changeTab}
              isLoading={isBookingLoading}
              stepsSummaryContent={
                <ActivityStepsSummary
                  className={classnames(styles.stepsSummary, styles.stepsSummaryLarge)}
                  steps={STEPS_LIST_BOOKINGS_ACTS}
                  stepsShares={STEPS_SHARES_BOOKINGS_ACTS}
                  title={LABEL_STEPS_BOOKINGS_ACTS_HEADER}
                />
              }
            />
          </TabPanel>
          <TabPanel
            index={ACTIVITY_TAB_ID_SALES}
            value={activeTab}
            tms={ACTIVITIES_TABS_TMS[ACTIVITY_TAB_ID_SALES]}
          >
            <ActivityTab
              activityType={ACTIVITY_TYPE_SALE}
              data={sales}
              apiUrls={apiUrls}
              changeTab={changeTab}
              isLoading={isBookingLoading}
              stepsSummaryContent={
                <ActivityStepsSummary
                  className={classnames(styles.stepsSummary, styles.stepsSummaryLarge)}
                  steps={STEPS_LIST_BOOKINGS_ACTS}
                  stepsShares={STEPS_SHARES_BOOKINGS_ACTS}
                  title={LABEL_STEPS_BOOKINGS_ACTS_HEADER}
                />
              }
            />
          </TabPanel>
        </>
      )}
    </Tabs>
  );
}
