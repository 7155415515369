import React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { LABEL_KNOW_MORE } from 'settings/labels';

import LazyImage from 'commonUi/LazyImage/LazyImage';

import styles from './NewsSliderItem.module.scss';

interface NewsSliderItemProps {
  className?: string;
  description: ReactNode;
  image?: string;
  style?: CSSProperties;
  title: string;
  url: string;
}

export default function NewsSliderItem({
  className,
  description,
  image,
  style,
  title,
  url,
}: NewsSliderItemProps) {
  return (
    <div className={classnames(className, styles.root)} style={style}>
      <LazyImage
        className={styles.img}
        alt={title}
        src={image || '/images/placeholders/283x159.png'}
        title={title}
      />
      <div className={styles.content}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <Link className={styles.link} to={url} target="_blank" rel="noreferrer">
          {LABEL_KNOW_MORE}
        </Link>
      </div>
    </div>
  );
}
