import React from 'react';
import classnames from 'classnames';

import styles from './NotificationDot.module.scss';

interface NotificationDotProps {
  amount?: number;
  className?: string;
  max?: number;
  maxIsInclusive?: boolean;
  TagName?: keyof JSX.IntrinsicElements;
}

export default function NotificationDot({
  amount = 0,
  className,
  max,
  maxIsInclusive = false,
  TagName = 'span',
}: NotificationDotProps) {
  if (amount <= 0) {
    return null;
  }
  const classNames = classnames(className, styles.root);
  if (typeof max === 'number' && (maxIsInclusive ? amount >= max : amount > max)) {
    return <TagName className={classNames}>{Math.trunc(max)}+</TagName>;
  }
  return <TagName className={classNames}>{Math.trunc(amount)}</TagName>;
}
