import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import {
  LABEL_ADDITIONAL_INFORMATIONS,
  LABEL_FINANCIAL_INFORMATIONS,
  LABEL_GENERAL_INFORMATIONS,
  LABEL_HOUSING_INFORMATIONS,
} from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import LotPdfGeneralInfos from './LotPdfGeneralInfos';
import LotPdfHousingInfos from './LotPdfHousingInfos';
import LotPdfFinancialInfos from './LotPdfFinancialInfos';
import LotPdfAdditionalInfos from './LotPdfAdditionalInfos';

import styles from './LotPdfInfosStyles';

export default function LotPdfInfos({ lot, program }: LotPdfInfosProps) {
  return (
    <View style={styles.infos}>
      <View style={[styles.col, styles.colGeneralInfos]}>
        <View style={styles.colContent}>
          <Text style={styles.title}>{LABEL_GENERAL_INFORMATIONS}</Text>
          <View style={styles.titleBorder} />
          <LotPdfGeneralInfos lot={lot} program={program} />
        </View>
      </View>
      <View style={[styles.col, styles.colHousingInfos]}>
        <View style={styles.colContent}>
          <Text style={styles.title}>{LABEL_HOUSING_INFORMATIONS}</Text>
          <View style={styles.titleBorder} />
          <LotPdfHousingInfos lot={lot} />
        </View>
      </View>
      <View style={[styles.col, styles.colAdditionalInfos]}>
        <View style={styles.colContent}>
          <Text style={styles.title}>{LABEL_ADDITIONAL_INFORMATIONS}</Text>
          <View style={styles.titleBorder} />
          <LotPdfAdditionalInfos lot={lot} />
        </View>
      </View>
      <View style={[styles.col, styles.colFinancialInfos]}>
        <View style={styles.colContent}>
          <Text style={styles.title}>{LABEL_FINANCIAL_INFORMATIONS}</Text>
          <View style={styles.titleBorder} />
          <LotPdfFinancialInfos lot={lot} program={program} />
        </View>
      </View>
    </View>
  );
}

export type LotPdfInfosProps = {
  lot: LotTypeV2;
  program: ProgramTypeV2;
};
