import React, { useEffect, useState } from 'react';
import type { ComponentProps } from 'react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import qs from 'query-string';
import classnames from 'classnames';

import { LABEL_PRE_BOOKING, LABEL_QUIT } from 'settings';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import { LotCard } from 'commonUi/LotCard/LotCard';
import LotPromotion from 'commonUi/ListLotV2/LotPromotion/LotPromotion';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import { FormPrebooking } from './FormPrebooking';
import { ConfirmationPrebooking } from './ConfirmationPrebooking';

import styles from '../PageAction.module.scss';

export function Prebooking({
  history,
  location,
  match,
}: RouteComponentProps<{ programRef?: string; lotNumber?: string }>) {
  const { programRef, lotNumber } = match.params;
  const [successData, setSuccessData] = useState<
    ComponentProps<typeof ConfirmationPrebooking>['successData']
  >();
  const { lot, program } = useLoadProgramAndLot(programRef, lotNumber);
  const [backUrl, setBackUrl] = useState('');

  useEffect(() => {
    const params = qs.parse(location.search, { arrayFormat: 'bracket' });
    setBackUrl(params.backUrl as string);
  }, [location.pathname]);

  function handleClose() {
    if (backUrl) {
      window.location.replace(backUrl);
    } else {
      history.goBack();
    }
  }

  if (!programRef || !lotNumber) {
    return <Redirect to="/recherche" />;
  }

  return (
    <div className={styles.root}>
      <div className={classnames(styles.header, { [styles.headerSuccess]: successData })}>
        <button className={styles.returnBtn} type="button" onClick={handleClose}>
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_QUIT}</span>
        </button>
        <div className={styles.name}>
          <Icon className={styles.returnBtnIcon} icon="pre-book" />
          {LABEL_PRE_BOOKING}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.lotCardWrapper}>
          <div className={styles.lotCard}>
            <LotCard lotNumber={lotNumber} lot={lot} program={program} />
          </div>
        </div>
        {lot?.bloc_libre && (
          <div className={styles.promoContainer}>
            <LotPromotion lot={lot} />
          </div>
        )}
        <div className={styles.formContainer}>
          {!successData && (
            <FormPrebooking
              handleClose={handleClose}
              lot={lot}
              program={program}
              setSuccessData={setSuccessData}
            />
          )}
          {successData && (
            <ConfirmationPrebooking
              programRef={programRef}
              successData={successData}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
      <TagCommander
        navigation_pagename={
          successData ? 'form_prereservation.confirmation' : 'form_prereservation'
        }
        navigation_template="prereservation"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}
