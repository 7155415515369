import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Dialog as MuiDialog } from '@material-ui/core';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ModalLayout.module.scss';

export default function ModalLayout({ children, isVideo = false, onClose, open }: VideoPopinProps) {
  return (
    <MuiDialog
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{
        paper: classnames(styles.paper, { [styles.paperVideo]: isVideo }),
        root: styles.root,
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
    >
      <button className={styles.close} type="button" onClick={onClose}>
        <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
      </button>
      {children}
    </MuiDialog>
  );
}

export type VideoPopinProps = {
  children: ReactNode;
  isVideo?: boolean;
  onClose: () => void;
  open: boolean;
};
