import React from 'react';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './AccountDetailsPhonesField.module.scss';

export default function AccountDetailPhonesField({ phones = [] }: AccountDetailPhonesFieldProps) {
  return (
    <div className={styles.root}>
      <SvgIcon iconId="icon-phone" className={styles.iconStart} />
      <div className={styles.cards}>
        {phones.slice(0, 2).map(phone => (
          <div className={styles.card}>
            <div className={styles.label}>{phone?.label}</div>
            <div className={styles.value}>{phone?.value}</div>
          </div>
        ))}
      </div>
      <Icon className={styles.icon} icon="lock" />
    </div>
  );
}

type AccountDetailPhonesFieldProps = {
  phones: {
    label: string;
    value: string;
  }[];
};
