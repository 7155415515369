import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { TRANSITION_FAST } from 'settings/ui';

import { MediaType } from 'types/medias';

import MediasSelector from 'commonUi/MediasSelector/MediasSelector';

import styles from './AccordionMediaVisual.module.scss';

interface AccordionMediaVisualProps {
  className?: string;
  medias: MediaType[] | undefined;
}

export default function AccordionMediaVisual({ className, medias }: AccordionMediaVisualProps) {
  const [showedMedias, setShowedMedias] = useState(medias);

  useEffect(() => {
    // Handle the transition from no medias to new medias
    setShowedMedias(prevShowedMedias => {
      if (prevShowedMedias === null && medias) {
        return medias;
      }
      return prevShowedMedias;
    });
  }, [medias]);

  return (
    <div className={className}>
      <CSSTransition
        mountOnEnter
        in={medias === showedMedias && showedMedias !== null}
        onExited={() => {
          // After the previous medias have faded out, start showing the new medias
          setShowedMedias(medias);
        }}
        timeout={TRANSITION_FAST * 1000}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone,
        }}
      >
        <MediasSelector
          classes={{
            root: styles.medias,
            activeMedia: styles.activeMedia,
          }}
          medias={showedMedias}
        />
      </CSSTransition>
    </div>
  );
}
