export const DOC_TYPE_COMMERCE = 'Documents commerciaux';
export const DOC_TYPE_COMPLEMENTAIRE = 'Documents complémentaires';
export const DOC_TYPE_RESERVATION = 'Documents de réservation';

export const DOC_NATURE_PRODUCT = 'Fiche produit';
export const DOC_NATURE_PLAQUETTE = 'Web - Plaquette VIP';
export const DOC_NATURE_PLAN_MASSE = 'Web - Plan masse';

export const ERROR_MSG_CONTACT_ID_NOT_RETRIEVED_FROM_TOKEN =
  'contact_id not retrieved from token key';
