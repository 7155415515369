import React from 'react';
import classNames from 'classnames';

import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import { DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { FormSendDocuments } from './FormSendDocuments';

import styles from '../ModalAction.module.scss';

interface ModalSendDocumentsProps {
  closeCallBack: () => void;
  documents?: DocumentTypeV2[];
  open: boolean;
  title: string;
}

export function ModalSendDocuments({
  closeCallBack,
  documents = [],
  open,
  title,
}: ModalSendDocumentsProps) {
  function handleClose() {
    if (typeof closeCallBack === 'function') {
      closeCallBack();
    }
  }

  if (!open) {
    return null;
  }

  return (
    <div className={classNames('show', [styles.show])}>
      <div className={classNames('show', [styles.show])}>
        <MuiDialog
          open={open}
          onClose={handleClose}
          classes={{
            paper: styles.modalSkin,
            root: styles.modalRootForm,
            scrollPaper: styles.scrollPaper,
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              <Icon className={styles.icon} icon="send" />
              <span>{title}</span>
            </div>
            <div className={styles.rightContent}>
              <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
                <Icon className={styles.svgIcon} icon="cross" />
              </IconButton>
            </div>
          </div>

          <div className={styles.modalContent}>
            <FormSendDocuments
              documents={documents.map(d => ({ name: d.titre, url: d.url }))}
              handleClose={handleClose}
            />
          </div>
        </MuiDialog>
      </div>
    </div>
  );
}
