import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

import styles from './PdfAvailabilityStyles';

export default function PdfAvailability({
  availability = true,
  label,
  value,
}: PdfAvailabilityProps) {
  return (
    <View style={styles.root}>
      <View style={styles.icon}>
        <PdfSvgPicto picto={availability ? 'checked' : 'failed'} />
      </View>
      <Text style={styles.label}>{label}</Text>
      {value && <Text style={styles.value}>{value}</Text>}
    </View>
  );
}

type PdfAvailabilityProps = {
  availability?: boolean;
  label: string;
  value?: string;
};
