import React from 'react';
import type { ComponentProps } from 'react';

import type { ComparisonItem } from '../../types/ComparisonType';

import { ComparisonCell } from '../ComparisonCell/ComparisonCell';

interface ComparisonRowProps {
  comparisonDatas: ComparisonItem[];
  dataFrom?: string;
  dataProperty?: string;
  subtitle?: string;
  title: string;
  type?: string;
  valueFormatter?: ComponentProps<typeof ComparisonCell>['valueFormatter'];
  valueSuffix?: string;
}

export function ComparisonRow({
  comparisonDatas,
  dataFrom,
  dataProperty,
  subtitle,
  title,
  type,
  valueFormatter,
  valueSuffix = '',
}: ComparisonRowProps) {
  return (
    <tr>
      <th>
        <div>{title}</div>
        {subtitle && <div>{subtitle}</div>}
      </th>
      {comparisonDatas.map(item => (
        <ComparisonCell
          key={`${item.lot.reference}-${item.program.referenceProgramme}`}
          dataFrom={dataFrom}
          dataProperty={dataProperty}
          lot={item.lot}
          program={item.program}
          type={type}
          valueFormatter={valueFormatter}
          valueSuffix={valueSuffix}
        />
      ))}
    </tr>
  );
}
