import { useState } from 'react';

export function useModal() {
  const [open, setOpen] = useState(false);

  const openModal = (e?: any) => {
    e?.preventDefault();
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return { open, openModal, closeModal };
}

export function useModalMultiples() {
  const [open, setOpen] = useState<string>();

  const openModal = (idModal: string) => {
    setOpen(idModal);
  };
  const closeModal = () => {
    setOpen(undefined);
  };

  return { open, openModal, closeModal };
}
