import React from 'react';
import classnames from 'classnames';

import styles from './LotDetailLayout.module.scss';

interface LotDetailLayoutDesktopProps {
  className?: string;
  isOdd?: boolean;
  renderButton?: () => React.ReactNode;
  renderDetails?: () => React.ReactNode;
  renderImage?: () => React.ReactNode;
  renderPromotion?: (className?: string) => React.ReactNode;
}

export function LotDetailLayoutDesktop({
  className,
  isOdd,
  renderDetails,
  renderButton,
  renderImage,
  renderPromotion,
}: LotDetailLayoutDesktopProps) {
  return (
    <div
      id="details-programme-visible"
      className={classnames(className, styles.root, {
        [styles.odd]: isOdd,
        [styles.noButtons]: !renderButton,
      })}
    >
      {renderPromotion && renderPromotion(styles.promo)}
      <div className={styles.content}>
        {renderImage && <div className={styles.detailImage}>{renderImage()}</div>}
        {renderDetails && <div className={styles.detailInfos}>{renderDetails()}</div>}
        {renderButton && <div className={styles.detailButtons}>{renderButton()}</div>}
      </div>
    </div>
  );
}
