import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { floorFormatter, formatPrice, numberFormatter } from 'services';
import { showQuarter } from 'services/date';
import {
  LABEL_DELIVERY_DATE,
  LABEL_FISCALITY,
  LABEL_LOT,
  LABEL_NATURE,
  LABEL_NORMAL_VAT,
  LABEL_PARKING,
  LABEL_PLAN,
  LABEL_POTENTIAL,
  LABEL_PRICE_COMMERCIAL_TTC,
  LABEL_PRICE_IMMO,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_MOB_HT,
  LABEL_PROFITABILITY,
  LABEL_PROGRAM_CITY,
  LABEL_REDUCED_VAT,
  LABEL_RENT,
  LABEL_RENT_LMNP,
  LABEL_RENT_MARKET,
  LABEL_RENT_PINEL,
  LABEL_STAIR,
  LABEL_STATUS,
  LABEL_SURF,
  LABEL_SURF_ANNEX,
  LABEL_TYPO,
} from 'settings';

import { ComparisonItem } from '../../types/ComparisonType';

import { ComparisonRow } from '../ComparisonRow/ComparisonRow';
import {
  CELL_TYPE_FISCALITY,
  CELL_TYPE_LOT_LINK,
  CELL_TYPE_PLAN_LINK,
  CELL_TYPE_PROGRAM_LINK,
  CELL_TYPE_STATUS,
} from '../ComparisonCell/ComparisonCell';

import styles from './ComparisonTable.module.scss';

interface ComparisonTableProps {
  comparisonDatas: ComparisonItem[] | undefined;
}

export function ComparisonTable({ comparisonDatas }: ComparisonTableProps) {
  if (!comparisonDatas) {
    return (
      <div className={styles.loading}>
        <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={24} />
      </div>
    );
  }

  const uniqueFiscalities = new Set(comparisonDatas.map(({ lot }) => lot.fiscalites[0]));
  const isMultifiscalities = uniqueFiscalities.size > 1;
  const firstFiscality = uniqueFiscalities.values().next().value;
  const isPinel = uniqueFiscalities.size === 1 && firstFiscality === 'Pinel';
  const isLMNP = uniqueFiscalities.size === 1 && firstFiscality === 'LMNP';
  const isDemembrement = uniqueFiscalities.size === 1 && firstFiscality === 'Demembrement';
  const isResidenceHorsPinel =
    uniqueFiscalities.size === 1 && firstFiscality === 'ResidenceHorsPinel';

  return (
    <table className={styles.table}>
      <tbody>
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          title={LABEL_FISCALITY}
          type={CELL_TYPE_FISCALITY}
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          title={LABEL_PROGRAM_CITY}
          type={CELL_TYPE_PROGRAM_LINK}
        />
        {isLMNP && (
          <ComparisonRow
            comparisonDatas={comparisonDatas}
            dataFrom="program"
            dataProperty="type"
            title={LABEL_NATURE}
          />
        )}
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          title={LABEL_LOT}
          type={CELL_TYPE_LOT_LINK}
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          dataFrom="lot"
          dataProperty="typologie"
          title={LABEL_TYPO}
        />
        <ComparisonRow
          dataProperty="etage"
          comparisonDatas={comparisonDatas}
          title={LABEL_STAIR}
          valueFormatter={floorFormatter}
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          dataFrom="lot"
          dataProperty="surfaces.surfaceHabitable"
          title={LABEL_SURF}
          valueFormatter={numberFormatter}
          valueSuffix="m²"
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          dataFrom="lot"
          dataProperty="surfaces.surfaceAnnexes"
          title={LABEL_SURF_ANNEX}
          valueFormatter={numberFormatter}
          valueSuffix="m²"
        />
        <ComparisonRow
          dataFrom="lot"
          dataProperty="parking"
          comparisonDatas={comparisonDatas}
          title={LABEL_PARKING}
        />
        {(isMultifiscalities || isPinel || isResidenceHorsPinel) && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="prix.TVAalternative.prixTTC"
              subtitle={`(${LABEL_REDUCED_VAT})`}
              title={LABEL_PRICE_IMMO}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="prix.TVANormale.prixTTC"
              subtitle={`(${LABEL_NORMAL_VAT})`}
              title={LABEL_PRICE_IMMO}
              valueFormatter={formatPrice}
            />
          </>
        )}
        {isMultifiscalities && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.loyerMarcheHT"
              title={LABEL_RENT}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.rendementLocatif"
              title={LABEL_PROFITABILITY}
              valueFormatter={numberFormatter}
              valueSuffix="%"
            />
          </>
        )}
        {isPinel && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.plafondLoyerPinel"
              title={LABEL_RENT_PINEL}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.rentabilitePinel"
              title={LABEL_PROFITABILITY}
              valueFormatter={numberFormatter}
              valueSuffix="%"
            />
          </>
        )}
        {isResidenceHorsPinel && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.loyerMarcheAnnuelHT"
              subtitle={`(${LABEL_POTENTIAL})`}
              title={LABEL_RENT_MARKET}
              valueFormatter={formatPrice}
            />
          </>
        )}
        {isLMNP && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="prix.prixHTHorsMobilier"
              title={LABEL_PRICE_IMMO_HT}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="prix.prixMobilierHT"
              title={LABEL_PRICE_MOB_HT}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.loyerMarcheAnnuelHT"
              title={LABEL_RENT_LMNP}
              valueFormatter={formatPrice}
            />
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="investissementLocatif.rentabiliteLMNP"
              title={LABEL_PROFITABILITY}
              valueFormatter={numberFormatter}
              valueSuffix="%"
            />
          </>
        )}
        {isDemembrement && (
          <>
            <ComparisonRow
              comparisonDatas={comparisonDatas}
              dataFrom="lot"
              dataProperty="prix.TVANormale.prixTTC"
              title={LABEL_PRICE_COMMERCIAL_TTC}
              valueFormatter={formatPrice}
            />
          </>
        )}
        <ComparisonRow
          dataFrom="program"
          dataProperty="dates.livraison"
          comparisonDatas={comparisonDatas}
          title={LABEL_DELIVERY_DATE}
          valueFormatter={showQuarter}
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          title={LABEL_PLAN}
          type={CELL_TYPE_PLAN_LINK}
        />
        <ComparisonRow
          comparisonDatas={comparisonDatas}
          title={LABEL_STATUS}
          type={CELL_TYPE_STATUS}
        />
      </tbody>
    </table>
  );
}
