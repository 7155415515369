import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import { omit, uniqBy } from 'lodash';
import { Button as ButtonMui, CircularProgress, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import classnames from 'classnames';

import {
  LABEL_ADD_TO_A_FOLDER,
  LABEL_ADD_TO_FOLDER_CREATE_FOLDER,
  LABEL_COMPARISON,
  LABEL_FOLDERS_CREATED_AT,
  LABEL_FOLDERS_FIND_FOLDER,
  LABEL_FOLDERS_MODIFIED_AT,
  LABEL_FOLDERS_MY_FOLDERS,
  LABEL_FOLDERS_NO_FOLDER,
  LABEL_LOT,
  LABEL_LOTS,
  LABEL_PROGRAM,
  LABEL_PROGRAMS,
} from 'settings/labels';
import { PANEL_ID_FOLDERS } from 'settings/panels';
import { TMS_TIMEOUT } from 'settings/tms';

import { getFolderSaveMessage } from 'services/folders';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';
import SearchForm from 'commonUi/SearchForm/SearchForm';
import Button from 'commonUi/Button/Button';
import Drawer, { DRAWER_DIRECTION_RIGHT } from 'commonUi/Drawer/Drawer';

import { FolderType } from 'api/vi3pAPI/apiTypes/FolderType';
import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { ModalEditFolder } from 'modules/MyFolder/Components/Modals/ModalEditFolder';
import { useModal } from 'hooks/useModal';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import userContext from 'modules/App/Contexts/userContext';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { ListAddItems } from './ListAddItems/ListAddItems';

import styles from './FolderLinks.module.scss';

export interface FolderLinksProps {
  closePanel: () => void;
  idPanelOpen: string | undefined;
}

export default function FolderLinks({ closePanel, idPanelOpen }: FolderLinksProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [editFolder, setEditFolder] = useState<FolderType>();
  const [isFolderNameAlreadyUsed, setIsFolderNameAlreadyUsed] = useState<boolean>(false);
  const [, setFolderOldName] = useState<string>('');
  const [toggleList, setToggleList] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnFolderClickedId, setBtnFolderClickedId] = useState<number>();
  const { userCrm } = useContext(userContext);
  const { isResponsive } = useContext(ResponsiveContext);
  const { setEventPageVars } = useContext(TmsContext);
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search);

  const {
    emptyDatas,
    folderLots,
    folderComparisons,
    folderPrograms,
    folderSaveType,
    folderTms,
    setFolderSaveType,
  } = useContext(foldersContext);

  const { open, openModal, closeModal } = useModal();

  const { data: folders, mutate } = useSWRVi3pImmutable<FolderType[]>({
    url: `dossiers/${userCrm?.extension_VI3P_ContactId}`,
  });

  const toggleLabel = {
    comparison: LABEL_COMPARISON,
    lot:
      folderLots.length > 1
        ? `${LABEL_LOTS} (${folderLots.length})`
        : `${LABEL_LOT} (${folderLots.length})`,
    program:
      folderPrograms.length > 1
        ? `${LABEL_PROGRAMS} (${folderPrograms.length})`
        : `${LABEL_PROGRAM} (${folderPrograms.length})`,
  };

  function onSearchChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setSearch(evt.target.value);
  }

  function handleClose() {
    setEventPageVars({});
    closePanel();
    if (params?.panelFolders === 'open') {
      const search = qs.parse(location.search, { arrayFormat: 'bracket' });
      const queryToString = qs.stringify(omit(search, ['panelFolders']), {
        encode: false,
        arrayFormat: 'bracket',
      });
      history.replace(queryToString ? `${location.pathname}?${queryToString}` : location.pathname);
    }
  }

  async function updateFolder(newName: FolderType['title']) {
    // create
    if (!userCrm) return;
    try {
      const newFolder: FolderType = {
        id: -1,
        title: newName,
        programmes: [],
        lots: [],
        comparaisons: [],
        creation_date: dayjs().format('YYYY-MM-DD'),
        modification_date: dayjs().format('YYYY-MM-DD'),
      };
      mutate(folders ? [...folders, newFolder] : [newFolder], false);
      closeModal();
      setEditFolder(undefined);
      await axiosVI3PInstance.post('dossiers/create', {
        [userCrm.extension_VI3P_ContactId]: {
          [newName]: {
            programmes: folderPrograms,
            lots: folderLots,
            comparaisons: folderComparisons.length > 0 ? [folderComparisons] : [],
          },
        },
      });
      mutate(folders ? [...folders, newFolder] : [newFolder]);
      setIsFolderNameAlreadyUsed(false);
      if (folderPrograms.length > 0 || folderLots.length > 0 || folderComparisons.length > 0) {
        setEventPageVars({});
        closePanel();
        enqueueSnackbar(getFolderSaveMessage(folderSaveType, folderLots, folderPrograms, newName));
        emptyDatas();
        setFolderSaveType(null);
      }
    } catch (newError) {
      if (newError?.response?.status) {
        setIsFolderNameAlreadyUsed(true);
        setFolderOldName(newName);
      }
    }
  }

  async function handleSaveInFolder(folderTitle: string, folderId: number) {
    const currentFolder = folders?.filter(p => p.title === folderTitle)[0];
    if (!userCrm || !currentFolder) return;
    let newDatas = {};
    if (folderSaveType === 'program' && folderPrograms.length > 0) {
      newDatas = {
        ...currentFolder,
        programmes: uniqBy([...currentFolder.programmes, ...folderPrograms], 'programRef'),
      };
    }
    if (folderSaveType === 'lot' && folderLots.length > 0) {
      newDatas = {
        ...currentFolder,
        lots: uniqBy([...currentFolder.lots, ...folderLots], v => `${v.programRef}-${v.lotNumber}`),
      };
    }
    if (folderSaveType === 'comparison' && folderComparisons.length > 0) {
      newDatas = {
        ...currentFolder,
        comparaisons: uniqBy([...currentFolder.comparaisons, folderComparisons], v =>
          JSON.stringify(v)
        ),
      };
    }
    if (folderPrograms.length > 0 || folderLots.length > 0 || folderComparisons.length > 0) {
      setBtnFolderClickedId(folderId);
      setLoading(true);
      await axiosVI3PInstance.post('dossiers/update', {
        [userCrm?.extension_VI3P_ContactId]: {
          [folderTitle]: newDatas,
        },
      });
      setTimeout(() => {
        setLoading(false);
        setEventPageVars({});
        closePanel();
        enqueueSnackbar(
          getFolderSaveMessage(folderSaveType, folderLots, folderPrograms, folderTitle)
        );
      }, TMS_TIMEOUT);
      emptyDatas();
      setFolderSaveType(null);
    }
  }

  const nbFolders = folders ? folders.length : 0;

  let tms = {};
  if (folderTms && loading) {
    tms = folderTms.confirmation;
  } else if (folderTms && !loading) {
    tms = folderTms.default;
  }

  const content = (
    <>
      {(folderPrograms.length > 0 || folderLots.length > 0 || folderComparisons.length > 0) && (
        <div className={classnames(styles.cards, { [styles.open]: toggleList })}>
          <ListAddItems />
        </div>
      )}
      <div className={styles.searchContainer}>
        <div className={styles.search}>
          <SearchForm
            inputClassName={styles.searchField}
            id="search-folder"
            fullWidth
            onChange={onSearchChange}
            placeholder={LABEL_FOLDERS_FIND_FOLDER}
          />
        </div>
      </div>
      <div className={styles.folderList}>
        {nbFolders === 0 && (
          <div className={styles.folderEmpty}>
            <div className={styles.folderEmptyText}>{LABEL_FOLDERS_NO_FOLDER}</div>
          </div>
        )}
        {folders && nbFolders > 0 && (
          <>
            {folders
              .filter(i => i.title != null && i.title.toLowerCase().includes(search.toLowerCase()))
              .sort((a, b) => {
                return (
                  dayjs(b.modification_date || b.creation_date).valueOf() -
                  dayjs(a.modification_date || a.creation_date).valueOf()
                );
              })
              .map(link => {
                const creationDate = dayjs(link.creation_date);
                const modificationDate = dayjs(link.modification_date);
                const nbElements =
                  link.programmes.length + link.lots.length + link.comparaisons.length;

                return (
                  <div key={link.title} className={styles.folderItem}>
                    <ButtonMui
                      classes={{ root: styles.folderButton, startIcon: styles.startIcon }}
                      fullWidth
                      startIcon={
                        loading && btnFolderClickedId === link.id ? (
                          <div className={styles.startIconContainer}>
                            <CircularProgress
                              classes={{ root: styles.loader }}
                              variant="indeterminate"
                              size={24}
                            />
                          </div>
                        ) : (
                          <div className={styles.startIconContainer}>
                            <SvgIcon iconId="icon-folder" />
                            <span className={styles.folderNbElements}>{nbElements}</span>
                          </div>
                        )
                      }
                      href={
                        folderPrograms.length === 0 &&
                        folderLots.length === 0 &&
                        folderComparisons.length === 0
                          ? `/mes-dossiers/${link.id}`
                          : undefined
                      }
                      onClick={() =>
                        (folderPrograms.length > 0 ||
                          folderLots.length > 0 ||
                          folderComparisons.length > 0) &&
                        handleSaveInFolder(link.title, link.id)
                      }
                      disabled={loading && btnFolderClickedId !== link.id}
                    >
                      <div>
                        <div className={styles.folderButtonTitle}>{link.title}</div>
                        {link.modification_date ? (
                          <span className={styles.folderButtonDate}>
                            {LABEL_FOLDERS_MODIFIED_AT} {modificationDate.format('DD/MM/YYYY')}
                          </span>
                        ) : (
                          <span className={styles.folderButtonDate}>
                            {LABEL_FOLDERS_CREATED_AT} {creationDate.format('DD/MM/YYYY')}
                          </span>
                        )}
                      </div>
                    </ButtonMui>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );

  return (
    <Drawer
      classes={{
        drawerContainerClassName: styles.drawerContainer,
        drawerRootClassName: styles.drawerRoot,
        paperRootClassName: styles.paperRoot,
      }}
      onClose={handleClose}
      open={idPanelOpen === PANEL_ID_FOLDERS}
      openDirection={DRAWER_DIRECTION_RIGHT}
    >
      {idPanelOpen === PANEL_ID_FOLDERS && (
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.title}>
              <SvgIcon className={styles.svgIcon} iconId="icon-my-folders" />
              <span>
                {folderPrograms.length > 0 || folderLots.length > 0 || folderComparisons.length > 0
                  ? LABEL_ADD_TO_A_FOLDER
                  : LABEL_FOLDERS_MY_FOLDERS}
              </span>
            </div>
            <IconButton classes={{ root: styles.close }} onClick={handleClose}>
              <Icon icon="cross" />
            </IconButton>
          </div>

          {(folderPrograms.length > 0 || folderLots.length > 0 || folderComparisons.length > 0) && (
            <div className={styles.toggle}>
              {folderSaveType ? toggleLabel[folderSaveType] : undefined}
              <button
                className={styles.toggleButton}
                type="button"
                onClick={() => setToggleList(prevState => !prevState)}
              >
                <Icon
                  className={classnames(styles.icon, { [styles.iconOpen]: toggleList })}
                  icon="chevron-down"
                />
              </button>
            </div>
          )}

          <div className={styles.content}>
            {isResponsive ? (
              content
            ) : (
              <CustomScrollbar className={styles.scroll}>
                <div className={styles.scrollContent}>{content}</div>
              </CustomScrollbar>
            )}
          </div>

          <div className={styles.createBtnContainer}>
            <div className={styles.createBtn}>
              <Button
                className={styles.createButton}
                color="primary"
                onClick={() => openModal()}
                variant="contained"
                disabled={loading}
              >
                <span className={styles.label}>{LABEL_ADD_TO_FOLDER_CREATE_FOLDER}</span>
              </Button>
            </div>
          </div>

          <ModalEditFolder
            closeCallBack={() => {
              closeModal();
              setIsFolderNameAlreadyUsed(false);
            }}
            open={open}
            handleInputOnChange={() => setIsFolderNameAlreadyUsed(false)}
            updateFolder={updateFolder}
            folderName={editFolder ? editFolder.title : ''}
            isFolderNameAlreadyUsed={isFolderNameAlreadyUsed}
            folderNames={folders ? folders.map(folder => folder.title) : []}
            setIsFolderNameAlreadyUsed={setIsFolderNameAlreadyUsed}
          />

          {tms && (
            <TagCommanderEvent isActive={!!idPanelOpen} {...tms} useEffectDeps={Object.keys(tms)} />
          )}
        </div>
      )}
    </Drawer>
  );
}
