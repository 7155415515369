import React from 'react';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { LotCard } from 'commonUi/LotCard/LotCard';

interface LotCardCpnProps {
  programRef: string;
  lotNumber: string;
}

export default function LotCardLoadProgramAndLot({ programRef, lotNumber }: LotCardCpnProps) {
  const { lot, program } = useLoadProgramAndLot(programRef, lotNumber);

  return <LotCard lotNumber={lotNumber} lot={lot} program={program} />;
}
