import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  info: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '8px',
    margin: '3px 0',
    padding: '0 0 0 5px',
    position: 'relative',
  },
  infoDot: {
    backgroundColor: COLORS.GREYS.GREY,
    borderRadius: '50%',
    height: '2px',
    left: 0,
    position: 'absolute',
    top: '5px',
    width: '2px',
  },
  infoLine: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  infoLabel: {
    margin: '0 2px 0 0',
    fontSize: '8px',
  },
  infoValue: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '8px',
    fontWeight: 'medium',
  },
});
