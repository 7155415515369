import scssSettings from '../assets/styles/_export.module.scss';

export const KEY_ENTER = 'Enter';
export const KEY_SPACE = ' ';

export const RESPONSIVE_MEDIA_QUERY = `(max-width: ${scssSettings.BREAKPOINT_SM_MAX})`;
export const TABLET_MEDIA_QUERY = `(max-width: ${scssSettings.BREAKPOINT_MD_MAX})`;
export const MOBILE_MEDIA_QUERY = `(max-width: ${scssSettings.BREAKPOINT_XS_MAX})`;

export const NON_BREAKING_SPACE = '\xa0';

export const COLORS = {
  PRIMARY: {
    BLUE: scssSettings.COLOR_PRIMARY_BLUE,
    BLUE_LIGHT: scssSettings.COLOR_PRIMARY_BLUE_LIGHT,
    BLUE_DARK: scssSettings.COLOR_PRIMARY_DARK_BLUE,
  },
  SECONDARY: {
    BLUE: scssSettings.COLOR_SECONDARY_BLUE,
    CYAN: scssSettings.COLOR_SECONDARY_CYAN,
    GOLD: scssSettings.COLOR_GOLD,
    GREEN: scssSettings.COLOR_GREEN,
    ORANGE: scssSettings.COLOR_ORANGE,
    PINK: scssSettings.COLOR_PINK,
    RED: scssSettings.COLOR_RED,
    RED2: scssSettings.COLOR_RED2,
    YELLOW: scssSettings.COLOR_YELLOW,
  },
  GREYS: {
    BLACK: scssSettings.COLOR_BLACK,
    BLACK2: scssSettings.COLOR_BLACK2,
    BLACK3: scssSettings.COLOR_BLACK3,
    BLUE: scssSettings.COLOR_BLUE,
    GREY: scssSettings.COLOR_GREY,
    GREY2: scssSettings.COLOR_GREY2,
    GREY_BLUE: scssSettings.COLOR_GREY_BLUE,
    GREY_LIGHT: scssSettings.COLOR_GREY_LIGHT,
    GREY_LIGHT2: scssSettings.COLOR_GREY_LIGHT2,
    GREY_LIGHT3: scssSettings.COLOR_GREY_LIGHT3,
    GREY_HEADER: scssSettings.COLOR_HEADER_GREY,
    PURE_BLACK: scssSettings.COLOR_PURE_BLACK,
    PURE_GREY: scssSettings.COLOR_PURE_GREY,
    PURE_WHITE: scssSettings.COLOR_PURE_WHITE,
    WHITE: scssSettings.COLOR_WHITE,
  },
};

export const BREAKPOINT_XS = Number(scssSettings.BREAKPOINT_XXS);
export const BREAKPOINT_SM = Number(scssSettings.BREAKPOINT_XS);
export const BREAKPOINT_MD = Number(scssSettings.BREAKPOINT_SM);
export const BREAKPOINT_LG = Number(scssSettings.BREAKPOINT_MD);
export const BREAKPOINT_XL = Number(scssSettings.BREAKPOINT_LG);

export const TRANSITION_FAST = Number(scssSettings.TRANSITION_FAST);

export const HEADER_DOM_ID = 'site-header';
export const PROGRAM_PROMO_DOM_ID = 'program-promo-banner';
export const PROGRAM_ANCHORS_DOM_ID = 'program-menu-anchors';
