import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { error } from './log';
import { DocumentTypeV2 } from '../api/viOffresAPI/apiTypes/Documents';

function urlToPromise(url: string) {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export async function downloadDocs(
  documents: DocumentTypeV2[],
  title: string,
  setDownloadProgression?: (progressionPercent: number) => void
) {
  const zip = new JSZip();

  documents.forEach(({ titre, url }) => {
    zip.file(decodeURI(titre).replace(/\s/g, '-'), urlToPromise(url));
  });

  if (!documents.length) {
    return null;
  }

  return zip
    .generateAsync({ type: 'blob' }, function updateCallback(metadata) {
      if (typeof setDownloadProgression === 'function') {
        setDownloadProgression(Math.round(metadata.percent));
      }
    })
    .then(blob => {
      saveAs(blob, `${title.replace(/\s/g, '-').toLowerCase()}.zip`);
      if (typeof setDownloadProgression === 'function') {
        setDownloadProgression(0);
      }
    })
    .catch(e => {
      error('zip', e);
      if (typeof setDownloadProgression === 'function') {
        setDownloadProgression(0);
      }
    });
}
