import React from 'react';
import type { PropsWithChildren } from 'react';
import { Text, View } from '@react-pdf/renderer';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import LotPdfImage from '../LotPdfImage/LotPdfImage';

import styles from './LotPdfBannerStyles';

export type LotPdfBannerProps = {
  lot: LotTypeV2;
  program: ProgramTypeV2;
};

export default function LotPdfBanner({
  children,
  lot,
  program,
}: PropsWithChildren<LotPdfBannerProps>) {
  return (
    <View style={styles.banner}>
      <View style={styles.bannerCol}>
        <LotPdfImage lot={lot} program={program} />
      </View>
      <View style={[styles.bannerCol, styles.bannerContent]}>
        <Text style={styles.name}>{program.nomCommercial}</Text>
        <Text style={styles.address}>
          {program.localisation.adresse}, {program.localisation.ville} (
          {program.localisation.codePostal})
        </Text>
        <View style={styles.totem}>{children}</View>
      </View>
    </View>
  );
}
