import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import {
  ClickAwayListener as MuiClickAwayListener,
  Fade as MuiFade,
  Popper as MuiPopper,
} from '@material-ui/core';

import {
  LABEL_BANK,
  LABEL_BIC,
  LABEL_IBAN,
  LABEL_ORDER,
  LABEL_SECURITY_DEPOSIT,
} from 'settings/labels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import RowDetailsList from 'commonUi/RowDetailsList/RowDetailsList';

import styles from './DepositInfos.module.scss';

export interface DepositInfosProps {
  className?: string;
  bicDepotGarantie: string | undefined;
  ibanDepotGarantie: string | undefined;
  modePaiement: string | undefined;
  montantDepot: string;
  nomBanqueDepotGarantie: string | undefined;
  ordreDepotGarantie: string | undefined;
}

export default function DepositInfos({
  className = undefined,
  bicDepotGarantie,
  ibanDepotGarantie,
  modePaiement,
  montantDepot,
  nomBanqueDepotGarantie,
  ordreDepotGarantie,
}: DepositInfosProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const togglerRef = useRef<HTMLButtonElement>(null);

  if (
    !bicDepotGarantie &&
    !ibanDepotGarantie &&
    !modePaiement &&
    !nomBanqueDepotGarantie &&
    !ordreDepotGarantie
  ) {
    return null;
  }

  function handleToggle() {
    setIsOpen(prevState => !prevState);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <div className={classnames(styles.root, className)}>
      <button type="button" className={styles.toggle} onClick={handleToggle} ref={togglerRef}>
        i
      </button>
      <MuiPopper
        disablePortal
        transition
        anchorEl={togglerRef.current}
        open={isOpen}
        placement="top"
        style={{ zIndex: 125 }}
      >
        {({ TransitionProps }) => (
          <MuiClickAwayListener onClickAway={handleClose}>
            <MuiFade {...TransitionProps} timeout={150}>
              <div className={styles.content}>
                <button type="button" className={styles.close} onClick={handleClose}>
                  <Icon icon="cross" />
                </button>
                <RowDetailsList
                  items={{
                    [montantDepot]: {
                      label: LABEL_SECURITY_DEPOSIT,
                      value: montantDepot,
                    },
                    ...(modePaiement
                      ? {
                          [LABEL_SECURITY_DEPOSIT]: {
                            label: LABEL_SECURITY_DEPOSIT,
                            value: modePaiement,
                          },
                        }
                      : {}),
                    ...(ordreDepotGarantie
                      ? {
                          [LABEL_ORDER]: {
                            label: LABEL_ORDER,
                            value: ordreDepotGarantie,
                          },
                        }
                      : {}),
                    ...(ibanDepotGarantie
                      ? {
                          [LABEL_IBAN]: {
                            label: LABEL_IBAN,
                            value: ibanDepotGarantie,
                          },
                        }
                      : {}),
                    ...(bicDepotGarantie
                      ? {
                          [LABEL_BIC]: {
                            label: LABEL_BIC,
                            value: bicDepotGarantie,
                          },
                        }
                      : {}),
                    ...(nomBanqueDepotGarantie
                      ? {
                          [LABEL_BANK]: {
                            label: LABEL_BANK,
                            value: nomBanqueDepotGarantie,
                          },
                        }
                      : {}),
                  }}
                />
              </div>
            </MuiFade>
          </MuiClickAwayListener>
        )}
      </MuiPopper>
    </div>
  );
}
