import React, { useContext, useRef, useState } from 'react';
import classnames from 'classnames';
import {
  ClickAwayListener as MuiClickAwayListener,
  Grow as MuiGrow,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@material-ui/core';

import { LABEL_SHOW_ACTIVITY_DETAIL } from 'settings/labels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';

import Tooltip from 'commonUi/Tooltip/Tooltip';

import styles from './ActivityInfo.module.scss';
import ActivityInfoPanel from './ActivityInfoPanel';

export interface ActivityInfoProps {
  activity: Activity;
  btnClassName?: string;
  className?: string;
}

export default function ActivityInfo({
  activity,
  btnClassName = undefined,
  className = undefined,
}: ActivityInfoProps) {
  const [open, setOpen] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleClick: React.MouseEventHandler = ev => {
    ev.stopPropagation();
    setOpen(prev => !prev);
  };
  const handleClose = () => setOpen(false);
  const { isResponsive } = useContext(ResponsiveContext);

  return (
    <div className={classnames(className, styles.root)}>
      <Tooltip arrow title={LABEL_SHOW_ACTIVITY_DETAIL} placement="top">
        <button
          ref={btnRef}
          type="button"
          className={classnames(btnClassName, styles.button)}
          onClick={handleClick}
        >
          <span>i</span>
        </button>
      </Tooltip>

      {isResponsive ? (
        <MobileDrawer isDrawerOpen={open}>
          <ActivityInfoPanel activity={activity} onClose={handleClose} />
        </MobileDrawer>
      ) : (
        <MuiPopper
          transition
          open={open}
          anchorEl={btnRef.current}
          placement={isResponsive ? 'bottom-end' : 'left-start'}
          style={{ zIndex: 150 }}
        >
          {({ TransitionProps, placement }) => (
            <MuiGrow {...TransitionProps}>
              <MuiPaper
                classes={{ root: classnames(styles.paper, styles.selectorWeight) }}
                onClick={ev => ev.stopPropagation()}
              >
                <MuiClickAwayListener onClickAway={handleClose}>
                  <ActivityInfoPanel
                    activity={activity}
                    onClose={handleClose}
                    placement={placement}
                  />
                </MuiClickAwayListener>
              </MuiPaper>
            </MuiGrow>
          )}
        </MuiPopper>
      )}
    </div>
  );
}
