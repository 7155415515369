import React, { useContext, useRef, useState } from 'react';
import type { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import { LABEL_CREATE_OPTION } from 'settings/labels';
import { MODAL_ID_CREATE_OPTION } from 'settings/modal';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import type { OptionConfirmationReturnType } from 'api/viCrmApi/apiTypes/Option';
import type { ModalDataLotProgram, ModalType } from 'modules/ActionsProgramsAndLots/Modal';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { LotCard } from 'commonUi/LotCard/LotCard';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import FormOption from './FormOption';
import ConfirmationOption from './ConfirmationOption';

import styles from '../ModalAction.module.scss';
import { formatActivityDate } from '../../../services/date';

interface ModalOptionProps {
  lot: ModalDataLotProgram;
  closeCallBack: () => void;
  onSuccess?: (type: ModalType, activityId?: string) => void;
  open: boolean;
  optimisticSuccess?: () => void;
  undoOptimisticSuccess?: () => void;
}

export default function ModalOption({
  lot,
  closeCallBack,
  open,
  onSuccess,
  optimisticSuccess,
  undoOptimisticSuccess,
  ...rootDivProps
}: ModalOptionProps & HTMLAttributes<HTMLDivElement>) {
  const [isSuccess, setIsSuccess] = useState(false);
  const successMessage = useRef<string>();
  const [apiError, setApiError] = useState(false);
  const { setEventPageVars } = useContext(TmsContext);

  const handleSuccess = (response: OptionConfirmationReturnType) => {
    if (response) {
      setIsSuccess(true);
      successMessage.current = formatActivityDate(response.expiration, 'DD/MM/YYYY - HH:mm');
      if (typeof onSuccess === 'function') onSuccess(MODAL_ID_CREATE_OPTION, response.optionId);
    }
  };

  function handleClose() {
    setIsSuccess(false);
    setApiError(false);
    setEventPageVars({});
    if (typeof closeCallBack === 'function') {
      closeCallBack();
    }
  }

  const { lot: lotFromApi, program } = useLoadProgramAndLot(lot?.program?.ref, lot?.number);

  const tms = {
    navigation_pagename: 'form_option',
    navigation_template: 'option',
  };
  if (isSuccess) {
    tms.navigation_pagename = 'form_option.confirmation';
  } else if (apiError) {
    tms.navigation_pagename = 'form_option.erreur';
  }

  return (
    <div className={classNames('show', [styles.show])} {...rootDivProps}>
      <MuiDialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: styles.modalSkin,
          root: styles.modalRootForm,
          scrollPaper: styles.scrollPaper,
        }}
      >
        <div className={classNames(styles.modalHeader, { [styles.modalHeaderSuccess]: isSuccess })}>
          <div className={styles.title}>
            <Icon className={styles.icon} icon="option" />
            <span>{LABEL_CREATE_OPTION}</span>
          </div>
          <div className={styles.rightContent}>
            <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
              <Icon className={styles.svgIcon} icon="cross" />
            </IconButton>
          </div>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.lotCardWrapper}>
            <LotCard
              className={styles.lotCard}
              lotNumber={lot.number}
              program={program}
              lot={lotFromApi}
            />
          </div>
          {isSuccess ? (
            <ConfirmationOption handleClose={handleClose} successMessage={successMessage.current} />
          ) : (
            <FormOption
              apiError={apiError}
              handleApiError={setApiError}
              handleSuccess={handleSuccess}
              lot={lot}
              lotTaxType={lotFromApi?.fiscalites}
              onClose={handleClose}
              optimisticSuccess={optimisticSuccess}
              undoOptimisticSuccess={undoOptimisticSuccess}
            />
          )}
        </div>
      </MuiDialog>

      <TagCommanderEvent isActive={open} {...tms} useEffectDeps={['navigation_pagename']} />
    </div>
  );
}
