import jwtDecode from 'jwt-decode';
import memoize from 'memoizee';

import { USER_ROLE_MAPPING, USER_ROLE_OTHER } from 'settings/user';

import type { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';

const SESSION_AUTH_KEY = window.vinci.REACT_APP_SESSION_AUTH_KEY;

// TODO Replace decodeToken with a hook to store active User
export const decodeToken = memoize(jwtDecode as (token: string) => UserTokenType);

export function getHashValue(key: string) {
  const matches = window.location.hash.match(new RegExp(`${key}=([^&]*)`));
  return matches ? matches[1] : undefined;
}

export function getTokenFromSession() {
  const token = localStorage.getItem(SESSION_AUTH_KEY);

  if (
    window.location.pathname !== '/deconnexion' &&
    token !== 'false' &&
    token !== undefined &&
    token !== null
  ) {
    return token;
  }
  return undefined;
}

export function getCodeErrorFromUrl() {
  const token = getHashValue('error_description');

  if (token !== undefined && token !== null) {
    const splitToken = decodeURIComponent(token).split(':');
    if (splitToken[0] !== undefined && splitToken[0] !== null) {
      return splitToken[0];
    }
  }
  return false;
}

export function getNormalizedRoleFrom(role: string) {
  if (role && USER_ROLE_MAPPING[role]) {
    return USER_ROLE_MAPPING[role];
  }

  return USER_ROLE_OTHER;
}

export function getNormalizedRole() {
  const token = getTokenFromSession();
  if (token) {
    return getNormalizedRoleFrom(decodeToken(token)?.extension_VI3P_Role);
  }
  return undefined;
}

export function getTokenFromUrl() {
  const token = getHashValue('id_token');
  if (token !== undefined && token !== null) {
    return token;
  }
  return false;
}

export function getStateFromUrl() {
  const state = getHashValue('state');
  if (!state) {
    return undefined;
  }
  try {
    return JSON.parse(atob(decodeURIComponent(state)));
  } catch (e) {
    return undefined;
  }
}

export function isInvite() {
  const token = getTokenFromUrl() || getTokenFromSession();
  if (token) {
    const decodedToken = decodeToken(token);

    if (decodedToken !== null) {
      if (
        decodedToken.extension_VI3P_ContactId === undefined ||
        decodedToken.extension_VI3P_EstInvite
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isTokenExpired(timeThreshold?: number): boolean;
export function isTokenExpired(token: string | undefined, timeThreshold?: number): boolean;
export function isTokenExpired(token: UserTokenType | undefined, timeThreshold?: number): boolean;
export function isTokenExpired(
  tokenOrTime: string | number | UserTokenType | undefined,
  timeThreshold?: number
) {
  let expiration: number | undefined;
  if (typeof tokenOrTime === 'undefined' || typeof tokenOrTime === 'number') {
    const token = getTokenFromSession();
    expiration = token ? decodeToken(token)?.exp : undefined;
  } else if (typeof tokenOrTime === 'string') {
    expiration = decodeToken(tokenOrTime)?.exp;
  } else {
    expiration = tokenOrTime.exp;
  }

  return !expiration || expiration - Math.floor(Date.now() / 1000) <= (timeThreshold ?? 0);
}

export function isAuthenticated() {
  const token = getTokenFromSession();
  const needToDeleteToken = isTokenExpired();
  if (needToDeleteToken) removeTokenFromStorage();
  return !!token && !needToDeleteToken;
}

export function removeTokenFromStorage() {
  localStorage.removeItem(SESSION_AUTH_KEY);
}

export function setTokenInSession(token: string) {
  localStorage.setItem(SESSION_AUTH_KEY, token);
}
