import axios from 'axios';

import { error, getTokenFromSession } from '../../services';

const SITE_NAME = 'vi3p';

export default class ApiInterface {
  baseURL = '';

  apiKey = '';

  getCredentials = () => {
    const token = getTokenFromSession();

    if (token) {
      return {
        Authorization: `Bearer ${localStorage.getItem(window.vinci.REACT_APP_SESSION_AUTH_KEY)}`,
        withCredentials: true,
        'x-api-key': '8PG8YfKNbN0QZEpcC91c7+ldKgeKHNR4JxFiweLNzOMlNkExqUIJ8zY11tGcx8hP',
      };
    }

    return {};
  };

  get instance() {
    if (this.baseURL === '') {
      throw new Error('baseURL is not set');
    }

    return axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.apiKey,
        ...this.getCredentials(),
      },
    });
  }

  deleteData = (url, params) =>
    this.instance
      .delete(url, {
        params: {
          site: SITE_NAME,
          ...params,
        },
      })
      .catch(e => {
        error('DELETE error', url, e);
        return Promise.reject(e);
      });

  getData = (url, params) =>
    this.instance
      .get(url, {
        params: {
          site: SITE_NAME,
          ...params,
        },
      })
      .catch(e => {
        error('GET error', url, e);
        return Promise.reject(e);
      });

  fetchData = (url, params) => this.getData(url, params).then(({ data }) => data);

  // @deprecated should be replaced with fetchData
  fetchDataOrUndefined = (url, params) =>
    this.getData(url, params).then(({ data }) => (data && data.length ? data : undefined));

  patchData = (url, data) =>
    this.instance
      .patch(url, data, {
        params: {
          site: SITE_NAME,
        },
      })
      .catch(e => {
        error('PATCH error', url, e);
        return Promise.reject(e);
      });

  postData = (url, data, headers) =>
    this.instance
      .post(url, data, {
        headers: {
          ...headers,
        },
        params: {
          site: SITE_NAME,
        },
      })
      .catch(e => {
        error('POST error', url, e);
        return Promise.reject(e);
      });

  putData = (url, data, headers) =>
    this.instance
      .put(url, data, {
        headers: {
          ...headers,
        },
        params: {
          site: SITE_NAME,
        },
      })
      .catch(e => {
        error('PUT error', url, e);
        return Promise.reject(e);
      });
}
