import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import Button from 'commonUi/Button/Button';
import {
  LABEL_FINISH,
  LABEL_MY_ACTIVITY,
  LABEL_SEND_SIGNATURE_SUCCESS_NOTIFY,
  LABEL_SEND_SIGNATURE_SUCCESS_TIME,
  LABEL_SEND_SIGNATURE_SUCCESS_TITLE,
} from 'settings/labels';

import ActivityConfirmationSteps from 'commonUi/ActivityStep/ActivityConfirmationSteps';

import styles from '../Confirmation.module.scss';

export function ConfirmationSign() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classnames(styles.confirmation, styles.confirmationSign)}>
      <h3>{LABEL_SEND_SIGNATURE_SUCCESS_TITLE}</h3>
      <p>{LABEL_SEND_SIGNATURE_SUCCESS_TIME}</p>

      <div className={styles.steps}>
        <ActivityConfirmationSteps activeStep={2} withLoadIconOnActiveStep />
      </div>
      <p>
        {LABEL_SEND_SIGNATURE_SUCCESS_NOTIFY}{' '}
        <RouterLink className={styles.link} to="/mon-activite-commerciale">
          {LABEL_MY_ACTIVITY}
        </RouterLink>
      </p>

      <div className={styles.button}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/mon-activite-commerciale/pre-reservations"
        >
          {LABEL_FINISH}
        </Button>
      </div>
    </div>
  );
}
