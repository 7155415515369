import React, { ContextType, useCallback, useContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';

import { LOT_CELL_ID_KIND, LOT_JSON_PROGRAM_REF } from 'settings/lots';
import { SORT_ORDER_ASC } from 'settings/search';

import type { LotJson, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType, ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';

import { axiosOffresInstance } from 'api/offresAPI/axiosInstance';
import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';
import { getLotsStatuses } from 'api/viOffresAPI/apiClient';
import { isProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import { isLotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { sortLots } from 'services/lots';
import { generatePdf, generatePlanImageFromPdf } from 'services/pdf';

import programLotContext from 'modules/App/Contexts/programLotContext';
import programPdfContext from 'modules/App/Contexts/programPdfContext';
import lotPdfContext from 'modules/App/Contexts/lotPdfContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import ProgramPdf from 'commonUi/PDF/ProgramPdf/ProgramPdf';
import LotPdf from 'commonUi/PDF/LotPdf/LotPdf';

function makeLotId(programRef: string, lotNumber: string) {
  return `${programRef}-${lotNumber}`;
}

interface Pdf {
  blob: Blob;
  url: string;
}
interface ProgramPdfProviderProps {
  program?: ProgramListType | ProgramTypeV2;
  lot?: LotTypeV2 | LotJson;
  lots?: LotJson[];
  statuses?: Record<string, Status>;
}

export default function PdfProvider({
  children,
  program: programProps,
  lot: lotProps,
  lots: lotsProps,
  statuses: statusesProps = {},
}: PropsWithChildren<ProgramPdfProviderProps>) {
  const fetchProgram = (ref: string) =>
    new Promise<ProgramTypeV2>(resolve => {
      if (isProgramTypeV2(programProps)) {
        resolve(programProps);
      } else if (programs[ref]) {
        resolve(programs[ref]);
      } else {
        resolve(
          axiosOffresInstance({
            url: `/programmes/${ref}`,
            params: { site: 'vi3p' },
          }).then(({ data }) => data)
        );
      }
    });
  const fetchLots = (ref: string) =>
    new Promise<LotJson[]>(resolve => {
      const programLots = programsLots[ref];
      if (lotsProps) {
        resolve(lotsProps);
      } else if (programLots) {
        resolve(programLots);
      } else {
        resolve(
          sortLots(
            getLots(lot => lot[LOT_JSON_PROGRAM_REF] === programs[ref]?.referenceProgramme),
            LOT_CELL_ID_KIND,
            SORT_ORDER_ASC
          )
        );
      }
    });
  const fetchLot = (ref: string, number: string) =>
    new Promise<LotTypeV2>(resolve => {
      const lot = lotsDetail[makeLotId(ref, number)];
      if (isLotTypeV2(lotProps)) {
        resolve(lotProps);
      } else if (lot) {
        resolve(lot);
      } else {
        resolve(
          axiosViOffreInstance
            .get<LotTypeV2>(`/programmes/${ref}/lots/${number}?site=vi3p`)
            .then(({ data }) => data)
        );
      }
    });
  const fetchStatuses = (ids: string[]) =>
    new Promise<typeof statusesProps>(resolve => {
      const idsToFetch = ids.filter(id => !statuses[id]);
      if (!idsToFetch.length) {
        resolve(statuses);
      } else {
        resolve(
          getLotsStatuses(`/lotStatut`, idsToFetch.join('')).then(newStatuses => ({
            ...statuses,
            ...newStatuses,
          }))
        );
      }
    });

  const { taxesById } = useContext(TaxonomiesContext);
  const { getLots, isLoading } = useContext(programLotContext);

  const [isGeneratingProgram, setIsGeneratingProgram] = useState<
    Record<string, boolean | undefined>
  >({});
  const [isGeneratingLot, setIsGeneratingLot] = useState<Record<string, boolean | undefined>>({});

  // Common data
  const [statuses, setStatuses] = useState(statusesProps);
  useEffect(() => {
    setStatuses(prev => {
      if (Object.keys(statusesProps).some(key => statusesProps[key] !== prev[key])) {
        return { ...prev, ...statusesProps };
      }
      return prev;
    });
  }, [statusesProps]);
  const programRef = isProgramTypeV2(programProps)
    ? programProps.referenceProgramme
    : programProps?.ref;
  const [programs, setPrograms] = useState<Record<string, ProgramTypeV2>>(() =>
    programRef && isProgramTypeV2(programProps) ? { [programRef]: programProps } : {}
  );
  useEffect(() => {
    if (isProgramTypeV2(programProps) && programRef && !programs[programRef]) {
      setPrograms(prev => ({ ...prev, [programRef]: programProps }));
    }
  }, [programs, programProps]);

  // Program PDF generation
  const [pdfsProgram, setPdfsProgram] = useState<Record<string, Pdf | undefined>>({});
  const [programsLots, setProgramsLots] = useState<Record<string, LotJson[] | undefined>>(() =>
    lotsProps && programRef ? { [programRef]: lotsProps } : {}
  );

  const getPdfProgram = useCallback<ContextType<typeof programPdfContext>['getPdf']>(
    programRef => pdfsProgram[programRef],
    [pdfsProgram]
  );
  const generateProgram = useCallback<ContextType<typeof programPdfContext>['generate']>(
    ref => {
      const url = pdfsProgram[ref]?.url;
      if (!url) {
        setIsGeneratingProgram(prev => ({ ...prev, [ref]: true }));
        // In theory we should check if `isLoading` from `programLotContext` is false before doing
        // anything else but in practice it should always be false at this point
        return Promise.all([fetchProgram(ref), fetchLots(ref)])
          .then(([program, lots]) =>
            Promise.all([program, lots, fetchStatuses(lots.map(lot => lot.nid))])
          )
          .then(([program, lots, statuses]) =>
            Promise.all([
              program,
              lots,
              statuses,
              generatePdf(
                <ProgramPdf program={program} lots={lots} statuses={statuses} taxById={taxesById} />
              ),
            ])
          )
          .then(([program, lots, statuses, { blob, url }]) => {
            setPrograms(prev => ({ ...prev, [ref]: program }));
            setProgramsLots(prev => ({ ...prev, [ref]: lots }));
            setStatuses(statuses);
            setPdfsProgram(prev => ({
              ...prev,
              [ref]: { blob, url },
            }));
            return url;
          })
          .finally(() => setIsGeneratingProgram(prev => ({ ...prev, [ref]: false })));
      }
      return Promise.resolve(url);
    },
    [pdfsProgram, isLoading, programs, programsLots]
  );

  // Lot PDF generation
  const [pdfsLot, setPdfsLot] = useState<Record<string, Pdf | undefined>>({});
  const [lotsDetail, setLotsDetail] = useState<Record<string, LotTypeV2 | undefined>>({});

  const getPdfLot = useCallback<ContextType<typeof lotPdfContext>['getPdf']>(nid => pdfsLot[nid], [
    pdfsLot,
  ]);
  const generateLot = useCallback<ContextType<typeof lotPdfContext>['generate']>(
    (ref, number, nid) => {
      const url = pdfsLot[number]?.url;
      if (!url) {
        setIsGeneratingLot(prev => ({ ...prev, [nid]: true }));
        // In theory we should check if `isLoading` from `programLotContext` is false before doing
        // anything else but in practice it should always be false at this point
        return Promise.all([fetchProgram(ref), fetchLot(ref, number), fetchStatuses([nid])])
          .then(([program, lot, status]) =>
            Promise.all([
              program,
              lot,
              status[nid]?.label,
              new Promise<string | undefined>(resolve => {
                const plan = lot.documents.filter(doc => doc.nature === 'Plan')[0];
                if (plan) {
                  resolve(generatePlanImageFromPdf(plan.url));
                } else {
                  resolve(undefined);
                }
              }),
            ])
          )
          .then(([program, lot, status, plan]) =>
            Promise.all([
              lot,
              program,
              generatePdf(<LotPdf lot={lot} program={program} status={status} plan={plan} />),
            ])
          )
          .then(([lot, program, { blob, url }]) => {
            setLotsDetail(prev => ({ ...prev, [nid]: lot }));
            setPrograms(prev => ({ ...prev, [ref]: program }));
            setStatuses(statuses);
            setPdfsLot(prev => ({
              ...prev,
              [nid]: { blob, url },
            }));
            return url;
          })
          .finally(() => setIsGeneratingLot(prev => ({ ...prev, [nid]: false })));
      }
      return Promise.resolve(url);
    },
    [pdfsLot, programs, lotsDetail]
  );

  return (
    <programPdfContext.Provider
      value={{
        getPdf: getPdfProgram,
        programs,
        generate: generateProgram,
        isGenerating: isGeneratingProgram,
      }}
    >
      <lotPdfContext.Provider
        value={{
          getPdf: getPdfLot,
          lotsDetail,
          programs,
          generate: generateLot,
          isGenerating: isGeneratingLot,
        }}
      >
        {children}
      </lotPdfContext.Provider>
    </programPdfContext.Provider>
  );
}
