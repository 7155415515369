import React from 'react';
import classnames from 'classnames';

import { LABEL_SEE_MANAGEMENT_MANDATE, LABEL_SEE_PRICE_LIST } from 'settings/labels';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import FieldCta from 'commonUi/FieldCta/FieldCta';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './ProgramManager.module.scss';

export default function ProgramManager({ className = undefined, program }: ProgramManagerProps) {
  if (!program?.packs?.gestionnaireAdb) {
    return null;
  }

  const { descriptif: description, images, nom: name } = program.packs.gestionnaireAdb;

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.buttons}>
        {program?.packs?.gestionnaireAdb?.url_price_list && (
          <div className={styles.buttonsItem}>
            <FieldCta
              cta={{
                target: '_blank',
                title: program.packs.gestionnaireAdb.price_list_name || LABEL_SEE_PRICE_LIST,
                uri: program.packs.gestionnaireAdb.url_price_list,
              }}
            />
          </div>
        )}
        {program?.packs?.gestionnaireAdb?.url_mandate && (
          <div className={styles.buttonsItem}>
            <FieldCta
              cta={{
                target: '_blank',
                title: program.packs.gestionnaireAdb.mandate_name || LABEL_SEE_MANAGEMENT_MANDATE,
                uri: program.packs.gestionnaireAdb.url_mandate,
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.infos}>
        <FieldImage className={styles.image} media={images?.[0]} />

        <div className={styles.text}>
          <div className={styles.name}>{name}</div>
          <FieldRte html={description} />
        </div>
      </div>
    </div>
  );
}

export type ProgramManagerProps = {
  className?: string;
  program: ProgramTypeV2;
};
