import React from 'react';
import classnames from 'classnames';

import { LABEL_DELIVERY_SHORT, LABEL_FROM, LABEL_HT, LABEL_TTC } from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import { isAuthenticated } from 'services/authentication';
import { showQuarter } from 'services/date';
import { getMinPrice } from 'services/formatter';
import { setRedirectUrl } from 'services/redirect';
import { htmlEntityDecode } from 'services/stringHtml';
import { taxMapping } from 'services/taxes';

import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import LazyImage from 'commonUi/LazyImage/LazyImage';

import styles from './HighlightSliderItem.module.scss';

export const DISPLAY_COL = 'col';

interface HighlightSliderItemProps {
  className?: string;
  data: {
    field_adresse_locality?: string;
    field_adresse_postal_code?: number;
    field_diaporama_descriptif?: string;
    field_fiscalite?: string;
    field_livraison_previsionnelle?: Parameters<typeof showQuarter>[0];
    field_prix_hors_mobilier_range?: Parameters<typeof getMinPrice>[0];
    field_prix_tva_normale_ran?: Parameters<typeof getMinPrice>[0];
    field_ref_operation?: string;
    field_type?: string;
    title: string;
  };
  display?: string;
}

export default function HighlightSliderItem({
  className,
  data,
  display,
}: HighlightSliderItemProps) {
  const {
    field_adresse_locality: address,
    field_adresse_postal_code: postalCode,
    field_diaporama_descriptif: diapo = '/images/placeholders/923x519.png',
    field_fiscalite: tax,
    field_livraison_previsionnelle: delivery,
    field_prix_hors_mobilier_range: priceNoMobilier,
    field_prix_tva_normale_ran: priceNormalTva,
    field_ref_operation: ref,
    title,
  } = data;

  const standardTax = tax ? taxMapping?.get(tax.split(/,\s?/)[0]) : undefined;

  const programUrl = ref ? `/programme/page/${ref}/description` : null;

  const minPrice = tax?.includes(TAX_TYPE_LMNP)
    ? getMinPrice(priceNoMobilier)
    : getMinPrice(priceNormalTva);

  return (
    <a
      href={isAuthenticated() ? programUrl : window.vinci.REACT_APP_LOGIN_URL}
      className={classnames(className, styles.root, { [styles.col]: display === DISPLAY_COL })}
      onClick={setRedirectUrl(programUrl)}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.img}>
        <LazyImage alt={title} src={diapo.split('#')[0].replace('&amp;', '&')} />
        {standardTax && (
          <span className={styles.etiquette}>
            <PastilleFiscality tax={standardTax} />
          </span>
        )}
      </div>

      <div className={styles.description}>
        {address && postalCode && (
          <div className={styles.address}>
            {htmlEntityDecode(address)} ({postalCode})
          </div>
        )}

        {title && <div className={styles.title}>{htmlEntityDecode(title)}</div>}

        <dl className={styles.informations}>
          {delivery && (
            <div>
              <dt>{LABEL_DELIVERY_SHORT}</dt>
              <dd>{showQuarter(delivery)}</dd>
            </div>
          )}
          {minPrice && (
            <div>
              <dt>{LABEL_FROM}</dt>
              <dd>
                {tax?.includes(TAX_TYPE_LMNP)
                  ? `${minPrice} ${LABEL_HT}`
                  : `${minPrice} ${LABEL_TTC}`}
              </dd>
            </div>
          )}
        </dl>
      </div>
    </a>
  );
}
