import { identity } from './functional';

type Notifier = (...args: any[]) => void;
export function createObserver(createNotifier: (notifier: Notifier) => () => void = identity) {
  const notifiers: Notifier[] = [];
  const notify = createNotifier((...args) => notifiers.forEach(listener => listener(...args)));

  return {
    notify,
    subscribe(notifier: Notifier) {
      notifiers.push(notifier);
      return () => {
        notifiers.splice(notifiers.indexOf(notifier), 1);
      };
    },
  };
}
