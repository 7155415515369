import qs from 'query-string';
import { omit } from 'lodash';

export function formatQuery(query: Record<string, string | number | string[] | number[]> = {}) {
  return Object.entries(query)
    .reduce<string[]>((acc, [key, val]) => {
      if (val === undefined || val === null) {
        return acc;
      }
      if (val instanceof Array) {
        const arrayKey = key.indexOf('[]') === -1 ? key : key.split('[]').join('');
        const values = val.map(
          item => `${encodeURIComponent(arrayKey)}[]=${encodeURIComponent(item)}`
        );
        if (values.length > 0) {
          return acc.concat(values);
        }
        return acc;
      }
      return acc.concat(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
    }, [])
    .join('&');
}

export function getUrl(url: string, params: Parameters<typeof formatQuery>[0] = {}) {
  const query = formatQuery(params);
  return query ? `${url}?${query}` : url;
}

export function getLoginUrlWithState() {
  const baseUrl = window.vinci.REACT_APP_LOGIN_URL;

  const state = JSON.stringify({
    origin: `${window.location.pathname}${window.location.search}`,
  });

  return `${baseUrl}&state=${btoa(state)}`;
}

type Query = { [key: string]: any };

export function modifyQuery(query: Query, removeKeys: Parameters<typeof omit>[1] = []) {
  const filteredQuery = omit(
    qs.parse(window.location.search, { arrayFormat: 'bracket' }),
    removeKeys
  );
  const queryString = qs.stringify(
    { ...filteredQuery, ...query },
    { encode: false, arrayFormat: 'bracket' }
  );
  return `?${encodeURI(queryString)}`;
}
