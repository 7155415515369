import { useCallback, useEffect, useState } from 'react';
import debounce from 'services/debounce';

export function useDebouncedValue(value, timer) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const updateDebounced = useCallback(debounce(setDebouncedValue, timer), [timer]);

  useEffect(() => updateDebounced(value), [value, updateDebounced]);

  return debouncedValue;
}
