import React, { useContext, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import {
  ClickAwayListener as MuiClickAwayListener,
  Grow as MuiGrow,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@material-ui/core';

import { getActivityStep, getBookingSaleActivityStep } from 'services/activities';
import { replaceTokens } from 'services/formatter';
import {
  ACTIVITY_STEP_COLOR_CURRENT,
  ACTIVITY_STEP_COLOR_PAST,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_SALE,
} from 'settings/activity';
import { LABEL_ACTIVITY_STEP } from 'settings/labels';
import { TOKEN_NB } from 'settings/token';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { Pastille } from 'commonUi/Pastille/Pastille';

import ActivityStepsList from './ActivityStepsList';

import styles from './ActivityStep.module.scss';

const STEPS_LIST_HOVER_TIMEOUT = 300;

interface ActivityStepsProps {
  activity: Activity;
  baseTag?: ReactNode;
  className?: string;
  isHover?: boolean;
  revertHover?: boolean;
}

export default function ActivityStep({
  activity,
  baseTag,
  className,
  isHover = false,
  revertHover = false,
}: ActivityStepsProps) {
  const { isResponsive, isTablette } = useContext(ResponsiveContext);

  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const openTimeout = useRef<NodeJS.Timeout>();

  function handleOpenList(ev: { stopPropagation: () => void }) {
    if (ev) {
      ev.stopPropagation();
    }
    setIsOpen(true);
  }
  function handleCloseList(ev: { stopPropagation: () => void }) {
    if (ev) {
      ev.stopPropagation();
    }
    if (openTimeout.current) {
      clearTimeout(openTimeout.current);
    }
    setIsOpen(false);
  }
  function handleMouseEnter(ev: React.MouseEvent) {
    openTimeout.current = setTimeout(() => {
      handleOpenList(ev);
      openTimeout.current = undefined;
    }, STEPS_LIST_HOVER_TIMEOUT);
  }

  function buildPastille(activeStepNumber, nbSteps) {
    let bgColor = activeStep === nbSteps ? ACTIVITY_STEP_COLOR_PAST : ACTIVITY_STEP_COLOR_CURRENT;
    if (
      (activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
        activity.field_type_ac === ACTIVITY_TYPE_SALE) &&
      activeStepNumber > 0
    ) {
      bgColor = ACTIVITY_STEP_COLOR_PAST;
    }
    return (
      <Pastille
        label={replaceTokens(LABEL_ACTIVITY_STEP, {
          [TOKEN_NB]:
            (activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
              activity.field_type_ac === ACTIVITY_TYPE_SALE) &&
            activeStepNumber === 0
              ? 1
              : activeStepNumber,
        })}
        bgColor={bgColor}
        textColor="#fff"
      />
    );
  }

  const activeStep =
    activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
    activity.field_type_ac === ACTIVITY_TYPE_SALE
      ? getBookingSaleActivityStep(activity)
      : getActivityStep(activity);
  let tag = baseTag;
  if (!tag) {
    if (
      activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
      activity.field_type_ac === ACTIVITY_TYPE_SALE
    ) {
      tag = buildPastille(activeStep, 6);
    } else {
      tag = buildPastille(activeStep === 5 ? activeStep : activeStep + 1, 5);
    }
  }

  if (
    !activeStep &&
    activity.field_type_ac !== ACTIVITY_TYPE_BOOKING &&
    activity.field_type_ac !== ACTIVITY_TYPE_SALE &&
    !baseTag
  ) {
    return null;
  }

  return (
    <>
      <div ref={anchorRef} className={classnames(className, styles.tagWrapper)}>
        {tag}
        <Pastille
          className={classnames(styles.tagSee, {
            [styles.tagRevertHover]: revertHover,
            [styles.tagSeeHover]: isHover,
          })}
          chipClassName={styles.chip}
          fullWidth
          icon="display"
          iconClassName={styles.tagSeeIcon}
          label="Voir"
          labelClassName={styles.tagSeeLabel}
          bgColor="#0575E6"
          textColor="#fff"
          onClick={isTablette ? handleOpenList : undefined}
          onMouseEnter={!isResponsive && !isTablette ? handleMouseEnter : undefined}
          onMouseLeave={!isResponsive && !isTablette ? handleCloseList : undefined}
        />
      </div>

      <MuiPopper
        transition
        anchorEl={anchorRef.current}
        placement="top"
        open={isOpen}
        style={{ zIndex: 150 }}
      >
        {({ TransitionProps, placement }) => (
          <MuiGrow {...TransitionProps}>
            <MuiPaper
              classes={{
                root: classnames(styles.popper, styles.selectorWeight, styles[placement]),
              }}
              onClick={ev => ev.stopPropagation()}
            >
              <MuiClickAwayListener onClickAway={handleCloseList}>
                <>
                  {isTablette && (
                    <button type="button" className={styles.popperClose} onClick={handleCloseList}>
                      <Icon icon="cross-bold" />
                    </button>
                  )}
                  <ActivityStepsList activity={activity} />
                </>
              </MuiClickAwayListener>
            </MuiPaper>
          </MuiGrow>
        )}
      </MuiPopper>
    </>
  );
}
