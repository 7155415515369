import React from 'react';
import type { ComponentProps } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';
import dayjs from 'dayjs';

import type { ReportLight } from 'api/viOffresAPI/apiTypes/Reports';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './ReportItem.module.scss';
import ReportImages from './ReportImages';

interface ReportItemProps {
  className?: string;
  from?: string;
  programRef: string;
  report: ReportLight;
  reverse?: boolean;
  target?: ComponentProps<ReactRouterLink>['target'];
}

export default function ReportItem({
  className,
  from,
  programRef,
  report,
  reverse = false,
  target = '_self',
}: ReportItemProps) {
  const date = dayjs(report.date);
  const url = `/programme/page/${programRef}/chantiers/${report.id}`;

  return (
    <div className={classnames(className, styles.root, { [styles.reverse]: reverse })}>
      <ReactRouterLink
        className={styles.content}
        to={from ? `${url}?from=${from}` : url}
        target={target}
      >
        <h3 className={styles.title}>{report.title}</h3>
        {date.isValid() && (
          <time className={styles.date} dateTime={report.date}>
            Posté le {date.format('DD/MM/YYYY')}
          </time>
        )}

        {report.description && (
          <FieldRte className={styles.description} html={report.description} />
        )}

        <div className={styles.button}>Découvrir</div>
      </ReactRouterLink>

      <ReportImages className={styles.images} images={report.image} />
    </div>
  );
}
