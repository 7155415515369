import qs from 'query-string';
import { useLocation } from 'react-router-dom';

export interface SearchType {
  type: 'lot' | 'program';
  lat: string;
  lng: string;
  east: number | string;
  south: number | string;
  north: number | string;
  west: number | string;
  zoom: string;
  budget: string;
  profitability: string;
  programmeId: string;
  programRef?: string;
  promoRef: string;
  marker: string;
  lotNumber: string;
  surface: string;
  origin: 'list' | 'map';
  profitabilityMax: string;
  profitabilityMin: string;
  reducedVAT: string[];
  surfaceMin: string;
  surfaceMax: string;
  budgetMax: string;
  budgetMin: string;
  // dateLivraison;
  actability: string[];
  annexes: string[];
  delivery: string[];
  kinds: string[];
  viewport: string;
  // locationsSearch;
  locations: string[];
  others: string[];
  taxes: string[];
  nearProgram;
  activityId?: string;
  // mapOpen;
  searchId: string;
  map;
}
export function useSearch() {
  const location = useLocation();
  const search = qs.parse(location.search, { arrayFormat: 'bracket' }) as Partial<SearchType>;
  return {
    type: 'program' as const,
    actability: [] as SearchType['actability'],
    annexes: [] as SearchType['annexes'],
    delivery: [] as SearchType['delivery'],
    kinds: [] as SearchType['kinds'],
    locations: [] as SearchType['locations'],
    others: [] as SearchType['others'],
    taxes: [] as SearchType['taxes'],
    ...search,
  };
}
