import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { MOBILE_MEDIA_QUERY, RESPONSIVE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from 'settings/ui';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

function ResponsiveProvider({ children }: ResponsiveProviderProps) {
  const [isFooterVisibleResponsive, setIsFooterVisibleResponsive] = useState(false);

  function isAppleDevice() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  const [responsive, setResponsive] = useState({
    isAppleDevice: isAppleDevice(),
    isMobile: matchMedia(MOBILE_MEDIA_QUERY).matches,
    isResponsive: matchMedia(RESPONSIVE_MEDIA_QUERY).matches,
    isTablette: matchMedia(TABLET_MEDIA_QUERY).matches,
  });

  const updateResponsive = () => {
    setResponsive({
      isMobile: matchMedia(MOBILE_MEDIA_QUERY).matches,
      isResponsive: matchMedia(RESPONSIVE_MEDIA_QUERY).matches,
      isTablette: matchMedia(TABLET_MEDIA_QUERY).matches,
      isAppleDevice: isAppleDevice(),
    });
  };

  const setFooterVisibleResponsive = useCallback(flag => {
    setIsFooterVisibleResponsive(flag);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateResponsive);

    return function cleanup() {
      window.removeEventListener('resize', updateResponsive);
    };
  });

  return (
    <ResponsiveContext.Provider
      value={{
        ...responsive,
        isFooterVisibleMobile: isFooterVisibleResponsive,
        setIsFooterVisibleMobile: setFooterVisibleResponsive,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
}

export type ResponsiveProviderProps = {
  children: ReactNode;
};

export default ResponsiveProvider;
