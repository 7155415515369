import React, { useContext, useState } from 'react';

import { LABEL_MY_SAVED_SEARCH_PLURAL } from 'settings/labels';
import { TMS_TIMEOUT } from 'settings/tms';

import { TmsContext } from 'modules/App/Contexts/TmsContext';
import userContext from 'modules/App/Contexts/userContext';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { useHistory } from 'react-router-dom';

import styles from './LastSavedSearchList.module.scss';

export default function LastSavedSearchList() {
  const { savedSearch } = useContext(userContext);
  const { setEventPageVars } = useContext(TmsContext);
  const [linkClick, setLinkClick] = useState<boolean>(false);
  const history = useHistory();

  let nbSearch = savedSearch ? savedSearch.length : 0;
  nbSearch = nbSearch > 3 ? 3 : nbSearch;

  if (!savedSearch || savedSearch.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <SvgIcon className={styles.titleIcon} iconId="icon-my-research" />
        {LABEL_MY_SAVED_SEARCH_PLURAL}
        {nbSearch > 0 && ` (${nbSearch})`}
      </div>
      <div className={styles.list}>
        {savedSearch.slice(0, 3).map(search => (
          <div className={styles.listItem} key={search.id}>
            <button
              type="button"
              className={styles.searchLink}
              onClick={() => {
                setLinkClick(true);
                setTimeout(() => {
                  setLinkClick(false);
                  setEventPageVars({});
                  history.replace(`/${search.url}`);
                }, TMS_TIMEOUT);
              }}
            >
              <span className={styles.searchTitle}>{search.titre}</span>
              <span className={styles.searchFilters}>{search.filtres}</span>
            </button>
          </div>
        ))}
      </div>

      <TagCommanderEvent
        isActive={linkClick}
        navigation_pagename="recherche_voirmarecherche"
        navigation_template="recherche"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </div>
  );
}
