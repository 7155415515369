import React from 'react';
import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import styles from './RowDetailsListItem.module.scss';

interface RowDetailsListItemProps {
  label: string;
  href?: string;
  infos?: ReactNode;
  note?: ReactNode;
  value: string | undefined;
  custom?: ReactNode;
}

export default function RowDetailsListItem({
  custom,
  label,
  value,
  href,
  note,
  infos,
}: RowDetailsListItemProps) {
  if (href) {
    return (
      <>
        <div className={styles.itemContent}>
          <span>{label}</span>
          <Link to={href} className={styles.label}>
            {value}
          </Link>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.itemContent}>
        <span>{label}</span>
        <span className={styles.value}>{value || '-'}</span>
        {infos && <div>{infos}</div>}
        {custom && <div>{custom}</div>}
      </div>
      {note && <div className={styles.label}>{note}</div>}
    </>
  );
}
