import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './PdfDepositInfosStyles';

export default function PdfDepositInfos({
  bicDepotGarantie,
  ibanDepotGarantie,
  modePaiement,
  nomBanqueDepotGarantie,
  ordreDepotGarantie,
}: DepositInfosProps) {
  if (
    !bicDepotGarantie &&
    !ibanDepotGarantie &&
    !modePaiement &&
    !nomBanqueDepotGarantie &&
    !ordreDepotGarantie
  ) {
    return null;
  }

  return (
    <View style={styles.root}>
      {!!modePaiement && <Text>{modePaiement}</Text>}
      {!!ordreDepotGarantie && <Text>{ordreDepotGarantie}</Text>}
      {!!ibanDepotGarantie && <Text>{ibanDepotGarantie}</Text>}
      {!!bicDepotGarantie && !!nomBanqueDepotGarantie && (
        <View style={styles.cols}>
          <Text style={styles.colsItem}>{bicDepotGarantie}</Text>
          <Text style={styles.colsItem}>{nomBanqueDepotGarantie}</Text>
        </View>
      )}
    </View>
  );
}

export type DepositInfosProps = {
  bicDepotGarantie: string;
  ibanDepotGarantie: string;
  modePaiement: string;
  nomBanqueDepotGarantie: string;
  ordreDepotGarantie: string;
};
