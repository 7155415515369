import {
  TAX_LABEL_TYPE_BRS,
  TAX_LABEL_TYPE_DEFICIT,
  TAX_LABEL_TYPE_DEMEMBREMENT,
  TAX_LABEL_TYPE_LMNP,
  TAX_LABEL_TYPE_MALRAUX,
  TAX_LABEL_TYPE_NONE,
  TAX_LABEL_TYPE_PINEL,
  TAX_LABEL_TYPE_PINEL_DEROGE,
  TAX_LABEL_TYPE_PINEL_PLUS,
  TAX_LABEL_TYPE_PLS,
  TAX_TYPE_BRS,
  TAX_TYPE_DEFICIT,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PINEL,
  TAX_TYPE_PINEL_DEROGE,
  TAX_TYPE_PINEL_PLUS,
  TAX_TYPE_PLS,
} from 'settings/taxes';

import type { TaxType } from 'api/viOffresAPI/apiTypes/Taxonomies';

export const taxMapping: Map<
  string,
  | typeof TAX_TYPE_PINEL
  | typeof TAX_TYPE_NONE
  | typeof TAX_TYPE_DEMEMBREMENT
  | typeof TAX_TYPE_LMNP
  | typeof TAX_TYPE_DEFICIT
  | typeof TAX_TYPE_BRS
  | typeof TAX_TYPE_PINEL_DEROGE
  | typeof TAX_TYPE_PINEL_PLUS
  | typeof TAX_TYPE_PLS
  | typeof TAX_TYPE_MALRAUX
> = new Map([
  ['DeficitFoncier', TAX_TYPE_DEFICIT],
  ['Demembrement', TAX_TYPE_DEMEMBREMENT],
  ['Démembrement', TAX_TYPE_DEMEMBREMENT],
  ['LMNP', TAX_TYPE_LMNP],
  ['Pinel', TAX_TYPE_PINEL],
  ['ResidenceHorsPinel', TAX_TYPE_NONE],
  ['Hors dispositif', TAX_TYPE_NONE],
  ['Aucun', TAX_TYPE_NONE],
  ['aucun', TAX_TYPE_NONE],
  ['Non', TAX_TYPE_NONE],
  ['BRS', TAX_TYPE_BRS],
  ['PinelDeroge', TAX_TYPE_PINEL_DEROGE],
  ['Pinel+', TAX_TYPE_PINEL_PLUS],
  ['PLS', TAX_TYPE_PLS],
  ['Malraux', TAX_TYPE_MALRAUX],
]);

export const taxLabelMapping: Map<TaxType, string> = new Map([
  [TAX_TYPE_DEFICIT, TAX_LABEL_TYPE_DEFICIT],
  [TAX_TYPE_DEMEMBREMENT, TAX_LABEL_TYPE_DEMEMBREMENT],
  [TAX_TYPE_LMNP, TAX_LABEL_TYPE_LMNP],
  [TAX_TYPE_NONE, TAX_LABEL_TYPE_NONE],
  [TAX_TYPE_PINEL, TAX_LABEL_TYPE_PINEL],
  [TAX_TYPE_BRS, TAX_LABEL_TYPE_BRS],
  [TAX_TYPE_PINEL_DEROGE, TAX_LABEL_TYPE_PINEL_DEROGE],
  [TAX_TYPE_PINEL_PLUS, TAX_LABEL_TYPE_PINEL_PLUS],
  [TAX_TYPE_PLS, TAX_LABEL_TYPE_PLS],
  [TAX_TYPE_MALRAUX, TAX_LABEL_TYPE_MALRAUX],
]);

export const fiscalitiesMap = new Map([
  ['DeficitFoncier', 'deficitFoncier'],
  ['Demembrement', 'Demembrement'],
  ['Démembrement', 'Demembrement'],
  ['LMNP', 'LMNP'],
  ['Pinel', 'pinel'],
  ['Aucun', 'aucun'],
  ['aucun', 'aucun'],
  ['ResidenceHorsPinel', 'aucun'],
  ['Pinel+', 'pinel+'],
  ['BRS', 'BRS'],
  ['PLS', 'PLS'],
  ['PinelDeroge', 'pinelDeroge'],
  ['Malraux', 'Malraux'],
]);
