import React from 'react';

import SvgIcon from '../SvgIcon/SvgIcon';
import styles from './Availability.module.scss';

export function Availability({ availability = true, label, value = undefined }: AvailabilityProps) {
  return (
    <div className={styles.root}>
      <SvgIcon className={styles.icon} iconId={availability ? 'icon-checked' : 'icon-failed'} />
      <span className={styles.label}>{label}</span>{' '}
      {value && <span className={styles.value}>{value}</span>}
    </div>
  );
}

type AvailabilityProps = {
  availability?: boolean;
  label: string;
  value?: string;
};
