import { GMAP_BASE_URL } from 'settings/map';

import ApiInterface from './ApiInterface';

class ApiKelQuartier extends ApiInterface {
  constructor() {
    super();

    this.baseURL = window.vinci.REACT_APP_API_KELQUARTIER_URL;
    this.apiKey = window.vinci.REACT_APP_API_KELQUARTIER_KEY;
  }

  kelquartierReceivePois = data => {
    return {
      data,
      type: 'KELQUARTIER_RECEIVE_POIS',
    };
  };

  /**
   * Api CALL
   * @param themes
   * @param lat
   * @param lng
   * @returns {Promise<*>}
   */
  getKelquartierPois = (themes, lat, lng) => {
    return this.fetchData('/pois', {
      max_lat: lat + 0.01,
      min_lat: lat - 0.01,
      // eslint-disable-next-line sort-keys
      max_lon: lng + 0.01,
      min_lon: lng - 0.01,
      theme2: themes.join(','),
      format: 'json',
    });
  };

  /**
   * Get pois data
   * @param themes
   * @param lat
   * @param lng
   * @returns {function(*): (Promise<unknown>)}
   */
  kelquartierGetPois = (themes, lat, lng) => {
    if (themes.length === 0) {
      return Promise.resolve([]).then(data => this.kelquartierReceivePois(data));
    }

    return this.getKelquartierPois(themes, lat, lng)
      .then(data => this.kelquartierReceivePois(data))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      });
  };

  getGoogleReverseGeocoding = (lat, lng) => {
    return this.fetchData(
      `${GMAP_BASE_URL}/geocode/json?latlng=${lat},${lng}&key=${window.vinci.REACT_APP_GOOGLE_MAP_API_KEY}`
    );
  };
}
export const apiKelQuartier = new ApiKelQuartier();
