import { useCallback, useEffect, useRef, useState } from 'react';

import {
  LOTS_DEFAULT_SORT_BY,
  LOTS_DEFAULT_SORT_ORDER,
  PROGRAM_DEFAULT_SORT_BY,
  PROGRAM_DEFAULT_SORT_ORDER,
  SORT_ORDER_ASC,
  SORT_ORDER_DESC,
  SORT_PROGRAM_BY_SEARCH,
} from 'settings/search';

import type { ProgramSortBy, ProgramSortOrder } from 'api/viOffresAPI/apiTypes/Program';
import type { LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';

export default function useSearchSortState(locations: unknown[] | undefined) {
  const hadLocations = useRef(false);

  const [programsSortBy, setProgramsSortBy] = useState<ProgramSortBy>(PROGRAM_DEFAULT_SORT_BY);
  const [programsSortOrder, setProgramsSortOrder] = useState<ProgramSortOrder>(
    PROGRAM_DEFAULT_SORT_ORDER
  );
  const [lotsSortBy, setLotsSortBy] = useState<LotSortBy>(LOTS_DEFAULT_SORT_BY);
  const [lotsSortOrder, setLotsSortOrder] = useState<LotSortOrder>(LOTS_DEFAULT_SORT_ORDER);

  useEffect(() => {
    // When selecting one or more location filter, set the sort type to distance from map center
    // rather than the preferred location (or whichever else was selected)
    // When removing all locations, restore the sort type to the default one
    if (hadLocations.current && !locations?.length) {
      hadLocations.current = false;
      setProgramsSortBy(PROGRAM_DEFAULT_SORT_BY);
    } else if (!hadLocations.current && locations?.length) {
      hadLocations.current = true;
      setProgramsSortBy(SORT_PROGRAM_BY_SEARCH);
    }
  }, [locations]);

  const reorderPrograms = useCallback(
    (type: typeof programsSortBy) => {
      if (type === programsSortBy) {
        setProgramsSortOrder(
          programsSortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC
        );
      } else {
        setProgramsSortBy(type);
        setProgramsSortOrder(SORT_ORDER_ASC);
      }
    },
    [programsSortBy, programsSortOrder]
  );

  const reorderLots = useCallback(
    (type: typeof lotsSortBy, callback?: () => void) => {
      callback?.();
      if (type === lotsSortBy) {
        setLotsSortOrder(lotsSortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC);
      } else {
        setLotsSortBy(type);
        setLotsSortOrder(SORT_ORDER_ASC);
      }
    },
    [lotsSortBy, lotsSortOrder]
  );

  return {
    programs: {
      sortBy: programsSortBy,
      sortOrder: programsSortOrder,
      setSortBy: setProgramsSortBy,
      setSortOrder: setProgramsSortOrder,
    },
    lots: {
      sortBy: lotsSortBy,
      sortOrder: lotsSortOrder,
      setSortBy: setLotsSortBy,
      setSortOrder: setLotsSortOrder,
    },
    reorderPrograms,
    reorderLots,
  };
}
