export const SLIDER_MODAL_ID = 'modal-slider';
export const LOADING_MODAL_ID = 'modal-loading';
export const CONFIRMATION_MODAL_ID = 'modal-confirmation';
export const COMPARAISON_MODAL_ID = 'modal-comparer';
export const EDIT_FOLDER_MODAL_ID = 'modal-edit-folder';
export const SEND_BOOKLET_MODAL_ID = 'modal-send-booklet';
export const DELETE_FOLDER_MODAL_ID = 'modal-delete-folder';
export const SAVE_SEARCH_FORM_MODAL_ID = 'modal-form-save-search';

export const MODAL_ID_SEND_BOOKLET = 'sendBooklet';
export const MODAL_ID_SEND_PRODUCT_BOOKLET = 'sendProductBooklet';
export const MODAL_ID_SEND_CARD_LOT = 'sendCardLot';
export const MODAL_ID_SEND_CARD_PROGRAM = 'sendCardProgram';
export const MODAL_ID_SEND_COMPARISON = 'sendComparison';
export const MODAL_ID_SEND_DOCUMENTS = 'sendDocuments';
export const MODAL_ID_CREATE_OPTION = 'createOption';
export const MODAL_ID_CREATE_PREBOOK = 'createPrebook';
export const MODAL_ID_CANCEL_OPTION = 'cancelOption';
export const MODAL_ID_CANCEL_PREBOOK = 'cancelPrebook';
