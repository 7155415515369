import React from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';
import { Chip as MuiChip } from '@material-ui/core';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';

import { COLORS } from 'settings/ui';

import Tooltip from '../Tooltip/Tooltip';

import styles from './Pastille.module.scss';

interface PastilleProps extends Omit<ComponentProps<typeof MuiChip>, 'icon'> {
  bgColor?: string;
  bgColorActive?: string;
  className?: string;
  chipClassName?: string;
  fullWidth?: boolean;
  icon?: Icons;
  iconClassName?: string;
  isActive?: boolean;
  isLight?: boolean;
  labelClassName?: string;
  noContainer?: boolean;
  textColor?: string;
  tooltip?: string;
}

export function Pastille({
  bgColor = COLORS.SECONDARY.PINK,
  bgColorActive = COLORS.SECONDARY.GREEN,
  className,
  chipClassName,
  fullWidth = false,
  onClick,
  noContainer = false,
  icon,
  iconClassName,
  isActive,
  isLight,
  labelClassName,
  textColor = COLORS.GREYS.PURE_WHITE,
  tooltip,
  ...props
}: PastilleProps) {
  const isClickable = onClick !== undefined;
  const Chip = ({ className }: { className?: string }) => (
    <MuiChip
      clickable={isClickable}
      component={isClickable ? 'button' : 'div'}
      classes={{
        label: classnames(styles.label, labelClassName),
        root: classnames(className, styles.chipRoot, styles.selectorWeight, {
          [styles.chipLight]: isLight,
          [styles.fullWidth]: fullWidth,
        }),
      }}
      icon={icon && <Icon className={classnames(iconClassName, styles.icon)} icon={icon} />}
      style={{
        backgroundColor: isActive ? bgColorActive : bgColor,
        color: textColor,
      }}
      onClick={onClick}
      {...props}
    />
  );

  if (tooltip) {
    if (noContainer) {
      return (
        <Tooltip arrow title={tooltip} placement="top">
          <div>
            <Chip className={className} />
          </div>
        </Tooltip>
      );
    }

    return (
      <div className={classnames(styles.root, className)}>
        <Tooltip arrow title={tooltip} placement="top">
          <div>
            <Chip className={chipClassName} />
          </div>
        </Tooltip>
      </div>
    );
  }

  if (noContainer) {
    return <Chip className={className} />;
  }

  return (
    <div className={classnames(styles.root, className)}>
      <Chip className={chipClassName} />
    </div>
  );
}
