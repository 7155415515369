import React from 'react';

import type { MediaType, VideoType } from 'types/medias';

import { extractVideoIdFromUrl, makeYouTubeThumbnailFromId } from 'services/videos';

import { mediaIsVideo } from 'types/medias';

import FieldImage from 'commonUi/FieldImage/FieldImage';

interface MediaThumbnailProps {
  className?: string;
  media: MediaType | VideoType | undefined;
}

export default function MediaThumbnail({ className, media }: MediaThumbnailProps) {
  if (mediaIsVideo(media)) {
    const videoId = extractVideoIdFromUrl(media.url);
    if (videoId) {
      return <img className={className} src={makeYouTubeThumbnailFromId(videoId)} alt="" />;
    }
  }

  if (media) {
    return <FieldImage className={className} media={media} />;
  }

  return null;
}
