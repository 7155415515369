import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  pastille: {
    alignItems: 'center',
    borderRadius: '9px',
    display: 'flex',
    flexDirection: 'row',
    height: '17px',
    padding: '0 6px',
  },
  label: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '10px',
    fontWeight: 'medium',
    margin: '-1px 0 0 0',
    textTransform: 'uppercase',
  },
  picto: {
    margin: '0 2px 0 0',
  },
  pastilleFurnished: {
    width: '26px',
    height: '26px',
  },
  pastilleNotaryFees: {
    alignItems: 'center',
    backgroundColor: COLORS.PRIMARY.BLUE_DARK,
    borderRadius: '9px',
    display: 'flex',
    flexDirection: 'row',
    height: '18px',
    padding: '0 7px',
  },
  pastilleNotaryFeesPicto: {
    margin: '0 2px 0 0',
    width: '13px',
  },
  pastilleNotaryFeesLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '6px',
    color: COLORS.GREYS.PURE_WHITE,
    lineHeight: '1',
    marginTop: '-3px',
  },
  pastilleNotaryFeesLabelBold: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  pastilleHonoBoost: {
    alignItems: 'center',
    backgroundColor: COLORS.GREYS.BLUE,
    borderRadius: '9px',
    display: 'flex',
    flexDirection: 'row',
    height: '18px',
    padding: '0 7px',
  },
  pastilleHonoBoostPicto: {
    margin: '0 2px 0 0',
    width: '13px',
  },
  pastilleHonoBoostLabel: {
    color: COLORS.GREYS.PURE_WHITE,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '6px',
    fontWeight: 'bold',
    lineHeight: '1',
    marginTop: '-3px',
    textTransform: 'uppercase',
  },
});
