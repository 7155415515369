export const regexPercentageFees = '^([0-9]{1}([.,][0-9]{1,2})?|10[.,]?0?0?)$';
export function isValidPercentageFees(percentageFees) {
  return RegExp(regexPercentageFees).test(percentageFees);
}

export const regexPhoneNumber = '^(33|0|0033)[1-9](\\d{2}){4}$';
export function isValidPhoneNumber(phoneNumber) {
  return RegExp(regexPhoneNumber).test(phoneNumber);
}

export const regexEmail =
  "^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

export function isValidEmail(email) {
  return RegExp(regexEmail).test(email);
}

export function isValidDateOfBirth(dateOfBirth) {
  return RegExp('^([0-2][0-9]|(3)[0-1])(\\/)(((0)[0-9])|((1)[0-2]))(\\/)\\d{4}$').test(dateOfBirth);
}

export function isValidZIPCode(zipCode) {
  return RegExp('^(?:(?:(?:0[1-9]|[1-8]\\d|9[0-5])(?:\\d{3})?)|97[1-8]|98[4-9]|2[abAB])$').test(
    zipCode
  );
}
export function isCoAcquereurFieldAreEmpty(coAcquereur, fieldsCoAcquereurToCheck) {
  return !fieldsCoAcquereurToCheck.some(value => value && value !== '');
}
