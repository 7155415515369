import React, { ReactNode } from 'react';

import styles from './StandardPage.module.scss';

export default function StandardPage(props: StandarPageType) {
  const { children = null, title = undefined } = props;

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      <div>{children}</div>
    </div>
  );
}

type StandarPageType = {
  children?: ReactNode;
  title?: string;
};
