import React from 'react';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import styles from './PendingPage.module.scss';

export default function PendingPage() {
  return (
    <div className={styles.loading}>
      <SpinLoader width={100} height={100} />
    </div>
  );
}
