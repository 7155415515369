import React from 'react';
import classnames from 'classnames';

import {
  ACTIVITY_STEP_COLOR_CURRENT,
  ACTIVITY_STEP_COLOR_FUTURE,
  ACTIVITY_STEP_COLOR_PAST,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_SALE,
} from 'settings/activity';
import {
  LABEL_ACTIVITY_STEP,
  LABEL_ACTIVITY_STEP_CONTRACT,
  LABEL_ACTIVITY_STEP_PREBOOK,
  LABEL_ACTIVITY_STEP_REQUEST,
  LABEL_ACTIVITY_STEP_SIGNED_CLIENT,
  LABEL_ACTIVITY_STEP_SIGNED_VINCI,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_1,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_2,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_3,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_4,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_5,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_6,
} from 'settings/labels';
import { TOKEN_NB } from 'settings/token';

import type { Activity, ActivityDateKey } from 'api/vi3pAPI/apiTypes/ActivityType';

import { getActivityStep, getBookingSaleActivityStep } from 'services/activities';
import { formatActivityDate } from 'services/date';
import { replaceTokens } from 'services/formatter';

import { Pastille } from 'commonUi/Pastille/Pastille';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ActivityStepsList.module.scss';

export const ACTIVITY_STEPS: {
  icon: string;
  iconCurrent?: string;
  title: string;
  dateField: ActivityDateKey;
}[] = [
  {
    icon: 'icon-pre-book-mono',
    iconCurrent: 'icon-pre-book',
    title: LABEL_ACTIVITY_STEP_PREBOOK,
    dateField: 'field_datereservation_ac',
  },
  {
    icon: 'icon-generate-contract-mono',
    iconCurrent: 'icon-generate-contract',
    title: LABEL_ACTIVITY_STEP_CONTRACT,
    dateField: 'field_dategenerationcontrat_ac',
  },
  {
    icon: 'icon-send-documents-mono',
    iconCurrent: 'icon-send-documents',
    title: LABEL_ACTIVITY_STEP_REQUEST,
    dateField: 'field_datedernierenvoi_ac',
  },
  {
    icon: 'icon-contract-signed-client-bg-mono',
    iconCurrent: 'icon-contract-signed-client-bg',
    title: LABEL_ACTIVITY_STEP_SIGNED_CLIENT,
    dateField: 'field_datecontratsigne_client_ac',
  },
  {
    icon: 'icon-contract-signed-vinci-bg-mono',
    iconCurrent: 'icon-contract-signed-vinci-bg',
    title: LABEL_ACTIVITY_STEP_SIGNED_VINCI,
    dateField: 'field_datecontratsigne_vinci_ac',
  },
];

export const ACTIVITY_STEPS_BOOKINGS_SALES: {
  icon: string;
  iconCurrent?: string;
  title: string;
  dateField?: ActivityDateKey;
}[] = [
  {
    icon: 'icon-contract-signed-vinci-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_1,
    dateField: 'field_datecontratsigne_vinci_ac',
  },
  {
    icon: 'icon-sru-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_2,
    dateField: 'field_dateenvoi_courrier_sru_ac',
  },
  {
    icon: 'icon-receipt-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_3,
  },
  {
    icon: 'icon-receipt-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_4,
  },
  {
    icon: 'icon-transmission-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_5,
    dateField: 'field_dateoffre_pret_ac',
  },
  {
    icon: 'icon-contract-signed-mono',
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_6,
    dateField: 'field_dateacte_reel_ac',
  },
];

interface ActivityStepsListType {
  activity: Activity;
  className?: string;
}

export default function ActivityStepsList({
  activity,
  className = undefined,
}: ActivityStepsListType) {
  const isBookingsOrSales =
    activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
    activity.field_type_ac === ACTIVITY_TYPE_SALE;
  const activeStep = isBookingsOrSales
    ? getBookingSaleActivityStep(activity)
    : getActivityStep(activity);

  const steps = isBookingsOrSales ? ACTIVITY_STEPS_BOOKINGS_SALES : ACTIVITY_STEPS;

  return (
    <ol className={classnames(className, styles.list)}>
      {steps.map(({ dateField, icon, iconCurrent, title }, index) => {
        let bgColor;

        if (isBookingsOrSales) {
          bgColor = ACTIVITY_STEP_COLOR_FUTURE;
          if (activeStep === 0 && index === 0) {
            bgColor = ACTIVITY_STEP_COLOR_CURRENT;
          }
          if (activeStep > 0 && index < activeStep) {
            bgColor = ACTIVITY_STEP_COLOR_PAST;
          }
        }
        if (!isBookingsOrSales) {
          bgColor = ACTIVITY_STEP_COLOR_PAST;
          if (index === activeStep) {
            bgColor = ACTIVITY_STEP_COLOR_CURRENT;
          } else if (index > activeStep) {
            bgColor = ACTIVITY_STEP_COLOR_FUTURE;
          }
        }

        return (
          <li
            key={title}
            className={classnames(styles.item, {
              [styles.futureItem]: isBookingsOrSales && index !== 0 && index >= activeStep,
              [styles.currentItem]:
                (isBookingsOrSales && activeStep === 0 && index === 0) ||
                (!isBookingsOrSales && index === activeStep),
            })}
          >
            <Pastille
              className={styles.itemTag}
              label={replaceTokens(LABEL_ACTIVITY_STEP, { [TOKEN_NB]: index + 1 })}
              bgColor={bgColor}
              textColor="#fff"
            />

            <SvgIcon
              className={styles.itemIcon}
              iconId={index === activeStep ? iconCurrent || icon : icon}
            />

            <span className={styles.itemTitle}>
              {title.split('\n').map((bit, i) => (
                <>
                  {i > 0 && <br />}
                  {bit}
                </>
              ))}
            </span>

            {index < activeStep && dateField && activity[dateField] && (
              <span className={styles.itemDate}>
                {formatActivityDate(activity[dateField], 'DD/MM/YYYY')}
              </span>
            )}
          </li>
        );
      })}
    </ol>
  );
}
