import React from 'react';

import { ParagraphFreeStyleType } from 'api/vi3pAPI/apiTypes/ContentType';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

export default function ParagraphFreestyle({ paragraph }: ParagraphFreestyle) {
  const { riche_texte: content } = paragraph;

  return <FieldRte html={content} />;
}

type ParagraphFreestyle = {
  paragraph: ParagraphFreeStyleType;
};
