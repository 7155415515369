import React, { useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

import { replaceTokens } from 'services/formatter';
import { LABEL_ACTIVITY_STEP } from 'settings/labels';
import { TOKEN_NB } from 'settings/token';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ActivityStepsSummary.module.scss';

export default function ActivityStepsSummary({
  className = undefined,
  defaultOpen = false,
  steps,
  stepsShares,
  title,
}: ActivityStepsSummaryType) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  function toggleIsOpen() {
    setIsOpen(prev => !prev);
  }

  return (
    <div className={className}>
      <MuiAccordion
        classes={{ root: styles.root, rounded: styles.rounded }}
        expanded={isOpen}
        onChange={toggleIsOpen}
      >
        <MuiAccordionSummary
          expandIcon={<SvgIcon className={styles.expander} iconId="icon-chevron-down" />}
          id="steps-summary-header"
          aria-controls="steps-summary-content"
          classes={{
            content: styles.accordionSummaryContent,
            expandIcon: styles.accordionSummaryexpandIcon,
            root: styles.accordionSummaryRoot,
          }}
        >
          <span className={styles.headerIcon}>i</span>
          <span>{title}</span>
        </MuiAccordionSummary>

        <MuiAccordionDetails classes={{ root: styles.accordionDetailsRoot }}>
          <div>
            <ol className={styles.steps}>
              {steps.map(({ icons, title }, i) => (
                <li key={title} className={styles.step}>
                  <div className={styles.stepNumber}>
                    {replaceTokens(LABEL_ACTIVITY_STEP, { [TOKEN_NB]: i + 1 })}
                  </div>

                  <div className={styles.stepIcons}>
                    {icons.map(iconId => (
                      <SvgIcon key={iconId} className={styles.stepIcon} iconId={iconId} />
                    ))}
                  </div>

                  <div className={styles.stepTitle}>{title}</div>
                </li>
              ))}
            </ol>

            <div className={styles.shares}>
              {stepsShares.map(stepsShare => (
                <div
                  className={styles.share}
                  style={{ width: `${(stepsShare.nbSteps / steps.length) * 100}%` }}
                >
                  <span className={styles.shareLabel}>{stepsShare.label}</span>
                </div>
              ))}
            </div>
          </div>
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
}

export type ActivityStepsSummaryType = {
  className?: string;
  defaultOpen?: boolean;
  steps: {
    icons: string[];
    title: string;
  }[];
  stepsShares: {
    label: string;
    nbSteps: number;
  }[];
  title: string;
};
