import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './PdfBottomContentStyles';

export default function PdfBottomContent({ text }: PdfBottomContentProps) {
  return (
    <View style={styles.root}>
      <View style={[styles.col]}>{text && <Text>{text}</Text>}</View>
    </View>
  );
}

export type PdfBottomContentProps = {
  text: string;
};
