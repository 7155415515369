import React, { useCallback, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import useSWR from 'swr';
import memoize from 'memoizee';
import classnames from 'classnames';

import { LOT_JSON_NUMBER, LOT_JSON_PRICE_INCLUDING_TAX, LOT_JSON_PROGRAM_REF } from 'settings/lots';

import programLotContext from 'modules/App/Contexts/programLotContext';

import { formatPrice } from 'services/formatter';

import { getLot, getProgramByRef } from 'api/viOffresAPI/apiClient';

import styles from './ActualityDetailLots.module.scss';

interface Lot {
  number: string;
  programRef: string;
  priceIncludingTax: number | string | null;
  program: {
    city: string;
    name: string;
    postalCode: string;
  };
}

interface ActualityDetailLotsProps {
  className?: string;
  /** Should be in the form `[programRef,LotNumber];[programRef,lotNumber]` */
  lots: string | undefined;
}

export default function ActualityDetailLots({
  className,
  lots: lotsIds,
}: ActualityDetailLotsProps) {
  const { lots, programs } = useContext(programLotContext);

  const getProgramData = useCallback<(programRef: string) => Promise<Lot['program']>>(
    memoize(async programRef => {
      // Look for the program in the Programs Export first and if not found, fetch it from API
      const program = programs.find(
        program => program.ref.toLowerCase() === programRef.toLowerCase()
      );

      if (program) {
        return {
          city: program.city,
          name: program.name,
          postalCode: program.postalCode,
        };
      }

      const programData = await getProgramByRef(programRef);
      return {
        city: programData.localisation.ville,
        name: programData.nomCommercial,
        postalCode: programData.localisation.codePostal.toString(),
      };
    }),
    [programs]
  );
  const getLotData = useCallback<
    (
      programRef: string,
      lotNumber: string
    ) => Promise<Pick<Lot, 'number' | 'programRef' | 'priceIncludingTax'>>
  >(
    memoize(async (programRef, lotNumber) => {
      // Look for the lot in the Lots Export first and if not found, fetch it from API
      const lot = lots.find(
        lot =>
          lot[LOT_JSON_PROGRAM_REF].toLowerCase() === programRef.toLowerCase() &&
          lot[LOT_JSON_NUMBER].toLowerCase() === lotNumber.toLowerCase()
      );

      if (lot) {
        return {
          number: lot[LOT_JSON_NUMBER],
          programRef: lot[LOT_JSON_PROGRAM_REF],
          priceIncludingTax: lot[LOT_JSON_PRICE_INCLUDING_TAX],
        };
      }

      const lotData = await getLot(programRef, lotNumber);
      return {
        number: lotData.reference,
        programRef: lotData.referenceProgramme,
        priceIncludingTax: lotData.prix.TVANormale.prixTTC,
      };
    }),
    [lots]
  );
  const { data } = useSWR<Lot[], unknown, [string, string] | undefined>(
    lotsIds && lots && programs ? ['actualityLots', lotsIds] : undefined,
    async ([_, lotsIds]) => {
      if (!lots || !programs) {
        return [];
      }
      const parsedIds = lotsIds
        .replace(/[[\]\s]/g, '')
        .split(';')
        .map(str => str.split(',') as [string, string]);

      return Promise.allSettled(
        parsedIds.map(async ([programRef, lotNumber]) => {
          const programData = await getProgramData(programRef);
          const lotData = await getLotData(programRef, lotNumber);
          return { ...lotData, program: programData };
        })
      ).then(promises =>
        promises.reduce(
          (arr, promise) => (promise.status === 'fulfilled' ? [...arr, promise.value] : arr),
          []
        )
      );
    }
  );

  if (!data?.length) {
    return (
      <div className={classnames(className, styles.root, styles.loading)}>
        <MuiCircularProgress variant="indeterminate" size={24} />
      </div>
    );
  }

  return (
    <table className={classnames(className, styles.root)}>
      <thead>
        <tr>
          <th>Dpt - ville</th>
          <th>N° Lot</th>
          <th>Prix immo. TTC</th>
        </tr>
      </thead>
      <tbody>
        {data.map(lot => (
          <tr key={`${lot.programRef}-${lot.number}`}>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {lot.program.name} - {lot.program.postalCode} {lot.program.city}
              </NavLink>
            </td>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {lot.number}
              </NavLink>
            </td>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {formatPrice(lot.priceIncludingTax ?? undefined)}
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
