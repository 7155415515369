import React from 'react';
import ReactDOM from 'react-dom';

import { LOADING_MODAL_ID } from 'settings/modal';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import Modal from './Modal';

export default function ModalLoader({ closeCallback, showModal }: ModalLoaderProps) {
  const elem = document.getElementById(LOADING_MODAL_ID);
  return (
    <>
      {elem &&
        ReactDOM.createPortal(
          <Modal
            showModal={showModal}
            closeCallback={closeCallback}
            type="fullscreen"
            className="modal-desktop"
          >
            <div className="modal-loader">
              <SpinLoader width={100} height={100} />
            </div>
          </Modal>,
          elem
        )}
    </>
  );
}

export type ModalLoaderProps = {
  closeCallback: () => void;
  showModal: boolean;
};
