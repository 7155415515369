import React from 'react';

import { LABEL_NEWS_TITLE, LABEL_NEW_PROGRAMS_TITLE, LABEL_OPPORTUNITIES } from 'settings/labels';

import type { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';
import type Housing from 'api/viOffresAPI/apiTypes/Housing';

import { useSWROffres } from 'api/offresAPI/useSWROffres';
import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import HighlightSlider from 'modules/Actuality/components/HighlightSlider/HighlightSlider';
import NewsSlider from 'modules/Actuality/components/NewsSlider/NewsSlider';
import UpcomingSlider from 'modules/Actuality/components/UpcomingSlider/UpcomingSlider';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import StandardPage from 'layout/components/StandardPage/StandardPage';

function ActualityPage() {
  const { data: opportunities } = useSWROffres<Housing[]>({ url: '/opportunites' });
  const { data: newPrograms } = useSWROffres<Housing[]>({ url: '/nouvelles' });
  const { data: upcoming } = useSWROffres<Housing[]>({ url: '/prochainement' });
  const { data: news } = useSWRVi3p<ActualityType[]>({ url: `actualites` });

  return (
    <StandardPage title="Actualités">
      <HighlightSlider title={LABEL_OPPORTUNITIES} content={opportunities} />
      <HighlightSlider dark title={LABEL_NEW_PROGRAMS_TITLE} content={newPrograms} />
      <UpcomingSlider content={upcoming} />
      <NewsSlider title={LABEL_NEWS_TITLE} id="actualites" content={news} />

      <TagCommander navigation_pagename="actualites" navigation_template="actualites" />
    </StandardPage>
  );
}

export default ActualityPage;
