import React, { Component } from 'react';
import type { ComponentProps, ErrorInfo } from 'react';
import type { Route } from 'react-router-dom';

import { error } from 'services';

import ErrorPage from 'layout/components/ErrorPage/ErrorPage';

interface ErrorBoundaryProps {
  location: ComponentProps<typeof Route>['location'];
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    errorMessage: '',
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error.message ? error.message : error };
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { location } = this.props;
    if (prevProps.location?.pathname !== location?.pathname) {
      this.setState({ hasError: false, errorMessage: '' });
    }
  }

  componentDidCatch(e: Error, errorInfo: ErrorInfo) {
    error(e, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { errorMessage, hasError } = this.state;

    if (hasError) {
      return <ErrorPage errorMessage={errorMessage} />;
    }

    return children;
  }
}
