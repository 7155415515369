import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  banner: {
    display: 'flex',
    flexDirection: 'row',
  },
  bannerCol: {
    padding: '0 12px',
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  totem: {
    margin: '10px 0 0 0',
    width: '100%',
  },
  name: {
    color: COLORS.GREYS.PURE_BLACK,
    fontSize: '24px',
    fontWeight: 'medium',
    lineHeight: 1,
    margin: '0 0 5px 0',
  },
  address: {
    color: COLORS.GREYS.PURE_BLACK,
    fontSize: '10px',
    lineHeight: 1,
    textTransform: 'uppercase',
  },
});
