import React from 'react';
import classnames from 'classnames';

import styles from './LotTable.module.scss';

function LotTableContainer({ scrollable = true, ...props }) {
  return (
    <div
      id="lot-table-container"
      className={classnames(styles.listLotContainer, {
        [styles.fullListLot]: scrollable,
      })}
      {...props}
    />
  );
}

export default LotTableContainer;
