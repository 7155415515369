export const APP_URL_PENDING = '/pending';

export const FLOORS = {
  '6 ème sous sol': '6 ème sous sol',
  '5 ème sous sol': '5 ème sous sol',
  '4 ème sous sol': '4 ème sous sol',
  '3 ème sous sol': '3 ème sous sol',
  '2 ème sous sol': '2 ème sous sol',
  '1er sous sol': '1er sous sol',
  'Sous sol': 'Sous sol',
  'Rez de chaussée': 'RDC',
  Entresol: 'Entresol',
  'Rez de jardin': 'RDJ',
  'Rez de dalle': 'Rez de dalle',
  '1 er étage': '1',
  '1/2 ème étage': '1/2',
  '2 ème étage': '2',
  '2/3 ème étage': '2/3',
  '3 ème étage': '3',
  '3/4 ème étage': '3/4',
  '4 ème étage': '4',
  '4/5 ème étage': '4/5',
  '5 ème étage': '5',
  '5/6 ème étage': '5/6',
  '6 ème étage': '6',
  '6/7 ème étage': '6/7',
  '7 ème étage': '7',
  '7/8 ème étage': '7/8',
  '8 ème étage': '8',
  '8/9 ème étage': '8/9',
  '9 ème étage': '9',
  '9/10 ème étage': '9/10',
  '10 ème étage': '10',
  '10/11 ème étage': '10/11',
  '11 ème étage': '11',
  '12 ème étage': '12',
  '13 ème étage': '13',
  '14 ème étage': '14',
  '15 ème étage': '15',
  '16 ème étage': '16',
  '17 ème étage': '17',
  '18 ème étage': '18',
  '19 ème étage': '19',
  '20 ème étage': '20',
  Combles: 'Combles',
} as const;

export const FILE_SIZE_UNITS = ['o', 'Ko', 'Mo', 'Go', 'To'] as const;

export const PAGE_TYPE_ADB = 'page_adb';
export const PAGE_TYPE_KITCHEN = 'cuisine_equipee';
export const PAGE_TYPE_SEO = 'page_seo';

export const CONTRACT_KITCHEN_INCLUDED = 'Inclus';
export const CONTRACT_KITCHEN_SELECTED = 'En option payante';
export const CONTRACT_KITCHEN_NOT_SELECTED = 'Sans cuisine';

export const PARKING_TYPE = 'Parking';
