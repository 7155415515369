export const APPLE_MAP_DIR_URL = 'https://maps.apple.com/';

export const GMAP_BASE_URL = 'https://maps.googleapis.com/maps/api';
export const GMAP_DIR_URL = 'https://www.google.com/maps/dir/';

export const GMAP_ANGULAR_HEIGHT_CITY = 0.15;
export const GMAP_ANGULAR_WIDTH_CITY = 0.2;
export const GMAP_ANGULAR_HEIGHT_DEPARTMENT = 0.25;
export const GMAP_ANGULAR_WIDTH_DEPARTMENT = 0.3;

export const REGION_VIEWPORT: Record<
  string,
  { north: number; east: number; south: number; west: number }
> = {
  // List of best map viewports for each metropolitan French region.
  11: {
    // Île-de-France
    north: 49.29,
    east: 3.66,
    south: 48.07,
    west: 1.31,
  },
  24: {
    // Centre-Val de Loire
    north: 49.04,
    east: 3.25,
    south: 46.28,
    west: -0.15,
  },
  27: {
    // Bourgogne-Franche-Comté
    north: 48.5,
    east: 7.25,
    south: 46.11,
    west: 2.68,
  },
  28: {
    // Normandie
    north: 50.05,
    east: 1.85,
    south: 48.1,
    west: -2.02,
  },
  32: {
    // Hauts-de-France
    north: 51.23,
    east: 4.76,
    south: 48.68,
    west: 1.11,
  },
  44: {
    // Grand Est
    north: 50.08,
    east: 8.38,
    south: 47.35,
    west: 3.19,
  },
  52: {
    // Pays de la Loire
    north: 48.59,
    east: 1.08,
    south: 46.19,
    west: -2.73,
  },
  53: {
    // Bretagne
    north: 48.94,
    east: -0.98,
    south: 47.42,
    west: -5.19,
  },
  75: {
    // Nouvelle-Aquitaine
    north: 47.25,
    east: 2.78,
    south: 42.67,
    west: -1.94,
  },
  76: {
    // Occitanie
    north: 45.09,
    east: 4.87,
    south: 42.23,
    west: -0.47,
  },
  84: {
    // Auvergne-Rhône-Alpes
    north: 46.91,
    east: 7.36,
    south: 44.05,
    west: 1.94,
  },
  93: {
    // Provence-Alpes-Côte d'Azur
    north: 45.19,
    east: 7.81,
    south: 42.91,
    west: 4.16,
  },
  94: {
    // Corse
    north: 43.05,
    east: 9.65,
    south: 41.3,
    west: 8.49,
  },
};
