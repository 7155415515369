import {
  APPLE_MAP_DIR_URL,
  GMAP_ANGULAR_HEIGHT_CITY,
  GMAP_ANGULAR_WIDTH_CITY,
  GMAP_DIR_URL,
} from 'settings/map';
import { EXTENDED_SEARCH_MAX_LNG, EXTENDED_SEARCH_STEP } from 'settings/search';

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

interface Coordinates {
  lat: number;
  lng: number;
}
export function getDistanceInKm(coordinates1: Coordinates, coordinates2: Coordinates) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(coordinates2.lat - coordinates1.lat);
  const dLng = degreesToRadians(coordinates2.lng - coordinates1.lng);

  const radLat1 = degreesToRadians(coordinates1.lat);
  const radLat2 = degreesToRadians(coordinates2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(radLat1) * Math.cos(radLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.abs(earthRadiusKm * c);
}

let geocoder: google.maps.Geocoder;

export function isGeocoderReady() {
  return !!geocoder;
}

export function geocode(address: string) {
  if (!geocoder) {
    geocoder = new window.google.maps.Geocoder();
  }
  return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
    geocoder.geocode({ address, region: 'FR' }, (results, status) =>
      status === 'OK' ? resolve(results[0]) : reject()
    );
  });
}

export function getProgramsInViewport(north, south, east, west, programs) {
  if (!programs || !north || !south || !east || !west) {
    return null;
  }
  return programs.filter(({ lat, lng }) => lat < north && lat > south && lng < east && lng > west);
}

export function getExtendedViewport(viewport, programs, extended = false) {
  if (!programs || programs.length === 0 || !viewport) {
    return { extended, viewport };
  }

  const { east, north, south, west } = viewport;
  const viewportPrograms = getProgramsInViewport(north, south, east, west, programs);
  if (viewportPrograms.length > 0 || (viewport && east - west > EXTENDED_SEARCH_MAX_LNG)) {
    return { extended, viewport };
  }

  return getExtendedViewport(
    {
      east: east + EXTENDED_SEARCH_STEP,
      north: north + EXTENDED_SEARCH_STEP,
      south: south - EXTENDED_SEARCH_STEP,
      west: west - EXTENDED_SEARCH_STEP,
    },
    programs,
    true
  );
}

// The default value given to the angular width & height should be able to more or less cover a
// typically bigger city like Lyon, Paris or Marseille
export function getSearchViewportFromPoint(
  coordinates: Coordinates,
  angularWidth = GMAP_ANGULAR_WIDTH_CITY,
  angularHeight = GMAP_ANGULAR_HEIGHT_CITY
) {
  return {
    north: coordinates.lat + angularHeight / 2,
    south: coordinates.lat - angularHeight / 2,
    east: coordinates.lng + angularWidth / 2,
    west: coordinates.lng - angularWidth / 2,
  };
}

export function getMapDirectionProps(lat, lng, address, isDesktop, isMobileApple) {
  if (isDesktop) {
    return {
      href: `${GMAP_DIR_URL}?api=1&destination=${lat},${lng}`,
      rel: 'noopener noreferrer',
      target: '_blank',
    };
  }

  // https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
  if (isMobileApple) {
    return {
      href: `${APPLE_MAP_DIR_URL}?q=${lat},${lng}&address=${encodeURIComponent(address)}`,
      rel: 'noopener noreferrer',
      target: '_blank',
    };
  }

  // https://developers.google.com/maps/documentation/urls/android-intents#search_for_a_location
  return {
    href: `geo:${lat},${lng}?q=${encodeURIComponent(address)}`,
  };
}
