const DEBOUNCE_WAIT_DEFAULT = 100;

/**
 * Enforces that a function is only called after a certain amount of time has passed without it being called
 * @param {Function} func - The function to debounce
 * @param {Number} wait - The time to wait before calling the function
 * @param {Boolean} (immediate) - Whether the function should be called before or after the wait time
 * @returns {Function} - The debounced function
 */
export default function debounce(func, wait = DEBOUNCE_WAIT_DEFAULT, immediate) {
  let timeout;
  // eslint-disable-next-line func-names
  return function (...args) {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (immediate && !timeout) func.apply(this, args);
  };
}
