import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  section: {
    padding: '30px 12px 5px',
  },
  sectionPicto: {
    margin: '0 10px 0 0',
    width: '28px',
  },
  sectionTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  sectionTitleText: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '16px',
    fontWeight: 'medium',
  },
  description: {
    width: '16px',
  },
  localisation: {
    width: '12px',
  },
  plan: {
    width: '16px',
  },
  price: {
    width: '14px',
  },
});
