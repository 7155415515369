import React from 'react';
import classnames from 'classnames';

const iconClass = 'icon';

export default function Icon({ className, icon, title, ...htmlProps }: IconType) {
  return (
    <i
      className={classnames(className, iconClass, `${iconClass}--${icon}`)}
      title={title}
      {...htmlProps}
    />
  );
}

export type Icons =
  | 'add-to-folder'
  | 'arrow-left'
  | 'booklet'
  | 'calendar'
  | 'call'
  | 'cancel'
  | 'checked'
  | 'check-circle-bg'
  | 'check-circle-vi3p'
  | 'chevron-down'
  | 'compare'
  | 'contract-signed'
  | 'contract-signed-vinci'
  | 'criterias'
  | 'cross'
  | 'cross-bold'
  | 'description'
  | 'digital-signing'
  | 'display'
  | 'download'
  | 'download-documents'
  | 'edit'
  | 'euro'
  | 'failed'
  | 'folder'
  | 'generate-contract'
  | 'hide'
  | 'home'
  | 'house'
  | 'idea'
  | 'interlocutor'
  | 'itineraire'
  | 'kitchen'
  | 'kitchen-pack'
  | 'label'
  | 'load'
  | 'location'
  | 'lock'
  | 'lot-card'
  | 'mail'
  | 'map-plan'
  | 'marker'
  | 'marker-home'
  | 'marker-light'
  | 'minus'
  | 'my-folders'
  | 'my-research'
  | 'more'
  | 'notifications'
  | 'option'
  | 'pdf'
  | 'percent'
  | 'plans'
  | 'plus-vi3p'
  | 'power-off'
  | 'pre-book'
  | 'price-euro'
  | 'program-card'
  | 'rectangle'
  | 'rent'
  | 'reservation-contract'
  | 'reset'
  | 'route'
  | 'save'
  | 'search'
  | 'see'
  | 'send'
  | 'send-demand'
  | 'send-documents'
  | 'share'
  | 'show'
  | 'trash'
  | 'user'
  | 'user-short'
  | 'wallet';

export type IconType = {
  className?: string;
  icon: Icons;
  title?: string;
};
