import React from 'react';
import type { HTMLAttributes } from 'react';
import classNames from 'classnames';

import icons from 'assets/styles/sprite/symbol-defs.svg';
import styles from './SvgIcon.module.scss';

interface SvgIconProps extends HTMLAttributes<SVGElement> {
  iconId: string;
}

export default function SvgIcon({ className, iconId, ...restProps }: SvgIconProps) {
  return (
    <svg className={classNames(styles.svgIcon, className)} {...restProps}>
      <use xlinkHref={`${icons}#${iconId}`} />
    </svg>
  );
}
