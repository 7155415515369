import { Dispatch, SetStateAction, createContext } from 'react';

export const tmsContextDefault = {
  componentPageVars: {} as Record<string, any>,
  eventPageVars: {} as Record<string, any>,
  pageVars: {} as Record<string, any>,
  setComponentPageVars: (() => {}) as Dispatch<SetStateAction<Record<string, any>>>,
  setEventPageVars: (() => {}) as Dispatch<SetStateAction<Record<string, any>>>,
  setPageVars: (() => {}) as Dispatch<SetStateAction<Record<string, any>>>,
  tmsIsReady: false,
};

export const TmsContext = createContext(tmsContextDefault);
