import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import { LABEL_PROGRAM_404 } from 'settings/labels';
import { TOKEN_REF } from 'settings/token';

import { replaceTokens } from 'services/formatter';
import { programIsManaged } from 'services/programs';

import useAttributionProgram from 'hooks/useAttributionProgram';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import ErrorPage from 'layout/components/ErrorPage/ErrorPage';
import Assistance from 'modules/Assistance/components/AssistanceBlock/Assistance';

import ProgramContent from '../ProgramContent/ProgramContent';

import styles from './Program.module.scss';

const MANAGED_PROGRAM_MAIL = 'backofficemeuble@vinci-immobilier.com';

export default function Program({ match }: RouteComponentProps<{ programRef: string }>) {
  const { program, error } = useAttributionProgram(match.params.programRef);

  if (error) {
    return (
      <ErrorPage
        errorMessage={replaceTokens(LABEL_PROGRAM_404, { [TOKEN_REF]: match.params.programRef })}
      />
    );
  }

  if (!program) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <>
      <ProgramContent program={program} />
      <Assistance
        showLinkedContacts
        defaultMail={programIsManaged(program) ? MANAGED_PROGRAM_MAIL : undefined}
      />
      <TagCommander
        navigation_pagename="programme"
        navigation_template={`programme.${match.params.programRef}`}
      />
    </>
  );
}
