import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import { stringify } from 'query-string';

import { regexEmail } from 'services/constraints';
import { buildCriteriasFromSearch } from 'services/filters';
import { programIsPreview } from 'services/programs';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_SAVE_SEARCH,
  LABEL_CONFIRM_SAVE_SEARCH_ERROR,
  LABEL_CONFIRM_SAVE_SEARCH_MODIFICATION,
  LABEL_MY_SEARCH,
  LABEL_MY_SEARCH_MAIL,
  LABEL_MY_SEARCH_MAIL_ERROR,
  LABEL_MY_SEARCH_NAME,
  LABEL_MY_SEARCH_NAME_ERROR,
  LABEL_MY_SEARCH_NAME_ERROR_LENGTH,
  LABEL_SAVE_MY_SEARCH,
  LABEL_SAVE_SEARCH_ALERT_FIELD_LABEL,
} from 'settings/labels';
import { SAVE_SEARCH_FORM_MODAL_ID } from 'settings/modal';
import { LOT_STATUS_FREE } from 'settings/lots';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import {
  PatchSavedSearchType,
  PostSavedSearchType,
  SavedSearchType,
} from 'api/vi3pAPI/apiTypes/SavedSearch';
import { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { modifyQuery } from 'services/url';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Button from 'commonUi/Button/Button';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import TextField from 'commonUi/TextField/TextField';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';

import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import useSearchFiltersAsString from '../../hooks/useSearchFiltersAsString';

import styles from './ModalFormSaveSearch.module.scss';

const FIELD_SEARCH_ALERT = 'field-search-alert';
const FIELD_SEARCH_NAME = 'field-search-name';
const FIELD_SEARCH_EMAIL = 'field-search-email';

export interface ModalFormSaveSearchProps {
  closeCallBack: () => void;
  lots: LotJson[];
  open: boolean;
  programs?: ProgramListType[];
  savedSearch: SavedSearchType | undefined;
  setSearchSaved: (searchSaved: boolean) => void;
  setShowPopin: (showPopin: boolean) => void;
}

export default function ModalFormSaveSearch({
  closeCallBack,
  lots = [],
  open,
  programs,
  savedSearch,
  setSearchSaved,
  setShowPopin,
}: ModalFormSaveSearchProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { annexes: annexOptions } = useContext(TaxonomiesContext);
  const search = useSearch();
  const [loading, setLoading] = useState<boolean>(false);

  const navigationPagename = search?.searchId
    ? 'mesrecherches_modifier.enregistrer'
    : 'resultats_enregistrerrecherche';
  const [tms, setTms] = useState(
    search?.searchId
      ? {
          navigation_pagename: navigationPagename,
          navigation_template: 'mesrecherches',
        }
      : {
          navigation_pagename: navigationPagename,
          navigation_template: 'recherche',
        }
  );
  const { activityUrls, userCrm } = useContext(userContext);

  const filtersAsString = useSearchFiltersAsString(search);

  const { control, formState, getValues, handleSubmit, watch } = useForm({
    resolver: yupResolver(saveSearchSchema),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;
  const defaultValues = {
    [FIELD_SEARCH_ALERT]: savedSearch?.alerte || true,
    [FIELD_SEARCH_NAME]: savedSearch?.titre || LABEL_MY_SEARCH,
    [FIELD_SEARCH_EMAIL]: savedSearch?.email || userCrm?.email,
  };
  const alertValue = watch(FIELD_SEARCH_ALERT);
  const withAlert = alertValue !== undefined ? alertValue : defaultValues[FIELD_SEARCH_ALERT];

  const programsNids =
    !search.nearProgram && programs
      ? programs.filter(p => !programIsPreview(p)).map(p => Number(p.nid))
      : [];
  const [freeLotsNids, nonFreeLotsNids] = !search.nearProgram
    ? lots
        .filter(lot => !programIsPreview(lot.program))
        .reduce<[number[], number[]]>(
          ([freeLots, nonFreeLots], lot) => {
            if (lot.status === LOT_STATUS_FREE) {
              return [[...freeLots, Number(lot.nid)], nonFreeLots];
            }
            return [freeLots, [...nonFreeLots, Number(lot.nid)]];
          },
          [[], []]
        )
    : [[], []];

  async function postSearch(searchName: string, searchAlert: boolean, searchEmail: string) {
    const removeQuery = ['panelMySearch', 'searchId'];
    if (programsNids.length === 0 && freeLotsNids.length === 0 && nonFreeLotsNids.length === 0) {
      removeQuery.push('nearProgram');
    }
    const newSearch: PostSavedSearchType = {
      titre: searchName,
      url: `recherche${modifyQuery({}, removeQuery)}`,
      criteres: `?${stringify(buildCriteriasFromSearch(search, annexOptions), {
        encode: false,
        arrayFormat: 'bracket',
      })}`,
      email: searchEmail,
      filtres: filtersAsString,
      alerte: searchAlert,
      resultats: {
        programmes: programsNids,
        lotsLibres: freeLotsNids,
        lotsNonLibres: nonFreeLotsNids,
      },
    };

    return axiosVI3PInstance.post(
      `profils/${userCrm?.extension_VI3P_ContactId}/recherches`,
      newSearch
    );
  }

  async function patchSearch(
    searchName: string,
    searchAlert: boolean,
    searchEmail: string,
    savedSearch: SavedSearchType
  ) {
    const removeQuery = ['panelMySearch', 'searchId'];
    if (programsNids.length === 0 && freeLotsNids.length === 0 && nonFreeLotsNids.length === 0) {
      removeQuery.push('nearProgram');
    }
    const newUrl = `recherche${modifyQuery({}, removeQuery)}`;
    const newCriteres = `?${stringify(buildCriteriasFromSearch(search, annexOptions), {
      encode: false,
      arrayFormat: 'bracket',
    })}`;
    const newResultats = {
      programmes: programsNids,
      lotsLibres: freeLotsNids,
      lotsNonLibres: nonFreeLotsNids,
    };
    const newSearch: PatchSavedSearchType = {
      id: savedSearch.id,
      ...(savedSearch.titre !== searchName
        ? {
            titre: searchName,
          }
        : {}),
      ...(savedSearch.url !== newUrl
        ? {
            url: newUrl,
          }
        : {}),
      ...(savedSearch.criteres !== newCriteres
        ? {
            criteres: newCriteres,
          }
        : {}),
      ...(savedSearch.filtres !== filtersAsString
        ? {
            filtres: filtersAsString,
          }
        : {}),
      ...(savedSearch.alerte !== searchAlert
        ? {
            alerte: searchAlert,
          }
        : {}),
      ...(savedSearch.email !== searchEmail
        ? {
            email: searchEmail,
          }
        : {}),
      resultats: newResultats,
    };

    return axiosVI3PInstance.patch(
      `profils/${userCrm?.extension_VI3P_ContactId}/recherches`,
      newSearch
    );
  }

  async function saveSearch() {
    setLoading(true);
    const {
      [FIELD_SEARCH_ALERT]: searchAlert,
      [FIELD_SEARCH_EMAIL]: searchEmail,
      [FIELD_SEARCH_NAME]: searchName,
    } = getValues();

    try {
      const response = search?.searchId
        ? await patchSearch(
            searchName,
            searchAlert,
            searchEmail,
            savedSearch as NonNullable<typeof savedSearch>
          )
        : await postSearch(searchName, searchAlert, searchEmail);

      mutate(activityUrls.savedSearch, response.data, false);

      if (search?.searchId) {
        setShowPopin(false);
      } else {
        setShowPopin(true);
      }
      setSearchSaved(true);
      enqueueSnackbar(
        search?.searchId ? LABEL_CONFIRM_SAVE_SEARCH_MODIFICATION : LABEL_CONFIRM_SAVE_SEARCH
      );

      if (search?.searchId) {
        history.replace(modifyQuery({ panelMySearch: 'open' }, ['searchId']));
      }

      closeCallBack();
    } catch (newError) {
      if (newError) {
        enqueueSnackbar(LABEL_CONFIRM_SAVE_SEARCH_ERROR, {
          content: (key, message) => (
            <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
          ),
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    setTms({
      ...tms,
      ...{
        navigation_pagename: loading ? `${navigationPagename}.confirmation` : navigationPagename,
      },
    });
  }, [loading]);

  return (
    <div id={SAVE_SEARCH_FORM_MODAL_ID} className={classNames('show', [styles.show])}>
      <MuiDialog
        open={open}
        onClose={closeCallBack}
        classes={{
          paper: styles.modalPaper,
          root: styles.modalRoot,
        }}
        BackdropProps={{ style: { backgroundColor: 'rgba(5, 34, 65, 0.6)' } }}
      >
        <IconButton classes={{ root: styles.modalClose }} disableRipple onClick={closeCallBack}>
          <Icon className={styles.svgIcon} icon="cross" />
        </IconButton>

        <div className={styles.modalTitle}>{LABEL_SAVE_MY_SEARCH}</div>

        <div className={styles.modalContent}>
          <div className={styles.filters}>{filtersAsString}</div>
          <form onSubmit={handleSubmit(saveSearch)}>
            <div className={styles.formField}>
              <Controller
                name={FIELD_SEARCH_NAME}
                control={control}
                defaultValue={defaultValues[FIELD_SEARCH_NAME]}
                render={props => (
                  <TextField
                    error={!!errors?.[FIELD_SEARCH_NAME]}
                    helperText={errors?.[FIELD_SEARCH_NAME]?.message}
                    label={LABEL_MY_SEARCH_NAME}
                    onChange={ev => props?.field.onChange(ev.target.value)}
                    required
                    value={props?.field.value}
                  />
                )}
              />
            </div>
            <div className={styles.formField}>
              <Controller
                name={FIELD_SEARCH_ALERT}
                control={control}
                defaultValue={defaultValues[FIELD_SEARCH_ALERT]}
                render={props => (
                  <Checkbox
                    checked={props.field.value}
                    handleChange={ev => {
                      setTms({
                        ...tms,
                        ...{
                          navigation_pagename: ev.target.checked
                            ? 'resultats_enregistrerrecherche_alerte.activer'
                            : 'resultats_enregistrerrecherche_alerte.desactiver',
                        },
                      });
                      props?.field.onChange(ev.target.checked);
                    }}
                    color="primary"
                    classes={{
                      root: styles.formFieldCheckbox,
                      label: styles.formFieldCheckboxLabel,
                    }}
                    label={LABEL_SAVE_SEARCH_ALERT_FIELD_LABEL}
                  />
                )}
              />
            </div>
            {withAlert && (
              <div className={styles.formField}>
                <Controller
                  name={FIELD_SEARCH_EMAIL}
                  control={control}
                  defaultValue={defaultValues[FIELD_SEARCH_EMAIL]}
                  render={props => (
                    <TextField
                      error={!!errors?.[FIELD_SEARCH_EMAIL]}
                      helperText={errors?.[FIELD_SEARCH_EMAIL]?.message}
                      label={LABEL_MY_SEARCH_MAIL}
                      onChange={ev => props?.field.onChange(ev.target.value)}
                      required
                      value={props?.field.value}
                    />
                  )}
                />
              </div>
            )}
            <div className={styles.formButtons}>
              <div className={styles.formButtonsItem}>
                <Button
                  className={styles.button}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={closeCallBack}
                >
                  {LABEL_CANCEL_BUTTON}
                </Button>
              </div>
              <div className={styles.formButtonsItem}>
                <Button
                  className={styles.button}
                  disabled={!isValid}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  {LABEL_SAVE_MY_SEARCH}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </MuiDialog>

      <TagCommanderEvent isActive={open} {...tms} useEffectDeps={Object.keys(tms)} />
    </div>
  );
}

const saveSearchSchema = yup.object().shape({
  [FIELD_SEARCH_NAME]: yup
    .string()
    .trim()
    .min(1, LABEL_MY_SEARCH_NAME_ERROR)
    .max(30, LABEL_MY_SEARCH_NAME_ERROR_LENGTH),
  [FIELD_SEARCH_ALERT]: yup.boolean(),
  [FIELD_SEARCH_EMAIL]: yup.string().when(FIELD_SEARCH_ALERT, {
    is: true,
    then: yup
      .string()
      .trim()
      .required(LABEL_MY_SEARCH_MAIL_ERROR)
      .matches(RegExp(regexEmail), LABEL_MY_SEARCH_MAIL_ERROR),
  }),
});
