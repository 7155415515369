import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { ClickAwayListener as MuiClickAwayListener } from '@material-ui/core';
import { isEqual, omit } from 'lodash';

import { replaceTokens } from 'services/formatter';
import {
  LABEL_ERROR_NO_LOCATION,
  LABEL_SAVED_SEARCH,
  LABEL_SAVE_MY_SEARCH,
  LABEL_SAVE_MY_SEARCH_TOOLTIP,
  LABEL_SAVE_SEARCH_BUTTON_POPIN_TEXT,
  LABEL_SAVE_SEARCH_BUTTON_POPIN_TITLE,
  LABEL_SEE_MY_SEARCH,
  LABEL_TOO_MUCH_RESULTS,
  LABEL_TOO_MUCH_RESULTS_MODIFY,
  LABEL_TOO_MUCH_RESULTS_RANGE,
} from 'settings/labels';
import { PANEL_ID_SEARCH } from 'settings/panels';
import { TOKEN_NB_LOTS, TOKEN_NB_PROGRAMS } from 'settings/token';

import { modifyQuery } from 'services/url';

import { usePanels } from 'hooks/usePanels';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { SearchType, useSearch } from 'modules/HomePage/hooks/useSearch';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import SavedSearchDrawer from 'modules/SaveSearch/Components/SavedSearchDrawer/SavedSearchDrawer';

import styles from './SaveSearchButton.module.scss';

interface SaveSearchButtonProps {
  classes?: Partial<Record<'root' | 'icon' | 'labelText', string>>;
  hideButtonLabel?: boolean;
  nbResultsLots: number;
  nbResultsPrograms: number;
  openSaveSearchModal: () => void;
  searchSaved: boolean;
  setSearchSaved: (searchSaved: boolean) => void;
  setShowPopin: (showPopin: boolean) => void;
  showPopin: boolean;
}

export default function SaveSearchButton({
  classes = {},
  hideButtonLabel = false,
  nbResultsLots,
  nbResultsPrograms,
  openSaveSearchModal,
  searchSaved,
  setSearchSaved,
  setShowPopin,
  showPopin,
}: SaveSearchButtonProps) {
  const history = useHistory();
  const search = useSearch();
  const { settings } = useContext(SettingsContext);
  const [savedFilters, setSavedFilters] = useState<Partial<SearchType>>();
  const [showBlockedPopin, setShowBlockedPopin] = useState<boolean>(false);
  const [showErrorPopin, setShowErrorPopin] = useState<boolean>(false);
  interface MapCoordinates {
    east: string | number;
    lat: string;
    lng: string;
    north: string | number;
    south: string | number;
    west: string | number;
    zoom: string;
  }
  const [searchResultsMapCoordinates, setSearchResultsMapCoordinates] = useState<MapCoordinates>();
  const [mapCoordinates, setMapCoordinates] = useState<MapCoordinates>();
  const [locations, setLocations] = useState<string[]>();
  const { open, openPanel, closePanel } = usePanels();

  function hasMapInitialPosition() {
    const newMapCoordinates = {
      east: search.east,
      lat: search.lat,
      lng: search.lng,
      north: search.north,
      south: search.south,
      west: search.west,
      zoom: search.zoom,
    };

    return isEqual(searchResultsMapCoordinates, newMapCoordinates);
  }

  function handleSaveClick() {
    if (!search?.locations) {
      setShowErrorPopin(true);
    } else if (
      !search.nearProgram &&
      ((Number(nbLimitLots) > 0 && Number(nbLimitLots) < nbResultsLots) ||
        (Number(nbLimitPrograms) > 0 && Number(nbLimitPrograms) < nbResultsPrograms))
    ) {
      setShowBlockedPopin(true);
    } else if (hasMapInitialPosition()) {
      openModalForSaving();
    } else {
      if (searchResultsMapCoordinates) {
        history.replace(modifyQuery(searchResultsMapCoordinates, []));
      }
      openModalForSaving();
    }
  }

  function openModalForSaving() {
    setSavedFilters(search);
    openSaveSearchModal();
  }

  function handleClick() {
    openPanel(PANEL_ID_SEARCH);
    setShowPopin(false);
  }

  useEffect(() => {
    if (searchSaved && !isEqual(savedFilters, omit(search, ['panelMySearch', 'panelFolders']))) {
      setSearchSaved(false);
    }

    // When we change location, we first add locations param in url before having an update of map coordinates values
    // So, we add to check if the map has moved after modifying locations (#9902 bug)
    if (
      search?.east &&
      search?.north &&
      search?.south &&
      search?.west &&
      search?.lat &&
      search?.lng &&
      search?.zoom
    ) {
      const newMapCoordinates = {
        east: search.east,
        lat: search.lat,
        lng: search.lng,
        north: search.north,
        south: search.south,
        west: search.west,
        zoom: search.zoom,
      };
      if (!isEqual(mapCoordinates, newMapCoordinates)) {
        setMapCoordinates(newMapCoordinates);

        if (
          search?.locations &&
          !isEqual(search?.locations, locations) &&
          !isEqual(searchResultsMapCoordinates, newMapCoordinates)
        ) {
          setLocations(search.locations);
          setSearchResultsMapCoordinates(newMapCoordinates);
        }
      }
    }
  }, [search]);

  if (!settings) {
    return null;
  }
  const {
    alertes_mail: {
      nb_resultats_recherche_lot: nbLimitLots,
      nb_resultats_recherche_programme: nbLimitPrograms,
    },
  } = settings;

  return (
    <div className={styles.root}>
      <Button
        className={classNames(classes.root, styles.button, {
          [styles.disabled]: searchSaved || showPopin,
          [styles.hiddenLabel]: hideButtonLabel,
        })}
        color="primary"
        variant="contained"
        iconId="icon-notifications-mono"
        iconClass={classNames(classes.icon, styles.icon)}
        labelTextClassName={classNames(classes.labelText, styles.labelText)}
        tooltip={LABEL_SAVE_MY_SEARCH_TOOLTIP}
        onClick={handleSaveClick}
        disabled={searchSaved || showPopin}
      >
        {searchSaved || showPopin ? LABEL_SAVED_SEARCH : LABEL_SAVE_MY_SEARCH}
      </Button>
      {showPopin && (
        <MuiClickAwayListener onClickAway={() => setShowPopin(false)}>
          <div className={styles.popin}>
            <button type="button" className={styles.popinClose} onClick={() => setShowPopin(false)}>
              <Icon className={styles.popinCloseIcon} icon="cross" />
            </button>
            <div className={styles.popinTitle}>{LABEL_SAVE_SEARCH_BUTTON_POPIN_TITLE}</div>
            <div className={styles.popinText}>{LABEL_SAVE_SEARCH_BUTTON_POPIN_TEXT}</div>
            <div className={styles.popinButtonWrapper}>
              <Button variant="text" iconId="icon-my-research" onClick={handleClick}>
                {LABEL_SEE_MY_SEARCH}
              </Button>
            </div>
          </div>
        </MuiClickAwayListener>
      )}
      {(Number(nbLimitLots) > 0 || Number(nbLimitPrograms) > 0) && showBlockedPopin && (
        <MuiClickAwayListener onClickAway={() => setShowBlockedPopin(false)}>
          <div className={styles.popinBlocked}>
            <button
              type="button"
              className={styles.popinClose}
              onClick={() => setShowBlockedPopin(false)}
            >
              <Icon className={styles.popinCloseIcon} icon="cross" />
            </button>
            <div className={styles.popinBlockedContent}>
              <SvgIcon className={styles.popinBlockedContentIcon} iconId="icon-search" />
              <div className={styles.popinBlockedContentInfos}>
                <div>
                  <span className={styles.popinBlockedContentTextLarge}>
                    {LABEL_TOO_MUCH_RESULTS}
                  </span>{' '}
                  <span className={styles.popinBlockedContentText}>
                    {replaceTokens(LABEL_TOO_MUCH_RESULTS_RANGE, {
                      [TOKEN_NB_LOTS]: nbLimitLots || 0,
                      [TOKEN_NB_PROGRAMS]: nbLimitPrograms || 0,
                    })}
                  </span>
                </div>
                <span className={styles.popinBlockedContentTextLarge}>
                  {LABEL_TOO_MUCH_RESULTS_MODIFY}
                </span>
              </div>
            </div>
          </div>
        </MuiClickAwayListener>
      )}
      {showErrorPopin && (
        <MuiClickAwayListener onClickAway={() => setShowErrorPopin(false)}>
          <div className={styles.popinError}>
            <button
              type="button"
              className={styles.popinClose}
              onClick={() => setShowErrorPopin(false)}
            >
              <Icon className={styles.popinCloseIcon} icon="cross" />
            </button>
            <div className={styles.popinErrorContent}>
              <SvgIcon className={styles.popinErrorContentIcon} iconId="icon-search" />
              {LABEL_ERROR_NO_LOCATION}
            </div>
          </div>
        </MuiClickAwayListener>
      )}

      <SavedSearchDrawer onClose={closePanel} open={open === PANEL_ID_SEARCH} />
    </div>
  );
}
