import React, { useState } from 'react';
import classnames from 'classnames';

import { ListItemText as MuiListItemText, MenuItem as MuiMenuItem } from '@material-ui/core';

import { LABEL_DISPLAY_MORE_OPTIONS } from 'settings/labels';

import ToggleCircle from 'modules/HomePage/Components/Search/ToggleCircle/ToggleCircle';

import { Checkbox } from '../Checkbox/Checkbox';

import styles from './FilterOptions.module.scss';

type ValueType = number | string;
interface FilterOptionsBaseProps<Value extends ValueType> {
  classes?: Partial<
    Record<'root' | 'toggle' | 'more' | 'item' | 'label' | 'checkbox' | 'pastille', string>
  >;
  labelMapping?: Map<string, string>;
  onChange: (values: Value[]) => void;
  withPastille?: boolean;
}
interface FilterOptionsSimpleProps<Value extends ValueType> extends FilterOptionsBaseProps<Value> {
  maxOptions: never;
  multiple?: false | never;
  options: never;
  value?: Value;
}
interface FilterOptionsMultipleProps<Value extends ValueType>
  extends FilterOptionsBaseProps<Value> {
  maxOptions?: number;
  multiple: true;
  options?: { label: string; value: Value }[];
  value?: Value[];
}
type FilterOptionsProps<Value extends ValueType, Multiple extends boolean> = Multiple extends true
  ? FilterOptionsMultipleProps<Value>
  : FilterOptionsSimpleProps<Value>;

export default function FilterOptions<Value extends ValueType, Multiple extends boolean>({
  classes = {},
  labelMapping,
  maxOptions,
  multiple = false,
  onChange,
  options = [],
  value,
  withPastille = false,
}: FilterOptionsProps<Value, Multiple>) {
  const [seeMoreOptions, setSeeMoreOptions] = useState(false);

  function renderOption(option: typeof options[number]) {
    const formattedLabel = labelMapping ? labelMapping.get(option.label) : option.label;
    const isActive =
      (value instanceof Array && value.indexOf(option.value) > -1) || value === option.value;
    return (
      <MuiMenuItem
        classes={{ root: classnames(classes.item, styles.menuItemRoot) }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component="div" // I'm pretty sure TypeScript is lying on the type of this props
        key={option.value}
        value={option.value}
        onClick={() => {
          if (Array.isArray(value) && value.indexOf(option.value) > -1) {
            onChange(value.filter(x => x !== option.value));
          } else if (multiple) {
            onChange([...(value as Value[]), option.value]);
          } else {
            onChange([option.value]);
          }
        }}
      >
        <Checkbox
          checked={Array.isArray(value) ? value.indexOf(option.value) > -1 : value === option.value}
          classes={{ root: classes.checkbox }}
          color="primary"
        />
        <MuiListItemText
          classes={{
            root: classnames(classes.label, styles.labelRoot, {
              [styles.labelChecked]: isActive,
            }),
          }}
          disableTypography
          primary={
            withPastille ? (
              <ToggleCircle
                className={classes.pastille}
                active={isActive}
                iconId={option.label.toLowerCase()}
              />
            ) : (
              formattedLabel
            )
          }
        />
      </MuiMenuItem>
    );
  }

  const visibleOptions =
    maxOptions && options.length > maxOptions ? options.slice(0, maxOptions) : options;
  const moreOptions = maxOptions && options.length > maxOptions ? options.slice(maxOptions) : [];

  return (
    <div className={classnames(classes.root, styles.root)}>
      {visibleOptions.map(option => renderOption(option))}
      {moreOptions.length > 0 && !seeMoreOptions && (
        <button
          className={classnames(classes.toggle, styles.toggle)}
          onClick={() => setSeeMoreOptions(true)}
          type="button"
        >
          {LABEL_DISPLAY_MORE_OPTIONS}
        </button>
      )}
      {moreOptions.length > 0 && seeMoreOptions && (
        <div className={classnames(classes.more, styles.moreOptions)}>
          {moreOptions.map(option => renderOption(option))}
        </div>
      )}
    </div>
  );
}
