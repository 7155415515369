import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import { LABEL_ERROR_MAX, LABEL_ERROR_MIN_MAX, LABEL_MAX, LABEL_MIN } from 'settings';

import { FormHelperText as MuiFormHelperText, Slider as MuiSlider } from '@material-ui/core';
import TextField from '../TextField/TextField';

import styles from './RangeField.module.scss';

export interface RangeFieldProps {
  classes?: Partial<Record<'root', string>>;
  endAdornmentText?: ReactNode;
  id: string;
  maxRange: number;
  minRange: number;
  moreLabelMax?: string;
  name: string;
  onChange?: (value: { max: number; min: number }) => void;
  setFilterInError?: (x: boolean) => void;
  values?: { max: number; min: number } | { max: undefined; min: undefined };
  valuesFormatter?: (value: number) => number;
  valuesFormatterReverse?: (value: number) => number;
}

export default function RangeField({
  classes = {},
  endAdornmentText,
  id,
  maxRange,
  minRange,
  moreLabelMax,
  name,
  onChange,
  setFilterInError = () => {},
  values,
  valuesFormatter = x => x,
  valuesFormatterReverse = x => x,
}: RangeFieldProps) {
  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);
  const [minValue, setMinValue] = useState<number>(() =>
    values?.min !== undefined ? valuesFormatter(values.min) : minRange
  );
  const [maxValue, setMaxValue] = useState<number>(() =>
    values?.max !== undefined ? valuesFormatter(values.max) : maxRange
  );

  useEffect(() => {
    setMinValue(prevState => (values?.min !== undefined ? valuesFormatter(values.min) : prevState));
    setMaxValue(prevState => (values?.max !== undefined ? valuesFormatter(values.max) : prevState));
  }, [values?.min, values?.max]);

  const [errorMessage, setErrorMessage] = useState<string>();

  function updateValues() {
    const minValue = parseInt(minRef.current ? minRef.current.value : '', 10);
    const maxValue = parseInt(maxRef.current ? maxRef.current.value : '', 10);
    if (!Number.isNaN(maxValue) && !Number.isNaN(minValue)) {
      onChange?.({
        max: valuesFormatterReverse(maxValue),
        min: valuesFormatterReverse(minValue),
      });
    }
  }

  const handleBlurTextField = () => {
    if (minValue >= maxValue) {
      setErrorMessage(LABEL_ERROR_MIN_MAX);
      setFilterInError(true);
    }

    if (maxValue > valuesFormatter(maxRange)) {
      setErrorMessage(`${LABEL_ERROR_MAX} ${valuesFormatter(maxRange)}`);
      setFilterInError(true);
    }

    updateValues();
  };

  const debounceOnChange = debounce(updateValues, 500);

  const handleChangeTextField = (event, type) => {
    const { value } = event.target;
    const setter = type === 'min' ? setMinValue : setMaxValue;
    setter(parseInt(value, 10));
    setErrorMessage('');
    setFilterInError(false);
    debounceOnChange();
  };

  const handleChangeSlider = (event, value) => {
    setMinValue(parseInt(value[0], 10));
    setMaxValue(parseInt(value[1], 10));
    setErrorMessage('');
    setFilterInError(false);
  };

  const labelMax = (
    <>
      <span className={styles.label}>{LABEL_MAX}</span>
      <span className={styles.subLabel}>{moreLabelMax}</span>
    </>
  );
  return (
    <div className={classnames(classes.root, styles.root)}>
      <div className={styles.slider}>
        <MuiSlider
          classes={{
            rail: styles.sliderRail,
            root: styles.sliderRoot,
            thumb: styles.sliderThumb,
            track: styles.sliderTrack,
          }}
          max={valuesFormatter(maxRange)}
          onChange={handleChangeSlider}
          onChangeCommitted={updateValues}
          value={[minValue, maxValue]}
          valueLabelDisplay="off"
        />
      </div>
      <div className={styles.grid}>
        <div className={styles.column}>
          <TextField
            display="horizontal"
            endAdornmentText={endAdornmentText}
            error={!!errorMessage}
            id={`${id}-min`}
            inputRef={minRef}
            label={LABEL_MIN}
            name={`${name}-min`}
            onBlur={handleBlurTextField}
            onChange={e => handleChangeTextField(e, 'min')}
            type="number"
            value={minValue}
          />
        </div>
        <div className={styles.column}>
          <TextField
            display="horizontal"
            endAdornmentText={endAdornmentText}
            error={!!errorMessage}
            id={`${id}-max`}
            inputRef={maxRef}
            label={labelMax}
            name={`${name}-max`}
            onBlur={handleBlurTextField}
            onChange={e => handleChangeTextField(e, 'max')}
            type="number"
            value={maxValue}
          />
        </div>
      </div>
      <div>
        <MuiFormHelperText
          classes={{
            root: classnames(styles.helperTextRoot, {
              [styles.helperTextError]: errorMessage,
            }),
          }}
        >
          {errorMessage}
        </MuiFormHelperText>
      </div>
    </div>
  );
}
