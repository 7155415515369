import React from 'react';
import type { ComponentProps } from 'react';
import { Document } from '@react-pdf/renderer';

import { replaceTokens } from 'services/formatter';
import {
  LABEL_DATE_PDF,
  LABEL_DOCUMENTS,
  LABEL_PROGRAM_TAB_DESCRIPTION,
  LABEL_PROGRAM_TAB_LOCATION,
  LABEL_PROGRAM_TAB_LOTS,
} from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import PdfBanner from '../Layout/PdfBanner';
import PdfPageLayout from '../Layout/PdfPageLayout';
import PdfSection from '../Layout/PdfSection';
import PdfBottomContent from '../Layout/PdfBottomContent';

import ProgramPdfDocuments from './Components/ProgramPdfDocuments';
import ProgramPdfInfos from './Components/ProgramPdfInfos';
import ProgramPdfLocalisation from './Components/ProgramPdfLocalisation';
import ProgramPdfPricesGrid from './Components/ProgramPdfPricesGrid';
import ProgramPdfTotem from './Components/ProgramPdfTotem';

export interface ProgramPdfProps {
  program: ProgramTypeV2;
  lots: LotJson[];
  statuses: ComponentProps<typeof ProgramPdfPricesGrid>['statuses'];
  taxById: TaxesById;
}

export default function ProgramPdf({ program, statuses, taxById, lots }: ProgramPdfProps) {
  return (
    <Document>
      <PdfPageLayout>
        <PdfBanner program={program}>
          <ProgramPdfTotem program={program} />
        </PdfBanner>
        <PdfSection picto="description" title={LABEL_PROGRAM_TAB_DESCRIPTION}>
          <ProgramPdfInfos program={program} />
        </PdfSection>
        {lots.length > 0 && (
          <PdfSection picto="price" title={LABEL_PROGRAM_TAB_LOTS}>
            <ProgramPdfPricesGrid
              program={program}
              statuses={statuses}
              lots={lots}
              taxById={taxById}
            />
          </PdfSection>
        )}
        <PdfSection picto="localisation" title={LABEL_PROGRAM_TAB_LOCATION}>
          <ProgramPdfLocalisation program={program} />
        </PdfSection>
        <PdfSection picto="plan" title={LABEL_DOCUMENTS}>
          <ProgramPdfDocuments program={program} />
        </PdfSection>
        <PdfBottomContent
          text={`${replaceTokens(LABEL_DATE_PDF, {
            [TOKEN_DATE]: new Date(Date.now()).toLocaleDateString(),
          })}
          https://www.vinci-immobilier-patrimoine.com/mentions-legales`}
        />
      </PdfPageLayout>
    </Document>
  );
}
