import { useMemo } from 'react';
import useSWR from 'swr';

import type { GeoSearch } from 'api/viOffresAPI/apiTypes/GeoSearch';

import { getLocality } from 'api/viOffresAPI/apiClient';

function useAutocomplete(search: string | undefined): { results: GeoSearch; isValidating: boolean };
function useAutocomplete<T>(
  search: string | undefined,
  processResult: (data: GeoSearch) => T
): {
  results: T;
  isValidating: boolean;
};
function useAutocomplete<T>(search: string | undefined, processResult?: (data: GeoSearch) => T) {
  const isPostalCode = search?.match(/^[0-9]{2,5}/) !== null;

  const { data = { villes: [], departements: [], regions: [] }, isValidating } = useSWR(
    search
      ? [isPostalCode || search?.length >= 2 ? 'search/geosearch' : undefined, search]
      : undefined,
    ([url, city]) => getLocality(url, city)
  );

  const results = useMemo(
    () => (typeof processResult === 'function' ? processResult(data) : data),
    [data]
  );

  return { results, isValidating };
}

export default useAutocomplete;
