import React from 'react';
import classnames from 'classnames';

import { useModal } from 'hooks/useModal';
import { ellipsisText, replaceTokens } from 'services';
import {
  DOC_NATURE_PRODUCT,
  LABEL_DOWNLOAD,
  LABEL_PRODUCT_BOOKLET,
  LABEL_SEND_FOLDER_PROGRAM,
  LABEL_SHARE,
  TOKEN_PROGRAM_NAME,
} from 'settings';

import { DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';

import Button from 'commonUi/Button/Button';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import { ModalSendDocuments } from 'modules/ActionsProgramsAndLots/ModalSendDocuments/ModalSendDocuments';

import styles from './DocumentLine.module.scss';

const DOCUMENT_TITLE_MAX_LENGTH = 50;
const DOCUMENT_NATURE_MAPPING = {
  [DOC_NATURE_PRODUCT.toLocaleLowerCase()]: LABEL_PRODUCT_BOOKLET,
};

export default function DocumentLine({
  className = undefined,
  document,
  onToggle = () => {},
  programName,
  selected = false,
}: DocumentLineType) {
  const { open, openModal, closeModal } = useModal();

  function handleToggle() {
    onToggle(document.id);
  }

  const documentNature = decodeURIComponent(document.nature);
  const documentTitle = decodeURIComponent(document.titre);

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.content}>
        <div className={styles.checkbox}>
          <Checkbox checked={selected} handleChange={handleToggle} />
        </div>
        <div className={styles.documentInfos}>
          <div className={styles.nature}>
            {ellipsisText(
              DOCUMENT_NATURE_MAPPING[documentNature.toLocaleLowerCase()] ?? documentNature,
              DOCUMENT_TITLE_MAX_LENGTH
            )}
          </div>
          <div className={styles.title}>
            {ellipsisText(documentTitle, DOCUMENT_TITLE_MAX_LENGTH)}
          </div>
        </div>
        <div className={styles.actions}>
          {document.url && (
            <Button
              href={document.url}
              target="_blank"
              rel="noreferrer"
              className={styles.action}
              iconId="icon-download-bicolor"
              iconClass={styles.actionIcon}
              tooltip={LABEL_DOWNLOAD}
              tooltipPlacement="bottom"
            />
          )}
          <Button
            className={styles.action}
            iconId="icon-send"
            iconClass={styles.actionIcon}
            tooltip={LABEL_SHARE}
            tooltipPlacement="bottom"
            onClick={openModal}
          />
        </div>
      </div>

      <ModalSendDocuments
        closeCallBack={closeModal}
        documents={[document]}
        open={open}
        title={replaceTokens(LABEL_SEND_FOLDER_PROGRAM, {
          [TOKEN_PROGRAM_NAME]: programName,
        })}
      />
    </div>
  );
}

export type DocumentLineType = {
  className?: string;
  document: DocumentTypeV2;
  onToggle?: (id: number) => void;
  programName: string;
  selected?: boolean;
};
