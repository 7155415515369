import React, { useContext, useMemo } from 'react';
import type { ComponentProps } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import dayjs from 'dayjs';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { ReportDetail } from 'api/viOffresAPI/apiTypes/Reports';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import ProgramFreeBlock from 'modules/ProgramPage/ProgramFreeBlock/ProgramFreeBlock';

import styles from './ReportContent.module.scss';
import ReportSlideshow from './ReportSlideshow';

interface ReportContentProps {
  from?: string;
  program: ProgramTypeV2 | undefined;
  report: ReportDetail;
}

export default function ReportContent({ from, program, report }: ReportContentProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const documents = useMemo(
    () => report?.documents.filter(({ document, titre }) => document[0]?.url && titre),
    [report?.documents]
  );
  const freeBlocks = useMemo<ComponentProps<typeof ProgramFreeBlock>['paragraph'][]>(
    () =>
      report?.blocs_libre?.map(block => ({
        background_color: block.field_background_color[0]?.color,
        cta: block.field_cta?.[0],
        display: block.field_display[0].value,
        image: isResponsive
          ? block.field_freestyle_picture?.[0].url.mobile
          : block.field_freestyle_picture?.[0].url.desktop,
        media: block.field_media?.[0].url,
        text_wysiwyg: block.field_text_wysiwyg?.[0]?.value,
        title_wysiwyg: block.field_title_wysiwyg?.[0]?.value,
      })) ?? [],
    [isResponsive, report?.blocs_libre]
  );

  if (!program || !report) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  const date = dayjs(report.datePublication);
  const urlBack = `/programme/page/${program.referenceProgramme}/chantiers`;
  const urlProgram = `/programme/page/${program.referenceProgramme}`;

  return (
    <div className={styles.root}>
      <ReactRouterLink className={styles.goBack} to={from ? `${urlBack}?from=${from}` : urlBack}>
        Voir tous les articles
      </ReactRouterLink>

      <h1 className={styles.pageTitle}>Suivi de chantier</h1>

      <div className={styles.program}>
        <ReactRouterLink className={styles.name} to={urlProgram} target="_blank">
          {program.nomCommercial}
        </ReactRouterLink>
        <address className={styles.address}>
          {program.localisation.adresse} {program.localisation.codePostal}{' '}
          {program.localisation.ville}
        </address>
      </div>

      <section className={styles.report}>
        <header className={styles.header}>
          <h2 className={styles.title}>{report.titre}</h2>
          {date.isValid() && (
            <time className={styles.date} dateTime={report.datePublication}>
              Posté le {date.format('DD/MM/YYYY')}
            </time>
          )}
        </header>

        <ReportSlideshow className={styles.banner} report={report} />

        <div>
          {report.description && <FieldRte className={styles.rte} html={report.description} />}
        </div>

        {freeBlocks.length > 0 && (
          <div className={styles.freeBlocks}>
            {freeBlocks.map(freeBlock => (
              <ProgramFreeBlock
                key={freeBlock.title_wysiwyg}
                className={styles.freeBlock}
                paragraph={freeBlock}
              />
            ))}
          </div>
        )}

        {documents.length > 0 && (
          <div className={styles.documents}>
            {documents.map(({ document, id, titre: title }) => (
              <a
                key={id}
                className={styles.document}
                href={document[0]?.url}
                target="_blank"
                rel="noreferrer"
              >
                {title}
                <Icon className={styles.icon} icon="download" />
              </a>
            ))}
          </div>
        )}
      </section>
    </div>
  );
}
