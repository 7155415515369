export function ellipsisText(text, maxLength = 60) {
  const actualText = text === undefined || text === null ? '' : text;
  if (actualText.length > maxLength) {
    return `${actualText.substring(0, maxLength)}...`;
  }
  return actualText;
}

export function switchSinglePlural(value, single, plural) {
  return value > 1 ? `${value} ${plural}` : `${value} ${single}`;
}

export function switchSinglePluralWithReplacement(value, single, plural) {
  return value > 1 ? plural.replace('%n', value) : single.replace('%n', value);
}

/**
 * Removes an item from a stringified list of items
 * @param {String} str - The string to fix
 * @param {String} item - The item to remove from the list
 * @param {String} (separator) - The string separating each item
 * @returns {String}
 */
export function RemoveItemFromString(str, item, separator = ', ') {
  return str
    .split(separator)
    .filter(subStr => subStr !== item)
    .join(separator);
}

/**
 * Removes `ResidenceHorsPinel` and `, ResidenceHorsPinel` from a string
 * @param {String} str
 * @param {String} (separator)
 * @returns {String}
 */
export function RemoveResidenceHorsPinel(str, separator) {
  if (!str) {
    return '';
  }
  return RemoveItemFromString(str, 'ResidenceHorsPinel', separator);
}

export function decodeEntity(inputStr) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
}
