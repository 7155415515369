import React from 'react';
import classnames from 'classnames';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import { Promotion } from 'types/promotions';

import styles from './HonoBoostPromotionBanner.module.scss';

export default function HonoBoostPromotionBanner({
  classes = undefined,
  promotion,
}: HonoBoostPromotionBannerProps) {
  if (!promotion?.promo_programme_bandeau_text) {
    return null;
  }

  return (
    <div className={classnames(styles.root, classes?.root)}>
      <FieldRte
        className={classnames(styles.rte, classes?.rte)}
        html={promotion.promo_programme_bandeau_text}
      />
    </div>
  );
}

export type HonoBoostPromotionBannerProps = {
  classes?: {
    root?: string;
    rte?: string;
  };
  promotion: Promotion;
};
