export const NOTIF_STATUS_READ = 'lu';
export const NOTIF_STATUS_UNREAD = 'non-lu';

export const NOTIF_TYPE_DEADLINE_OPTION = 'Arrivée échéance (option)';
export const NOTIF_TYPE_DEADLINE_OPTION_COACQ = 'Arrivée échéance (option, coacquereur)';
export const NOTIF_TYPE_DEADLINE_PRERES = 'Arrivée échéance (pré-réservation)';
export const NOTIF_TYPE_DEADLINE_PRERES_COACQ = 'Arrivée échéance (pré-réservation, coacquereur)';

export const NOTIF_TYPE_DEADLINE_1H_OPTION = 'Arrivée échéance dans 1 heure(option)';
export const NOTIF_TYPE_DEADLINE_1H_OPTION_COACQ =
  'Arrivée échéance dans 1 heure(option, coacquereur)';
export const NOTIF_TYPE_DEADLINE_1H_PRERES = 'Arrivée échéance dans 1 heure(pré-réservation)';
export const NOTIF_TYPE_DEADLINE_1H_PRERES_COACQ =
  'Arrivée échéance dans 1 heure(pré-réservation, coacquereur)';

export const NOTIF_TYPE_SIGNED_CONTRACT = 'Contrat de réservation signé';
export const NOTIF_TYPE_SIGNED_CONTRACT_COACQ = 'Contrat de réservation signé coacquereur';

export const NOTIF_TYPE_SIGNED_FOLDER = "Dossier de réservation signé par l'acquéreur";
export const NOTIF_TYPE_SIGNED_FOLDER_COACQ = 'Dossier de réservation signé par le(s) acquéreur(s)';

export const NOTIF_TYPE_LOT_AVAILABLE = 'Lot disponible';

export const NOTIF_TYPE_NEW_CONTRACT = 'Nouveau contrat';

export const NOTIF_TYPE_SAVED_SEARCH = 'Nouveau résultat de recherche';

export const NOTIF_TYPE_ACT_CERTIFICATION = "Attestation d'acte";

export const NOTIF_TYPE_PREBOOK_GENERATE_CONTRACT_FAILED =
  'preReservationGenererContratResultFailed';
export const NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_SUCCESS = 'preReservationSignerContratResultSuccess';
export const NOTIF_TYPE_PREBOOK_SIGN_CONTRACT_FAILED = 'preReservationSignerContratResultFailed';

export const NOTIF_TITLE_NEW_CONTRACT = 'Contrat de réservation disponible';
export const NOTIF_TITLE_DEADLINE_OPTION = 'Option arrive à expiration';
export const NOTIF_TITLE_DEADLINE_PRERES = 'Pré-réservation arrive à expiration';
export const NOTIF_TITLE_SIGNED_CONTRACT = 'Contrat contre-signé par Vinci immobilier';
export const NOTIF_TITLE_SIGNED_FOLDER = 'Contrat signé par le client';
export const NOTIF_TITLE_ACT_CERTIFICATION = 'Attestation notariée disponible';
export const NOTIF_TITLE_CONTRACT_GENERATION_FAILED =
  'Échec de la génération du contrat de réservation';
export const NOTIF_TITLE_SEND_SIGN_REQUEST_SUCCESS = 'Demande de signature envoyée';
export const NOTIF_TITLE_SEND_SIGN_REQUEST_FAILED = "Échec de l'envoi de la demande de signature";

export const NOTIF_LINK_LABEL_LOT_AVAILABLE = 'Pré-réservez';
export const NOTIF_LINK_LABEL_NEW_CONTRACT = 'Envoyer le au client';
export const NOTIF_LINK_LABEL_DEADLINE_OPTION = 'Pré-réservez ce lot';
export const NOTIF_LINK_LABEL_DEADLINE_PRERES =
  'Générez le contrat de réservation et envoyez la demande de signature au client';
export const NOTIF_LINK_LABEL_SIGNED_CONTRACT = 'Consultez le contrat de réservation';
export const NOTIF_LINK_LABEL_SIGNED_FOLDER = 'Consultez le contrat de réservation';
export const NOTIF_LINK_LABEL_ACT_CERTIFICATION = 'Consulter l’attestation notariée';
export const NOTIF_LINK_LABEL_SEE_RESERVATION = 'Consulter la réservation';
