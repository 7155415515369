import React from 'react';

import { LABEL_3D_RESIDENCE_TITLE } from 'settings/labels';

import styles from './Program3DResidence.module.scss';

export default function Program3DResidence({ url }: Program3DResidenceProps) {
  if (!url) {
    return null;
  }

  return (
    <iframe className={styles.root} allowFullScreen src={url} title={LABEL_3D_RESIDENCE_TITLE} />
  );
}

export type Program3DResidenceProps = {
  url: string;
};
