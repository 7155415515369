import {
  LABEL_FOLDER_SAVE_MESSAGE_COMPARISON,
  LABEL_FOLDER_SAVE_MESSAGE_LOT,
  LABEL_FOLDER_SAVE_MESSAGE_LOTS,
  LABEL_FOLDER_SAVE_MESSAGE_PROGRAM,
  LABEL_FOLDER_SAVE_MESSAGE_PROGRAMS,
  TOKEN_FOLDER_NAME,
  TOKEN_LIST,
} from '../settings';
import { replaceTokens } from './formatter';

export function getFolderSaveMessage(type, lots, programs, folderName) {
  switch (type) {
    case 'comparison':
      return replaceTokens(LABEL_FOLDER_SAVE_MESSAGE_COMPARISON, {
        [TOKEN_FOLDER_NAME]: folderName,
      });

    case 'lot':
      if (lots.length === 1) {
        return replaceTokens(LABEL_FOLDER_SAVE_MESSAGE_LOT, {
          [TOKEN_FOLDER_NAME]: folderName,
          [TOKEN_LIST]: lots.map(l => l.lotNumber).join(', '),
        });
      }
      return replaceTokens(LABEL_FOLDER_SAVE_MESSAGE_LOTS, {
        [TOKEN_FOLDER_NAME]: folderName,
        [TOKEN_LIST]: lots.map(l => l.lotNumber).join(', '),
      });

    case 'program':
      if (programs.length === 1) {
        return replaceTokens(LABEL_FOLDER_SAVE_MESSAGE_PROGRAM, {
          [TOKEN_FOLDER_NAME]: folderName,
          [TOKEN_LIST]: programs.map(p => p.programName).join(', '),
        });
      }
      return replaceTokens(LABEL_FOLDER_SAVE_MESSAGE_PROGRAMS, {
        [TOKEN_FOLDER_NAME]: folderName,
        [TOKEN_LIST]: programs.map(p => p.programName).join(', '),
      });

    default:
      return null;
  }
}
