import React, { ReactNode } from 'react';

import styles from './CardsList.module.scss';

export default function CardsList(props: CardsListType) {
  const { children, title } = props;

  return (
    <div className={styles.root}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
}

type CardsListType = {
  children: ReactNode;
  title: string;
};
