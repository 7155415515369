import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';

import SvgIcon from '../SvgIcon/SvgIcon';

import styles from './SearchForm.module.scss';

function SearchForm({
  fullWidth,
  id,
  onChange,
  onFocus,
  placeholder,
  value,
  inputClassName = undefined,
  inputProps,
  ...props
}: any) {
  return (
    <TextField
      className={inputClassName}
      classes={{ root: styles.textFieldRoot }}
      fullWidth={fullWidth}
      id={id}
      InputProps={{
        classes: {
          root: styles.inputRoot,
          input: styles.input,
          notchedOutline: styles.notchedOutline,
        },
        disableUnderline: true,
        startAdornment: (
          <InputAdornment classes={{ root: styles.inputAdornment }} position="start">
            <SvgIcon className={styles.svgIcon} iconId="icon-search" />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      type="text"
      variant="outlined"
      value={value}
      {...props}
    />
  );
}

export default SearchForm;
