import React from 'react';
import type { Options } from 'react-youtube';
import classnames from 'classnames';

import { replaceTokens } from 'services/formatter';
import { tmsShowPrivacyCenter } from 'services/tms';
import { LABEL_ALLOW, LABEL_PLACEHOLDER_TEXT } from 'settings/labels';
import { TOKEN_CATEGORY, TOKEN_NAME, TOKEN_VENDOR } from 'settings/token';

import styles from './Placeholder.module.scss';

interface PlaceholderProps extends Options {
  absolute?: boolean;
  className?: string;
  featureName: string;
  featureCategory: string;
  featureVendor: string;
}

export function Placeholder({
  absolute = false,
  className,
  featureName,
  featureCategory,
  featureVendor,
  height = '100%',
  width = '100%',
}: PlaceholderProps) {
  return (
    <div
      className={classnames(styles.root, className, { [styles.absolute]: absolute })}
      style={{
        height,
        width,
      }}
    >
      <p>
        {replaceTokens(LABEL_PLACEHOLDER_TEXT, {
          [TOKEN_CATEGORY]: featureCategory,
          [TOKEN_NAME]: featureName,
          [TOKEN_VENDOR]: featureVendor,
        })}
      </p>
      <button type="button" className="btn-primary" onClick={() => tmsShowPrivacyCenter()}>
        {LABEL_ALLOW}
      </button>
    </div>
  );
}
