import { useContext } from 'react';
import { mutate } from 'swr';
import type { SWRConfiguration } from 'swr';

import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
} from 'settings/activity';
import { TAX_TYPE_PINEL } from 'settings/taxes';

import { axiosViCrmInstance } from 'api/viCrmApi/axiosInstance';

import type { Activity, ProspectType } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { ActivityChoices } from 'api/viCrmApi/apiTypes/ActivityChoices';
import type { OptionPostType } from 'api/viCrmApi/apiTypes/Option';
import type { PreBookingPostType } from 'api/viCrmApi/apiTypes/Prebook';

import settingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import { getCommercialApiBaseUrl, getDefaultActivity } from 'services/activities';
import { decodeToken, getTokenFromSession } from 'services/authentication';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { useSWRViCrm } from 'api/viCrmApi/useSWRViCrm';

const TMP_ACTIVITY_ID = 'temporary-activity';

function getContactId() {
  const encodedToken = getTokenFromSession();
  if (!encodedToken) {
    return undefined;
  }
  return decodeToken(encodedToken)?.extension_VI3P_ContactId;
}

// FETCH ACTIVITIES
/**
 * Fetch activities as well as their Lots & Program data & format it all
 */
function useActivities(
  activityStatus: string,
  activityType: string,
  options: Partial<SWRConfiguration<Activity[]>>
) {
  const contactId = getContactId();
  const url = contactId
    ? `activite_commerciale/${contactId}/all/${activityStatus}/${activityType}`
    : undefined;
  return { url, ...useSWRVi3p<Activity[]>({ url }, options) };
}

export function useBookings(
  activityStatus: Parameters<typeof useActivities>[0],
  options: Parameters<typeof useActivities>[2] = {}
) {
  return useActivities(activityStatus, ACTIVITY_TYPE_BOOKING, options);
}

export function useOptions(
  activityStatus: Parameters<typeof useActivities>[0],
  options: Parameters<typeof useActivities>[2] = {}
) {
  return useActivities(activityStatus, ACTIVITY_TYPE_OPTION, options);
}

export function usePreBookings(
  activityStatus: Parameters<typeof useActivities>[0],
  options: Parameters<typeof useActivities>[2] = {}
) {
  return useActivities(activityStatus, ACTIVITY_TYPE_PRE_BOOKING, options);
}

// MODIFY ACTIVITIES
export function useCreateOption() {
  const { settings } = useContext(settingsContext);
  const { options, activityUrls } = useContext(userContext);

  return async (
    lotNumber: string,
    programRef: string,
    prospect: Pick<
      ProspectType,
      'civility' | 'lastName' | 'firstName' | 'destination' | 'email' | 'postalCode'
    >
  ) => {
    if (
      options?.some(
        activity =>
          activity.field_numerolot_ac === lotNumber &&
          activity.field_referenceprogramme_ac === programRef &&
          activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
      )
    ) {
      throw new Error('Tried to create option on an already optioned lot');
    }

    if (
      !options?.some(
        activity =>
          activity.field_numerolot_ac === lotNumber &&
          activity.field_referenceprogramme_ac === programRef
      )
    ) {
      mutate(
        activityUrls.options,
        options => [
          ...options,
          getDefaultActivity({
            field_idcrm_ac: TMP_ACTIVITY_ID,
            field_numerolot_ac: lotNumber,
            field_referenceprogramme_ac: programRef,
            field_dispositiffiscal_ac: TAX_TYPE_PINEL,
            field_statutnom_ac: ACTIVITY_STATUS_ACTIVE,
            field_type_ac: ACTIVITY_TYPE_OPTION,
          }),
        ],
        false
      );
    }

    const payload: OptionPostType = {
      civilite: prospect.civility,
      nomProspect: prospect.lastName,
      prenomProspect: prospect.firstName,
      email: prospect.email,
      codePostal: prospect.postalCode,
      dateRdv: new Date().toISOString(),
      numeroLot: lotNumber,
      referenceProgramme: programRef,
      destinationAchat: prospect.destination,
    };

    try {
      const response = await axiosViCrmInstance.post(
        `${getCommercialApiBaseUrl(!!settings.cdo?.activite_commerciale_api)}/options`,
        payload
      );
      mutate(
        activityUrls.options,
        (options: Activity[]) => {
          const index = options.findIndex(option => option.field_idcrm_ac === TMP_ACTIVITY_ID);
          const newOptions = [...options];
          newOptions.splice(index, 1, {
            ...options[index],
            field_idcrm_ac: response.data.optionId,
            field_dateexpiration_ac: response.data.expiration,
          });
          return newOptions;
        },
        false
      );
      // no mutate from api here because option is not created yet ( dependency between drupal and api crm)
      return response;
    } catch (error) {
      mutate(
        activityUrls.options,
        (activities: Activity[]) => {
          const tmpIndex = activities.findIndex(({ field_idcrm_ac: id }) => id === TMP_ACTIVITY_ID);
          return tmpIndex === -1
            ? activities
            : [...activities.slice(0, tmpIndex), ...activities.slice(tmpIndex + 1)];
        },
        false
      );
      throw error;
    }
  };
}

export function useCancelOption() {
  const { settings } = useContext(settingsContext);
  const { options, activityUrls } = useContext(userContext);
  let undo: { index: number; activity: Activity } | undefined;

  return (optionId: string) => {
    if (options?.every(option => option.field_idcrm_ac !== optionId)) {
      return Promise.reject(
        new Error("Tried to cancel an option on a lot that isn't optioned by user")
      );
    }
    mutate(
      activityUrls.options,
      (options: Activity[]) => {
        const index = options.findIndex(option => option.field_idcrm_ac === optionId);
        undo = { index, activity: options[index] };
        const newOptions = [...options];
        newOptions.splice(index, 1, {
          ...options[index],
          field_statutnom_ac: ACTIVITY_STATUS_CANCELED,
        });
        return newOptions;
      },
      false
    );
    if (!optionId) {
      return Promise.reject(new Error('Attempted to cancel an option without providing its ID'));
    }
    return axiosViCrmInstance
      .post(
        `${getCommercialApiBaseUrl(
          !!settings.cdo?.activite_commerciale_api
        )}/options/${optionId}/cancel`
      )
      .catch(error => {
        mutate(
          activityUrls.options,
          (activities: Activity[]) =>
            undo
              ? [
                  ...activities.slice(0, undo.index),
                  undo.activity,
                  ...activities.slice(undo.index + 1),
                ]
              : activities,
          false
        );
        throw error;
      })
      .finally(() => {
        undo = undefined;
      });
  };
}

export function useCreatePreBooking() {
  const { settings } = useContext(settingsContext);
  const { preBookings, activityUrls } = useContext(userContext);

  return async (
    prospect: Pick<
      ProspectType,
      'civility' | 'lastName' | 'firstName' | 'email' | 'phone' | 'destination'
    >,
    taxType: Activity['field_dispositiffiscal_ac'],
    ptz: boolean,
    vat: number | undefined,
    lotNumber: string,
    programRef: string,
    signatureDate: string
  ) => {
    if (
      preBookings?.some(
        activity =>
          activity.field_numerolot_ac === lotNumber &&
          activity.field_referenceprogramme_ac === programRef
      )
    ) {
      throw new Error('Tried to create a pre-booking on an already pre-booked lot');
    }
    mutate(
      activityUrls.preBookings,
      preBookings => [
        ...preBookings,
        getDefaultActivity({
          field_idcrm_ac: TMP_ACTIVITY_ID,
          field_numerolot_ac: lotNumber,
          field_referenceprogramme_ac: programRef,
          field_dispositiffiscal_ac: taxType,
          field_statutnom_ac: ACTIVITY_STATUS_ACTIVE,
          field_type_ac: ACTIVITY_TYPE_PRE_BOOKING,
        }),
      ],
      false
    );
    const payload: PreBookingPostType = {
      acquereur: {
        civilite: prospect.civility,
        nom: prospect.lastName,
        prenom: prospect.firstName,
        email: prospect.email,
        telephone: prospect.phone,
        destinationAchat: prospect.destination,
      },
      financement: {
        dispositifFiscal: taxType,
      },
      PTZ: ptz,
      TVA: vat,
      numeroLot: lotNumber,
      referenceProgramme: programRef,
      dateDeSignature: signatureDate,
    };

    try {
      const response = await axiosViCrmInstance.post(
        `${getCommercialApiBaseUrl(!!settings.cdo?.activite_commerciale_api)}/pre-reservations`,
        payload
      );
      mutate(
        activityUrls.preBookings,
        (preBookings: Activity[]) => {
          const index = preBookings.findIndex(p => p.field_idcrm_ac === TMP_ACTIVITY_ID);
          const newPrebookings = [...preBookings];
          newPrebookings.splice(index, 1, {
            ...preBookings[index],
            field_idcrm_ac: response.data.preReservationId,
          });
          return newPrebookings;
        },
        false
      );
      return response;
    } catch (error) {
      mutate(
        activityUrls.preBookings,
        (activities: Activity[]) => {
          const tmpIndex = activities.findIndex(({ field_idcrm_ac: id }) => id === TMP_ACTIVITY_ID);
          return tmpIndex === -1
            ? activities
            : [...activities.slice(0, tmpIndex), ...activities.slice(tmpIndex + 1)];
        },
        false
      );
      throw error;
    }
  };
}

export function useCancelPreBooking() {
  const { settings } = useContext(settingsContext);
  const { preBookings, activityUrls } = useContext(userContext);
  let undo: { index: number; activity: Activity } | undefined;

  return (preBookingId: string, motive: string) => {
    if (preBookings?.every(activity => activity.field_idcrm_ac !== preBookingId)) {
      return Promise.reject(
        new Error("Tried to cancel a pre-booking on a lot that isn't pre-booked by user")
      );
    }
    mutate(
      activityUrls.preBookings,
      (preBookings: Activity[]) => {
        const index = preBookings.findIndex(
          preBooking => preBooking.field_idcrm_ac === preBookingId
        );
        undo = { index, activity: preBookings[index] };
        const newPreBookings = [...preBookings];
        newPreBookings.splice(index, 1, {
          ...preBookings[index],
          field_statutnom_ac: ACTIVITY_STATUS_CANCELED,
        });
        return newPreBookings;
      },
      false
    );
    if (!preBookingId) {
      return Promise.reject(
        new Error('Attempted to cancel a pre-booking without providing its ID')
      );
    }
    if (!motive) {
      return Promise.reject(
        new Error('Attempted to cancel a pre-booking without providing a motive')
      );
    }
    return axiosViCrmInstance
      .post(
        `${getCommercialApiBaseUrl(
          !!settings.cdo?.activite_commerciale_api
        )}/pre-reservations/${preBookingId}/cancel?${new URLSearchParams({
          motif: motive,
        }).toString()}`
      )
      .then(data => {
        // mutate(activityUrls.preBookings);
        return data;
      })
      .catch(error => {
        mutate(
          activityUrls.preBookings,
          (activities: Activity[]) =>
            undo
              ? [
                  ...activities.slice(0, undo.index),
                  undo.activity,
                  ...activities.slice(undo.index + 1),
                ]
              : activities,
          false
        );
        throw error;
      })
      .finally(() => {
        undo = undefined;
      });
  };
}

// OTHER ACTIVITY-RELATED HOOKS
export function useActivityChoices(
  urlDatas: { preBookingId: string | undefined; time?: number },
  config?: any
) {
  const { settings } = useContext(settingsContext);
  const apiBaseUrl = getCommercialApiBaseUrl(!!settings.cdo?.activite_commerciale_api);
  const { preBookingId, time } = urlDatas;

  const { data, ...swr } = useSWRViCrm<ActivityChoices>(
    {
      url: preBookingId
        ? `${apiBaseUrl}/pre-reservations/${preBookingId}/choix${time ? `?t=${time}` : ''}`
        : undefined,
    },
    config
  );

  return { choices: data, ...swr };
}
