import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom';
import { AppBar, CssBaseline, StylesProvider, Toolbar } from '@material-ui/core';

import {
  LABEL_ACTUALITY,
  LABEL_DISCONNECTION,
  LABEL_MY_ACCOUNT,
  LABEL_MY_ACTIVITY,
  LABEL_MY_NOTIFICATIONS,
  LABEL_SEARCH,
} from 'settings/labels';
import { EDIT_FOLDER_MODAL_ID } from 'settings/modal';
import { PANEL_ID_SEARCH } from 'settings/panels';
import { HEADER_DOM_ID, PROGRAM_ANCHORS_DOM_ID } from 'settings/ui';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import { useModalUi } from 'hooks/context/useModalUi';
import { usePanels } from 'hooks/usePanels';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import MobileMenu from 'layout/components/HeaderAuthenticated/MobileMenu/MobileMenu';
import { HeaderFilters } from 'modules/HomePage/Components/Search/HeaderFilters/HeaderFilters';
import NotificationsList from 'modules/Notifications/NotificationsList';
import FolderLinks from 'modules/Folders/FolderLinks';
import NewsletterPanel from 'modules/Newsletter/Components/NewsletterPanel';
import SavedSearchDrawer from 'modules/SaveSearch/Components/SavedSearchDrawer/SavedSearchDrawer';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import KelQuartierModuleProvider from 'sharedModulesV4/KelQuartier/providers/KelQuartierModuleProvider';

import logoVi3p from 'images/logo-vi3p.png';

import { apiKelQuartier } from 'api/KelQuartier/ApiKelQuartier';

import DesktopToolbar from './Toolbar/DesktopToolbar';
import MobileToolbar from './Toolbar/MobileToolbar';

import MobileDrawer from './Drawer/MobileDrawer';
import styles from './HeaderAuthenticated.module.scss';

const TOOLBAR_LINKS = [
  {
    href: '/recherche',
    label: LABEL_SEARCH,
  },
  {
    href: '/mon-activite-commerciale',
    label: LABEL_MY_ACTIVITY,
  },
  {
    href: '/actualites',
    label: LABEL_ACTUALITY,
  },
];
const TOOLBAR_ICONS_DESKTOP = [
  {
    id: 'myNotifications',
    label: LABEL_MY_NOTIFICATIONS,
    icon: <SvgIcon iconId="icon-notifications" />,
  },
  {
    id: 'myAccount',
    label: LABEL_MY_ACCOUNT,
    icon: <Icon icon="user" />,
  },
];
const TOOLBAR_ICONS_MOBILE = [
  {
    id: 'myNotifications',
    label: LABEL_MY_NOTIFICATIONS,
    icon: <SvgIcon className={styles.iconNotifications} iconId="icon-notifications" />,
  },
  {
    id: 'myAccount',
    label: LABEL_MY_ACCOUNT,
    icon: <Icon icon="user" />,
    href: '/mon-profil',
  },
  {
    label: LABEL_DISCONNECTION,
    icon: <Icon icon="power-off" />,
    href: '/deconnexion',
  },
];

export default function HeaderAuthenticated() {
  const { pathname } = useLocation();
  const { location } = useHistory();
  const { isResponsive, isMobile, isTablette } = useContext(ResponsiveContext);
  const { setPageVars, setComponentPageVars } = useContext(TmsContext);
  const { open, openPanel, closePanel } = usePanels();
  const headerDomRef = useRef<HTMLElement>(null);
  const switchRef = useRef<HTMLDivElement>(null);

  const logoWidth = isResponsive ? 141 : 170;
  const logoHeight = isResponsive ? 29 : 35;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [panelOpen, setPanelOpen] = useState('');

  const modalUi = useModalUi();
  const isSearchPage = pathname === '/recherche';

  useEffect(() => {
    function onScroll() {
      const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
      if (programMenuAnchors && headerDomRef.current) {
        if (programMenuAnchors?.getBoundingClientRect().top < headerDomRef.current?.offsetHeight) {
          headerDomRef.current.style.top = `${
            programMenuAnchors.getBoundingClientRect().top - headerDomRef.current.offsetHeight
          }px`;
        } else if (headerDomRef.current?.style?.top) {
          headerDomRef.current.style.top = '';
        }
      }
    }
    document.addEventListener('scroll', onScroll);
    return document.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!isResponsive) {
      setIsDrawerOpen(false);
    }
  }, [isResponsive]);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(isDrawerOpen => !isDrawerOpen);
    setPanelOpen(panelOpen => (panelOpen ? '' : panelOpen));
    closePanel();
  }, []);

  const handleToolbarIcon = useCallback(toolbarIconId => {
    setPanelOpen(panelOpen => (panelOpen === toolbarIconId ? '' : toolbarIconId));
    // handleOpen(toolbarIconId)
  }, []);

  const handleLogoClick = () => {
    setPageVars({});
    setComponentPageVars({});
  };

  const isModalCreateFolderOpen = modalUi.isModalOpen(EDIT_FOLDER_MODAL_ID);

  return (
    <StylesProvider injectFirst>
      <>
        <CssBaseline />
        <AppBar
          ref={headerDomRef}
          classes={{
            root: classNames(styles.appBarRoot, {
              [styles.appBarRootDrawerOpen]: isDrawerOpen,
            }),
            colorPrimary: styles.colorPrimary,
          }}
          id={HEADER_DOM_ID}
          position="fixed"
        >
          <Toolbar className={styles.toolbar}>
            <ReactLink to="/recherche" className={styles.home} onClick={handleLogoClick}>
              <picture>
                <img src={logoVi3p} alt="Vinci VIP" width={logoWidth} height={logoHeight} />
              </picture>
            </ReactLink>
            {isSearchPage && !isMobile && !isTablette && (
              <KelQuartierModuleProvider
                getGoogleReverseGeocoding={apiKelQuartier.getGoogleReverseGeocoding}
                kelquartierGetPois={apiKelQuartier.kelquartierGetPois}
                theme={['vi3p', 'vi3p-search']}
              >
                <HeaderFilters className={styles.filters} positionSuggestedSearchRef={switchRef} />
              </KelQuartierModuleProvider>
            )}
            {!isResponsive && (
              <DesktopToolbar
                handleToolbarIcon={handleToolbarIcon}
                panelOpen={panelOpen}
                pathname={location.pathname}
                toolbarLinks={TOOLBAR_LINKS}
                toolbarIconsDesktop={TOOLBAR_ICONS_DESKTOP}
                openPanel={openPanel}
                openedPanel={open}
              />
            )}
            {isResponsive && (
              <MobileToolbar
                handleOpenDrawer={handleOpenDrawer}
                handleToolbarIcon={handleToolbarIcon}
                isDrawerOpen={isDrawerOpen}
                openPanel={openPanel}
              />
            )}
          </Toolbar>
        </AppBar>

        {isResponsive && (
          <>
            <MobileDrawer isDrawerOpen={isDrawerOpen} displayNone={isModalCreateFolderOpen}>
              <MobileMenu
                closePanel={() => setIsDrawerOpen(false)}
                handleToolbarIcon={handleToolbarIcon}
                openPanel={openPanel}
                pathname={location.pathname}
                toolbarLinks={TOOLBAR_LINKS}
                toolbarIconButtonMobile={TOOLBAR_ICONS_MOBILE}
              />
            </MobileDrawer>

            <MobileDrawer isDrawerOpen={panelOpen === 'myNotifications'} openFrom="left">
              <div
                className={classNames([styles.notificationsBlock], {
                  [styles.hide]: panelOpen !== 'myNotifications',
                })}
              >
                <NotificationsList closePanel={() => handleToolbarIcon('myNotifications')} />
              </div>
            </MobileDrawer>
          </>
        )}

        {!isResponsive && panelOpen === 'myNotifications' && (
          <>
            <div className={styles.bg} />
            <div
              className={classNames([styles.notificationsBlock], {
                [styles.hide]: panelOpen !== 'myNotifications',
              })}
            >
              <NotificationsList closePanel={() => handleToolbarIcon('myNotifications')} />
            </div>
          </>
        )}

        <FolderLinks closePanel={closePanel} idPanelOpen={open} />
        <SavedSearchDrawer onClose={closePanel} open={open === PANEL_ID_SEARCH} />
        <NewsletterPanel closePanel={closePanel} open={open} />
      </>
    </StylesProvider>
  );
}
