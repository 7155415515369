import React from 'react';
import type { ComponentProps, MouseEvent } from 'react';
import classnames from 'classnames';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import { LABEL_NO, LABEL_YES } from 'settings/labels';

import styles from './ModalConfirm.module.scss';

interface ModalConfirm extends Omit<ComponentProps<typeof MuiDialog>, 'onClose' | 'title'> {
  classes?: Partial<
    Record<'buttonCancel' | 'buttonConfirm' | 'content' | 'footer' | 'inner' | 'title', string>
  > &
    ComponentProps<typeof MuiDialog>['classes'];
  children?: React.ReactNode;
  colorCancel?: ComponentProps<typeof Button>['color'];
  colorConfirm?: ComponentProps<typeof Button>['color'];
  confirmIsDisabled?: boolean;
  labelCancel?: React.ReactNode;
  labelConfirm?: React.ReactNode;
  loading?: boolean;
  onCancel: (ev: MouseEvent) => void;
  onConfirm: (ev: MouseEvent) => void;
  title?: React.ReactNode;
}

export default function ModalConfirm({
  classes = {},
  children,
  colorCancel = 'primary',
  colorConfirm = 'primary',
  confirmIsDisabled = false,
  labelCancel = LABEL_NO,
  labelConfirm = LABEL_YES,
  onCancel,
  onConfirm,
  loading = false,
  title = undefined,
  ...DialogProps
}: ModalConfirm) {
  const {
    buttonCancel: buttonCancelClassName,
    buttonConfirm: buttonConfirmClassName,
    content: contentClassName,
    footer: footerClassName,
    inner: innerClassName,
    title: titleClassName,
    ...muiDialogClasses
  } = classes;
  return (
    <MuiDialog
      onClose={onCancel}
      classes={{
        ...muiDialogClasses,
        paper: classnames(muiDialogClasses.paper, styles.modalSkin),
        root: classnames(muiDialogClasses.root, styles.root),
      }}
      {...DialogProps}
    >
      <div className={classnames(innerClassName, styles.modalInner)}>
        <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={onCancel}>
          <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
        </IconButton>

        <div className={classnames(contentClassName, styles.modalContent)}>
          {title && <h2 className={classnames(titleClassName, styles.modalTitle)}>{title}</h2>}
          {children}
          <div className={classnames(footerClassName, styles.modalFooter)}>
            <Button
              fullWidth
              data-test-id="modal-cancel-button"
              className={classnames(buttonCancelClassName, styles.button, styles.cancelButton)}
              variant="contained"
              color={colorCancel}
              onClick={onCancel}
            >
              {labelCancel}
            </Button>
            <Button
              data-test-id="modal-confirm-button"
              className={classnames(buttonConfirmClassName, styles.button, styles.confirmButton)}
              disabled={confirmIsDisabled}
              fullWidth
              variant="contained"
              color={colorConfirm}
              loading={loading}
              onClick={onConfirm}
            >
              {labelConfirm}
            </Button>
          </div>
        </div>
      </div>
    </MuiDialog>
  );
}
