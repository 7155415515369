import { useContext, useEffect } from 'react';
import { isEqual } from 'lodash';

import { TMS_DEFAULT_VALUES } from 'settings/tms';

import { getTmsVars } from 'services/tms';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

export type TagCommanderProps = {
  useEffectDeps?: string[];
};

export default function TagCommander({
  useEffectDeps = [],
  ...tmsValues
}: TagCommanderProps & Partial<typeof TMS_DEFAULT_VALUES>) {
  const { setPageVars } = useContext(TmsContext);

  useEffect(
    () => {
      setPageVars(prevPageVars => {
        const tmsVars = getTmsVars(tmsValues);
        return isEqual(prevPageVars, tmsVars) ? prevPageVars : tmsVars;
      });
    },
    useEffectDeps.map(key => tmsValues[key])
  );

  return null;
}
