import React from 'react';
import type { HTMLAttributes } from 'react';
import classnames from 'classnames';

import { formatPhone } from 'services/formatter';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './Interlocutor.module.scss';

interface InterlocutorProps extends HTMLAttributes<HTMLDivElement> {
  // Some of those fields can be the empty string
  interlocutor: {
    description: string;
    email: string;
    nom: string;
    prenom: string;
    telephone: string;
    vignette: string;
  };
  phoneIsLink?: boolean;
}

export default function Interlocutor({
  className,
  interlocutor,
  phoneIsLink = false,
  ...htmlProps
}: InterlocutorProps) {
  const formattedPhone =
    interlocutor.telephone &&
    formatPhone(interlocutor.telephone.replace(/^\+\d\d/, '0'), '.. .. .. .. ..');

  return (
    <div className={classnames(className, styles.root)} {...htmlProps}>
      <div className={styles.info}>
        {interlocutor.vignette && (
          <div className={styles.img}>
            <img src={interlocutor.vignette} alt="" />
          </div>
        )}
        <div className={styles.content}>
          <span className={styles.name}>
            {interlocutor.prenom} {interlocutor.nom.toUpperCase()}
          </span>
          <span className={styles.role}>{interlocutor.description}</span>
        </div>
      </div>

      <div className={styles.contact}>
        {interlocutor.telephone && phoneIsLink && (
          <a
            className={classnames(styles.phone, styles.button)}
            href={`tel:${interlocutor.telephone.replace(/\s/g, '')}`}
          >
            <SvgIcon className={styles.icon} iconId="icon-phone-simple" />
            {formattedPhone}
          </a>
        )}
        {interlocutor.telephone && !phoneIsLink && (
          <div className={styles.phone}>
            <SvgIcon className={styles.icon} iconId="icon-phone-simple" />
            {formattedPhone}
          </div>
        )}
        {interlocutor.email && (
          <a
            className={classnames(styles.email, styles.button)}
            href={`mailto:${interlocutor.email}`}
          >
            <SvgIcon className={styles.icon} iconId="icon-enveloppe" />
            Contacter par mail
          </a>
        )}
      </div>
    </div>
  );
}
