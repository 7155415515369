import { useEffect, useRef, useState } from 'react';

import type { SliderRef } from 'types/slider';

const isSliderTooLarge = (nbItems: number, itemWidth: number, gutter: number) =>
  nbItems * (itemWidth + gutter) <= window.innerWidth;

export default function useDisableSlider(itemNb: number, itemWidth: number, itemGutter: number) {
  const sliderRef = useRef<SliderRef>(null);

  const [isDisabled, setIsDisabled] = useState(() =>
    isSliderTooLarge(itemNb, itemWidth, itemGutter)
  );

  useEffect(() => {
    function updateInfinite() {
      setIsDisabled(isSliderTooLarge(itemNb, itemWidth, itemGutter));
      sliderRef.current?.slickGoTo(0, true);
    }
    window.addEventListener('resize', updateInfinite);
    return () => window.removeEventListener('resize', updateInfinite);
  }, [itemNb, itemWidth, itemGutter]);

  return { isDisabled, sliderRef };
}
