import { createContext } from 'react';

import type {
  AnnexTaxonomyTerm,
  KindTaxonomyTerm,
  TaxTaxonomyTerm,
  TaxesById,
} from 'api/viOffresAPI/apiTypes/Taxonomies';

interface TaxonomiesContext {
  annexes: AnnexTaxonomyTerm[];
  isAnnexesLoading: boolean;
  isKindsLoading: boolean;
  isTaxesLoading: boolean;
  isYearsLoading: boolean;
  kinds: KindTaxonomyTerm[];
  taxes: TaxTaxonomyTerm[];
  taxesById: TaxesById;
  years: { label: string; value: string }[];
}

export default createContext<TaxonomiesContext>({
  annexes: [],
  isAnnexesLoading: false,
  isKindsLoading: false,
  isTaxesLoading: false,
  isYearsLoading: false,
  kinds: [],
  taxes: [],
  taxesById: {},
  years: [{ label: 'Immédiate', value: 'now' }],
});
