import React from 'react';
import type { ComponentProps } from 'react';

import type { ParagraphType } from 'api/vi3pAPI/apiTypes/ContentType';

import Paragraphs from 'modules/Paragraphs/Paragraphs';
import TagCommander from 'modules/App/TagCommander/TagCommander';

import styles from './KitchenPage.module.scss';

interface KitchenPageType {
  paragraphs: ParagraphType[];
  tms: Omit<ComponentProps<typeof TagCommander>, 'useEffectDeps'>;
}

export default function KitchenPage({ paragraphs, tms }: KitchenPageType) {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Paragraphs paragraphs={paragraphs} />
      </div>
      {tms && <TagCommander {...tms} useEffectDeps={Object.keys(tms)} />}
    </div>
  );
}
