import React, { useContext } from 'react';

import { PANEL_ID_FOLDERS } from 'settings/panels';
import { PROGRAM_PHASE_MARKETING, PROGRAM_PHASE_PREVIEW } from 'settings/programs';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { foldersContext } from 'modules/App/Contexts/foldersContext';

import { usePanels } from 'hooks/usePanels';

import FolderLinks from 'modules/Folders/FolderLinks';

import ProgramBoosterPrices from './ProgramBoosterPrices';
import ProgramBoosterPreview from './ProgramBoosterPreview';

interface ProgramBoosterTopProps {
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramBoosterTop({ className, program }: ProgramBoosterTopProps) {
  const { emptyDatas, folderSaveType, setFolderPrograms, setFolderSaveType } = useContext(
    foldersContext
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();
  const handleAddFolder = () => {
    if (folderSaveType !== 'program') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('program');
    setFolderPrograms([
      { programName: program.nomCommercial, programRef: program.referenceProgramme },
    ]);
  };

  if (program.phaseCommerciale === PROGRAM_PHASE_MARKETING) {
    return (
      <>
        <ProgramBoosterPrices
          className={className}
          addToFolderHandler={handleAddFolder}
          program={program}
        />
        <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
      </>
    );
  }

  if (program.phaseCommerciale === PROGRAM_PHASE_PREVIEW) {
    return (
      <>
        <ProgramBoosterPreview
          className={className}
          addToFolderHandler={handleAddFolder}
          program={program}
        />
        <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
      </>
    );
  }

  return null;
}
