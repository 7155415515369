import React from 'react';

import { LABEL_ERROR_404, LABEL_ERROR_PAGE_DONT_EXIST } from 'settings/labels';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import image404 from 'images/error_404.svg';

import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
  errorMessage: string;
}

export default function ErrorPage({ errorMessage }: ErrorPageProps) {
  return (
    <>
      <div className={styles.root}>
        <h1 className={styles.title}>{LABEL_ERROR_PAGE_DONT_EXIST}</h1>
        {errorMessage && <p className={styles.subtitle}>{errorMessage}</p>}
        <img className={styles.image} src={image404} alt="Erreur 404" />
      </div>

      <TagCommander navigation_template="error_40x" navigation_pagename="error_40x" />
    </>
  );
}

export function ErrorPage404(props) {
  return <ErrorPage {...props} errorMessage={LABEL_ERROR_404} />;
}
