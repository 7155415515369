import type { ComponentProps } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Element, scroller } from 'react-scroll';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import Sticky from 'react-stickynode';

import {
  LABEL_3D_RESIDENCE_TITLE,
  LABEL_ANCHOR_3D,
  LABEL_ANCHOR_DESCRIPTION,
  LABEL_ANCHOR_DOCUMENTS,
  LABEL_ANCHOR_GALLERY,
  LABEL_ANCHOR_LOCATION,
  LABEL_ANCHOR_PRICES_GRID,
  LABEL_ANCHOR_REPORT,
  LABEL_ANCHOR_SERVICES,
  LABEL_ANCHOR_VIDEO,
  LABEL_PROGRAM_REPORT_TITLE,
} from 'settings/labels';
import { LOT_CELL_ID_KIND, LOT_JSON_PROGRAM_REF } from 'settings/lots';
import { SORT_ORDER_ASC } from 'settings/search';
import { HEADER_DOM_ID, PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { mapAndFilter } from 'services/array';
import { identity } from 'services/functional';
import { sortLots } from 'services/lots';
import { programHasKitchen, programIsADB } from 'services/programs';
import { getPromotionStylesFromSettings } from 'services/promotions';
import { extractVideoIdFromUrl } from 'services/videos';

import { apiKelQuartier } from 'api/KelQuartier/ApiKelQuartier';

import programLotContext from 'modules/App/Contexts/programLotContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import ProgramModalGallery from 'commonUi/ProgramModalGallery/ProgramModalGallery';
import ReportItem from 'commonUi/Reports/ReportItem';
import KelQuartierModuleProvider from 'sharedModulesV4/KelQuartier/providers/KelQuartierModuleProvider';

import PdfProvider from 'modules/App/Providers/PdfProvider';
import VideoPopin from 'modules/HomePage/Components/VideoPopin/VideoPopin';
import ProgramContentBlock from './ProgramContentBlock';
import ProgramBanner from '../ProgramBanner/ProgramBanner';
import ProgramMenuAnchors from '../ProgramMenuAnchors/ProgramMenuAnchors';
import ProgramLots from '../ProgramLots/ProgramLots';
import ProgramDocuments from '../ProgramDocuments/ProgramDocuments';
import Program3DResidence from '../Program3DResidence/Program3DResidence';
import ProgramDescription from '../ProgramDescription/ProgramDescription';
import ProgramServices from '../ProgramServices/ProgramServices';
import ProgramLocation from '../ProgramLocation/ProgramLocation';
import ProgramPromotion from '../ProgramPromotion/ProgramPromotion';
import ProgramMentions from '../ProgramMentions/ProgramMentions';
import ProgramFreeBlock from '../ProgramFreeBlock/ProgramFreeBlock';

import styles from './ProgramContent.module.scss';

const ANCHOR_ID_LOTS = 'lots';
const ANCHOR_ID_DESCRIPTION = 'description';
const ANCHOR_ID_GALLERY = 'galerie';
const ANCHOR_ID_VIDEO = 'video';
const ANCHOR_ID_3D_RESIDENCE = '3d';
const ANCHOR_ID_LOCATION = 'localisation';
const ANCHOR_ID_DOCUMENTS = 'documents';
const ANCHOR_ID_REPORT = 'chantier';
const ANCHOR_ID_SERVICES = 'services';

interface ProgramContentProps {
  program: ProgramTypeV2;
}

export default function ProgramContent({ program }: ProgramContentProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { userCrm } = useContext(userContext);
  const { getLots, isLoading: isDataLoading } = useContext(programLotContext);
  const location = useLocation();
  const params = qs.parse(location.search);

  const lots = useMemo(
    () =>
      isDataLoading
        ? []
        : sortLots(
            getLots(lot => lot[LOT_JSON_PROGRAM_REF] === program.referenceProgramme),
            LOT_CELL_ID_KIND,
            SORT_ORDER_ASC
          ),
    [isDataLoading, program.referenceProgramme]
  );
  const { statuses, updateStatus } = useGetLotsStatuses(
    lots,
    // If attribution switch is ON, the lots have already been filtered for it through `getLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );

  const [isVideoPopinOpen, setIsVideoPopinOpen] = useState(false);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [hasAnchorScrolled, setHasAnchorScrolled] = useState<boolean>(false);

  const handleToggleGallery = () => setOpenGallery(prev => !prev);
  const handleCloseGallery = () => setOpenGallery(false);
  const imagesGallery = program?.perspectives?.slider ?? [];

  const handleToggleVideoPopin = () => setIsVideoPopinOpen(prev => !prev);
  const showServices =
    (programIsADB(program) && settings.blocks?.adb) ||
    (programHasKitchen(program) && settings.blocks?.equipped_kitchen) ||
    settings.blocks?.adb_lame_service;

  const freeBlock = program?.paragraphesVi3p?.[0]?.field_texte_simple?.[0]?.value;

  const anchors = [
    {
      icon: 'icon-price-grid-bicolor',
      iconHover: 'icon-price-grid-mono',
      id: ANCHOR_ID_LOTS,
      label: LABEL_ANCHOR_PRICES_GRID,
    },
    {
      icon: 'icon-description-bicolor',
      iconHover: 'icon-description-mono',
      id: ANCHOR_ID_DESCRIPTION,
      label: LABEL_ANCHOR_DESCRIPTION,
    },
    program.bulletin_chantier && {
      icon: 'icon-progress-bicolor',
      iconHover: 'icon-progress-mono',
      id: ANCHOR_ID_REPORT,
      label: LABEL_ANCHOR_REPORT,
    },
    imagesGallery.length > 0 && {
      icon: 'icon-gallery-bicolor',
      iconHover: 'icon-gallery-mono',
      id: ANCHOR_ID_GALLERY,
      label: LABEL_ANCHOR_GALLERY,
      nb: imagesGallery.length,
      onClick: handleToggleGallery,
    },
    program?.videos?.length > 0 && {
      icon: 'icon-video-bicolor',
      iconClassName: styles.size1,
      iconHover: 'icon-video-mono',
      id: ANCHOR_ID_VIDEO,
      label: LABEL_ANCHOR_VIDEO,
      onClick: handleToggleVideoPopin,
    },
    program.maquetteOrbitale && {
      icon: 'icon-3d-bicolor',
      iconClassName: styles.size2,
      iconHover: 'icon-3d-mono',
      id: ANCHOR_ID_3D_RESIDENCE,
      label: LABEL_ANCHOR_3D,
    },
    {
      icon: 'icon-location-bicolor',
      iconHover: 'icon-location-mono',
      id: ANCHOR_ID_LOCATION,
      label: LABEL_ANCHOR_LOCATION,
    },
    program.documents.length > 0 && {
      icon: 'icon-download-documents-bicolor',
      iconHover: 'icon-download-documents-mono',
      id: ANCHOR_ID_DOCUMENTS,
      label: LABEL_ANCHOR_DOCUMENTS,
    },
    showServices && {
      icon: 'icon-services-bicolor',
      iconClassName: styles.size3,
      iconHover: 'icon-services-mono',
      id: ANCHOR_ID_SERVICES,
      label: LABEL_ANCHOR_SERVICES,
    },
  ].filter(identity) as ComponentProps<typeof ProgramMenuAnchors>['anchors'];

  const nonHonoBoostPromos = program.promotions.filter(promo => !promo.honoboost);

  const promosLegalNotices = mapAndFilter(nonHonoBoostPromos, promo => promo.mentionsLegales);
  const freeBlocksLegalNotices = program.paragraphesLibresVi3P
    ?.map(paragraph => paragraph.mentions_legales)
    .filter(Boolean);
  const legalNotices = [...promosLegalNotices, ...freeBlocksLegalNotices];

  const siteHeader = document.getElementById(HEADER_DOM_ID);
  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);

  useEffect(() => {
    if (
      !hasAnchorScrolled &&
      params?.anchor &&
      anchors.some(anchor => anchor.id === params.anchor)
    ) {
      const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
      const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);

      scroller.scrollTo(params.anchor, {
        duration: 300,
        offset:
          0 - (programMenuAnchors?.offsetHeight || 0) - (programPromoBanner?.offsetHeight || 0),
      });

      setHasAnchorScrolled(true);
    }
  }, [params?.anchor, anchors]);

  let stickyTop = siteHeader ? siteHeader.offsetHeight : 0;
  if (isResponsive) {
    stickyTop = programMenuAnchors ? programMenuAnchors.offsetHeight : 0;
  }

  const videoId = program.videos.length ? extractVideoIdFromUrl(program.videos[0]) : undefined;
  return (
    <PdfProvider program={program} lots={lots} statuses={statuses}>
      <div className={styles.root}>
        {isResponsive && (
          <ProgramMenuAnchors anchors={anchors} program={program} lots={lots.length} />
        )}
        {nonHonoBoostPromos.length > 0 && nonHonoBoostPromos[0].promo_programme_bandeau_text && (
          <Sticky enabled top={stickyTop} innerZ={110}>
            <div
              className={styles.promoBanner}
              id={PROGRAM_PROMO_DOM_ID}
              style={getPromotionStylesFromSettings(settings)}
            >
              <FieldRte
                className={styles.promoBannerText}
                html={nonHonoBoostPromos[0].promo_programme_bandeau_text}
              />
            </div>
          </Sticky>
        )}
        <ProgramBanner program={program} />

        {!isResponsive && (
          <ProgramMenuAnchors anchors={anchors} program={program} lots={lots.length} />
        )}

        <ProgramModalGallery
          closeCallBack={handleCloseGallery}
          open={openGallery}
          program={program}
        />

        {videoId && (
          <VideoPopin
            onClose={() => setIsVideoPopinOpen(false)}
            open={isVideoPopinOpen}
            videoId={videoId}
          />
        )}

        {freeBlock && (
          <ProgramContentBlock
            classes={{ contentClass: styles.freeBlockContent, rootClass: styles.freeBlockRoot }}
          >
            <FieldRte className={classnames(styles.freeBlock, styles.alert)} html={freeBlock} />
          </ProgramContentBlock>
        )}

        <Element
          className={styles.lots}
          name={ANCHOR_ID_LOTS}
          id={`program-block-${ANCHOR_ID_LOTS}`}
        >
          <ProgramContentBlock classes={{ rootClass: styles.lotsRoot }}>
            {nonHonoBoostPromos.map(promotion => (
              <ProgramPromotion promotion={promotion} />
            ))}
            {program.paragraphesLibresVi3P?.map(paragraph => (
              <ProgramFreeBlock
                key={paragraph.title_wysiwyg}
                className={styles.freeBlock}
                paragraph={paragraph}
              />
            ))}
            <ProgramLots
              program={program}
              isLotsLoading={isDataLoading}
              lots={lots}
              statuses={statuses}
              updateStatus={updateStatus}
            />
          </ProgramContentBlock>
        </Element>
        <Element name={ANCHOR_ID_DESCRIPTION} id={`program-block-${ANCHOR_ID_DESCRIPTION}`}>
          <ProgramContentBlock classes={{ contentClass: styles.paddingContent }} withBg>
            {/* TODO : Update with API data */}
            <ProgramDescription program={program} />
          </ProgramContentBlock>
        </Element>
        {program.bulletin_chantier && (
          <Element name={ANCHOR_ID_REPORT} id={`program-block-${ANCHOR_ID_REPORT}`}>
            <ProgramContentBlock
              classes={{ contentClass: styles.paddingContent }}
              svgIcon="icon-progress-bicolor"
              title={LABEL_PROGRAM_REPORT_TITLE}
              withBg
            >
              <ReportItem
                className={styles.report}
                programRef={program.referenceProgramme}
                report={program.bulletin_chantier}
                target="_blank"
              />
            </ProgramContentBlock>
          </Element>
        )}
        {program.maquetteOrbitale && (
          <Element name={ANCHOR_ID_3D_RESIDENCE} id={`program-block-${ANCHOR_ID_3D_RESIDENCE}`}>
            <ProgramContentBlock
              classes={{ contentClass: styles.paddingContent }}
              svgIcon="icon-3d-bicolor"
              title={LABEL_3D_RESIDENCE_TITLE}
              withBg
            >
              <Program3DResidence url={program.maquetteOrbitale} />
            </ProgramContentBlock>
          </Element>
        )}
        <Element name={ANCHOR_ID_LOCATION} id={`program-block-${ANCHOR_ID_LOCATION}`}>
          <ProgramContentBlock withBg>
            <KelQuartierModuleProvider
              getGoogleReverseGeocoding={apiKelQuartier.getGoogleReverseGeocoding}
              kelquartierGetPois={apiKelQuartier.kelquartierGetPois}
              theme={['vi3p', 'vi3p-program']}
            >
              <ProgramLocation program={program} />
            </KelQuartierModuleProvider>
          </ProgramContentBlock>
        </Element>
        {program.documents.length > 0 && (
          <Element name={ANCHOR_ID_DOCUMENTS} id={`program-block-${ANCHOR_ID_DOCUMENTS}`}>
            <ProgramContentBlock withBg>
              <ProgramDocuments program={program} />
            </ProgramContentBlock>
          </Element>
        )}
        {showServices && (
          <Element name={ANCHOR_ID_SERVICES} id={`program-block-${ANCHOR_ID_SERVICES}`}>
            <ProgramContentBlock>
              <ProgramServices program={program} />
            </ProgramContentBlock>
          </Element>
        )}
        {legalNotices?.length > 0 && (
          <ProgramContentBlock withBg>
            <ProgramMentions items={legalNotices} />
          </ProgramContentBlock>
        )}
      </div>
    </PdfProvider>
  );
}
