import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { Dialog as MuiDialog } from '@material-ui/core';

import {
  LABEL_REPORT_GALLERY,
  LABEL_REPORT_GALLERY_CLOSE,
  LABEL_REPORT_GALLERY_OPEN,
} from 'settings/labels';
import { TOKEN_NAME } from 'settings/token';

import type { ReportDetail } from 'api/viOffresAPI/apiTypes/Reports';

import { replaceTokens } from 'services/formatter';
import { extractVideoIdFromUrl, makeYouTubeThumbnailFromId } from 'services/videos';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import FieldVideo from 'commonUi/FieldVideo/FieldVideo';
import GallerySlider from 'commonUi/GallerySlider/GallerySlider';

import styles from './ReportSlideshow.module.scss';

interface ReportSlideshowProps {
  className?: string;
  report: ReportDetail;
}

type MediaSlide =
  | { id: string; desc: string; image: string }
  | { id: string; desc: string; video: string };

export default function ReportSlideshow({ className, report }: ReportSlideshowProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const usableSlideshow = useMemo<MediaSlide[]>(
    () =>
      report.diaporama
        .map(slide => {
          const videoId = slide.video ? extractVideoIdFromUrl(slide.video) : undefined;
          if (videoId) {
            return {
              id: slide.id,
              video: videoId,
              desc: slide.description ?? '',
            };
          }
          return {
            id: slide.id,
            image: slide.image[0]?.url,
            desc: slide.description ?? '',
          };
        })
        .filter(slide => slide.video || slide.image),
    [report.diaporama]
  );

  if (!usableSlideshow.length) {
    return null;
  }

  // Modal can open if there are strictly more than 1 element unless that single element is a video
  const modalCanOpen = usableSlideshow.length > 1 || 'video' in usableSlideshow[0];
  return (
    <>
      <button
        type="button"
        className={classnames(className, styles.button)}
        onClick={modalCanOpen ? () => setModalIsOpen(true) : undefined}
        disabled={!modalCanOpen}
      >
        <img
          className={styles.image}
          src={
            'video' in usableSlideshow[0]
              ? makeYouTubeThumbnailFromId(usableSlideshow[0].video, 'maxresdefault')
              : usableSlideshow[0].image
          }
          alt={usableSlideshow[0].desc}
        />
        <div className={styles.cta} data-label={LABEL_REPORT_GALLERY_OPEN} />
      </button>
      <MuiDialog
        classes={{ root: styles.dialog, paper: styles.paper }}
        maxWidth="xl"
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-label={replaceTokens(LABEL_REPORT_GALLERY, { [TOKEN_NAME]: report.titre })}
      >
        <button
          type="button"
          className={styles.close}
          onClick={() => setModalIsOpen(false)}
          title={LABEL_REPORT_GALLERY_CLOSE}
        >
          <Icon icon="cross" />
        </button>
        <GallerySlider
          slider={usableSlideshow}
          makeSlide={media => ({
            key: media.id,
            content:
              'video' in media ? (
                <div className={styles.slide}>
                  <FieldVideo className={styles.video} id={media.video} isLazy={false} />
                  {media.desc && <FieldRte className={styles.text} html={media.desc} />}
                </div>
              ) : (
                <div className={styles.slide}>
                  <img className={styles.image} src={media.image} alt="" />
                  {media.desc && <FieldRte className={styles.text} html={media.desc} />}
                </div>
              ),
          })}
          makeMiniature={media => ({
            key: media.id,
            content:
              'video' in media ? (
                <img src={makeYouTubeThumbnailFromId(media.video)} alt="" />
              ) : (
                <img className={styles.slideImg} src={media.image} alt="" />
              ),
          })}
        />
      </MuiDialog>
    </>
  );
}
