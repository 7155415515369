import { createContext } from 'react';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

interface Pdf {
  blob: Blob;
  url: string;
}

export default createContext<{
  getPdf: (programRef: string) => Pdf | undefined;
  programs: Record<string, ProgramTypeV2 | undefined>;
  generate: (ref: string) => Promise<string>;
  isGenerating: Record<string, boolean | undefined>;
}>({
  getPdf: () => undefined,
  programs: {},
  generate: () => Promise.resolve(''),
  isGenerating: {},
});
