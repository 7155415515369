import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_DISPLAY_LOTS,
  LABEL_DISPLAY_MAP,
  LABEL_SAVE_TO_FOLDER,
  LABEL_SEND_BOOKLET,
  LABEL_SEND_PROGRAM_CARD,
} from 'settings/labels';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import programPdfContext from 'modules/App/Contexts/programPdfContext';
import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { modifyQuery } from 'services/url';

import { usePanels } from 'hooks/usePanels';

import Button from 'commonUi/Button/Button';
import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import FolderLinks from 'modules/Folders/FolderLinks';

import styles from './ProgramResultActions.module.scss';

interface ProgramCardActionsProps {
  className?: string;
  hideMapButton?: boolean;
  program: ProgramListType;
  openModalSendBooklet?: (programRef: string) => void;
  reduceMobilePanel?: () => void;
  setIsPanelExpanded?: (isPanelExpanded: boolean) => void;
  showLotsListButton?: boolean;
  showSendProgramPdfButton?: boolean;
  tmsInfos?: Record<'confirmation' | 'default', Record<string, string>>;
}

export default function ProgramResultActions({
  className,
  hideMapButton = false,
  openModalSendBooklet = () => {},
  program,
  reduceMobilePanel,
  setIsPanelExpanded,
  showLotsListButton = true,
  showSendProgramPdfButton = true,
  tmsInfos,
}: ProgramCardActionsProps) {
  const history = useHistory();
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const { getPdf, programs, generate, isGenerating } = useContext(programPdfContext);
  const pdf = getPdf(program.ref);
  const [isModalPdfOpen, setIsModalPdfOpen] = useState(false);
  const [openModalPdfWhenReady, setOpenModalPdfWhenReady] = useState(false);

  useEffect(() => {
    if (openModalPdfWhenReady && pdf?.url) {
      setIsModalPdfOpen(true);
      setOpenModalPdfWhenReady(false);
    }
  }, [openModalPdfWhenReady, pdf?.url]);

  const {
    emptyDatas,
    folderSaveType,
    setFolderPrograms,
    setFolderSaveType,
    setFolderTms,
  } = useContext(foldersContext);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  function handleDisplayOnMapClick() {
    if (typeof reduceMobilePanel === 'function') {
      reduceMobilePanel();
    }
    history.replace(
      modifyQuery({
        programRef: program.ref,
        origin: 'list',
        lat: program.lat,
        lng: program.lng,
        zoom: 14,
      })
    );
  }

  function handleDisplayLotsClick() {
    history.replace(
      modifyQuery({
        origin: 'map',
        programRef: program.ref,
        type: 'lot',
        from: 'display_lots_btn',
      })
    );
    if (typeof setIsPanelExpanded === 'function') {
      setIsPanelExpanded(true);
    }
  }

  function handleClickAddFolder() {
    if (folderSaveType !== 'program') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('program');
    setFolderPrograms([{ programName: program.name, programRef: program.ref }]);
    if (tmsInfos) {
      setFolderTms(tmsInfos);
    }
  }

  return (
    <div className={classnames(className, styles.root)}>
      {showSendProgramPdfButton && (
        <div className={styles.item}>
          <ButtonModalPdf
            pdf={pdf}
            open={isModalPdfOpen}
            onClose={() => setIsModalPdfOpen(false)}
            program={programs[program.ref]}
          >
            <Button
              className={styles.button}
              variant="outlined"
              iconClass={styles.svg}
              iconId="icon-program-card"
              tooltip={LABEL_SEND_PROGRAM_CARD}
              color="primary"
              loading={isGenerating[program.ref]}
              onClick={() => {
                if (pdf) {
                  setIsModalPdfOpen(true);
                } else {
                  setOpenModalPdfWhenReady(true);
                  generate(program.ref);
                }
              }}
            />
          </ButtonModalPdf>
        </div>
      )}
      <div className={styles.item}>
        <Button
          className={styles.button}
          color="primary"
          iconId="icon-booklet"
          iconClass={styles.svg}
          onClick={() => openModalSendBooklet(program.ref)}
          variant="outlined"
          tooltip={LABEL_SEND_BOOKLET}
        />
      </div>
      <div className={styles.item}>
        <Button
          className={styles.button}
          color="primary"
          iconId="icon-add-to-folder"
          iconClass={styles.svg}
          onClick={handleClickAddFolder}
          variant="outlined"
          tooltip={LABEL_SAVE_TO_FOLDER}
        />
      </div>
      {!isResponsive && !isTablette && showLotsListButton && (
        <div className={styles.item}>
          <Button
            className={styles.button}
            color="primary"
            iconId="icon-display-bicolor"
            iconClass={styles.svg}
            onClick={handleDisplayLotsClick}
            variant="outlined"
            tooltip={LABEL_DISPLAY_LOTS}
          />
        </div>
      )}
      {(isTablette || isResponsive) && !hideMapButton && (
        <div className={styles.item}>
          <Button
            className={styles.button}
            color="primary"
            icon="map-plan"
            iconClass={styles.icon}
            onClick={handleDisplayOnMapClick}
            tooltip={LABEL_DISPLAY_MAP}
            variant="outlined"
          />
        </div>
      )}

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
    </div>
  );
}
