import React, { useEffect, useMemo, useRef } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';

import {
  FormControl as MuiFormControl,
  Input as MuiInput,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
} from '@material-ui/core';

import { LABEL_AND_MORE } from 'settings/labels';
import { SEARCH_MAX_PRIZE } from 'settings/search';
import { CustomScrollbar } from '../CustomScrollbar/CustomScrollbar';
import Icon from '../../sharedModulesV4/common/components/Atoms/Icon';

import styles from './Filter.module.scss';

export const FILTER_TYPE_LIST = 'list';
export const FILTER_TYPE_RANGE = 'range';

type FilterType = typeof FILTER_TYPE_LIST | typeof FILTER_TYPE_RANGE;
type Value = number | string;
type RangeValue = { max: Value; min: Value } | { max: undefined; min: undefined };
interface Option {
  label: string;
  value: Value;
}
interface FilterBaseProps
  extends Omit<
    ComponentProps<typeof MuiSelect>,
    | 'children'
    | 'classes'
    | 'IconComponent'
    | 'input'
    | 'MenuProps'
    | 'multiple'
    | 'onOpen'
    | 'open'
    | 'onClose'
    | 'renderValue'
    | 'value'
  > {
  classes?: Partial<Record<'label' | 'labelFocused' | 'select', string>>;
  filterInError?: boolean;
  id: string;
  label: string;
  labelMapping?: Map<string, string>;
  menuListClassName?: string;
  open: boolean;
  popoverClassName?: string;
  setOpen: (open: boolean) => void;
  isIcon?: boolean;
}
type ListChildren = (props: {
  multiple: true;
  options: Option[];
  value: Value[];
  labelMapping: Map<string, string> | undefined;
}) => ReactNode;
interface FilterListProps extends FilterBaseProps {
  children: ListChildren;
  filterType?: typeof FILTER_TYPE_LIST;
  options?: Option[];
  unit?: never;
  value: Value[];
  valueMapping?: Map<string, string>;
  valuesFormatter?: never;
}
type RangeChildren = (props: { value: RangeValue }) => ReactNode;
interface FilterRangeProps extends FilterBaseProps {
  children: RangeChildren;
  filterType: typeof FILTER_TYPE_RANGE;
  options?: never;
  unit?: string;
  value: RangeValue;
  valueMapping?: never;
  valuesFormatter?: (value: Value) => string | number;
}
type FilterProps<T extends FilterType> = T extends typeof FILTER_TYPE_LIST
  ? FilterListProps
  : FilterRangeProps;

export default function Filter<T extends FilterType>({
  children,
  classes = {},
  filterType = FILTER_TYPE_LIST,
  filterInError = false,
  id,
  label,
  labelMapping,
  open,
  menuListClassName,
  options = [],
  popoverClassName,
  setOpen,
  unit,
  value,
  valuesFormatter = x => x,
  valueMapping,
  isIcon = true,
  ...selectProps
}: FilterProps<T>) {
  const labelId = `${id}-label`;
  const valueLabelRef = useRef<HTMLDivElement>(null);

  const selectValue = useMemo(() => {
    if (filterType === FILTER_TYPE_LIST) {
      return value as Value[];
    }
    if (filterType === FILTER_TYPE_RANGE) {
      const range = value as RangeValue;
      if (range.min !== undefined && range.max !== undefined) {
        return [range.min, range.max];
      }
    }
    return [];
  }, [filterType, value]);

  function renderValue(value: Value[], options: Option[], labelMapping?: Map<string, string>) {
    if (filterType === FILTER_TYPE_RANGE) {
      if (value[0] !== undefined && value[1] !== undefined) {
        const andMore = value[1] === SEARCH_MAX_PRIZE ? LABEL_AND_MORE : '';
        const [min, max] = value.map(v => `${valuesFormatter(v)}${unit}`);
        return (
          <div>
            {min} à {max} {andMore}
          </div>
        );
      }
    }

    if (filterType === FILTER_TYPE_LIST) {
      const values = value as Value[];
      if (options.length > 0 && values.length > 0) {
        const selectedOptions = values.reduce<ReactNode[]>((array, val, idx) => {
          const option = options.filter(x => x.value === val);
          if (option.length === 0) return array;
          const formattedLabel = labelMapping?.get(option[0].label) ?? option[0].label;
          return [
            ...array,
            <span
              className={classnames(styles.chip, 'value-chip', { [styles.first]: idx === 0 })}
              key={formattedLabel}
            >
              {valueMapping ? valueMapping.get(formattedLabel) : formattedLabel}
            </span>,
          ];
        }, []);
        if (selectedOptions.length) {
          return (
            <div className={styles.selectValue} ref={valueLabelRef}>
              {selectedOptions}
            </div>
          );
        }
        return null;
      }
    }

    return null;
  }

  useEffect(() => {
    if (valueLabelRef.current) {
      let totalWidth = 0;
      let totalHidden = 0;
      const valueLabelOffsetWidth = valueLabelRef.current.offsetWidth;
      const elem = valueLabelRef.current.getElementsByClassName('nb-hidden-values');
      if (elem.length && elem[0].parentElement) {
        elem[0].parentElement.removeChild(elem[0]);
      }
      const chips = valueLabelRef.current.querySelectorAll<HTMLElement>('.value-chip');
      if (chips.length > 1) {
        chips.forEach((valueChip, idx) => {
          valueChip.classList.remove(styles.hidden);
          totalWidth += valueChip.offsetWidth;
          if (idx > 0 && totalWidth > valueLabelOffsetWidth - 16) {
            valueChip.classList.add(styles.hidden);
            totalHidden += 1;
          }
        });
        if (totalHidden > 0) {
          valueLabelRef.current.classList.remove(styles.full);
          const node = document.createElement('span');
          node.classList.add('nb-hidden-values');
          const textnode = document.createTextNode(`+${totalHidden}`);
          node.appendChild(textnode);
          valueLabelRef.current.appendChild(node);
        }
      }
      if (chips.length === 1) {
        valueLabelRef.current.classList.add(styles.full);
      }
    }
  }, [value]);

  return (
    <MuiFormControl
      classes={{
        root: styles.root,
      }}
      fullWidth
      variant="outlined"
    >
      <MuiInputLabel
        classes={{
          filled: styles.labelFilled,
          focused: classnames(classes.labelFocused, styles.labelFocused),
          root: classnames(classes.label, styles.labelRoot),
        }}
        id={labelId}
      >
        {label}
      </MuiInputLabel>

      <MuiSelect
        classes={{
          root: classnames(classes.select, styles.selectRoot),
        }}
        IconComponent={() => (isIcon ? <Icon className={styles.icon} icon="chevron-down" /> : null)}
        input={
          <MuiInput
            classes={{
              focused: styles.selectInputFocused,
              formControl: styles.selectInputFormControl,
              underline: styles.selectInputUnderline,
            }}
            id={id}
          />
        }
        MenuProps={{
          anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
          classes: {
            list: classnames(menuListClassName, styles.menuList),
          },
          getContentAnchorEl: null,
          MenuListProps: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            component: 'div', // Material-UI is lying about this typing
          },
          PopoverClasses: {
            paper: styles.paper,
            root: classnames(popoverClassName, styles.popover),
          },
        }}
        multiple
        onOpen={() => setOpen(true)}
        open={open}
        onClose={() => {
          if (!filterInError) {
            setOpen(false);
          }
        }}
        renderValue={(value: Value[]) => renderValue(value, options, labelMapping)}
        value={selectValue}
        {...selectProps}
      >
        <CustomScrollbar>
          {filterType === FILTER_TYPE_LIST
            ? (children as ListChildren)({
                multiple: true,
                options,
                value: value as Value[],
                labelMapping,
              })
            : (children as RangeChildren)({ value: value as RangeValue })}
        </CustomScrollbar>
      </MuiSelect>
    </MuiFormControl>
  );
}
