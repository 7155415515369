import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import parse from 'html-react-parser';

import styles from './FieldRte.module.scss';

function getScriptBufferer<T>(setScript: React.Dispatch<React.SetStateAction<T[]>>) {
  const buffer: T[] = [];
  let timestamp: NodeJS.Timeout | undefined;
  return (script: T) => {
    buffer.push(script);
    if (timestamp) {
      clearTimeout(timestamp);
    }
    timestamp = setTimeout(() => {
      setScript(buffer);
      buffer.splice(0, buffer.length); // Empty buffer array after processing it
    }, 50);
  };
}

export default function FieldRte({ className = undefined, html }: FieldRteType) {
  type ScriptType = { attributes: Record<string, string>; content?: string };
  const [scripts, setScripts] = useState<ScriptType[]>([]);

  const parsedContent = useMemo(() => {
    if (!html) {
      return null;
    }
    const bufferScript = getScriptBufferer<ScriptType>(setScripts);
    return parse(html, {
      replace: domNode => {
        if (domNode.type === 'script' && 'attribs' in domNode) {
          bufferScript({
            attributes: domNode.attribs,
            content: ((domNode.children?.[0] as unknown) as { data: string })?.data,
          });
          return <></>;
        }
        return domNode;
      },
    });
  }, [html]);

  if (!parsedContent) {
    return null;
  }

  return (
    <>
      <Helmet>
        {scripts.map(({ attributes, content }) => (
          <script key={attributes.id || attributes.src || content?.slice(0, 25)} {...attributes}>
            {content}
          </script>
        ))}
      </Helmet>
      <div className={classnames(styles.root, className, 'rte-content')}>{parsedContent}</div>
    </>
  );
}

export type FieldRteType = {
  className?: string;
  html: string;
};
