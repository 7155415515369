import React, { useState } from 'react';
import classnames from 'classnames';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';

import {
  LABEL_HT,
  LABEL_NB_AVAILABLE_LOT,
  LABEL_NB_AVAILABLE_LOTS,
  LABEL_PROGRAM_PRICE_TABS,
  LABEL_TTC,
  LABEL_UNFURNISHED_PRICE,
  LABEL_VAT_NORMAL,
  LABEL_VAT_REDUCED,
} from 'settings/labels';
import { PROGRAM_TAX_TYPE_FURNISHED, PROGRAM_TAX_TYPE_UNFURNISHED } from 'settings/programs';
import { TAX_TYPE_MALRAUX } from 'settings/taxes';
import { TOKEN_NB } from 'settings/token';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { formatPriceRange, replaceTokens } from 'services/formatter';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ProgramBoosterPrices.module.scss';

const PRICE_TAB_NORMAL = 'normal';
const PRICE_TAB_REDUCED = 'reduced';

interface ProgramBoosterPricesProps {
  addToFolderHandler: () => void;
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramBoosterPrices({
  addToFolderHandler,
  className,
  program,
}: ProgramBoosterPricesProps) {
  const [activeTab, setActiveTab] = useState(PRICE_TAB_NORMAL);

  const nbAvailableLotsContent = program.nbLotsDisponibles ? (
    <div className={styles.lots}>
      {replaceTokens(
        Number(program.nbLotsDisponibles) === 1 ? LABEL_NB_AVAILABLE_LOT : LABEL_NB_AVAILABLE_LOTS,
        {
          [TOKEN_NB]: program.nbLotsDisponibles,
        }
      )}
    </div>
  ) : null;

  const addToFolderButton = (
    <button className={styles.addToFolderButton} type="button" onClick={addToFolderHandler}>
      <Icon className={styles.addToFolderButtonIcon} icon="add-to-folder" />
    </button>
  );

  if (program.informationsFiscalesEtFinancieres.fiscalites.includes(TAX_TYPE_MALRAUX)) {
    const formattedPrice = formatPriceRange(
      program.informationsFiscalesEtFinancieres.prixHorsMobilier.split('-').every(Boolean)
        ? program.informationsFiscalesEtFinancieres.prixHorsMobilier
        : program.informationsFiscalesEtFinancieres.prixTVAReduite,
      '€',
      false,
      true
    );
    if (!formattedPrice) {
      return null;
    }
    return (
      <div className={classnames(className, styles.pricesWrapper)}>
        <div className={styles.prices}>
          <div>
            <span className={styles.pricesAmount}>
              {formattedPrice} {LABEL_TTC}
            </span>
            {nbAvailableLotsContent}
          </div>
          <div>{addToFolderButton}</div>
        </div>
      </div>
    );
  }

  if (program.informationsFiscalesEtFinancieres.typeFiscalite === PROGRAM_TAX_TYPE_FURNISHED) {
    const formattedPrice = formatPriceRange(
      program.informationsFiscalesEtFinancieres.prixHorsMobilier,
      '€',
      false,
      true
    );
    if (!formattedPrice) {
      return null;
    }
    return (
      <div className={classnames(className, styles.pricesWrapper)}>
        <div className={styles.prices}>
          <div>
            <span className={styles.pricesAmount}>
              {formattedPrice} {LABEL_HT}*
            </span>
            <span className={styles.pricesSub}>* {LABEL_UNFURNISHED_PRICE}</span>
            {nbAvailableLotsContent}
          </div>
          <div>{addToFolderButton}</div>
        </div>
      </div>
    );
  }

  if (program.informationsFiscalesEtFinancieres.typeFiscalite === PROGRAM_TAX_TYPE_UNFURNISHED) {
    const formattedPriceReduced = formatPriceRange(
      program.informationsFiscalesEtFinancieres.prixTVAReduite,
      '€',
      false,
      true
    );
    const formattedPriceNormal = formatPriceRange(
      program.informationsFiscalesEtFinancieres.prixTVANormal,
      '€',
      false,
      true
    );

    if (!formattedPriceReduced && !formattedPriceNormal) {
      return null;
    }

    if (!formattedPriceReduced) {
      return (
        <div className={classnames(className, styles.pricesWrapper)}>
          <div className={styles.prices}>
            <div>
              <span className={styles.pricesAmount}>
                {formattedPriceNormal} {LABEL_TTC}
              </span>
              {nbAvailableLotsContent}
            </div>
            <div>{addToFolderButton}</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <MuiTabs
          classes={{
            root: classnames(className, styles.tabs),
            scroller: styles.scroller,
            flexContainer: styles.flexContainer,
            indicator: styles.indicator,
          }}
          value={activeTab}
          onChange={(_, newTab) => setActiveTab(newTab)}
          variant="fullWidth"
          textColor="primary"
          aria-label={LABEL_PROGRAM_PRICE_TABS}
        >
          <MuiTab
            classes={{
              root: classnames(styles.tab, styles.selectorWeight),
              selected: styles.selected,
            }}
            label={LABEL_VAT_NORMAL}
            value={PRICE_TAB_NORMAL}
            id={`prices-tab-${PRICE_TAB_NORMAL}`}
            aria-controls={`prices-panel-${PRICE_TAB_NORMAL}`}
          />
          <MuiTab
            classes={{
              root: classnames(styles.tab, styles.selectorWeight),
              selected: styles.selected,
            }}
            label={LABEL_VAT_REDUCED}
            value={PRICE_TAB_REDUCED}
            id={`prices-tab-${PRICE_TAB_REDUCED}`}
            aria-controls={`prices-panel-${PRICE_TAB_REDUCED}`}
          />
        </MuiTabs>
        <div
          role="tabpanel"
          hidden={activeTab !== PRICE_TAB_NORMAL}
          id={`prices-panel-${PRICE_TAB_NORMAL}`}
          aria-labelledby={`prices-tab-${PRICE_TAB_NORMAL}`}
          className={styles.pricesWrapper}
        >
          <div className={styles.prices}>
            <div>
              <span className={styles.pricesAmount}>
                {formattedPriceNormal} {LABEL_TTC}
              </span>
              {nbAvailableLotsContent}
            </div>
            <div>{addToFolderButton}</div>
          </div>
        </div>
        <div
          role="tabpanel"
          hidden={activeTab !== PRICE_TAB_REDUCED}
          id={`prices-panel-${PRICE_TAB_REDUCED}`}
          aria-labelledby={`prices-tab-${PRICE_TAB_REDUCED}`}
          className={styles.pricesWrapper}
        >
          <div className={styles.prices}>
            <div>
              <span className={styles.pricesAmount}>
                {formattedPriceReduced} {LABEL_TTC}
              </span>
              {nbAvailableLotsContent}
            </div>
            <div>{addToFolderButton}</div>
          </div>
        </div>
      </>
    );
  }

  return null;
}
