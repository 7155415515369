import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import { LABEL_GROUND_PLAN, LABEL_PERSPECTIVES } from 'settings/labels';
import { DOC_NATURE_PLAN_MASSE } from 'settings/documents';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramPdfDocumentsStyles';

export default function ProgramPdfDocuments({ program }: ProgramPdfDocumentsProps) {
  const {
    documents,
    perspectives: { slider: images },
  } = program;
  const groundPlans = documents.filter(
    d =>
      d.nature === DOC_NATURE_PLAN_MASSE &&
      new RegExp(`\\.${['png', 'jpg', 'jpeg'].join('|')}$`).test(d.url)
  );

  return (
    <View style={styles.block}>
      {images && images.length > 0 && (
        <View>
          <Text style={styles.title}>{LABEL_PERSPECTIVES}</Text>
          {images.map(image => (
            <Image
              key={image}
              style={styles.image}
              source={{
                uri: `${image}?pdf`,
                method: 'GET',
                body: '',
                headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache' },
              }}
            />
          ))}
        </View>
      )}
      {groundPlans && groundPlans.length > 0 && (
        <View style={styles.block}>
          <Text style={styles.title}>{LABEL_GROUND_PLAN}</Text>
          {groundPlans.map(p => (
            <Image key={p.id} style={styles.image} src={p.url} />
          ))}
        </View>
      )}
    </View>
  );
}

export type ProgramPdfDocumentsProps = {
  program: ProgramTypeV2;
};
