import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import {
  LABEL_BALCONY,
  LABEL_CAVE,
  LABEL_GARDEN,
  LABEL_LOGGIA,
  LABEL_NORMAL_VAT,
  LABEL_PARKING,
  LABEL_REDUCED_VAT,
  LABEL_SELL_INCLUSION,
  LABEL_TERRACE,
} from 'settings/labels';
import { formatPrice, numberFormatter } from 'services/formatter';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import PdfAvailability from '../../../Common/PdfAvailability/PdfAvailability';

import styles from './LotPdfInfosStyles';

export default function LotPdfAdditionalInfos({ lot }: LotPdfAdditionalInfosProps) {
  return (
    <View style={styles.subCols}>
      {lot?.parkingVi3p?.length > 0 && (
        <View style={styles.subColsCol}>
          {lot.parkingVi3p.map(parking => (
            <View key={parking.nom}>
              <View>
                <View style={styles.parkingList}>
                  <View style={styles.listDot} />
                  <Text style={styles.listLabel}>{LABEL_PARKING}*</Text>
                  <Text style={styles.listValue}>{parking.nom}</Text>
                </View>
                <View>
                  <View style={styles.parkingDetails}>
                    <Text style={styles.parkingDetailsLabel}>{LABEL_NORMAL_VAT}</Text>
                    <Text style={styles.parkingDetailsValue}>
                      {formatPrice(parking.prixTauxTvaNormale)}
                    </Text>
                  </View>
                  <View style={styles.parkingDetails}>
                    <Text style={styles.parkingDetailsLabel}>{LABEL_REDUCED_VAT}</Text>
                    <Text style={styles.parkingDetailsValue}>
                      {formatPrice(parking.prixTauxTvaReduite)}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.note}>
                <Text>*{LABEL_SELL_INCLUSION}</Text>
              </View>
            </View>
          ))}
        </View>
      )}

      <View style={styles.subColsCol}>
        {lot?.surfaces?.surfaceJardin > 0 && (
          <PdfAvailability
            label={LABEL_GARDEN}
            value={
              numberFormatter(lot?.surfaces?.surfaceJardin)
                ? `${numberFormatter(lot.surfaces.surfaceJardin)} m²`
                : undefined
            }
          />
        )}
        {lot?.caveVi3p?.length > 0 && <PdfAvailability label={LABEL_CAVE} />}
        {lot?.surfaces?.surfaceBalcon > 0 && (
          <PdfAvailability
            label={LABEL_BALCONY}
            value={
              numberFormatter(lot?.surfaces?.surfaceBalcon)
                ? `${numberFormatter(lot.surfaces.surfaceBalcon)} m²`
                : undefined
            }
          />
        )}
        {lot?.surfaces.surfaceTerrasse > 0 && (
          <PdfAvailability
            label={LABEL_TERRACE}
            value={
              numberFormatter(lot?.surfaces?.surfaceTerrasse)
                ? `${numberFormatter(lot.surfaces.surfaceTerrasse)} m²`
                : undefined
            }
          />
        )}
        {lot?.surfaces.surfaceLoggia > 0 && (
          <PdfAvailability
            label={LABEL_LOGGIA}
            value={
              numberFormatter(lot?.surfaces?.surfaceLoggia)
                ? `${numberFormatter(lot.surfaces.surfaceLoggia)} m²`
                : undefined
            }
          />
        )}
      </View>
    </View>
  );
}

export type LotPdfAdditionalInfosProps = {
  lot: LotTypeV2;
};
