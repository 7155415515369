import React from 'react';

import SliderArrow from 'commonUi/SliderArrow/SliderArrow';

/* For some reason, React-Slick doesn't behave the same way when it's handling the next/prev actions
 * on its own compared to when we call `slickNext`, `slickPrev` or `slickGoTo`. The bahaviour we
 * want is the later, so we force it by not passing the `onClick` handler given by the library to
 * the next/prev components, passing instead what we'll aptly call here `realOnClick`. */
export default function Arrow({ onClick = undefined, realOnClick, ...rest }: ArrowProps) {
  return <SliderArrow onClick={realOnClick} {...rest} />;
}

export type ArrowProps = {
  onClick?: () => void;
  realOnClick: () => void;
};
