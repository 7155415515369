import React from 'react';

import { ParagraphApiAny } from 'types/paragraph';

import Paragraph from './Paragraph';

interface ParagraphsProps {
  className?: string;
  containerClassName?: string;
  paragraphs?: ParagraphApiAny[];
}

export default function Paragraphs({
  className,
  containerClassName,
  paragraphs,
  ...rest
}: ParagraphsProps) {
  if (!paragraphs?.length) {
    return null;
  }

  return (
    <>
      {paragraphs.map(paragraph => {
        return paragraph ? (
          <Paragraph
            key={paragraph.uuid}
            className={className}
            containerClassName={containerClassName}
            paragraph={paragraph}
            {...rest}
          />
        ) : null;
      })}
    </>
  );
}
