import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '0 0 6px',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 4px 0 0',
    width: '8px',
  },
  label: {
    fontSize: '7px',
  },
  value: {
    color: COLORS.PRIMARY.BLUE_DARK,
    fontSize: '7px',
    fontWeight: 'medium',
    margin: '0 0 0 3px',
  },
});
