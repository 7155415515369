import { ERROR_MSG_CONTACT_ID_NOT_RETRIEVED_FROM_TOKEN } from 'settings/documents';
import { error } from './log';

export function getDocumentUrl(documentId: string, contactId: string | undefined) {
  if (!contactId) {
    error('logic', ERROR_MSG_CONTACT_ID_NOT_RETRIEVED_FROM_TOKEN);
    return undefined;
  }

  return `${window.vinci.REACT_APP_VINCI_VI3P_API_URL}document/${contactId}/${documentId}`;
}
