import React from 'react';
import classnames from 'classnames';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import LaunchAlertCta from 'commonUi/LaunchAlertCta/LaunchAlertCta';

import styles from './ProgramBoosterPreview.module.scss';

const MODAL_TMS_VALUES = {
  open: (programRef: string) => ({
    navigation_pagename: 'programme.alerteav',
    navigation_template: `programme.${programRef}`,
  }),
  confirmed: (programRef: string) => ({
    navigation_pagename: 'programme.alerteav.confirmation',
    navigation_template: `programme.${programRef}`,
  }),
} as const;

interface ProgramBoosterPreviewProps {
  addToFolderHandler: () => void;
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramBoosterPreview({
  addToFolderHandler,
  className,
  program,
}: ProgramBoosterPreviewProps) {
  return (
    <div className={classnames(className, styles.root)}>
      <LaunchAlertCta
        big
        classes={{ root: styles.alert, active: styles.active }}
        launch={program.dates.lancementCommercial}
        programData={{
          city: program.localisation.ville,
          fiscalite: program.informationsFiscalesEtFinancieres.fiscalites[0],
          name: program.nomCommercial,
          postalCode: program.localisation.codePostal,
          programRef: program.referenceProgramme,
        }}
        modalTmsValues={MODAL_TMS_VALUES}
      />

      <button className={styles.addToFolder} type="button" onClick={addToFolderHandler}>
        <Icon icon="add-to-folder" />
      </button>
    </div>
  );
}
