import React, { useContext, useState } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import { LABEL_DOWNLOAD_BOOKLET, LABEL_SEND_BOOKLET } from 'settings/labels';

import type { ProgramListType, ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { ProgramCard } from 'commonUi/ProgramCard/ProgramCard';
import Button from 'commonUi/Button/Button';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { FormSendBooklet } from './FormSendBooklet';

import styles from '../ModalAction.module.scss';

interface ModalSendBookletProps {
  booklet: { name: string; url: string };
  closeCallBack: () => void;
  open: boolean;
  // ModalSendBooklet can be called from the Search context or from other places so it needs to be able to handle both types of Programs
  program: ProgramTypeV2 | ProgramListType | undefined;
  title?: ReactNode;
  downloadLabel?: ReactNode;
}

export default function ModalSendBooklet({
  booklet,
  closeCallBack,
  open,
  program,
  title = LABEL_SEND_BOOKLET,
  downloadLabel = LABEL_DOWNLOAD_BOOKLET,
}: ModalSendBookletProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { setEventPageVars } = useContext(TmsContext);
  const [loading, setLoading] = useState<boolean>(false);

  function handleClose() {
    setEventPageVars({});
    if (typeof closeCallBack === 'function') {
      closeCallBack();
    }
  }

  if (!open || !program) {
    return null;
  }

  return (
    <div className={classNames('show', [styles.show])}>
      <div className={classNames('show', [styles.show])}>
        <MuiDialog
          open={open}
          onClose={handleClose}
          classes={{
            paper: styles.modalSkin,
            root: styles.modalRootForm,
            scrollPaper: styles.scrollPaper,
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              <Icon className={styles.icon} icon="booklet" />
              <span>{title}</span>
            </div>
            <div className={styles.rightContent}>
              {!isResponsive && (
                <div className={styles.actionButton}>
                  <Button
                    className={styles.link}
                    variant="contained"
                    color="primary"
                    disabled={!booklet.url || booklet.url === '#'}
                    href={booklet.url}
                    icon="download"
                    iconClass={styles.icon}
                    target="_blank"
                  >
                    {downloadLabel}
                  </Button>
                </div>
              )}
              <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
                <Icon className={styles.svgIcon} icon="cross" />
              </IconButton>
            </div>
          </div>

          <div className={styles.modalContent}>
            <div className={styles.programWardWrapper}>
              <ProgramCard program={program} />
            </div>
            {isResponsive && (
              <div className={styles.actionButton}>
                <Button
                  className={styles.link}
                  variant="contained"
                  color="primary"
                  disabled={!booklet.url || booklet.url === '#'}
                  href={booklet.url}
                  icon="download"
                  iconClass={styles.icon}
                  target="_blank"
                >
                  {LABEL_DOWNLOAD_BOOKLET}
                </Button>
              </div>
            )}
            <FormSendBooklet
              booklet={booklet}
              handleClose={handleClose}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </MuiDialog>
      </div>

      <TagCommanderEvent
        isActive={open}
        navigation_pagename={loading ? 'form_envoibrochure.confirmation' : 'form_envoibrochure'}
        navigation_template="brochure"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}
