import React, { useContext } from 'react';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import { ComparisonsList } from './ComparisonsList';
import { LotsList } from './LotsList';
import { ProgramsList } from './ProgramsList';

export function ListAddItems() {
  const { folderSaveType } = useContext(foldersContext);

  switch (folderSaveType) {
    case 'comparison':
      return <ComparisonsList />;

    case 'program':
      return <ProgramsList />;

    case 'lot':
      return <LotsList />;

    default:
      return null;
  }
}
