import React, { useState } from 'react';

import { ModalUiContext, ModalUiContextDefault } from 'modules/App/Contexts/ModalUiContext';

export default function ModalUiProvider({ children }: ModalUiProviderType) {
  const [modal, setModal] = useState(ModalUiContextDefault);
  return <ModalUiContext.Provider value={[modal, setModal]}>{children}</ModalUiContext.Provider>;
}

export type ModalUiProviderType = {
  children: JSX.Element;
};
