import React, { useContext } from 'react';

import { LABEL_SOON_TITLE } from 'settings/labels';

import type Housing from 'api/viOffresAPI/apiTypes/Housing';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import useDisableSlider from 'hooks/useDisableSlider';

import SliderArrow from 'commonUi/HomeSliders/SliderArrow';

import SliderContainer from 'layout/components/SliderContainer/SliderContainer';

import UpcomingSliderItem from './UpcomingSliderItem';

import styles from './UpcomingSlider.module.scss';

const SLIDER_ITEM_WIDTH = 315;
const SLIDER_ITEM_GUTTER = 0;

interface UpcomingSliderProps {
  content?: Housing[];
}

export default function UpcomingSlider({ content = [] }: UpcomingSliderProps) {
  const { isDisabled, sliderRef } = useDisableSlider(
    content.length,
    SLIDER_ITEM_WIDTH,
    SLIDER_ITEM_GUTTER
  );
  const { isResponsive } = useContext(ResponsiveContext);

  if (content.length === 0) {
    return null;
  }

  return (
    <SliderContainer
      ref={sliderRef}
      className={styles.root}
      sliderSettings={{
        arrows: isResponsive || !isDisabled,
        autoplay: false,
        dots: false,
        infinite: isResponsive || !isDisabled,
        nextArrow: <SliderArrow label="Suivant" />,
        prevArrow: <SliderArrow label="Précédent" />,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: true,
            },
          },
        ],
        speed: 1000,
        slidesToScroll: 1,
        touchMove: isResponsive || !isDisabled,
        variableWidth: true,
      }}
      title={LABEL_SOON_TITLE}
    >
      {content.map(item => (
        <UpcomingSliderItem
          key={item.nid}
          className={styles.card}
          data={item}
          style={
            !isResponsive
              ? {
                  width: `${SLIDER_ITEM_WIDTH}px`,
                  margin: `0 ${SLIDER_ITEM_GUTTER / 2}px`,
                }
              : undefined
          }
        />
      ))}
    </SliderContainer>
  );
}
