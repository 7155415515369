import React, { ReactNode } from 'react';
import classnames from 'classnames';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ProgramContentBlock.module.scss';

export default function ProgramContentBlock({
  children,
  classes,
  icon,
  svgIcon,
  title = undefined,
  withBg = false,
}: ProgramContentBlockProps) {
  return (
    <div className={classnames(styles.root, classes?.rootClass, { [styles.withBg]: withBg })}>
      <div className={classnames(styles.content, classes?.contentClass)}>
        {title && (
          <div className={styles.title}>
            {icon && <Icon className={classnames(styles.icon, styles.titleIcon)} icon={icon} />}
            {svgIcon && (
              <SvgIcon className={classnames(styles.icon, styles.titleSvgIcon)} iconId={svgIcon} />
            )}
            <span className={styles.titleLabel}>{title}</span>
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
}

export type ProgramContentBlockProps = {
  children: ReactNode;
  classes?: {
    contentClass?: string;
    rootClass?: string;
  };
  icon?: Icons;
  svgIcon?: string;
  title?: string;
  withBg?: boolean;
};
