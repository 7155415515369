import React, { ReactNode } from 'react';
import { Text, View } from '@react-pdf/renderer';

import styles from './PdfRowDetailListStyles';

interface PdfRowDetailsListProps {
  items: Record<
    string,
    {
      label: string;
      note?: ReactNode;
      value: string | undefined | null;
    }
  >;
}

export default function PdfRowDetailsList({ items }: PdfRowDetailsListProps) {
  if (!Object.entries(items).length) {
    return null;
  }

  return (
    <View>
      {Object.entries(items).map(([key, item]) => (
        <View key={key} style={styles.info}>
          <View style={styles.infoDot} />
          <View>
            <View style={styles.infoLine}>
              <Text style={styles.infoLabel}>{item.label}</Text>
              <Text style={styles.infoValue}>{item.value || '-'}</Text>
            </View>
            {item.note}
          </View>
        </View>
      ))}
    </View>
  );
}
