import React, { useContext } from 'react';
import classnames from 'classnames';

import { TAX_ICON } from 'settings/taxes';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import {
  programHasKitchen,
  programIsADB,
  programIsControlledPrice,
  programIsFNO,
  programIsFurnishEligible,
  programIsHonoBoost,
  programIsReducedVAT,
  programIsSenior,
  programIsStudent,
} from 'services/programs';

import taxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import KitchenIcon from 'commonUi/KitchenIcon/KitchenIcon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ProgramCardThumbnailPictos.module.scss';

interface ProgramCardThumbnailPictosProps {
  className?: string;
  program: ProgramListType;
}

export default function ProgramCardThumbnailPictos({
  className,
  program,
}: ProgramCardThumbnailPictosProps) {
  const { taxesById } = useContext(taxonomiesContext);
  const taxId = taxesById[program.taxes[0]];

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.left}>
        {taxId && taxId in TAX_ICON && (
          <SvgIcon className={styles.picto} iconId={TAX_ICON[taxId]} />
        )}
        {programIsControlledPrice(program) && (
          <SvgIcon className={styles.picto} iconId="icon-prix-maitrises" />
        )}
        {programIsReducedVAT(program) && <SvgIcon className={styles.picto} iconId="icon-tva-5-5" />}
        {programIsFNO(program) && <SvgIcon className={styles.picto} iconId="icon-fno" />}
        {programIsHonoBoost(program) && (
          <SvgIcon className={styles.picto} iconId="icon-honoboost" />
        )}
      </div>
      <div className={styles.right}>
        {programHasKitchen(program) && <KitchenIcon iconClassName={styles.picto} />}
        {programIsADB(program) && <SvgIcon className={styles.picto} iconId="icon-adb" />}
        {programIsFurnishEligible(program) && (
          <SvgIcon className={styles.picto} iconId="icon-furnish-eligible" />
        )}
        {programIsSenior(program) && <SvgIcon className={styles.picto} iconId="icon-ovelia" />}
        {programIsStudent(program) && <SvgIcon className={styles.picto} iconId="icon-student" />}
      </div>
    </div>
  );
}
