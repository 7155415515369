import React, { forwardRef } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';

import type { SliderRef, SliderSettings } from 'types/slider';

import styles from './SliderContainer.module.scss';

/**
 * Some trickery to avoid having react-slick annoying inline style applied to our nice component
 */
function SliderStyleSink<T extends { style?: CSSProperties }>({
  children,
  style: _,
}: PropsWithChildren<T>) {
  return <>{children}</>;
}

interface SliderContainerProps {
  className?: string;
  dark?: boolean;
  sliderSettings: Partial<SliderSettings>;
  title?: string;
}

export default forwardRef<SliderRef, PropsWithChildren<SliderContainerProps>>(
  function SliderContainer(
    { children, className = undefined, dark = false, sliderSettings, title = undefined },
    ref
  ) {
    return (
      <div className={classnames(className, styles.root, { [styles.dark]: dark })}>
        {title && <div className={styles.title}>{title}</div>}

        <Slider ref={ref} {...sliderSettings}>
          {Array.isArray(children) ? (
            children.map(child => <SliderStyleSink>{child}</SliderStyleSink>)
          ) : (
            <SliderStyleSink>{children}</SliderStyleSink>
          )}
        </Slider>
      </div>
    );
  }
);
