import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { formatActivityDate } from 'services/date';
import { PASTILLE_STATUS_COLOR } from 'services/pastille';
import { LOT_STATUS_OPTIONED, LOT_STATUS_PRE_BOOKED } from 'settings/lots';
import { LABEL_DATE_END_ON } from 'settings/labels';
import { PDF_PASTILLE_STATUS_ICON, PDF_PASTILLE_STATUS_ICON_WIDTHS_REDUCED } from 'settings/pdf';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';

import PdfPastille from '../PdfPastille/PdfPastille';

import styles from './PdfLotCellStatusStyles';

interface PdfLotCellStatusProps {
  content: string;
  lot: LotJson;
  statuses: Record<string, Status>;
}

export default function PdfLotCellStatus({ content, lot, statuses }: PdfLotCellStatusProps) {
  const { nid } = lot;

  const status = statuses[nid] && statuses[nid].label ? statuses[nid].label : content;
  const expirationDate = statuses[nid] ? statuses[nid].expirationDate : undefined;

  return (
    <View style={styles.root}>
      <View style={styles.pastille}>
        <PdfPastille
          backgroundColor={PASTILLE_STATUS_COLOR[status].bgColor}
          label={status}
          labelStyle={styles.pastilleLabel}
          picto={PDF_PASTILLE_STATUS_ICON[status]}
          pictoStyle={{ width: PDF_PASTILLE_STATUS_ICON_WIDTHS_REDUCED[status] }}
          textColor={PASTILLE_STATUS_COLOR[status].textColor}
        />
      </View>
      {(status === LOT_STATUS_OPTIONED || status === LOT_STATUS_PRE_BOOKED) && !!expirationDate && (
        <Text style={styles.expiration}>
          {LABEL_DATE_END_ON} {formatActivityDate(expirationDate, 'DD/MM/YY HH:mm')}{' '}
        </Text>
      )}
    </View>
  );
}
