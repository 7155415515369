import React, { useEffect, useState } from 'react';
import type { CSSProperties, ComponentPropsWithRef, Key, ReactNode } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import classnames from 'classnames';

import { LOT_GRID_NB_LINES } from 'settings/lots';
import { PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import type { HeadingType } from 'api/viOffresAPI/apiTypes/LotType';
import type { ActivityHeading } from 'api/vi3pAPI/apiTypes/ActivityType';

import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';

import styles from './TableV2.module.scss';

export interface TableV2Props<Heading> {
  className?: string;
  defaultHeight?: number;
  disableHeight?: boolean;
  error?: ReactNode;
  headerBorderRadius?: boolean;
  headerContent?: ReactNode;
  headerWithTop?: boolean;
  headings: Heading[];
  id?: string;
  listWrapperClassName?: string;
  minHeight?: number;
  nbLines?: number;
  onRowsRendered?: (index: number) => void;
  renderHeader: (col: Heading) => ReactNode;
  renderRow: (params: {
    index: number;
    isScrolling: boolean;
    isVisible: boolean;
    key: Key;
    parent: HTMLElement;
    style: CSSProperties | undefined;
  }) => ReactNode;
  rowCount: number;
  rowsListRef: ComponentPropsWithRef<typeof List>['ref'];
  scrollRef?: ComponentPropsWithRef<typeof CustomScrollbar>['ref'];
  setCurrentRowOpen?: React.Dispatch<React.SetStateAction<string>>;
}

export default function TableV2<Heading extends HeadingType | ActivityHeading>({
  className,
  disableHeight = false,
  error,
  headerBorderRadius = true,
  headerContent,
  headerWithTop = true,
  headings,
  id,
  listWrapperClassName,
  minHeight = 70,
  nbLines = LOT_GRID_NB_LINES,
  onRowsRendered,
  renderHeader,
  renderRow,
  rowCount,
  rowsListRef,
  scrollRef,
}: TableV2Props<Heading>) {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight,
  });
  const listMaxHeight = nbLines * minHeight;

  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
  const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);

  function handleScroll(e) {
    const { scrollTop, scrollLeft } = e.target;
    const { Grid } = rowsListRef.current;
    Grid.handleScrollEvent({ scrollTop, scrollLeft });
  }

  const [nb, setNb] = useState(rowCount);

  useEffect(() => {
    if (nb !== rowCount) {
      setNb(rowCount);
    }
  }, [nb, rowCount]);

  // Force update of virtualized table when number of rows changes
  if (nb !== rowCount) {
    return null;
  }

  return (
    <div className={classnames(className, styles.lotsListWrapper)} id={id}>
      {error}
      <div
        className={classnames(styles.lotsListHeader, {
          [styles.withTop]: headerWithTop,
        })}
        id="program-lots-list-header"
        style={
          programPromoBanner
            ? {
                top:
                  (programMenuAnchors?.offsetHeight || 0) + (programPromoBanner?.offsetHeight || 0),
              }
            : undefined
        }
      >
        {headerContent && <div>{headerContent}</div>}
        <div
          className={classnames(styles.listHeader, className ? styles[className] : undefined, {
            [styles.borderRadius]: headerBorderRadius,
          })}
        >
          {headings.map(col => renderHeader(col))}
        </div>
      </div>
      <div className={classnames(styles.listWrapper, listWrapperClassName)}>
        <AutoSizer disableHeight={disableHeight}>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <CustomScrollbar
                className={styles.scroll}
                options={{
                  callbacks: {
                    onScroll: e => handleScroll(e),
                  },
                }}
                ref={scrollRef}
              >
                <List
                  ref={rowsListRef}
                  height={disableHeight ? listMaxHeight : height}
                  width={width}
                  rowCount={rowCount}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  rowRenderer={({ index, isScrolling, isVisible, key, parent, style }) => (
                    <CellMeasurer
                      cache={cache}
                      columnIndex={0}
                      key={key}
                      parent={parent}
                      rowIndex={index}
                    >
                      {renderRow({ index, isScrolling, isVisible, key, parent, style })}
                    </CellMeasurer>
                  )}
                  containerStyle={{
                    maxWidth: '100%',
                    width: '100%',
                  }}
                  style={{
                    overflowX: false,
                    overflowY: false,
                    width: '100%',
                  }}
                  onRowsRendered={({ startIndex }) => {
                    if (typeof onRowsRendered === 'function') {
                      onRowsRendered(startIndex);
                    }
                  }}
                />
              </CustomScrollbar>
            </div>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
