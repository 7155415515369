import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import { SnackbarContent as MuiSnackbarContent } from '@material-ui/core';

import styles from './Toast.module.scss';

export const TOAST_VARIANT_SUCCESS = 'success';
export const TOAST_VARIANT_ERROR = 'error';
export const TOAST_VARIANT_WARNING = 'warning';
export const TOAST_VARIANT_INFO = 'info';

interface NotificationActionType {
  key: any;
  content: React.ReactNode;
  onClick?: React.MouseEventHandler;
  variant?: 'primary' | 'secondary';
}

interface NotificationType {
  action?: NotificationActionType | NotificationActionType[];
  id: string | number;
  message: React.ReactNode;
  variant?:
    | typeof TOAST_VARIANT_SUCCESS
    | typeof TOAST_VARIANT_ERROR
    | typeof TOAST_VARIANT_WARNING
    | typeof TOAST_VARIANT_INFO;
}

export default forwardRef<unknown, NotificationType>(
  ({ action: actionProps, id, message, variant = 'info' }, ref) => {
    const { closeSnackbar } = useSnackbar();

    let actions: NotificationActionType[] = [
      {
        key: 'close',
        content: 'Fermer',
        onClick: () => closeSnackbar(id),
      },
    ];
    if (Array.isArray(actionProps)) {
      actions = [...actionProps, ...actions];
    } else if (actionProps) {
      actions = [actionProps, ...actions];
    }

    return (
      <MuiSnackbarContent
        ref={ref}
        action={actions.map(({ key, content, onClick, variant = 'primary' }) => (
          <button
            key={key}
            type="button"
            className={classnames(styles.button, styles[variant])}
            onClick={onClick}
          >
            {content}
          </button>
        ))}
        classes={{
          root: classnames(styles.root, styles[variant], styles.selectorWeight),
          message: classnames(styles.message, styles.selectorWeight),
          action: classnames(styles.action, styles.selectorWeight),
        }}
        message={message}
      />
    );
  }
);
