import React from 'react';

import { formatPrice } from 'services/formatter';
import {
  LABEL_FROM,
  LABEL_HT,
  LABEL_LMNP,
  LABEL_PRICE_OF,
  LABEL_TO,
  LABEL_TTC,
} from 'settings/labels';

interface ProgramPriceProps {
  fiscality: string;
  highlightClassName?: string;
  max?: number;
  min: number;
}

export function ProgramPrice({ fiscality, highlightClassName, min, max = 0 }: ProgramPriceProps) {
  const tax = fiscality === LABEL_LMNP ? `(${LABEL_HT})` : `(${LABEL_TTC})`;

  if ((min && !max) || min === max) {
    return (
      <div className="result-item__price">
        {LABEL_FROM}{' '}
        <span className={highlightClassName}>
          {formatPrice(min)} {tax}
        </span>
      </div>
    );
  }

  if (min && max > 0) {
    return (
      <div className="result-item__price">
        {LABEL_PRICE_OF}{' '}
        <span className={highlightClassName}>
          {formatPrice(min)} {LABEL_TO} {formatPrice(max)}
        </span>{' '}
        {tax}
      </div>
    );
  }

  return null;
}
