import React, { useContext, useEffect, useState } from 'react';
import type { ComponentProps } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button as ButtonMui } from '@material-ui/core';
import classnames from 'classnames';

import {
  LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET,
  LABEL_PROMO,
  LABEL_SEND_LOT_CARD,
  LABEL_SEND_PROGRAM_CARD,
  LABEL_SHOW_LOT_DETAIL,
} from 'settings/labels';
import {
  LOT_CELL_ID_NUMBER,
  LOT_CELL_ID_PLAN,
  LOT_CELL_ID_PROGRAM,
  LOT_CELL_ID_STATUS,
  LOT_CELL_ID_TAX,
  LOT_CELL_TYPE_ACTION,
  LOT_CELL_TYPE_CHECKBOX,
  LOT_MOBILE_HEADINGS,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
} from 'settings/lots';
import {
  MODAL_ID_SEND_BOOKLET,
  MODAL_ID_SEND_CARD_LOT,
  MODAL_ID_SEND_CARD_PROGRAM,
} from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import lotPdfContext from 'modules/App/Contexts/lotPdfContext';
import programPdfContext from 'modules/App/Contexts/programPdfContext';

import { getLotActionButtons } from 'services/lots';
import { getPromotionStylesFromSettings } from 'services/promotions';
import { modifyQuery } from 'services/url';

import { useBooklet } from 'hooks/useBooklet';
import { usePanels } from 'hooks/usePanels';
import { useModalMultiples } from 'hooks/useModal';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import Button from 'commonUi/Button/Button';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';
import FolderLinks from 'modules/Folders/FolderLinks';
import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import LotCell from '../LotCell/LotCell';

import styles from './LotRowMobile.module.scss';

interface LotRowMobileProps {
  alerts: ComponentProps<typeof LotCell>['alerts'];
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  isChecked?: boolean;
  isDrawerOpen: boolean;
  lot: LotJson;
  myOptions?: ComponentProps<typeof LotCell>['myOptions'];
  myBookings?: ComponentProps<typeof LotCell>['myBookings'];
  onStatusAlertClick: ComponentProps<typeof LotCell>['onStatusAlertClick'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  reduceMobilePanel?: () => void;
  renderShowMore: () => JSX.Element | null;
  setCurrentRowOpen: (nid: string) => void;
  statuses: Record<string, Status>;
  taxById: TaxesById;
  updateStatus: (lotNid: string, newStatus: string) => void;
  withCheckbox?: boolean;
}

export default function LotRowMobile({
  renderShowMore,
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lot,
  reduceMobilePanel = undefined,
  statuses,
  taxById = {},
  updateStatus,
  withCheckbox = false,
  myOptions,
  myBookings,
  onStatusAlertClick,
  alerts,
  isChecked = false,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  isDrawerOpen,
  setCurrentRowOpen,
}: LotRowMobileProps) {
  const history = useHistory();
  const { settings } = useContext(SettingsContext);
  const { tax } = lot;

  const { openCancelOption, openCancelPreBooking, openCreateOption } = useContext(
    ActivityModalsContext
  );
  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(lot.ref, open === MODAL_ID_SEND_BOOKLET);

  const option = myOptions?.find(
    option =>
      option.field_numerolot_ac === lot.number && option.field_referenceprogramme_ac === lot.ref
  );
  const preBooking = myBookings?.find(
    booking =>
      booking.field_numerolot_ac === lot.number && booking.field_referenceprogramme_ac === lot.ref
  );

  const checkboxHead = headings.find(({ id }) => id === LOT_CELL_TYPE_CHECKBOX);
  const bodyHeadings = headings.filter(({ id }) => !LOT_MOBILE_HEADINGS.includes(id));
  const taxHead = headings.find(({ id }) => id === LOT_CELL_ID_TAX);
  const statusHead = headings.find(({ id }) => id === LOT_CELL_ID_STATUS);
  const numberHead = headings.find(({ id }) => id === LOT_CELL_ID_NUMBER);
  const programHead = headings.find(({ id }) => id === LOT_CELL_ID_PROGRAM);

  const sortBy = [LOT_CELL_ID_PLAN];
  const bodyHeadingsSorted = sortBy
    .map(id => bodyHeadings.find(heading => heading.id === id))
    .concat(bodyHeadings.filter(heading => !sortBy.includes(heading.id)));

  const taxName = taxById[tax];
  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType } = useContext(
    foldersContext
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();
  const promotionLabel =
    !!lot.promotions && lot.promotions.length > 0
      ? Object.values(lot.promotions[0])[0] || `<p>${LABEL_PROMO}</p>`
      : undefined;

  const {
    showCancelOption,
    showCancelPreBook,
    showMakeOption,
    showMakePrebook,
  } = getLotActionButtons(
    statuses[lot.nid]?.label ?? lot.status,
    Boolean(option) || Boolean(preBooking)
  );

  const {
    getPdf: getPdfProgram,
    programs: programPdfProgram,
    generate: generateProgram,
    isGenerating: isGeneratingProgram,
  } = useContext(programPdfContext);
  const pdfProgram = getPdfProgram(lot.ref);
  const [openModalProgramPdfWhenReady, setOpenModalProgramPdfWhenReady] = useState(false);

  useEffect(() => {
    if (openModalProgramPdfWhenReady && pdfProgram?.url) {
      openModal(MODAL_ID_SEND_CARD_PROGRAM);
      setOpenModalProgramPdfWhenReady(false);
    }
  }, [openModalProgramPdfWhenReady, pdfProgram?.url, openModal]);

  const {
    getPdf: getPdfLot,
    lotsDetail: lotPdfLots,
    programs: lotPdfPrograms,
    generate: generateLot,
    isGenerating: isGeneratingLot,
  } = useContext(lotPdfContext);
  const pdfLot = getPdfLot(lot.nid);
  const [openModalLotPdfWhenReady, setOpenModalLotPdfWhenReady] = useState(false);

  useEffect(() => {
    if (openModalLotPdfWhenReady && pdfLot?.url) {
      openModal(MODAL_ID_SEND_CARD_LOT);
      setOpenModalLotPdfWhenReady(false);
    }
  }, [openModalLotPdfWhenReady, pdfLot?.url, openModal]);

  return (
    <>
      {isDrawerOpen && renderShowMore()}
      <div className={styles.resultItemWrapper}>
        {numberHead && (
          <span className={styles.lotNumber}>
            <LotCell
              onStatusAlertClick={onStatusAlertClick}
              myBookings={myBookings}
              myOptions={myOptions}
              alerts={alerts}
              col={numberHead}
              lot={lot}
              statuses={statuses}
              taxById={taxById}
            />
          </span>
        )}
        <div className={styles.resultItemHeader}>
          <div className={styles.group}>
            {withCheckbox && (
              <LotCell
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={checkboxHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
                handleOnChangeCheckbox={handleOnChangeCheckbox}
                isChecked={isChecked}
              />
            )}
            {taxName && (
              <LotCell
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={taxHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
              />
            )}
          </div>
          <LotCell
            onStatusAlertClick={onStatusAlertClick}
            myBookings={myBookings}
            myOptions={myOptions}
            alerts={alerts}
            col={statusHead}
            lot={lot}
            statuses={statuses}
            taxById={taxById}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
          />
        </div>
        {(programHead || !hideMapButton) && (
          <div className={styles.resultItemHeader2}>
            {programHead && (
              <LotCell
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={programHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
              />
            )}
            {!hideMapButton && (
              <button
                className={styles.showMapBtn}
                type="button"
                onClick={() => {
                  if (typeof reduceMobilePanel === 'function') {
                    reduceMobilePanel();
                  }
                  history.replace(
                    modifyQuery({
                      programRef: lot.program.ref,
                      origin: 'list',
                      lat: lot.program.lat,
                      lng: lot.program.lng,
                      zoom: 14,
                    })
                  );
                }}
              >
                <Icon className={styles.showMapBtnIcon} icon="map-plan" />
              </button>
            )}
          </div>
        )}

        <div className={styles.actionsBtn}>
          {showMakeOption && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-option"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_OPTION}
                onClick={() =>
                  openCreateOption(
                    {
                      lotNumber: lot.number,
                      programRef: lot.ref,
                      programName: lot.program.name,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_OPTIONED);
                      }
                    }
                  )
                }
              />
            </span>
          )}
          {showCancelOption && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-cancel"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION}
                onClick={() => {
                  if (option?.field_idcrm_ac && option.field_dateexpiration_ac) {
                    openCancelOption(
                      {
                        activityId: option.field_idcrm_ac,
                        expirationDate: new Date(option.field_dateexpiration_ac),
                        lotNumber: option.field_numerolot_ac,
                      },
                      () => {
                        if (typeof updateStatus === 'function') {
                          updateStatus(lot.nid, LOT_STATUS_FREE);
                        }
                      }
                    );
                  }
                }}
              />
            </span>
          )}
          {showMakePrebook && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-pre-book"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_PREBOOK}
                component={RouterLink}
                to={`/pre-reservation/${lot.ref}/${lot.number}?backUrl=${encodeURIComponent(
                  window.location.href
                )}`}
              />
            </span>
          )}
          {showCancelPreBook && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-pre-book"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK}
                onClick={() => {
                  if (preBooking?.field_idcrm_ac && preBooking.field_dateexpiration_ac) {
                    openCancelPreBooking(
                      {
                        activityId: preBooking.field_idcrm_ac,
                        expirationDate: new Date(preBooking.field_dateexpiration_ac),
                        lotNumber: preBooking.field_numerolot_ac,
                      },
                      () => {
                        if (typeof updateStatus === 'function') {
                          updateStatus(lot.nid, LOT_STATUS_FREE);
                        }
                      }
                    );
                  }
                }}
              />
            </span>
          )}
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-add-to-folder"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER}
              color="primary"
              fullWidth
              onClick={() => {
                if (folderSaveType !== 'lot') {
                  emptyDatas();
                }
                openPanel(PANEL_ID_FOLDERS);
                setFolderSaveType('lot');
                setFolderLots([{ lotNumber: lot.number, programRef: lot.ref }]);
              }}
            />
          </span>
          <span className={styles.actionBtnContainer}>
            <ButtonModalPdf
              pdf={pdfProgram}
              program={programPdfProgram[lot.ref]}
              open={open === MODAL_ID_SEND_CARD_PROGRAM}
              onClose={() => closeModal()}
            >
              <Button
                loading={isGeneratingProgram[lot.ref]}
                onClick={() => {
                  if (pdfProgram) {
                    openModal(MODAL_ID_SEND_CARD_PROGRAM);
                  } else {
                    setOpenModalProgramPdfWhenReady(true);
                    generateProgram(lot.ref);
                  }
                }}
                className={styles.actionBtn}
                variant="outlined"
                iconClass={styles.svgIcon}
                iconId="icon-program-card"
                tooltip={LABEL_SEND_PROGRAM_CARD}
                color="primary"
                fullWidth
              />
            </ButtonModalPdf>
          </span>
          <span className={styles.actionBtnContainer}>
            <ButtonModalPdf
              pdf={pdfLot}
              program={lotPdfPrograms[lot.ref]}
              lot={lotPdfLots[lot.nid]}
              open={open === MODAL_ID_SEND_CARD_LOT}
              onClose={() => closeModal()}
            >
              <Button
                loading={isGeneratingLot[lot.nid]}
                onClick={() => {
                  if (pdfLot) {
                    openModal(MODAL_ID_SEND_CARD_LOT);
                  } else {
                    setOpenModalLotPdfWhenReady(true);
                    generateLot(lot.ref, lot.number, lot.nid);
                  }
                }}
                className={styles.actionBtn}
                variant="outlined"
                iconClass={styles.svgIcon}
                iconId="icon-lot-card"
                tooltip={LABEL_SEND_LOT_CARD}
                color="primary"
                fullWidth
              />
            </ButtonModalPdf>
          </span>
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-booklet"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET}
              color="primary"
              fullWidth
              onClick={() => openModal(MODAL_ID_SEND_BOOKLET)}
            />
          </span>
        </div>

        <ButtonMui
          onClick={() => setCurrentRowOpen(lot.nid)}
          classes={{
            root: classnames(styles.btnDetails, { [styles.btnDetailsPromo]: promotionLabel }),
            label: classnames(styles.btnDetailsLabel, {
              [styles.btnDetailsLabelPromo]: promotionLabel,
            }),
          }}
          fullWidth
          style={promotionLabel ? getPromotionStylesFromSettings(settings) : {}}
        >
          {!!promotionLabel && (
            <div className={styles.labelPromo}>
              <FieldRte className={styles.promoText} html={promotionLabel} />
            </div>
          )}
          {LABEL_SHOW_LOT_DETAIL}
        </ButtonMui>

        <dl className={styles.resultItemInfo}>
          {bodyHeadingsSorted.map((col: HeadingType) => {
            if (col.type === LOT_CELL_TYPE_ACTION) {
              return null;
            }
            if (Array.isArray(col.type)) {
              return col.type.map((type, index) => {
                const field = Array.isArray(col.field) ? col.field[index] : col.field;
                const title = Array.isArray(col.title) ? col.title[index] : col.title;
                return (
                  <div key={`${col.id}-${field}`} className={styles.line}>
                    <dt>
                      {title}
                      {col.sub && <br />}
                      {col.sub && <span className={styles.additionnalInfo}> {col.sub}</span>}
                    </dt>
                    <dd>
                      <LotCell
                        onStatusAlertClick={onStatusAlertClick}
                        myBookings={myBookings}
                        myOptions={myOptions}
                        alerts={alerts}
                        col={{ field, title, type, id: col.id }}
                        lot={lot}
                        statuses={statuses}
                        taxById={taxById}
                      />
                    </dd>
                  </div>
                );
              });
            }
            return (
              <div key={col.id} className={styles.line}>
                <dt>
                  {col.title}
                  {col.sub && <br />}
                  {col.sub && <span className={styles.additionnalInfo}> {col.sub}</span>}
                </dt>
                <dd>
                  <LotCell
                    onStatusAlertClick={onStatusAlertClick}
                    myBookings={myBookings}
                    myOptions={myOptions}
                    alerts={alerts}
                    col={col}
                    lot={lot}
                    statuses={statuses}
                    taxById={taxById}
                  />
                </dd>
              </div>
            );
          })}
        </dl>

        <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

        {open === MODAL_ID_SEND_BOOKLET && (
          <ModalSendBooklet
            booklet={booklet}
            closeCallBack={closeModal}
            open
            program={lot.program}
          />
        )}
      </div>
    </>
  );
}
