import axios from 'axios';
import { getTokenFromSession } from '../../services/authentication';

export function axiosInstance({ baseUrl, apiKey }: { baseUrl: string; apiKey: string }) {
  function getCredentials() {
    const token = getTokenFromSession();

    if (token) {
      return {
        Authorization: `Bearer ${localStorage.getItem(
          window.vinci.REACT_APP_SESSION_AUTH_KEY as string
        )}`,
        withCredentials: true,
      };
    }

    return {};
  }

  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': apiKey,
      ...getCredentials(),
    },
  });
}

export const axiosOffresInstance = axiosInstance({
  baseUrl: window.vinci.REACT_APP_VINCI_OFFRES_API_URL as string,
  apiKey: window.vinci.REACT_APP_VINCI_OFFRES_API_KEY as string,
});
