import React, { useContext } from 'react';

import { foldersContext } from 'modules/App/Contexts/foldersContext';

import LotCardLoadProgramAndLot from 'commonUi/LotCard/LotCardLoadProgramAndLot';

export function ComparisonsList() {
  const { folderComparisons } = useContext(foldersContext);

  if (folderComparisons.length === 0) {
    return null;
  }

  return (
    <>
      {folderComparisons.map(item => (
        <LotCardLoadProgramAndLot
          key={`${item.programRef}-${item.lotNumber}`}
          lotNumber={item.lotNumber}
          programRef={item.programRef}
        />
      ))}
    </>
  );
}
