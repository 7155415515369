export default {
  af: 'Afghanistan',
  za: 'Afrique du Sud',
  al: 'Albanie',
  dz: 'Algérie',
  de: 'Allemagne',
  ad: 'Andorre',
  ao: 'Angola',
  ag: 'Antigua-et-Barbuda',
  sa: 'Arabie saoudite',
  ar: 'Argentine',
  am: 'Arménie',
  au: 'Australie',
  at: 'Autriche',
  az: 'Azerbaïdjan',
  bs: 'Bahamas',
  bh: 'Bahreïn',
  bd: 'Bangladesh',
  bb: 'Barbade',
  by: 'Biélorussie',
  be: 'Belgique',
  bz: 'Belize',
  bj: 'Bénin',
  bt: 'Bhoutan',
  bo: 'Bolivie',
  ba: 'Bosnie-Herzégovine',
  bw: 'Botswana',
  br: 'Brésil',
  bn: 'Brunei',
  bg: 'Bulgarie',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  kh: 'Cambodge',
  cm: 'Cameroun',
  ca: 'Canada',
  cv: 'Cap-Vert',
  cf: 'République centrafricaine',
  cl: 'Chili',
  cn: 'Chine',
  cy: 'Chypre (pays)',
  co: 'Colombie',
  km: 'Comores (pays)',
  cg: 'République du Congo',
  cd: 'République démocratique du Congo',
  kr: 'Corée du Sud',
  kp: 'Corée du Nord',
  cr: 'Costa Rica',
  ci: "Côte d'Ivoire",
  hr: 'Croatie',
  cu: 'Cuba',
  dk: 'Danemark',
  dj: 'Djibouti',
  do: 'République dominicaine',
  dm: 'Dominique',
  eg: 'Égypte',
  sv: 'Salvador',
  ae: 'Émirats arabes unis',
  ec: 'Équateur (pays)',
  er: 'Érythrée',
  es: 'Espagne',
  ee: 'Estonie',
  us: 'États-Unis',
  et: 'Éthiopie',
  fj: 'Fidji',
  fi: 'Finlande',
  fr: 'France',
  ga: 'Gabon',
  gm: 'Gambie',
  ge: 'Géorgie (pays)',
  gh: 'Ghana',
  gr: 'Grèce',
  gd: 'Grenade (pays)',
  gt: 'Guatemala',
  gn: 'Guinée',
  gw: 'Guinée-Bissau',
  gq: 'Guinée équatoriale',
  gy: 'Guyana',
  ht: 'Haïti',
  hn: 'Honduras',
  hu: 'Hongrie',
  in: 'Inde',
  id: 'Indonésie',
  ir: 'Iran',
  iq: 'Irak',
  ie: 'Irlande (pays)',
  is: 'Islande',
  il: 'Israël',
  it: 'Italie',
  jm: 'Jamaïque',
  jp: 'Japon',
  jo: 'Jordanie',
  kz: 'Kazakhstan',
  ke: 'Kenya',
  kg: 'Kirghizistan',
  ki: 'Kiribati',
  kw: 'Koweït',
  la: 'Laos',
  ls: 'Lesotho',
  lv: 'Lettonie',
  lb: 'Liban',
  lr: 'Liberia',
  ly: 'Libye',
  li: 'Liechtenstein',
  lt: 'Lituanie',
  lu: 'Luxembourg (pays)',
  mk: 'Macédoine du Nord',
  mg: 'Madagascar',
  my: 'Malaisie',
  mw: 'Malawi',
  mv: 'Maldives',
  ml: 'Mali',
  mt: 'Malte',
  ma: 'Maroc',
  mh: 'Îles Marshall (pays)',
  mu: 'Maurice (pays)',
  mr: 'Mauritanie',
  mx: 'Mexique',
  fm: 'États fédérés de Micronésie (pays)',
  md: 'Moldavie',
  mc: 'Monaco',
  mn: 'Mongolie',
  me: 'Monténégro',
  mz: 'Mozambique',
  mm: 'Birmanie',
  na: 'Namibie',
  nr: 'Nauru',
  np: 'Népal',
  ni: 'Nicaragua',
  ne: 'Niger',
  ng: 'Nigeria',
  no: 'Norvège',
  nz: 'Nouvelle-Zélande',
  om: 'Oman',
  ug: 'Ouganda',
  uz: 'Ouzbékistan',
  pk: 'Pakistan',
  pw: 'Palaos',
  pa: 'Panama',
  pg: 'Papouasie-Nouvelle-Guinée',
  py: 'Paraguay',
  nl: 'Pays-Bas',
  pe: 'Pérou',
  ph: 'Philippines',
  pl: 'Pologne',
  pt: 'Portugal',
  qa: 'Qatar',
  ro: 'Roumanie',
  gb: 'Royaume-Uni',
  ru: 'Russie',
  rw: 'Rwanda',
  kn: 'Saint-Christophe-et-Niévès',
  sm: 'Saint-Marin',
  vc: 'Saint-Vincent-et-les-Grenadines',
  lc: 'Sainte-Lucie',
  sb: 'Îles Salomon',
  ws: 'Samoa',
  st: 'Sao Tomé-et-Principe',
  sn: 'Sénégal',
  rs: 'Serbie',
  sc: 'Seychelles',
  sl: 'Sierra Leone',
  sg: 'Singapour',
  sk: 'Slovaquie',
  si: 'Slovénie',
  so: 'Somalie',
  sd: 'Soudan',
  ss: 'Soudan du Sud',
  lk: 'Sri Lanka',
  se: 'Suède',
  ch: 'Suisse',
  sr: 'Suriname',
  sz: 'Eswatini',
  sy: 'Syrie',
  tj: 'Tadjikistan',
  tz: 'Tanzanie',
  td: 'Tchad',
  cz: 'Tchéquie',
  th: 'Thaïlande',
  tl: 'Timor oriental',
  tg: 'Togo',
  to: 'Tonga',
  tt: 'Trinité-et-Tobago',
  tn: 'Tunisie',
  tm: 'Turkménistan',
  tr: 'Turquie',
  tv: 'Tuvalu',
  ua: 'Ukraine',
  uy: 'Uruguay',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Viêt Nam',
  ye: 'Yémen',
  zm: 'Zambie',
  zw: 'Zimbabwe',
};
