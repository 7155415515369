import React from 'react';

import {
  LABEL_FORM_OPTION_CONTENT_DATE_AVAILABLE,
  LABEL_FORM_OPTION_CONTENT_SOME_MINUTES,
  LABEL_FORM_OPTION_CONTENT_YOUR_SPACE,
  LABEL_FORM_OPTION_SUCCESS_TITLE,
  LABEL_MY_ACTIVITY,
} from 'settings/labels';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ConfirmationOption.module.scss';

interface ConfirmationOptionProps {
  handleClose: () => void;
  successMessage?: string;
}

export default function ConfirmationOption({
  handleClose,
  successMessage,
}: ConfirmationOptionProps) {
  return (
    <div className={styles.confirmation} data-test-id="form-option--confirmation">
      <SvgIcon className={styles.svgIcon} iconId="icon-check-circle" />
      <h3>{LABEL_FORM_OPTION_SUCCESS_TITLE}</h3>
      <p>{LABEL_FORM_OPTION_CONTENT_SOME_MINUTES}</p>
      <p>
        {LABEL_FORM_OPTION_CONTENT_DATE_AVAILABLE}
        <span className={styles.highlight}>{successMessage}</span>
      </p>
      <p>
        {LABEL_FORM_OPTION_CONTENT_YOUR_SPACE}
        <a className={styles.link} href="/mon-activite-commerciale">
          {LABEL_MY_ACTIVITY}
        </a>
      </p>

      <div className={styles.button}>
        <Button fullWidth variant="contained" color="primary" onClick={handleClose}>
          Terminer
        </Button>
      </div>
    </div>
  );
}
