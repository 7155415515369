import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import type { OptionsObject } from 'notistack';

import { replaceTokens } from 'services/formatter';
import {
  LABEL_ALERT_CREATE_FAIL,
  LABEL_ALERT_CREATE_SUCCESS,
  LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_FEMALE,
  LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_MALE,
  LABEL_ALERT_DELETE_FAIL,
  LABEL_ALERT_DELETE_SUCCESS,
  LABEL_ALERT_MARKETING_CREATE_FAIL,
  LABEL_ALERT_MARKETING_CREATE_SUCCESS,
  LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_FEMALE,
  LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_MALE,
  LABEL_ALERT_MARKETING_DELETE_FAIL,
  LABEL_ALERT_MARKETING_DELETE_FAIL_MULTIPLE,
  LABEL_ALERT_MARKETING_DELETE_SUCCESS,
  LABEL_ALERT_MARKETING_DELETE_SUCCESS_MULTIPLE,
} from 'settings/labels';
import { TOKEN_LOT_NUMBER } from 'settings/token';
import { USER_GENDER_MALE } from 'settings/user';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { AlertMarketing, AlertType } from 'api/vi3pAPI/apiTypes/AccountTypes';
import { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';

const TEMPORARY_UUID = 'temporary';

function getToastOptions(
  options: boolean | OptionsObject | ((toastType: 'success' | 'failure') => OptionsObject),
  type: 'success' | 'failure'
) {
  switch (typeof options) {
    case 'boolean':
      return undefined;

    case 'function':
      return options(type);

    default:
      return options;
  }
}

export default function useAlerts(userCrm: UserTokenType, options = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const userId = userCrm?.extension_VI3P_ContactId;
  const userGender = userCrm?.extension_civilite;
  const { data: alerts, mutate } = useSWRVi3p<AlertType[]>(
    {
      url: userId ? `alertes/${userId}` : undefined,
    },
    options
  );

  const createAlert = useCallback((lotNumber: string, programRef: string, noToast?: boolean) => {
    // Optimistic creation
    mutate((data: AlertType[]) => {
      return [
        ...data,
        {
          alert_id: 0,
          uuid: TEMPORARY_UUID,
          date: new Date(Date.now()).toISOString(),
          lotNumber,
          programRef,
        },
      ];
    }, false);
    if (!noToast) {
      enqueueSnackbar(
        <>
          <div>{replaceTokens(LABEL_ALERT_CREATE_SUCCESS, { [TOKEN_LOT_NUMBER]: lotNumber })}</div>
          <div>
            {userGender === USER_GENDER_MALE
              ? LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_MALE
              : LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_FEMALE}
          </div>
        </>
      );
    }
    return axiosVI3PInstance
      .post('alertes/create', {
        data: {
          field_utilisateur_id: userId,
          field_numero_lot: lotNumber,
          field_referenceprogramme: programRef,
          field_type_alerte: 'Option tombée',
        },
      })
      .then(() => {
        // Synchronize after creation
        return mutate();
      })
      .catch(() => {
        if (!noToast) {
          enqueueSnackbar(
            replaceTokens(LABEL_ALERT_CREATE_FAIL, { [TOKEN_LOT_NUMBER]: lotNumber }),
            {
              autoHideDuration: null,
              content: (key, message) => (
                <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
              ),
            }
          );
        }
        // Undo optimistic creation
        return mutate((data: AlertType[]) => {
          const tmpIndex = data.findIndex(a => a.uuid === TEMPORARY_UUID);
          if (tmpIndex !== -1) {
            return [...data.slice(0, tmpIndex), ...data.slice(tmpIndex + 1)];
          }
          return data;
        }, false);
      });
  }, []);
  const deleteAlert = useCallback((lotNumber: string, programRef: string, noToast?: boolean) => {
    // Optimistic deletion
    let deletedAlert: AlertType | undefined;
    let deletedIndex = -1;
    mutate((data: AlertType[]) => {
      deletedIndex = data.findIndex(a => a.lotNumber === lotNumber && a.programRef === programRef);
      deletedAlert = data[deletedIndex];
      if (deletedIndex !== -1) {
        return [...data.slice(0, deletedIndex), ...data.slice(deletedIndex + 1)];
      }
      return data;
    }, false);
    if (!noToast) {
      enqueueSnackbar(replaceTokens(LABEL_ALERT_DELETE_SUCCESS, { [TOKEN_LOT_NUMBER]: lotNumber }));
    }
    return axiosVI3PInstance
      .delete(`alertes/${userId}/${programRef}/${lotNumber}`)
      .then(() => {
        // Synchronize after deletion
        return mutate();
      })
      .catch(() => {
        if (!noToast) {
          enqueueSnackbar(
            replaceTokens(LABEL_ALERT_DELETE_FAIL, { [TOKEN_LOT_NUMBER]: lotNumber }),
            {
              autoHideDuration: null,
              content: (key, message) => (
                <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
              ),
            }
          );
        }
        // Undo Optimistic deletion
        return mutate((data: AlertType[]) => {
          if (deletedAlert && deletedIndex) {
            return [...data.slice(0, deletedIndex), deletedAlert, ...data.slice(deletedIndex)];
          }
          return data;
        });
      })
      .finally(() => {
        deletedAlert = undefined;
        deletedIndex = -1;
      });
  }, []);
  const toggleAlert = useCallback(
    (lotNumber: string, programRef: string, noToast?: boolean) => {
      if (alerts?.some(alert => alert.lotNumber === lotNumber && alert.programRef === programRef)) {
        return deleteAlert(lotNumber, programRef, noToast);
      }
      return createAlert(lotNumber, programRef, noToast);
    },
    [alerts]
  );

  type MarketingAlerts = Record<string, AlertMarketing>;
  const { data: alertsMarketing, mutate: mutateMarketing } = useSWRVi3p<MarketingAlerts>(
    {
      url: userId ? `profils/${userId}/alerteMiseEnVente` : undefined,
    },
    options
  );

  const createMarketingAlert = useCallback(
    async (
      programRef: string,
      email: string,
      toastOptions:
        | boolean
        | OptionsObject
        | ((toastType: 'success' | 'failure') => OptionsObject) = true
    ) => {
      try {
        const response = await axiosVI3PInstance.post<MarketingAlerts>(
          `profils/${userId}/alerteMiseEnVente`,
          {
            email,
            programme_ref: programRef,
          }
        );

        if (toastOptions) {
          enqueueSnackbar(
            <>
              <div>{LABEL_ALERT_MARKETING_CREATE_SUCCESS}</div>
              <div>
                {userGender === USER_GENDER_MALE
                  ? LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_MALE
                  : LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_FEMALE}
              </div>
            </>,
            getToastOptions(toastOptions, 'success')
          );
        }

        // Synchronize after creation
        await mutateMarketing(response.data);
      } catch (e) {
        if (toastOptions) {
          enqueueSnackbar(LABEL_ALERT_MARKETING_CREATE_FAIL, {
            autoHideDuration: null,
            content: (key_1, message_2) => (
              <Toast id={key_1} message={message_2} variant={TOAST_VARIANT_ERROR} />
            ),
            ...getToastOptions(toastOptions, 'failure'),
          });
        }
      }
    },
    []
  );
  const deleteMarketingAlerts = useCallback(
    async (
      alertIds: string | string[],
      toastOptions:
        | boolean
        | OptionsObject
        | ((toastType: 'success' | 'failure') => OptionsObject) = true
    ) => {
      // Optimistic deletion
      let deletedAlerts: MarketingAlerts = {};
      mutateMarketing((data: MarketingAlerts) => {
        try {
          const [keptAlerts, alertsDeleted] = Object.entries(data).reduce<
            [MarketingAlerts, MarketingAlerts]
          >(
            ([keptAlerts, deletedAlerts], [alertId, alert]) => {
              if (Array.isArray(alertIds) ? alertIds.includes(alertId) : alertId === alertIds) {
                return [keptAlerts, { ...deletedAlerts, [alertId]: alert }];
              }
              return [{ ...keptAlerts, [alertId]: alert }, deletedAlerts];
            },
            [{}, {}]
          );
          deletedAlerts = alertsDeleted;
          return keptAlerts;
        } catch (e) {
          return data;
        }
      }, false);
      return axiosVI3PInstance
        .delete(
          `profils/${userId}/alerteMiseEnVente?ids=${
            Array.isArray(alertIds) ? alertIds.join(',') : alertIds
          }`
        )
        .then(() => {
          if (toastOptions) {
            enqueueSnackbar(
              Object.keys(deletedAlerts).length > 1
                ? LABEL_ALERT_MARKETING_DELETE_SUCCESS_MULTIPLE
                : LABEL_ALERT_MARKETING_DELETE_SUCCESS,
              getToastOptions(toastOptions, 'success')
            );
          }
          // Synchronize after deletion
          mutateMarketing();
        })
        .catch(() => {
          if (toastOptions) {
            enqueueSnackbar(
              Object.keys(deletedAlerts).length > 1
                ? LABEL_ALERT_MARKETING_DELETE_FAIL_MULTIPLE
                : LABEL_ALERT_MARKETING_DELETE_FAIL,
              {
                autoHideDuration: null,
                content: (key, message) => (
                  <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
                ),
                ...getToastOptions(toastOptions, 'failure'),
              }
            );
          }
          // Undo Optimistic deletion
          mutateMarketing((data: MarketingAlerts) => {
            return deletedAlerts
              ? {
                  ...data,
                  ...deletedAlerts,
                }
              : data;
          }, false);
        })
        .finally(() => {
          deletedAlerts = {};
        });
    },
    []
  );

  return {
    alerts,
    createAlert,
    deleteAlert,
    toggleAlert,
    alertsMarketing,
    createMarketingAlert,
    deleteMarketingAlerts,
  };
}
