import React, { useContext, useEffect, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import classNames from 'classnames';

import { replaceTokens } from 'services/formatter';
import { error } from 'services/log';
import { LABEL_VERSION } from 'settings/labels';
import { MENU_KEY_FOOTER } from 'settings/menus';
import { TOKEN_VERSION } from 'settings/token';

import type { MenuType } from 'api/vi3pAPI/apiTypes/MenuTypes';
import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

function Footer({ authenticatedFooter = false }: FooterProps) {
  const [isOpen, setIsOpen] = useState<{ [x: string]: boolean }>({});
  const { tmsIsReady } = useContext(TmsContext);
  const { isResponsive, setIsFooterVisibleMobile } = useContext(ResponsiveContext);
  const { settings } = useContext(SettingsContext);
  const footerRef = useRef<HTMLElement>(null);

  const { data: footerMenuItems } = useSWRVi3pImmutable<MenuType>({
    url: `/menu_link_content?id=${MENU_KEY_FOOTER}`,
  });

  useEffect(() => {
    if (isResponsive) {
      document.addEventListener('scroll', onScroll);
    }
  });

  function handleMobileToggle(ev: MouseEvent, key: string) {
    ev.preventDefault();
    setIsOpen({
      ...isOpen,
      [key]: !isOpen[key],
    });
  }

  function openCookiePreferences() {
    try {
      window.tC.privacyCenter.showPrivacyCenter();
    } catch (e) {
      error('TMS', e);
    }
  }

  function onScroll() {
    if (footerRef.current && footerRef.current.getBoundingClientRect().top < window.innerHeight) {
      setIsFooterVisibleMobile(true);
    } else {
      setIsFooterVisibleMobile(false);
    }
  }

  return (
    <footer
      className={classNames('footer', {
        footer__authenticated: authenticatedFooter,
      })}
      ref={footerRef}
    >
      {settings.footer?.blocs && (
        <div className="footer__top">
          <div
            className={classNames('footer__about', {
              'footer-foldable--open': isOpen['col-0'],
              'footer-foldable': settings.footer.blocs[0].collapse_mobile,
            })}
          >
            {settings.footer.blocs[0].collapse_mobile && (
              <button
                type="button"
                className="footer-foldable__btn"
                onClick={ev => handleMobileToggle(ev, 'col-0')}
              >
                {settings.footer.blocs[0].collapse_mobile}
              </button>
            )}

            <div className="footer-foldable__content">
              {settings.footer.blocs[0].image && (
                <img
                  src={settings.footer.blocs[0].image}
                  alt={`Picto ${settings.footer.blocs[0].titre}`}
                />
              )}

              {settings.footer.blocs[0].titre && <FieldRte html={settings.footer.blocs[0].titre} />}

              {settings.footer.blocs[0].contenu && (
                <FieldRte html={settings.footer.blocs[0].contenu} />
              )}
            </div>
          </div>

          {settings.footer.blocs.length > 1 && (
            <div className="footer__cols">
              {settings.footer.blocs.map((bloc, key) => {
                const { collapse_mobile, contenu, titre, image } = bloc;

                if (key === 0) {
                  return null;
                }

                const content = (
                  <>
                    <div className="footer-col__head">
                      <div className="footer-col__picto">
                        {image && <img src={image} alt={`Picto ${titre}`} />}
                      </div>

                      {titre && (
                        <FieldRte
                          html={titre}
                          className={classNames('footer-rte', 'footer__heading')}
                        />
                      )}
                    </div>

                    <div className="footer-col__content">
                      {titre && (
                        <FieldRte
                          html={titre}
                          className={classNames('footer-rte', 'footer__heading')}
                        />
                      )}

                      {contenu && <FieldRte html={contenu} className="footer-rte" />}
                    </div>
                  </>
                );

                return (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                    className={classNames('footer-col', {
                      'footer-foldable--open': isOpen[`col-${key}`],
                      'footer-foldable': collapse_mobile,
                    })}
                  >
                    {collapse_mobile && (
                      <button
                        type="button"
                        className="footer-foldable__btn"
                        onClick={ev => handleMobileToggle(ev, `col-${key}`)}
                      >
                        {collapse_mobile}
                      </button>
                    )}

                    {collapse_mobile ? (
                      <div className="footer-foldable__content">{content}</div>
                    ) : (
                      content
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      <div className="footer__bottom">
        {settings.footer?.copyright && (
          <div className="footer__copyright">{settings.footer.copyright}</div>
        )}
        <div className="footer__version">
          {replaceTokens(LABEL_VERSION, { [TOKEN_VERSION]: window.vinci.REACT_APP_VERSION })}
        </div>

        {footerMenuItems && (
          <ul className="footer__links">
            {footerMenuItems[MENU_KEY_FOOTER]?.length > 0 &&
              footerMenuItems[MENU_KEY_FOOTER].map(item => {
                const menuItem = item.privacy_center ? (
                  <li key={item.mid}>
                    <button type="button" onClick={openCookiePreferences}>
                      {item.name}
                    </button>
                  </li>
                ) : (
                  <li key={item.mid}>
                    <a href={item.url} target={item.target}>
                      {item.name}
                    </a>
                  </li>
                );

                if (item.tmsReady) {
                  return tmsIsReady && menuItem;
                }

                return menuItem;
              })}
          </ul>
        )}
      </div>
    </footer>
  );
}

export type FooterProps = {
  authenticatedFooter?: boolean;
};

export default Footer;
