import React, { useContext } from 'react';
import Slider from 'react-slick';

import { LABEL_CONTACT_REGIONAL_CONTACTS } from 'settings/labels';

import ContactsContext from 'modules/App/Contexts/ContactsContext';

import LazyImage from 'commonUi/LazyImage/LazyImage';

import SliderArrow from './SliderArrow';

export default function ContactsSlider() {
  const { getContacts, isLoading } = useContext(ContactsContext);

  if (isLoading) {
    return null;
  }

  return (
    <section className="section-conteneur section-interlocuteurs" id="interlocuteurs">
      <h2 className="block-content-title">{LABEL_CONTACT_REGIONAL_CONTACTS}</h2>

      <Slider
        arrows
        dots={false}
        infinite
        initialSlide={0}
        nextArrow={<SliderArrow label="Suivant" />}
        prevArrow={<SliderArrow label="Précédent" />}
        slidesToScroll={1}
        speed={1000}
        variableWidth
        responsive={[
          {
            breakpoint: 991,
            settings: { dots: true },
          },
        ]}
      >
        {getContacts()
          .filter(({ disabled_homepage: disabled }) => !disabled)
          .map(({ prenom, nom, photo, region, email, telephone }) => (
            <div key={email} className="contact">
              <LazyImage
                className="contact__img"
                src={photo || '/images/placeholders/200x200.png'}
                alt={`${prenom} ${nom}`}
              />

              <div className="contact__name">
                <div>{prenom}</div>
                <div>{nom}</div>
              </div>
              <div className="contact__location">{region}</div>

              <div className="contact__contacts">
                {email && (
                  <a href={`mailto:${email}`} title={email}>
                    <img src="/images/ico_mail_blanc.png" alt={email} />
                  </a>
                )}

                {telephone && (
                  <a href={`tel:${telephone}`} title={telephone}>
                    <img src="/images/ico_phone_blanc.png" alt={telephone} />
                  </a>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </section>
  );
}
