import {
  LABEL_ACT,
  LABEL_BOOKING,
  LABEL_CONFIRMATION_ACTIVITY_STEP_CONTRACT,
  LABEL_CONFIRMATION_ACTIVITY_STEP_PREBOOK,
  LABEL_CONFIRMATION_ACTIVITY_STEP_REQUEST,
  LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_CLIENT,
  LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_VINCI,
  LABEL_PRE_BOOKING,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_1,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_2,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_3,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_4,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_5,
  LABEL_STEPS_BOOKINGS_ACTS_STEP_6,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_1,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_2,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_3,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_4,
  LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_5,
} from './labels';

export const ACTIVITY_TAB_ID_BOOKINGS = 'reservations';
export const ACTIVITY_TAB_ID_OPTIONS = 'options';
export const ACTIVITY_TAB_ID_PREBOOKINGS = 'pre-reservations';
export const ACTIVITY_TAB_ID_SALES = 'ventes';

export const ACTIVITY_TYPE_BOOKING = 'Réservation';
export const ACTIVITY_TYPE_OPTION = 'Option';
export const ACTIVITY_TYPE_PRE_BOOKING = 'Pré-réservation';
export const ACTIVITY_TYPE_SALE = 'Vente';

export const ACTIVITY_STATUS_ACTIVE = 'Active';
export const ACTIVITY_STATUS_CANCELED = 'Annulée';
export const ACTIVITY_STATUS_FROZEN = 'Gelée';
export const ACTIVITY_STATUS_LOST = 'Perdue';
export const ACTIVITY_STATUS_PENDING = "En file d'attente";
export const ACTIVITY_STATUS_WON = 'Gagnée';

export const ACTIVITY_STATUS_ORDER = [
  ACTIVITY_STATUS_WON,
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_STATUS_CANCELED,
];

export const DOCUMENT_NATURE_FIELD_DOSSIER_RESERVATION = 'Dossier de réservation';
export const DOCUMENT_NATURE_FIELD_DOSSIER_RESERVATION_SIGNE =
  'Dossier de réservation signé par le(s) acquéreur(s)';
export const DOCUMENT_NATURE_FIELD_DOSSIER_RESERVATION_SIGNE_VINCIIMMO =
  'Dossier de réservation contresigné par Vinci Immobilier';

export const ACTIVITY_CELL_TYPE_DATE = 'date';
export const ACTIVITY_CELL_TYPE_DATE_TIME = 'dateTime';
export const ACTIVITY_CELL_TYPE_DEFAULT = 'default';
export const ACTIVITY_CELL_TYPE_NAME = 'name';
export const ACTIVITY_CELL_TYPE_TAX = 'tax';
export const ACTIVITY_CELL_TYPE_PRICE = 'price';
export const ACTIVITY_CELL_TYPE_PROGRAM = 'program';
export const ACTIVITY_CELL_TYPE_LOT = 'lot';
export const ACTIVITY_CELL_TYPE_STATUS = 'status';
export const ACTIVITY_CELL_TYPE_CONTRACT = 'contract';
export const ACTIVITY_CELL_TYPE_ACTIONS = 'actions';

export const ACTIVITY_HEADING_CLIENT = 'client';
export const ACTIVITY_HEADING_COUNSELOR = 'counselor';
export const ACTIVITY_HEADING_FEES = 'fees';
export const ACTIVITY_HEADING_MANAGER = 'manager';
export const ACTIVITY_HEADING_TAX = 'tax';
export const ACTIVITY_HEADING_PROGRAM = 'program';
export const ACTIVITY_HEADING_END = 'expiration';
export const ACTIVITY_HEADING_LOT = 'lot';
export const ACTIVITY_HEADING_PRICE = 'price';
export const ACTIVITY_HEADING_RATE = 'rate';
export const ACTIVITY_HEADING_SALE = 'sale';
export const ACTIVITY_HEADING_STATUS = 'status';
export const ACTIVITY_HEADING_STATUS_SALES = 'status-sales';
export const ACTIVITY_HEADING_ACT = 'act';
export const ACTIVITY_HEADING_BOOKING = 'booking';
export const ACTIVITY_HEADING_ACTIONS = 'actions';
export const ACTIVITY_HEADING_CONTRACT = 'contract';

export const ACTIVITY_STEP_COLOR_PAST = '#0fa669';
export const ACTIVITY_STEP_COLOR_CURRENT = '#003c71';
export const ACTIVITY_STEP_COLOR_FUTURE = '#D6D6D6';

export const ACTIVITY_STEPS_CONFIRMATION: {
  icon: string;
  iconCurrent?: string;
  title: string;
}[] = [
  {
    icon: 'icon-pre-book-mono',
    iconCurrent: 'icon-pre-book',
    title: LABEL_CONFIRMATION_ACTIVITY_STEP_PREBOOK,
  },
  {
    icon: 'icon-generate-contract-mono',
    iconCurrent: 'icon-generate-contract',
    title: LABEL_CONFIRMATION_ACTIVITY_STEP_CONTRACT,
  },
  {
    icon: 'icon-send-documents-mono',
    iconCurrent: 'icon-send-documents',
    title: LABEL_CONFIRMATION_ACTIVITY_STEP_REQUEST,
  },
  {
    icon: 'icon-contract-signed-client-bg-mono',
    iconCurrent: 'icon-contract-signed-client-bg',
    title: LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_CLIENT,
  },
  {
    icon: 'icon-contract-signed-vinci-bg-mono',
    iconCurrent: 'icon-contract-signed-vinci-bg',
    title: LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_VINCI,
  },
];

export const DEFAULT_SECURITY_DEPOSIT = '1600';

export const ACTIVITIES_TABS_TMS = {
  [ACTIVITY_TAB_ID_OPTIONS]: {
    navigation_template: 'espace_perso',
    navigation_pagename: 'espace_perso.lots_optionnes',
  },
  [ACTIVITY_TAB_ID_PREBOOKINGS]: {
    navigation_template: 'espace_perso',
    navigation_pagename: 'espace_perso.lots_prereserves',
  },
  [ACTIVITY_TAB_ID_BOOKINGS]: {
    navigation_template: 'espace_perso',
    navigation_pagename: 'espace_perso.lots_reserve',
  },
  [ACTIVITY_TAB_ID_SALES]: {
    navigation_template: 'espace_perso',
    navigation_pagename: 'espace_perso.lots_vendus',
  },
};

export const ACTIVITIES_DOWNLOAD_ZIP_TMS = {
  [ACTIVITY_TYPE_BOOKING]: 'espace_perso.lots_reserve_telechargerzip',
  [ACTIVITY_TYPE_PRE_BOOKING]: 'espace_perso.lots_prereserves_telechargerzip',
};

export const ACTIVITIES_DOWNLOAD_TMS = {
  [ACTIVITY_TYPE_BOOKING]: 'espace_perso.lots_reserve_telecharger',
  [ACTIVITY_TYPE_PRE_BOOKING]: 'espace_perso.lots_prereserves_telecharger',
};

export const STEPS_LIST_OPTIONS_PREBOOKINGS = [
  {
    icons: ['icon-pre-book'],
    title: LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_1,
  },
  {
    icons: ['icon-generate-contract', 'icon-download-contract'],
    title: LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_2,
  },
  {
    icons: ['icon-send-documents'],
    title: LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_3,
  },
  {
    icons: ['icon-contract-signed-mono', 'icon-download-contract'],
    title: LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_4,
  },
  {
    icons: ['icon-contract-signed-vinci-mono', 'icon-download-contract'],
    title: LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_5,
  },
];

export const STEPS_LIST_BOOKINGS_ACTS = [
  {
    icons: ['icon-contract-signed-vinci-mono', 'icon-download-contract'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_1,
  },
  {
    icons: ['icon-sru-mono'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_2,
  },
  {
    icons: ['icon-receipt-mono'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_3,
  },
  {
    icons: ['icon-receipt-mono'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_4,
  },
  {
    icons: ['icon-transmission-mono'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_5,
  },
  {
    icons: ['icon-contract-signed-mono'],
    title: LABEL_STEPS_BOOKINGS_ACTS_STEP_6,
  },
];

export const STEPS_SHARES_OPTIONS_PREBOOKINGS = [
  {
    label: LABEL_PRE_BOOKING,
    nbSteps: 4,
  },
  {
    label: LABEL_BOOKING,
    nbSteps: 1,
  },
];

export const STEPS_SHARES_BOOKINGS_ACTS = [
  {
    label: LABEL_BOOKING,
    nbSteps: 5,
  },
  {
    label: LABEL_ACT,
    nbSteps: 1,
  },
];

export const ACTIVITY_TYPE_TO_TAB_ID_MAPPING = {
  [ACTIVITY_TYPE_BOOKING]: ACTIVITY_TAB_ID_BOOKINGS,
  [ACTIVITY_TYPE_OPTION]: ACTIVITY_TAB_ID_OPTIONS,
  [ACTIVITY_TYPE_PRE_BOOKING]: ACTIVITY_TAB_ID_PREBOOKINGS,
  [ACTIVITY_TYPE_SALE]: ACTIVITY_TAB_ID_SALES,
} as const;
