import { useEffect, useState } from 'react';
import { createHook } from 'hookleton';

import { error } from '../../services';

export const useLoadPrograms = createHook(() => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    fetch(`${window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH}?${new Date().getTime()}`)
      .then(stream => stream.json())
      .then(json => {
        setPrograms(json);
      })
      .catch(e => error('fetch', e));
  }, []);

  return programs;
});

export function useLoadProgramsWithDefaultNull() {
  const [programs, setPrograms] = useState(null);

  useEffect(() => {
    fetch(`${window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH}?${new Date().getTime()}`)
      .then(stream => stream.json())
      .then(json => {
        setPrograms(json);
      })
      .catch(e => error('fetch', e));
  }, []);

  return programs;
}
