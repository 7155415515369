import React from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import YouTube from 'commonUi/YouTube/YouTube';

import styles from './VideoPopin.module.scss';

export default function VideoPopin({ onClose, open, videoId }: VideoPopinProps) {
  if (!open) {
    return null;
  }

  const video = {
    settings: {
      videoId,
      height: '100%',
      width: '100%',
      controls: false,
      enablejsapi: 1,
    },
  };

  return (
    <MuiDialog
      maxWidth="xl"
      open={open}
      onClose={onClose}
      classes={{
        paper: styles.paper,
        root: styles.root,
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
    >
      <button className={styles.close} type="button" onClick={onClose}>
        <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
      </button>
      <YouTube className={styles.video} {...video.settings} />
    </MuiDialog>
  );
}

export type VideoPopinProps = {
  onClose: () => void;
  open: boolean;
  videoId: string;
};
