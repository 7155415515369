import React, { useState } from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';
import { useSnackbar } from 'notistack';
import classnames from 'classnames';
import {
  Button as ButtonMui,
  Link,
  ClickAwayListener as MuiClickAwayListener,
} from '@material-ui/core';

import { LABEL_DISPLAY, LABEL_DOCUMENT_NOT_FOUND, LABEL_SEND } from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import Button from 'commonUi/Button/Button';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import styles from './Dropdowns.module.scss';

interface BookletDropdownProps {
  bookletUrl: string;
  buttonIcon?: ComponentProps<typeof Button>['icon'];
  buttonId: string;
  lot?: LotTypeV2;
  modalId: string;
  openModal: (modalId: string) => void;
  pageTemplate?: string;
  pageTemplateRef?: string;
}

export default function BookletDropdown({
  bookletUrl,
  buttonIcon,
  buttonId,
  children,
  lot,
  modalId,
  openModal,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
}: PropsWithChildren<BookletDropdownProps>) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDisplayBookletClicked, setIsDisplayBookletClicked] = useState(false);
  const [activeButton, setActiveButton] = useState<string>();

  const showBooklet: React.MouseEventHandler = ev => {
    setIsDisplayBookletClicked(true);
    if (!bookletUrl || bookletUrl === '#') {
      ev.preventDefault();
      enqueueSnackbar(LABEL_DOCUMENT_NOT_FOUND, {
        preventDuplicate: true,
        key: 'no-booklet',
        content: (key, message) => (
          <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
        ),
      });
    }
  };

  function handleClickOutside() {
    setIsDisplayBookletClicked(false);
    setActiveButton(undefined);
  }
  const isButtonActive = activeButton === buttonId;

  return (
    <>
      <MuiClickAwayListener onClickAway={handleClickOutside} key={buttonId}>
        <div className={styles.buttonDropdownContainer}>
          <Button
            action
            className={classnames(styles.button, {
              [styles.isActive]: isButtonActive,
            })}
            color="primary"
            iconId={!isButtonActive && buttonIcon ? `icon-${buttonIcon}` : undefined}
            icon={isButtonActive && buttonIcon ? buttonIcon : undefined}
            iconClass={!isButtonActive ? styles.buttonSvgIcon : styles.buttonIcon}
            iconDropdownClass={styles.buttonDropdownIcon}
            isActive={isButtonActive}
            labelClassName={styles.buttonLabel}
            onClick={() =>
              setActiveButton(activeButton => (activeButton === buttonId ? undefined : buttonId))
            }
            variant="outlined"
            withDropdown
          >
            {children}
          </Button>
          <div
            className={classnames(styles.dropdownContent, {
              [styles.show]: isButtonActive,
            })}
          >
            <Link className={styles.button} href={bookletUrl} onClick={showBooklet} target="_blank">
              <Icon icon="display" className={styles.icon} /> {LABEL_DISPLAY}
            </Link>
            <ButtonMui className={styles.button} onClick={() => openModal(modalId)}>
              <Icon icon="send" className={styles.icon} />
              {LABEL_SEND}
            </ButtonMui>
          </div>
        </div>
      </MuiClickAwayListener>

      {pageTemplate && pageTemplateRef && lot && (
        <TagCommanderEvent
          isActive={isDisplayBookletClicked}
          navigation_pagename={`${pageTemplateRef}.${lot.reference}_brochure`}
          navigation_template={pageTemplate}
          useEffectDeps={['navigation_template', 'navigation_pagename']}
        />
      )}
    </>
  );
}
