import React from 'react';

import { floorFormatter, numberFormatter } from 'services/formatter';
import {
  LABEL_EXPOSITION,
  LABEL_HOUSING_INFORMATIONS,
  LABEL_NORMAL_VAT,
  LABEL_REDUCED_VAT,
  LABEL_STAIR,
  LABEL_SURFACE_HABITABLE,
  LABEL_TYPE,
} from 'settings/labels';
import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { LotPlan } from 'commonUi/LotPlan/LotPlan';
import RowDetailsList from 'commonUi/RowDetailsList/RowDetailsList';

import styles from './ActivityDetailsHousing.module.scss';

interface ActivityDetailsHousingProps {
  classes?: {
    root?: string;
    title?: string;
  };
  lot: LotTypeV2;
}

export default function ActivityDetailsHousing({ classes = {}, lot }: ActivityDetailsHousingProps) {
  const { tauxTva: tauxTvaNormale } = lot.prix.TVANormale;
  const tauxTvaReduite = lot.prix.TVAalternative?.tauxTva;
  return (
    <div className={classes?.root}>
      <div className={classes?.title}>{LABEL_HOUSING_INFORMATIONS}</div>
      <RowDetailsList
        items={{
          [LABEL_TYPE]: {
            label: LABEL_TYPE,
            value: lot.typologie,
            custom: (
              <span className={styles.plan}>
                <LotPlan lot={lot} />
              </span>
            ),
          },
          [LABEL_STAIR]: { label: LABEL_STAIR, value: floorFormatter(lot.etage) },
          [LABEL_SURFACE_HABITABLE]: {
            label: LABEL_SURFACE_HABITABLE,
            value: numberFormatter(lot.surfaces.surfaceHabitable, 'm²'),
          },
          [LABEL_EXPOSITION]: { label: LABEL_EXPOSITION, value: lot.orientation },
          [LABEL_NORMAL_VAT]: {
            label: LABEL_NORMAL_VAT,
            value: tauxTvaNormale ? numberFormatter(parseFloat(tauxTvaNormale), '%') : undefined,
          },
          [LABEL_REDUCED_VAT]: {
            label: LABEL_REDUCED_VAT,
            value: tauxTvaReduite ? numberFormatter(parseFloat(tauxTvaReduite), '%') : undefined,
          },
        }}
      />
    </div>
  );
}
