import React, { useContext, useState } from 'react';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';

import { LABEL_DOWNLOAD_COMPARISON, LABEL_SEND_COMPARISON } from 'settings/labels';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Button from 'commonUi/Button/Button';
import { LotCardSimple } from 'commonUi/LotCard/LotCardSimple';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { ComparisonItem } from '../../Comparison/types/ComparisonType';
import { FormSendComparison } from './FormSendComparison';

import styles from '../ModalAction.module.scss';

interface ModalSendComparisonProps {
  closeCallBack: () => void;
  comparisonDatas: ComparisonItem[] | undefined;
  handleDownload: () => void;
  isDownloading: boolean;
  open: boolean;
}

export function ModalSendComparison({
  closeCallBack,
  comparisonDatas,
  handleDownload,
  isDownloading,
  open,
}: ModalSendComparisonProps) {
  const { setEventPageVars } = useContext(TmsContext);
  const [loading, setLoading] = useState<boolean>(false);

  function handleClose() {
    setEventPageVars({});
    if (typeof closeCallBack === 'function') {
      closeCallBack();
    }
  }

  if (!open || !comparisonDatas) {
    return null;
  }

  return (
    <div className={styles.show}>
      <MuiDialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: styles.modalSkin,
          root: styles.modalRootForm,
          scrollPaper: styles.scrollPaper,
        }}
      >
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            <Icon className={styles.icon} icon="send" />
            <span>{LABEL_SEND_COMPARISON}</span>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.actionButton}>
              <Button
                color="primary"
                icon="download"
                labelWhileLoading
                loading={isDownloading}
                onClick={handleDownload}
                variant="contained"
              >
                {LABEL_DOWNLOAD_COMPARISON}
              </Button>
            </div>
            <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
              <Icon className={styles.svgIcon} icon="cross" />
            </IconButton>
          </div>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.cardsList}>
            {comparisonDatas &&
              comparisonDatas.map(c => (
                <div
                  className={styles.cardsListItem}
                  key={`${c.lot.reference}-${c.program.referenceProgramme}`}
                >
                  <LotCardSimple lot={c.lot} program={c.program} />
                </div>
              ))}
          </div>
          <FormSendComparison
            comparisonDatas={comparisonDatas}
            handleClose={handleClose}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </MuiDialog>

      <TagCommanderEvent
        isActive={open}
        navigation_pagename={loading ? 'comparaison.envoi.confirmation' : 'comparaison.envoi'}
        navigation_template="comparaison"
        useEffectDeps={['navigation_pagename']}
      />
    </div>
  );
}
