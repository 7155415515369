import React from 'react';
import classnames from 'classnames';

import awardIcon from 'images/icons/strongPoints/Icon_awards.png';
import espaceIcon from 'images/icons/strongPoints/Icon_espaces_exterieurs.png';
import familleIcon from 'images/icons/strongPoints/Icon_famillle.png';
import localisationIcon from 'images/icons/strongPoints/Icon_localisation.png';
import commercesIcon from 'images/icons/strongPoints/Icon_commerces.png';
import environnementIcon from 'images/icons/strongPoints/Icon_foret.png';
import transportIcon from 'images/icons/strongPoints/Icon_transport.png';

import styles from './StrongPoints.module.scss';

const defaultImg = awardIcon;

const iconsMapping = {
  Commerces: commercesIcon,
  Environnement: environnementIcon,
  Transports: transportIcon,
  Localisation: localisationIcon,
  Qualité: awardIcon,
  'Espaces extérieurs': espaceIcon,
  Famille: familleIcon,
};

export default function StrongPoints({
  className = undefined,
  strongPoints = [],
  title = undefined,
}: StrongPointsType) {
  if (strongPoints.length === 0) {
    return null;
  }

  return (
    <div className={classnames(className)}>
      {title && <div className={styles.title}>{title}</div>}

      <div className={styles.list}>
        {strongPoints.map(({ texte, theme }) => (
          <div className={styles.item}>
            <div className={styles.circle}>
              <img
                className={styles.img}
                src={iconsMapping[theme] || defaultImg}
                alt={theme}
                title={theme}
              />
            </div>
            <div className={styles.text}>{texte}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export type StrongPointType = { texte: string; theme: string };

export type StrongPointsType = {
  className?: string;
  strongPoints: StrongPointType[];
  title?: React.ReactNode;
};
