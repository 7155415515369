import React, { forwardRef } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';

import {
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './TextField.module.scss';

interface TextFieldType {
  display?: 'horizontal';
  endAdornmentIcon?: ComponentProps<typeof Icon>['icon'];
  endAdornmentText?: ReactNode;
  labelNbAdditionalStars?: number;
  notchedOutlineInvisible?: boolean;
  success?: boolean;
}

export default forwardRef<HTMLDivElement, MuiTextFieldProps & TextFieldType>(function TextField(
  {
    disabled,
    display,
    endAdornmentIcon,
    endAdornmentText,
    fullWidth = true,
    helperText,
    InputLabelProps,
    labelNbAdditionalStars = 0,
    multiline = false,
    notchedOutlineInvisible = false,
    success = false,
    type = 'text',
    required,
    label,
    ...textFieldProps
  },
  ref
) {
  let endAdornment = endAdornmentText;
  const iconEndAdornment = disabled ? ('lock' as const) : endAdornmentIcon;
  if (iconEndAdornment) {
    endAdornment = <Icon className={styles.endAdornmentIcon} icon={iconEndAdornment} />;
  }
  const labelNbStars = required ? labelNbAdditionalStars + 1 : labelNbAdditionalStars;

  return (
    <MuiTextField
      ref={ref}
      classes={{
        root: classnames(styles.root, { [styles.horizontal]: display === 'horizontal' }),
      }}
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      multiline={multiline}
      type={type}
      label={typeof label === 'string' ? `${label} ${'*'.repeat(labelNbStars)}` : label}
      variant={'outlined' as any}
      FormHelperTextProps={{
        classes: {
          error: styles.helperTextError,
          root: styles.helperTextRoot,
        },
      }}
      InputProps={{
        classes: {
          adornedEnd: styles.inputAdornedEnd,
          disabled: styles.inputDisabled,
          error: styles.inputError,
          input: styles.input,
          focused: styles.inputFocused,
          notchedOutline: notchedOutlineInvisible
            ? styles.notchedOutlineInvisible
            : styles.notchedOutline,
          root: classnames(styles.inputRoot, {
            [styles.inputRootNumber]: type === 'number',
            [styles.inputSuccess]: success,
          }),
        },
        endAdornment: endAdornment && (
          <InputAdornment
            classes={{
              root: styles.endAdornment,
            }}
            position="end"
            disableTypography
          >
            {endAdornment}
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        classes: {
          error: styles.labelError,
          focused: styles.labelFocused,
          root: classnames(styles.labelRoot, { [styles.multilineLabel]: multiline }),
        },
        ...InputLabelProps,
      }}
      {...textFieldProps}
    />
  );
});
