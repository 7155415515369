export const PROGRAM_ARRAY_LENGTH = 26;

export const PROGRAM_TAX_TYPE_FURNISHED = 'Meublé';
export const PROGRAM_TAX_TYPE_UNFURNISHED = 'Non meublé';

export const PROGRAM_HOUSING_TYPE_COLLECTIVE = 'Logement Collectif';
export const PROGRAM_HOUSING_TYPE_INDIVIDUAL = 'Logement Individuel';
export const PROGRAM_HOUSING_TYPE_STUDENT = 'Résidence étudiante';
export const PROGRAM_HOUSING_TYPE_SENIOR = 'Résidence séniors';

export const PROGRAM_HOUSING_TYPE_MAPPING = new Map<
  | typeof PROGRAM_HOUSING_TYPE_COLLECTIVE
  | typeof PROGRAM_HOUSING_TYPE_INDIVIDUAL
  | typeof PROGRAM_HOUSING_TYPE_STUDENT
  | typeof PROGRAM_HOUSING_TYPE_SENIOR,
  string
>([
  [PROGRAM_HOUSING_TYPE_COLLECTIVE, 'Collectif'],
  [PROGRAM_HOUSING_TYPE_INDIVIDUAL, 'Individuel'],
  [PROGRAM_HOUSING_TYPE_STUDENT, 'Étudiante'],
  [PROGRAM_HOUSING_TYPE_SENIOR, 'Séniors'],
]);

export const PROGRAM_TMS_BASE = 'programme';
export const PROGRAM_TMS_TAB_ID_DESCRIPTION = `${PROGRAM_TMS_BASE}.descriptif`;
export const PROGRAM_TMS_TAB_ID_DOCUMENTS = `${PROGRAM_TMS_BASE}.documents`;
export const PROGRAM_TMS_TAB_ID_LOCATION = `${PROGRAM_TMS_BASE}.localisation`;
export const PROGRAM_TMS_TAB_ID_LOTS = `${PROGRAM_TMS_BASE}.grilledeprix`;

export const PROGRAM_ABOUT_LINE_LIMIT_DESKTOP = 4;
export const PROGRAM_ABOUT_LINE_LIMIT_MOBILE = 5;

export const PROGRAM_KINDS_ORDER = ['T1', 'T2', 'T3', 'T4', 'T5 et plus', 'Maison', 'Parking'];

export const PROGRAM_PHASE_ARCHIVE = 'Archive';
export const PROGRAM_PHASE_LAUNCH = 'Lancement';
export const PROGRAM_PHASE_MARKETING = 'Commercialisation';
export const PROGRAM_PHASE_PARKING = 'Parkings seulement';
export const PROGRAM_PHASE_PREVIEW = 'Avant-première';

export const PROGRAM_JSON_NID = 0;
export const PROGRAM_JSON_LATITUDE = 1;
export const PROGRAM_JSON_LONGITUDE = 2;
export const PROGRAM_JSON_NAME = 3;
export const PROGRAM_JSON_CITY = 4;
export const PROGRAM_JSON_POSTAL_CODE = 5;
export const PROGRAM_JSON_DEPARTMENT = 6;
export const PROGRAM_JSON_REGION = 7;
export const PROGRAM_JSON_PRICE_MIN = 8;
export const PROGRAM_JSON_PRICE_MAX = 9;
export const PROGRAM_JSON_LOT_COUNT = 10;
export const PROGRAM_JSON_KIND_TIDS = 11;
export const PROGRAM_JSON_KIND_LABELS = 12;
export const PROGRAM_JSON_DELIVERY = 13;
export const PROGRAM_JSON_FISCALITY_TIDS = 14;
export const PROGRAM_JSON_IMAGE = 15;
export const PROGRAM_JSON_PRICE = 16;
export const PROGRAM_JSON_PROFITABILITY = 17;
export const PROGRAM_JSON_PHOTOS = 18;
export const PROGRAM_JSON_SURFACE = 19;
export const PROGRAM_JSON_ANNEX_TIDS = 20;
export const PROGRAM_JSON_ACTABILITY = 21;
export const PROGRAM_JSON_OTHERS = 22;
export const PROGRAM_JSON_ELIGIBILITY = 23;
export const PROGRAM_JSON_REF = 24;
export const PROGRAM_JSON_ATTRIBUTION = 25;
export const PROGRAM_JSON_OFFERS = 26;
export const PROGRAM_JSON_HOUSING_TYPE = 27;
export const PROGRAM_JSON_KITCHEN = 28;
export const PROGRAM_JSON_SALE_INTERNAL = 29;
export const PROGRAM_JSON_SALE_PARTNER = 30;
export const PROGRAM_JSON_CLEAN_CITY = 31;
export const PROGRAM_JSON_URL_3D = 32;
// Unknown data in index 33
export const PROGRAM_JSON_PROMOTIONS = 34;
export const PROGRAM_JSON_MARKETING = 35;
export const PROGRAM_JSON_REPORTS = 36;
export const PROGRAM_JSON_ELIGIBILITY_ADB = 37;
