import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import { axiosViCrmInstance } from './axiosInstance';

export type apiData<T> = {
  data: T;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const useSWRViCrm = <T extends {}>(
  request: Omit<AxiosRequestConfig, 'baseURL' | 'method' | 'data'>,
  config?: Partial<SWRConfiguration<T>>
) => {
  const requestModified: AxiosRequestConfig = {
    ...request,
    method: `GET`,
    params: {
      site: 'vi3p',
    },
  };

  const { data: axiosData, ...responseUseSWR } = useSWR<
    AxiosResponse<T> | T | apiData<T>,
    AxiosError
  >(
    request?.url ? JSON.stringify(requestModified) : null,
    async () => axiosViCrmInstance(requestModified).then(response => response.data),
    config
  );

  return { ...responseUseSWR, data: axiosData as T };
};
