import React from 'react';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import { error } from 'services';

import styles from './VinciCookies.module.scss';

export default function VinciCookies() {
  function openCookiePreferences() {
    try {
      window.tC.privacyCenter.showPrivacyCenter();
    } catch (e) {
      error('TMS', e);
    }
  }
  return (
    <div className={styles.partenairesContainer}>
      <h2>Gestion des cookies sur le site internet sur VINCI Immobilier Patrimoine</h2>
      <h2>le 14 janvier 2021</h2>
      <p>
        Nous utilisons différents cookies sur le site pour améliorer l’interactivité du site et nos
        services. Lors de votre première visite sur les sites de VINCI Immobilier Patrimoine, une
        fenêtre d’information (POPIN) vous informe de la présence de ces cookies et vous invite à
        indiquer votre choix. Ils ne sont déposés que si vous les acceptez. Vous pouvez à tout
        moment vous informer et paramétrer vos cookies pour les accepter ou les refuser en vous
        rendant sur la page{' '}
        <button className={styles.button} onClick={openCookiePreferences} type="button">
          « Gestion des cookies »{' '}
        </button>
        présente en bas de chaque page du site. Vous pourrez indiquer votre préférence soit
        globalement pour le site, soit service par service.
      </p>

      <h4>Qu&apos;est-ce qu&apos;un &quot;cookie&quot; ?</h4>
      <p>
        Un &quot;cookie&quot; est une suite d&apos;informations, généralement de petite taille et
        identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel
        vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le
        renverra au serveur web chaque fois que vous vous y reconnecterez. Les cookies ont de
        multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d&apos;un
        site marchand, le contenu courant de votre panier d&apos;achat, un identifiant permettant de
        tracer votre navigation pour des finalités statistiques, etc….
      </p>

      <h4>Les cookies utilisés sur le site</h4>
      <h5>A. Cookies strictement techniques</h5>
      <p>
        Ces cookies nous permettent d’améliorer le fonctionnement du site internet de le rendre plus
        convivial pour le visiteur. Par exemple, nous stockons vos données de connexion. Ils sont
        obligatoires et nécessaires pour votre navigation.
      </p>
      <h5>B. Cookies de performance / Cookies de mesure d’audience</h5>
      <p>
        Ces cookies permettent de savoir si vous avez déjà visité le site auparavant ou non. Ce
        cookie n’est utilisé qu’à des fins statistiques, pour nous permettre de savoir,
        principalement :
        <ul>
          <li> Le nombre de visiteurs uniques</li>
          <li> Les heures de connexion les plus fréquentes </li>
          <li> Les pages visitées </li>
          <li> Combien de temps vous naviguez sur le site </li>
        </ul>
        Ces cookies sont uniquement installés si vous avez donné votre accord.
      </p>
      <h5>C. Cookies de personnalisation</h5>
      <p>
        Ces cookies permettent de personnaliser votre expérience sur notre site web et vous proposer
        éventuellement des programmes qui correspondent à votre localisation lorsque vous nous
        l’indiquer et sur la base des recherches que vous faites sur notre site internet. Ils
        permettent également d’afficher des cartes sur notre sites en positionnant le programme
        immobilier dans son environnement, avec l’ensemble des points d’intérêts. Ces cookies sont
        uniquement installés si vous avez donné votre accord.
      </p>
      <h5>D. Cookies réseaux sociaux et vidéos</h5>
      <p>
        Le site de VINCI Immobilier Patrimoine s’appuie sur certains services proposés par des sites
        tiers. Il s’agit notamment :
        <ul>
          <li> Des boutons de partage (twitter, Facebook, Instagram,….)</li>
          <li>Des listes de tweets (Twitter)</li>
          <li> Des vidéos diffusées sur le site (youtube, dailymotion,…)</li>
        </ul>
        Ils permettent aux réseaux sociaux citez ci-dessus d’enregistrer les articles et les pages
        que vous partagez via le bouton de partage. Ils peuvent également contenir des cookies de
        suivis qui tracent votre comportement de navigation. Ces cookies sont uniquement installés
        si vous avez donné votre accord.
      </p>

      <h5>Liste exhaustive des cookies et des partenaires</h5>
      <p>
        Veuillez trouver ci-dessous, la synthèse des cookies déposées en fonction de vos
        consentements
      </p>
      <div id="rgpd-tableauPartenaires" />
      <TagCommander navigation_pagename="homepage" navigation_template="homepage" />
    </div>
  );
}
