import React from 'react';
import type { ComponentProps } from 'react';
import MuiPaper from '@material-ui/core/Paper';

import LastSavedSearchList from 'modules/SaveSearch/Components/LastSavedSearchList/LastSavedSearchList';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';

import styles from './AutocompletePaper.module.scss';

interface AutocompletePaperProps extends ComponentProps<typeof MuiPaper> {
  showSavedSearch?: boolean;
}

export default function AutocompletePaper({
  children,
  showSavedSearch = false,
  ...paperProps
}: AutocompletePaperProps) {
  return (
    <MuiPaper {...paperProps}>
      <div className={styles.scrollWrapper}>
        <CustomScrollbar className={styles.scroll}>
          <div className={styles.scrollContent}>{children}</div>
        </CustomScrollbar>
      </div>
      {showSavedSearch && <LastSavedSearchList />}
    </MuiPaper>
  );
}
