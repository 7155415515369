import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { formatActivityDate } from 'services/date';
import { replaceTokens } from 'services/formatter';
import { PASTILLE_STATUS_COLOR } from 'services/pastille';
import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_STATUS_WON,
  ACTIVITY_TAB_ID_BOOKINGS,
  ACTIVITY_TAB_ID_PREBOOKINGS,
  ACTIVITY_TAB_ID_SALES,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
} from 'settings/activity';
import {
  LABEL_DATE,
  LABEL_DATE_EXPIRATION,
  LABEL_SEE_BOOKING,
  LABEL_SEE_PRE_BOOKING,
  LABEL_SEE_SALE,
} from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import { modifyQuery } from 'services/url';

import ActivityStep from 'commonUi/ActivityStep/ActivityStep';
import { Pastille } from 'commonUi/Pastille/Pastille';

import styles from './ActivityStatus.module.scss';

const TOOLTIP = {
  [ACTIVITY_TYPE_OPTION]: LABEL_SEE_PRE_BOOKING,
  [ACTIVITY_TYPE_PRE_BOOKING]: LABEL_SEE_BOOKING,
  [ACTIVITY_TYPE_BOOKING]: LABEL_SEE_SALE,
};
const HANDLE_CLICK = {
  [ACTIVITY_TYPE_OPTION]: ACTIVITY_TAB_ID_PREBOOKINGS,
  [ACTIVITY_TYPE_PRE_BOOKING]: ACTIVITY_TAB_ID_BOOKINGS,
  [ACTIVITY_TYPE_BOOKING]: ACTIVITY_TAB_ID_SALES,
};

interface ActivityStatusType {
  activity: Activity;
  changeTab?: (newTab: string) => void;
  className?: string;
  displayColumn?: boolean;
  hover?: boolean;
  showSteps?: boolean;
  showStepsForWon?: boolean;
  showSubtitle?: boolean;
}

export default function ActivityStatus({
  activity,
  changeTab,
  className = undefined,
  displayColumn = false,
  hover = false,
  showSteps = false,
  showStepsForWon = false,
  showSubtitle = true,
}: ActivityStatusType) {
  const history = useHistory();
  let subtitle = '';
  if (activity.field_dateexpiration_ac) {
    const date = formatActivityDate(activity.field_dateexpiration_ac, 'DD/MM/YYYY - HH:mm');
    let text = '';
    if (activity.field_statutnom_ac === ACTIVITY_STATUS_CANCELED) {
      text = LABEL_DATE;
    } else if (
      activity.field_statutnom_ac === ACTIVITY_STATUS_LOST ||
      activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
    ) {
      text = LABEL_DATE_EXPIRATION;
    }
    subtitle = date && replaceTokens(text, { [TOKEN_DATE]: date });
  }

  if (activity.field_statutnom_ac === ACTIVITY_STATUS_WON) {
    return (
      <div className={classnames(className, styles.root)}>
        <div className={classnames(styles.tags, { [styles.displayColumn]: displayColumn })}>
          <Pastille
            className={styles.tag}
            label={activity.field_statutnom_ac}
            tooltip={TOOLTIP[activity.field_type_ac]}
            onClick={ev => {
              if (changeTab && activity.field_type_ac in HANDLE_CLICK) {
                ev.stopPropagation();
                history.replace(modifyQuery({}, ['activityId']));
                changeTab(HANDLE_CLICK[activity.field_type_ac]);
              }
            }}
            {...PASTILLE_STATUS_COLOR[activity.field_statutnom_ac]}
          />

          {showStepsForWon && (
            <ActivityStep className={styles.tag} activity={activity} isHover={hover} />
          )}
        </div>
        {showSubtitle && subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    );
  }

  return (
    <div className={classnames(className, styles.root, { [styles.displayColumn]: displayColumn })}>
      <div className={classnames(styles.tags, { [styles.displayColumn]: displayColumn })}>
        <Pastille
          className={styles.tag}
          label={activity.field_statutnom_ac}
          {...PASTILLE_STATUS_COLOR[activity.field_statutnom_ac]}
        />

        {showSteps && <ActivityStep className={styles.tag} activity={activity} isHover={hover} />}
      </div>
      {showSubtitle && subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
}
