import React, { useCallback, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';
import { FormHelperText } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';

import { formatPhone } from 'services';
import { PHONE_COUNTRY_DATAS } from 'settings';

import styles from './PhoneField.module.scss';

const PHONE_FRANCE_CODE = 'fr';

export interface PhoneFieldProps {
  country?: ComponentProps<typeof PhoneInput>['country'];
  disabled?: ComponentProps<typeof PhoneInput>['disabled'];
  error?: boolean;
  helperText?: string;
  id: string;
  inputProps: React.HTMLProps<string>;
  onBlurInput?: (ev: {
    target: { id: string; value: ComponentProps<typeof PhoneInput>['value'] };
  }) => void;
  onChangeInput: (ev: {
    target: { id: string; value: ComponentProps<typeof PhoneInput>['value'] };
    format: string;
  }) => void;
  placeholder?: ComponentProps<typeof PhoneInput>['specialLabel'];
  setCountryCode?: (countryCode: string) => void;
  value?: ComponentProps<typeof PhoneInput>['value'];
}

export default function PhoneField({
  country,
  disabled = false,
  error = false,
  helperText,
  id,
  inputProps,
  onBlurInput = () => {},
  onChangeInput,
  placeholder,
  setCountryCode,
  value,
  ...phoneInputProps
}: PhoneFieldProps) {
  const [focusInput, setFocusInput] = useState(false);
  const [countryInfos, setCountryInfos] = useState<{
    countryCode: string;
    dialCode: string;
    format: string;
    name: string;
  }>(PHONE_COUNTRY_DATAS);

  const handleChange = useCallback(
    (value: string, country: typeof countryInfos) => {
      let newValue = value;
      const formatLength = country.format.split('.').length - 1;
      if (
        country.countryCode === PHONE_FRANCE_CODE &&
        newValue.length > formatLength &&
        newValue.charAt(country.dialCode.length) === '0'
      ) {
        newValue = `${newValue.slice(0, country.dialCode.length)}${newValue.slice(
          country.dialCode.length + 1
        )}`;
      }
      if (country.countryCode === PHONE_FRANCE_CODE && newValue.length > formatLength) {
        newValue = newValue.slice(0, formatLength);
      }
      setCountryInfos(prev => (country.countryCode !== prev.countryCode ? country : prev));
      if (typeof setCountryCode === 'function') {
        setCountryCode(country.countryCode);
      }
      onChangeInput({
        target: { id, value: newValue === country.dialCode ? '' : newValue },
        format: country.format,
      });
    },
    [onChangeInput, id]
  );

  const displayedValue = useMemo(() => {
    if (focusInput && !value) {
      return `+${countryInfos.dialCode}`;
    }
    return formatPhone(value, countryInfos.format);
  }, [value, countryInfos, focusInput]);

  return (
    <div className={styles.phone}>
      <PhoneInput
        autoFormat={false}
        disabled={disabled}
        containerClass={classnames(styles.root, {
          [styles.disabled]: disabled,
          [styles.error]: error,
          [styles.filled]: value,
          [styles.focus]: focusInput,
        })}
        inputClass={styles.input}
        inputProps={{ ...inputProps, id, value: displayedValue ?? '' }}
        country={country}
        countryCodeEditable={false}
        dropdownClass={styles.dropdown}
        onBlur={() => {
          onBlurInput({ target: { id, value } });
          setFocusInput(false);
        }}
        onChange={handleChange}
        onFocus={() => {
          setFocusInput(true);
        }}
        placeholder=""
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        specialLabel={
          // The PhoneInput lib handles JSX instead of string without any issue here
          <label htmlFor={id} className={styles.label}>
            {placeholder} {inputProps.required && <span className={styles.required}>*</span>}
          </label>
        }
        value={value}
        {...phoneInputProps}
      />

      {helperText && (
        <FormHelperText
          classes={{
            error: styles.helperTextError,
            root: styles.helperTextRoot,
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </div>
  );
}
