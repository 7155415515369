import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import {
  BLOC_ALERTE_MODAL_CONTEXT_DEFAULT,
  blocAlerteModalContext,
} from './contexts/blocAlerteModalContext';

import Icon from '../common/components/Atoms/Icon';

import styles from './BlocAlerteModal.module.scss';

export default function BlocAlerteModal({ customCTA: Component, disable = false }) {
  const { showBlock, setShowBlock, settings, userIdCrm, setBlockAsSeenByUser, theme } = useContext(
    blocAlerteModalContext
  );

  const { bloc_alerte_cta: blocAlerteCTA, contenu, est_affiche: isDisplay, image, titre } =
    settings ?? BLOC_ALERTE_MODAL_CONTEXT_DEFAULT.settings;

  function handleClose() {
    setShowBlock(false);
    setBlockAsSeenByUser(userIdCrm);
  }

  if (!isDisplay || !contenu || disable) {
    return null;
  }
  return (
    <Dialog
      open={showBlock}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
      classes={{
        paperScrollPaper: classnames(styles.paperScrollPaper, styles[theme]),
        paperWidthLg: classnames(styles.paperWidthLg, styles[theme]),
      }}
      maxWidth="lg"
    >
      <DialogTitle
        classes={{
          root: classnames(styles.titre, styles[theme], {
            [styles.noTitle]: !titre,
          }),
        }}
        disableTypography
      >
        {titre}
        <Icon onClick={handleClose} icon="cross" />
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.dialogContent,
        }}
      >
        {image && <img className={styles.img} src={image} alt="img" />}
        <DialogContentText
          id="alert-dialog-description"
          classes={{
            root: classnames(styles.dialogContentText, styles[theme]),
          }}
        >
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: contenu }}
          />
        </DialogContentText>
      </DialogContent>
      {blocAlerteCTA.libelle && blocAlerteCTA.url && (
        <DialogActions
          classes={{
            root: styles.dialogActions,
          }}
        >
          <Component
            classes={{
              root: classnames(styles.button, styles[theme]),
            }}
            className={classnames(styles.button, styles[theme])}
            href={blocAlerteCTA.url}
            target={blocAlerteCTA.target}
          >
            {blocAlerteCTA.libelle}
          </Component>
        </DialogActions>
      )}
    </Dialog>
  );
}

BlocAlerteModal.propTypes = {
  customCTA: PropTypes.elementType.isRequired,
  disable: PropTypes.bool,
};

BlocAlerteModal.defaultProps = {
  disable: false,
};
