import React, { useEffect, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';
import ReactYoutube from 'react-youtube';

import useTms from 'hooks/context/useTms';
import { TMS_CATEGORY_SOCIAL_NETWORKS, TMS_VENDOR_YOUTUBE } from 'settings/tms';
import { Placeholder } from '../Placeholder/Placeholder';

interface YouTubeProps extends ComponentProps<typeof ReactYoutube> {
  className?: string;
}

export default function YouTube({ className, opts, ...props }: YouTubeProps) {
  const canShow = useTms({
    category: TMS_CATEGORY_SOCIAL_NETWORKS,
    vendor: TMS_VENDOR_YOUTUBE,
  });
  const [player, setPlayer] = useState({
    playVideo: () => {},
    pauseVideo: () => {},
  });
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!player) {
      return;
    }
    if (isPlaying) {
      player.playVideo();
    } else {
      player.pauseVideo();
    }
  }, [player, isPlaying]);

  if (!canShow) {
    return (
      <div className="vinci-youtube-player">
        <Placeholder
          absolute
          featureName="la vidéo"
          featureCategory="Réseaux sociaux et vidéo"
          featureVendor="Youtube"
          {...opts}
        />
      </div>
    );
  }

  return (
    <div className={classnames('vinci-youtube-player', className, { playing: isPlaying })}>
      <button
        type="button"
        className="vinci-youtube-overlay"
        onClick={() => setIsPlaying(true)}
        aria-label="Jouer la vidéo"
      />
      <ReactYoutube
        containerClassName="vinci-youtube-video"
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onReady={({ target }) => setPlayer(target)}
        opts={opts}
        {...props}
      />
    </div>
  );
}
