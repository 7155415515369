import React from 'react';

import styles from './PageTitle.module.scss';

export default function PageTitle(props: PageTitleType) {
  const { title } = props;

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
}

type PageTitleType = {
  title: string;
};
