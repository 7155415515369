import React from 'react';
import classnames from 'classnames';

import type { ProgramFreeBlock as ProgramFreeBlockType } from 'api/viOffresAPI/apiTypes/Program';

import { hex2rgba } from 'services/ui';
import { extractVideoIdFromUrl } from 'services/videos';

import FieldCta from 'commonUi/FieldCta/FieldCta';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import YouTube from 'commonUi/YouTube/YouTube';

import styles from './ProgramFreeBlock.module.scss';

interface ProgramFreeBlockProps {
  className?: string;
  paragraph: Omit<ProgramFreeBlockType, 'mentions_legales'>;
}

export default function ProgramFreeBlock({ className, paragraph }: ProgramFreeBlockProps) {
  const title = paragraph.title_wysiwyg || undefined;
  const text = paragraph.text_wysiwyg || undefined;
  const cta = paragraph.cta
    ? {
        uri: paragraph.cta.document || paragraph.cta.uri,
        target: '_blank',
        title: paragraph.cta.texte_lien || paragraph.cta.title,
      }
    : undefined;

  if (!title && !text && !cta) {
    return null;
  }

  const videoId = paragraph.media ? extractVideoIdFromUrl(paragraph.media) : undefined;
  const imageUrl = paragraph.image || paragraph.media;

  return (
    <div
      className={classnames(
        className,
        styles.root,
        paragraph.display === 'img_left' ? styles.left : styles.right
      )}
      style={{ backgroundColor: hex2rgba(paragraph.background_color) }}
    >
      <div className={styles.content}>
        {title && <FieldRte html={title} />}
        {text && <FieldRte className={styles.text} html={text} />}
        {cta?.title && cta?.uri && <FieldCta className={styles.cta} cta={cta} />}
      </div>

      {!videoId && imageUrl && (
        <figure className={styles.mediaContainer}>
          <FieldImage className={styles.image} media={{ url: imageUrl, properties: {} }} />
        </figure>
      )}
      {videoId && (
        <div className={classnames(styles.mediaContainer, styles.videoContainer)}>
          <YouTube
            videoId={videoId}
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                controls: 0,
                enablejsapi: 1,
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
