import React, { useState } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazy-load';

export default function LazyImage(props: LazyImageType) {
  const {
    alt,
    className = undefined,
    offset = 500,
    onVisible = undefined,
    src,
    throttle = 250,
    title = undefined,
    ...imgProps
  } = props;
  const [visible, setVisible] = useState(false);

  const onContentVisible = () => {
    setVisible(true);
    if (typeof onVisible === 'function') {
      onVisible();
    }
  };

  return (
    <LazyLoad
      debounce={false}
      offsetVertical={offset}
      offsetHorizontal={Infinity} // This is necessary so the images in sliders aren't unrendered when horizontally off screen.
      throttle={throttle}
      onContentVisible={onContentVisible}
    >
      <img
        className={classnames(className, 'img-lazy', {
          'img-lazy--invisible': !visible,
          'img-lazy--visible': visible,
        })}
        alt={alt}
        src={src}
        title={title}
        {...imgProps}
      />
    </LazyLoad>
  );
}

type LazyImageType = {
  alt: string;
  className?: string;
  offset?: number;
  onVisible?: () => void;
  title?: string;
  src: string;
  throttle?: number;
};
