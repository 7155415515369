import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  totem: {
    backgroundColor: COLORS.PRIMARY.BLUE_DARK,
    borderRadius: '3px',
    fontWeight: 'medium',
    padding: '10px 11px',
    position: 'relative',
  },
  totemGradient: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  availableLots: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  separator: {
    margin: '3px 0 3px',
  },
  datesListItem: {
    alignItems: 'flex-start',
    color: COLORS.GREYS.PURE_WHITE,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '9px',
    justifyContent: 'space-between',
    margin: '2px 0',
  },
  dateValue: {
    textTransform: 'uppercase',
  },
  priceWrapper: {
    margin: '0 0 2px 0',
  },
  price: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '12px',
  },
});
