import { useContext, useEffect } from 'react';
import { isEqual } from 'lodash';

import { TMS_DEFAULT_VALUES } from 'settings/tms';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

export type TagCommanderComponentProps = {
  useEffectDeps?: string[];
};

export default function TagCommanderComponent({
  useEffectDeps = [],
  ...tmsValues
}: TagCommanderComponentProps & Partial<typeof TMS_DEFAULT_VALUES>) {
  const { setComponentPageVars } = useContext(TmsContext);

  useEffect(() => {
    setComponentPageVars(prevVars => (isEqual(prevVars, tmsValues) ? prevVars : tmsValues));
  }, [...useEffectDeps.map(key => tmsValues[key])]);

  return null;
}
