import React, { useContext } from 'react';
import type { ComponentProps, ReactNode } from 'react';

import {
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
} from 'settings/activity';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import ActivityTableDesktopAndTablet from '../ActivityDesktopAndTablet/ActivityTableDesktopAndTablet';
import ActivityTableMobile from '../ActivityMobile/ActivityTableMobile';

import styles from './ActivityTab.module.scss';

const LABEL_ACTIVITY_NO_RESULT = {
  [ACTIVITY_TYPE_OPTION]: <>Aucun lot n&apos;a été optionné.</>,
  [ACTIVITY_TYPE_PRE_BOOKING]: <>Aucun lot n&apos;a été pré-réservé.</>,
  [ACTIVITY_TYPE_BOOKING]: <>Aucun lot n&apos;a été réservé.</>,
  [ACTIVITY_TYPE_SALE]: <>Aucun lot n&apos;a été vendu.</>,
};

interface ActivityTabType {
  activityType: ComponentProps<typeof ActivityTableDesktopAndTablet>['activityType'];
  apiUrls:
    | ComponentProps<typeof ActivityTableMobile>['apiUrls']
    | ComponentProps<typeof ActivityTableDesktopAndTablet>['apiUrls'];
  changeTab: (newTab: string) => void;
  data:
    | ComponentProps<typeof ActivityTableMobile>['data']
    | ComponentProps<typeof ActivityTableDesktopAndTablet>['data']
    | undefined;
  isLoading?: boolean;
  stepsSummaryContent?: ReactNode;
}

export default function ActivityTab({
  activityType,
  apiUrls,
  changeTab,
  data,
  isLoading = false,
  stepsSummaryContent,
}: ActivityTabType) {
  const { isResponsive } = useContext(ResponsiveContext);
  if (!data && isLoading) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  if (isResponsive) {
    return (
      <ActivityModalsProvider>
        <ActivityTableMobile
          activityType={activityType}
          apiUrls={apiUrls}
          data={data}
          empty={LABEL_ACTIVITY_NO_RESULT[activityType]}
        />
      </ActivityModalsProvider>
    );
  }

  return (
    <ActivityModalsProvider>
      <div className={styles.root}>
        {!isResponsive && stepsSummaryContent}

        <ActivityTableDesktopAndTablet
          activityType={activityType}
          data={data}
          apiUrls={apiUrls}
          changeTab={changeTab}
          empty={LABEL_ACTIVITY_NO_RESULT[activityType]}
        />
      </div>
    </ActivityModalsProvider>
  );
}
