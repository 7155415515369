import React from 'react';
import classnames from 'classnames';
import { CircularProgress, Typography } from '@material-ui/core';

import styles from './CircularProgressWithLabel.module.scss';

export default function CircularProgressWithLabel({
  labelClassName,
  value,
  size,
}: CircularProgressWithLabelType) {
  return (
    <div className={styles.root}>
      <CircularProgress
        classes={{ colorPrimary: styles.determinate }}
        variant="determinate"
        value={value}
        size={size}
      />
      <Typography
        className={classnames(styles.label, styles.labelClassName)}
        variant="caption"
        component="div"
        color="textSecondary"
      >
        {`${Math.round(value)}%`}
      </Typography>
    </div>
  );
}

export type CircularProgressWithLabelType = {
  labelClassName?: string;
  /** The value of the progress indicator. Between 0 and 100 */
  value: number;
  size?: number;
};
