import { createContext } from 'react';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

interface Pdf {
  blob: Blob;
  url: string;
}

export default createContext<{
  getPdf: (nid: string) => Pdf | undefined;
  lotsDetail: Record<string, LotTypeV2 | undefined>;
  programs: Record<string, ProgramTypeV2 | undefined>;
  generate: (ref: string, number: string, nid: string) => Promise<string>;
  isGenerating: Record<string, boolean | undefined>;
}>({
  getPdf: () => undefined,
  lotsDetail: {},
  programs: {},
  generate: () => Promise.resolve(''),
  isGenerating: {},
});
