import React from 'react';
import type { ComponentProps } from 'react';

import { ParagraphType } from 'api/vi3pAPI/apiTypes/ContentType';

import Paragraphs from 'modules/Paragraphs/Paragraphs';
import TagCommander from 'modules/App/TagCommander/TagCommander';

interface SEOPageType {
  paragraphs: ParagraphType[];
  tms?: Omit<ComponentProps<typeof TagCommander>, 'useEffectDeps'>;
}

export default function SEOPage({ paragraphs, tms }: SEOPageType) {
  return (
    <section className="section-conteneur">
      <div className="section-conteneur-wrap container">
        <Paragraphs paragraphs={paragraphs} />
      </div>
      {tms && <TagCommander {...tms} useEffectDeps={Object.keys(tms)} />}
    </section>
  );
}
