import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { useSnackbar } from 'notistack';
import type { SnackbarKey } from 'notistack';
import {
  CircularProgress,
  InputAdornment,
  ClickAwayListener as MuiClickAwayListener,
  TextField,
  Typography,
} from '@material-ui/core';
import { debounce } from 'lodash';
import AutoCompleteMaterialUI from '@material-ui/lab/Autocomplete';

import {
  LABEL_CITIES,
  LABEL_DEPARTMENTS,
  LABEL_REGIONS,
  LABEL_SEARCH_NO_LOCATION,
  LABEL_SEARCH_PLACEHOLDER,
  LABEL_SEARCH_PLACEHOLDER_WHERE,
} from 'settings/labels';
import { LOT_JSON_PROGRAM_REF } from 'settings/lots';
import {
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_DEPARTMENT,
  LOCATION_TYPE_REGION,
  MAP_DEFAULT_LAT,
  MAP_DEFAULT_LNG,
  MAP_DEFAULT_ZOOM,
} from 'settings/search';
import {
  GMAP_ANGULAR_HEIGHT_CITY,
  GMAP_ANGULAR_HEIGHT_DEPARTMENT,
  GMAP_ANGULAR_WIDTH_CITY,
  GMAP_ANGULAR_WIDTH_DEPARTMENT,
  REGION_VIEWPORT,
} from 'settings/map';
import { TMS_CATEGORY_PERSONALIZATION, TMS_VENDOR_GOOGLE_MAPS } from 'settings/tms';

import type { GeoSearchResult } from 'api/viOffresAPI/apiTypes/Autocomplete';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { geocode, getDistanceInKm, getSearchViewportFromPoint } from 'services/map';
import { modifyQuery } from 'services/url';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';

import useTms from 'hooks/context/useTms';

import useAutocomplete from 'modules/HomePage/hooks/useAutocomplete';
import useFilterData, { filterProgramsWithLots } from 'modules/HomePage/hooks/useFilterData';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import AutocompletePaper from './AutocompletePaper';
import AutocompleteChip from './AutocompleteChip';

import styles from './Autocomplete.module.scss';

const MAX_LOCATIONS = 5;

type Coordinates = Parameters<typeof getDistanceInKm>[1];
type MapViewport = typeof REGION_VIEWPORT[number];

async function toto(
  geocodedLocations: { coordinates: Coordinates }[],
  locations: string[],
  baseViewport: Record<'north' | 'east' | 'south' | 'west', number>,
  programs: ProgramListType[]
) {
  const coordinates = {
    lat: Math.min(...geocodedLocations.map(({ coordinates }) => coordinates.lat)),
    lng: Math.min(...geocodedLocations.map(({ coordinates }) => coordinates.lng)),
  };
  const [nearProgram] = programs.reduce<[ProgramListType | undefined, number]>(
    ([closestProgram, minDist], program) => {
      const programDistance = getDistanceInKm(program, coordinates);
      if (programDistance < minDist) {
        return [program, programDistance];
      }
      return [closestProgram, minDist];
    },
    [undefined, Infinity]
  );

  if (!nearProgram) {
    return Promise.reject();
  }
  const viewport = await geocode(`${nearProgram.city} ${nearProgram.postalCode}`)
    .then(({ geometry }) => {
      const locationsViewport = geometry.viewport.toJSON();
      const updatedNorth = Math.max(locationsViewport.north, baseViewport.north);
      const updatedEast = Math.max(locationsViewport.east, baseViewport.east);
      const updatedSouth = Math.min(locationsViewport.south, baseViewport.south);
      const updatedWest = Math.min(locationsViewport.west, baseViewport.west);
      // east, north, south and west coordinates have been removed from this redirect URL
      // it avoids map move on save search button click after adding a locality without results (#9902 bug)
      return `south=${updatedSouth},west=${updatedWest},north=${updatedNorth},east=${updatedEast}`;
    })
    .catch(
      () =>
        `south=${baseViewport.south},west=${baseViewport.west},north=${baseViewport.north},east=${baseViewport.east}`
    );
  return {
    locations,
    viewport,
    nearProgram,
  };
}

interface AutocompleteProps {
  classes?: Partial<
    Record<
      | 'focused'
      | 'groupLabel'
      | 'input'
      | 'inputAdornment'
      | 'inputRoot'
      | 'listbox'
      | 'noOptions'
      | 'open'
      | 'option'
      | 'optionLabel'
      | 'paper'
      | 'popper'
      | 'root'
      | 'simple'
      | 'tag'
      | 'textFieldRoot',
      string
    >
  >;
  maxItems?: number;
  positionSuggestedSearchRef?: React.RefObject<HTMLDivElement>;
  simple?: boolean;
}

export default function Autocomplete({
  classes = {},
  maxItems,
  positionSuggestedSearchRef = undefined,
  simple = false,
}: AutocompleteProps) {
  const history = useHistory();
  const { settings } = useContext(settingsContext);
  const isMapShown = useTms({
    category: TMS_CATEGORY_PERSONALIZATION,
    vendor: TMS_VENDOR_GOOGLE_MAPS,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const mapToastKeyRef = useRef<SnackbarKey>();
  useEffect(() => {
    if (isMapShown && mapToastKeyRef.current) {
      closeSnackbar(mapToastKeyRef.current);
      mapToastKeyRef.current = undefined;
    }
  }, [closeSnackbar, isMapShown]);

  const search = useSearch();
  const { filterData, lots, programs } = useFilterData();
  const usablePrograms = useMemo(
    () =>
      // We need to filter for DISPLAYABLE programs that can show up on the map
      filterProgramsWithLots(
        programs,
        Array.from(new Set(lots.map(lot => lot[LOT_JSON_PROGRAM_REF]))),
        search
      ),
    [programs, lots]
  );
  const [searchInput, setSearchInput] = useState<string>();
  const { results, isValidating } = useAutocomplete<GeoSearchResult[]>(searchInput, data => {
    /// format data for autocomplete => flatmap data
    if (data && searchInput) {
      return [
        ...data?.regions?.map(
          ({ id_region: id, libelle_region: label, nbResults }) =>
            ({
              label,
              value: label,
              id,
              type: LOCATION_TYPE_REGION,
              nbResults,
            } as const)
        ),
        ...data?.departements?.map(
          ({ id_departement: id, libelle_departement: label, nbResults }) =>
            ({
              label,
              value: label,
              id,
              type: LOCATION_TYPE_DEPARTMENT,
              nbResults,
            } as const)
        ),
        ...data?.villes?.map(
          ({
            libelle_ville: city,
            postal_code: postalCode,
            id_departement: idDepartment,
            libelle_departement: department,
            nbResults,
            nbResultsDpts,
          }) =>
            ({
              label: `${city} (${postalCode})`,
              value: city,
              id: postalCode,
              idDepartment,
              type: LOCATION_TYPE_CITY,
              department,
              nbResults,
              nbResultsDepartment: nbResultsDpts,
            } as const)
        ),
      ];
    }
    return [];
  });
  const { isResponsive } = useContext(ResponsiveContext);
  const [focusAutocomplete, setFocusAutocomplete] = useState(false);

  function onElementsClicked(values: GeoSearchResult[]) {
    if (values?.length) {
      // here geocode, update url with good viewport
      // modify query without programmeId
      const locations: string[] = values.map(
        locations =>
          `label=${locations?.label},value=${locations?.value},id=${locations?.id},type=${locations?.type}`
      );

      // `geocode` can reject, which means we can't use `Promise.all` because we still need to use
      // the rest of the data from the geolocs that didn't reject
      return Promise.allSettled(
        values?.map(({ id, type, value }) => {
          if (type === 'region' && REGION_VIEWPORT[id] !== undefined) {
            return Promise.resolve([REGION_VIEWPORT[id], type]);
          }
          return Promise.all([geocode(`france ${value} ${id}`), type]);
        })
      ).then(promiseResults => {
        const fullfilledResults = promiseResults.reduce<
          { viewport: google.maps.LatLngBoundsLiteral; coordinates: google.maps.LatLngLiteral }[]
        >((fullfilledResuls, result) => {
          // Ignore rejected promises & transform resolved ones into desired data
          if (result.status === 'fulfilled') {
            const [geoloc, type] = result.value;
            let coordinates: Coordinates;
            let viewport: ReturnType<typeof getSearchViewportFromPoint>;
            switch (type) {
              case 'region':
                // Google map is terrible at geolocating French regions, so we hard code the desired map viewport for each region
                viewport = geoloc as MapViewport;
                coordinates = {
                  lat: (viewport.north + viewport.south) / 2,
                  lng: (viewport.east + viewport.west) / 2,
                };
                break;
              case 'department': {
                coordinates = (result
                  .value[0] as google.maps.GeocoderResult).geometry.location.toJSON();
                const settingsAngularWidth = parseFloat(
                  settings.map?.angular_width_dpt?.replace(',', '.') ?? ''
                );
                const settingsAngularHeight = parseFloat(
                  settings.map?.angular_height_dpt?.replace(',', '.') ?? ''
                );
                viewport = getSearchViewportFromPoint(
                  coordinates,
                  Number.isNaN(settingsAngularWidth)
                    ? GMAP_ANGULAR_WIDTH_DEPARTMENT
                    : settingsAngularWidth,
                  Number.isNaN(settingsAngularHeight)
                    ? GMAP_ANGULAR_HEIGHT_DEPARTMENT
                    : settingsAngularHeight
                );
                break;
              }
              default: {
                coordinates = (result
                  .value[0] as google.maps.GeocoderResult).geometry.location.toJSON();
                const settingsAngularWidth = parseFloat(
                  settings.map?.angular_width_city?.replace(',', '.') ?? ''
                );
                const settingsAngularHeight = parseFloat(
                  settings.map?.angular_height_city?.replace(',', '.') ?? ''
                );
                viewport = getSearchViewportFromPoint(
                  coordinates,
                  Number.isNaN(settingsAngularWidth)
                    ? GMAP_ANGULAR_WIDTH_CITY
                    : settingsAngularWidth,
                  Number.isNaN(settingsAngularHeight)
                    ? GMAP_ANGULAR_HEIGHT_CITY
                    : settingsAngularHeight
                );
                break;
              }
            }
            return [...fullfilledResuls, { coordinates, viewport }];
          }
          return fullfilledResuls;
        }, []);

        if (fullfilledResults?.length) {
          const south = Math.min(...fullfilledResults.map(({ viewport }) => viewport.south));
          const west = Math.min(...fullfilledResults.map(({ viewport }) => viewport.west));
          const north = Math.max(...fullfilledResults.map(({ viewport }) => viewport.north));
          const east = Math.max(...fullfilledResults.map(({ viewport }) => viewport.east));

          const { programs: filteredPrograms } = filterData({
            locations,
            south,
            west,
            north,
            east,
          });
          if (filteredPrograms?.length) {
            return history.replace(
              modifyQuery(
                {
                  locations,
                  viewport: `south=${south},west=${west},north=${north},east=${east}`,
                },
                ['programRef', 'nearProgram']
              )
            );
          }
          // Location don't have programs so display near programs.
          return toto(
            fullfilledResults,
            locations,
            { south, west, north, east },
            usablePrograms
          ).then(query => history.replace(modifyQuery(query, ['programRef'])));
        }
        return null;
      });
    }
    return history.replace(
      modifyQuery({ lat: MAP_DEFAULT_LAT, lng: MAP_DEFAULT_LNG, zoom: MAP_DEFAULT_ZOOM }, [
        'viewport',
        'locations',
        'nearProgram',
      ])
    );
  }

  const mapLabel = {
    [LOCATION_TYPE_CITY]: LABEL_CITIES,
    [LOCATION_TYPE_DEPARTMENT]: LABEL_DEPARTMENTS,
    [LOCATION_TYPE_REGION]: LABEL_REGIONS,
  };

  const debounced = debounce(newValue => setSearchInput(newValue), 500);
  const defaultValues = search.locations?.map(s => {
    const obj = {} as GeoSearchResult;
    s?.split(',')?.forEach(element => {
      const [label, value] = element.split('=');
      obj[label] = value;
    });
    if (obj.type === 'city' && results.length > 0 && obj.label) {
      const result = results.find(r => r.label === obj.label);
      if (result) {
        return result;
      }
    }
    return obj;
  });
  let isOpen = !!searchInput && searchInput.length >= 2;
  if (defaultValues?.length >= MAX_LOCATIONS) {
    isOpen = false;
  }

  function handleClickOutside() {
    setFocusAutocomplete(false);
  }

  return (
    <MuiClickAwayListener onClickAway={handleClickOutside}>
      <div className={styles.wrapper} data-test-id="search-input-wrapper">
        <AutoCompleteMaterialUI<GeoSearchResult, true, true>
          autoHighlight
          data-test-id="search-autocomplete-input"
          onFocus={
            isMapShown
              ? undefined
              : () => {
                  if (!mapToastKeyRef.current) {
                    mapToastKeyRef.current = enqueueSnackbar(
                      <p>
                        Pour utiliser la recherche, veuillez{' '}
                        <button
                          type="button"
                          className={styles.toastLink}
                          onClick={window.tC.privacyCenter.showPrivacyCenter}
                        >
                          accepter tous vos cookies
                        </button>
                      </p>,
                      {
                        persist: true,
                        preventDuplicate: true,
                        onExit() {
                          mapToastKeyRef.current = undefined;
                        },
                      }
                    );
                  }
                }
          }
          classes={{
            focused: classnames(classes.focused, styles.focused),
            groupLabel: classnames(classes.groupLabel, styles.groupLabel),
            input: classnames(classes.input, styles.input),
            inputRoot: classnames(classes.inputRoot, styles.inputRoot),
            listbox: classnames(classes.listbox, styles.listbox),
            noOptions: classnames(classes.noOptions, styles.noOptions),
            option: classnames(classes.option, styles.option),
            paper: classnames(classes.paper, styles.paper),
            popper: classnames(classes.popper, styles.popper),
            root: classnames(
              classes.root,
              styles.root,
              isOpen && [classes.open, styles.open],
              simple && [classes.simple, styles.simple]
            ),
            tag: classnames(classes.tag, styles.tag),
          }}
          open={isOpen}
          noOptionsText={
            isValidating ? (
              <CircularProgress variant="indeterminate" size={24} />
            ) : (
              LABEL_SEARCH_NO_LOCATION
            )
          }
          disableClearable
          key={[...(search.locations || [])]?.join('')}
          multiple
          defaultValue={defaultValues}
          limitTags={isResponsive ? 1 : 2}
          options={!isValidating ? results : []}
          getOptionLabel={option => mapLabel[option.type]}
          getOptionDisabled={option => {
            return defaultValues?.some(val => val.label.includes(option.label));
          }}
          groupBy={option => mapLabel[option.type]}
          renderOption={(option, state) => {
            if (state.inputValue) {
              const regex = new RegExp(state.inputValue, 'gi');
              return (
                <Typography
                  classes={{ root: classnames(classes.optionLabel, styles.typographyRoot) }}
                  noWrap
                >
                  <span>
                    {ReactHtmlParser(
                      option.label.replace(
                        regex,
                        match => `<span class="search-highlight">${match}</span>`
                      )
                    )}
                  </span>
                  {option.nbResults > 0 && <span>{option.nbResults}</span>}
                </Typography>
              );
            }
            return <Typography noWrap>{option.label}</Typography>;
          }}
          onChange={(_, newValue) => {
            setFocusAutocomplete(true);
            onElementsClicked(newValue as GeoSearchResult[]);
          }}
          onInputChange={(_, newValue) => {
            debounced(newValue);
          }}
          renderInput={params => {
            const newParams = {
              ...params,
              placeholder:
                defaultValues?.length > 0
                  ? LABEL_SEARCH_PLACEHOLDER_WHERE
                  : LABEL_SEARCH_PLACEHOLDER,
              classes: {
                root: classnames(classes.textFieldRoot, styles.textFieldRoot),
              },
              InputProps: {
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    classes={{
                      root: classnames(classes.inputAdornment, styles.inputAdornment),
                    }}
                    position="start"
                  >
                    <SvgIcon className={styles.svgIcon} iconId="icon-search" />
                  </InputAdornment>
                ),
              },
              inputProps: {
                ...params.inputProps,
                classes: {
                  root: classnames(classes.inputRoot, styles.inputRoot),
                  input: classnames(classes.input, styles.input),
                },
                disableUnderline: true,
              },
              inputRef: (input: HTMLInputElement) => {
                if (focusAutocomplete && input) {
                  input.focus();
                }
              },
            };
            return <TextField {...newParams} />;
          }}
          filterOptions={p => p}
          renderTags={(value, getTagProps) => {
            if (typeof maxItems === 'number' && maxItems > 0) {
              return (
                <>
                  {value.slice(0, maxItems).map((option, index) => (
                    <AutocompleteChip
                      key={option.id}
                      autocompleteOpen={isOpen}
                      defaultValues={defaultValues}
                      getTagProps={getTagProps}
                      index={index}
                      onAutocompleteOptionClick={onElementsClicked}
                      option={option}
                      positionSuggestedSearchRef={positionSuggestedSearchRef}
                    />
                  ))}
                  {value.length > maxItems && (
                    <div className={styles.chipLimit}>+{value.length - maxItems}</div>
                  )}
                </>
              );
            }
            return value.map((option, index) => (
              <AutocompleteChip
                key={option.id}
                autocompleteOpen={isOpen}
                defaultValues={defaultValues}
                getTagProps={getTagProps}
                index={index}
                onAutocompleteOptionClick={onElementsClicked}
                option={option}
                positionSuggestedSearchRef={positionSuggestedSearchRef}
              />
            ));
          }}
          PaperComponent={props => <AutocompletePaper showSavedSearch {...props} />}
        />
      </div>
    </MuiClickAwayListener>
  );
}
