import React from 'react';
import { View } from '@react-pdf/renderer';

import { floorFormatter, numberFormatter } from 'services/formatter';
import {
  LABEL_EXPOSITION,
  LABEL_STAIR,
  LABEL_SURFACE_HABITABLE,
  LABEL_TYPE,
} from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import PdfRowDetailsList from '../../../Common/PdfRowDetailsList/PdfRowDetailsList';

export default function LotPdfHousingInfos({ lot }: LotPdfHousingInfosProps) {
  return (
    <View>
      <PdfRowDetailsList
        items={{
          [LABEL_TYPE]: {
            label: LABEL_TYPE,
            value: lot.typologie,
          },
          [LABEL_STAIR]: { label: LABEL_STAIR, value: floorFormatter(lot.etage) },
          [LABEL_SURFACE_HABITABLE]: {
            label: LABEL_SURFACE_HABITABLE,
            value: numberFormatter(lot.surfaces.surfaceHabitable, 'm²'),
          },
          [LABEL_EXPOSITION]: { label: LABEL_EXPOSITION, value: lot.orientation },
        }}
      />
    </View>
  );
}

export type LotPdfHousingInfosProps = {
  lot: LotTypeV2;
};
