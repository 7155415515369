export const USER_GENDER_FEMALE = 'Madame';
export const USER_GENDER_MALE = 'Monsieur';
export const USER_SHORT_GENDER_FEMALE = 'Mme';
export const USER_SHORT_GENDER_MALE = 'M';

export const USER_GENDER_MAP = new Map([
  [USER_SHORT_GENDER_MALE, USER_GENDER_MALE],
  [USER_GENDER_MALE, USER_GENDER_MALE],
  [USER_SHORT_GENDER_FEMALE, USER_GENDER_FEMALE],
  [USER_GENDER_FEMALE, USER_GENDER_FEMALE],
]);

export const USER_SHORT_GENDER_MAP = new Map([
  [USER_SHORT_GENDER_MALE, USER_SHORT_GENDER_MALE],
  [USER_GENDER_MALE, USER_SHORT_GENDER_MALE],
  [USER_SHORT_GENDER_FEMALE, USER_SHORT_GENDER_FEMALE],
  [USER_GENDER_FEMALE, USER_SHORT_GENDER_FEMALE],
]);

export const USER_ROLE_OP = 'Opérationnel';
export const USER_ROLE_DO = 'Directeur opérationnel';
export const USER_ROLE_SMC = 'Signataire du mandat de commercialisation';
export const USER_ROLE_INDEP = 'Indépendant';
export const USER_ROLE_OTHER = 'Autre';

export const USER_ROLE_MAPPING: Record<
  string,
  | typeof USER_ROLE_OP
  | typeof USER_ROLE_DO
  | typeof USER_ROLE_SMC
  | typeof USER_ROLE_INDEP
  | typeof USER_ROLE_OTHER
> = {
  Opérationnel: USER_ROLE_OP,
  Operationnel: USER_ROLE_OP,
  'Directeur opérationnel': USER_ROLE_DO,
  'Directeur operationnel': USER_ROLE_DO,
  DirecteurOperationnel: USER_ROLE_DO,
  'Signataire du mandat de commercialisation': USER_ROLE_SMC,
  SignataireDuMandatDeCommercialisation: USER_ROLE_SMC,
  Indépendant: USER_ROLE_INDEP,
  Independant: USER_ROLE_INDEP,
  Autre: USER_ROLE_OTHER,
};
