import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { isAuthenticated, isInvite } from 'services/authentication';
import { getLoginUrlWithState } from 'services/url';

import AuthenticatedLayout from 'layout/AuthenticatedLayout';
import Assistance from 'modules/Assistance/components/AssistanceBlock/Assistance';
import PendingValidation from 'modules/Authentication/PendingValidation';

import ErrorBoundary from './Tools/ErrorBoundary';

interface AuthenticatedRouteProps extends Omit<RouteProps, 'component' | 'path' | 'render'> {
  component: NonNullable<RouteProps['component']>;
  noFooter?: boolean;
  noHeader?: boolean;
  path?: Exclude<RouteProps['path'], readonly string[]>;
}

const TECHNICAL_ROUTES = ['/deconnexion', '/refresh-aadb2c'];
function isTechnicalRoute(path: AuthenticatedRouteProps['path']) {
  if (!path) {
    return false;
  }
  return TECHNICAL_ROUTES.includes(path);
}

export default function AuthenticatedRoute({
  component: Component,
  noFooter,
  noHeader,
  ...routeProps
}: AuthenticatedRouteProps) {
  // Go to Login page if we are no longer connected.
  if (!isAuthenticated()) {
    window.location.href = getLoginUrlWithState();
    return null;
  }

  // Don't display "pending validation" for technical uri"
  if (!isTechnicalRoute(routeProps.path) && isInvite()) {
    return (
      <Route
        {...routeProps}
        render={() =>
          isAuthenticated() ? (
            <AuthenticatedLayout hideFooter={noFooter} hideHeader={noHeader}>
              <ErrorBoundary location={routeProps.location}>
                <PendingValidation />
              </ErrorBoundary>
            </AuthenticatedLayout>
          ) : (
            <Redirect to="/deconnexion" />
          )
        }
      />
    );
  }

  return (
    <Route
      {...routeProps}
      render={componentProps => (
        <AuthenticatedLayout hideFooter={noFooter} hideHeader={noHeader}>
          <ErrorBoundary location={routeProps.location}>
            <Component {...componentProps} />
            <Assistance />
          </ErrorBoundary>
        </AuthenticatedLayout>
      )}
    />
  );
}
