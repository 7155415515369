import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';

import { programHasKitchen, programIsADB } from 'services/programs';
import { CONTENT_TYPE_SERVICE } from 'settings/blocks';
import { PARAGRAPH_TYPE_ACCORDION_SERVICE } from 'settings/paragraph';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { NonNullableArray } from 'types/tools';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';

import AccordionMedia from 'commonUi/AccordionMedia/AccordionMedia';
import FieldCta from 'commonUi/FieldCta/FieldCta';
import Paragraphs from 'commonUi/Paragraphs/Paragraphs';
import ProgramManager from './ProgramManager';

import styles from './ProgramServices.module.scss';

// TODO Do a little better on the typing of `paragraph`
function makeAccordionMediaItem(paragraph: any, injectContent?: JSX.Element) {
  if (paragraph?.content_type !== PARAGRAPH_TYPE_ACCORDION_SERVICE) {
    return null;
  }

  const { cta, media: medias, paragraphs, text_cta: tabLabel, title } = paragraph;

  return {
    content: (
      <>
        <Paragraphs paragraphs={paragraphs} />
        <FieldCta cta={cta?.[0]} />
        {injectContent}
      </>
    ),
    cta,
    medias,
    tabLabel,
    title,
    uuid: paragraph.uuid,
  };
}

export default function ProgramServices({ className = undefined, program }: ProgramServicesProps) {
  const { settings } = useContext(SettingsContext);

  // Get ADB blocks content defined in settings
  const { data: paragraphADB } = useSWRVi3pImmutable({
    url: settings.blocks?.adb
      ? `/content/block_content/${CONTENT_TYPE_SERVICE}?id=${settings.blocks.adb}`
      : undefined,
  });
  const { data: paragraphKitchen } = useSWRVi3pImmutable({
    url: settings.blocks?.equipped_kitchen
      ? `/content/block_content/${CONTENT_TYPE_SERVICE}?id=${settings.blocks.equipped_kitchen}`
      : undefined,
  });
  const { data: paragraphService } = useSWRVi3pImmutable({
    url: settings.blocks?.adb_lame_service
      ? `/content/block_content/${CONTENT_TYPE_SERVICE}?id=${settings.blocks.adb_lame_service}`
      : undefined,
  });

  const items = useMemo(() => {
    const result: ReturnType<typeof makeAccordionMediaItem>[] = [];

    // ADB eligibility
    if (programIsADB(program)) {
      result.push(makeAccordionMediaItem(paragraphADB, <ProgramManager program={program} />));
    }

    // Equipped Kitchen eligibility
    if (programHasKitchen(program)) {
      result.push(makeAccordionMediaItem(paragraphKitchen));
    }

    result.push(makeAccordionMediaItem(paragraphService));

    return result.filter(Boolean) as NonNullableArray<typeof result>;
  }, [program, paragraphADB, paragraphKitchen, paragraphService]);

  if (!program || items.length === 0) {
    return null;
  }

  return (
    <AccordionMedia
      openFirst
      classes={{
        root: classnames(className, styles.root),
        itemsButton: styles.accordionItemsButton,
      }}
      items={items}
    />
  );
}

export type ProgramServicesProps = {
  className?: string;
  program: ProgramTypeV2;
};
