import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_STATUS_WON,
} from 'settings/activity';
import {
  LOT_STATUS_BOOKED,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
  LOT_STATUS_PRE_BOOKED,
} from 'settings/lots';
import {
  TAX_TYPE_DEFICIT,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PINEL,
} from 'settings/taxes';

export const PASTILLE_STATUS_COLOR = {
  // Lot statuses
  [LOT_STATUS_FREE]: { bgColor: '#DAFAE1', textColor: '#0FA669' },
  [LOT_STATUS_OPTIONED]: { bgColor: '#FFF3E8', textColor: '#F99536' },
  [LOT_STATUS_PRE_BOOKED]: { bgColor: '#FFE8E7', textColor: '#FE607D' },
  [LOT_STATUS_BOOKED]: { bgColor: '#FFE8E7', textColor: '#FE607D' },

  // Activity statuses
  [ACTIVITY_STATUS_ACTIVE]: { bgColor: '#E8F7FF', textColor: '#4FBFE2' },
  [ACTIVITY_STATUS_CANCELED]: { bgColor: '#F3F3F3', textColor: '#AAA' },
  [ACTIVITY_STATUS_LOST]: { bgColor: '#F3F3F3', textColor: '#AAA' },
  [ACTIVITY_STATUS_WON]: { bgColor: '#E0F8EB', textColor: '#0FA669' },
};

export const PASTILLE_STATUS_ICON = {
  [LOT_STATUS_FREE]: 'check-circle-vi3p',
  [LOT_STATUS_OPTIONED]: 'option',
  [LOT_STATUS_PRE_BOOKED]: 'pre-book',
  [LOT_STATUS_BOOKED]: 'reservation-contract',
};

export const PASTILLE_TAX_COLOR = {
  [TAX_TYPE_PINEL]: '#C084F2',
  [TAX_TYPE_LMNP]: '#9A9AF5',
  [TAX_TYPE_NONE]: '#646464',
  [TAX_TYPE_DEFICIT]: '#F08218',
  [TAX_TYPE_DEMEMBREMENT]: '#FF9F81',
  [TAX_TYPE_MALRAUX]: '#4FBFE2',
};
