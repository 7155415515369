import React from 'react';
import classnames from 'classnames';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';

import { LABEL_SHOW_DETAIL } from 'settings';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './LotOpen.module.scss';

export default function LotOpen({
  className = undefined,
  isOpen = false,
  loading = false,
  onClick = () => {},
}: LotOpenType) {
  const handleClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    onClick(ev);
  };

  return (
    <Tooltip
      arrow
      classes={{
        arrow: styles.arrowTooltip,
        tooltip: styles.tooltip,
      }}
      title={LABEL_SHOW_DETAIL}
      placement="top"
    >
      <Button
        data-test-id="results-list--lots-row--action-button"
        className={classnames(className, styles.root, { [styles.rotate]: isOpen })}
        classes={{ root: styles.MuiRoot }}
        disableRipple
        fullWidth
        onClick={handleClick}
      >
        {!loading ? (
          <Icon icon="chevron-down" />
        ) : (
          <div>
            <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={10} />
          </div>
        )}
      </Button>
    </Tooltip>
  );
}

export type LotOpenType = {
  className?: string;
  isOpen?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler;
};
