import React from 'react';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './CardIcon.module.scss';

export const ICON_TYPE_DEFAULT = 'icon';
export const ICON_TYPE_SPRITE = 'sprite';

export default function CardIcon(props: CardIconType) {
  const { icon, iconType = ICON_TYPE_DEFAULT, text = undefined, title } = props;

  return (
    <div className={styles.root}>
      <div className={styles.iconWrapper}>
        {iconType === ICON_TYPE_DEFAULT && <Icon className={styles.icon} icon={icon as Icons} />}
        {iconType === ICON_TYPE_SPRITE && <SvgIcon className={styles.sprite} iconId={icon} />}
      </div>
      <div className={styles.infos}>
        <span className={styles.label}>{title}</span>
        {text && <p>{text}</p>}
      </div>
    </div>
  );
}

type CardIconType = {
  icon: string;
  iconType?: typeof ICON_TYPE_DEFAULT | typeof ICON_TYPE_SPRITE;
  text?: string;
  title: string;
};
