import React from 'react';
import classnames from 'classnames';

import {
  LABEL_ARE_YOU_INDIVIDUAL,
  LABEL_ARE_YOU_PROFESSIONAL,
  LABEL_BECOME_PARTNER,
  LABEL_INVITATION_TEXT,
  LABEL_NOT_PARNTER_TEXT_ONE,
  LABEL_NOT_PARNTER_TEXT_TWO,
  LABEL_URL_VINCI,
} from 'settings/labels';

import styles from './BannerBottom.module.scss';

const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

export default function BannerBottom() {
  return (
    <section className={styles.root}>
      <div className={styles.left}>
        <div className={styles.title}>{LABEL_ARE_YOU_PROFESSIONAL}</div>
        <p className={styles.text}>
          {LABEL_NOT_PARNTER_TEXT_ONE}
          <br />
          {LABEL_NOT_PARNTER_TEXT_TWO}
        </p>
        <a href={SIGNUP_URL} className={classnames('btn-primary', styles.btn)}>
          {LABEL_BECOME_PARTNER}
        </a>
      </div>
      <div className={styles.right}>
        <div className={styles.title}>{LABEL_ARE_YOU_INDIVIDUAL}</div>
        <p className={styles.text}>{LABEL_INVITATION_TEXT}</p>
        <a
          className={styles.link}
          href="https://www.vinci-immobilier.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {LABEL_URL_VINCI}
        </a>
      </div>
    </section>
  );
}
