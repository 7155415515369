import React, { useEffect } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import YouTube from 'commonUi/YouTube/YouTube';
import SliderArrow from './SliderArrow';

interface UnderFirstSliderProps {
  content?: {
    text: ReactNode;
    title: ReactNode;
    image?: string;
    videoId: string | undefined;
    opts: ComponentProps<typeof YouTube>['opts'];
  }[];
}

export default function UnderFirstSlider({ content = [] }: UnderFirstSliderProps) {
  const location = useLocation();
  const params = qs.parse(location.search);

  useEffect(() => {
    if (params?.video === 'open') {
      const element = document.getElementById('video');
      const header = document.getElementById('guest-header');
      if (element) {
        const elementPos = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPos - (header?.clientHeight || 0),
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [params]);

  if (content.length === 0) {
    return null;
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    nextArrow: <SliderArrow label="Suivant" />,
    prevArrow: <SliderArrow label="Précédent" />,
  };

  return (
    <section className="section-conteneur section-under-first" id="video">
      <div className="section-conteneur-wrap">
        <Slider {...sliderSettings}>
          {content.map(({ opts, image, videoId, text, title }) => (
            <div key={videoId || image} className="block-content">
              <div className="block-content-text">
                {title}
                {text}
              </div>

              {image && (
                <div className="image-wrapper">
                  <img src={image} alt="" />
                </div>
              )}
              {videoId && <YouTube videoId={videoId} opts={opts} />}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}
