export default {
  Afghane: '389530000',
  'Sud-Africaine': '389530001',
  Albanaise: '389530002',
  Algérienne: '389530003',
  Allemande: '389530004',
  Andorrane: '389530005',
  Angolaise: '389530006',
  Antiguayenne: '389530007',
  Saoudienne: '389530008',
  Argentine: '389530009',
  Arménienne: '389530010',
  Australienne: '389530011',
  Autrichienne: '389530012',
  Azerbaïdjanaise: '389530013',
  Bahaméenne: '389530014',
  Bahreïnienne: '389530015',
  Bangladaise: '389530016',
  Barbadienne: '389530017',
  Belge: '389530018',
  Bélizienne: '389530019',
  Béninoise: '389530020',
  Bhoutanaise: '389530021',
  Biélorusse: '389530022',
  Birmane: '389530023',
  Bolivienne: '389530024',
  Bosnienne: '389530025',
  Botswanaise: '389530026',
  Brésilienne: '389530027',
  Brunéienne: '389530028',
  Bulgare: '389530029',
  Burkinabée: '389530030',
  Burundaise: '389530031',
  Cambodgienne: '389530032',
  Camerounaise: '389530033',
  Canadienne: '389530034',
  'Cap-Verdienne': '389530035',
  Centrafricaine: '389530036',
  Chilienne: '389530037',
  Chinoise: '389530038',
  Chypriote: '389530039',
  Colombienne: '389530040',
  Comorienne: '389530041',
  Congolaise: '389530042',
  'Nord-Coréenne': '389530043',
  'Sud-Coréenne': '389530044',
  Costaricienne: '389530045',
  Ivoirienne: '389530046',
  Croate: '389530047',
  Cubaine: '389530048',
  Danoise: '389530049',
  Djiboutienne: '389530050',
  Dominicaine: '389530051',
  Dominiquaise: '389530052',
  Égyptienne: '389530053',
  Émirienne: '389530054',
  Équatorienne: '389530055',
  Érythréenne: '389530056',
  Espagnole: '389530057',
  Estonienne: '389530058',
  Américaine: '389530059',
  Éthiopienne: '389530060',
  Fidjienne: '389530061',
  Finlandaise: '389530062',
  Française: '389530063',
  Gabonaise: '389530064',
  Gambienne: '389530065',
  Géorgienne: '389530066',
  Ghanéenne: '389530067',
  Grecque: '389530068',
  Grenadienne: '389530069',
  Guatémaltèque: '389530070',
  Guinéenne: '389530071',
  Bissaoguinéenne: '389530072',
  Équatoguinéenne: '389530073',
  Guyanienne: '389530074',
  Haïtienne: '389530075',
  Hondurienne: '389530076',
  Hongroise: '389530077',
  Indienne: '389530078',
  Indonésienne: '389530079',
  Irakienne: '389530080',
  Iranienne: '389530081',
  Irlandaise: '389530082',
  Islandaise: '389530083',
  Israélienne: '389530084',
  Italienne: '389530085',
  Jamaïcaine: '389530086',
  Japonaise: '389530087',
  Jordanienne: '389530088',
  Kazakhstanaise: '389530089',
  Kényane: '389530090',
  Kirghize: '389530091',
  Kiribatienne: '389530092',
  Kosovare: '389530093',
  Koweïtienne: '389530094',
  Laotienne: '389530095',
  Lesothienne: '389530096',
  Lettonne: '389530097',
  Libanaise: '389530098',
  Libérienne: '389530099',
  Libyenne: '389530100',
  Liechtensteinoise: '389530101',
  Lituanienne: '389530102',
  Luxembourgeoise: '389530103',
  Macédonienne: '389530104',
  Malgache: '389530105',
  Malaisienne: '389530106',
  Malawite: '389530107',
  Maldivienne: '389530108',
  Malienne: '389530109',
  Maltaise: '389530110',
  Marocaine: '389530111',
  Marshallaise: '389530112',
  Mauricienne: '389530113',
  Mauritanienne: '389530114',
  Mexicaine: '389530115',
  Micronésienne: '389530116',
  Moldave: '389530117',
  Monégasque: '389530118',
  Mongole: '389530119',
  Monténégrine: '389530120',
  Mozambicaine: '389530121',
  Namibienne: '389530122',
  Nauruane: '389530123',
  Népalaise: '389530124',
  Nicaraguayenne: '389530125',
  Nigérienne: '389530126',
  Nigériane: '389530127',
  Norvégienne: '389530128',
  Néozélandaise: '389530129',
  Omanaise: '389530130',
  Ougandaise: '389530131',
  Ouzbèke: '389530132',
  Pakistanaise: '389530133',
  Palaoise: '389530134',
  Panaméenne: '389530135',
  Papouasienne: '389530136',
  Paraguayenne: '389530137',
  Néerlandaise: '389530138',
  Péruvienne: '389530139',
  Philippine: '389530140',
  Polonaise: '389530141',
  Portugaise: '389530142',
  Qatarienne: '389530143',
  Roumaine: '389530144',
  Britannique: '389530145',
  Russe: '389530146',
  Rwandaise: '389530147',
  Christophienne: '389530148',
  Lucienne: '389530149',
  'Saint-Marinaise': '389530150',
  Vincentaise: '389530151',
  Salomonaise: '389530152',
  Salvadorienne: '389530153',
  Samoenne: '389530154',
  Santoméenne: '389530155',
  Sénégalaise: '389530156',
  Serbe: '389530157',
  Seychelloise: '389530158',
  Sierraléonaise: '389530159',
  Singapourienne: '389530160',
  Slovaque: '389530161',
  Slovène: '389530162',
  Somalienne: '389530163',
  Soudanaise: '389530164',
  Srilankaise: '389530165',
  Suédoise: '389530166',
  Suisse: '389530167',
  Surinamienne: '389530168',
  Swazie: '389530169',
  Syrienne: '389530170',
  Tadjike: '389530171',
  Tanzanienne: '389530172',
  Tchadienne: '389530173',
  Tchèque: '389530174',
  Thaïlandaise: '389530175',
  Timoraise: '389530176',
  Togolaise: '389530177',
  Tongienne: '389530178',
  Trinidadienne: '389530179',
  Tunisienne: '389530180',
  Turkmène: '389530181',
  Turque: '389530182',
  Tuvalaise: '389530183',
  Ukrainienne: '389530184',
  Uruguayenne: '389530185',
  Vanouataise: '389530186',
  Vénézuélienne: '389530187',
  Vietnamienne: '389530188',
  Yéménite: '389530189',
  Zambienne: '389530190',
  Zimbabwéenne: '389530191',
};
