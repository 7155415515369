import React, { useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { ReportLight } from 'api/viOffresAPI/apiTypes/Reports';

import ReportItem from 'commonUi/Reports/ReportItem';
import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import styles from './ReportsListContent.module.scss';

interface ReportsContentProps {
  from?: string;
  program: ProgramTypeV2 | undefined;
  showNbReports?: number;
  reports: ReportLight[];
}

export default function ReportsListContent({
  from,
  program,
  showNbReports = 4,
  reports,
}: ReportsContentProps) {
  const [isShowAll, setIsShowAll] = useState(false);

  if (!program || !reports) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Suivi de chantier</h1>

      <div className={styles.program}>
        <ReactRouterLink
          className={styles.name}
          to={`/programme/page/${program.referenceProgramme}`}
          target="_blank"
        >
          {program.nomCommercial}
        </ReactRouterLink>
        <address className={styles.address}>
          {program.localisation.adresse} {program.localisation.codePostal}{' '}
          {program.localisation.ville}
        </address>
      </div>

      <div className={styles.reports}>
        {reports.slice(0, isShowAll ? undefined : showNbReports).map((report, index) => (
          <ReportItem
            key={report.id}
            className={styles.report}
            from={from}
            programRef={program.referenceProgramme}
            report={report}
            reverse={index % 2 === 1}
          />
        ))}

        {!isShowAll && reports.length > showNbReports && (
          <button type="button" className={styles.seeMore} onClick={() => setIsShowAll(true)}>
            Voir plus d&apos;articles
          </button>
        )}
      </div>
    </div>
  );
}
