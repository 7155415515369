import React, { useEffect } from 'react';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { LABEL_FISCALITY } from 'settings';

import styles from '../PageAction.module.scss';

export function FieldPtz({
  defaultValue = false,
  name,
  noLabel = false,
  show = true,
}: FieldPtzType) {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, defaultValue);
  }, [name, show]);

  if (!show) {
    return null;
  }

  return (
    <>
      {!noLabel && <span className={styles.subtitle}>{LABEL_FISCALITY}</span>}
      <div className={styles.ptz}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={props => (
            <Checkbox
              checked={props?.field.value}
              handleChange={ev => props?.field.onChange(!props?.field.value)}
              color="primary"
              label="PTZ"
            />
          )}
        />
      </div>
    </>
  );
}

export type FieldPtzType = {
  defaultValue?: boolean;
  name: string;
  noLabel?: boolean;
  show?: boolean;
};
