import React, { Fragment, useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import type { SliderRef, SliderSettings } from 'types/slider';

import SlickSlider from 'commonUi/SlickSlider/SlickSlider';

import SliderArrow from './Arrow';

import styles from './GallerySlider.module.scss';

interface GallerySliderProps<T> {
  activeSlide?: number;
  fakeInfinite?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  makeMiniature: (media: T) => { content: ReactNode; key: string | undefined };
  makeSlide: (media: T) => { content: ReactNode; key: string | undefined } | null;
  slider: T[];
  updateSlide?: (slide: any) => void;
}

export default function GallerySlider<T>({
  activeSlide: propsActiveSlide = undefined,
  fakeInfinite = false,
  infinite = false,
  initialSlide = 0,
  makeSlide,
  makeMiniature,
  slider,
  updateSlide = () => {},
}: GallerySliderProps<T>) {
  const [activeSlide, activeSlideChange] = useState(propsActiveSlide ?? initialSlide);
  const sliderRef = useRef<SliderRef>();

  useEffect(() => {
    if (typeof propsActiveSlide === 'number') {
      activeSlideChange(propsActiveSlide);
      if (sliderRef.current) {
        sliderRef.current.slickGoTo((propsActiveSlide + 1) % slider.length);
      }
    }
  }, [propsActiveSlide]);

  // Custom Next/Prev clicks handlers for faking infinite scroll.
  const nextOnClick = () => {
    if (fakeInfinite) {
      // Go to +1 or loop from max-1 to 0
      sliderRef.current?.slickGoTo((activeSlide + 1) % slider.length);
    } else {
      sliderRef.current?.slickNext();
    }
  };

  const prevOnClick = () => {
    if (fakeInfinite) {
      // Go to -1 or loop from 0 to max-1
      sliderRef.current?.slickGoTo((activeSlide + slider.length - 1) % slider.length);
    } else {
      sliderRef.current?.slickPrev();
    }
  };

  const sliderSettings: Partial<SliderSettings> = {
    beforeChange: (_, next) => {
      activeSlideChange(next);
      updateSlide(next);
    },
    centerMode: true,
    onInit: () => updateSlide(initialSlide),
    infinite: infinite && !fakeInfinite,
    nextArrow: <SliderArrow realOnClick={nextOnClick} />,
    prevArrow: <SliderArrow realOnClick={prevOnClick} />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
    initialSlide: propsActiveSlide ?? initialSlide,
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: true,
  };

  if (!slider || slider.length === 0) {
    return null;
  }

  return (
    <>
      <SlickSlider className={styles.root} sliderRef={sliderRef} {...sliderSettings}>
        {slider.map(slide => {
          const madeSlide = makeSlide(slide);
          return madeSlide ? <Fragment key={madeSlide.key}>{madeSlide.content}</Fragment> : null;
        })}
      </SlickSlider>

      <div className={styles.mini}>
        {slider.map((item, index) => {
          const { content, key } = makeMiniature(item);
          return (
            <button
              key={key}
              type="button"
              className={styles.miniBtn}
              onClick={() => sliderRef.current?.slickGoTo(index)}
              onKeyDown={() => sliderRef.current?.slickGoTo(index)}
            >
              {content}
            </button>
          );
        })}
      </div>

      <div className={styles.active}>
        {activeSlide + 1}/{slider.length}
      </div>
    </>
  );
}
