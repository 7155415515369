import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { floorFormatter, formatPrice, numberFormatter } from 'services';
import { showQuarter } from 'services/date';
import { taxMapping } from 'services/taxes';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_MAIL_SEND,
  LABEL_DELIVERY_DATE,
  LABEL_EMAIL_CONTENT,
  LABEL_EMAIL_FORM_ERROR_TITLE,
  LABEL_EMAIL_RECEIVER,
  LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR,
  LABEL_EMAIL_SUBJECT,
  LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR,
  LABEL_FISCALITY,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_LOT,
  LABEL_NATURE,
  LABEL_NORMAL_VAT,
  LABEL_PARKING,
  LABEL_POTENTIAL,
  LABEL_PRICE_COMMERCIAL_TTC,
  LABEL_PRICE_IMMO,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_MOB_HT,
  LABEL_PROFITABILITY,
  LABEL_PROGRAM_CITY,
  LABEL_REDUCED_VAT,
  LABEL_RENT,
  LABEL_RENT_LMNP,
  LABEL_RENT_MARKET,
  LABEL_RENT_PINEL,
  LABEL_SEND_COMPARISON,
  LABEL_STAIR,
  LABEL_STATUS,
  LABEL_SURF,
  LABEL_SURF_ANNEX,
  LABEL_TYPO,
} from 'settings/labels';
import { MAIL_MESSAGE_COMPARE } from 'settings/mailSharing';
import { TMS_TIMEOUT } from 'settings/tms';

import { regexEmail } from 'services/constraints';

import userContext from 'modules/App/Contexts/userContext';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import TextField from 'commonUi/TextField/TextField';
import { ComparisonItem } from '../../Comparison/types/ComparisonType';

import styles from '../ModalAction.module.scss';

export function FormSendComparison({
  comparisonDatas,
  handleClose,
  loading,
  setLoading,
}: {
  comparisonDatas: ComparisonItem[];
  handleClose: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { userCrm } = useContext(userContext);
  const [apiError, setApiError] = useState(false);

  const uniqueFiscalities = new Set(comparisonDatas.map(({ lot }) => lot.fiscalites[0]));
  const isMultifiscalities = uniqueFiscalities.size > 1;
  const firstFiscality = uniqueFiscalities.values().next().value;
  const isPinel = uniqueFiscalities.size === 1 && firstFiscality === 'Pinel';
  const isLMNP = uniqueFiscalities.size === 1 && firstFiscality === 'LMNP';
  const isDemembrement = uniqueFiscalities.size === 1 && firstFiscality === 'Demembrement';
  const isResidenceHorsPinel =
    uniqueFiscalities.size === 1 && firstFiscality === 'ResidenceHorsPinel';

  const attachments = [
    {
      title: LABEL_FISCALITY,
      values: comparisonDatas.map(c => taxMapping?.get(c.lot.fiscalites[0] || '-')),
    },
    {
      title: LABEL_PROGRAM_CITY,
      values: comparisonDatas.map(
        c =>
          `${c.program.nomCommercial} / ${c.program.localisation.codePostal} ${c.program.localisation.ville}`
      ),
    },
    isLMNP && { title: LABEL_NATURE, values: comparisonDatas.map(c => c.program.type || '-') },
    { title: LABEL_LOT, values: comparisonDatas.map(c => c.lot.reference || '-') },
    { title: LABEL_TYPO, values: comparisonDatas.map(c => c.lot.typologie || '-') },
    {
      title: LABEL_STAIR,
      values: comparisonDatas.map(c => (c.lot.etage ? floorFormatter(c.lot.etage) : '-')),
    },
    {
      title: LABEL_SURF,
      values: comparisonDatas.map(c =>
        numberFormatter(c.lot.surfaces.surfaceHabitable)
          ? `${numberFormatter(c.lot.surfaces.surfaceHabitable)}m²`
          : '-'
      ),
    },
    {
      title: LABEL_SURF_ANNEX,
      values: comparisonDatas.map(c =>
        numberFormatter(c.lot.surfaces.surfaceAnnexes)
          ? `${numberFormatter(c.lot.surfaces.surfaceAnnexes)}m²`
          : '-'
      ),
    },
    { title: LABEL_PARKING, values: comparisonDatas.map(c => c.lot.parking || '-') },
    (isMultifiscalities || isPinel || isResidenceHorsPinel) && {
      title: `${LABEL_PRICE_IMMO} (${LABEL_REDUCED_VAT})`,
      values: comparisonDatas.map(c => formatPrice(c.lot.prix.TVAalternative?.prixTTC)),
    },
    (isMultifiscalities || isPinel || isResidenceHorsPinel) && {
      title: `${LABEL_PRICE_IMMO} (${LABEL_NORMAL_VAT})`,
      values: comparisonDatas.map(c => formatPrice(c.lot.prix.TVANormale.prixTTC)),
    },
    isMultifiscalities && {
      title: LABEL_RENT,
      values: comparisonDatas.map(c => formatPrice(c.lot.investissementLocatif.loyerMarcheHT)),
    },
    isMultifiscalities && {
      title: LABEL_PROFITABILITY,
      values: comparisonDatas.map(c =>
        numberFormatter(c.lot.investissementLocatif.rendementLocatif)
          ? `${numberFormatter(c.lot.investissementLocatif.rendementLocatif)}%`
          : '-'
      ),
    },
    isPinel && {
      title: LABEL_RENT_PINEL,
      values: comparisonDatas.map(c => formatPrice(c.lot.investissementLocatif.plafondLoyerPinel)),
    },
    isPinel && {
      title: LABEL_PROFITABILITY,
      values: comparisonDatas.map(c =>
        numberFormatter(c.lot.investissementLocatif.rentabilitePinel)
          ? `${numberFormatter(c.lot.investissementLocatif.rentabilitePinel)}%`
          : '-'
      ),
    },
    isResidenceHorsPinel && {
      title: `${LABEL_RENT_MARKET} (${LABEL_POTENTIAL})`,
      values: comparisonDatas.map(c =>
        formatPrice(c.lot.investissementLocatif.loyerMarcheAnnuelHT)
      ),
    },
    isLMNP && {
      title: LABEL_PRICE_IMMO_HT,
      values: comparisonDatas.map(c => formatPrice(c.lot.prix.prixHTHorsMobilier)),
    },
    isLMNP && {
      title: LABEL_PRICE_MOB_HT,
      values: comparisonDatas.map(c => formatPrice(c.lot.prix.prixMobilierHT)),
    },
    isLMNP && {
      title: LABEL_RENT_LMNP,
      values: comparisonDatas.map(c =>
        formatPrice(c.lot.investissementLocatif.loyerMarcheAnnuelHT)
      ),
    },
    isLMNP && {
      title: LABEL_PROFITABILITY,
      values: comparisonDatas.map(c =>
        numberFormatter(c.lot.investissementLocatif.rentabiliteLMNP)
          ? `${numberFormatter(c.lot.investissementLocatif.rentabiliteLMNP)}%`
          : '-'
      ),
    },
    isDemembrement && {
      title: LABEL_PRICE_COMMERCIAL_TTC,
      values: comparisonDatas.map(c => formatPrice(c.lot.prix.TVANormale.prixTTC)),
    },
    {
      title: LABEL_DELIVERY_DATE,
      values: comparisonDatas.map(c => showQuarter(c.program.dates.livraison)),
    },
    { title: LABEL_STATUS, values: comparisonDatas.map(c => c.lot.statut || '-') },
  ];

  useEffect(() => {
    setValue('field-content', MAIL_MESSAGE_COMPARE(userCrm));
  }, []);

  async function sendMail(values) {
    setLoading(true);
    try {
      await axiosVI3PInstance.post(`mailer`, {
        to: values?.['field-receiver'],
        subject: values?.['field-object'],
        message: values?.['field-content'],
        attachments: attachments.filter(a => a),
        type: 'compare',
        prescripteurFullName: `${userCrm?.given_name} ${userCrm?.family_name}`,
        prescripteurEmail: userCrm?.email,
      });
      setTimeout(() => {
        setLoading(false);
        enqueueSnackbar(LABEL_CONFIRM_MAIL_SEND);
        handleClose();
      }, TMS_TIMEOUT);
    } catch (newError) {
      setLoading(false);
      if (newError?.response?.status) {
        setApiError(true);
      }
    }
  }

  const { control, handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(sendComparisonSchema),
    mode: 'onChange',
  });
  const { isDirty, isValid, errors } = formState;

  return (
    <form onSubmit={handleSubmit(sendMail)}>
      <div className={styles.form}>
        <div className={styles.input}>
          <Controller
            name="field-receiver"
            control={control}
            render={props => (
              <TextField
                error={errors?.['field-receiver']}
                helperText={errors?.['field-receiver'] && LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR}
                label={LABEL_EMAIL_RECEIVER}
                name="field-receiver"
                onChange={ev => props?.field.onChange(ev.target.value)}
                required
                value={props?.field.value}
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-object"
            control={control}
            render={props => (
              <TextField
                error={errors?.['field-object']}
                helperText={errors?.['field-object'] && LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR}
                id="field-object"
                label={LABEL_EMAIL_SUBJECT}
                name="field-object"
                onChange={ev => props?.field.onChange(ev.target.value)}
                required
                value={props?.field.value}
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-content"
            control={control}
            render={props => (
              <TextField
                id="field-content"
                label={LABEL_EMAIL_CONTENT}
                multiline
                name="field-content"
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value ? props?.field.value : MAIL_MESSAGE_COMPARE}
              />
            )}
          />
        </div>
        {!apiError && (
          <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
        )}
      </div>
      <div className={classNames(styles.modalFooter, { [styles.errorFooter]: apiError })}>
        {apiError && (
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => setApiError(false)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{LABEL_EMAIL_FORM_ERROR_TITLE}</h4>
              <p>{LABEL_FORM_ERROR_CONTENT}</p>
            </div>
          </div>
        )}
        {!apiError && (
          <>
            <div className={styles.cancelButton}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            </div>
            <div className={styles.createButton}>
              <Button
                disabled={!isDirty || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                {LABEL_SEND_COMPARISON}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const sendComparisonSchema = yup.object().shape({
  'field-receiver': yup.string().required().matches(RegExp(regexEmail)),
  'field-object': yup.string().trim().required(),
});
