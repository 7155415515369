import { useState } from 'react';

export function usePanels() {
  const [open, setOpen] = useState<string>();

  return {
    open,
    openPanel(idPanel: string) {
      setOpen(idPanel);
    },
    closePanel() {
      setOpen(undefined);
    },
  };
}
