import React, { useState } from 'react';
import classnames from 'classnames';

import type { ReportLight } from 'api/viOffresAPI/apiTypes/Reports';
import type { NonNullableArray } from 'types/tools';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import FieldImage from 'commonUi/FieldImage/FieldImage';
import GallerySlider from 'commonUi/GallerySlider/GallerySlider';
import ModalLayout from 'commonUi/ModalLayout/ModalLayout';

import styles from './ReportImages.module.scss';
import SliderInline from '../SliderInline/SliderInline';

interface ReportImagesProps {
  className?: string;
  images: NonNullableArray<ReportLight['image']>;
}

export default function ReportImages({ className, images }: ReportImagesProps) {
  const [isOpen, setIsOpen] = useState(false);

  if (!images.length) {
    return null;
  }

  if (images.length === 1) {
    return (
      <div className={classnames(className, styles.root)}>
        <FieldImage className={styles.image} src={images[0].url} alt={images[0].properties.alt} />
      </div>
    );
  }

  return (
    <div className={classnames(className, styles.root)}>
      <button
        type="button"
        className={styles.open}
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        <Icon icon="show" />
      </button>

      <SliderInline
        lazyLoadImages
        renderArrows
        classes={{
          root: styles.sliderContainer,
          slider: styles.slider,
          image: styles.image,
          dots: styles.dots,
          dot: styles.dot,
          dotCurrent: styles.current,
          dotActive: styles.active,
        }}
        images={images}
      />

      <ModalLayout onClose={() => setIsOpen(false)} open={isOpen}>
        <GallerySlider
          makeMiniature={image => ({
            key: image.properties.target_uuid,
            content: <FieldImage media={image} />,
          })}
          makeSlide={image => ({
            key: image.properties.target_uuid,
            content: <FieldImage className={styles.galleryImg} media={image} />,
          })}
          slider={images}
        />
      </ModalLayout>
    </div>
  );
}
