import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Drawer as MuiDrawer, withStyles } from '@material-ui/core';

// import Button, { BUTTON_COLORS } from 'components/Common/Atoms/Button/Button';

import Button from '@material-ui/core/Button';
import styles from './Drawer.module.scss';
import Icon from '../Icon';
import { kelQuartierModuleContext } from '../../../../KelQuartier/contexts/kelQuartierModuleContext';

const CustomDrawer = withStyles({
  paper: {
    display: 'block',
    width: '100%',
  },
})(MuiDrawer);

export default function Drawer(props) {
  const { children, onClose, title, ...drawerProps } = props;
  const { theme } = useContext(kelQuartierModuleContext);

  const btnClassName = classNames(styles.close, styles.root, styles.border, styles.round, {
    [styles[theme]]: styles[theme],
  });

  return (
    <CustomDrawer onClose={onClose} {...drawerProps}>
      <div className="container">
        <div className={classNames(styles.header, styles[theme])}>
          <h2 className={styles.title}>{title}</h2>
          <Button className={btnClassName} color="white" round border onClick={onClose}>
            <Icon className={classNames(styles.icon, styles[theme])} icon="cross" />
          </Button>
        </div>
        {children}
      </div>
    </CustomDrawer>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

Drawer.defaultProps = {
  children: null,
  onClose: undefined,
  title: '',
};
