/* eslint-disable sort-keys */
export const MAP_MARKER_ZOOM = 15;

export const KELQUARTIER_THEMES = [
  {
    pois: [
      {
        ids: [27],
        title: 'Bureaux de poste',
      },
      {
        ids: [19],
        title: 'Marchés de quartier',
      },
      {
        ids: [29, 30],
        title: 'Supermarchés',
      },
    ],
    title: 'Commerce de proximité',
  },
  {
    pois: [
      {
        ids: [12],
        title: 'Bibliothèques',
      },
      {
        ids: [13],
        title: 'Monuments historiques',
      },
      {
        ids: [31],
        title: 'Théâtres',
      },
    ],
    title: 'Culture',
  },
  {
    pois: [
      {
        ids: [63],
        title: 'Crèches',
      },
      {
        ids: [7],
        title: 'Ecoles maternelles',
      },
      {
        ids: [8],
        title: 'Ecoles primaires',
      },
      {
        ids: [6],
        title: 'Collèges',
      },
      {
        ids: [10],
        title: 'Lycées',
      },
      {
        ids: [9],
        title: 'Enseignement supérieur',
      },
    ],
    title: 'Education',
  },
  {
    pois: [
      {
        ids: [3],
        title: 'Cinémas',
      },
      {
        ids: [15],
        title: 'Espaces verts',
      },
      {
        ids: [14],
        title: 'Equipements sportifs',
      },
      {
        ids: [2],
        title: 'Bars',
      },
      {
        ids: [26],
        title: 'Restaurants',
      },
    ],
    title: 'Loisirs / restauration / sports',
  },
  {
    pois: [
      {
        ids: [17],
        title: 'Hôpitaux',
      },
      {
        ids: [25],
        title: 'Pharmacies',
      },
    ],
    title: 'Santé',
  },
  {
    pois: [
      {
        ids: [1],
        title: 'Bus',
      },
      {
        ids: [16, 20, 65],
        title: 'Gare, métro, RER',
      },
      {
        ids: [22],
        title: 'Tramways',
      },
      {
        ids: [33, 34],
        title: 'Location de vélo',
      },
    ],
    title: 'Transport',
  },
];

export const KELQUARTIER_PICTOS = {
  1: 'bus',

  16: 'metro',
  20: 'metro',
  65: 'metro',

  22: 'tramway',

  33: 'location de velo',
  34: 'location de velo',

  27: 'bureau de poste',

  19: 'mini market',

  29: 'supermarche',
  30: 'supermarche',

  3: 'cinema',

  15: 'espace vert',

  14: 'equipement sportif',

  2: 'bar',

  26: 'restaurant',

  7: 'ecole maternelle',

  8: 'ecole primaire / college / lycee',

  6: 'ecole',

  10: 'ecole',

  9: 'enseignement superieur',

  12: 'bibliotheque',

  13: 'monument historique',

  31: 'theatre',

  17: 'hopital',

  25: 'pharmacie',

  63: 'creche',
};
