import React, { useMemo } from 'react';
import classnames from 'classnames';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './ProgramCertifications.module.scss';

interface ProgramCertificationsProps {
  certifications: ProgramTypeV2['certifications'];
  className?: string;
  classes?: Partial<Record<'item' | 'root', string>>;
}

export default function ProgramCertifications({
  className,
  classes = {},
  certifications,
}: ProgramCertificationsProps) {
  const validCertifications = useMemo(
    () =>
      certifications.filter(
        certification =>
          !Array.isArray(certification.pictogramme) ||
          !Array.isArray(certification.mentionsLegales),
        []
      ),
    [certifications]
  );

  if (!validCertifications.length) {
    return null;
  }

  return (
    <div className={classnames(className, classes.root, styles.root)}>
      {validCertifications.map(certification => {
        if (
          Array.isArray(certification.pictogramme) &&
          Array.isArray(certification.mentionsLegales)
        ) {
          return null;
        }
        return (
          <div key={certification.name} className={classnames(classes.item, styles.item)}>
            {!Array.isArray(certification.pictogramme) && certification.pictogramme && (
              <img
                className={styles.image}
                src={certification.pictogramme}
                alt={certification.name}
              />
            )}
            {!Array.isArray(certification.mentionsLegales) && certification.mentionsLegales && (
              <FieldRte className={styles.text} html={certification.mentionsLegales} />
            )}
          </div>
        );
      })}
    </div>
  );
}
