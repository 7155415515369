import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { LABEL_HONO_BOOST } from 'settings/labels';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

import styles from './PdfPastilleStyles';

export default function PdfPastilleHonoBoost() {
  return (
    <View style={styles.pastilleHonoBoost}>
      <View style={styles.pastilleHonoBoostPicto}>
        <PdfSvgPicto picto="checkedCircleYellow" />
      </View>
      <View style={styles.pastilleHonoBoostLabel}>
        <Text>{LABEL_HONO_BOOST}</Text>
      </View>
    </View>
  );
}
