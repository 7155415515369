import React from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';
import { Tooltip as MuiTooltip } from '@material-ui/core';

import styles from './Tooltip.module.scss';

export default function Tooltip({
  children,
  className,
  ...props
}: Omit<ComponentProps<typeof MuiTooltip>, 'classes'>) {
  return (
    <MuiTooltip
      classes={{
        arrow: styles.arrowTooltip,
        popper: styles.popper,
        tooltip: classnames(styles.tooltip, className),
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
}
