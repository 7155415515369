import React from 'react';
import type { PropsWithChildren } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import { LABEL_NEW_RESIDENCE } from 'settings/labels';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import {
  programHasKitchen,
  programIsControlledPrice,
  programIsFNO,
  programIsFurnishEligible,
  programIsHonoBoost,
  programIsNew,
  programIsVi4You,
} from 'services/programs';
import { taxMapping } from 'services/taxes';

import imgControlledPrices from 'images/Logo-prix-maitrises.png';
import imgKitchen from 'images/Logo-cuisine-equipee.png';
import imgVi4you from 'images/Logo_4you_Plan_de_travail_1.png';

import PdfPastilleFiscality from '../Common/PdfPastille/PdfPastilleFiscality';
import PdfPastilleNotaryFees from '../Common/PdfPastille/PdfPastilleNotaryFees';
import PdfPastilleHonoBoost from '../Common/PdfPastille/PdfPastilleHonoBoost';

import styles from './PdfBannerStyles';
import PdfPastilleFurnished from '../Common/PdfPastille/PdfPastilleFurnished';

interface PdfBanneProps {
  program: ProgramTypeV2;
}

export default function PdfBanner({ children, program }: PropsWithChildren<PdfBanneProps>) {
  const fiscality = program.informationsFiscalesEtFinancieres.fiscalites.length
    ? taxMapping.get(program.informationsFiscalesEtFinancieres.fiscalites[0])
    : undefined;
  return (
    <View style={styles.banner}>
      {/* GET parameter required, see	https://bugs.chromium.org/p/chromium/issues/detail?id=855713 for info about Chrome caching issue */}
      {program.perspectives.panorama && (
        <Image src={`${program.perspectives.panorama}?pdf`} style={styles.bannerImage} />
      )}
      <View style={styles.bannerGradient} />
      <View style={styles.bannerBottom}>
        <View style={styles.bannerBottomContent}>
          <View style={styles.pictosList}>
            {programIsVi4You(program) && (
              <View style={styles.pictosListItem}>
                <Image src={imgVi4you} style={styles.imgVi4you} />
              </View>
            )}
            {programHasKitchen(program) && (
              <View style={styles.pictosListItem}>
                <Image src={imgKitchen} style={styles.imgKitchen} />
              </View>
            )}
            {programIsFNO(program) && (
              <View style={styles.pictosListItem}>
                <PdfPastilleNotaryFees />
              </View>
            )}
          </View>
          <View style={styles.infosWrapper}>
            {programIsNew(program) && <Text style={styles.newHousing}>{LABEL_NEW_RESIDENCE}</Text>}
            <Text style={styles.name}>{program.nomCommercial}</Text>
            <Text style={styles.address}>
              {program.localisation.adresse}, {program.localisation.ville} (
              {program.localisation.codePostal})
            </Text>
            <View style={styles.infos}>
              {fiscality && (
                <View style={styles.pastille}>
                  <PdfPastilleFiscality tax={fiscality} />
                </View>
              )}
              {programIsFurnishEligible(program) && (
                <View style={styles.pictosListItem}>
                  <PdfPastilleFurnished />
                </View>
              )}
              {programIsControlledPrice(program) && (
                <View style={styles.pictosListItem}>
                  <Image src={imgControlledPrices} style={styles.imgControlledPrices} />
                </View>
              )}
              {programIsHonoBoost(program) && (
                <View style={styles.pictosListItem}>
                  <PdfPastilleHonoBoost />
                </View>
              )}
            </View>
          </View>
          {children && <View style={styles.totem}>{children}</View>}
        </View>
      </View>
    </View>
  );
}
