import { isAuthenticated } from './authentication';

export const REDIRECT_KEY = 'vinci_redirect';

export const redirectUrl = (() => {
  const url = sessionStorage.getItem(REDIRECT_KEY);
  sessionStorage.removeItem(REDIRECT_KEY);
  return url;
})();

export const setRedirectUrl = programUrl => () => {
  if (!isAuthenticated()) {
    sessionStorage.setItem(REDIRECT_KEY, programUrl);
  }
};
