import React from 'react';
import classnames from 'classnames';

import { TAX_ICON } from 'settings/taxes';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './Pastille.module.scss';

export interface PastilleFiscalityProps {
  className?: string;
  iconClassName?: string;
  tax: string | undefined;
}

export default function PastilleFiscality({
  className = undefined,
  iconClassName = undefined,
  tax,
}: PastilleFiscalityProps) {
  if (!tax) {
    return null;
  }
  return (
    <div className={className}>
      <SvgIcon className={classnames(styles.fiscalityIcon, iconClassName)} iconId={TAX_ICON[tax]} />
    </div>
  );
}
