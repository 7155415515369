import React from 'react';
import type { PropsWithChildren } from 'react';
import classnames from 'classnames';

import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import styles from './TabPanel.module.scss';

interface TabPanelType {
  className?: string;
  index: string | number;
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
  value: string | number;
}

export default function TabPanel(props: PropsWithChildren<TabPanelType>) {
  const { children, className = undefined, index, tms = undefined, value, ...other } = props;

  return (
    <div
      className={classnames(className, styles.root)}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
      {value === index && tms && <TagCommander {...tms} useEffectDeps={Object.keys(tms)} />}
    </div>
  );
}
