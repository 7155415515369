import React, { useState } from 'react';
import classnames from 'classnames';

import {
  LABEL_CITIES,
  LABEL_DELIVERY,
  LABEL_FISCALITY,
  LABEL_POSITION,
  LABEL_PREFERRED_LOCATION_BUTTON,
  LABEL_SORT_BY,
} from 'settings/labels';
import {
  SORT_PROGRAM_BY_DELIVERY,
  SORT_PROGRAM_BY_LOCALITY,
  SORT_PROGRAM_BY_POSITION,
  SORT_PROGRAM_BY_TAX,
} from 'settings/search';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import DropdownList from 'commonUi/DropdownList/DropdownList';
import ModalPosition from 'commonUi/ModalPosition/ModalPosition';
import SortItem from 'commonUi/SortItem/SortItem';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import type { ProgramSortBy, ProgramSortOrder } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramsDropdownSort.module.scss';

interface ProgramsDropdownSortProps {
  classes?: Partial<Record<'root' | 'button', string>>;
  className?: string;
  reorderPrograms: (type: string) => void;
  sortBy: ProgramSortBy;
  sortOrder: ProgramSortOrder;
}

export function ProgramsDropdownSort({
  classes = {},
  className,
  reorderPrograms,
  sortBy,
  sortOrder,
}: ProgramsDropdownSortProps) {
  const [positionOpenSignal, setPositionOpenSignal] = useState(0);

  return (
    <>
      <DropdownList
        id="sort-programs"
        buttonClassName={classes.button}
        className={classnames(className, classes.root)}
        label={LABEL_SORT_BY}
        popinClassName={styles.popin}
      >
        <SortItem
          className={styles.position}
          onClick={reorderPrograms}
          sortBy={sortBy}
          sortOrder={sortOrder}
          title={
            <>
              {LABEL_POSITION}
              <Tooltip arrow placement="top" title={LABEL_PREFERRED_LOCATION_BUTTON}>
                <button
                  type="button"
                  className={styles.positionButton}
                  onClick={ev => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setPositionOpenSignal(prevState => prevState + 1);
                  }}
                >
                  <Icon className={styles.positionIcon} icon="edit" />
                </button>
              </Tooltip>
            </>
          }
          type={SORT_PROGRAM_BY_POSITION}
          withSortIndicator={false}
        />
        <SortItem
          onClick={reorderPrograms}
          sortBy={sortBy}
          sortOrder={sortOrder}
          title={LABEL_CITIES}
          type={SORT_PROGRAM_BY_LOCALITY}
        />
        <SortItem
          onClick={reorderPrograms}
          sortBy={sortBy}
          sortOrder={sortOrder}
          title={LABEL_DELIVERY}
          type={SORT_PROGRAM_BY_DELIVERY}
        />
        <SortItem
          onClick={reorderPrograms}
          sortBy={sortBy}
          sortOrder={sortOrder}
          title={LABEL_FISCALITY}
          type={SORT_PROGRAM_BY_TAX}
        />
      </DropdownList>
      <ModalPosition
        openSignal={positionOpenSignal}
        onConfirmed={() => reorderPrograms(SORT_PROGRAM_BY_POSITION)}
      />
    </>
  );
}
