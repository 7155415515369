import type { RefObject } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { LABEL_AND_MORE, LABEL_DELIVERY_DATE, LABEL_RANGE_PRICE } from 'settings/labels';
import { SLIDER_RANGE } from 'settings/search';
import { TMS_TIMEOUT } from 'settings/tms';

import { budgetValueFormatter, budgetValueFormatterReverse } from 'services/formatter';
import { modifyQuery } from 'services/url';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import { useSearch } from 'modules/HomePage/hooks/useSearch';
import FilterOptions from 'commonUi/Filter/FilterOptions';
import RangeField from 'commonUi/RangeField/RangeField';
import Filter, { FILTER_TYPE_RANGE } from 'commonUi/Filter/Filter';

import Autocomplete from '../Autocomplete/Autocomplete';

import styles from './HeaderFilters.module.scss';

interface HeaderFiltersProps {
  className?: string;
  positionSuggestedSearchRef?: RefObject<HTMLDivElement>;
}

export function HeaderFilters({
  className,
  positionSuggestedSearchRef = undefined,
}: HeaderFiltersProps) {
  const history = useHistory();
  const [openBudget, setOpenBudget] = useState(false);
  const [openDeliveryDate, setOpenDeliveryDate] = useState(false);
  const [filterBudgetError, setFilterBudgetError] = useState(false);
  const [moreCriteriasClicked, setMoreCriteriasClicked] = useState(false);
  const { budgetMax, budgetMin, delivery } = useSearch();
  const { years: yearOptions } = useContext(TaxonomiesContext);

  const budgetValue =
    budgetMax || budgetMin
      ? {
          min: Number(budgetMin) || 0,
          max: Number(budgetMax) || Number(SLIDER_RANGE.values.budget.max),
        }
      : {};

  useEffect(() => {
    if (moreCriteriasClicked) {
      setTimeout(() => {
        setMoreCriteriasClicked(false);
      }, TMS_TIMEOUT);
    }
  }, [moreCriteriasClicked]);

  return (
    <div className={classnames(className, styles.root)}>
      <div className={classnames(styles.item, styles.location)}>
        <Autocomplete
          classes={{
            root: styles.autocomplete,
            textFieldRoot: styles.textFieldRoot,
            inputRoot: styles.input,
            inputAdornment: styles.icon,
          }}
          maxItems={1}
          positionSuggestedSearchRef={positionSuggestedSearchRef}
        />
      </div>

      <div className={classnames(styles.item, styles.budget)}>
        <Filter
          classes={{
            select: styles.rangeMenuHeader,
            label: styles.label,
            labelFocused: styles.focused,
          }}
          filterType={FILTER_TYPE_RANGE}
          filterInError={filterBudgetError}
          id="range-price"
          label={LABEL_RANGE_PRICE}
          menuListClassName={styles.rangeMenuList}
          isIcon={false}
          open={openBudget}
          popoverClassName={styles.rangePopover}
          setOpen={setOpenBudget}
          unit="K"
          value={budgetValue}
          valuesFormatter={budgetValueFormatter}
        >
          {props => (
            <div className={styles.filterPaper}>
              <RangeField
                endAdornmentText={
                  <>
                    K<sup>€</sup>
                  </>
                }
                id="field-price"
                maxRange={Number(SLIDER_RANGE.values.budget.max)}
                minRange={0}
                moreLabelMax={`500K € ${LABEL_AND_MORE}`}
                name="field-price"
                onChange={value =>
                  history.replace(modifyQuery({ budgetMax: value.max, budgetMin: value.min }))
                }
                setFilterInError={setFilterBudgetError}
                values={props?.value}
                valuesFormatter={budgetValueFormatter}
                valuesFormatterReverse={budgetValueFormatterReverse}
              />
            </div>
          )}
        </Filter>
      </div>
      <div className={classnames(styles.item, styles.delivery)}>
        <Filter
          classes={{
            select: styles.rangeMenuHeaderLastEl,
            label: styles.label,
            labelFocused: styles.focused,
          }}
          id="date-livraison"
          label={LABEL_DELIVERY_DATE}
          isIcon={false}
          open={openDeliveryDate}
          options={yearOptions}
          setOpen={setOpenDeliveryDate}
          value={delivery}
        >
          {props => (
            <FilterOptions
              {...props}
              onChange={values => history.replace(modifyQuery({ delivery: values }))}
            />
          )}
        </Filter>
      </div>
    </div>
  );
}
