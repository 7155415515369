import React from 'react';
import { View } from '@react-pdf/renderer';

import PdfSvgPicto from '../PdfSvgPicto/PdfSvgPicto';

import styles from './PdfPastilleStyles';

export default function PdfPastilleFurnished() {
  return (
    <View style={styles.pastilleFurnished}>
      <PdfSvgPicto picto="furnished" />
    </View>
  );
}
