import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  block: {
    margin: '17px 0 0 0',
  },
  title: {
    color: COLORS.GREYS.BLACK3,
    fontSize: '14px',
    margin: '0 0 15px 0',
  },
  image: {
    margin: '7px 0',
    padding: '0 15px',
    width: '100%',
  },
});
