import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ children, history }: ScrollToTopType) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
}

type ScrollToTopType = {
  children?: React.ReactNode;
  history: {
    listen: (listener: () => void) => () => void;
  };
};

export default withRouter(ScrollToTop);
