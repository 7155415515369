import React from 'react';
import classnames from 'classnames';
import { Link as LinkMaterial, LinkTypeMap, Typography } from '@material-ui/core';
import { Link as ReactRouterLink } from 'react-router-dom';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './Link.module.scss';

export default function Link({
  action = undefined,
  appbarLink = false,
  bold = false,
  href = '#',
  icon = undefined,
  isActive = false,
  label = undefined,
  svgIcon = undefined,
  target = '_blank',
}: LinkProps) {
  let style = classnames(styles.link);
  let underline: LinkTypeMap['props']['underline'] = 'none';
  if (icon) {
    style = classnames(styles.icon);
  }
  if (bold) {
    style = classnames(styles.bold);
    underline = 'hover';
  }

  return (
    <>
      <Typography
        className={classnames(styles.root, {
          [styles.appBar]: appbarLink,
          [styles.activeLink]: isActive,
        })}
      >
        <LinkMaterial
          component={ReactRouterLink}
          to={href}
          onClick={action}
          target={target}
          color="inherit"
          className={style}
          underline={underline}
        >
          {svgIcon && (
            <Typography className={classnames(styles.group)}>
              <span>
                <SvgIcon iconId={svgIcon} className={styles.icon} />
              </span>
              <Typography className={classnames(styles.label)}>{label}</Typography>
            </Typography>
          )}
          {icon && (
            <Typography className={classnames(styles.group)}>
              <Icon icon={icon} className={styles.icon} />
              <Typography className={classnames(styles.label)}>{label}</Typography>
            </Typography>
          )}
          {!icon && !svgIcon && label}
        </LinkMaterial>
      </Typography>
    </>
  );
}

export type LinkProps = {
  action?: () => void;
  appbarLink?: boolean;
  bold?: boolean;
  href?: string;
  icon?: Icons;
  svgIcon?: string;
  isActive?: boolean;
  label?: string;
  target?: string;
};
