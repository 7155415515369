import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ScrollArea from 'react-scrollbar/dist/no-css';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { kelQuartierModuleContext } from '../../contexts/kelQuartierModuleContext';
import { KELQUARTIER_THEMES } from '../../settings/kelquartier';

import poiStyles from './Poi.module.scss';
import listStyles from './PoiList.module.scss';
import controlStyles from './ControlStyles.module.scss';
import controlLabelStyles from './ControlLabelStyles.module.scss';
import togglableStyles from './TogglableStyles.module.scss';

export default function PoiList(props) {
  const { activePois, onPoiToggle } = props;
  const { theme: themeKelQuartier } = useContext(kelQuartierModuleContext);

  const poiThemeClassNames =
    themeKelQuartier instanceof Array
      ? themeKelQuartier.map(t => poiStyles[t])
      : poiStyles[themeKelQuartier];
  const togglableThemeClassNames =
    themeKelQuartier instanceof Array
      ? themeKelQuartier.map(t => togglableStyles[t])
      : togglableStyles[themeKelQuartier];

  function renderPoiToggler(theme, index1) {
    const { pois, text, title } = theme;
    return (
      <div key={index1} className={classNames(poiStyles.root, poiThemeClassNames)}>
        <div className={classNames(poiStyles.header, poiThemeClassNames)}>{title}</div>

        {text && <div>{text}</div>}

        <FormControl component="fieldset" classes={{ root: controlStyles.root }}>
          {pois.map(({ title }, index2) => {
            const key = `poi-${index1}-${index2}`;
            return (
              <FormControlLabel
                name={key}
                key={key}
                value={title}
                control={
                  <Radio
                    checkedIcon={<i style={{ display: 'none' }} />}
                    icon={<i style={{ display: 'none' }} />}
                    style={{ color: null }}
                    color="default"
                    classes={{
                      checked: classNames(togglableStyles.checked, togglableThemeClassNames),
                      disabled: togglableStyles.disabled,
                      root: classNames(togglableStyles.root, togglableThemeClassNames),
                    }}
                    onClick={onPoiToggle}
                  />
                }
                label={title}
                checked={activePois.indexOf(key) !== -1}
                classes={{
                  label: classNames(togglableStyles.label, togglableThemeClassNames),
                  root: controlLabelStyles.root,
                }}
              />
            );
          })}
        </FormControl>
      </div>
    );
  }

  return (
    <div className={listStyles.root}>
      <ScrollArea
        className={listStyles.container}
        speed={0.8}
        horizontal={false}
        smoothScrolling
        stopScrollPropagation
      >
        {KELQUARTIER_THEMES.map(renderPoiToggler)}
      </ScrollArea>
    </div>
  );
}

PoiList.propTypes = {
  activePois: PropTypes.arrayOf(PropTypes.string),
  onPoiToggle: PropTypes.func,
};

PoiList.defaultProps = {
  activePois: [],
  onPoiToggle: undefined,
};
