// eslint-disable-next-line import/no-self-import
import axios from 'axios';

export const offres = axios.create({
  baseURL: window.vinci.REACT_APP_VINCI_OFFRES_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': window.vinci.REACT_APP_VINCI_OFFRES_API_KEY,
    Authorization: `Bearer ${localStorage.getItem(window.vinci.REACT_APP_SESSION_AUTH_KEY)}`,
    withCredentials: true,
  },
});

export const vi3p = axios.create({
  baseURL: window.vinci.REACT_APP_VINCI_VI3P_API_URL,
  headers: {
    'Ocp-Apim-Subscription-Key': window.vinci.REACT_APP_VINCI_VI3P_API_KEY,
    Authorization: `Bearer ${localStorage.getItem(window.vinci.REACT_APP_SESSION_AUTH_KEY)}`,
    withCredentials: true,
  },
});

export const crm = axios.create({
  baseURL: window.vinci.REACT_APP_VINCI_CRM_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': window.vinci.REACT_APP_VINCI_CRM_API_KEY,
    Authorization: `Bearer ${localStorage.getItem(window.vinci.REACT_APP_SESSION_AUTH_KEY)}`,
    withCredentials: true,
  },
});
