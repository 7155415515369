import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_FORM_GENERATE_CONTRACT_SUCCESS_QUESTION,
  LABEL_FORM_GENERATE_CONTRACT_SUCCESS_SUB_TITLE,
  LABEL_FORM_GENERATE_CONTRACT_SUCCESS_TITLE,
  LABEL_LATER,
  LABEL_MY_ACTIVITY,
  LABEL_NOW,
} from 'settings/labels';

import { TmsContext } from 'modules/App/Contexts/TmsContext';

import Button from 'commonUi/Button/Button';

import ActivityConfirmationSteps from 'commonUi/ActivityStep/ActivityConfirmationSteps';

import styles from '../Confirmation.module.scss';

export function ConfirmationContract({
  handleClose,
  prebookIdCrm,
}: {
  handleClose: () => void;
  prebookIdCrm: string;
}) {
  const history = useHistory();
  const { setPageVars, setEventPageVars } = useContext(TmsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleNowClick() {
    setPageVars({});
    setEventPageVars({});
    history.push(`/signature-contrat/${prebookIdCrm}`);
  }

  return (
    <div className={classnames(styles.confirmation, styles.confirmationGenerateContract)}>
      <h3>{LABEL_FORM_GENERATE_CONTRACT_SUCCESS_TITLE}</h3>
      <p>
        {LABEL_FORM_GENERATE_CONTRACT_SUCCESS_SUB_TITLE}{' '}
        <Link className={styles.link} to="/mon-activite-commerciale">
          {LABEL_MY_ACTIVITY}
        </Link>
      </p>

      <div className={styles.steps}>
        <ActivityConfirmationSteps activeStep={1} withLoadIconOnActiveStep />
      </div>

      <p>
        <strong>{LABEL_FORM_GENERATE_CONTRACT_SUCCESS_QUESTION}</strong>
      </p>

      <div>
        <div className={styles.button}>
          <Button fullWidth variant="contained" color="primary" onClick={handleNowClick}>
            {LABEL_NOW}
          </Button>
        </div>
        <div className={styles.button}>
          <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
            {LABEL_LATER}
          </Button>
        </div>
      </div>
    </div>
  );
}
