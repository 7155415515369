import {
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_DEPARTMENT,
  LOCATION_TYPE_REGION,
} from 'settings/search';

import type { SearchType } from 'modules/HomePage/hooks/useSearch';

export function getSearchFilterRangeIntersects<T>(
  filter: { max: number; min: number },
  getValueFromItem: (item: T) => { max: number; min: number },
  ignoreMax: boolean
) {
  return (item: T) => {
    const range = getValueFromItem(item);
    if (ignoreMax) {
      return filter.min <= range.max;
    }

    return range.min && range.max && filter.min <= range.max && filter.max >= range.min;
  };
}
export function getSearchFilterRangeContains<T>(
  filter: { max: number | undefined; min: number | undefined },
  getValueFromItem: (item: T) => number,
  ignoreMax: boolean
) {
  return (item: T) => {
    const value = getValueFromItem(item);
    if (filter.min === undefined) {
      return true;
    }
    if (ignoreMax) {
      return filter.min <= value;
    }
    return (
      !!value && filter.min <= value && (filter.max !== undefined ? filter.max >= value : false)
    );
  };
}

export function getSearchFilterArray<T>(
  filter: string | undefined,
  getValueFromItem: (item: T) => string[],
  required?: boolean
): (item: T) => boolean;
export function getSearchFilterArray<T>(
  filter: string[] | undefined,
  getValueFromItem: (item: T) => string[],
  mode: 'all' | 'any',
  required?: boolean
): (item: T) => boolean;
export function getSearchFilterArray<T>(
  filter: string | string[] = [],
  getValueFromItem: (item: T) => string[],
  modeOrRequired?: boolean | 'all' | 'any',
  required = false
) {
  if (Array.isArray(filter)) {
    return (item: T) => {
      const value = getValueFromItem(item);

      if (filter.length === 0 || !Array.isArray(value)) {
        return !required;
      }

      if (modeOrRequired === 'all') {
        return !!value && filter.every(item => value.includes(item));
      }
      if (modeOrRequired === 'any') {
        return !!value && filter.some(item => value.includes(item));
      }
      return !required;
    };
  }

  return (item: T) => {
    const value = getValueFromItem(item);

    if (!filter || !Array.isArray(value)) {
      return !modeOrRequired;
    }

    return !!value && value.includes(filter);
  };
}

const today = new Date();
export function getSearchFilterDate<T>(
  filter: string[] = [],
  getValueFromItem: (item: T) => Date | null,
  required = false
) {
  return (item: T) => {
    const date = getValueFromItem(item);
    if (filter.length === 0 || !(date instanceof Date)) {
      return !required;
    }

    const year = date.getFullYear();

    return filter.some(filterValue => {
      if (filterValue === 'now') {
        return date < today;
      }

      if (filterValue.slice(-1) === '+') {
        return filterValue.slice(0, -1) === String(year);
      }
      return filterValue === String(year);
    });
  };
}

export function getSearchFilterInterval<T>(
  filter: { max: number | undefined; min: number | undefined },
  getValueFromItem: (item: T) => number,
  ignoreMax: boolean
) {
  return (item: T) => {
    const value = getValueFromItem(item);
    if (filter.min === undefined) {
      return true;
    }
    if (ignoreMax) {
      return filter.min <= value;
    }
    return value && filter.min <= value && (filter.max !== undefined ? filter.max >= value : false);
  };
}

export function getSearchFilterValue<T>(
  filter: string[] | undefined,
  getValueFromItem: (item: T) => string,
  required = false
) {
  return (item: T) => {
    const value = getValueFromItem(item);
    if (!filter || filter.length === 0) {
      return !required;
    }
    return value && filter.includes(value.toLowerCase());
  };
}

export function getSearchFilterLocation<T>(
  filter: string[] = [],
  getValueFromItem: (item: T) => string,
  required = false
) {
  return (item: T) => {
    const value = getValueFromItem(item);
    if (filter.length === 0) {
      return !required;
    }
    return !!value && filter.includes(value.toLowerCase());
  };
}

export function getSearchFilterLocations<T>(
  search: { locations?: string[]; nearProgram?: boolean },
  getValueFromItem: (item: T) => { city: string; department: string; region: string },
  required = false
) {
  if (!search.locations?.length) {
    return (x: T) => x;
  }
  const { cities, departments, regions } = search.locations
    .map(str => str.split(',') as [string, string, string, string])
    .reduce(
      (acc, location) => {
        switch (location[3]) {
          case `type=${LOCATION_TYPE_CITY}`:
            return {
              ...acc,
              cities: [...acc.cities, location[1].split('=')[1].toLocaleLowerCase()],
            };

          case `type=${LOCATION_TYPE_DEPARTMENT}`:
            return {
              ...acc,
              departments: [...acc.departments, location[2].split('=')[1].toLocaleLowerCase()],
            };

          case `type=${LOCATION_TYPE_REGION}`:
            return {
              ...acc,
              regions: [...acc.regions, location[2].split('=')[1].toLocaleLowerCase()],
            };

          default:
            return acc;
        }
      },
      { cities: [], departments: [], regions: [] }
    );
  const filterByCity = getSearchFilterLocation<T>(
    cities,
    item => getValueFromItem(item).city,
    required
  );
  const filterByDepartment = getSearchFilterLocation<T>(
    departments,
    item => getValueFromItem(item).department,
    required
  );
  const filterByRegion = getSearchFilterLocation<T>(
    regions,
    item => getValueFromItem(item).city,
    required
  );

  return (item: T) => {
    if (search.nearProgram) {
      return !required;
    }
    return filterByCity(item) || filterByDepartment(item) || filterByRegion(item);
  };
}

export function getSearchFilterBounds<T>(
  filter: Record<'north' | 'east' | 'south' | 'west', number>,
  getValueFromItem: (item: T) => { lat: number; lng: number },
  required = false
) {
  return (item: T) => {
    const { lat, lng } = getValueFromItem(item);
    return (
      (!Number.isNaN(filter.north) ? lat < filter.north : !required) &&
      (!Number.isNaN(filter.south) ? lat > filter.south : !required) &&
      (!Number.isNaN(filter.east) ? lng < filter.east : !required) &&
      (!Number.isNaN(filter.west) ? lng > filter.west : !required)
    );
  };
}

export function getFilterableValues<T extends Partial<SearchType>>(search: T): Partial<SearchType> {
  const SEARCHABLE_KEYS: (keyof SearchType)[] = [
    'north',
    'east',
    'south',
    'west',
    'locations',
    'delivery',
    'actability',
    'taxes',
    'budgetMin',
    'budgetMax',
    'surfaceMin',
    'surfaceMax',
    'profitabilityMin',
    'profitabilityMax',
    'kinds',
    'annexes',
    'others',
    'promoRef',
  ];
  return Object.fromEntries(
    Object.entries(search).filter(([key]) => SEARCHABLE_KEYS.includes(key as keyof SearchType))
  );
}
