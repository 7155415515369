import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import type { ReportDetail } from 'api/viOffresAPI/apiTypes/Reports';

import { useSWROffresImmutable } from 'api/offresAPI/useSWROffres';

import useAttributionProgram from 'hooks/useAttributionProgram';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import ReportContent from './ReportContent/ReportContent';

interface ReportProps {
  programRef: string;
  reportId: string;
}

export default function Report({ match, location }: RouteComponentProps<ReportProps>) {
  const { programRef, reportId } = match.params;
  const from = new URLSearchParams(location.search).get('from') ?? undefined;

  const { program } = useAttributionProgram(programRef);
  const { data: report } = useSWROffresImmutable<ReportDetail>({
    url: `/bulletinsChantier/${reportId}`,
  });

  return (
    <>
      <ReportContent from={from} program={program} report={report} />
      <TagCommander
        navigation_pagename={`programme.chantier.${reportId}`}
        navigation_template={`programme.chantier.${programRef}`}
      />
    </>
  );
}
