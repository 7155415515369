import React, { useMemo, useRef } from 'react';
import type { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Document as PdfDocument,
  Image as PdfImage,
  Page as PdfPage,
  View as PdfView,
  pdf,
} from '@react-pdf/renderer';
import heic2any from 'heic2any';

import { replaceTokens, unitsFormatter } from 'services/formatter';
import { log } from 'services/log';
import { FILE_SIZE_UNITS } from 'settings/app';
import {
  LABEL_DRAG_AND_DROP_PROOF,
  LABEL_FILE_FORMAT_ERROR,
  LABEL_FILE_FORMAT_ERROR_HELPER_TEXT,
  LABEL_FILE_SIZE_ERROR,
  LABEL_FILE_SIZE_ERROR_HELPER_TEXT,
  LABEL_IMPORT_DOCUMENT,
  LABEL_MAX_SIZE_IMG,
  LABEL_MAX_SIZE_PDF,
  LABEL_OR,
  LABEL_UNSELECT_FILE,
  LABEL_VALID_FORMAT,
} from 'settings/labels';
import { TOKEN_MAX_SIZE_IMG, TOKEN_MAX_SIZE_PDF, TOKEN_TYPES } from 'settings/token';

import Button from 'commonUi/Button/Button';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './Attachment.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const fileTypesMapping = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  heic: 'image/heif',
  heif: 'image/heif',
  pdf: 'application/pdf',
};

const reactDropZoneInvalidType = 'file-invalid-type';
const reactDropZoneTooLarge = 'file-too-large';

function isHEIC(file: File) {
  const x = file.type ? file.type.split('image/').pop() : file.name.split('.').pop()?.toLowerCase();
  return x === 'heic' || x === 'heif';
}

function convertFileToPdf(file: Blob, filename: string) {
  const reader = new FileReader();
  return new Promise<File>(resolve => {
    reader.readAsDataURL(file);
    reader.onloadend = e => {
      if (typeof e.target?.result === 'string') {
        pdf(
          <PdfDocument>
            <PdfPage size="A4">
              <PdfView>
                <PdfImage src={e.target.result} style={{ maxWidth: '100%', height: 'auto' }} />
              </PdfView>
            </PdfPage>
          </PdfDocument>
        )
          .toBlob()
          .then(blob => resolve(new File([blob], `${filename}.pdf`, { type: 'application/pdf' })));
      }
    };
  });
}

interface AttachmentType {
  cniPdfImageFilename: string;
  disabled?: boolean;
  inputId: string;
  onInputValueChange: (value: string | ArrayBuffer | File | undefined) => void;
  onInputValueError: ({ title, content }: { title: string; content: string }) => void;
  title: ReactNode;
  readonly?: boolean;
  selectedFile: File;
  settings: {
    fileTypes: readonly ('png' | 'jpg' | 'jpeg' | 'heic' | 'heif' | 'pdf')[];
    imgFileTypes: readonly ('png' | 'jpg' | 'jpeg' | 'heic' | 'heif')[];
    imgSizeLimit: number;
    sizeLimit: number;
  };
}

export default function Attachment({
  cniPdfImageFilename,
  disabled,
  inputId,
  onInputValueChange,
  onInputValueError,
  readonly = false,
  selectedFile,
  settings,
  title,
}: AttachmentType) {
  const input = useRef<HTMLInputElement>(null);
  const [
    validFileTypes,
    validImgFileTypes,
    formattedImgLimit,
    formattedPdfLimit,
    typesList,
  ] = useMemo(
    () => [
      new Set(settings.fileTypes.map(f => fileTypesMapping[f])),
      new Set(settings.imgFileTypes.map(f => fileTypesMapping[f])),
      unitsFormatter(settings.imgSizeLimit, FILE_SIZE_UNITS),
      unitsFormatter(settings.sizeLimit, FILE_SIZE_UNITS),
      settings.fileTypes.map(type => type.toUpperCase()).join(', '),
    ],
    [settings]
  );

  function validateFile(file: File) {
    return new Promise<File>(resolve => {
      if (
        file.size > settings.sizeLimit ||
        (validImgFileTypes.has(file.type) && file.size > settings.imgSizeLimit)
      ) {
        return onInputValueError({
          title: LABEL_FILE_SIZE_ERROR,
          content: replaceTokens(LABEL_FILE_SIZE_ERROR_HELPER_TEXT, {
            [TOKEN_MAX_SIZE_IMG]: formattedImgLimit,
            [TOKEN_MAX_SIZE_PDF]: formattedPdfLimit,
          }),
        });
      }

      // Fix for windows problem on apple images formats which has an empty file type
      if (isHEIC(file)) {
        log('HEIC File', 'FgCyan', file);
        return resolve(file);
      }

      if (!validFileTypes.has(file.type)) {
        return onInputValueError({
          title: LABEL_FILE_FORMAT_ERROR,
          content: replaceTokens(LABEL_FILE_FORMAT_ERROR_HELPER_TEXT, {
            [TOKEN_TYPES]: typesList,
          }),
        });
      }

      return resolve(file);
    });
  }
  function handleFile(file: File) {
    if (isHEIC(file)) {
      heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 })
        .then(convertedImg => convertFileToPdf(convertedImg as Blob, cniPdfImageFilename))
        .then(onInputValueChange);
    }

    if (['image/jpeg', 'image/png'].includes(file.type)) {
      convertFileToPdf(file, cniPdfImageFilename).then(onInputValueChange);
    }

    if (file.type === 'application/pdf') {
      onInputValueChange(file);
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: Array.from(validFileTypes).join(', '),
    disabled,
    maxFiles: 1,
    maxSize: settings.sizeLimit,
    noClick: true,
    onDrop(acceptedFiles) {
      if (acceptedFiles?.[0]) {
        validateFile(acceptedFiles[0]).then(handleFile);
      }
    },
    onDropRejected(error) {
      if (error.length > 0) {
        const rejectedError = error[0].errors[0].code;
        switch (rejectedError) {
          case reactDropZoneInvalidType:
            onInputValueError({
              title: LABEL_FILE_FORMAT_ERROR,
              content: replaceTokens(LABEL_FILE_FORMAT_ERROR_HELPER_TEXT, {
                [TOKEN_TYPES]: typesList,
              }),
            });
            break;
          case reactDropZoneTooLarge:
            onInputValueError({
              title: LABEL_FILE_SIZE_ERROR,
              content: replaceTokens(LABEL_FILE_SIZE_ERROR_HELPER_TEXT, {
                [TOKEN_MAX_SIZE_IMG]: formattedImgLimit,
                [TOKEN_MAX_SIZE_PDF]: formattedPdfLimit,
              }),
            });
            break;
          default:
        }
      }
    },
  });

  return (
    <div className={styles.attachment}>
      {title && <div className={styles.title}>{title}</div>}
      {!readonly && (
        <div className={styles.dragZone}>
          {selectedFile === undefined ? (
            <div {...getRootProps()}>
              <div className={styles.fileInformations}>
                <input {...getInputProps()} />
                <Icon className={styles.icon} icon="pdf" />
                <div className={styles.fileInformationsText}>
                  <span>
                    {LABEL_VALID_FORMAT} : {typesList}
                  </span>
                  <span>
                    {LABEL_MAX_SIZE_PDF} : {formattedPdfLimit}
                  </span>
                  <span>
                    {LABEL_MAX_SIZE_IMG} : {formattedImgLimit}
                  </span>
                </div>
              </div>

              <div className={styles.howTo}>
                <span className={styles.howToText}>{LABEL_DRAG_AND_DROP_PROOF}</span>
                <span className={styles.howToText}>{LABEL_OR}</span>
                <label htmlFor={inputId}>
                  <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={() => input.current?.click()}
                    variant="contained"
                  >
                    {LABEL_IMPORT_DOCUMENT}
                  </Button>
                </label>
              </div>
            </div>
          ) : (
            <button
              aria-label={LABEL_UNSELECT_FILE}
              className={styles.clearButton}
              title={LABEL_UNSELECT_FILE}
              type="button"
              onClick={ev => {
                ev.preventDefault();
                onInputValueChange(undefined);
              }}
            >
              <Icon icon="cross-bold" />
            </button>
          )}
          {Boolean(selectedFile) && (
            <Document file={selectedFile}>
              <Page pageNumber={1} className={styles.document} />
            </Document>
          )}
        </div>
      )}

      {!disabled && !readonly && (
        <input
          ref={input}
          type="file"
          id={inputId}
          onChange={ev => {
            const file = ev.target.files?.[0];
            if (!file) {
              return;
            }
            validateFile(file).then(handleFile);
            if (input.current) {
              input.current.value = ''; // Reset the input so we can re-select the same file after un-selecting it
            }
          }}
        />
      )}
    </div>
  );
}
