import React from 'react';
import { Redirect } from 'react-router-dom';

import { getTokenFromSession, getTokenFromUrl, isTokenExpired, setTokenInSession } from 'services';

export default function Signup() {
  const token = getTokenFromUrl() || getTokenFromSession();
  if (token && !isTokenExpired(token)) {
    setTokenInSession(token);
  }
  localStorage.removeItem('register-step');
  localStorage.removeItem('registered-files');
  return <Redirect to="/" />;
}
