import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_CLEAR_ALL_CRITERIAS,
  LABEL_CONTROLLED_PRICE,
  LABEL_FURNISHED,
  LABEL_NEW_RESIDENCE,
  LABEL_OTHER_CRITERIAS,
  LABEL_PREVIEW,
  LABEL_REDUCED_VAT,
} from 'settings/labels';
import {
  SEARCH_CRITERIA_CONTROLLED_PRICE,
  SEARCH_CRITERIA_FURNISHED,
  SEARCH_CRITERIA_NEW,
  SEARCH_CRITERIA_PREVIEW,
  SEARCH_CRITERIA_REDUCED_VAT,
} from 'settings/search';

import { modifyQuery } from 'services/url';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import ToggleCircle from '../ToggleCircle/ToggleCircle';

import styles from './SearchBar.module.scss';

interface SearchBarDesktopProps {
  className?: string;
  onFiltersButtonClick?: () => void;
}

export default function SearchBar({ className, onFiltersButtonClick }: SearchBarDesktopProps) {
  const history = useHistory();
  const { kinds: kindOptions, taxes: taxOptions } = useContext(TaxonomiesContext);
  const search = useSearch();

  const clearSearch = () => {
    const { searchId, type, ...currentFilters } = search; // Omit 'type' from search
    history.replace(modifyQuery({}, Object.keys(currentFilters)));
  };

  function toggleValue(queryKey: string, value: string[], id: string) {
    const index = value.indexOf(id);
    if (index !== -1) {
      history.replace(
        modifyQuery({ [queryKey]: [...value.slice(0, index), ...value.slice(index + 1)] })
      );
    } else {
      history.replace(modifyQuery({ [queryKey]: [...value, id] }));
    }
  }

  return (
    <div className={classnames(className, styles.root)}>
      <div className={classnames(styles.group, styles.vertical)}>
        <button
          type="button"
          className={classnames(
            styles.button,
            search.others.includes(SEARCH_CRITERIA_NEW) && styles.active
          )}
          onClick={() => toggleValue('others', search.others, SEARCH_CRITERIA_NEW)}
        >
          {LABEL_NEW_RESIDENCE}
        </button>
        <button
          type="button"
          className={classnames(
            styles.button,
            search.others.includes(SEARCH_CRITERIA_PREVIEW) && styles.active
          )}
          onClick={() => toggleValue('others', search.others, SEARCH_CRITERIA_PREVIEW)}
        >
          {LABEL_PREVIEW}
        </button>
      </div>

      <div className={styles.group}>
        <Tooltip title={LABEL_REDUCED_VAT}>
          <ToggleCircle
            className={styles.circle}
            iconId="reducedtva"
            active={search.others.includes(SEARCH_CRITERIA_REDUCED_VAT)}
            onClick={() => toggleValue('others', search.others, SEARCH_CRITERIA_REDUCED_VAT)}
          />
        </Tooltip>
        <Tooltip title={LABEL_CONTROLLED_PRICE}>
          <ToggleCircle
            className={styles.circle}
            iconId="controlledprice"
            active={search.others.includes(SEARCH_CRITERIA_CONTROLLED_PRICE)}
            onClick={() => toggleValue('others', search.others, SEARCH_CRITERIA_CONTROLLED_PRICE)}
          />
        </Tooltip>
        <Tooltip title={LABEL_FURNISHED}>
          <ToggleCircle
            className={styles.circle}
            iconId="furnished"
            active={search.others.includes(SEARCH_CRITERIA_FURNISHED)}
            onClick={() => toggleValue('others', search.others, SEARCH_CRITERIA_FURNISHED)}
          />
        </Tooltip>
      </div>

      {!!taxOptions?.length && (
        <div className={styles.group}>
          {taxOptions.map(tax => (
            <Tooltip key={tax.id} title={tax.short_label || tax.nom}>
              <ToggleCircle
                className={styles.circle}
                iconId={tax.short_label || tax.nom}
                active={search.taxes.includes(tax.id)}
                onClick={() => toggleValue('taxes', search.taxes, tax.id)}
              />
            </Tooltip>
          ))}
        </div>
      )}

      {!!kindOptions?.length && (
        <div className={styles.compact}>
          {kindOptions.map(kind => (
            <button
              key={kind.id}
              type="button"
              className={classnames(styles.item, search.kinds.includes(kind.id) && styles.active)}
              onClick={() => toggleValue('kinds', search.kinds, kind.id)}
            >
              {kind.nom}
            </button>
          ))}
        </div>
      )}

      <Tooltip title={LABEL_CLEAR_ALL_CRITERIAS}>
        <button type="button" className={styles.reset} onClick={clearSearch}>
          <SvgIcon className={styles.icon} iconId="icon-reset" />
        </button>
      </Tooltip>

      <Tooltip title={LABEL_OTHER_CRITERIAS}>
        <button type="button" className={styles.panelBtn} onClick={onFiltersButtonClick}>
          <SvgIcon className={styles.icon} iconId="icon-filters" />
          Filtres
        </button>
      </Tooltip>
    </div>
  );
}
