import React from 'react';
import type { ComponentProps } from 'react';
import { Text, View } from '@react-pdf/renderer';

import PdfSvgPicto from '../Common/PdfSvgPicto/PdfSvgPicto';

import styles from './PdfSectionStyles';

interface PdfSectionType extends ComponentProps<typeof View> {
  picto?: ComponentProps<typeof PdfSvgPicto>['picto'];
  title?: string;
}

export default function PdfSection({
  children,
  picto,
  title = undefined,
  ...props
}: PdfSectionType) {
  return (
    <View style={styles.section} {...props}>
      {(title || picto) && (
        <View style={styles.sectionTitle}>
          {picto && (
            <View style={[styles.sectionPicto, styles[picto]]}>
              <PdfSvgPicto picto={picto} />
            </View>
          )}
          {title && <Text style={styles.sectionTitleText}>{title}</Text>}
        </View>
      )}
      {children}
    </View>
  );
}
