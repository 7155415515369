import { createContext } from 'react';

interface Contact {
  description: string;
  disabled_homepage: boolean;
  email: string;
  nid: number;
  nom: string;
  photo: string;
  position: string;
  prenom: string;
  region: string;
  telephone: string;
  vignette: string;
  contact_adv: string;
}
interface ContactsContextProps {
  isLoading: boolean;
  // Get all the contacts that can be matched to the list of email addresses. If `getLinked` is `true` get the contacts linked to the primary contacts' email addresses as well.
  getContacts: (emails?: string[], getLinked?: boolean) => Contact[];
}

export default createContext<ContactsContextProps>({
  isLoading: false,
  getContacts: () => [],
});
