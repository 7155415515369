import ReactPDF from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';

import { identity } from 'services/functional';
import {
  getLotHeadingPrice1,
  getLotHeadingPrice2,
  getLotHeadingRentProfitability,
} from 'services/lots';
import {
  LOT_CELL_TYPE_LOT as LOT,
  LOT_CELL_TYPE_RENT as RENT,
  LOT_CELL_TYPE_STATUS as STATUS,
  LOT_CELL_TYPE_SURFACE as SURFACE,
  LOT_CELL_TYPE_SURFACE_ANNEX as SURFACE_ANNEX,
  LOT_CELL_TYPE_TAX as TAX,
  LOT_CELL_TYPE_VALUE as VALUE,
} from 'settings/lots';

import type { HeadingType, HeadingsWidthType } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxType } from 'api/viOffresAPI/apiTypes/Taxonomies';

export async function generatePdf(pdfNode: JSX.Element) {
  const blob = await ReactPDF.pdf(pdfNode).toBlob();
  const url = URL.createObjectURL(blob);
  return { blob, url };
}

export function getPDFLotsGridHeadings(tax: TaxType | undefined, vat: boolean) {
  const isLMNP = tax === 'LMNP';
  return [
    !tax && {
      field: 'tax',
      title: 'Fiscalité',
      type: TAX,
      id: 'tax',
      separator: 'none',
      weight: 1,
    },
    isLMNP && { field: 'type', title: 'Nature', type: VALUE, id: 'type', weight: 1 },
    { field: 'number', title: 'Lot', type: LOT, id: 'number', weight: 1 },
    { field: 'kind', title: 'Typo', type: VALUE, id: 'kind', weight: 1 },
    { field: 'floor', title: 'Étage', type: VALUE, id: 'floor', weight: 1 },
    { field: 'exposure', title: 'Exposition', type: VALUE, id: 'exposure', weight: 1 },
    { field: 'surface', title: 'Surf. hab.', type: SURFACE, id: 'surface', sub: '(m²)', weight: 1 },
    {
      field: 'surfaceAnnex',
      title: 'Surface annexe',
      type: SURFACE_ANNEX,
      id: 'surfaceAnnex',
      sub: '(m²)',
      weight: 1,
    },
    {
      field: 'hasParking',
      title: 'Parking',
      type: VALUE,
      id: 'hasParking',
      sub: '(annexe)',
      weight: 1,
    },
    !isLMNP && getLotHeadingPrice2(tax),
    !isLMNP && getLotHeadingPrice1(tax, vat, true),
    isLMNP && getLotHeadingPrice1(tax, vat),
    isLMNP && getLotHeadingPrice2(tax),
    getLotHeadingRentProfitability(tax),
    {
      field: 'rentFurnished',
      title: 'Loyer Meublé',
      type: RENT,
      id: 'rentFurnished',
      weight: 1,
    },
    { field: 'status', title: 'Statut', type: STATUS, id: 'status', weight: 1 },
  ].filter(identity) as HeadingType[];
}

export function getPDFLotsGridHeadingsWeight(tax: string | undefined): Record<string, number> {
  switch (tax) {
    case 'LMNP':
      return {
        type: 25,
        number: 15,
        kind: 10,
        floor: 13,
        exposure: 15,
        surface: 18,
        surfaceAnnex: 20,
        hasParking: 15,
        price1: 20,
        price2: 20,
        rentProfit: 15,
        rentFurnished: 15,
        status: 25,
      };

    case 'Malraux':
      return {
        number: 13,
        kind: 10,
        floor: 13,
        exposure: 20,
        surface: 20,
        surfaceAnnex: 20,
        hasParking: 15,
        price1: 20,
        rentProfit: 15,
        rentFurnished: 15,
        status: 25,
      };

    case 'Démembrement':
    case 'Demembrement':
      return {
        number: 13,
        kind: 10,
        floor: 13,
        exposure: 20,
        surface: 20,
        surfaceAnnex: 20,
        hasParking: 15,
        price1: 20,
        status: 25,
      };

    case 'Hors dispositif':
    case 'ResidenceHorsPinel':
    case 'Aucun':
    case 'Non':
      return {
        number: 12,
        kind: 10,
        floor: 13,
        exposure: 20,
        surface: 20,
        surfaceAnnex: 20,
        hasParking: 15,
        price2: 20,
        price1: 20,
        rentProfit: 15,
        rentFurnished: 15,
        status: 25,
      };

    case 'Pinel':
    case 'Pinel+':
    case 'PinelDeroge':
    case 'BRS':
    case 'PLS':
      return {
        number: 13,
        kind: 10,
        floor: 13,
        exposure: 20,
        surface: 20,
        surfaceAnnex: 20,
        hasParking: 15,
        price2: 20,
        price1: 20,
        rentProfit: 15,
        rentFurnished: 15,
        status: 25,
      };

    default:
      return {
        tax: 20,
        number: 13,
        kind: 10,
        floor: 13,
        exposure: 20,
        surface: 20,
        surfaceAnnex: 20,
        hasParking: 15,
        price2: 20,
        price1: 20,
        rentProfit: 15,
        rentFurnished: 15,
        status: 25,
      };
  }
}

export function getPDFLotsGridHeadingsWidth(
  headings: {
    id: string;
  }[],
  headingsWeight: Record<string, number>
): HeadingsWidthType {
  const totalWeight = Object.values(headingsWeight).reduce((totalWeight, weight) => {
    return totalWeight + weight;
  }, 0);

  return Object.fromEntries(
    headings.map(({ id }) => [id, (100 * headingsWeight[id] || 0) / totalWeight])
  );
}

export async function generatePlanImageFromPdf(planPdfUrl: string) {
  const pdfDoc = await pdfjs.getDocument(planPdfUrl).promise;
  const page = await pdfDoc.getPage(1);

  const scale = 1;
  const viewport = page.getViewport({ scale });
  const outputScale = window.devicePixelRatio || 1;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = Math.floor(viewport.width * outputScale);
  canvas.height = Math.floor(viewport.height * outputScale);
  canvas.style.width = `${Math.floor(viewport.width)}px`;
  canvas.style.height = `${Math.floor(viewport.height)}px`;

  if (context) {
    await page.render({
      canvasContext: context,
      transform: outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : undefined,
      viewport,
    }).promise;
  }
  return canvas.toDataURL();
}
