import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { formatActivityDate } from 'services/date';
import {
  LABEL_FINISH,
  LABEL_FORM_OPTION_CONTENT_SOME_MINUTES,
  LABEL_FORM_OPTION_CONTENT_YOUR_SPACE,
  LABEL_FORM_PREBOOK_CONTENT_DATE_AVAILABLE,
  LABEL_FORM_PREBOOK_SUCCESS_TITLE,
  LABEL_GENERATE_RESERVATION_CONTRACT,
  LABEL_LOAD_PROBLEM_ERROR_SUBTITLE,
  LABEL_LOAD_PROBLEM_ERROR_TITLE,
  LABEL_LOTS_ENABLE_ERROR_SUBTITLE,
  LABEL_MODAL_MISSING_DOCUMENTS_ADDITIONAL_TITLE,
  LABEL_MODAL_MISSING_DOCUMENTS_TITLE,
  LABEL_MY_ACTIVITY,
} from 'settings/labels';

import { useActivityChoices } from 'hooks/useActivities';
import useAttributionProgram from 'hooks/useAttributionProgram';

import Button from 'commonUi/Button/Button';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import ActivityConfirmationSteps from 'commonUi/ActivityStep/ActivityConfirmationSteps';

import { PrebookConfirmationReturnType } from 'api/viCrmApi/apiTypes/Prebook';

import styles from '../Confirmation.module.scss';

export function ConfirmationPrebooking({
  handleClose,
  programRef,
  successData,
}: {
  handleClose: () => void;
  programRef: string;
  successData: PrebookConfirmationReturnType;
}) {
  const history = useHistory();
  const [openError, setOpenError] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [getChoices, setGetChoices] = useState(false);
  const [time, setTime] = useState<number>();
  const [loading, setLoading] = useState(false);
  const { program } = useAttributionProgram(programRef);

  const { preReservationId } = successData;

  const { choices, ...swrChoices } = useActivityChoices(
    getChoices ? { preBookingId: preReservationId, time } : { preBookingId: undefined },
    {
      shouldRetryOnError: false,
    }
  );

  const hasMandatoryDocuments =
    choices?.documents?.conditionsParticulieres.length &&
    choices?.documents?.noticesDescriptives.length;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getChoices && !swrChoices?.isValidating) {
      if (swrChoices?.error) {
        setLoadError(true);
      } else if (choices) {
        if (!hasMandatoryDocuments) {
          setOpenError(true);
        } else {
          history.push(`/generation-contrat/${successData.preReservationId}`);
        }
      }
    }
  }, [getChoices, swrChoices?.isValidating]);

  const expirationDate = formatActivityDate(successData.expiration, 'DD/MM/YYYY - HH:mm');

  return (
    <div className={styles.confirmation}>
      <SvgIcon className={styles.svgIcon} iconId="icon-check-circle" />
      <h3>{LABEL_FORM_PREBOOK_SUCCESS_TITLE}</h3>
      <p>
        {LABEL_FORM_OPTION_CONTENT_SOME_MINUTES}
        <br />
        {LABEL_FORM_PREBOOK_CONTENT_DATE_AVAILABLE}
        <span className={styles.highlight}>{expirationDate}.</span> <br />
        {LABEL_FORM_OPTION_CONTENT_YOUR_SPACE}
        <Link
          className={styles.link}
          to="/mon-activite-commerciale"
          rel="noreferrer"
          target="_self"
        >
          {LABEL_MY_ACTIVITY}
        </Link>
      </p>

      <div className={styles.steps}>
        <ActivityConfirmationSteps activeStep={1} />
      </div>

      <div>
        {program?.eligibiliteCR && (
          <div className={styles.button}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              onClick={ev => {
                setTime(Date.now());
                ev.preventDefault();
                setLoading(true);
                setGetChoices(true);
              }}
            >
              {LABEL_GENERATE_RESERVATION_CONTRACT}
            </Button>
          </div>
        )}
        <div className={styles.button}>
          <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
            {LABEL_FINISH}
          </Button>
        </div>
      </div>
      <LotTableErrorModal
        open={!hasMandatoryDocuments && openError}
        additionalTitle={LABEL_MODAL_MISSING_DOCUMENTS_ADDITIONAL_TITLE}
        onClose={ev => {
          ev.stopPropagation();
          setOpenError(false);
          setLoading(false);
          setGetChoices(false);
        }}
        paperClassName={styles.modalPaper}
        title={LABEL_MODAL_MISSING_DOCUMENTS_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />
      <LotTableErrorModal
        open={loadError}
        onClose={ev => {
          ev.stopPropagation();
          setLoadError(false);
          setLoading(false);
          setGetChoices(false);
        }}
        paperClassName={styles.modalPaper}
        scrollPaperClassName={styles.modalScrollPaper}
        title={LABEL_LOAD_PROBLEM_ERROR_TITLE}
        subtitle={LABEL_LOAD_PROBLEM_ERROR_SUBTITLE}
        disablePortal={false}
      />
    </div>
  );
}
