import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { getDocumentsPrograms } from 'api/viOffresAPI/apiClient';

import { DOC_NATURE_PLAQUETTE } from 'settings/documents';

export function useBooklet(programRef: string | undefined, getBooklet: boolean) {
  const [booklet, setBooklet] = useState({ url: '#', name: '' });

  const { data: documents } = useSWR(
    getBooklet && programRef ? `document/program/${programRef}` : null,
    url => getDocumentsPrograms(url),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (documents && documents.programme?.length) {
      const booklet = documents.programme.find(doc => doc.nature === DOC_NATURE_PLAQUETTE);
      setBooklet({
        url: booklet?.uri ?? '#',
        name: booklet?.titre ? decodeURI(booklet.titre) : '',
      });
    }
  }, [documents]);

  return { booklet };
}
