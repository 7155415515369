import { useEffect, useState } from 'react';
import useSWR from 'swr';
import qs from 'query-string';

import type { DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';

import { getLot, getProgramByRef } from 'api/viOffresAPI/apiClient';
import { promiseAllFullfilled } from 'services/tools';

export function useDocuments(urlSWR: string | undefined) {
  const [documents, setDocuments] = useState<DocumentTypeV2[]>([]);

  const { data } = useSWR(urlSWR, async function getDocuments(urlSWR: string) {
    const { lots, programs } = qs.parse(urlSWR, {
      arrayFormat: 'bracket',
    }) as { lots: string[]; programs: string[] };
    const programsPromise = programs?.map(p => getProgramByRef(p.split(',')[0]));
    const lotsPromise = lots?.map(l => getLot(l.split(',')[0], l.split(',')[1]));
    const programsDatas = programsPromise && (await promiseAllFullfilled(programsPromise));
    const lotsDatas = lotsPromise && (await promiseAllFullfilled(lotsPromise));
    const programsDocuments = programsDatas
      ? programsDatas
          .filter(p => p.documents && p.documents.length > 0)
          .reduce<DocumentTypeV2[]>((acc, program) => acc.concat(program.documents), [])
      : [];
    const lotsDocuments = lotsDatas
      ? lotsDatas
          .filter(l => l.documents && l.documents.length > 0)
          .reduce<DocumentTypeV2[]>((acc, lot) => acc.concat(lot.documents), [])
      : [];
    return [...programsDocuments, ...lotsDocuments];
  });

  useEffect(() => {
    if (data) {
      setDocuments(data);
    }
  }, [data]);

  return { documents };
}
