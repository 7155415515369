import React from 'react';
import Helmet from 'react-helmet';

import { getTmsScriptUrl } from 'services/tms';

// Generate TMS script URL only once for all the App to avoid reloading it multiple times as HeadElement re-renders
const TMS_SCRIPT_URL = getTmsScriptUrl(46);

export default function HeadElement() {
  return (
    <Helmet>
      <html lang="fr" />
      <script type="text/javascript" src={TMS_SCRIPT_URL} />
    </Helmet>
  );
}
