import PDF_SVG_PICTOS from './pictos';

import styles from './PdfSvgPictoStyles';

interface PdfSvgPictoProps {
  picto: keyof typeof PDF_SVG_PICTOS;
}

export default function PdfSvgPicto({ picto }: PdfSvgPictoProps) {
  return PDF_SVG_PICTOS[picto]?.(styles.picto);
}
