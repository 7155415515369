export const TMS_CATEGORY_TECHNICAL = '1';
export const TMS_CATEGORY_PERFORMANCE = '2';
export const TMS_CATEGORY_PERSONALIZATION = '3';
export const TMS_CATEGORY_SOCIAL_NETWORKS = '4';
export const TMS_CATEGORY_ADVERTISING = '5';

export const TMS_VENDOR_ORBITAL = '1';
export const TMS_VENDOR_GOOGLE_MAPS = '2';
export const TMS_VENDOR_YOUTUBE = '3';
export const TMS_VENDOR_FACEBOOK = '7';
export const TMS_VENDOR_TWITTER = '13';
export const TMS_VENDOR_LINKEDIN = '14';
export const TMS_VENDOR_GOOGLE_PLACES = '15';
export const TMS_VENDOR_TYPEFORM = '16';
export const TMS_VENDOR_DAILYMOTION = '17';
export const TMS_VENDOR_INSTAGRAM = '18';
export const TMS_VENDOR_VIMEO = '19';

export const TMS_DEFAULT_VALUES = {
  env_site: 'B2B',
  env_work: window.vinci.REACT_APP_ENV,
  navigation_country: 'FR',
  navigation_device: 'd',
  navigation_language: 'FR',
  navigation_template: '',
  website_version: window.vinci.REACT_APP_VERSION,

  simulateur_type: '',
  projet_type: 'Investir',
  projet_region: '',
  form_theme: '',
  form_id: '',
  navigation_pagename: '',
  page_cat1: '',
  page_cat2: '',
  page_cat3: '',
  page_cat4: '',
  programme_id: '',
  programme_nom: '',
  programme_region: '',
  programme_ville: '',
  programme_departement: '',
  programme_adresse: '',
  programme_type_projet: '',
  programme_type_bien: '',
  programme_nb_lot_disponibles: '',
  programme_disponibilite: '',
  programme_etat_avancement: '',
  programme_date_livraison: '',
  programme_date_actabilite: '',
  programme_eligibilite: '',
  programme_rentabilite: '',
  progamme_array: [
    {
      programme_id: '',
      programme_nom: '',
      programme_region: '',
      programme_ville: '',
      programme_departement: '',
    },
  ],
  lot_array: [
    {
      lot_id: '',
      lot_nom: '',
      lot_type: '',
      lot_nb_piece: '',
      lot_etage: '',
      lot_surface: '',
      lot_prix: '',
    },
  ],

  lot_id: '',
  lot_nom: '',
  lot_type: '',
  lot_nb_piece: '',
  lot_surface: '',
  lot_surface_annexe: '',
  lot_prix: '',
  lot_option1: '',
  lot_option2: '',
  lot_option3: '',
  lot_disponibilite: '',
  lot_loyermarche: '',
  lot_eligibilite: '',
  lot_rentabilite: '',
  search_location: [] as string[],
};

export const TMS_DEFAULT_USER_VALUES = {
  user_id: '',
  user_logue: 0,
  user_civilite: '',
  user_nom: '',
  user_prenom: '',
  user_telephone: '',
  user_email: '',
  user_email_hashed_sha: '',
  user_email_hashed_md5: '',
  user_email_animateur: '',
  user_adresse: '',
  user_codepostal: '',
  user_ville: '',
  user_degre_invest: '',
  user_objectif_invest: '',
  user_projet_type: '',
  user_projet_1P: '',
  user_projet_2P: '',
  user_projet_3P: '',
  user_projet_4P: '',
  user_projet_5P: '',
  user_projet_budget: '',
  user_profil_configurateur: '',
  user_optins_array: [
    {
      user_optin_general: '',
      user_optin_sms: '',
      luser_optin_tel: '',
      user_optin_email: '',
      user_optin_courrier: '',
      user_optin_partenaire: '',
    },
  ],

  user_simu_montant_mens: '',
  user_simu_durée_pret_an: '',
  user_simu_projet_ville: '',
  user_simu_taux_pret: '',
  user_simu_montant_operation_immo: '',
  user_simu_apport: '',
  user_simu_revenu_reference: '',
  user_simu_situation_familiale: '',
  user_simu_nbpersonnes_appartement: '',
  user_simu_enfants_a_charge: '',
  user_simu_resultat_pinel_montant: '',
  user_simu_resultat_frais_notaire_montant: '',
  user_simu_resultat_frais_hypotheque_montant: '',
  user_simu_resultat_censi_bouvard_montant: '',
  user_simu_resultat_LMNP_montant: '',
  user_simu_resultat_eligibilite_ptz: '',
  user_simu_resultat_capacite_emprunt_montant: '',
};

export const TMS_TIMEOUT = 1000;

export const TMS_ORIGIN_FOLDER = 'd';
export const TMS_ORIGIN_PROGRAM = 'p';
export const TMS_ORIGIN_SEARCH = 'r';
