import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { uniqBy } from 'lodash';

import { getColumnWidth } from 'services/ui';
import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import {
  LOTS_DEFAULT_SORT_BY,
  LOTS_DEFAULT_SORT_ORDER,
  SEARCH_NB_ROWS_API_LOAD,
} from 'settings/search';

import { usePanels } from 'hooks/usePanels';

import {
  HeadingType,
  HeadingsWidthType,
  LotJson,
  LotSortBy,
  LotSortOrder,
} from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { modifyQuery } from 'services/url';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import userContext from 'modules/App/Contexts/userContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import { useSortedLots } from 'hooks/useSortedLots';
import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import TableV2 from 'commonUi/Table/TableV2';
import TableHeader from 'commonUi/Table/TableHeader/TableHeader';
import LotRow from 'commonUi/ListLotV2/LotRow/LotRow';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import LotCell from 'commonUi/ListLotV2/LotCell/LotCell';
import LotScrollingRow from 'commonUi/ListLotV2/LotRow/LotScrollingRow';
import PdfProvider from 'modules/App/Providers/PdfProvider';
import FolderLinks from 'modules/Folders/FolderLinks';

import { LotDetailsCpn } from 'modules/DetailsLotsAndActivity/LotDetailsCpn';

import styles from './LotsList.module.scss';

interface LotTableDesktopProps {
  taxById: TaxesById;
  programsRef: { programRef: string }[];
  isPanelExpanded: boolean;
  handleTogglePanel: () => void;
  handleOnChangeCheckbox: any;
  headings?: HeadingType[];
  headingsWidth?: HeadingsWidthType;
  lots?: LotJson[];
  noResultMessage?: ReactNode;
  reorder?(heading: HeadingType): void;
  selectedLots?: { lotNumber: string; programRef: string }[];
  sortBy?: LotSortBy;
  sortOrder?: LotSortOrder;
  pageTemplate?: string;
  pageTemplateRef?: string;
}

export default React.memo(function LotTableDesktop({
  handleOnChangeCheckbox,
  handleTogglePanel,
  headings = [],
  headingsWidth = undefined,
  isPanelExpanded,
  lots = [],
  noResultMessage = null,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  programsRef,
  reorder = () => {},
  selectedLots = [],
  sortBy = LOTS_DEFAULT_SORT_BY,
  sortOrder = LOTS_DEFAULT_SORT_ORDER,
  taxById,
}: LotTableDesktopProps) {
  const history = useHistory();
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert, userCrm } = useContext(
    userContext
  );
  const { lotNumber, origin, programRef } = useSearch();
  const { componentPageVars, setComponentPageVars } = useContext(TmsContext);
  // filter
  const { lots: sortedLots } = useSortedLots(lots, sortBy, sortOrder, programsRef);

  const [rowIndex, setRowIndex] = useState<number>(1);

  // necessary information for cell
  const { taxes: fiscalities } = useContext(TaxonomiesContext);

  const [currentRowOpen, setCurrentRowOpen] = useState<{ number: string; ref: string }>();

  useEffect(() => {
    if (!isPanelExpanded) {
      setCurrentRowOpen(undefined);
    }
  }, [isPanelExpanded]);

  // data
  const activeLots = programRef ? sortedLots.filter(lot => lot.ref === programRef) : [];
  const allData = [...lots, ...activeLots];
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      allData.length > 0 &&
      !allData.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);
  const lotsList = uniqBy([...activeLots, ...sortedLots], l => `${l.programId}-${l.number}`);
  const rowsListRef = useRef(null);
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);

  const { statuses, updateStatus } = useGetLotsStatuses(
    lotsList.slice((rowIndex - 1) * SEARCH_NB_ROWS_API_LOAD, rowIndex * SEARCH_NB_ROWS_API_LOAD),
    // If attribution switch is ON, the lots have already been filtered for it in `useLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );

  // load current lot
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentRowOpen?.ref, currentRowOpen?.number);

  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  useEffect(() => {
    if (programRef) {
      scrollRef.current?.osInstance()?.scroll(0, 0);
    }
  }, [origin, programRef]);

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = allData?.findIndex(l => l.number === lotNumber);
      setCurrentRowOpen(
        activeIndex !== -1
          ? { number: allData[activeIndex].number, ref: allData[activeIndex].ref }
          : undefined
      );
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  if (!programsRef?.length && !activeLots?.length) return <div>{noResultMessage}</div>;

  function onClickRowWithPanelExpend(lot: LotJson) {
    history.replace(modifyQuery({ programRef: lot.ref, origin: 'list' }));
  }

  return (
    <>
      <TableV2
        className={styles.table}
        error={
          <LotTableErrorModal
            open={loadLotError || openError}
            onClose={() => {
              setOpenError(false);
              setLoadLotError(false);
            }}
            title={LABEL_LOTS_ENABLE_ERROR_TITLE}
            subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
          />
        }
        headerWithTop={false}
        headings={headings}
        listWrapperClassName={styles.listWrapper}
        rowCount={lotsList.length}
        rowsListRef={rowsListRef}
        scrollRef={scrollRef}
        onRowsRendered={index => {
          const step = Math.floor(index / SEARCH_NB_ROWS_API_LOAD) + 1;
          if (rowIndex !== step) {
            setRowIndex(step);
          }
        }}
        renderHeader={col => (
          <TableHeader
            key={col.id}
            col={col}
            reorder={reorder}
            sortBy={sortBy}
            sortOrder={sortOrder}
            style={{
              width: headingsWidth ? getColumnWidth(col, headings, headingsWidth) : undefined,
            }}
          />
        )}
        renderRow={({ index, isScrolling, isVisible, style }) => {
          if (!isVisible && isScrolling) {
            return (
              <LotScrollingRow
                cellsWidth={headingsWidth}
                headings={headings}
                index={index}
                style={style}
              />
            );
          }

          const rowIsOpen =
            currentRowOpen?.number === lotsList[index]?.number &&
            currentRowOpen?.ref === lotsList[index]?.ref;
          return (
            <LotRow
              cellsWidth={headingsWidth}
              isCurrentRowOpen={!isDetailsLoading && rowIsOpen}
              onClickRow={() => {
                // Reset componentPageVars if we close the current lot accordion
                if (
                  currentRowOpen &&
                  componentPageVars?.navigation_pagename ===
                    `${pageTemplateRef}.${lotsList[index].number}`
                ) {
                  setComponentPageVars({});
                }
                setCurrentRowOpen(prev =>
                  prev?.number === lotsList[index]?.number && prev?.ref === lotsList[index]?.ref
                    ? undefined
                    : { number: lotsList[index].number, ref: lotsList[index].ref }
                );
              }}
              onClick={!isPanelExpanded ? () => onClickRowWithPanelExpend(lotsList[index]) : null}
              key={lotsList[index].nid}
              data={lotsList[index]}
              headings={headings}
              index={index}
              style={style}
              renderCell={(col, handleDisplayLot, isLotOpen) => (
                <LotCell
                  handleDisplayLot={() => {
                    if (!isPanelExpanded) handleTogglePanel();
                    handleDisplayLot();
                  }}
                  isLotOpen={isLotOpen}
                  isDetailsLoading={isDetailsLoading && rowIsOpen}
                  onStatusAlertClick={toggleAlert}
                  myBookings={myBookings}
                  myOptions={myOptions}
                  alerts={alerts}
                  handleOnChangeCheckbox={handleOnChangeCheckbox}
                  col={col}
                  lot={lotsList[index]}
                  statuses={statuses}
                  taxById={taxById}
                  isChecked={selectedLots.some(
                    l =>
                      l.lotNumber === lotsList[index].number &&
                      l.programRef === lotsList[index].program.ref
                  )}
                  pageTemplate={pageTemplate}
                  pageTemplateRef={pageTemplateRef}
                />
              )}
              renderShowMore={index => {
                if (!lotDetail || !programDetail) {
                  return null;
                }
                return (
                  <PdfProvider program={programDetail} lot={lotDetail} statuses={statuses}>
                    <LotDetailsCpn
                      lotApiOffre={lotDetail}
                      programDatas={programDetail}
                      myOptions={myOptions}
                      myBookings={myBookings}
                      fiscalities={fiscalities}
                      index={index}
                      statuses={statuses}
                      updateStatus={updateStatus}
                      pageTemplate={pageTemplate}
                      pageTemplateRef={pageTemplateRef}
                      openDropdownTop={index === lotsList.length - 1}
                      openPanel={openPanel}
                    />
                  </PdfProvider>
                );
              }}
            />
          );
        }}
      />

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
    </>
  );
});
