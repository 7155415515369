import React from 'react';
import { Route } from 'react-router-dom';
import type { RouteComponentProps, RouteProps } from 'react-router-dom';

import { isAuthenticated } from 'services/authentication';
import { getLoginUrlWithState } from 'services/url';

import AuthenticatedLayout from 'layout/AuthenticatedLayout';

import Program from 'modules/ProgramPage/Program/Program';

import ErrorBoundary from './Tools/ErrorBoundary';

interface ProgramRouteProps extends Omit<RouteProps, 'component' | 'path' | 'render'> {
  noFooter?: boolean;
  noHeader?: boolean;
  path?: Exclude<RouteProps['path'], readonly string[]>;
}

export default function ProgramRoute({ noFooter, noHeader, ...routeProps }: ProgramRouteProps) {
  // Go to Login page if we are no longer connected.
  if (!isAuthenticated()) {
    window.location.href = getLoginUrlWithState();
    return null;
  }

  return (
    <Route
      {...routeProps}
      render={(componentProps: RouteComponentProps<{ programRef: string; activeTab?: string }>) => (
        <AuthenticatedLayout hideFooter={noFooter} hideHeader={noHeader}>
          <ErrorBoundary location={routeProps.location}>
            <Program {...componentProps} />
          </ErrorBoundary>
        </AuthenticatedLayout>
      )}
    />
  );
}
