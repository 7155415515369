import React, { useState } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazy-load';

import { DEVICE_DESKTOP, DEVIDE_MOBILE } from 'settings/device';

import { MediaType } from 'types/medias';

import { useMediaImg } from 'hooks/useMediaImg';

import styles from './FieldImage.module.scss';

export default function FieldImage({
  alt: altProps = '',
  className = undefined,
  forceDevice = undefined,
  isLazy = false,
  lazyClassName = undefined,
  media = undefined,
  src: srcProps = '',
  ...imgProps
}: FieldImageProps) {
  const { url: src, alt, width, height } = useMediaImg(media, forceDevice);
  const [loaded, setLoaded] = useState(!isLazy);

  if (!src && !srcProps) {
    return null;
  }

  const imgRendered = (
    <img
      className={classnames(className, loaded ? styles.loaded : styles.loading)}
      alt={alt || altProps}
      src={src || srcProps}
      onLoad={() => setLoaded(true)}
      width={width}
      height={height}
      {...imgProps}
    />
  );

  return isLazy ? (
    <LazyLoad className={lazyClassName} debounce={false} offsetVertical={500} throttle={250}>
      {imgRendered}
    </LazyLoad>
  ) : (
    imgRendered
  );
}

export type FieldImageProps = {
  alt?: string;
  className?: string;
  forceDevice?: typeof DEVICE_DESKTOP | typeof DEVIDE_MOBILE;
  isLazy?: boolean;
  lazyClassName?: string;
  media?: MediaType | MediaType[];
  src?: string;
  [x: string]: any;
};
