import type { Color } from 'types/ui';

import { LOT_PROMO_COL_WIDTH } from 'settings/lots';

import type { HeadingsWidthType } from 'api/viOffresAPI/apiTypes/LotType';

export function hex2rgba(hex: string | undefined, alpha?: string | number | null) {
  const match = hex?.match(/(\w\w?)(\w\w?)(\w\w?)/);
  if (!match) {
    return undefined;
  }
  const [r, g, b] = match.slice(1).map(x => parseInt(x.length === 1 ? `${x}${x}` : x, 16));
  if (typeof alpha !== 'number' && typeof alpha !== 'string') {
    return `rgb(${r},${g},${b})`;
  }
  return `rgba(${r},${g},${b},${alpha})`;
}

export function colorToString(color: Color) {
  if (!color) {
    return undefined;
  }
  return hex2rgba(color.color, color.opacity);
}

export function getColumnWidth(
  col: { id: string },
  headings: { id: string }[],
  headingsWidth?: HeadingsWidthType
) {
  if (col.id === 'promo') {
    return `${LOT_PROMO_COL_WIDTH}px`;
  }
  if (!headingsWidth) {
    return undefined;
  }
  const totalWidth = Object.entries(headingsWidth).reduce((sum, [id, width]) => {
    if (headings.some(h => h.id === id)) {
      return sum + width;
    }
    return sum;
  }, 0);
  const weightedWidth = (100 * headingsWidth[col.id]) / totalWidth;
  return headings.some(h => h.id === 'promo')
    ? `calc(${weightedWidth}% - ${LOT_PROMO_COL_WIDTH / (headings.length - 1)}px)`
    : `${weightedWidth}%`;
}

export function getColumnStyles(
  col: { id: string },
  headings: { id: string }[],
  cellsWidth?: HeadingsWidthType
) {
  if (col.id === 'promo') {
    return { width: `${LOT_PROMO_COL_WIDTH}px` };
  }
  if (cellsWidth?.[col.id]) {
    return { width: getColumnWidth(col, headings, cellsWidth) };
  }

  return undefined;
}
