import { useEffect } from 'react';
import {
  getTokenFromSession,
  getTokenFromUrl,
  isAuthenticated,
  isTokenExpired,
  setTokenInSession,
} from 'services';
import { getLoginUrlWithState } from 'services/url';

const LOCAL_REGISTER_STEP = 'register-step';

export default function Refresh() {
  useEffect(() => {
    if (!isAuthenticated() && window.vinci.REACT_APP_LOGIN_URL) {
      window.location.href = window.vinci.REACT_APP_LOGIN_URL;
    }
    const token = getTokenFromUrl();
    // Check if we're really trying to store a proper & a new token
    if (token && token !== getTokenFromSession()) {
      if (!isTokenExpired(token)) {
        setTokenInSession(token);
      } else {
        window.top.location.href = getLoginUrlWithState();
      }
    } else {
      return;
    }
    localStorage.removeItem(LOCAL_REGISTER_STEP);
    localStorage.removeItem('registered-files');
  }, []);

  return null;
}
