import { MEDIAS_TYPE_IMAGE, MEDIAS_TYPE_PLAN, MEDIAS_TYPE_VIDEO } from 'settings/medias';

import { UrlResponsiveType } from 'types/url';

export type MediaType = {
  properties: {
    alt?: string;
    height?: number;
    target_id?: number;
    target_type?: string;
    target_uuid?: string;
    title?: string;
    url?: string;
    width?: number;
    type?: typeof MEDIAS_TYPE_IMAGE | typeof MEDIAS_TYPE_PLAN | typeof MEDIAS_TYPE_VIDEO;
  };
  url: string | UrlResponsiveType;
};

export interface VideoType {
  type?: 'remote_video';
  url: string;
  properties: {
    type?: 'video';
    title: string;
    thumbnail: string;
  };
}

export function mediaIsVideo(media: MediaType | VideoType | undefined): media is VideoType {
  return (
    (media as VideoType)?.type === 'remote_video' ||
    (media as VideoType)?.properties?.type === 'video'
  );
}
