import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  root: {
    color: COLORS.GREYS.PURE_GREY,
    fontSize: '6px',
  },
  cols: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 -2px',
  },
  colsItem: {
    padding: '0 2px',
  },
});
