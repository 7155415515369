import React, { useContext, useEffect, useMemo, useState } from 'react';

import { TMS_DEFAULT_USER_VALUES, TMS_DEFAULT_VALUES } from 'settings/tms';

import { error, info } from 'services/log';
import { getTmsScriptUrl, getTmsUserVars, prepareTmsVars, reloadTms } from 'services/tms';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import userContext from 'modules/App/Contexts/userContext';

declare global {
  interface Window {
    tC: {
      container: {
        reload: (data?: any) => void;
      };
      privacy: {
        cookieData: string[];
        getOptinCategories: () => string[];
        getOptinVendors: () => string[];
      };
      privacyCenter: {
        showPrivacyCenter: () => void;
      };
    };
    tc_vars: Record<keyof (typeof TMS_DEFAULT_VALUES & typeof TMS_DEFAULT_USER_VALUES), any>;
    tc_updateconsent: () => void;
  }
}

// Generate TMS script URL only once for all the App to avoid reloading it multiple times as TmsProvider re-renders
const TMS_SCRIPT_URL = getTmsScriptUrl(47);

export default function TmsProvider({ children }: TmsProviderType) {
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const navigationDevice = useMemo(() => {
    if (isResponsive) {
      if (isTablette) {
        return 't';
      }
      return 'm';
    }
    return 'd';
  }, [isResponsive, isTablette]);
  const { userCrm, userPrescripteurs } = useContext(userContext);
  const [isReady, setIsReady] = useState(false);
  const [pageVars, setPageVars] = useState(TMS_DEFAULT_VALUES);
  const userVars = useMemo(() => getTmsUserVars(userCrm, userPrescripteurs), [
    userCrm,
    userPrescripteurs,
  ]);
  type PartialTmsVars = Partial<typeof TMS_DEFAULT_VALUES>;
  const [componentPageVars, setComponentPageVars] = useState<PartialTmsVars>({});
  const [eventPageVars, setEventPageVars] = useState<PartialTmsVars>({});

  useEffect(
    () => {
      const tcVars = {
        ...prepareTmsVars({
          ...pageVars,
          ...userVars,
          ...componentPageVars,
          ...eventPageVars,
        }),
        navigation_device: navigationDevice,
      };
      info('TMS', tcVars);
      window.tc_vars = tcVars;
      if (isReady && tcVars.navigation_template && tcVars.navigation_pagename) {
        reloadTms();
      }
    },
    // isReady is purposefully not included in the deps to avoid reloading the TMS twice upon loading the container
    [pageVars, userVars, componentPageVars, eventPageVars, navigationDevice]
  );

  useEffect(() => {
    // window.tc_vars needs to be fed the correct data BEFORE the TMS script is loaded
    if (!isReady && pageVars !== TMS_DEFAULT_VALUES) {
      const script = document.createElement('script');
      script.src = TMS_SCRIPT_URL;
      script.async = true;
      script.onload = () => {
        try {
          window?.tC?.container?.reload();
          setIsReady(true);
        } catch (e) {
          error("TC script didn't load properly.", e);
        }
      };
      document.body.appendChild(script);
    }
  }, [isReady, pageVars]);

  return (
    <TmsContext.Provider
      value={{
        componentPageVars,
        eventPageVars,
        pageVars,
        setComponentPageVars,
        setEventPageVars,
        setPageVars,
        tmsIsReady: isReady,
      }}
    >
      {children}
    </TmsContext.Provider>
  );
}

export type TmsProviderType = {
  children: React.ReactNode;
};
