import {
  DEBUG_LEVELS,
  LOG_LEVEL_ERROR,
  LOG_LEVEL_INFO,
  LOG_LEVEL_SUCCESS,
  LOG_LEVEL_WARN,
  LOG_STYLES,
  LOG_TYPE_ERROR,
  LOG_TYPE_INFO,
  LOG_TYPE_SUCCESS,
  LOG_TYPE_WARN,
  NOTIFY_LEVELS,
} from 'settings/log';

export function log(name, color, ...rest) {
  const style = Object.entries(LOG_STYLES[color])
    .map(r => r.join(':'))
    .join(';');
  // eslint-disable-next-line no-console
  console.log(`[ %c${name}`, style, ']', ...rest);
}

function isDisplayable(type, availableLevels) {
  switch (type) {
    case LOG_TYPE_ERROR:
      return availableLevels.includes(LOG_TYPE_ERROR);

    case LOG_TYPE_INFO:
      return availableLevels.includes(LOG_LEVEL_INFO);

    case LOG_TYPE_SUCCESS:
      return availableLevels.includes(LOG_LEVEL_SUCCESS);

    case LOG_TYPE_WARN:
      return availableLevels.includes(LOG_LEVEL_WARN);

    default:
      return true;
  }
}

export function canDisplayLog(type) {
  return isDisplayable(type, DEBUG_LEVELS);
}

export function canDisplayNotification(type) {
  return isDisplayable(type, NOTIFY_LEVELS);
}

export function info(name, ...rest) {
  if (canDisplayLog(LOG_TYPE_INFO)) {
    log(name, 'FgCyan', ...rest);
  }
}

export function warn(name, ...rest) {
  if (canDisplayLog(LOG_TYPE_WARN)) {
    log(name, 'FgYellow', ...rest);
  }
}

export function error(name, ...rest) {
  if (canDisplayLog(LOG_LEVEL_ERROR)) {
    log(name, 'FgRed', ...rest);
  }
}

export function success(name, ...rest) {
  if (canDisplayLog(LOG_TYPE_SUCCESS)) {
    log(name, 'FgGreen', ...rest);
  }
}
