export const TOKEN_FOLDER_NAME = '%namefolder%';
export const TOKEN_LIST = '%list%';
export const TOKEN_NB = '%nb%';
export const TOKEN_NB_LOTS = '%nblots%';
export const TOKEN_NB_PROGRAMS = '%nbprogrammes%';
export const TOKEN_DATE = '%date%';
export const TOKEN_REF = '%ref%';
export const TOKEN_NAME = '%name%';
export const TOKEN_MIN = '%min%';
export const TOKEN_MAX = '%max%';
export const TOKEN_MAX_SIZE_IMG = '%maxsizeimg%';
export const TOKEN_MAX_SIZE_PDF = '%maxsizepdf%';
export const TOKEN_LOT_NUMBER = '%lotnumber%';
export const TOKEN_PROGRAM_NAME = '%nameprogram%';
export const TOKEN_TYPES = '%types%';
export const TOKEN_PRICE = '%price%';
export const TOKEN_CATEGORY = '%category%';
export const TOKEN_VENDOR = '%vendor%';
export const TOKEN_VERSION = '%version%';
