import React from 'react';
import classnames from 'classnames';

import { chunkify } from 'services/array';
import { getEntries } from 'services/programDatas';
import { PROGRAM_TAX_TYPE_FURNISHED } from 'settings/programs';
import { TAX_TYPE_PINEL } from 'settings/taxes';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramInfo.module.scss';

export default function ProgramInfo({
  className = undefined,
  program,
  title = undefined,
}: ProgramInfoType) {
  const isFurnished =
    program?.informationsFiscalesEtFinancieres.typeFiscalite === PROGRAM_TAX_TYPE_FURNISHED;

  const entries = getEntries(program, {
    type: true,
    manager: isFurnished,
    number: true,
    typology: true,
    address: true,
    salespoint: true,
    schedule: true,
    product: !isFurnished,
    zone: program?.informationsFiscalesEtFinancieres?.fiscalites?.includes(TAX_TYPE_PINEL),
  });
  const chunks = chunkify(entries, 3, x => x);

  return (
    <div className={classnames(className, styles.root)}>
      {title && <h3 className={styles.title}>{title}</h3>}

      <dl className={styles.list}>
        {chunks.map(({ chunk, key }) => (
          <div key={key} className={styles.col}>
            {chunk.map(({ title, value }) => (
              <div key={title} className={styles.line}>
                <dt className={styles.dt}>{title}</dt>
                <dd className={styles.dd}>{value}</dd>
              </div>
            ))}
          </div>
        ))}
      </dl>
    </div>
  );
}

export type ProgramInfoType = {
  className?: string;
  program: ProgramTypeV2;
  title?: string;
};
