import React from 'react';

import classnames from 'classnames';

import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './SliderArrow.module.scss';

export default function SliderArrow({
  className = undefined,
  disabled = false,
  icon = 'arrow-left',
  style = undefined,
  onClick = undefined,
}: SliderArrowProps) {
  return (
    <button
      type="button"
      className={classnames(className, styles.root)}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      <Icon className={styles.icon} icon={icon} />
    </button>
  );
}

export type SliderArrowProps = {
  className?: string;
  disabled?: boolean;
  icon?: Icons;
  onClick?: () => void;
  style?: { [x: string]: string };
};
