import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  block: {
    margin: '17px 0 0 0',
  },
  blockTop: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  blockTopRight: {
    alignItems: 'flex-end',
    color: COLORS.GREYS.PURE_GREY,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: '1 0 auto',
    fontSize: '7px',
    padding: '0 0 0 22px',
  },
  typologies: {
    display: 'flex',
    flexDirection: 'row',
  },
  availableLots: {
    color: COLORS.GREYS.BLACK3,
    fontSize: '14px',
    margin: '0 0 8px 0',
  },
  gridHeadings: {
    alignItems: 'center',
    backgroundColor: COLORS.GREYS.GREY_LIGHT,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontSize: '5px',
  },
  gridHeadingsCell: {
    alignItems: 'center',
    color: COLORS.GREYS.PURE_BLACK,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '7px 2px',
    position: 'relative',
    textTransform: 'uppercase',
    width: '100%',
  },
  subText: {
    bottom: '2px',
    color: COLORS.GREYS.GREY_LIGHT3,
    fontSize: '4px',
    left: '-7px',
    right: 0,
    position: 'absolute',
    textAlign: 'center',
  },
  sortPicto: {
    margin: '0 0 0 4px',
    width: '3px',
  },
  grid: {
    display: 'flex',
    flexDection: 'column',
  },
  gridRow: {
    alignItems: 'center',
    backgroundColor: COLORS.GREYS.WHITE,
    borderBottom: `0.5px solid ${COLORS.GREYS.GREY}`,
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0',
  },
  gridCell: {
    color: COLORS.GREYS.PURE_BLACK,
    fontSize: '7px',
    padding: '0 2px',
    textAlign: 'center',
  },
});
