import isMobile from 'ismobilejs';

import { identity } from 'services/functional';
import { TMS_DEFAULT_USER_VALUES, TMS_DEFAULT_VALUES } from 'settings/tms';

import type { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';
import type { Prescriptor } from 'api/vi3pAPI/apiTypes/Prescriptor';

import { error } from './log';
import { createObserver } from './observer';
import { isAuthenticated } from './authentication';

export function getTmsScriptUrl(number: number) {
  return `${window.vinci.REACT_APP_TAG_COMMANDER.replace(
    '%CONTAINERID%',
    number.toString()
  )}?cachebuster=${new Date().getTime()}`;
}

export function getTmsDevice() {
  const detect = isMobile(navigator.userAgent);
  if (detect.phone) {
    return 'm';
  }
  if (detect.tablet) {
    return 't';
  }

  return 'd';
}

type TmsValues = typeof TMS_DEFAULT_VALUES & typeof TMS_DEFAULT_USER_VALUES;
type TmsValue = Record<string, any> | boolean | string | number;
export function prepareTmsVars(vars: TmsValues): Record<keyof TmsValues, any> {
  function getPreparedTmsVars(currentVar: TmsValue | TmsValue[]) {
    if (currentVar === undefined || currentVar === null) {
      return null;
    }
    if (Array.isArray(currentVar)) {
      return currentVar.map(getPreparedTmsVars);
    }
    if (typeof currentVar === 'object') {
      return Object.fromEntries(
        Object.entries(currentVar).map(([key, value]) => [key, getPreparedTmsVars(value)])
      );
    }
    if (typeof currentVar === 'boolean') {
      return Number(currentVar);
    }
    return currentVar;
  }

  return getPreparedTmsVars(vars);
}

export function getTmsVars(props: Partial<typeof TMS_DEFAULT_VALUES>) {
  return {
    ...TMS_DEFAULT_VALUES,

    env_device: getTmsDevice(),
    user_logue: Number(isAuthenticated()),

    ...props,
  };
}

export function getTmsUserVars(
  userCrm: UserTokenType | undefined,
  userPrescripteurs: Prescriptor | undefined
): typeof TMS_DEFAULT_USER_VALUES {
  if (userCrm && userPrescripteurs) {
    return {
      ...TMS_DEFAULT_USER_VALUES,
      user_email: userCrm.email,
      user_id: userCrm.extension_VI3P_ContactId,
      user_email_animateur:
        userPrescripteurs.interlocuteurs?.[0]?.field_email ??
        TMS_DEFAULT_USER_VALUES.user_email_animateur,
    };
  }
  return TMS_DEFAULT_USER_VALUES;
}

export const tmsObserver = createObserver();
window.tc_updateconsent = tmsObserver.notify;

export function tmsShowPrivacyCenter(event?: MouseEvent) {
  if (event && event.preventDefault) {
    event.preventDefault();
  }
  try {
    window.tC.privacyCenter.showPrivacyCenter();
  } catch (e) {
    error(e);
  }
}

export function reloadTms(errorMessage = 'TagCommander - Reload failed') {
  if (typeof window !== 'undefined') {
    try {
      window.tC.container.reload({
        events: {
          page: [{}, {}],
        },
        exclusions: ['deduplication', 'privacy'],
      });
    } catch (e) {
      error(errorMessage, e);
    }
  }
}

export function tmsGetLocationLabels(locations: string[]) {
  return (
    locations
      .map(location =>
        Object.fromEntries<string>(
          location?.split(',').map(elem => elem.split('=') as [string, string])
        )
      )
      .map(location => location?.label)
      .filter(identity) ?? []
  );
}
