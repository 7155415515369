import React, { useMemo } from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';

import { replaceTokens } from 'services/formatter';
import { LABEL_PROGRAM_GALLERY, LABEL_PROGRAM_GALLERY_CLOSE } from 'settings/labels';
import { MEDIAS_TYPE_IMAGE } from 'settings/medias';
import { TOKEN_NAME } from 'settings/token';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import GallerySlider from 'commonUi/GallerySlider/GallerySlider';

import styles from './ProgramModalGallery.module.scss';

export default function ProgramModalGallery({
  closeCallBack,
  open,
  program,
}: ProgramModalGalleryProps) {
  const items = useMemo(
    () =>
      program.perspectives?.slider?.map(media => ({
        url: media,
        properties: {
          type: MEDIAS_TYPE_IMAGE,
        } as const,
      })) ?? [],
    [program]
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <MuiDialog
      classes={{ root: styles.dialog, paper: styles.paper }}
      maxWidth="xl"
      open={open}
      onClose={closeCallBack}
      aria-label={replaceTokens(LABEL_PROGRAM_GALLERY, { [TOKEN_NAME]: program.nomCommercial })}
    >
      <button
        type="button"
        className={styles.close}
        onClick={closeCallBack}
        title={LABEL_PROGRAM_GALLERY_CLOSE}
      >
        <Icon icon="cross" />
      </button>
      <GallerySlider
        slider={items}
        makeSlide={media => ({
          key: media.url,
          content: <FieldImage className={styles.slideImg} media={media} />,
        })}
        makeMiniature={media => ({
          key: media.url,
          content: <FieldImage media={media} />,
        })}
      />
    </MuiDialog>
  );
}

type ProgramModalGalleryProps = {
  closeCallBack: () => void;
  open: boolean;
  program: ProgramTypeV2;
};
