import React from 'react';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import YouTube from 'commonUi/YouTube/YouTube';

import styles from './SubActuality.module.scss';

interface SubActualityProps {
  actu: {
    lien: string;
    photo: { detail_sous_actu: string };
    texte: string;
    titre: string;
    video: string;
  };
}

export default function SubActuality({ actu }: SubActualityProps) {
  if (!actu) {
    return null;
  }

  const { lien: link, photo, texte: text, titre: title, video } = actu;

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>

      {text && <FieldRte className={styles.text} html={text} />}
      {photo && 'detail_sous_actu' in photo && (
        <img className={styles.img} src={photo.detail_sous_actu} alt="Vinci" title="Vinci" />
      )}
      {video && (
        <section className="section-conteneur">
          <YouTube
            videoId={video.split('/embed/')[1]}
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                controls: 0,
                enablejsapi: 1,
              },
            }}
          />
        </section>
      )}
      <a className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
    </div>
  );
}
