import { StyleSheet } from '@react-pdf/renderer';

import { COLORS } from 'settings/ui';

export default StyleSheet.create({
  banner: {
    height: '235px',
    position: 'relative',
    overflow: 'hidden',
  },
  bannerGradient: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  bannerImage: {
    height: 'auto',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  bannerBottom: {
    alignItems: 'flex-end',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    padding: '7px 10px',
    position: 'absolute',
    right: 0,
  },
  bannerBottomContent: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    padding: '7px 10px',
    width: '100%',
  },
  name: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '24px',
    fontWeight: 'medium',
    lineHeight: 1,
    margin: '0 0 5px 0',
  },
  address: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '10px',
    fontWeight: 'medium',
    lineHeight: 1,
    margin: '0 0 8px 0',
    textTransform: 'uppercase',
  },
  pictosList: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 3px 4px',
    minWidth: '116px',
  },
  pictosListItem: {
    padding: '0 7px 0 0',
  },
  imgVi4you: {
    height: '50px',
    width: '51px',
  },
  imgKitchen: {
    height: '50px',
    width: '50px',
  },
  imgControlledPrices: {
    height: '17px',
    margin: '0 0 0 7px',
    width: '39px',
  },
  newHousing: {
    color: COLORS.GREYS.PURE_WHITE,
    fontSize: '18px',
    fontWeight: 'medium',
  },
  infos: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  pastille: {
    height: '25px',
    margin: '0 4px 0 0',
    width: '25px',
  },
  infosWrapper: {
    flex: '1 0 auto',
  },
  totem: {
    width: '171px',
  },
});
