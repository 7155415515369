import { useCallback, useState } from 'react';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

export default function useDownloadActivityFile() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingProgress, setDownloadingProgress] = useState(0);

  return {
    isDownloading,
    downloadingProgress,
    handleFileDownload: useCallback((url: string) => {
      setIsDownloading(true);
      return axiosVI3PInstance
        .get<ArrayBuffer>(url, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {
            if (progressEvent && progressEvent.loaded && progressEvent.loaded) {
              const total = parseFloat(progressEvent.total);
              const current = progressEvent.loaded;
              const percentCompleted = Math.floor((current / total) * 100);
              setDownloadingProgress(percentCompleted);
            }
          },
        })
        .then(response => {
          setDownloadingProgress(0);
          return response;
        })
        .finally(() => setIsDownloading(false));
    }, []),
  };
}
