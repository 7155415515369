import type { ContextType } from 'react';

import type { Promotion } from 'types/promotions';

import type SettingsContext from 'modules/App/Contexts/SettingsContext';

export function hasHonoBoostPromotion(promotions: Promotion[]) {
  return promotions.length > 0 && promotions.some(promo => promo.honoboost);
}

export function getFirstPromotion(promotions: Promotion[] | undefined, isHonoboost: boolean) {
  return (
    promotions && promotions.length > 0 && promotions.find(promo => promo.honoboost === isHonoboost)
  );
}

export function getPromotionStylesFromSettings(
  settings: ContextType<typeof SettingsContext>['settings']
) {
  return {
    backgroundColor: settings.promotions?.promotions_background_color,
    color: settings.promotions?.promotions_text_color,
  };
}
