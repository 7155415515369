import React, { useState } from 'react';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@material-ui/core';
import * as yup from 'yup';

import {
  KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
  KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
  KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
} from 'settings/forms';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_OPTION,
  LABEL_FIRSTNAME,
  LABEL_FIRSTNAME_ERROR,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_ERROR_DEFAULT,
  LABEL_FORM_ERROR_MESSAGE_DEGRADED_CRM,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_FORM_OPTION_ERROR_TITLE,
  LABEL_FORM_OPTION_ERROR_TITLE_DEGRADED_CRM,
  LABEL_FORM_OPTION_TITLE,
  LABEL_FORM_PREBOOK_INVESTOR,
  LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
  LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
  LABEL_LASTNAME,
  LABEL_LASTNAME_ERROR,
  LABEL_MADAME,
  LABEL_MISTER,
  LABEL_PURCHASE_DESTINATION,
  LABEL_ZIPCODE,
  LABEL_ZIPCODE_ERROR,
} from 'settings/labels';
import { TOKEN_LOT_NUMBER, TOKEN_PROGRAM_NAME } from 'settings/token';
import { USER_GENDER_FEMALE, USER_GENDER_MALE } from 'settings/user';

import type { OptionConfirmationReturnType } from 'api/viCrmApi/apiTypes/Option';

import { replaceTokens } from 'services/formatter';
import { taxMapping } from 'services/taxes';

import { useCreateOption } from 'hooks/useActivities';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import RadioGroup from 'commonUi/RadioButton/RadioGroup';
import RadioLabel from 'commonUi/RadioButton/RadioLabel';
import SelectField from 'commonUi/SelectField/SelectField';
import TextField from 'commonUi/TextField/TextField';

import styles from '../ModalAction.module.scss';

const DEFAULT_ERROR_TITLE = LABEL_FORM_OPTION_ERROR_TITLE;
const DEFAULT_ERROR_MESSAGE = LABEL_FORM_ERROR_CONTENT;

export interface FormOptionType {
  apiError: boolean;
  handleApiError: (error: boolean) => void;
  handleSuccess: (responseData: OptionConfirmationReturnType) => void;
  lot: {
    number: string;
    program: { name?: string; ref: string };
  };
  lotTaxType?: string[];
  onClose: () => void;
  optimisticSuccess?: () => void;
  undoOptimisticSuccess?: () => void;
}

export default function FormOption({
  apiError,
  handleApiError,
  handleSuccess,
  lot,
  lotTaxType = [],
  onClose,
  optimisticSuccess,
  undoOptimisticSuccess,
}: FormOptionType) {
  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState(DEFAULT_ERROR_TITLE);
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE);

  const createOption = useCreateOption();

  const { clearErrors, control, handleSubmit, formState } = useForm({
    resolver: yupResolver(sendBookletSchema),
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;
  const { errors } = formState;

  function handleClose() {
    handleApiError(false);
    setLoading(false);
    setErrorTitle(DEFAULT_ERROR_TITLE);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  const submitHandler = handleSubmit(values => {
    setLoading(true);
    if (typeof optimisticSuccess === 'function') {
      optimisticSuccess();
    }
    return createOption(lot?.number, lot?.program.ref, {
      civility: values?.['field-civility'],
      firstName: values?.['field-firstname'],
      lastName: values?.['field-lastname'],
      postalCode: values?.['field-zipcode'],
      destination: values?.['field-purchase-destination'],
      email: '', // TODO data missing from form
    })
      .then(response => handleSuccess(response?.data))
      .catch(error => {
        if (typeof undoOptimisticSuccess === 'function') {
          undoOptimisticSuccess();
        }
        if (error?.response?.status) {
          const { status } = error.response;
          const errorMessage = error?.response?.data?.message;
          if (errorMessage) {
            if (status === 202) {
              setErrorTitle(LABEL_FORM_OPTION_ERROR_TITLE_DEGRADED_CRM);
              setErrorMessage(
                replaceTokens(LABEL_FORM_ERROR_MESSAGE_DEGRADED_CRM, {
                  [TOKEN_LOT_NUMBER]: lot.number,
                  [TOKEN_PROGRAM_NAME]: lot.program.name,
                })
              );
            }
            setErrorMessage(errorMessage);
          }
          handleApiError(true);
        }
      })
      .finally(() => setLoading(false));
  });

  const taxType = taxMapping?.get(lotTaxType[0]);

  return (
    <form onSubmit={submitHandler}>
      <div className={styles.form}>
        <div className={styles.title}>{LABEL_FORM_OPTION_TITLE}</div>
        <div className={styles.input}>
          <Controller
            name="field-civility"
            control={control}
            defaultValue={USER_GENDER_MALE}
            render={props => (
              <RadioGroup
                value={props.field?.value}
                onChange={ev => props?.field?.onChange(ev.target.value)}
                radioLabel={
                  <>
                    <RadioLabel value={USER_GENDER_MALE} label={LABEL_MISTER} />
                    <RadioLabel value={USER_GENDER_FEMALE} label={LABEL_MADAME} />
                  </>
                }
              />
            )}
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name="field-lastname"
              control={control}
              render={props => (
                <TextField
                  data-test-id="form-option--lastname"
                  error={errors?.['field-lastname']}
                  helperText={errors?.['field-lastname'] && LABEL_LASTNAME_ERROR}
                  label={LABEL_LASTNAME}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name="field-firstname"
              control={control}
              render={props => (
                <TextField
                  data-test-id="form-option--firstname"
                  error={errors?.['field-firstname']}
                  helperText={errors?.['field-firstname'] && LABEL_FIRSTNAME_ERROR}
                  label={LABEL_FIRSTNAME}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  required
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name="field-zipcode"
              control={control}
              render={props => (
                <TextField
                  data-test-id="form-option--zipcode"
                  error={errors?.['field-zipcode']}
                  helperText={errors?.['field-zipcode'] && LABEL_ZIPCODE_ERROR}
                  label={LABEL_ZIPCODE}
                  onChange={ev => {
                    const value = parseInt(ev.target.value, 10);
                    props?.field.onChange(Number.isNaN(value) ? ev.target.value : value);
                    clearErrors('field-zipcode');
                  }}
                  required
                  value={props?.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name="field-purchase-destination"
              control={control}
              defaultValue={
                taxType &&
                ['Pinel', 'LMNP', 'Demembrement', 'Pinel+', 'PinelDeroge', 'BRS', 'PLS'].includes(
                  taxType
                )
                  ? KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR
                  : ''
              }
              render={props => (
                <SelectField
                  id="field-purchase-destination"
                  data-test-id="form-option--destination"
                  label={LABEL_PURCHASE_DESTINATION}
                  onChange={optionValue => props?.field.onChange(optionValue)}
                  options={[
                    {
                      value: LABEL_FORM_PREBOOK_INVESTOR,
                      key: KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
                    },
                    {
                      value: LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
                      key: KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
                    },
                    {
                      value: LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
                      key: KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
                    },
                  ]}
                  required
                  value={props?.field.value}
                />
              )}
            />
          </div>
        </div>
        {!apiError && (
          <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
        )}
      </div>
      <div className={classNames(styles.modalFooter, { [styles.errorFooter]: apiError })}>
        {apiError && (
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => handleApiError(false)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{errorTitle}</h4>
              <p>{errorMessage}</p>
              <p>{LABEL_FORM_ERROR_DEFAULT}</p>
            </div>
          </div>
        )}
        {!apiError && (
          <>
            <div className={styles.cancelButton}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            </div>
            <div className={styles.createButton}>
              <Button
                data-test-id="form-option--submit"
                disabled={!isDirty || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                {LABEL_CONFIRM_OPTION}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const sendBookletSchema = yup.object().shape({
  'field-civility': yup.string().trim().required(),
  'field-firstname': yup.string().trim().required(),
  'field-lastname': yup.string().trim().required(),
  'field-zipcode': yup.number().required(),
  'field-purchase-destination': yup.string().trim().required(),
});
